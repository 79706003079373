import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TransportManagerIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M5.8135 6C6.65706 3.38874 9.10796 1.5 12 1.5C14.892 1.5 17.3429 3.38874 18.1865 6C18.1937 6.02231 18.2008 6.04468 18.2078 6.0671C18.3977 6.6777 18.5 7.3269 18.5 8C18.5 8.61788 18.4138 9.21562 18.2527 9.78186C18.1776 9.91402 18.0851 10.0532 17.975 10.2C17.6255 10.666 17.153 11.1399 16.6465 11.6464L17.3536 12.3536L17.3686 12.3385C17.8578 11.8494 18.3786 11.3285 18.775 10.8C18.9624 10.5502 19.1345 10.2827 19.264 10H19.469C19.9188 10 20.3203 9.70054 20.3951 9.25699C20.4641 8.84828 20.5 8.42833 20.5 8C20.5 7.57167 20.4641 7.15172 20.3951 6.74301C20.3203 6.29946 19.9188 6 19.469 6H19.2304C18.3551 2.82881 15.4495 0.5 12 0.5C8.55047 0.5 5.64486 2.82881 4.76962 6H4.53101C4.08119 6 3.67972 6.29946 3.60487 6.74301C3.53591 7.15172 3.5 7.57167 3.5 8C3.5 8.42833 3.53591 8.84828 3.60487 9.25699C3.67972 9.70054 4.08118 10 4.53101 10H5.8135C5.60996 9.36994 5.5 8.69781 5.5 8C5.5 7.30219 5.60996 6.63006 5.8135 6Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3C9.23858 3 7 5.23858 7 8C7 9.88174 8.03951 11.5207 9.5756 12.3739C6.34252 13.4009 4 16.4269 4 20V21H6V20C6 16.6863 8.68629 14 12 14C15.3137 14 18 16.6863 18 20V21H20V20C20 16.4269 17.6575 13.4009 14.4244 12.3739C15.9605 11.5207 17 9.88174 17 8C17 5.23858 14.7614 3 12 3ZM9 8C9 6.34315 10.3431 5 12 5C13.6569 5 15 6.34315 15 8C15 9.65685 13.6569 11 12 11C10.3431 11 9 9.65685 9 8Z"
        />
        <path d="M16 17H13V18H16V17Z" />
      </SvgIcon>
    );
  }
);

TransportManagerIcon.displayName = 'TransportManagerIcon';

export default TransportManagerIcon;
