import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { updateOrder } from '@core/api';
import { QueryKeys } from '@core/config';
import { AssignIcon, Box, LoadingButton, Stack } from '@dizzbo/ui';

import { CustomerType, OrderType, UUIDType } from '@types';
import { CustomerAutoCompleteTextField } from './CustomerAutoCompleteTextField';

const FormStyles = styled('form')(({}) => ({
  width: '100%',
}));

const schema = yup.object().shape({
  customer: yup.object().required('Vehicle is required'),
});

type Props = {
  orderUUID: UUIDType;
  customer?: CustomerType;
};

export const CustomerForm: React.FC<Props> = ({ orderUUID, customer }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const { mutate: mutateOrder, isPending } = useMutation<
    OrderType,
    unknown,
    Partial<OrderType>
  >({
    mutationKey: [QueryKeys.ORDERS, orderUUID],
    mutationFn: (values) =>
      updateOrder({ orderUUID: orderUUID, orderData: values }),
  });

  const onSubmit = (formData: any) => {
    const id = toast.loading(t('assigningCustomer'));
    mutateOrder(
      { loader: formData?.customer?.uuid },
      {
        onSuccess: (data, values) => {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.ORDERS, orderUUID],
          });
          toast.update(id, {
            autoClose: 6000,
            render: t('customerSuccessfullyAssigned'),
            type: 'success',
            isLoading: false,
          });
        },
        onError: (error, variables, context) => {
          toast.update(id, {
            autoClose: 6000,
            render: t('customerCouldNotBeAssigned'),
            type: 'error',
            isLoading: false,
          });
        },
      }
    );
  };

  return (
    <FormStyles onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" spacing={6}>
        <Controller
          name="customer"
          control={control}
          render={({ field, fieldState }) => (
            <CustomerAutoCompleteTextField
              label={t('customer')}
              {...field}
              sx={{ width: '100%' }}
            />
          )}
        />
        <Box>
          <LoadingButton
            size="large"
            type="submit"
            variant="primary"
            loading={isPending}
            startIcon={<AssignIcon />}
            disabled={!isValid}
          >
            {t('assign')}
          </LoadingButton>
        </Box>
      </Stack>
    </FormStyles>
  );
};
