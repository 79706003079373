import { useMutation } from '@tanstack/react-query';
import { updateTour } from '@core/api';
import { QueryKeys } from '@core/config';

export const useUpdateTour = (uuid: UUIDType) => {
  const mutation = useMutation({
    mutationKey: [QueryKeys.TOURS, uuid],
    mutationFn: (values) => updateTour({ tourUUID: uuid, tourData: values }),
  });
  return mutation;
};
