import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { useLocalStorage } from 'react-use';
import uuid4 from 'uuid4';

import { usePrevious, useUpdateEffect } from 'react-use';

import { useAuth } from '@dizzbo/core/hooks';
import { useUpdateUserSettings } from '@dizzbo/core/queries';

import { UUIDType, WorkspaceType } from 'packages/core/types';
import { reducer } from './reducer';

type ProviderProps = PropsWithChildren<{
  workspaceNamespace: string;
  children: ReactNode;
}>;

type WorkspacesContextType = {
  workspaces: WorkspaceType[];
  activeWorkspaceUUID: UUIDType | undefined;
  isLoadingWorkspaces: boolean;
  activeWorkspace: WorkspaceType | undefined;
  addWorkspace: () => void;
  deleteWorkspace: (uuid: UUIDType) => void;
  setActiveWorkspaceUUID: (uuid: UUIDType) => void;
  setWorkspaceTitle: (uuid: UUIDType, title: string) => void;
  setWorkspaceSettings: (
    uuid: UUIDType,
    settingType: string,
    settings: object
  ) => void;
  removeWorkspaceSettings: (
    uuid: UUIDType,
    settingType: string,
    setting: string
  ) => void;
};
// ----------------------------------------------------------------------

const initialState = {
  workspaces: [],
  activeWorkspaceUUID: 'default-workspace',
  isLoadingWorkspaces: true,
  activeWorkspace: undefined,
};

const WorkspacesContext = createContext<WorkspacesContextType>({
  ...initialState,
  addWorkspace: () => {},
  deleteWorkspace: () => {},
  setActiveWorkspaceUUID: (uuid: UUIDType) => {},
  setWorkspaceTitle: (uuid: UUIDType, title: string) => {},
  setWorkspaceSettings: (
    uuid: UUIDType,
    settingType: string,
    settings: object
  ) => {},
  removeWorkspaceSettings: (
    uuid: UUIDType,
    settingType: string,
    setting: string
  ) => {},
});

WorkspacesContext.displayName = 'WorkspacesContext';

// ----------------------------------------------------------------------

function WorkspacesProvider({ children, workspaceNamespace }: ProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { workspaces, activeWorkspaceUUID, isLoadingWorkspaces } = state;

  const { user } = useAuth();
  const { mutateUserSettings } = useUpdateUserSettings();
  const [localStorageWorkspaces, setLocalstorageWorkspaces] = useLocalStorage(
    workspaceNamespace,
    {
      workspaces: [
        {
          uuid: 'default-workspace',
          title: 'Default Workspace',
          settings: {},
        },
      ],
      activeWorkspaceUUID: 'default-workspace',
    }
  );
  const previousWorkspaces = usePrevious(workspaces);

  useEffect(() => {
    // check if we got settings for the workspaceNamespace from remote
    if (
      user &&
      user.frontendSettings &&
      !isEmpty(user.frontendSettings) &&
      user.frontendSettings[workspaceNamespace] &&
      user.frontendSettings[workspaceNamespace]?.workspaces &&
      !isEqual(user.frontendSettings[workspaceNamespace], state)
    ) {
      const workspaceCollection = user.frontendSettings[workspaceNamespace];
      console.log('got workspaces from remote', workspaceCollection);
      setWorkspaces(workspaceCollection?.workspaces);
      if (workspaceCollection?.activeWorkspaceUUID) {
        setActiveWorkspaceUUID(workspaceCollection?.activeWorkspaceUUID, true);
      }

      // if not, check if we have workspaces in local storage
    } else if (
      localStorageWorkspaces &&
      !isEmpty(localStorageWorkspaces) &&
      localStorageWorkspaces?.workspaces
    ) {
      setWorkspaces(localStorageWorkspaces?.workspaces);
      console.log(
        'got workspaces from local storage',
        localStorageWorkspaces?.workspaces
      );
      if (localStorageWorkspaces?.activeWorkspaceUUID) {
        setActiveWorkspaceUUID(
          localStorageWorkspaces?.activeWorkspaceUUID,
          true
        );
      }
    } else {
      console.log(
        'got no workspaces, setting default',
        localStorageWorkspaces?.workspaces
      );
      setWorkspaces([
        {
          uuid: 'default-workspace',
          title: 'Default Workspace',
          settings: {},
        },
      ]);
      setActiveWorkspaceUUID('default-workspace', true);
    }
  }, [user]);

  const debouncedSave = useCallback(
    debounce((workspacesState) => {
      console.log('debouncedSave', workspacesState);
      mutateUserSettings({ [workspaceNamespace]: workspacesState });
    }, 1000),
    []
  );

  useUpdateEffect(() => {
    if (user && user.frontendSettings && !isLoadingWorkspaces) {
      if (previousWorkspaces.length > 0) {
        setLocalstorageWorkspaces({ workspaces, activeWorkspaceUUID });
        debouncedSave({ workspaces, activeWorkspaceUUID });
      }
    }
  }, [workspaces]);

  /**
   * Set the the workspaces
   * @param {WorkspaceType[]} workspaces - The array of workspace we want to set.
   */
  const setWorkspaces = (workspaces: WorkspaceType[]) => {
    dispatch({
      type: 'SET_WORKSPACES',
      payload: {
        workspaces: workspaces,
      },
    });
  };

  /**
   * Set the UUID of the current active workspace
   * @param {UUIDType} uuid - The uuid of the current workspace we want to set.
   * @param {boolean} setFromRemote
   */
  const setActiveWorkspaceUUID = (
    uuid: UUIDType,
    setFromRemote: boolean = false
  ) => {
    if (!setFromRemote) {
      setLocalstorageWorkspaces({ workspaces, activeWorkspaceUUID: uuid });
      debouncedSave({ workspaces, activeWorkspaceUUID: uuid });
    }
    dispatch({
      type: 'SET_ACTIVE_WORKSPACE_UUID',
      payload: {
        uuid: uuid,
      },
    });
  };

  /**
   * Add a new workspace
   */
  const addWorkspace = () => {
    const uuid = uuid4();
    const workspace = { uuid: uuid, title: 'Workspace' };

    dispatch({
      type: 'ADD_WORKSPACE',
      payload: {
        workspace: workspace,
      },
    });
  };

  /**
   * Delete a workspace
   * @param {UUIDType} uuid - The uuid of the current workspace we want to delete.
   */
  const deleteWorkspace = (uuid: UUIDType) => {
    dispatch({
      type: 'DELETE_WORKSPACE',
      payload: {
        uuid: uuid,
      },
    });
  };

  /**
   * Set the workspace title
   * @param {UUIDType} uuid - The uuid of the current workspace.
   * @param {string} title - The new title of the workspace.
   */
  const setWorkspaceTitle = (uuid: UUIDType, title: string) => {
    dispatch({
      type: 'SET_WORKSPACE_TITLE',
      payload: {
        uuid: uuid,
        title: title,
      },
    });
  };

  /**
   * Set the workspace settings
   * @param {UUIDType} uuid - The uuid of the current workspace.
   * @param {string} settingType - the type of the setting. Like 'filters', 'pageSize', etc.
   * @param {object} settings - the setting itself. { "search": "", "status": [] },
   */
  const setWorkspaceSettings = (
    uuid: UUIDType,
    settingType: string,
    settings: object
  ) => {
    dispatch({
      type: 'SET_WORKSPACE_SETTINGS',
      payload: {
        uuid: uuid,
        settingType: settingType,
        settings: settings,
      },
    });
  };

  /**
   * Remove a workspace settings
   * @param {UUIDType} uuid - The uuid of the current workspace.
   * @param {string} settingType - the type of the setting. Like 'filters', 'pageSize', etc.
   * @param {string} setting - the setting itself.  "search", "status"
   */
  const removeWorkspaceSettings = (
    uuid: UUIDType,
    settingType: string,
    setting: string
  ) => {
    dispatch({
      type: 'REMOVE_WORKSPACE_SETTINGS',
      payload: {
        uuid: uuid,
        settingType: settingType,
        setting: setting,
      },
    });
  };

  const values = useMemo(
    () => ({
      ...state,
      addWorkspace,
      deleteWorkspace,
      setActiveWorkspaceUUID,
      setWorkspaceTitle,
      setWorkspaceSettings,
      removeWorkspaceSettings,
    }),
    [
      state.workspaces,
      state.activeWorkspaceUUID,
      state.isLoadingWorkspaces,
      state.activeWorkspace,
    ]
  );

  return (
    <WorkspacesContext.Provider value={values}>
      {children}
    </WorkspacesContext.Provider>
  );
}

export { WorkspacesContext, WorkspacesProvider };
