import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const InfoOutlineIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 20.496C16.6922 20.496 20.496 16.6922 20.496 12C20.496 7.30779 16.6922 3.504 12 3.504C7.30779 3.504 3.504 7.30779 3.504 12C3.504 16.6922 7.30779 20.496 12 20.496ZM12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM13.25 8.25001C13.25 8.94037 12.6904 9.50001 12 9.50001C11.3097 9.50001 10.75 8.94037 10.75 8.25001C10.75 7.55966 11.3097 7.00001 12 7.00001C12.6904 7.00001 13.25 7.55966 13.25 8.25001ZM13 17V11H11V17H13Z"
        />
      </SvgIcon>
    );
  }
);

InfoOutlineIcon.displayName = 'InfoOutlineIcon';

export default InfoOutlineIcon;
