import { MenuList as MuiMenuList } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiMenuListProps = ComponentProps<typeof MuiMenuList>;

export const MenuList = forwardRef<HTMLUListElement, MuiMenuListProps>(
  (props, ref) => <MuiMenuList ref={ref} {...props} />
);

MenuList.displayName = 'MenuList';
