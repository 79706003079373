import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const MinimizeIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M3 21H21V19H3V21Z" />
      </SvgIcon>
    );
  }
);

MinimizeIcon.displayName = 'MinimizeIcon';

export default MinimizeIcon;
