import { Link as MuiLink } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { ComponentProps, forwardRef } from 'react';

interface LinkPropsOverrides {
  /**
   * The linkType to use.
   * @default 'default'
   */
  linkType?:
    | 'default'
    | 'default-contrast'
    | 'destructive'
    | 'destructive-contrast';
}

type MuiLinkProps = ComponentProps<typeof MuiLink>;

type LinkProps = MuiLinkProps & LinkPropsOverrides;

const MuiLinkStyles = styled(MuiLink, {
  shouldForwardProp: (prop) => prop !== 'linkType',
})<LinkProps>(({ theme, linkType }) => {
  let style;

  if (linkType === 'default-contrast') {
    style = {
      color: theme.styles.link.defaultContrast.default.color,
      textDecoration: 'none',
      '&:hover': {
        color: theme.styles.link.defaultContrast.hover.color,
        textDecoration: 'underline',
      },
      '&:active': {
        color: theme.styles.link.defaultContrast.active.color,
        textDecoration: 'none',
      },
      '&:focus': {
        color: theme.styles.link.defaultContrast.focus.color,
        textDecoration: 'none',
      },
    };
  }

  if (linkType === 'destructive') {
    style = {
      color: theme.styles.link.desctructive.default.color,
      textDecoration: 'none',
      '&:hover': {
        color: theme.styles.link.desctructive.hover.color,
        textDecoration: 'underline',
      },
      '&:active': {
        color: theme.styles.link.desctructive.active.color,
        textDecoration: 'none',
      },
      '&:focus': {
        color: theme.styles.link.desctructive.focus.color,
        textDecoration: 'none',
      },
    };
  }
  if (linkType === 'destructive-contrast') {
    style = {
      color: theme.styles.link.desctructiveContrast.default.color,
      textDecoration: 'none',
      '&:hover': {
        color: theme.styles.link.desctructiveContrast.hover.color,
        textDecoration: 'underline',
      },
      '&:active': {
        color: theme.styles.link.desctructiveContrast.active.color,
        textDecoration: 'none',
      },
      '&:focus': {
        color: theme.styles.link.desctructiveContrast.focus.color,
        textDecoration: 'none',
      },
    };
  }

  return {
    ...style,
  };
});

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, linkType = 'default', ...rest }, ref) => {
    return (
      <MuiLinkStyles linkType={linkType} ref={ref} {...rest}>
        {children}
      </MuiLinkStyles>
    );
  }
);

Link.displayName = 'Link';
