import { alpha } from '@mui/material/styles';

const listTab = (palette) => {
  return {
    listTab: {
      default: {
        color: palette.oxford[100],
        backgroundColor: palette.turquoise[60],
      },
      hover: {},
      active: {
        color: palette.oxford[40],
        backgroundColor: palette.white,
      },
      focus: {},
      disabled: {},
    },
  };
};

export default listTab;
