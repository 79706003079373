const InputAdornment = (theme) => {
  return {
    MuiInputAdornment: {
      defaultProps: {},
      styleOverrides: {
        root: {
          '.MuiSvgIcon-root': {
            height: 20,
            width: 20,
          },
        },
        positionEnd: ({ ownerState, theme }) => {
          let stringStyles = {};
          if (typeof ownerState.children === 'string') {
            stringStyles = {
              '&::before': {},
              '&:last-of-type': {
                '&::before': {},
              },
            };
          }
          if (ownerState.children.length > 1) {
          }
          return {
            width: '50px',
            height: '38px',
            maxHeight: '38px',
            justifyContent: 'center',
            // '&::before': {
            //   content: "' '",
            //   display: 'block',
            //   position: 'absolute',
            //   zIndex: 1,
            //   right: 44,
            //   height: 20,
            //   top: 10,
            //   border: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
            //   borderWidth: '0 0 0 1px',
            // },
            '& + .MuiInputAdornment-root': {
              '&::before': {
                content: "' '",
                display: 'block',
                position: 'absolute',
                zIndex: 1,
                right: 44,
                height: 20,
                top: 9,
                border: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
                borderWidth: '0 0 0 1px',
              },
            },
            '&:last-of-type': {
              '&::before': {
                content: "' '",
                display: 'block',
                position: 'absolute',
                zIndex: 1,
                right: 44,
                height: 20,
                top: 9,
                border: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
                borderWidth: '0 0 0 1px',
              },
            },
            ...stringStyles,
          };
        },
      },
    },
  };
};

export default InputAdornment;
