const Menu = (theme) => {
  return {
    MuiMenu: {
      defaultProps: {},
      styleOverrides: {
        list: {
          // '&.MuiList-dense': {
          '.MuiMenuItem-root ': {
            ...theme.typography.buttonRegularSmall,
            '.MuiFormControlLabel-label': {
              ...theme.typography.buttonRegularSmall,
            },
            '.MuiSwitch-root': {
              marginLeft: -5,
            },
          },
          // },
        },
      },
    },

    MuiMenuItem: {
      defaultProps: {
        // dense: true,
      },
      styleOverrides: {
        root: {
          paddingLeft: 24,
          paddingRight: 24,
          height: 36,
          color: theme.styles.menuItem.default.color,
          ...theme.typography.buttonRegularSmall,
          '& .MuiTypography-root': {
            ...theme.typography.buttonRegularSmall,
          },
          backgroundColor:
            theme.styles.paper.filledPrimary.default.backgroundColor,
          transition: 'background-color 500ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',

          '&:hover': {
            backgroundColor: theme.palette.turquoise[40],
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.turquoise[100],
            '&.Mui-focusVisible': {
              backgroundColor: theme.palette.turquoise[100],
            },
          },
          '&+.MuiDivider-root ': {
            // margin: 0,
          },
          '& .MuiListItemIcon-root': {
            minWidth: 0,
            marginRight: 6,
          },
        },
      },
    },
    MuiListItemIcon: {
      defaultProps: {},
      styleOverrides: {
        root: {
          minWidth: 0,
          color: theme.styles.menuItem.default.color,
        },
      },
    },
  };
};

export default Menu;
