import React from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  Alert,
  Card,
  TextField,
  InputAdornment,
  CardContent,
  CardHeader,
  Stack,
  Box,
  Divider,
} from '@dizzbo/ui';
import { WarningIcon } from '@dizzbo/ui';
import { AutoSave } from '@core/components';
import { useFieldErrors } from '@dizzbo/core/hooks';

import { useTourDetail } from '../../../hooks';
import { useUpdateTour } from '../../../queries';
import { CarrierPriceTotal } from './CarrierPriceTotal';

type Props = {
  orientation?: string;
};

export const CarrierPrice: React.FC<Props> = ({ orientation = 'vertical' }) => {
  const { tourData } = useTourDetail();

  const { mutate: mutateTour, isError, error } = useUpdateTour(tourData.uuid);

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    values: {
      price: tourData.price ? tourData.price.toFixed(2) : '',
    },
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
  });

  const { hasFieldError, fieldError } = useFieldErrors(isError, errors, error);

  const onSubmit = (formData: any) => {
    mutateTour(formData, {
      onSuccess: (data: any, values: any) => {},
      onError: (error: any, variables: any, context: any) => {},
    });
  };

  return (
    <Card
      variant="filled-secondary"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
        minHeight: orientation === 'horizontal' ? 0 : 374,
      }}
    >
      <CardHeader title="Carrier Price" variant="small" />
      <CardContent>
        {!tourData.price && (
          <Alert
            title="Carrier price missing"
            variant="standard"
            severity="warning"
            icon={<WarningIcon />}
            sx={{ marginBottom: 6 }}
          />
        )}

        <Stack
          spacing={6}
          divider={<Divider orientation="horizontal" flexItem />}
        >
          <Controller
            name="price"
            control={control}
            defaultValue=""
            rules={{ required: false }}
            render={({ field, fieldState }) => (
              <TextField
                label="Carrier Price"
                required
                type="number"
                error={hasFieldError(field)}
                helperText={fieldError(field)}
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Stack>

        <AutoSave
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          isValid={isValid}
          control={control}
        />
      </CardContent>
      <CardContent>
        {orientation === 'vertical' && <Box sx={{ height: 80 }}></Box>}
      </CardContent>
      <CardContent>
        <CarrierPriceTotal price={getValues('price')} />
      </CardContent>
    </Card>
  );
};
