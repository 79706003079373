import React from 'react';
import { useGetTour, useGetTourOrders } from '../../../queries';

import { Box, LoadingScreen } from '@dizzbo/ui';

import { UUIDType } from '@types';
import { TourDetailCalendar } from './TourDetailCalendar';
type Props = {
  uuid: UUIDType;
};

export const TourDetail: React.FC<Props> = ({ uuid }) => {
  const { data: tourData, isPending } = useGetTour(uuid, 10 * 1000);
  const ordersQueries = useGetTourOrders(tourData?.orders);
  const isLoadingOrders = ordersQueries.some((query) => query.isPending);

  if (isPending || isLoadingOrders) {
    return <LoadingScreen sx={{ position: 'absolute' }} />;
  }

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Box sx={{ padding: 6 }}>
        {ordersQueries?.map((query) => {
          return <Box key={query?.data.uuid}>{query?.data.reference}</Box>;
        })}
      </Box>
      <Box sx={{ height: '100%', width: '100%' }}>
        <TourDetailCalendar tourData={tourData} />
      </Box>
    </Box>
  );
};
