import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';

import { MenuItem, Switch, Tooltip } from '@dizzbo/ui';

import { canHideColumn, getColumnLabel } from './columns';

type Props = {
  columnName: string;
  visible: boolean;
  handleChange: (columnName: string) => void;
};

export const ColumnsSettingsItem: React.FC<Props> = ({
  columnName,
  visible,
  handleChange,
}) => {
  if (!canHideColumn(columnName)) return null;

  return (
    <MenuItem key={columnName} sx={{ minWidth: 290 }}>
      <FormControlLabel
        checked={visible}
        control={
          <Tooltip
            arrow
            enterDelay={1000}
            enterNextDelay={1000}
            title={getColumnLabel(columnName)}
          >
            <Switch />
          </Tooltip>
        }
        label={getColumnLabel(columnName)}
        onChange={() => handleChange(columnName)}
      />
    </MenuItem>
  );
};
