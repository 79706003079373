import { ListSubheader as MuiListSubheader } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiListSubheaderProps = ComponentProps<typeof MuiListSubheader>;

export const ListSubheader = forwardRef<HTMLLIElement, MuiListSubheaderProps>(
  (props, ref) => {
    return <MuiListSubheader {...props} ref={ref} />;
  }
);

ListSubheader.displayName = 'ListSubheader';
