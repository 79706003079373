const Pagination = (theme) => {
  return {
    MuiPaginationItem: {
      defaultProps: {
        sx: {},
      },
      styleOverrides: {
        root: {
          '&.MuiPaginationItem-previousNext': {
            backgroundColor: theme.palette.turquoise[60],
          },
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  };
};

export default Pagination;
