import React from 'react';
import { styled } from '@mui/material/styles';

import { useWorkspaces } from '@dizzbo/core/hooks';
import { Box, Stack, Divider, Skeleton } from '@dizzbo/ui';
import { PersonIcon } from '@dizzbo/ui';

import { Search } from './Search';
import { DateRangeFilter } from './DateRangeFilter';
import { StatusFilter } from './StatusFilter';
import { ContributorsFilter } from './ContributorsFilter';
import { FillterChipList } from './FillterChipList';
import { TodosFilter } from './TodosFilter';
type Props = {};

const ContainerStyles = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  paddingLeft: 24,
  paddingRight: 24,
  height: 56,
  paddingTop: 12,
  paddingBottom: 12,
  backgroundColor: theme.palette.turquoise[10],
  borderBottom: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
}));

export const OrderListSettings: React.FC<Props> = ({}) => {
  const { isLoadingWorkspaces, activeWorkspaceUUID } = useWorkspaces();

  return (
    <>
      <ContainerStyles>
        <Stack
          direction="row"
          spacing={3}
          alignItems="center"
          sx={{ width: '100%' }}
        >
          {isLoadingWorkspaces ? (
            <>
              <Skeleton variant="rounded" sx={{ width: 100, height: 32 }} />
              <Skeleton variant="rounded" sx={{ width: 100, height: 32 }} />
              <Skeleton variant="rounded" sx={{ width: 100, height: 32 }} />
              <Skeleton variant="rounded" sx={{ width: 100, height: 32 }} />
              <Skeleton variant="rounded" sx={{ width: '100%', height: 32 }} />
            </>
          ) : (
            <>
              <Box>
                <DateRangeFilter />
              </Box>
              <Box>
                <StatusFilter />
              </Box>
              <Box>
                <ContributorsFilter />
              </Box>
              <Box>
                <TodosFilter />
              </Box>

              <Divider orientation="vertical" variant="middle" flexItem />

              <Box sx={{ width: '100%' }}>
                {/* https://react.dev/learn/you-might-not-need-an-effect */}
                <Search key={activeWorkspaceUUID} />
              </Box>
            </>
          )}
        </Stack>
      </ContainerStyles>
      <ContainerStyles sx={{ paddingX: 6 }}>
        {isLoadingWorkspaces ? (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Skeleton variant="rounded" sx={{ width: 125, height: 20 }} />
            <Skeleton variant="rounded" sx={{ width: 125, height: 20 }} />
            <Skeleton variant="rounded" sx={{ width: 125, height: 20 }} />
          </Stack>
        ) : (
          <FillterChipList
            chipsConfig={{
              team: {
                icon: <PersonIcon />,
              },
              department: {
                icon: <PersonIcon />,
              },
              assignee: {
                icon: <PersonIcon />,
              },
            }}
          />
        )}
      </ContainerStyles>
    </>
  );
};
