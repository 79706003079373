import { CardActions as MuiCardActions } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiCardActionsProps = ComponentProps<typeof MuiCardActions>;

export const CardActions = forwardRef<HTMLDivElement, MuiCardActionsProps>(
  (props, ref) => <MuiCardActions ref={ref} {...props} />
);

CardActions.displayName = 'CardActions';
