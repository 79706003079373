import { DialogTitle as MuiDialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { ComponentProps, forwardRef } from 'react';

interface DialogTitlePropsOverrides {
  onClose?: () => void;
}

type MuiDialogTitleProps = ComponentProps<typeof MuiDialogTitle>;

type DialogTitleProps = MuiDialogTitleProps & DialogTitlePropsOverrides;

import { Box } from '../Box';
import { MinimalIconButton } from '../MinimalIconButton';
import { CloseIcon } from '../icons';

const DialogTitleStyles = styled(MuiDialogTitle)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const DialogTitleContentStyles = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
}));

const DialogTitleActionStyles = styled(Box)(() => ({}));

export const DialogTitle = forwardRef<HTMLDivElement, DialogTitleProps>(
  (props, ref) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitleStyles {...other} ref={ref}>
        <DialogTitleContentStyles>{children}</DialogTitleContentStyles>
        <DialogTitleActionStyles>
          {onClose && (
            <MinimalIconButton
              variant="primary"
              aria-label="close"
              onClick={onClose}
            >
              <CloseIcon />
            </MinimalIconButton>
          )}
        </DialogTitleActionStyles>
      </DialogTitleStyles>
    );
  }
);

DialogTitle.displayName = 'DialogTitle';
