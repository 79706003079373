import React from 'react';
import Box from '@mui/material/Box';
import { FilterTextField } from './FilterTextField';
import { type Header, type TableInstance } from '../types';

interface Props {
  header: Header;
  table: TableInstance;
}

export const FilterRangeFields = ({ header, table }: Props) => {
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem' }}>
      <FilterTextField header={header} rangeFilterIndex={0} table={table} />
      <FilterTextField header={header} rangeFilterIndex={1} table={table} />
    </Box>
  );
};
