import { deleteOrderContact } from '@core/api';
import { QueryKeys } from '@core/config';
import { styled, useTheme } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React from 'react';
import { toast } from 'react-toastify';

import {
  AvatarListItem,
  Box,
  Card,
  Chip,
  ErrorIcon,
  IconButton,
  ListItemIcon,
  ListItemText,
  MailIcon,
  Menu,
  MenuItem,
  MoreVerticalIcon,
  PhoneIcon,
  Stack,
  Typography,
} from '@dizzbo/ui';
import { CustomerContactType, UUIDType } from '@types';
import { useTranslation } from 'react-i18next';

const ParticipantsListItemWrapperStyles = styled(Card)(({ theme }) => ({
  display: 'flex',
  backgroundColor: 'transparent',
  position: 'relative',
  width: '100%',

  alignItems: 'center',
}));

type Props = {
  contactData: CustomerContactType;
  orderUUID?: UUIDType;
};

export const ContactListItem: React.FC<Props> = ({
  contactData,
  orderUUID,
}: Props) => {
  const queryClient = useQueryClient();
  const theme = useTheme();

  const { displayName, email, phone, mobilePhone, fax } = contactData.contact;
  const { contactType, uuid: contactUUID } = contactData;

  const { mutate: mutateContact }: any = useMutation({
    mutationKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.CONTACTS],
    mutationFn: (values) => deleteOrderContact(orderUUID, contactUUID),
  });

  const { t } = useTranslation();

  const handleDelete = (popupState) => {
    mutateContact(null, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.CONTACTS],
        });
        toast.success(`Contact removed.`);
        popupState.close();
      },
      onError: () => {
        toast.error(`Couldn't remove Contact.`);
        popupState.close();
      },
    });
  };

  return (
    <ParticipantsListItemWrapperStyles>
      <Stack alignItems="start">
        <AvatarListItem
          username={displayName}
          avatarSx={{
            backgroundColor: theme.palette.oxford[20],
            color: theme.palette.turquoise[20],
            width: 32,
            height: 32,
            ...theme.typography.bodyBoldSmall,
          }}
        />
        <Stack pl={10} mt={-1}>
          <Stack direction="row" spacing={2} mb={1}>
            {email && (
              <Box display="flex" alignItems="center">
                <MailIcon sx={{ width: 16, height: 16, marginRight: 1 }} />
                <Typography variant="textLinkRegularSmall">{email}</Typography>
              </Box>
            )}
            {phone && (
              <Box display="flex" alignItems="center">
                <PhoneIcon sx={{ width: 16, height: 16, marginRight: 1 }} />
                <Typography variant="textLinkRegularSmall">{phone}</Typography>
              </Box>
            )}
            {mobilePhone && (
              <Box display="flex" alignItems="center">
                <PhoneIcon sx={{ width: 16, height: 16, marginRight: 1 }} />
                <Typography variant="textLinkRegularSmall">
                  {mobilePhone}
                </Typography>
              </Box>
            )}
            {fax && (
              <Box display="flex" alignItems="center">
                <PhoneIcon sx={{ width: 16, height: 16, marginRight: 1 }} />
                <Typography variant="textLinkRegularSmall">{fax}</Typography>
              </Box>
            )}
          </Stack>
          <Stack direction="row" spacing={2}>
            <Chip
              label={`${contactType} contact`}
              variant="outlined"
              size="small"
              sx={{ marginRight: 1 }}
            />
          </Stack>
        </Stack>
      </Stack>
      {orderUUID && (
        <Stack direction="row" alignItems="center" sx={{ marginLeft: 'auto' }}>
          <PopupState variant="popover" popupId="participant-menu">
            {(popupState) => (
              <React.Fragment>
                <IconButton
                  size="medium"
                  variant="tertiary"
                  {...bindTrigger(popupState)}
                >
                  <MoreVerticalIcon />
                </IconButton>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem onClick={() => handleDelete(popupState)}>
                    <ListItemIcon>
                      <ErrorIcon sx={{ width: 16, height: 16 }} />
                    </ListItemIcon>
                    <ListItemText>{`${t('remove')} ${displayName}`}</ListItemText>
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        </Stack>
      )}
    </ParticipantsListItemWrapperStyles>
  );
};
