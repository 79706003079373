import { OrderType, UUIDType } from '@types';
import React, { createContext, PropsWithChildren, useMemo } from 'react';

const PlannerContext = createContext(null);

PlannerContext.displayName = 'PlannerContext';

type ProviderProps = PropsWithChildren<{
  orderData: OrderType;
  children: React.ReactNode;
}>;

type Props = {
  orderData: OrderType;
  orderUUID: UUIDType;
  routeUUID: UUIDType;
};
function PlannerProvider({ children, orderData }: ProviderProps) {
  const value = useMemo<Props>(
    () => ({
      orderData,
      orderUUID: orderData.uuid,
      routeUUID: orderData.route,
    }),
    [orderData]
  );

  return (
    <PlannerContext.Provider value={value}>{children}</PlannerContext.Provider>
  );
}

export { PlannerContext, PlannerProvider };
