import { CardHeader as MuiCardHeader } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiCardHeaderProps = ComponentProps<typeof MuiCardHeader>;

interface CardHeaderPropsOverrides {
  variant?: 'small' | 'medium';
}

type CardHeaderProps = Omit<MuiCardHeaderProps, 'variant'> &
  CardHeaderPropsOverrides;

export const CardHeader = forwardRef<HTMLDivElement, CardHeaderProps>(
  (props, ref) => {
    return <MuiCardHeader ref={ref} {...props} />;
  }
);

CardHeader.displayName = 'CardHeader';
