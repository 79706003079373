import { OrderType, UUIDType } from '@types';
import { useContext } from 'react';
import { PlannerContext } from '../contexts';

type Props = {
  orderData: OrderType;
  orderUUID: UUIDType;
  routeUUID: UUIDType;
  isFieldLocked: (fieldName: string) => boolean;
};

export const useOrderDetail = () => useContext<Props>(PlannerContext);
