import { getOrderDeliveryList } from '@core/api';
import { QueryKeys } from '@core/config';
import { useQueries } from '@tanstack/react-query';
import { OrderType } from '@types';

export const useGetOrderDeliveries = (orders: OrderType[]) => {
  orders = orders || [];
  return useQueries({
    queries: orders.map((order) => {
      return {
        queryKey: [QueryKeys.DELIVERIES, order.uuid],
        queryFn: () => getOrderDeliveryList(order.uuid),
      };
    }),
  });
};
