import { styled } from '@mui/material/styles';
import React from 'react';

import { Box, Typography } from '@dizzbo/ui';
import { TourType } from '@types';
import { DetailSummary } from './DetailSummary';

const DetailWrapperStyles = styled(Box)(({ theme }) => ({
  overflow: 'auto',
  height: '100%',
}));

type Props = {
  tour: TourType;
};

export const Detail: React.FC<Props> = ({ tour }) => {
  return (
    <DetailWrapperStyles>
      <Typography variant="h4" p={6}>
        Dizzbo Tour {tour.reference}
      </Typography>
      <DetailSummary tour={tour} />
    </DetailWrapperStyles>
  );
};
