import { axiosAuthenticatedRequest } from '@dizzbo/core/api';
import { RouteSectionType, RouteType, UUIDType } from '@types';

type MoveRouteStopPayload = {
  stop: UUIDType;
  position: number;
};

type DeleteWaypointPayload = {
  uuid: UUIDType;
  sectionUUID: UUIDType;
};

type CreateAndUpdateWaypointPayload = {
  lat: number;
  lng: number;
  uuid: UUIDType;
  sectionUUID: UUIDType;
};

export const getRoute = async (routeUUID: UUIDType): Promise<RouteType> => {
  const res = await axiosAuthenticatedRequest.get<RouteType>(
    `/v2/routes/${routeUUID}/`
  );

  return res.data;
};

export const updateRoute = async (
  routeUUID: UUIDType,
  data: RouteType
): Promise<RouteType> => {
  const res = await axiosAuthenticatedRequest.patch<RouteType>(
    `/v2/routes/${routeUUID}/`,
    data
  );
  return res.data;
};

export const getRouteSection = async (
  routeUUID: UUIDType,
  sectionUUID: UUIDType
): Promise<RouteSectionType> => {
  const res = await axiosAuthenticatedRequest.get<RouteSectionType>(
    `/v2/routes/${routeUUID}/sections/${sectionUUID}/`
  );

  return res.data;
};

export const moveRouteStop = async (
  routeUUID: UUIDType,
  data: MoveRouteStopPayload
): Promise<unknown> => {
  const res = await axiosAuthenticatedRequest.patch(
    `/v2/routes/${routeUUID}/move-stop/`,
    data
  );
  return res.data;
};

export const deleteWaypoint = async (
  data: DeleteWaypointPayload
): Promise<unknown> => {
  const res = await axiosAuthenticatedRequest.delete(
    `/v2/sections/${data.sectionUUID}/waypoints/${data.uuid}/`
  );
  return res.data;
};

export const updateWaypoint = async (
  data: CreateAndUpdateWaypointPayload
): Promise<unknown> => {
  const res = await axiosAuthenticatedRequest.patch(
    `/v2/sections/${data.sectionUUID}/waypoints/${data.uuid}/`,
    { lat: data.lat, lng: data.lng }
  );
  return res.data;
};

export const createWaypoint = async (
  data: CreateAndUpdateWaypointPayload
): Promise<unknown> => {
  const res = await axiosAuthenticatedRequest.post(
    `/v2/sections/${data.sectionUUID}/waypoints/`,
    { uuid: data.uuid, lat: data.lat, lng: data.lng }
  );
  return res.data;
};
