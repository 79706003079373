import React from 'react';

import { Stack } from '@dizzbo/ui';

import { RequirementSelect } from './RequirementSelect';
import { RequirementsDisplay } from './RequirementsDisplay';

import { useOrderDetail } from '../../../hooks';

type Props = {};

export const Requirements: React.FC<Props> = () => {
  const { orderData } = useOrderDetail();
  const {
    uuid,
    podRequired,
    podRequiredDefault,
    paperDocumentsRequired,
    paperDocumentsRequiredDefault,
    neutralDelivery,
    palletExchange,
    transportInsuranceValue,
  } = orderData;

  return (
    <Stack spacing={3} direction="row" flexWrap="wrap" useFlexGap>
      <RequirementSelect
        orderUUID={uuid}
        fieldName="podRequired"
        value={podRequired}
        presetValue={podRequiredDefault}
        label="POD Required:"
      />
      <RequirementSelect
        orderUUID={uuid}
        fieldName="paperDocumentsRequired"
        value={paperDocumentsRequired}
        presetValue={paperDocumentsRequiredDefault}
        label="Original Documents required:"
      />
      <RequirementsDisplay label="Neutral Delivery:" value={neutralDelivery} />
      <RequirementsDisplay label="Pallet Exchange:" value={palletExchange} />
      <RequirementsDisplay
        label="Transport insurance:"
        value={!!transportInsuranceValue}
      />
    </Stack>
  );
};
