import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { translations } from './locales';

const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator'],
  caches: ['localStorage'],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: DETECTION_OPTIONS,
    resources: translations,
    fallbackLng: 'en',
    debug: false,
    // keySeparator: false, // we do not use keys in form messages.welcome
    // interpolation: {
    //   escapeValue: false, // react already safes from xss
    // },
  });

export default i18n;
