const TextField = (theme) => {
  return {
    MuiTextField: {
      defaultProps: {
        size: 'small',
        variant: 'filled',
      },
      styleOverrides: {
        root: {
          // Styling the EndAdornment Typography if the input is filled
          // Styles for focused are also applied via Input Theme Overrides
          '.MuiFormLabel-filled + .MuiInputBase-root': {
            '.MuiInputAdornment-root': {
              '.MuiTypography-root': {
                ...theme.typography.bodyRegular,
                color: theme.palette.primary.dark,
                transform: 'translate(0px, 7px) scale(1)',
              },
            },
          },
        },
      },
    },
  };
};

export default TextField;
