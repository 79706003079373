const Avatar = (theme) => {
  return {
    MuiAvatar: {
      defaultProps: {},
      styleOverrides: {
        root: {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.secondary.main,
        },
      },
    },
  };
};

export default Avatar;
