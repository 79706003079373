import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const QuickPayIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M12.8293 12.752H12L12.1873 12.2943L12.749 12.2943C12.7401 12.1962 12.7312 12.0981 12.7312 12C12.7312 11.8937 12.7401 11.7956 12.749 11.6975H12L12.1873 11.2316H12.8293C13.1949 9.91553 14.4611 9 16.0217 9C17.3771 9 18.5006 9.66213 19 10.8147H17.6C17.2166 10.3161 16.6904 10.1035 16.0306 10.1035C15.1121 10.1035 14.4166 10.5613 14.1223 11.2316H17.2968L17.1185 11.6975H13.9885C13.9707 11.7956 13.9618 11.8937 13.9618 12C13.9618 12.0981 13.9707 12.1962 13.9796 12.2943H16.8866L16.7172 12.752H14.1134C14.4076 13.4305 15.1032 13.8965 16.0306 13.8965C16.6904 13.8965 17.2166 13.6839 17.6 13.1853H19C18.5006 14.3379 17.3771 15 16.0217 15C14.4611 15 13.1771 14.0763 12.8293 12.752Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.28988 14C9.10128 13.3663 9 12.695 9 12L3 12V11L9.07089 11C9.1719 10.2939 9.37829 9.62171 9.67363 9L1 9V8L10.2547 8C11.5196 6.18652 13.6213 5 16 5C19.866 5 23 8.13401 23 12C23 15.866 19.866 19 16 19C13.2076 19 10.7971 17.3649 9.67363 15H6V14H9.28988ZM16 7C13.2386 7 11 9.23858 11 12C11 14.7614 13.2386 17 16 17C18.7614 17 21 14.7614 21 12C21 9.23858 18.7614 7 16 7Z"
        />
      </SvgIcon>
    );
  }
);

QuickPayIcon.displayName = 'QuickPayIcon';

export default QuickPayIcon;
