import React, { useState, useEffect } from 'react';
import { usePrevious } from 'react-use';
import { DndContext, closestCenter } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useQueryClient } from '@tanstack/react-query';

import { QueryKeys } from '@core/config';
import { useGetOrderStops, useMoveRouteStop } from '@core/hooks';

import { StopAddItem } from './StopAddItem';
import { SortableItem } from './SortableItem';

import { useOrderDetail } from '../../hooks';

type Props = {
  setDisplayAddStopItem: (value: boolean) => void;
  displayAddStopItem: boolean;
};

export const StopList: React.FC<Props> = ({
  setDisplayAddStopItem,
  displayAddStopItem,
}: Props) => {
  const { orderData } = useOrderDetail();
  const { uuid: orderUUID, route: routeUUID } = orderData;
  const { data: stopsData, isPending } = useGetOrderStops(orderUUID);
  const { mutateAsync } = useMoveRouteStop(routeUUID, orderUUID);
  const queryClient = useQueryClient();

  /*
   * these local stops are used to display the stops in the UI
   * so that changes via drag and drop are reflected immediately
   */
  const [localStops, setLocalStops] = useState(stopsData || []);

  useEffect(() => {
    if (stopsData) {
      setLocalStops(stopsData);
    }
  }, [stopsData]);

  const previousStops = usePrevious(stopsData);

  useEffect(() => {
    if (stopsData) {
      const newStopAdded = previousStops?.length < stopsData.length;
      if (newStopAdded && stopsData.length > 1) {
        setDisplayAddStopItem(false);
        return;
      }
      // insufficent stops
      if (stopsData.length < 2) {
        setDisplayAddStopItem(true);
      }
    }
  }, [previousStops, stopsData]);

  const handleDragEnd = async (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldStopIndex = localStops
        .map(function (e) {
          return e.uuid;
        })
        .indexOf(active.id);

      const newStopIndex = localStops
        .map(function (e) {
          return e.uuid;
        })
        .indexOf(over.id);

      const orderedStops = arrayMove(localStops, oldStopIndex, newStopIndex);

      setLocalStops(orderedStops);
      try {
        await mutateAsync({ stop: active.id, position: newStopIndex });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ROUTES, routeUUID],
        });
      } catch {}
    }
  };

  return (
    <>
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext
          items={localStops.map((s) => s.uuid)}
          strategy={verticalListSortingStrategy}
        >
          {/* {isPending && (
            <Skeleton variant="rounded" sx={{ width: 100, height: 100 }} />
          )} */}

          {localStops.map((stop, index) => (
            <SortableItem
              key={stop.uuid}
              stop={stop}
              index={index}
              lastItem={
                localStops.length === index + 1 &&
                !displayAddStopItem &&
                localStops.length !== 1
              }
            />
          ))}
        </SortableContext>
        {/* Add Stop Items */}
        {localStops.length === 0 && <StopAddItem index={0} />}
        {displayAddStopItem && <StopAddItem index={localStops.length || 1} />}
      </DndContext>
    </>
  );
};

export default StopList;
