import React, { ReactNode } from 'react';

import { CheckIcon, CloseIcon, TableInstance } from '@dizzbo/ui';
import { OrderType } from '@types';

type Props = {
  renderedCellValue: ReactNode;
  table: TableInstance<OrderType>;
};

const iconDimensions = { width: 16, height: 16 };

export const BooleanCell: React.FC<Props> = ({
  renderedCellValue: checked,
}: Props) =>
  checked ? (
    <CheckIcon sx={iconDimensions} color="success" />
  ) : (
    <CloseIcon sx={iconDimensions} color="error" />
  );
