import { OrderType, UUIDType } from '@types';
import PropTypes from 'prop-types';
import React, { createContext, PropsWithChildren, useMemo } from 'react';

const OrderDetailContext = createContext(null);

OrderDetailContext.displayName = 'OrderDetailContext';

type ProviderProps = PropsWithChildren<{
  orderData: OrderType;
}>;

type Props = {
  orderData: OrderType;
  orderUUID: UUIDType;
  routeUUID: UUIDType;
  isFieldLocked: (fieldName: string) => boolean;
};

const lockedFieldsByState = {
  DRAFT: {
    price: true,
  },
};

function OrderDetailProvider({ children, orderData }: ProviderProps) {
  const isFieldLocked = (fieldName: string): boolean => {
    return lockedFieldsByState[orderData.status]?.[fieldName];
  };

  const value = useMemo<Props>(
    () => ({
      orderData,
      orderUUID: orderData.uuid,
      routeUUID: orderData.route,
      isFieldLocked,
    }),
    [orderData]
  );

  return (
    <OrderDetailContext.Provider value={value}>
      {children}
    </OrderDetailContext.Provider>
  );
}

OrderDetailProvider.propTypes = {
  children: PropTypes.node,
};

export { OrderDetailContext, OrderDetailProvider };
