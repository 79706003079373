import { useQuery, useQueryClient } from '@tanstack/react-query';
import { PopupState } from 'material-ui-popup-state/hooks';
import React from 'react';

import { getOrderProofOfDelivery, getOrderTodoTasks } from '@core/api';
import { QueryKeys } from '@core/config';

import { ProofOfDeliveryReviewDialog } from '@order-detail/components/OrderDetailForm/ProofOfDeliveryDocuments/ProofOfDeliveryReviewDialog';
import { OrderSubtodoType, OrderTodoType, OrderType } from '@types';

type Props = {
  orderData: OrderType;
  popupState: PopupState;
  todo: OrderTodoType;
};

export const PoDReviewDialog: React.FC<Props> = ({
  orderData,
  popupState,
  todo,
}: Props) => {
  const { uuid: orderUUID } = orderData;
  const queryClient = useQueryClient();

  const { isPending, data: todoData } = useQuery({
    queryKey: [QueryKeys.ORDERS, todo],
    queryFn: () => getOrderTodoTasks(orderUUID, todo),
    enabled: popupState.isOpen,
  });

  let podReviewTodo = undefined;

  if (todoData && !isPending) {
    podReviewTodo = todoData.find(
      (todo: OrderSubtodoType) => todo.type === 'transports.proofofdelivery'
    );
  }

  const { isPending: isPendingPoD, data: podData } = useQuery({
    queryKey: [QueryKeys.ORDERS, podReviewTodo?.uuid],
    queryFn: () => getOrderProofOfDelivery(orderUUID, podReviewTodo?.uuid),
    enabled: !!podReviewTodo,
  });

  if (isPendingPoD || !podData) return null;

  const { uuid: podUUID, file, createdAt, title } = podData;

  const handleSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.ORDERS],
    });
  };

  return (
    <ProofOfDeliveryReviewDialog
      podUUID={podUUID}
      orderUUID={orderUUID}
      popupState={popupState}
      file={file}
      fileTitle={title}
      fileDate={createdAt}
      onSuccess={handleSuccess}
    />
  );
};
