import React from 'react';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import { SxProps } from '@mui/system';

import { Box } from '@dizzbo/ui';

const ContentStyles = styled(Box)(({ theme }) => ({
  width: '100%',
  minWidth: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: 64,
  backgroundColor: theme.palette.oxford[30],
  paddingTop: 4,
  paddingRight: 16,
  paddingBottom: 4,
  paddingLeft: 16,
}));

const StopsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

const StopStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'last',
})<any>(({ theme, last }) => {
  return {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    textAlign: last ? 'right' : 'left',
  };
});

const DateStyles = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ...theme.typography.bodyBoldSmall,
  // need to override fontweight important because the scheduler css is doing weird wildcard styling on all children
  fontWeight: `${theme.typography.bodyBoldSmall.fontWeight} !important`,
}));

export type Props = {
  sx?: SxProps;
  eventRecord?: any;
  resourceRecord?: any;
};

export const DeliveryItem = ({ eventRecord }: Props) => {
  const { startDate, endDate } = eventRecord;

  return (
    <ContentStyles>
      <StopsContainer>
        <StopStyles>
          <DateStyles>{dayjs(startDate).format('hh:mm')}</DateStyles>
        </StopStyles>
        <StopStyles last>
          <DateStyles>{dayjs(endDate).format('hh:mm')}</DateStyles>
        </StopStyles>
      </StopsContainer>
    </ContentStyles>
  );
};
