import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DateIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M6 2H8V6H6V2Z" />
        <path d="M3 3H5V19H19V3H21V21H3V3Z" />
        <path d="M15 5H9V3H15V5Z" />
        <path d="M7 9V11H9V9H7Z" />
        <path d="M9 12V14H7V12H9Z" />
        <path d="M7 15V17H9V15H7Z" />
        <path d="M11 9H13V11H11V9Z" />
        <path d="M13 12H11V14H13V12Z" />
        <path d="M11 15H13V17H11V15Z" />
        <path d="M17 11V9H15V11H17Z" />
        <path d="M15 14V12H17V14H15Z" />
        <path d="M17 17V15H15V17H17Z" />
        <path d="M18 2H16V6H18V2Z" />
      </SvgIcon>
    );
  }
);

DateIcon.displayName = 'DateIcon';

export default DateIcon;
