import { useIsMutating, useQuery } from '@tanstack/react-query';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React, { useEffect, useRef } from 'react';

import { getOrderTodoTasks } from '@core/api';
import { QueryKeys } from '@core/config';
import { BannerTodoItem, Skeleton } from '@dizzbo/ui';
import { OrderTodoType, OrderType, TourType } from '@types';

type Props = {
  todo: object;
  todoKey: OrderTodoType;
  orderData: OrderType;
  tourData: TourType;
};

export const TodoItem: React.FC<Props> = ({
  todo,
  todoKey,
  orderData,
  tourData,
}) => {
  const { assignee, uuid: orderUUID } = orderData;
  const handleClick = () => {
    popupState.open();
  };

  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'todoDialog',
  });

  const Dialog = todo.dialogComponent;

  const prevMutationCountRef = useRef<number>(0);

  const {
    data: orderTodosData,
    isPending,
    refetch,
  } = useQuery({
    queryKey: [QueryKeys.ORDERS, orderUUID, todoKey],
    queryFn: () => getOrderTodoTasks(orderUUID, todoKey),
  });

  const isMutatingOrderCount = useIsMutating({
    mutationKey: [QueryKeys.ORDERS, orderUUID],
  });

  useEffect(() => {
    refetch();

    prevMutationCountRef.current = isMutatingOrderCount;
  }, [isMutatingOrderCount]);

  return (
    <>
      {isPending ? (
        <Skeleton width="100%" height={56} />
      ) : (
        <BannerTodoItem
          username={assignee?.displayName}
          label={todo.label}
          disableAction={todo.disableAction}
          buttonLabel={todo.buttonLabel}
          buttonAction={handleClick}
          subTodos={orderTodosData}
        />
      )}
      <Dialog
        popupState={popupState}
        orderData={orderData}
        tourData={tourData}
        orderTodosData={orderTodosData}
      />
    </>
  );
};
