import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CardSubHeader,
  ErrorIcon,
  IconButton,
  ListItemIcon,
  ListItemText,
  LoadIcon,
  Menu,
  MenuItem,
  MoreVerticalIcon,
  Stack,
} from '@dizzbo/ui';

import { deleteOrderDelivery } from '@core/api';
import { QueryKeys } from '@core/config';

import { UUIDType } from '@types';
import { useOrderDetail } from '../../hooks';

const ActionsStyles = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'status',
})<any>(({ theme, status }) => {
  return { marginLeft: 'auto !important' };
});

type Props = {
  deliveryUUID: UUIDType;
  deliveryIndex: number;
  setActiveTab: (value: number) => void;
};

export const DeliveryDetailHeader: React.FC<Props> = ({
  deliveryUUID,
  deliveryIndex,
  setActiveTab,
}): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { orderData } = useOrderDetail();
  const { uuid: orderUUID, reference: orderReference } = orderData;

  const { mutate: mutateDelivery }: any = useMutation({
    mutationKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.DELIVERIES],
    mutationFn: () => deleteOrderDelivery(orderUUID, deliveryUUID),
    onSuccess: (data, values) => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.DELIVERIES],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.STOPS],
      });
      setActiveTab(0);
    },
    onError: (error, variables, context) => {},
  });

  const handleDelete = (popupState) => {
    mutateDelivery();
    popupState.close();
  };

  return (
    <CardSubHeader
      title={`${t('delivery')} ${orderReference}-${deliveryIndex}`}
      icon={<LoadIcon />}
    >
      <ActionsStyles>
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <IconButton
                size="medium"
                variant="tertiary"
                {...bindTrigger(popupState)}
              >
                <MoreVerticalIcon />
              </IconButton>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => handleDelete(popupState)}>
                  <ListItemIcon>
                    <ErrorIcon sx={{ width: 16, height: 16 }} />
                  </ListItemIcon>
                  <ListItemText>{t('deleteDelivery')}</ListItemText>
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      </ActionsStyles>
    </CardSubHeader>
  );
};
