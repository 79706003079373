import React from 'react';

import {
  CheckBoxCheckedIcon,
  CheckBoxDefaultIcon,
} from '../../components/icons';

const Checkbox = (theme) => {
  return {
    MuiCheckbox: {
      defaultProps: {
        checkedIcon: <CheckBoxCheckedIcon />,
        icon: <CheckBoxDefaultIcon />,
      },
      styleOverrides: {
        root: {
          padding: 0,
          paddingRight: 8,
          '.MuiSvgIcon-root': {
            width: 16,
            height: 16,
          },
        },
      },
    },
  };
};

export default Checkbox;
