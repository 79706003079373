import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ChevronDownIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 17.1213L21.7071 7.41421L20.2928 6L12 14.2929L3.70706 6L2.29285 7.41421L12 17.1213Z"
        />
      </SvgIcon>
    );
  }
);

ChevronDownIcon.displayName = 'ChevronDownIcon';

export default ChevronDownIcon;
