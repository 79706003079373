import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const LoadIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M5 5H19V17H21V3H3L3 17H5V5Z" />
        <path d="M22 22H19V20H14V22H10V20H5V22H2V18H22V22Z" />
      </SvgIcon>
    );
  }
);

LoadIcon.displayName = 'LoadIcon';

export default LoadIcon;
