import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';

import { useAuth } from '@dizzbo/core/hooks';
import { Avatar, Box, Typography } from '@dizzbo/ui';
import { SwitchIcon } from '@dizzbo/ui';
import { AppBarUserMenu } from './AppBarUserMenu';

const NavItemWrapperStyles = styled(Box)(({ theme }) => ({
  paddingTop: 12,
  paddingBottom: 12,
  borderTop: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
  height: 64,
}));

const NavItemStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: 40,
  minHeight: 40,
  padding: 0,
  textAlign: 'left',
  justifyContent: 'start',
  overflowX: 'hidden',
  ...theme.typography.buttonRegular,
  color: theme.palette.turquoise[100],
  textDecoration: 'none',
  backgroundColor: 'transparent',
  transition: theme.transitions.create('background'),

  '&.active': {
    color: theme.palette.green[100],
    backgroundColor: theme.palette.turquoise[200],
  },
}));

const IconWrapperStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})<any>(({ theme, expanded }) => {
  return {
    marginLeft: 12,
    marginRight: 12,
    height: 40,
    width: 40,
  };
});

interface AppBarNavItemUserProps {
  expanded: boolean;
}

export const AppBarNavItemUser = ({ expanded }: AppBarNavItemUserProps) => {
  const theme = useTheme();
  const { user } = useAuth();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'userMenu',
  });

  return (
    <NavItemWrapperStyles>
      {user && (
        <React.Fragment>
          <NavItemStyles
            {...bindTrigger(popupState)}
            sx={{
              cursor: 'pointer',
              transition: theme.transitions.create(['opacity']),
              opacity: anchorElUser ? 0.7 : 1,
            }}
          >
            <IconWrapperStyles>
              <Avatar
                username={user.displayName}
                sx={{ width: 40, height: 40 }}
              />
            </IconWrapperStyles>
            <AnimatePresence>
              {expanded && (
                <motion.div
                  key='AppBarNavItemContent'
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  style={{ width: '100%' }}
                >
                  <Box
                    sx={{
                      mr: 3,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      whiteSpace: 'nowrap',
                      overflowX: 'hidden',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        whiteSpace: 'nowrap',
                        overflowX: 'hidden',
                      }}
                    >
                      <Typography
                        variant='textLinkRegularSmall'
                        mr={2}
                        sx={{ width: '100%' }}
                      >
                        {user.displayName}{' '}
                      </Typography>
                      <SwitchIcon
                        sx={{
                          width: 16,
                          height: 16,
                          transition: theme.transitions.create(['transform']),
                          transform: anchorElUser
                            ? 'rotate(180deg)'
                            : 'rotate(0deg)',
                        }}
                      />
                    </Box>
                    <Typography variant='tiny'>Dizzbo GmbH</Typography>
                  </Box>
                </motion.div>
              )}
            </AnimatePresence>
          </NavItemStyles>
          <AppBarUserMenu popupState={popupState} />
        </React.Fragment>
      )}
    </NavItemWrapperStyles>
  );
};
