import { QueryKeys } from '@core/config';
import { styled } from '@mui/system';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { toast } from 'react-toastify';

import { LoadingPointAutocomplete } from '@core/components';
import { Box, IconButton, RemoveIcon, Stack } from '@dizzbo/ui';
import { LoadingPointBullet } from './LoadingPointBullet';

import { useCreateOrderStop } from '@core/hooks';
import { LoadingPointType } from '@types';
import { useOrderDetail } from '../hooks';

type Props = {
  index: number;
  lastItem?: boolean;
};

const StopContainerStyles = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isHovering' && prop !== 'isDragging',
})<any>(({ theme, isHovering, isDragging }) => {
  return {
    paddingTop: 12,
    paddingBottom: 8,
    paddingRight: 24,
    paddingLeft: 24,
    height: '100%',
    backgroundColor: theme.styles.planner.stopListItem.default.backgroundColor,
    '&:hover': {
      backgroundColor: theme.styles.planner.stopListItem.hover.backgroundColor,
    },
  };
});

const BulletContainerStyles = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'lastItem',
})<any>(({ theme, lastItem }) => {
  if (lastItem) {
    return {
      height: '100%',
      position: 'relative',
    };
  }
  return {
    height: '100%',
    position: 'relative',
    minHeight: 75,
    '&::after': {
      content: "' '",
      position: 'absolute',
      top: 52,
      bottom: 0,
      left: '50%',
      border: '1px dashed #404E64',
    },
  };
});

export const AddStopItem = React.forwardRef<HTMLDivElement, Props>(
  ({ index, lastItem = true }, ref) => {
    const { orderData } = useOrderDetail();
    const { uuid: orderUUID, route: routeUUID } = orderData;
    const { mutateAsync } = useCreateOrderStop(orderUUID);
    const queryClient = useQueryClient();

    const handleAutocompleteOnChange = async (
      loadingPoint: LoadingPointType
    ) => {
      try {
        await mutateAsync({ loadingPoint: loadingPoint.uuid });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ROUTES, routeUUID],
        });
      } catch (error) {
        console.error('error', error);
        toast.error(`An error occurred.`);
      }
    };

    return (
      <Box sx={{ backgroundColor: '#FFFFFF' }} ref={ref}>
        <StopContainerStyles direction="row">
          <BulletContainerStyles lastItem={lastItem}>
            <LoadingPointBullet index={index} />
          </BulletContainerStyles>
          <Stack
            spacing={4}
            direction="column"
            ml={3}
            sx={{
              display: 'inline',
              width: '100%',
            }}
          >
            <Stack direction="row" width="100%">
              <LoadingPointAutocomplete
                label="Stop"
                onChange={handleAutocompleteOnChange}
                value={null}
                sx={{ width: '100%' }}
                disableClearable={true}
                disablePopupIcon={true}
              />
              <IconButton
                size="large"
                variant="tertiary"
                color="success"
                sx={{ marginLeft: 2 }}
              >
                <RemoveIcon />
              </IconButton>
            </Stack>
          </Stack>
        </StopContainerStyles>
      </Box>
    );
  }
);

AddStopItem.displayName = 'AddStopItem';

export default AddStopItem;
