const Slider = (theme) => {
  return {
    MuiSlider: {
      defaultProps: {
        sx: {},
      },
      styleOverrides: {
        root: {
          height: 6,
        },
        rail: {
          opacity: 1,
          backgroundColor: theme.styles.slider.default.rail.backgroundColor,
        },
        track: {
          border: 0,
          backgroundColor: theme.styles.slider.default.track.backgroundColor,
        },
        colorPrimary: {
          color: theme.styles.slider.default.color,
        },
        thumbColorPrimary: {
          backgroundColor: theme.styles.slider.default.thumb.backgroundColor,
        },
        thumb: {
          backgroundImage: theme.styles.slider.default.thumb.backgroundImage,
        },
      },
    },
  };
};

export default Slider;
