import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PinLeftIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M4 3L4 21H2V3H4Z" />
        <path d="M5 11.9999L9.75813 7L11.1877 8.50218L8.81083 10.9998L21 10.9998V12.9998L8.81063 12.9998L11.1877 15.4976L9.75813 16.9998L5 11.9999Z" />
      </SvgIcon>
    );
  }
);

PinLeftIcon.displayName = 'PinLeftIcon';

export default PinLeftIcon;
