import { useTheme } from '@mui/material/styles';
import { UseQueryResult } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  CircularProgress,
  DocumentIcon,
  MapIcon,
  Tab,
  Tabs,
} from '@dizzbo/ui';
import { OrderType } from '@types';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

type Props = {
  activeTab: number;
  setActiveTab: (number: number) => void;
  ordersQueries: UseQueryResult<OrderType>[];
};

export const TourTabs: React.FC<Props> = ({
  activeTab,
  setActiveTab,
  ordersQueries,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  const isLoadingOrders = ordersQueries.some((query) => query.isPending);

  return (
    <Box
      sx={{
        transition: theme.transitions.create('border '),
        backgroundColor: theme.styles.common.pageBackground,
        paddingLeft: 3,
        position: 'relative',
        '&::after': {
          pointerEvents: 'none',
          content: "' '",
          display: 'block',
          position: 'absolute',
          zIndex: 1,
          height: '48px',
          width: '100%',
          bottom: -48,
          left: 0,
          backgroundRepeat: 'repeat-x',
          backgroundImage: `url(${theme.styles.images.shadowHorizontal})`,
        },
      }}
    >
      <Tabs
        value={activeTab}
        onChange={handleChange}
        aria-label="Order detail tabs"
      >
        <Tab
          icon={<MapIcon sx={{ width: 20, height: 20 }} />}
          iconPosition="start"
          label="Tour"
          {...a11yProps(0)}
        />
        {!isLoadingOrders
          ? ordersQueries?.map((query, index) => {
              return (
                <Tab
                  key={query.data.uuid}
                  icon={<DocumentIcon sx={{ width: 20, height: 20 }} />}
                  iconPosition="start"
                  label={`${t('order')} ${query.data.reference}`}
                  {...a11yProps(index)}
                />
              );
            })
          : ordersQueries?.map((query, index) => {
              return (
                <Tab
                  key={index}
                  disabled
                  icon={<CircularProgress size={16} thickness={5} />}
                  iconPosition="start"
                  label={t('order')}
                  {...a11yProps(1)}
                />
              );
            })}
      </Tabs>
    </Box>
  );
};
