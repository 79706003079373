import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const BarsIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 7V2H22V7H2ZM4 4H20V5H4V4Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 14V9H19V14H2ZM4 11H17V12H4V11Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 21V16H14V21H2ZM4 18H12V19H4V18Z"
        />
      </SvgIcon>
    );
  }
);

BarsIcon.displayName = 'BarsIcon';

export default BarsIcon;
