import { ChevronDownIcon } from '@dizzbo/ui';

// https://www.youtube.com/watch?v=CTDZ-5MnETA

const Select = (theme) => {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: ChevronDownIcon,
        MenuProps: {
          elevation: 3,
          sx: {
            marginTop: '4px',
          },
        },
      },
      styleOverrides: {
        select: {
          '&.MuiSelect-filled': {
            '&::before': {
              content: "' '",
              display: 'block',
              position: 'absolute',
              zIndex: 1,
              right: 44,
              height: 20,
              top: 8,
              border: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
              borderWidth: '0 0 0 1px',
            },
            backgroundColor: theme.styles.input.default.backgroundColor,
          },
        },
        icon: {
          color: theme.styles.input.default.color,
          transition: theme.transitions.create(['transform']),
          right: '12px',
          width: 20,
          height: 20,
          top: 10,
        },
        filled: {
          height: 24,
          paddingTop: 14,
          paddingBottom: 0,
          fontSize: 15,
          borderRadius: 4,
        },
        multiple: {
          // minHeight: 22,
          // height: 'auto',
          // paddingTop: 15,
          // paddingBottom: 1,
          // borderRadius: 4,
        },
      },
    },
  };
};

export default Select;
