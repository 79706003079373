import React from 'react';
import { usePopupState, bindTrigger } from 'material-ui-popup-state/hooks';
import { useTranslation } from 'react-i18next';

import { Button } from '@dizzbo/ui';
import { PlusIcon } from '@dizzbo/ui';
import { CreateOrderDialog } from '@orders/components/CreateOrderDialog';

import { useOrderDetail } from '../hooks';

type Props = {
  totalPrice: string;
};

export const CreateOrderButton: React.FC<Props> = ({ totalPrice }) => {
  const { t } = useTranslation();
  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'orderCreateDialog',
  });
  const { orderData } = useOrderDetail();
  const newOrderData = {
    ...orderData,
    price: parseFloat(totalPrice),
  };
  if (!orderData) return null;

  return (
    <>
      <Button
        type="submit"
        size="large"
        startIcon={<PlusIcon />}
        onClick={() => popupState.open()}
        {...bindTrigger(popupState)}
      >
        {t('createNewOrder')}
      </Button>
      <CreateOrderDialog orderData={newOrderData} popupState={popupState} />
    </>
  );
};
