import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SendIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 22L22 12L2 2L2 22ZM3.86374 10.2528L3.86374 5.22634L17.4111 12L3.86374 18.7737L3.86374 13.7474L8.52319 12.0001L3.86374 10.2528Z"
        />
      </SvgIcon>
    );
  }
);

SendIcon.displayName = 'SendIcon';

export default SendIcon;
