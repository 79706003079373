import React from 'react';

import { Stack, TableInstance, Typography } from '@dizzbo/ui';
import { LoadingPointType, OrderType } from '@types';

type Props = {
  loadingPoint?: LoadingPointType;
  highlighted: boolean;
  table: TableInstance<OrderType>;
};

export const LoadingPointCell: React.FC<Props> = ({
  loadingPoint,
  highlighted = false,
  table,
}: Props) => {
  const { getState } = table;
  const { density } = getState();

  let textVariant: any = 'bodyRegular';
  if (density === 'compact') {
    textVariant = 'bodyRegularSmall';
    if (highlighted) {
      textVariant = 'bodyBoldSmall';
    }
  } else {
    if (highlighted) {
      textVariant = 'bodyBold';
    }
  }
  return loadingPoint ? (
    <Stack>
      <Typography variant={textVariant}>{loadingPoint.name}</Typography>
      <Typography variant="tiny">
        {loadingPoint.address.streetAddress}, {loadingPoint.address.postalCode}{' '}
        {loadingPoint.address.city}, {loadingPoint.address.country}{' '}
      </Typography>
    </Stack>
  ) : (
    ''
  );
};
