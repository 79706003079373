import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import 'yup-phone-lite';

import { createContact } from '@core/api';
import { useFieldErrors } from '@dizzbo/core/hooks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  Stack,
  TextField,
  Typography,
} from '@dizzbo/ui';
import { ContactType, UUIDType } from '@types';

const schema = yup.object().shape({
  name: yup.string().required('Please enter a name'),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Please enter an email address'),
  website: yup
    .string()
    .matches(
      /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
      'Website should be a valid URL'
    ),
  phone: yup.string().phone([], 'Please enter a valid phone number'),
  mobile_phone: yup.string().phone([], 'Please enter a valid phone number'),
});

type Props = {
  popupState: PopupState;
  setContact?: (contact: ContactType) => void;
  carrierUUID?: UUIDType;
  customerUUID?: UUIDType;
};

export const CreateContactDialog: React.FC<Props> = ({
  popupState,
  setContact,
  carrierUUID,
  customerUUID,
}: Props) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!popupState.isOpen) {
      reset();
    }
  }, [popupState]);

  const {
    mutateAsync: mutateContact,
    isPending,
    isError,
    error,
  } = useMutation<ContactType, unknown, Partial<ContactType>>({
    mutationFn: (values) => createContact(values),
  });

  const { hasFieldError, fieldError } = useFieldErrors(isError, errors, error);

  const onSubmit = async (formData: any) => {
    const id = toast.loading('Creating Contacts...');
    try {
      const data = await mutateContact({
        ...formData,
        ...(!!carrierUUID && { carrier: carrierUUID }),
        ...(!!customerUUID && { loader: customerUUID }),
      });
      setContact(data);
      popupState.close();
      toast.update(id, {
        autoClose: 6000,
        render: 'Contact created. 👌',
        type: 'success',
        isLoading: false,
      });
    } catch {
      toast.update(id, {
        autoClose: 6000,
        render: 'Contact could not be created. 🤯',
        type: 'error',
        isLoading: false,
      });
    }
  };

  return (
    <>
      <Dialog
        PaperProps={{
          elevation: 6,
          variant: 'filled-primary',
          sx: {
            minWidth: 600,
          },
        }}
        scroll="paper"
        {...bindDialog(popupState)}
      >
        <DialogTitle onClose={popupState.close}>Create Contact</DialogTitle>
        <DialogContent dividers>
          <Stack spacing={6}>
            <Typography variant="h6">Create a new contact</Typography>

            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <TextField
                  label="Name"
                  required
                  error={hasFieldError(field)}
                  helperText={fieldError(field)}
                  {...field}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <TextField
                  label="Email Adress"
                  required
                  error={hasFieldError(field)}
                  helperText={fieldError(field)}
                  {...field}
                />
              )}
            />
            <Controller
              name="website"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label="Website"
                  error={hasFieldError(field)}
                  helperText={fieldError(field)}
                  {...field}
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label="Phone"
                  error={hasFieldError(field)}
                  helperText={fieldError(field)}
                  {...field}
                />
              )}
            />
            <Controller
              name="mobile_phone"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label="Mobile phone"
                  error={hasFieldError(field)}
                  helperText={fieldError(field)}
                  {...field}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack spacing={3} direction="row">
            <Button
              type="button"
              variant="tertiary"
              onClick={() => popupState.close()}
            >
              Cancel
            </Button>
            <LoadingButton
              type="button"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid}
              loading={isPending}
            >
              Create contact
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};
