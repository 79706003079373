import React, { PropsWithChildren, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from '@dizzbo/core/hooks';
import { LoadingScreen } from '@dizzbo/ui';

export const AuthGuard: React.FC<PropsWithChildren> = ({ children }) => {
  const { user, isLoading } = useAuth();
  const { pathname } = useLocation();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!user) {
    return <Navigate to='/login' state={{ previousPath: pathname }} />;
  }

  return children;
};
