import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DragBarsIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M3 10H21V8H3V10Z" />
        <path d="M3 16H21V14H3V16Z" />
      </SvgIcon>
    );
  }
);

DragBarsIcon.displayName = 'DragBarsIcon';

export default DragBarsIcon;
