import {
  localizationDe,
  localizationEn,
} from '@dizzbo/ui/components/Table/_locales';
import { alpha, useTheme } from '@mui/material/styles';
import { OrderType, PaginatedListType } from '@types';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  HideIcon,
  MaterialReactTable,
  MoreVerticalIcon,
  PinDownIcon,
  ResetIcon,
  SortAscIcon,
  TablePaginationBar,
  ViewIcon,
} from '@dizzbo/ui';

import {
  columns,
  defaultColumnOrder,
  defaultColumnPinning,
  defaultColumnVisibility,
} from './columns';

type Props = {
  columnOrder?: string[];
  setColumnOrder: any;
  columnVisibility?: Partial<Record<keyof OrderType, boolean>>;
  setColumnVisibility: any;
  data: PaginatedListType<OrderType>;
  density: 'comfortable' | 'compact';
  isLoading: boolean;
  isStale: boolean;
  isFetching: boolean;
  isError: boolean;
  sorting: any;
  setSorting: any;
  pagination: any;
  setPagination: any;
  columnPinning: { left: string[]; right: string[] };
  setColumnPinning: any;
};

export const Table: React.FC<Props> = ({
  columnOrder = defaultColumnOrder,
  setColumnOrder,
  columnVisibility = defaultColumnVisibility,
  setColumnVisibility,
  columnPinning = defaultColumnPinning,
  setColumnPinning,
  data,
  density,
  setDensity,
  isLoading,
  isStale,
  isFetching,
  isError,
  sorting,
  setSorting,
  pagination,
  setPagination,
}: Props) => {
  const theme = useTheme();
  const { i18n, t } = useTranslation();
  const tableInstanceRef = useRef(null);

  let locale = localizationEn;

  if (i18n.language === 'de') {
    locale = localizationDe;
  }

  return (
    <Box
      sx={{
        display: 'flex',

        width: '100%',
        flexDirection: 'column',
      }}
    >
      <MaterialReactTable
        tableInstanceRef={tableInstanceRef}
        enableGlobalFilter={false}
        enableFilters={false}
        enableStickyHeader
        enableColumnOrdering
        enableColumnDragging
        // enableColumnResizing
        columns={columns}
        data={data?.results ?? []}
        initialState={{
          showColumnFilters: false,
        }}
        manualFiltering
        manualPagination
        manualSorting
        enablePinning
        onPaginationChange={(calcUpdatedValue) => {
          const changedValue: {
            pageSize: number;
            pageIndex: number;
          } = calcUpdatedValue(pagination);
          setPagination(changedValue);
        }}
        onSortingChange={setSorting}
        rowCount={data?.count ?? 0}
        state={{
          columnOrder,
          columnVisibility,
          density,
          columnPinning,
          // isLoading: isFetching,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isFetching && isStale,
          sorting,
        }}
        onColumnOrderChange={(changedValue) => {
          setColumnOrder(changedValue);
        }}
        onColumnPinningChange={(calcUpdatedValue) => {
          const changedValue: {
            left: (keyof OrderType)[];
            right: (keyof OrderType)[];
          } = calcUpdatedValue(columnPinning);
          setColumnPinning(changedValue);
        }}
        onColumnVisibilityChange={(calcUpdatedValue) => {
          const changedValue: Record<string, boolean> =
            calcUpdatedValue(columnVisibility);
          setColumnVisibility(changedValue);
        }}
        muiTableBodyCellProps={({ column }) => {
          const isPinnedPosition = column.getIsPinned();
          const pinnedIndex = column.getPinnedIndex();
          if (isPinnedPosition) {
            let pinnedShadowSX = {
              backgroundImage: `url(${theme.styles.images.shadowVerticalRight})`,
              backgroundSize: 32,
              right: -32,
            };

            if (isPinnedPosition === 'right') {
              if (pinnedIndex > 0) return;

              pinnedShadowSX = {
                backgroundImage: `url(${theme.styles.images.shadowVerticalLeft})`,
                backgroundSize: 32,
                left: -32,
              };
            }

            return {
              sx: {
                boxShadow: 'none',
                overflow: 'visible',
                '&::before': {
                  content: "' '",
                  display: 'block',
                  position: 'absolute',
                  zIndex: 1,
                  height: '100%',
                  width: 32,
                  top: 0,
                  backgroundRepeat: 'repeat-y',
                  ...pinnedShadowSX,
                },
              },
            };
          }
        }}
        muiTableHeadCellProps={({ table, column }) => {
          const { getState } = table;
          const { density } = getState();
          let headCellPadding = {};
          if (density === 'compact') {
            headCellPadding = {
              // firty important fix as else the padding is not applied
              paddingTop: '12px !important',
              paddingBottom: '12px !important',
              fontSize: '13px !important',
            };
          }
          const isPinnedPosition = column.getIsPinned();
          const pinnedIndex = column.getPinnedIndex();
          if (isPinnedPosition) {
            let pinnedShadowSX = {
              backgroundImage: `url(${theme.styles.images.shadowVerticalRight})`,
              backgroundSize: '32px 2px ',
              right: -32,
            };

            if (isPinnedPosition === 'right') {
              if (pinnedIndex > 0) return;

              pinnedShadowSX = {
                backgroundImage: `url(${theme.styles.images.shadowVerticalLeft})`,
                backgroundSize: '32px 2px',
                left: -32,
              };
            }
            return {
              sx: {
                zIndex: isPinnedPosition ? 2 : 1,
                boxShadow: 'none',
                backgroundColor: alpha(theme.palette.turquoise[10], 0.8),
                backdropFilter: 'blur(10px)',
                '&::before': {
                  content: "' '",
                  display: 'block',
                  position: 'absolute',
                  zIndex: 1,
                  height: '100%',
                  width: 32,
                  top: 0,
                  backgroundRepeat: 'repeat-y',
                  ...pinnedShadowSX,
                },
                ...headCellPadding,
              },
            };
          }
          return {
            sx: {
              backgroundColor: theme.palette.turquoise[10],
              ...headCellPadding,
            },
          };
        }}
        muiTableContainerProps={({ table }) => {
          const { getState } = table;
          const { isFullScreen } = getState();
          if (isFullScreen) {
            return {
              sx: {
                maxHeight: 'calc(-168px + 100vh)',
                height: 'calc(-168px + 100vh)',
              },
            };
          }
          return {
            sx: {
              maxHeight: 'calc(-296px + 100vh)',
              height: 'calc(-296px + 100vh)',
            },
          };
        }}
        muiTablePaperProps={({ table }) => {
          return {
            sx: {
              width: '100%',
              height: '100%',
              boxShadow: 'none',
              backgroundColor: theme.styles.table.default.backgroundColor,
              borderRadius: 0,
            },
          };
        }}
        renderTopToolbar={({ table }) => {
          return null;
        }}
        renderBottomToolbar={
          ({ table }) => null
          // <PaginationBar table={table} setPagination={setPagination} />
        }
        icons={{
          MoreVertIcon: (props) => <MoreVerticalIcon />,
          PushPinIcon: (props) => (
            <PinDownIcon {...props} sx={{ width: 16, height: 16 }} />
          ),
          VisibilityOffIcon: (props) => (
            <HideIcon {...props} sx={{ width: 16, height: 16 }} />
          ),
          SortIcon: (props) => (
            <SortAscIcon {...props} sx={{ width: 16, height: 16 }} />
          ),
          ClearAllIcon: (props) => (
            <ResetIcon {...props} sx={{ width: 16, height: 16 }} />
          ),
          ViewColumnIcon: (props) => (
            <ViewIcon {...props} sx={{ width: 16, height: 16 }} />
          ),
          ArrowDownwardIcon: (props) => (
            <SortAscIcon {...props} sx={{ width: 16, height: 16 }} />
          ),
        }}
        // renderColumnActionsMenuItems={({ closeMenu, column, table }) => {
        //   return [<div>1</div>, <div>2</div>, <div>2</div>];
        // }}
        muiTableHeadCellColumnActionsButtonProps={({ table, column }) => ({
          sx: {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.primary.dark,
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: 'transparent',
            backgroundColor: 'transparent',
            padding: '10px',
            margin: 0,
            opacity: 1,
            transform: 'none',
            '&:hover': {
              backgroundColor: 'transparent',
              borderColor: theme.palette.turquoise[100],
            },
            svg: {
              width: 16,
              height: 16,
            },
          },
        })}
        localization={locale}
        // muiTableToolbarBottomProps={{ sx: { position: 'fixed', bottom: 0 } }}
      />
      <TablePaginationBar
        rowCount={data?.count ?? 0}
        pagination={pagination}
        setPagination={setPagination}
        isLoading={isFetching}
        labelRowsPerPage={t('rowsPerPage')}
      />
    </Box>
  );
};
