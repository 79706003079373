import { DialogContent as MuiDialogContent } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiDialogContentProps = ComponentProps<typeof MuiDialogContent>;

export const DialogContent = forwardRef<HTMLDivElement, MuiDialogContentProps>(
  (props, ref) => <MuiDialogContent ref={ref} {...props} />
);

DialogContent.displayName = 'DialogContent';
