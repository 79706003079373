import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TableCompressIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M2 2H22V4H2V2Z" />
        <path d="M2 13V11H22V13H2Z" />
        <path d="M2 20V22H22V20H2Z" />
        <path d="M8 18L12 14L16 18L8 18Z" />
        <path d="M12 10L16 6L8 6L12 10Z" />
      </SvgIcon>
    );
  }
);

TableCompressIcon.displayName = 'TableCompressIcon';

export default TableCompressIcon;
