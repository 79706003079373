import { getCarrierContacts } from '@core/api';
import { QueryKeys } from '@core/config';
import { useQuery } from '@tanstack/react-query';
import { UUIDType } from '@types';

export const useGetCarrierContacts = (uuid: UUIDType, enabled: boolean) => {
  return useQuery({
    queryKey: [QueryKeys.CARRIER_CONTACTS, uuid],
    queryFn: () => getCarrierContacts(uuid),
    enabled: enabled,
  });
};
