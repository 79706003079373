export function mergeObjects(
  obj1: Record<string, string>,
  obj2: Record<string, string>
): Record<string, string> {
  let mergedObject: Record<string, string> = {};

  for (let key in obj1) {
    mergedObject[key] = obj1[key];
  }

  for (let key in obj2) {
    if (key in mergedObject) {
      throw new Error(
        `Error in mergeObjects() in objectUtils.ts! Duplicate key found: ${key}`
      );
    }
    mergedObject[key] = obj2[key];
  }

  return mergedObject;
}
