import React from 'react';
import { styled } from '@mui/material/styles';

import { Currency } from '@dizzbo/core/i18n';
import { Stack, Box } from '@dizzbo/ui';

const ContainerStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
}));

const LeadingStyles = styled(Box)(({ theme }) => ({
  ...theme.typography.h5,
  marginRight: 4,
}));

const PriceContainerStyles = styled(Box)(({ theme }) => ({
  marginLeft: 12,
}));

const LabelStyles = styled(Box)(({ theme }) => ({
  ...theme.typography.labelSmall,
  color: theme.styles.common.textColors.light,
}));

const PriceStyles = styled(Box)(({ theme }) => ({
  ...theme.typography.h3,
}));

type CarrierPriceTotalProps = {
  price?: number;
};

export const CarrierPriceTotal: React.FC<CarrierPriceTotalProps> = ({
  price,
}) => {
  return (
    <ContainerStyles>
      <Stack direction="row" alignItems="center" sx={{ height: '100%' }}>
        <LeadingStyles>=</LeadingStyles>
        <PriceContainerStyles>
          <LabelStyles>Carrier price total</LabelStyles>
          <PriceStyles>
            {price ? <Currency value={price} /> : '--,-- €'}
          </PriceStyles>
        </PriceContainerStyles>
      </Stack>
    </ContainerStyles>
  );
};
