import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DocumentOrderIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M7 17H17V16H7V17Z" />
        <path d="M12 19H7V18H12V19Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.4142 0H2V24H22V6.58579L15.4142 0ZM4 22V2H14.5858L20 7.41421V22H4Z"
        />
        <path d="M7 15H17V14H7V15Z" />
        <path d="M7 13H17V12H7V13Z" />
        <path d="M7 11H17V10H7V11Z" />
      </SvgIcon>
    );
  }
);

DocumentOrderIcon.displayName = 'DocumentOrderIcon';

export default DocumentOrderIcon;
