import { alpha } from '@mui/material/styles';

const divider = (palette) => {
  return {
    divider: {
      primary: {
        default: {
          borderColor: alpha(palette.oxford[20], 0.25),
        },
      },
      primaryContrast: {
        default: {
          borderColor: alpha(palette.oxford[120], 0.8),
        },
      },
    },
  };
};

export default divider;
