import React from 'react';

import { Card, CardContent } from '@dizzbo/ui';

import { CustomerType, UUIDType } from '@types';
import { CustomerDisplay } from './CustomerDisplay';
import { CustomerForm } from './CustomerForm';

type Props = {
  orderUUID: UUIDType;
  customer?: CustomerType;
};

export const CustomerSlot: React.FC<Props> = ({ orderUUID, customer }) => {
  return (
    <Card variant="filled-secondary" elevation={0}>
      <CardContent>
        {customer ? (
          <CustomerDisplay orderUUID={orderUUID} customer={customer} />
        ) : (
          <CustomerForm orderUUID={orderUUID} />
        )}
      </CardContent>
    </Card>
  );
};
