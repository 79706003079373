import FormControlLabel from '@mui/material/FormControlLabel';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { updateOrder } from '@core/api';
import { AutoSave } from '@core/components';
import { QueryKeys } from '@core/config';
import { useFieldErrors } from '@dizzbo/core/hooks';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  MultiLineTextfield,
  Select,
  Stack,
  Switch,
  TextField,
} from '@dizzbo/ui';

import { OrderType } from '@types';
import { useOrderDetail } from '../../../hooks';

type Props = {
  orientation?: 'horizontal' | 'vertical';
};

const vehicleTypes = [
  { name: 'STANDARD_PLANE', label: 'Standard Plane 13,6 LDM' },
  { name: 'FRIGO', label: 'Frigo 13,6 LDM' },
  { name: 'KOFFERSATTEL', label: 'Koffersattel 13,6 LDM' },
  { name: 'MEGATRAILER', label: 'Megatrailer 13,6 LDM' },
  { name: 'STANDARD_TAUTLINER', label: 'Standard Tautliner 13,6 LDM' },
  { name: 'SPRINTER_12', label: 'Sprinter 1,2' },
  { name: 'PLANE_136_A_PANEL', label: 'Plane 13,6 mit A Tafel' },
  { name: 'SMALL_TRUCK', label: 'Kleiner LKW' },
  { name: 'OPEN_TRUCK', label: 'Offener LKW' },
  { name: 'TRAILER_TRAIN', label: 'Hängerzug' },
  { name: 'JUMBO_TRAILER_TRAIN', label: 'Jumbo Hängerzug' },
  { name: 'WALKING_FLOOR_TRAILER', label: 'Walkingfloor' },
  { name: 'TIPPER_TRUCK', label: 'Kipper' },
  { name: 'ALU_TIPPER_TRUCK', label: 'Alukipper' },
  { name: 'JOLODA_TRAILER', label: 'Joloda Trailer' },
  { name: 'COIL_TROUGH_TRAILER', label: 'Coilmulden Auflieger' },
];

export const OrderInformation: React.FC<Props> = ({
  orientation = 'vertical',
}) => {
  const { orderData, isFieldLocked } = useOrderDetail();

  const {
    uuid: orderUUID,
    transportInsuranceValue,
    notes,
    requestedVehicleType,
  } = orderData;

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    mutate: mutateOrder,
    isError,
    error,
  }: any = useMutation<OrderType, unknown, Partial<OrderType>>({
    mutationKey: [QueryKeys.ORDERS, orderUUID],
    mutationFn: (values) =>
      updateOrder({ orderUUID: orderUUID, orderData: values }),
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch,
    setValue,
  } = useForm({
    mode: 'all',
    values: {
      notes: notes,
      requestedVehicleType,
      transportInsurance: !!transportInsuranceValue,
      transportInsuranceValue: transportInsuranceValue
        ? transportInsuranceValue.toFixed(2)
        : '',
    },
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
  });

  const { hasFieldError, fieldError } = useFieldErrors(isError, errors, error);
  const watchTransportInsurance = watch('transportInsurance');

  useEffect(() => {
    if (!watchTransportInsurance) {
      setValue('transportInsuranceValue', '');
    }
  }, [watchTransportInsurance]);

  const onSubmit = (formData: any) => {
    const data = {
      transportInsuranceValue: formData.transportInsuranceValue,
      notes: formData.notes,
      requestedVehicleType: formData.requestedVehicleType,
    };
    mutateOrder(data, {
      onSuccess: (data: any, values: any) => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ORDERS, orderUUID],
        });
      },
      onError: (error: any, variables: any, context: any) => {},
    });
  };

  return (
    <Card
      variant="filled-secondary"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
        minHeight: orientation === 'horizontal' ? 0 : 374,
      }}
    >
      <CardHeader title={t('orderInformation')} variant="small" />
      <CardContent>
        <Stack
          spacing={6}
          direction={orientation === 'horizontal' ? 'row' : 'column'}
          divider={
            orientation === 'vertical' && (
              <Divider orientation="horizontal" flexItem />
            )
          }
        >
          <Controller
            name="requestedVehicleType"
            control={control}
            defaultValue="STANDARD_PLANE"
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel id="vehicle-type-label">
                  {t('requestedVehicleType')}
                </InputLabel>
                <Select
                  labelId="vehicle-type-label"
                  id="vehicle-type"
                  label="vehicle-type"
                  {...field}
                >
                  {vehicleTypes.map((vehicleType) => (
                    <MenuItem key={vehicleType.name} value={vehicleType.name}>
                      {vehicleType.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          <Box sx={{ width: '100%' }}>
            <Controller
              name="notes"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <MultiLineTextfield
                  label={t('additionalInternalInformationAndNotes')}
                  characterLimit={400}
                  error={hasFieldError(field)}
                  helperText={fieldError(field)}
                  placeholder={'-'}
                  {...field}
                />
              )}
            />
          </Box>

          <Box sx={{ flexDirection: 'column', width: '100%' }}>
            <Controller
              name="transportInsurance"
              control={control}
              render={({ field: { value, ...field } }) => (
                <FormControlLabel
                  label={t('transportInsuranceRequired')}
                  control={
                    <Switch
                      {...field}
                      checked={!!value}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                />
              )}
            />
            {watchTransportInsurance ? (
              <Box sx={{ display: 'flex', marginTop: 6 }}>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ marginRight: 4, marginLeft: 3 }}
                />
                <Controller
                  name="transportInsuranceValue"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <TextField
                      label={t('valueOfGoods')}
                      fullWidth
                      required
                      type="number"
                      error={hasFieldError(field)}
                      helperText={fieldError(field)}
                      {...field}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">€</InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            ) : null}
          </Box>
        </Stack>
        <AutoSave
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          isValid={isValid}
          control={control}
        />
      </CardContent>
    </Card>
  );
};
