import { darkShadows as shadows } from '../shadows';

const tooltip = (palette) => {
  return {
    tooltip: {
      default: {
        color: palette.turquoise[60],
        backgroundColor: palette.oxford[60],
        boxShadow: shadows[6],
      },
    },
  };
};

export default tooltip;
