import { FormControlState, useFormControl } from '@mui/material/FormControl';
import { FormHelperTextProps as MuiFormHelperTextProps } from '@mui/material/FormHelperText';
import { motion } from 'framer-motion';
import React, { forwardRef } from 'react';

import { Box, Stack, Typography } from '../';
import { InfoOutlineIcon } from '../icons';

export const FormHelperText = forwardRef<
  HTMLDivElement,
  MuiFormHelperTextProps
>(({ children, sx }, ref) => {
  const formControlState: FormControlState | undefined = useFormControl();
  let error: boolean | undefined;
  if (formControlState) {
    error = formControlState.error;
  }

  let color = 'primary.light';
  if (error) {
    color = 'error';
  }
  return (
    <Box sx={sx}>
      <motion.div
        initial={{ opacity: 0, minHeight: 0 }}
        animate={{ opacity: 1, minHeight: 20 }}
        exit={{ opacity: 0, minHeight: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Stack
          ref={ref}
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ mt: 1 }}
        >
          {error && <InfoOutlineIcon htmlColor={color} sx={{ width: 20 }} />}
          <Typography component="span" variant="bodyRegular" color={color}>
            {children}
          </Typography>
        </Stack>
      </motion.div>
    </Box>
  );
});

FormHelperText.displayName = 'FormHelperText';
