import { Typography as MuiTypography } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type TypographyPropsAdditional = {
  component?: React.ElementType;
};

type MuiTypographyProps = ComponentProps<typeof MuiTypography>;

type TypographyProps = MuiTypographyProps & TypographyPropsAdditional;

export const Typography = forwardRef<HTMLDivElement, TypographyProps>(
  (props, ref) => <MuiTypography ref={ref} {...props} />
);

Typography.displayName = 'Typography';
