import React from 'react';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { getStatusColor } from '@dizzbo/core/utils';
import { Currency } from '@dizzbo/core/i18n';
import {
  Stack,
  Box,
  Typography,
  Chip,
  TruckLTLIcon,
  ChildOfIcon,
} from '@dizzbo/ui';

import { useOrderDetail } from '../../hooks';

type Props = {};

export const OrderDetailHeader: React.FC<Props> = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { orderData } = useOrderDetail();
  const { reference, tour, status, createdAt, modifiedAt, profit } = orderData;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        paddingRight: 6,
        paddingLeft: 6,
        backgroundColor: theme.styles.common.pageBackground,
        paddingTop: 3,
        paddingBottom: 3,
        '&::after': {
          pointerEvents: 'none',
          content: "' '",
          display: 'block',
          position: 'absolute',
          zIndex: 1,
          height: '48px',
          width: '100%',
          bottom: -48,
          left: 0,
          backgroundRepeat: 'repeat-x',
          backgroundImage: `url(${theme.styles.images.shadowHorizontal})`,
        },
      }}
    >
      <Box>
        <Stack direction="row" spacing={2}>
          <Typography variant="h2" color="primary">
            {t('dizzboOrder')} {reference}
          </Typography>
          <Typography
            variant="h3"
            sx={{ color: getStatusColor(status), textTransform: 'capitalize' }}
          >
            – {status}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={3}>
          <Chip
            label="FTL"
            size="medium"
            variant="oxford-40"
            icon={<TruckLTLIcon />}
          />
          <Chip
            label={`${t('dizzboTour')} ${tour?.reference}`}
            size="medium"
            variant="outline-oxford-40"
            icon={<ChildOfIcon />}
          />
          <Typography variant="bodyRegularSmall" color="primary">
            {t('createdAt')} {dayjs(createdAt).format('DD.MM.YYYY')}
          </Typography>
          <Typography variant="bodyRegularSmall" color="primary">
            {t('lastEdited')} {dayjs(modifiedAt).format('DD.MM.YYYY')}
          </Typography>
        </Stack>
      </Box>
      <Box sx={{ textAlign: 'right' }}>
        <Typography variant="labelSmall" color="grey.70">
          {t('dizzboTourProfit')}
        </Typography>
        <Typography variant="h3" color="primary">
          {profit !== null ? <Currency value={profit} /> : '--,-- €'}
        </Typography>
      </Box>
    </Stack>
  );
};
