import { IconButton as MuiIconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { ComponentProps, forwardRef } from 'react';

import { IconButtonPropsSizeOverrides } from '@mui/material/IconButton';
import { OverridableStringUnion } from '@mui/types';

interface MinimalIconButtonPropsOverrides {
  /**
   * The size of the component.
   * @default 'medium'
   */
  size?: OverridableStringUnion<
    'tiny' | 'small' | 'medium' | 'large',
    IconButtonPropsSizeOverrides
  >;
  /**
   * The variant to use.
   * @default 'primary'
   */
  variant?: 'primary' | 'primary-contrast';
}

type MuiIconButtonProps = ComponentProps<typeof MuiIconButton>;

type MinimalIconButtonProps = Omit<
  MuiIconButtonProps,
  | 'centerRipple'
  | 'disableRipple'
  | 'disableTouchRipple'
  | 'disableFocusRipple'
  | 'focusRipple'
  | 'focusVisibleClassName'
  | 'TouchRippleProps'
  | 'touchRippleRef'
  | 'size'
> &
  MinimalIconButtonPropsOverrides;

const MuiIconButtonStyle = styled(MuiIconButton, {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'size',
})<MinimalIconButtonProps>(({ theme, variant, size }) => {
  let sizeStyle;
  let style;
  const defaultStyles = {
    borderRadius: 0,
  };

  sizeStyle = {
    padding: 4,
    '& svg': {
      height: 24,
      width: 24,
    },
  };
  if (size === 'medium') {
    sizeStyle = {
      padding: 2,
      '& svg': {
        height: 20,
        width: 20,
      },
    };
  }
  if (size === 'small') {
    sizeStyle = {
      padding: 0,
      '& svg': {
        height: 20,
        width: 20,
      },
    };
  }

  if (size === 'tiny') {
    sizeStyle = {
      padding: 0,
      '& svg': {
        height: 16,
        width: 16,
      },
    };
  }

  style = {
    color: theme.styles.minimalIconButton.primary.default.color,
    '&:hover': {
      color: theme.styles.minimalIconButton.primary.hover.color,
      backgroundColor: 'transparent',
    },
    '&:active': {
      color: theme.styles.minimalIconButton.primary.active.color,
    },
    '&:focus': {
      color: theme.styles.minimalIconButton.primary.focus.color,
    },
    '&.Mui-disabled': {
      color: theme.styles.minimalIconButton.primary.disabled.color,
    },
  };

  if (variant === 'primary-contrast') {
    style = {
      color: theme.styles.minimalIconButton.primaryContrast.default.color,
      '&:hover': {
        color: theme.styles.minimalIconButton.primaryContrast.hover.color,
        backgroundColor: 'transparent',
      },
      '&:active': {
        color: theme.styles.minimalIconButton.primaryContrast.active.color,
      },
      '&:focus': {
        color: theme.styles.minimalIconButton.primaryContrast.focus.color,
      },
      '&.Mui-disabled': {
        color: theme.styles.minimalIconButton.primaryContrast.disabled.color,
      },
    };
  }

  return {
    ...defaultStyles,
    ...sizeStyle,
    ...style,
  };
});

export const MinimalIconButton = forwardRef<
  HTMLButtonElement,
  MinimalIconButtonProps
>(({ children, variant, size, ...rest }, ref) => {
  return (
    <MuiIconButtonStyle
      variant={variant}
      size={size}
      ref={ref}
      {...rest}
      className="MuiMinimalIconButton-root"
    >
      {children}
    </MuiIconButtonStyle>
  );
});

MinimalIconButton.displayName = 'MinimalIconButton';
