import React, { useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { View } from '@dizzbo/core/views';
import { postOAuthCredentials } from '@dizzbo/core/api';
import { useAuth } from '@dizzbo/core/hooks';
import { Box, LoadingScreen } from '@dizzbo/ui';

type Props = {
  bgImage?: string;
};

export const OAuthRedirectView: React.FC<Props> = ({ bgImage }) => {
  const { loginUser } = useAuth();
  const navigate = useNavigate();

  const [flowId, setValue, remove] = useLocalStorage('flowId');
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const sessionState = searchParams.get('session_state');
  const state = searchParams.get('state');

  const oAuthMutation = useMutation({
    mutationFn: (values) => {
      return postOAuthCredentials(values);
    },
    onSuccess: (data: any, values: any) => {
      remove();
      loginUser(data);
    },
    onError: (error: any, variables: any, context: any) => {
      remove();
      toast.error(`An error happened.`);
      navigate('/login');
    },
  });

  useEffect(() => {
    if (code) {
      oAuthMutation.mutate({
        flowId: flowId,
        payload: { code, sessionState, state },
      });
    } else {
      navigate('/login');
    }
  }, [code]);

  return (
    <View title="Office 356 Login">
      <Box
        sx={{
          height: '100vh',
          backgroundImage: `url(${bgImage})`,
          backgroundColor: '#16253c',
          backgroundSize: { xs: 'cover', xl: 'contain' },
          backgroundPosition: 'top right',
          backgroundRepeat: 'no-repeat',
          flexGrow: 1,
        }}
      >
        <LoadingScreen
          sx={{ position: 'absolute', background: 'transparent' }}
        />
      </Box>
    </View>
  );
};
