import { alpha } from '@mui/material/styles';

// Button Styles are used for Button and IconButton

const documentUploadField = (palette) => {
  return {
    documentUploadField: {
      default: {
        borderColor: palette.oxford[20],
      },
    },
  };
};

export default documentUploadField;
