import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import Pusher from 'pusher-js';

import { getAccessToken } from '@dizzbo/core/api/jwt';
import { DIZZBO_TMS_API_URL } from '@dizzbo/core/api';
import { QueryKeys } from '@core/config';
import { PUSHER_KEY } from '@core/config';

// https://codesandbox.io/s/@tanstack/react-query-websockets-ep1op?file=/src/App.tsx
// Discussion about optimistic updates
// https://github.com/TanStack/query/discussions/4252#discussioncomment-3795134

const entityMapping = {
  'transports.order': QueryKeys.ORDERS,
  'transports.stop': QueryKeys.STOPS,
  'transports.delivery': QueryKeys.DELIVERIES,
  'transports.deliverygood': QueryKeys.DELIVERY_GOODS,
  'tours.tour': QueryKeys.TOURS,
  'routes.route': QueryKeys.ROUTES,
  'routes.section': QueryKeys.ROUTES,
  'carriers.vehicle': QueryKeys.VEHICLES, // not sure if we need a section key as section are inside the route object
};

export const useReactQuerySubscription = () => {
  const channelName = 'private-updates';
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!PUSHER_KEY) {
      return;
    }
    const pusher = new Pusher(PUSHER_KEY as string, {
      cluster: 'eu',
      userAuthentication: {
        endpoint: `${DIZZBO_TMS_API_URL}v2/pusher/user-auth/`,
        transport: 'ajax',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
      channelAuthorization: {
        endpoint: `${DIZZBO_TMS_API_URL}v2/pusher/channel-auth/`,
        transport: 'ajax',
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    });

    const channel = pusher.subscribe(channelName);
    channel.bind('update', (data) => {
      const { entity, id } = data;

      // console.log('pusher update', data);
      if (entity) {
        const queryKey = entityMapping[entity[0]];
        if (queryKey === QueryKeys.ORDERS) {
          // for now, if an order gets updated,
          // we also invalidate all orders to update the order lists
          queryClient.invalidateQueries({ queryKey: [queryKey] });
          queryClient.invalidateQueries({ queryKey: [queryKey, id] });
        } else {
          console.log('queryKey, id', queryKey, id);
          queryClient.invalidateQueries({ queryKey: [queryKey, id] });
        }
      }
    });

    return () => {
      console.log('disconnect pusher');
      pusher.unsubscribe(channelName);
      pusher.disconnect();
    };
  }, [queryClient, PUSHER_KEY]);
};
