import { Button as MuiButton } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

import {
  ButtonPropsColorOverrides,
  ButtonPropsSizeOverrides,
  ButtonPropsVariantOverrides,
} from '@mui/material/Button';
import { OverridableStringUnion } from '@mui/types';

type MuiButtonProps = ComponentProps<typeof MuiButton>;

interface ButtonPropsAdditional {
  component?: React.ElementType;
  to?: String;
}

interface ButtonPropsOverrides {
  /**
   * The size of the component.
   * `small` is equivalent to the dense button styling.
   * @default 'large'
   */
  size?: OverridableStringUnion<
    'small' | 'medium' | 'large',
    ButtonPropsSizeOverrides
  >;
  /**
   * The variant to use.
   * @default 'primary'
   */
  variant?: OverridableStringUnion<
    | 'text'
    | 'primary'
    | 'secondary'
    | 'secondary-contrast'
    | 'tertiary'
    | 'tertiary-contrast',
    ButtonPropsVariantOverrides
  >;
  /**
   * The color of the component.
   * It supports both default and custom theme colors, which can be added as shown in the
   * [palette customization guide](https://mui.com/material-ui/customization/palette/#adding-new-colors).
   * @default 'primary'
   */
  color?: OverridableStringUnion<
    'inherit' | 'primary' | 'warning',
    ButtonPropsColorOverrides
  >;
}

type ButtonProps = Omit<
  MuiButtonProps,
  | 'size'
  | 'variant'
  | 'color'
  | 'disableRipple'
  | 'disableTouchRipple'
  | 'focusRipple'
  | 'focusVisibleClassName'
  | 'TouchRippleProps'
  | 'touchRippleRef'
  | 'disableFocusRipple'
  | 'disableElevation'
> &
  ButtonPropsOverrides &
  ButtonPropsAdditional;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, sx, ...rest }, ref) => (
    <MuiButton ref={ref} sx={sx} {...rest}>
      {children}
    </MuiButton>
  )
);

Button.displayName = 'Button';
