import ShadowLeftImage from '../../assets/images/shadows/shadow-vertical-left-light.png';
import ShadowRightImage from '../../assets/images/shadows/shadow-vertical-right-light.png';
import ShadowHorizontalImage from '../../assets/images/shadows/shadow-horizontal-light.png';

const images = (palette) => {
  return {
    images: {
      shadowHorizontal: ShadowHorizontalImage,
      shadowVerticalLeft: ShadowLeftImage,
      shadowVerticalRight: ShadowRightImage,
    },
  };
};

export default images;
