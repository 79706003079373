import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const MenuIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M22 5H2V7H22V5Z" />
        <path d="M2 11H22V13H2V11Z" />
        <path d="M2 17H22V19H2V17Z" />
      </SvgIcon>
    );
  }
);

MenuIcon.displayName = 'MenuIcon';

export default MenuIcon;
