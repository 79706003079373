import { styled } from '@mui/material/styles';
import React, { useState } from 'react';

import {
  Box,
  Card,
  CardActions,
  CardContent,
  Paper,
  Typography,
} from '@dizzbo/ui';

import { RoutingMap } from '@core/components/RoutingMap';
import { useGetRoute } from '@core/hooks';
import { PricingBar } from '@planner/components/PricingBar';

import { StopType } from '@types';
import { useTranslation } from 'react-i18next';
import { useOrderDetail } from '../../../hooks';
import { StopList } from './StopList';

const CardContentStyles = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: 0,
}));

const StopListWrapperStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 600,
}));

const MapWrapperStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hasStops',
})<any>(({ theme, hasStops }) => ({
  height: hasStops ? 600 : 300,
  width: '100%',
}));

type Props = {
  tourStops: StopType[];
};

export const TourPlan: React.FC<Props> = ({ tourStops }) => {
  const [totalPrice, setTotalPrice] = useState(0);
  const { orderData } = useOrderDetail();
  const { route: routeUUID, uuid: orderUUID } = orderData;
  const { data, isPending } = useGetRoute(routeUUID);

  const { t } = useTranslation();

  return (
    <Card
      variant="filled-secondary"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <CardContentStyles>
        <StopListWrapperStyles>
          <Box py={4} px={6}>
            <Typography variant="h5" color="primary">
              {t('route')}
            </Typography>
          </Box>
          <StopList tourStops={tourStops} orderUUID={orderUUID} />
        </StopListWrapperStyles>
        <MapWrapperStyles hasStops={tourStops.length > 0}>
          <RoutingMap route={data} />
        </MapWrapperStyles>
      </CardContentStyles>
      <Paper elevation={3}>
        <CardActions sx={{ padding: 0, overflow: 'hidden' }}>
          <PricingBar
            setTotalPrice={setTotalPrice}
            totalPrice={totalPrice}
            orderData={orderData}
          />
        </CardActions>
      </Paper>
    </Card>
  );
};
