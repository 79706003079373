import { alpha } from '@mui/material/styles';

const paper = (palette) => {
  return {
    paper: {
      color: {
        default: palette.oxford[40],
      },
      outlined: {
        default: {
          borderColor: alpha(palette.oxford[20], 0.25),
        },
      },
      filled: {
        default: {
          backgroundColor: palette.common.white,
        },
      },
      filledPrimary: {
        default: {
          backgroundColor: palette.common.white,
        },
      },
      filledSecondary: {
        default: {
          backgroundColor: alpha(palette.turquoise[80], 0.2),
        },
      },
      filledSecondaryLight: {
        default: {
          backgroundColor: alpha(palette.turquoise[80], 0.08),
        },
      },
      filledDisabled: {
        default: {
          backgroundColor: alpha(palette.oxford[15], 0.3),
        },
      },
      filledTurquoise: {
        default: {
          backgroundColor: palette.turquoise[100],
        },
      },
      filledTurquoiseLight: {
        default: {
          backgroundColor: palette.turquoise[40],
        },
      },
      filledGreen: {
        default: {
          backgroundColor: palette.green[100],
        },
      },
    },
  };
};

export default paper;
