import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ListIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M4 5H2V7H4V5Z" />
        <path d="M22 5H6V7H22V5Z" />
        <path d="M6 11H22V13H6V11Z" />
        <path d="M4 11H2V13H4V11Z" />
        <path d="M6 17H22V19H6V17Z" />
        <path d="M4 17H2V19H4V17Z" />
      </SvgIcon>
    );
  }
);

ListIcon.displayName = 'ListIcon';

export default ListIcon;
