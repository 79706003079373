import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosAuthenticatedRequest } from '../api';
import { QueryKeys } from '../queries';
import { UserSettingsType } from '../types';

export const useUpdateUserSettings = () => {
  const queryClient = useQueryClient();
  const updateUserSettings = async (
    frontendSettings: Partial<UserSettingsType>
  ) => {
    const res = await axiosAuthenticatedRequest.patch<UserSettingsType>(
      '/v2/users/me/frontend-settings/',
      frontendSettings
    );
    return res.data;
  };

  const { mutateAsync: mutateUserSettings, isPending }: any = useMutation({
    mutationKey: ['user', 'settings'],
    mutationFn: (values) => updateUserSettings(values),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.AUTH] });
    },
  });
  return {
    isPending,
    mutateUserSettings,
  };
};
