import update from 'immutability-helper';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Box } from '@dizzbo/ui';

import { TourType, UUIDType } from '@types';
import { Window } from './Window';

type WindowType = {
  title?: string;
  position: {
    top: number;
    left: number;
  };
  size: {
    width: number;
    height: number;
  };
  content?: ReactNode;
  bounds: { left?: number; top?: number; right?: number; bottom?: number };
  pinned?: {
    isPinned: boolean;
    position: { left: number; top: number };
    size: { width: number; height: number };
  };
};

type WindowsType = {
  [key: string]: WindowType;
};

type ContainerProps = {
  selectedTour?: TourType;
  children: ReactNode;
};

export const WindowWrapper: React.FC<ContainerProps> = ({
  selectedTour,
  children,
}) => {
  const windowsWrapperRef = useRef(null);
  const prevselectedTourUUID = useRef<UUIDType>(undefined);

  const [windowsWrapperSize, setWindowsWrapperSize] = useState({
    width: 0,
    height: 0,
  });

  const [windows, setWindows] = useState<WindowsType>({
    a: {
      title: '',
      position: {
        top: 20,
        left: 80,
      },
      size: {
        width: 300,
        height: 0,
      },
      bounds: { left: 0, top: 0, right: 0, bottom: 0 },
      pinned: {
        isPinned: true,
        position: { left: 0, top: 0 },
        size: { width: 500, height: 0 },
      },
    },
  });

  useEffect(() => {
    if (selectedTour && selectedTour.uuid !== prevselectedTourUUID.current) {
      Object.keys(windows).map((key) => {
        windows[key].title = selectedTour.reference;
      });
      setWindows({ ...windows });
      prevselectedTourUUID.current = selectedTour.uuid;
    }
  }, [selectedTour]);

  useEffect(() => {
    if (!windowsWrapperRef.current) return;
    const resizeObserver = new ResizeObserver((event) => {
      setWindowsWrapperSize({
        width: event[0].contentBoxSize[0].inlineSize,
        height: event[0].contentBoxSize[0].blockSize,
      });
    });
    resizeObserver.observe(windowsWrapperRef.current);
    return () => resizeObserver.disconnect();
  }, [windowsWrapperRef]);

  useEffect(() => {
    Object.keys(windows).map((key) => {
      windows[key].pinned.position.top = 0;
      windows[key].pinned.position.left =
        windowsWrapperSize.width - windows[key].size.width;
      windows[key].pinned.size.height = windowsWrapperSize.height;

      if (windows[key].pinned.isPinned) {
        windows[key].position.top = 0;
        windows[key].position.left =
          windowsWrapperSize.width - windows[key].pinned.size.width;
        windows[key].pinned.position.left =
          windowsWrapperSize.width - windows[key].pinned.size.width;
        windows[key].size.height = windowsWrapperSize.height;
      }
    });

    setWindows({ ...windows });
  }, [windowsWrapperSize]);

  const moveWindow = useCallback(
    (id: string, left: number, top: number) => {
      setWindows(
        update(windows, {
          [id]: {
            $merge: { position: { left, top } },
          },
        })
      );
    },
    [windows, setWindows]
  );

  const resizeWindow = useCallback(
    (id: string, width: number, height: number) => {
      setWindows(
        update(windows, {
          [id]: {
            $merge: { size: { width, height } },
          },
        })
      );
    },
    [windows, setWindows]
  );

  const pinWindow = useCallback(
    (id: string, isPinned: boolean, left?: number, top?: number) => {
      if (isPinned) {
        setWindows(
          update(windows, {
            [id]: {
              size: {
                $set: {
                  width: windows[id].pinned.size.width,
                  height: windows[id].pinned.size.height,
                },
              },
              position: { $set: { left, top } },
              pinned: { isPinned: { $set: isPinned } },
            },
          })
        );
      } else {
        setWindows(
          update(windows, {
            [id]: {
              size: { $set: { width: 300, height: 300 } },
              pinned: { isPinned: { $set: isPinned } },
            },
          })
        );
      }
    },
    [windows, setWindows]
  );

  const expandWindow = useCallback(
    (id: string) => {
      setWindows(
        update(windows, {
          [id]: {
            size: {
              $set: {
                width: windowsWrapperSize.width - 100,
                height: windowsWrapperSize.height - 100,
              },
            },
            position: { $set: { left: 50, top: 50 } },
          },
        })
      );
    },
    [windows, setWindows]
  );

  return (
    <Box ref={windowsWrapperRef} sx={{ height: '100%', width: '100%' }}>
      <Box
        sx={{
          position: 'relative',
          height: '100%',
          width: '100%',
          zIndex: 100,
        }}
      >
        {children}
        {selectedTour &&
          Object.keys(windows).map((key) => {
            const { title, position, size, pinned } = windows[
              key
            ] as WindowType;
            return (
              <Window
                key={key}
                id={key}
                title={title}
                position={position}
                size={size}
                resizeWindow={resizeWindow}
                pinWindow={pinWindow}
                moveWindow={moveWindow}
                expandWindow={expandWindow}
                pinned={pinned}
                selectedTour={selectedTour}
              />
            );
          })}
      </Box>
    </Box>
  );
};
