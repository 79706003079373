import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import Fade from '@mui/material/Fade';

import { View } from '@dizzbo/core/views';
import { LoadingScreen, StickyBox, Stack } from '@dizzbo/ui';

import { Header } from '../components/TourDetail/Header';
import { TourSection } from '../components/TourDetail/TourSection';
import { TourTabs } from '../components/TourDetail/TourTabs';
import { useGetTourOrders, useGetTour } from '../queries';

import { TourDetailProvider } from '../../tours/contexts';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Fade in={value === index}>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tour-tabpanel-${index}`}
        aria-labelledby={`tour-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    </Fade>
  );
};

export const TourDetailView: FC = () => {
  const containerRef = React.useRef();
  const theme = useTheme();
  const { uuid } = useParams();

  const [activeTab, setActiveTab] = React.useState(0);
  const { data: tourData, isPending } = useGetTour(uuid);
  const ordersQueries = useGetTourOrders(tourData?.orders);
  const isLoadingOrders = ordersQueries.some((query) => query.isPending);

  if (isPending) {
    return <LoadingScreen sx={{ position: 'absolute' }} />;
  }

  return (
    <View
      title={`Tour Detail - ${tourData.reference}`}
      sx={{ height: '100%', overflowY: 'auto', paddingBottom: 6 }}
      ref={containerRef}
    >
      <StickyBox
        container={containerRef}
        zIndex={theme.zIndex.appBar + 3}
        stickTo="top"
        offset={0}
        stuckStyles={{}}
        unstuckStyles={{}}
      >
        <Header
          isLTL={tourData?.orders.length > 1}
          tourReference={tourData?.reference}
          status={tourData.status}
          createdAt={tourData.createdAt}
          modifiedAt={tourData.modifiedAt}
          price={tourData.price}
        />
      </StickyBox>
      {/* <TodosSection orderData={orderData} tourData={tourData} /> */}
      <StickyBox
        container={containerRef}
        zIndex={theme.zIndex.appBar + 4}
        stickTo="top"
        offset={80}
        stuckStyles={{}}
        unstuckStyles={{}}
      >
        <TourTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          ordersQueries={ordersQueries}
        />
      </StickyBox>
      <TabPanel value={activeTab} index={0}>
        <TourDetailProvider
          tourData={tourData}
          ordersData={
            !isLoadingOrders && ordersQueries.length > 0
              ? ordersQueries?.map((query) => query?.data)
              : []
          }
        >
          <TourSection />
        </TourDetailProvider>
      </TabPanel>
      {!isLoadingOrders &&
        ordersQueries?.map((query) => {
          <TabPanel value={activeTab} index={query?.data.uuid}>
            {/* <OrderDetailProvider orderData={orderData}> */}
            {/* <OrderDetailForm orderData={query?.data} /> */}
            {/* </OrderDetailProvider> */}
          </TabPanel>;
        })}
      <Stack
        direction="row"
        spacing={6}
        justifyContent="space-between"
        sx={{
          marginTop: theme.spacing(6),
          paddingLeft: theme.spacing(6),
          paddingRight: theme.spacing(6),
        }}
      ></Stack>
    </View>
  );
};
