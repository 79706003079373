import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { Box, Tabs, Tab } from '@dizzbo/ui';
import { DocumentIcon, MapIcon } from '@dizzbo/ui';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const OrderDetailNavigation: React.FC<any> = ({
  activeTab,
  setActiveTab,
  stuck,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box
      sx={{
        transition: theme.transitions.create('border '),
        backgroundColor: theme.styles.common.pageBackground,
        paddingLeft: 3,
        position: 'relative',
        borderTop: stuck
          ? `1px solid ${theme.styles.divider.primary.default.borderColor}`
          : `0px solid ${theme.styles.divider.primary.default.borderColor}`,
        '&::after': {
          pointerEvents: 'none',
          content: "' '",
          display: 'block',
          position: 'absolute',
          zIndex: 1,
          height: '48px',
          width: '100%',
          bottom: -48,
          left: 0,
          backgroundRepeat: 'repeat-x',
          backgroundImage: `url(${theme.styles.images.shadowHorizontal})`,
        },
      }}
    >
      <Tabs
        value={activeTab}
        onChange={handleChange}
        aria-label="Order detail tabs"
      >
        <Tab
          icon={<DocumentIcon sx={{ width: 20, height: 20 }} />}
          iconPosition="start"
          label={t('order')}
          {...a11yProps(0)}
        />
        <Tab
          icon={<MapIcon sx={{ width: 20, height: 20 }} />}
          iconPosition="start"
          label="Tour"
          {...a11yProps(1)}
        />
      </Tabs>
    </Box>
  );
};
