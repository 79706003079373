import * as React from 'react';
import SvgIcon, {
  SvgIconProps as MuiSvgIconProps,
} from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material/styles';

interface SvgIconPropsOverrides {
  /**
   * If `true`, uses the dark version of the dizzbo logo.
   * @default false
   */
  dark?: boolean;
}

type SvgIconProps = MuiSvgIconProps & SvgIconPropsOverrides;

const DizzboLogo: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    const { dark, ...rest } = props;
    const theme = useTheme();

    if (dark && theme.mode === 'light') {
      return (
        <SvgIcon ref={ref} {...rest} viewBox="0 0 82.6067 18">
          <path
            fill="#172234"
            d="M6,3.8c2.9,0,5,2.3,5,5c0,2.9-2.1,5.2-5,5.2H3.9V3.8H6z M6.2,0.3c-0.1,0-0.1,0-0.2,0H0v17.3h6
          c0.1,0,0.1,0,0.2,0c4.8,0,8.9-4.1,8.8-8.7C15.1,4.3,11,0.3,6.2,0.3z"
          />
          <path fill="#172234" d="M21.9,0.3v17.3h-4V0.3H21.9z" />
          <path
            fill="#172234"
            d="M55.1,3.8c1.1,0,1.9,0.8,1.9,1.8c0,1-0.8,1.8-1.9,1.8h-1.8V3.8H55.1z M56.8,10.6c1,0,1.9,0.8,1.9,1.8
          s-0.8,1.8-1.9,1.8h-3.5v-3.5H56.8z M55.5,0.3h-6.2v17.3h7.8c3,0,5.4-2.2,5.4-5.2c0-1.9-1-3.6-3-4.3c0.9-0.8,1.3-1.9,1.3-3.1
          C60.8,2.9,58.5,0.3,55.5,0.3z"
          />
          <path fill="#172234" d="M37.7,17.7h8.2v-3.7h-5.9L37.7,17.7z" />
          <path
            fill="#172234"
            d="M44.9,2.6l1.5-2.3h-4.5l-2.2,3.7l-8.3,13.6h4.5L44.9,2.6z"
          />
          <path
            fill="#127871"
            d="M35.2,0.3l-1.9,3.2l-8.6,14.2h4.6l2.1-3.7l8.3-13.6H35.2z"
          />
          <path fill="#127871" d="M32,2.6l1.3-2.3h-7.6v3.7h5.4L32,2.6z" />
          <path
            fill="#172234"
            d="M73.4,3.7c2.9,0,5.3,2.3,5.3,5.2c0,2.7-2.2,5.2-5.1,5.2c-2.9,0-5.1-2.3-5.1-5.2C68.2,6.1,70.5,3.7,73.4,3.7z
          M73.3,0c-4.9,0-9.1,4.3-8.9,9c-0.1,4.7,4.1,9,8.9,9c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c4.8,0,9.1-4.3,8.9-9c0.1-4.7-4.1-9-8.9-9
          c-0.1,0-0.1,0-0.2,0C73.4,0,73.4,0,73.3,0z"
          />
        </SvgIcon>
      );
    }
    return (
      <SvgIcon ref={ref} {...rest} viewBox="0 0 82.6067 18">
        <path
          fill="#89D7D2"
          d="M6,3.8c2.9,0,5,2.3,5,5c0,2.9-2.1,5.2-5,5.2H3.9V3.8H6z M6.2,0.3c-0.1,0-0.1,0-0.2,0H0v17.3h6
        c0.1,0,0.1,0,0.2,0c4.8,0,8.9-4.1,8.8-8.7C15.1,4.3,11,0.3,6.2,0.3z"
        />
        <path fill="#89D7D2" d="M21.9,0.3v17.3h-4V0.3H21.9z" />
        <path
          fill="#89D7D2"
          d="M55.1,3.8c1.1,0,1.9,0.8,1.9,1.8c0,1-0.8,1.8-1.9,1.8h-1.8V3.8H55.1z M56.8,10.6c1,0,1.9,0.8,1.9,1.8
        s-0.8,1.8-1.9,1.8h-3.5v-3.5H56.8z M55.5,0.3h-6.2v17.3h7.8c3,0,5.4-2.2,5.4-5.2c0-1.9-1-3.6-3-4.3c0.9-0.8,1.3-1.9,1.3-3.1
        C60.8,2.9,58.5,0.3,55.5,0.3z"
        />
        <path fill="#89D7D2" d="M37.7,17.7h8.2v-3.7h-5.9L37.7,17.7z" />
        <path
          fill="#89D7D2"
          d="M44.9,2.6l1.5-2.3h-4.5l-2.2,3.7l-8.3,13.6h4.5L44.9,2.6z"
        />
        <path
          fill="#BAF0AA"
          d="M35.2,0.3l-1.9,3.2l-8.6,14.2h4.6l2.1-3.7l8.3-13.6H35.2z"
        />
        <path fill="#BAF0AA" d="M32,2.6l1.3-2.3h-7.6v3.7h5.4L32,2.6z" />
        <path
          fill="#89D7D2"
          d="M73.4,3.7c2.9,0,5.3,2.3,5.3,5.2c0,2.7-2.2,5.2-5.1,5.2c-2.9,0-5.1-2.3-5.1-5.2C68.2,6.1,70.5,3.7,73.4,3.7z
        M73.3,0c-4.9,0-9.1,4.3-8.9,9c-0.1,4.7,4.1,9,8.9,9c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c4.8,0,9.1-4.3,8.9-9c0.1-4.7-4.1-9-8.9-9
        c-0.1,0-0.1,0-0.2,0C73.4,0,73.4,0,73.3,0z"
        />
      </SvgIcon>
    );
  }
);

DizzboLogo.displayName = 'DizzboLogo';

export default DizzboLogo;
