import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { getRoute } from '@core/api';
import { QueryKeys } from '@core/config';
import { RouteType, UUIDType } from '@types';

export const useGetRoute = (
  routeUUID: UUIDType
): UseQueryResult<RouteType, Error> => {
  return useQuery({
    queryKey: [QueryKeys.ROUTES, routeUUID],
    queryFn: () => getRoute(routeUUID),
    enabled: !!routeUUID,
  });
};
