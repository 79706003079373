import React from 'react';
import { useRoutes } from 'react-router-dom';

import { AuthGuard } from '@dizzbo/core/routes/auth-guard';
import { GuestGuard } from '@dizzbo/core/routes/guest-guard';
import { LoginView } from '@dizzbo/core/views/LoginView';
import { OAuthRedirectView } from '@dizzbo/core/views/OAuthRedirectView';

import { DashboardLayout } from '@core/layouts';

import { DashboardView } from '@dashboard/views/DashboardView';
import { OrderListView } from '@orders/views/OrderListView';

import { OrderDetailView } from '../../order-detail/views/OrderDetailView';
import { TourListView, TourDetailView } from '@tours/views';
import { PlannerView } from '@planner/views/PlannerView';
import { SchedulerView } from '@scheduler/views/SchedulerView';
import { RealtimeListView } from '@realtime/views/RealtimeListView';
import { RealtimeDetailView } from '@realtime/views/RealtimeDetailView';

import { MapPreviewView } from '@core/views/MapPreviewView';
import HeroBackgroundImage from '../../../assets/images/home-hero.jpg';

export const Router = () => {
  return useRoutes([
    {
      path: '/login',
      element: (
        <GuestGuard>
          <LoginView bgImage={HeroBackgroundImage} />
        </GuestGuard>
      ),
    },
    {
      path: '/oauth/login',
      element: (
        <GuestGuard>
          <OAuthRedirectView bgImage={HeroBackgroundImage} />
        </GuestGuard>
      ),
    },
    {
      path: '/map-preview',
      element: <MapPreviewView />,
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: <DashboardView />,
        },
        {
          path: '/orders',
          children: [
            { index: true, element: <OrderListView /> },
            { path: '/orders/:uuid', element: <OrderDetailView /> },
          ],
        },
        {
          path: '/tours',
          children: [
            { index: true, element: <TourListView /> },
            { path: '/tours/:uuid', element: <TourDetailView /> },
          ],
        },
        {
          path: '/planner',
          element: <PlannerView />,
        },
        {
          path: '/realtime',
          children: [
            { index: true, element: <RealtimeListView /> },
            { path: '/realtime/:id', element: <RealtimeDetailView /> },
          ],
        },
        {
          path: '/scheduler',
          element: <SchedulerView />,
        },
      ],
    },
  ]);
};
