import { IconButton as MuiIconButton } from '@mui/material';
import { IconButtonPropsSizeOverrides } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { OverridableStringUnion } from '@mui/types';
import React, { ComponentProps, forwardRef } from 'react';

interface IconButtonPropsOverrides {
  /**
   * The size of the component.
   * @default 'medium'
   */

  size?: OverridableStringUnion<
    'medium' | 'large',
    IconButtonPropsSizeOverrides
  >;
  /**
   * The variant to use.
   * @default 'primary'
   */
  variant?:
    | 'primary'
    | 'secondary'
    | 'secondary-contrast'
    | 'tertiary'
    | 'tertiary-contrast';
}

type MuiIconButtonProps = ComponentProps<typeof MuiIconButton>;

type IconButtonProps = Omit<
  MuiIconButtonProps,
  | 'centerRipple'
  | 'disableRipple'
  | 'disableTouchRipple'
  | 'disableFocusRipple'
  | 'focusRipple'
  | 'focusVisibleClassName'
  | 'TouchRippleProps'
  | 'touchRippleRef'
  | 'size'
> &
  IconButtonPropsOverrides;

const MuiIconButtonStyle = styled(MuiIconButton, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<IconButtonProps>(({ theme, variant }) => {
  let style;

  style = {
    backgroundColor: theme.styles.button.primary.default.backgroundColor,
    '&:hover': {
      backgroundColor: theme.styles.button.primary.hover.backgroundColor,
    },
    '&:active': {
      backgroundColor: theme.styles.button.primary.active.backgroundColor,
      color: theme.styles.button.primary.active.color,
    },
    '&:focus-visible': {
      boxShadow: `0px 0px 0px 3px ${theme.styles.button.primary.focus.boxShadowColor};`,
    },
    '&.Mui-disabled': {
      color: theme.styles.button.primary.disabled.color,
      backgroundColor: theme.styles.button.primary.disabled.backgroundColor,
    },
    '&.MuiIconButton-colorWarning': {
      backgroundColor:
        theme.styles.button.primaryDestructive.default.backgroundColor,
      color: theme.styles.button.primaryDestructive.default.color,
      '&:hover': {
        color: theme.styles.button.primaryDestructive.hover.color,
        backgroundColor:
          theme.styles.button.primaryDestructive.hover.backgroundColor,
      },
      '&:active': {
        color: theme.styles.button.primaryDestructive.active.color,
        backgroundColor:
          theme.styles.button.primaryDestructive.active.backgroundColor,
      },
      '&:focus-visible': {
        color: theme.styles.button.primaryDestructive.focus.color,
        backgroundColor:
          theme.styles.button.primaryDestructive.focus.backgroundColor,
        boxShadow: `inset 0px 0px 0px 3px ${theme.styles.button.primaryDestructive.focus.boxShadowColor};`,
      },
      '&.Mui-disabled': {
        color: theme.styles.button.primaryDestructive.disabled.color,
        backgroundColor:
          theme.styles.button.primaryDestructive.disabled.backgroundColor,
      },
    },
  };

  if (variant === 'secondary') {
    style = {
      backgroundColor: theme.styles.button.secondary.default.backgroundColor,
      '&:hover': {
        backgroundColor: theme.styles.button.secondary.hover.backgroundColor,
      },
      '&:active': {
        color: theme.styles.button.secondary.active.color,
        backgroundColor: theme.styles.button.secondary.active.backgroundColor,
      },
      '&:focus-visible': {
        boxShadow: `inset  0px 0px 0px 3px ${theme.styles.button.secondary.focus.boxShadowColor};`,
      },
      '&.Mui-disabled': {
        color: theme.styles.button.secondary.disabled.color,
        backgroundColor: theme.styles.button.secondary.disabled.backgroundColor,
      },
    };
  }

  if (variant === 'secondary-contrast') {
    style = {
      color: theme.styles.button.secondaryContrast.default.color,
      backgroundColor:
        theme.styles.button.secondaryContrast.default.backgroundColor,
      '&:hover': {
        color: theme.styles.button.secondaryContrast.hover.color,
        backgroundColor:
          theme.styles.button.secondaryContrast.hover.backgroundColor,
      },
      '&:active': {
        color: theme.styles.button.secondaryContrast.active.color,
        backgroundColor:
          theme.styles.button.secondaryContrast.active.backgroundColor,
      },
      '&:focus-visible': {
        color: theme.styles.button.secondaryContrast.focus.color,
        backgroundColor:
          theme.styles.button.secondaryContrast.focus.backgroundColor,
        boxShadow: `inset  0px 0px 0px 3px ${theme.styles.button.secondaryContrast.focus.boxShadowColor};`,
      },
      '&.Mui-disabled': {
        color: theme.styles.button.secondaryContrast.disabled.color,
        backgroundColor:
          theme.styles.button.secondaryContrast.disabled.backgroundColor,
      },
    };
  }

  if (variant === 'tertiary') {
    style = {
      color: theme.styles.button.tertiary.default.color,
      '&:hover': {
        backgroundColor: 'transparent',
        boxShadow: `inset 0px 0px 0px 2px ${theme.styles.button.tertiary.hover.boxShadowColor};`,
      },
      '&:active': {
        backgroundColor: 'transparent',
        color: theme.styles.button.tertiary.active.color,
        boxShadow: `inset 0px 0px 0px 2px ${theme.styles.button.tertiary.active.boxShadowColor};`,
      },
      '&:focus-visible': {
        backgroundColor: 'transparent',
        boxShadow: `inset 0px 0px 0px 3px ${theme.styles.button.tertiary.focus.boxShadowColor};`,
      },
      '&.Mui-disabled': {
        backgroundColor: 'transparent',
        color: theme.styles.button.tertiary.disabled.color,
      },
      '&.MuiIconButton-colorWarning': {
        color: theme.styles.button.tertiaryDestructive.default.color,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent',
          boxShadow: `inset 0px 0px 0px 2px ${theme.styles.button.tertiaryDestructive.hover.boxShadowColor};`,
        },
        '&:active': {
          color: theme.styles.button.tertiaryDestructive.active.color,
          backgroundColor: 'transparent',
          '&:hover': {
            boxShadow: `inset 0px 0px 0px 2px ${theme.styles.button.tertiaryDestructive.active.boxShadowColor};`,
          },
        },
        '&:focus-visible': {
          backgroundColor: 'transparent',
          boxShadow: `inset 0px 0px 0px 3px ${theme.styles.button.tertiaryDestructive.focus.boxShadowColor};`,
        },
        '&.Mui-disabled': {
          color: theme.styles.button.tertiaryDestructive.disabled.color,
          backgroundColor: 'transparent',
        },
      },
    };
  }

  if (variant === 'tertiary-contrast') {
    style = {
      color: theme.styles.button.tertiaryContrast.default.color,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
        boxShadow: `inset 0px 0px 0px 2px ${theme.styles.button.tertiaryContrast.hover.boxShadowColor};`,
      },
      '&:active': {
        color: theme.styles.button.tertiaryContrast.active.color,
        backgroundColor: 'transparent',
        boxShadow: `inset 0px 0px 0px 2px ${theme.styles.button.tertiaryContrast.active.boxShadowColor};`,
      },
      '&:focus-visible': {
        backgroundColor: 'transparent',
        boxShadow: `inset 0px 0px 0px 3px ${theme.styles.button.tertiaryContrast.focus.boxShadowColor};`,
      },
      '&.Mui-disabled': {
        backgroundColor: 'transparent',
        color: theme.styles.button.tertiaryContrast.disabled.color,
      },
      '&.MuiIconButton-colorWarning': {
        color: theme.styles.button.tertiaryDestructiveContrast.default.color,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent',
          boxShadow: `inset 0px 0px 0px 2px ${theme.styles.button.tertiaryDestructiveContrast.hover.boxShadowColor};`,
        },
        '&:active': {
          color: theme.styles.button.tertiaryDestructiveContrast.active.color,
          backgroundColor: 'transparent',
          boxShadow: `inset 0px 0px 0px 2px ${theme.styles.button.tertiaryDestructiveContrast.active.boxShadowColor};`,
        },
        '&:focus-visible': {
          backgroundColor: 'transparent',
          boxShadow: `inset 0px 0px 0px 3px ${theme.styles.button.tertiaryDestructiveContrast.focus.boxShadowColor};`,
        },
        '&.Mui-disabled': {
          color: theme.styles.button.tertiaryDestructiveContrast.disabled.color,
          backgroundColor: 'transparent',
        },
      },
    };
  }
  return {
    ...style,
  };
});

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children, variant, ...rest }, ref) => {
    return (
      <MuiIconButtonStyle variant={variant} ref={ref} {...rest}>
        {children}
      </MuiIconButtonStyle>
    );
  }
);

IconButton.displayName = 'IconButton';
