import { axiosAuthenticatedRequest } from '@dizzbo/core/api';
import {
  CustomerContactType,
  DeliveryType,
  OrderCommentType,
  OrderEventType,
  OrderTodoType,
  OrderType,
  PaginatedListType,
  StopType,
  UUIDType,
} from '@types';

export const getOrders = async (params?: object) => {
  const res = await axiosAuthenticatedRequest.get<PaginatedListType<OrderType>>(
    `/v2/orders/`,
    {
      params: params,
      paramsSerializer: {
        indexes: null, // by default: false
      },
    }
  );
  return res.data;
};

export const getOrder = async (orderUUID: UUIDType) => {
  const res = await axiosAuthenticatedRequest.get<OrderType>(
    `/v2/orders/${orderUUID}/`
  );
  return res.data;
};

export const updateOrder = async ({
  orderUUID,
  orderData,
}: {
  orderUUID: UUIDType;
  orderData: Partial<OrderType>;
}) => {
  const res = await axiosAuthenticatedRequest.patch<OrderType>(
    `/v2/orders/${orderUUID}/`,
    orderData
  );
  return res.data;
};

export const createOrder = async (formData: Partial<OrderType>) => {
  const res = await axiosAuthenticatedRequest.post<OrderType>(
    '/v2/orders/',
    formData
  );
  return res.data;
};

export const deleteOrder = async (orderUUID: UUIDType) => {
  const res = await axiosAuthenticatedRequest.delete<void>(
    `/v2/orders/${orderUUID}/`
  );
  return res.data;
};

export const copyOrder = async (orderUUID: UUIDType) => {
  const res = await axiosAuthenticatedRequest.post<OrderType>(
    `/v2/orders/${orderUUID}/copy/`
  );
  return res.data;
};

export const confirmOrder = async (orderUUID: UUIDType) => {
  const res = await axiosAuthenticatedRequest.post<OrderType>(
    `/v2/orders/${orderUUID}/confirmations/`
  );
  return res.data;
};

export const reviewOrder = async (orderUUID: UUIDType) => {
  const res = await axiosAuthenticatedRequest.post<OrderType>(
    `/v2/orders/${orderUUID}/review/`
  );
  return res.data;
};

export const cancelOrder = async (orderUUID: UUIDType) => {
  const res = await axiosAuthenticatedRequest.post<OrderType>(
    `/v2/orders/${orderUUID}/cancel/`
  );
  return res.data;
};

/* Order Documents -----------------------------------------------------------*/

export const getOrderDocumentList = async (orderUUID: UUIDType) => {
  const res = await axiosAuthenticatedRequest.get(
    `/v2/orders/${orderUUID}/orderdocuments/`
  );
  return res.data;
};

export const createOrderDocument = async (
  orderUUID: UUIDType,
  data: any,
  onProgress
): Promise<any> => {
  const res = await axiosAuthenticatedRequest.post(
    `/v2/orders/${orderUUID}/orderdocuments/`,
    data,
    {
      onUploadProgress: onProgress,
    }
  );
  return res.data;
};

export const deleteOrderDocument = async (
  orderUUID: UUIDType,
  documentUUID: UUIDType
) => {
  const res = await axiosAuthenticatedRequest.delete<void>(
    `/v2/orders/${orderUUID}/orderdocuments/${documentUUID}`
  );
  return res.data;
};

/* Order Stops -----------------------------------------------------------*/

export const getOrderStops = async (orderUUID: UUIDType) => {
  const res = await axiosAuthenticatedRequest.get<StopType[]>(
    `/v2/orders/${orderUUID}/stops/`
  );
  return res.data;
};

export interface PostStopsPayloadStopType
  extends Omit<Partial<StopType>, 'loadingPoint'> {
  uuid: UUIDType;
  loadingPoint: string;
}

export const createOrderStop = async (
  orderUUID: UUIDType,
  data: OrderType | null
) => {
  const res = await axiosAuthenticatedRequest.post(
    `/v2/orders/${orderUUID}/stops/`,
    data
  );
  return res.data;
};

export const updateOrderStops = async (
  orderUUID: UUIDType,
  data: PostStopsPayloadStopType[]
) => {
  const res = await axiosAuthenticatedRequest.post<StopType[]>(
    `/v2/orders/${orderUUID}/stops/`,
    data
  );
  return res.data;
};

export const getOrderStop = async (orderUUID: UUIDType, stopUUID: UUIDType) => {
  const res = await axiosAuthenticatedRequest.get<StopType>(
    `/v2/orders/${orderUUID}/stops/${stopUUID}/`
  );
  return res.data;
};

export const updateOrderStop = async (
  orderUUID: UUIDType,
  stopUUID: UUIDType,
  data: Partial<StopType>
) => {
  const res = await axiosAuthenticatedRequest.patch<StopType>(
    `/v2/orders/${orderUUID}/stops/${stopUUID}/`,
    data
  );
  return res.data;
};

export const deleteOrderStop = async (
  orderUUID: UUIDType,
  stopUUID: UUIDType
) => {
  const res = await axiosAuthenticatedRequest.delete<void>(
    `/v2/orders/${orderUUID}/stops/${stopUUID}/`
  );
  return res.data;
};

/* Order Deliveries -----------------------------------------------------------*/

export const getOrderDeliveryList = async (
  orderUUID: UUIDType,
  params?: object
) => {
  const res = await axiosAuthenticatedRequest.get(
    `/v2/orders/${orderUUID}/deliveries/`,
    { params: params }
  );
  return res.data;
};

export const createOrderDelivery = async (orderUUID: UUIDType, data) => {
  const res = await axiosAuthenticatedRequest.post(
    `/v2/orders/${orderUUID}/deliveries/`,
    data
  );
  return res.data;
};

export const deleteOrderDelivery = async (
  orderUUID: UUIDType,
  deliveryUUID: UUIDType
) => {
  const res = await axiosAuthenticatedRequest.delete<void>(
    `/v2/orders/${orderUUID}/deliveries/${deliveryUUID}/`
  );
  return res.data;
};

export const updateOrderDelivery = async (
  orderUUID: UUIDType,
  deliveryUUID: UUIDType,
  data: Partial<DeliveryType>
) => {
  const res = await axiosAuthenticatedRequest.patch<DeliveryType>(
    `/v2/orders/${orderUUID}/deliveries/${deliveryUUID}/`,
    data
  );
  return res.data;
};

/* Order Goods -----------------------------------------------------------*/

export const getDeliveryGoodList = async (
  deliveryUUID: UUIDType,
  params?: object
) => {
  const res = await axiosAuthenticatedRequest.get(
    `/v2/deliveries/${deliveryUUID}/deliverygoods/`,
    { params: params }
  );
  return res.data;
};

export const createDeliveryGood = async (deliveryUUID: UUIDType, data) => {
  const res = await axiosAuthenticatedRequest.post(
    `/v2/deliveries/${deliveryUUID}/deliverygoods/`,
    data
  );
  return res.data;
};

export const updateDeliveryGood = async (
  deliveryUUID: UUIDType,
  deliveryGoodUUID: UUIDType,
  data
) => {
  const res = await axiosAuthenticatedRequest.patch(
    `/v2/deliveries/${deliveryUUID}/deliverygoods/${deliveryGoodUUID}/`,
    data
  );
  return res.data;
};

export const deleteDeliveryGood = async (
  deliveryUUID: UUIDType,
  deliveryGoodUUID: UUIDType
) => {
  const res = await axiosAuthenticatedRequest.delete<void>(
    `/v2/deliveries/${deliveryUUID}/deliverygoods/${deliveryGoodUUID}/`
  );
  return res.data;
};

/* Order Type of Goods -----------------------------------------------------------*/

export const getTypeOfGoods = async () => {
  const res = await axiosAuthenticatedRequest.get('/v2/typesofgoods/');
  return res.data;
};

/* Order Units of Measurement -----------------------------------------------------------*/

export const getUnitsOfMeasurement = async () => {
  const res = await axiosAuthenticatedRequest.get('/v2/unitsofmeasurement/');
  return res.data;
};

/* Order PoDs -----------------------------------------------------------*/

export const createOrderProofOfDelivery = async (
  orderUUID: UUIDType,
  data: any,
  onProgress
): Promise<any> => {
  const res = await axiosAuthenticatedRequest.post(
    `/v2/orders/${orderUUID}/pods/`,
    data,
    {
      onUploadProgress: onProgress,
    }
  );
  return res.data;
};

export const getOrderProofOfDeliveryList = async (orderUUID: UUIDType) => {
  const res = await axiosAuthenticatedRequest.get(
    `/v2/orders/${orderUUID}/pods/`
  );
  return res.data;
};

export const updateOrderProofOfDelivery = async (
  orderUUID: UUIDType,
  podUUID: UUIDType,
  data
) => {
  const res = await axiosAuthenticatedRequest.patch(
    `/v2/orders/${orderUUID}/pods/${podUUID}/`,
    data
  );
  return res.data;
};

export const getOrderProofOfDelivery = async (
  orderUUID: UUIDType,
  podUUID: UUIDType
) => {
  const res = await axiosAuthenticatedRequest.get(
    `/v2/orders/${orderUUID}/pods/${podUUID}/`
  );
  return res.data;
};

export const deleteOrderProofOfDelivery = async (
  orderUUID: UUIDType,
  podUUID: UUIDType
) => {
  const res = await axiosAuthenticatedRequest.delete<void>(
    `/v2/orders/${orderUUID}/pods/${podUUID}/`
  );
  return res.data;
};

/* Order Contacts -----------------------------------------------------------*/

export const getOrderContacts = async (
  orderUUID: UUIDType
): Promise<CustomerContactType[]> => {
  const res = await axiosAuthenticatedRequest.get(
    `/v2/orders/${orderUUID}/contacts/`
  );
  return res.data;
};

export const createOrderContact = async (orderUUID: UUIDType, data) => {
  const res = await axiosAuthenticatedRequest.post(
    `/v2/orders/${orderUUID}/contacts/`,
    data
  );
  return res.data;
};

export const deleteOrderContact = async (
  orderUUID: UUIDType,
  contactUUID: UUIDType
) => {
  const res = await axiosAuthenticatedRequest.delete<void>(
    `/v2/orders/${orderUUID}/contacts/${contactUUID}`
  );
  return res.data;
};

/* Order Todos -----------------------------------------------------------*/

export const getOrderTodos = async (orderUUID: UUIDType) => {
  const res = await axiosAuthenticatedRequest.get(
    `/v2/orders/${orderUUID}/todos/`
  );
  return res.data;
};

export const getOrderTodoTasks = async (
  orderUUID: UUIDType,
  orderTODO: OrderTodoType
) => {
  const res = await axiosAuthenticatedRequest.get(
    `/v2/orders/${orderUUID}/todos/${orderTODO}/`
  );
  return res.data;
};

/* Order Comments --------------------------------------------------------*/

export const getOrderComments = async (
  orderUUID: UUIDType,
  params?: object
) => {
  const res = await axiosAuthenticatedRequest.get<
    PaginatedListType<OrderCommentType>
  >(`/v2/orders/${orderUUID}/comments/`, {
    params: params,
    paramsSerializer: {
      indexes: null, // by default: false
    },
  });
  return res.data;
};

export const createOrderComment = async (orderUUID: UUIDType, data) => {
  const res = await axiosAuthenticatedRequest.post(
    `/v2/orders/${orderUUID}/comments/`,
    data
  );
  return res.data;
};

export const getOrderEvents = async (orderUUID: UUIDType) => {
  const res = await axiosAuthenticatedRequest.get<OrderEventType[]>(
    `/v2/orders/${orderUUID}/events/`
  );
  return res.data;
};
