import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AssignIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M6 3H22V21H6V16.5H8V19H20V5H8V7H6V3Z" />
        <path d="M2 13V11H12.377L9.99995 8.50218L11.4295 7L16.1876 11.9999L11.4295 16.9998L9.99995 15.4976L12.3768 13H2Z" />
      </SvgIcon>
    );
  }
);

AssignIcon.displayName = 'AssignIcon';

export default AssignIcon;
