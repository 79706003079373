import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import React, { ComponentProps, forwardRef } from 'react';

type MuiDateTimePickerProps = ComponentProps<typeof MuiDateTimePicker>;

export const DateTimePicker = forwardRef<
  HTMLDivElement,
  MuiDateTimePickerProps
>((props, ref) => <MuiDateTimePicker ref={ref} {...props} />);

DateTimePicker.displayName = 'DateTimePicker';
