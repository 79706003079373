import axios, { AxiosResponse, AxiosError } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { DIZZBO_TMS_API_URL } from './config';
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  deleteTokens,
} from './jwt';
import i18n from '@dizzbo/core/i18n/i18n';
import { isValidToken } from '@dizzbo/core';

export const axiosRequest = axios.create({
  baseURL: DIZZBO_TMS_API_URL,
  timeout: 0,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
    'Accept-Language': i18n.language,
  },
});

axiosRequest.interceptors.request.use((config) => {
  const newConfig = { ...config };

  if (newConfig.headers['Content-Type'] === 'multipart/form-data')
    return newConfig;

  if (config.params) {
    newConfig.params = decamelizeKeys(config.params);
  }
  if (config.data) {
    newConfig.data = decamelizeKeys(config.data);
  }
  return newConfig;
});

axiosRequest.interceptors.response.use((response: AxiosResponse) => {
  if (
    response.data &&
    response.headers['content-type'] === 'application/json'
  ) {
    response.data = camelizeKeys(response.data);
  }
  return response;
});

export async function refreshToken() {
  const refreshToken = getRefreshToken();

  // If the refreshToken is not valid, logout the user by deleting the tokens.
  if (!refreshToken || !isValidToken(refreshToken)) {
    deleteTokens();
    return;
  }
  const refreshBody = { refresh: JSON.parse(refreshToken) };

  try {
    const response = await axiosRequest.post(
      `/auth/token/refresh/`,
      refreshBody
    );
    setAccessToken(response.data.access);
    return response.data.access;
  } catch (error) {
    console.log('DELETE TOKENS - axios refreshToken');
    deleteTokens();
  }
}

async function errorInterceptor(error: AxiosError) {
  const originalRequest = error.config;

  if (error.response?.status === 401) {
    try {
      const newAccessToken = await refreshToken();
      if (!newAccessToken) return;

      const headerAuthorization = `Bearer ${newAccessToken}`;
      axiosAuthenticatedRequest.defaults.headers['Authorization'] =
        headerAuthorization;
      originalRequest.headers['Authorization'] = headerAuthorization;
      const response = await axiosAuthenticatedRequest(originalRequest);
      if (response.status !== 200) {
        console.log('DELETE TOKENS - axios errorInterceptor');
        deleteTokens();
        axiosAuthenticatedRequest.defaults.headers['Authorization'] = '';
        window.location.reload();
      }
      return response;
    } catch (error) {
      // if token refresh fails, logout the user to avoid potential security risks.
      console.log('DELETE TOKENS - axios errorInterceptor error');
      deleteTokens();
      axiosAuthenticatedRequest.defaults.headers['Authorization'] = '';
      window.location.reload();
    }
  }

  return Promise.reject(error);
}

export const axiosAuthenticatedRequest = axios.create({
  baseURL: DIZZBO_TMS_API_URL,
  timeout: 0,
  headers: {
    Authorization: `Bearer ${getAccessToken()}`,
    'Content-Type': 'application/json',
    'Accept-Language': i18n.language,
  },
});

axiosAuthenticatedRequest.interceptors.request.use((config) => {
  const newConfig = { ...config };

  if (newConfig.headers['Content-Type'] === 'multipart/form-data')
    return newConfig;

  if (config.params) {
    newConfig.params = decamelizeKeys(config.params);
  }
  if (config.data) {
    newConfig.data = decamelizeKeys(config.data);
  }
  return newConfig;
});

axiosAuthenticatedRequest.interceptors.response.use(
  (response: AxiosResponse) => {
    if (
      response.data &&
      response.headers['content-type'] === 'application/json'
    ) {
      response.data = camelizeKeys(response.data);
    }
    return response;
  },
  errorInterceptor
);

export const setlanguageHeader = (language: string) => {
  axiosRequest.defaults.headers['Accept-Language'] = language;
  axiosAuthenticatedRequest.defaults.headers['Accept-Language'] = language;
};
