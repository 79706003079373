import { Slider as MuiSlider } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiSliderProps = ComponentProps<typeof MuiSlider>;

export const Slider = forwardRef<HTMLDivElement, MuiSliderProps>(
  (props, ref) => <MuiSlider {...props} ref={ref} />
);

Slider.displayName = 'Slider';
