import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const BarsFilledIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path fillRule="evenodd" clipRule="evenodd" d="M2 7V2H22V7H2Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2 14V9H19V14H2Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2 21V16H14V21H2Z" />
      </SvgIcon>
    );
  }
);

BarsFilledIcon.displayName = 'BarsFilledIcon';

export default BarsFilledIcon;
