import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SkipLeftIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M19.7282 4.18537L18.2718 2.81464L9.62671 12L18.2718 21.1854L19.7282 19.8146L12.3732 12L19.7282 4.18537Z" />
        <path d="M5 3V21H7L7 3L5 3Z" />
      </SvgIcon>
    );
  }
);

SkipLeftIcon.displayName = 'SkipLeftIcon';

export default SkipLeftIcon;
