import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const FullscreenExitIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M22 22H6.24273L8.24273 20H20L20 8.2427L22 6.2427L22 22Z" />
        <path d="M17.7575 1.99997L2 1.99997L2 17.7574L4 15.7574L4 3.99997L15.7575 3.99997L17.7575 1.99997Z" />
        <path d="M18.6716 11L13.0148 11L13.0148 5.34311L15.1361 7.46443L20.0858 2.51468L21.5001 3.9289L16.5503 8.87864L18.6716 11Z" />
        <path d="M5.34315 13H11V18.6568L8.87869 16.5355L3.92894 21.4853L2.51473 20.071L7.46447 15.1213L5.34315 13Z" />
      </SvgIcon>
    );
  }
);

FullscreenExitIcon.displayName = 'FullscreenExitIcon';

export default FullscreenExitIcon;
