import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Tabs, Tab } from '@dizzbo/ui';
import { LanguageIcon, AccountIcon } from '@dizzbo/ui';

import { Language, Account } from './Settings';

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      paddingLeft={6}
      paddingRight={6}
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

type SettingsNavigationProps = {
  activeTab?: number;
};

export const SettingsNavigation: React.FC<SettingsNavigationProps> = ({
  activeTab,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(activeTab);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        flexDirection: 'row',
        bgcolor: 'background.paper',
      }}
    >
      <Box sx={{ height: '100%' }}>
        <Tabs
          orientation='vertical'
          // variant='scrollable'
          value={value}
          onChange={handleChange}
          aria-label='Vertical tabs example'
          sx={{ borderRight: 1, borderColor: 'divider', alignItems: 'start' }}
        >
          <Tab
            label={t('account')}
            icon={
              <AccountIcon sx={{ width: 20, height: 20, marginRight: 6 }} />
            }
            iconPosition='start'
            {...a11yProps(0)}
          />
          <Tab
            label={t('language')}
            icon={
              <LanguageIcon sx={{ width: 20, height: 20, marginRight: 6 }} />
            }
            iconPosition='start'
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <Box sx={{ height: '100%', width: '100%' }}>
        <TabPanel value={value} index={0}>
          <Account />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Language />
        </TabPanel>
      </Box>
    </Box>
  );
};
