import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ChevronLeftIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 11.2929L15.7071 1.58578L17.1213 3L8.82843 11.2929L17.1213 19.5858L15.7071 21L6 11.2929Z"
        />
      </SvgIcon>
    );
  }
);

ChevronLeftIcon.displayName = 'ChevronLeftIcon';

export default ChevronLeftIcon;
