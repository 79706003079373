import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DragIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.6579 5H15V3H21V9H19V6.48636L13.4864 12L19 17.5136V15H21V21H15V19H17.6579L12.0721 13.4142L6.48636 19H9V21H3V15H5L5 17.6579L10.6579 12L5 6.34207L5 9L3 9V3H9L9 5L6.48636 5L12.0721 10.5858L17.6579 5ZM5 18.9737V19H5.02635L5 18.9737ZM5 5H5.02634L5 5.02634V5Z"
        />
      </SvgIcon>
    );
  }
);

DragIcon.displayName = 'DragIcon';

export default DragIcon;
