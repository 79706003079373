export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';

const deleteTokens = () => {
  window.localStorage.removeItem(ACCESS_TOKEN);
  window.localStorage.removeItem(REFRESH_TOKEN);
};

const setAccessToken = (token) => {
  window.localStorage.setItem(ACCESS_TOKEN, token);
};

const setRefreshToken = (token) => {
  window.localStorage.setItem(REFRESH_TOKEN, token);
};

const getAccessToken = () => {
  return window.localStorage.getItem(ACCESS_TOKEN);
};

const getRefreshToken = () => {
  return window.localStorage.getItem(REFRESH_TOKEN);
};

export {
  deleteTokens,
  setAccessToken,
  setRefreshToken,
  getAccessToken,
  getRefreshToken,
};
