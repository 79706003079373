import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ChevronUpIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 6L21.7071 15.7071L20.2928 17.1213L12 8.82843L3.70706 17.1213L2.29285 15.7071L12 6Z"
        />
      </SvgIcon>
    );
  }
);

ChevronUpIcon.displayName = 'ChevronUpIcon';

export default ChevronUpIcon;
