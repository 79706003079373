import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';

import { Box } from '@dizzbo/ui';

import './styles.no-css-modules.scss';

const ContainerStyles = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  minWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  alingItems: 'center',
  justifyContent: 'center',

  // GRID HEADER ---------------------------------------------------------------
  '& .b-grid-header': {
    color: theme.styles.scheduler.gridHeader.color,
  },

  '& .b-grid-header-container': {
    backgroundColor: '#ffffff',
    borderBottom: `1px solid ${theme.styles.scheduler.gridHeader.borderColor}`,
    boxShadow: theme.shadows[2],
  },

  '& .b-grid-footer-scroller, .b-grid-header-scroller': {
    backgroundColor: theme.styles.scheduler.timeline.backgroundColor,
    ...theme.typography.label,
  },

  '& .b-grid-header.b-depth-0': {
    // need to remove the custom padding. this messes with the alignment of the day grid/weekends
    // paddingInline: '12px 12px',
  },

  '& .b-grid-header-container .b-sch-timeaxiscolumn': {
    backgroundColor: '#ffffff',
  },

  '& .b-grid-header-container .b-sch-timeaxiscolumn.b-depth-0:hover': {
    backgroundColor: '#ffffff!important',
  },

  '& .b-grid-panel-body': {
    // backgroundColor: theme.styles.scheduler.gridPanelBody.backgroundColor,
  },

  '& .b-sch-header-timeaxis-cell ': {
    borderBottom: `1px solid ${theme.styles.scheduler.gridHeader.borderColor}`,
  },

  '& .b-horizontaltimeaxis .b-sch-header-timeaxis-cell ': {
    borderInlineStart: `1px solid ${theme.styles.scheduler.gridHeader.borderColor}`,
  },

  '& .b-sch-header-timeaxis-cell': {
    color: theme.styles.scheduler.gridHeader.color,
    backgroundColor: theme.styles.scheduler.gridHeader.backgroundColor,
    ...theme.typography.label,
  },

  '& .b-horizontaltimeaxis .b-sch-header-row': {
    flex: '1 0 32px',
  },

  '& .b-grid-header .b-sch-timerange.b-sch-current-time': {
    backgroundColor:
      theme.styles.scheduler.currentTimeIndicator.backgroundColor,
    color: theme.styles.scheduler.currentTimeIndicator.color,
    height: '25%',
    bottom: 0,
  },

  '& .b-grid-headers .b-sch-nonworkingtime': {
    backgroundColor: theme.styles.scheduler.gridHeader.weekend.backgroundColor,
  },

  // END GRID HEADER

  // TIMELINE ------------------------------------------------------------------
  '& .b-timeline-subgrid': {
    backgroundColor: theme.styles.scheduler.timeline.backgroundColor,
  },
  '& .b-timeline-subgrid .b-sch-range': {
    backgroundColor: theme.styles.scheduler.timeline.weekend.backgroundColor,
  },
  // END TIMELINE

  // COLUMNS -------------------------------------------------------------------

  // container for column items
  '& .b-react-portal-container': {
    width: '100%',
    height: '100%',
  },

  '& .b-grid-cell': {
    padding: 0,
  },

  // END COLUMNS ---------------------------------------------------------------

  // EVENTS --------------------------------------------------------------------

  '& .b-timeline-subgrid .b-sch-current-time': {
    borderColor: theme.styles.scheduler.currentTimeIndicator.borderColor,
    borderLeftStyle: 'solid',
    borderLeftWidth: 4,
  },

  '.b-sch-event-wrap': {
    boxShadow: theme.shadows[0],
    transition: theme.transitions.create(['box-shadow']),
    '&.b-dragging': {
      boxShadow: theme.shadows[6],
      borderRadius: 12,
    },
  },

  '& .b-sch-event': {
    backgroundColor: 'transparent',

    '& .b-sch-event-content': {
      flex: 1,
      height: 64,
      boxShadow: `0px 0px 0px 1px ${theme.palette.oxford[10]};`,
      borderRadius: 12,
      width: '100%',
      overflow: 'auto',
      transition: theme.transitions.create(['box-shadow']),
      '&:hover': {
        boxShadow: `0px 0px 0px 2px ${theme.palette.turquoise[100]};`,
      },
    },
    '&.b-sch-event-selected': {
      '& .b-sch-event-content': {
        boxShadow: `0px 0px 0px 4px ${theme.palette.turquoise[100]};`,
      },
    },
    // drag handles of the event
    '&:before': {
      zIndex: 99999999,
      top: 18,
    },
    '&:after': {
      zIndex: 99999999,
      top: 18,
    },
  },

  '& .b-stickyevents.b-sch-horizontal .b-sch-event-wrap:not(.b-disable-sticky), .b-stickyevents.b-sch-horizontal .b-sch-resourcetimerange':
    { minWidth: 100 },
  '& .b-sch-event:not(.b-milestone) .b-sch-event-content': { margin: 0 },
  '& .b-jsx-container': {
    width: '100%',
    overflow: 'auto',
  },
  '& .b-sch-event-resizable-true': {
    '&:before': {
      backgroundImage: `linear-gradient(to right, ${theme.styles.scheduler.eventResizeHandle.color} 1px, transparent 1px)`,
    },
    '&:after': {
      backgroundImage: `linear-gradient(to right, ${theme.styles.scheduler.eventResizeHandle.color} 1px, transparent 1px)`,
    },
  },

  // END EVENTS ----------------------------------------------------------------
}));

type Props = {
  sx?: SxProps;
  children: React.ReactNode;
};

export const SchedulerStylesWrapper = React.forwardRef(
  (
    {
      children,
      sx,
      ...rest
    }: Props &
      React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
      >,
    ref
  ) => {
    return (
      <ContainerStyles ref={ref} sx={sx} {...rest}>
        {children}
      </ContainerStyles>
    );
  }
);

SchedulerStylesWrapper.displayName = 'SchedulerStylesWrapper';
