import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React from 'react';
dayjs.extend(localizedFormat);

import { Avatar, ListItem, Stack, Typography } from '@dizzbo/ui';
import { OrderEventType } from '@types';

type Props = {
  event: OrderEventType;
};

export const EventListItem: React.FC<Props> = ({ event }) => {
  const theme = useTheme();

  return (
    <ListItem alignItems="flex-start" sx={{ height: 'auto', paddingY: 4 }}>
      <Stack spacing={2} direction="column" sx={{ overflow: 'hidden' }}>
        <Stack spacing={2} direction="row" alignItems="center">
          <Typography
            variant="h6"
            color="primary"
            sx={{ textTransform: 'capitalize' }}
          >
            {event.contentType.model} {event.type}
          </Typography>
          <Typography variant="footnote" color="primary">
            {dayjs(event.createdAt).format('DD.MM.YYYY h:mm')}
          </Typography>
        </Stack>
        <Stack spacing={2} direction="row" alignItems="center">
          <Avatar
            username={event.user.displayName}
            sx={{
              width: 32,
              height: 32,
              ...theme.typography.bodyBoldSmall,
              backgroundColor: theme.palette.turquoise[100],
            }}
          />
          <Typography variant="bodyRegularSmall" color="primary.light">
            {event.user.displayName}
          </Typography>
        </Stack>
        <Stack spacing={0} direction="column">
          <Typography
            variant="bodyRegularSmall"
            color="primary.light"
            sx={{ textTransform: 'capitalize' }}
          >
            <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
              {JSON.stringify(event.data.fields, null, 2)}
            </pre>
          </Typography>
        </Stack>
      </Stack>
    </ListItem>
  );
};
