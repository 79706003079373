import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CloudDoneIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d='M13 4C10.8245 4 8.97112 5.38991 8.28425 7.33325L8.04859 8H7C4.23858 8 2 10.2386 2 13C2 15.7614 4.23858 18 7 18H17C19.7614 18 22 15.7614 22 13C22 11.6041 21.4276 10.3408 20.5043 9.43325L21.9183 8.01921C23.2033 9.28797 24 11.0505 24 13C24 16.866 20.866 20 17 20H7C3.13401 20 0 16.866 0 13C0 9.24452 2.95739 6.17978 6.67031 6.00763C7.79174 3.63961 10.2033 2 13 2C14.8463 2 16.5248 2.71454 17.7751 3.88173L16.3599 5.29696C15.4719 4.49092 14.2928 4 13 4Z' />
        <path d='M11.2071 16.1213L21.9142 5.41421L20.5 4L11.2071 13.2929L8.41421 10.5L7 11.9142L11.2071 16.1213Z' />
      </SvgIcon>
    );
  }
);

CloudDoneIcon.displayName = 'CloudDoneIcon';

export default CloudDoneIcon;
