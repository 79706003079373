import React, { useState } from 'react';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import type {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from 'react-table';

import { TourTable } from '../TourTable';
import { getTours } from '@core/api/tours';
import { QueryKeys } from '@core/config';
import { TourCalendar } from '../TourCalendar';

export const TourList: React.FC = ({ viewType }) => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  async function getToursWithParams(
    pagination,
    columnFilters,
    sorting,
    globalFilter
  ) {
    const urlSearchParams = new URLSearchParams([
      ['offset', `${pagination.pageIndex * pagination.pageSize}`],
    ]);
    urlSearchParams.set('limit', `${pagination.pageSize}`);

    // Filtering
    // ----------------------------------------------------------------------
    //   const status = {
    //     'pending': 0,
    //     'rejected': 1,
    //     'on road to load': 3,
    //     'on road to unload': 4,
    //     'fulfilled': 5,
    //     'invoiced': 6,
    //     'canceled': 7
    //   }

    for (let index = 0; index < columnFilters.length; index++) {
      urlSearchParams.append(
        columnFilters[index].id,
        columnFilters[index].value
      );
    }

    // Sorting
    // ----------------------------------------------------------------------
    let sortingParams: string;

    for (let index = 0; index < sorting.length; index++) {
      let sortingPrefix = '';
      if (!sorting[index].desc) {
        sortingPrefix = '-';
      }
      sortingParams = sortingPrefix + sorting[index].id;
    }
    if (sortingParams) {
      urlSearchParams.set('ordering', sortingParams);
    }

    // Search
    // ----------------------------------------------------------------------
    if (globalFilter) {
      urlSearchParams.set('search', globalFilter);
    }

    const params = Object.fromEntries(urlSearchParams);
    const resData = await getTours(params);
    return resData;
  }

  const { data, isError, isFetching, isPending, isStale } = useQuery({
    queryKey: [
      QueryKeys.TOURS,
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: () =>
      getToursWithParams(pagination, columnFilters, sorting, globalFilter),
    placeholderData: keepPreviousData,
  });

  return (
    <>
      {viewType === 'calendar' ? (
        <TourCalendar toursData={data?.results ? data.results : []} />
      ) : (
        <TourTable
          data={data}
          isLoading={isPending}
          isStale={isStale}
          isFetching={isFetching}
          isError={isError}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          sorting={sorting}
          setSorting={setSorting}
          pagination={pagination}
          setPagination={setPagination}
        />
      )}
    </>
  );
};
