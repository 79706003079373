import React from 'react';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import { useTranslation } from 'react-i18next';

import { Dialog, DialogContent, DialogTitle } from '@dizzbo/ui';

import { SettingsNavigation } from './SettingsNavigation';

type Props = {
  popupState: PopupState;
  activeTab?: number;
  setActiveTab: (tab: number) => void;
};
export const SettingsDialog: React.FC<Props> = ({
  popupState,
  activeTab,
  setActiveTab,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Dialog
        PaperProps={{
          elevation: 6,
          variant: 'filled-primary',
          sx: {
            maxWidth: 1400,
            minWidth: 800,
            minHeight: '80vh',
          },
        }}
        scroll='paper'
        {...bindDialog(popupState)}
        onClose={() => setActiveTab(0)}
      >
        <DialogTitle onClose={popupState.close}>{t('mySettings')}</DialogTitle>
        <DialogContent dividers>
          <SettingsNavigation activeTab={activeTab} />
        </DialogContent>
      </Dialog>
    </>
  );
};
