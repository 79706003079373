import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateOrderStop } from '@core/api';
import { QueryKeys } from '@core/config';
import { UUIDType } from '@types';

export const useUpdateOrderStop = (orderUUID: UUIDType, stopUUID: UUIDType) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values: Payload) =>
      updateOrderStop(orderUUID, stopUUID, values),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.STOPS, stopUUID],
      });
    },
  });
};
