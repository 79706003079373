import Popper from '@mui/material/Popper';
import { SxProps } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React, { useState } from 'react';

import { getContactsAutocomplete } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  Autocomplete,
  CheckIcon,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  PlusIcon,
  SearchIcon,
  TextField,
} from '@dizzbo/ui';

import { ContactType, UUIDType } from '@types';
import { useTranslation } from 'react-i18next';
import { CreateContactDialog } from './CreateContactDialog';

const styles = {
  popper: {
    width: 'fit-content',
  },
};

type Props = {
  label: string;
  value?: any;
  onChange?: (user: ContactType) => void;
  sx?: SxProps;
  display?: boolean;
  carrierUUID?: UUIDType;
  customerUUID?: UUIDType;
};

const ContactsPopper = function (props) {
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};

export const ContactsAutoCompleteTextField = React.forwardRef<
  HTMLElement,
  Props
>(
  (
    {
      label,
      value = null,
      onChange,
      display,
      sx,
      carrierUUID,
      customerUUID,
    }: Props,
    ref
  ) => {
    const popupState = usePopupState({
      variant: 'dialog',
      popupId: 'contactCreateDialog',
    });

    const [searchValue, setSearchValue] = useState('');
    const [open, setOpen] = React.useState(false);

    async function findContact(query) {
      const term = query.queryKey[1];
      const resData = await getContactsAutocomplete({
        q: term,
        ...(!!carrierUUID && { carrier: carrierUUID }),
        ...(!!customerUUID && { loader: customerUUID }),
      });
      return resData;
    }

    const { isFetching, data = [] } = useQuery({
      queryKey: [QueryKeys.CONTACTS, searchValue],
      queryFn: findContact,
      enabled: open && !!searchValue,
    });

    const handleClick = (e) => {
      popupState.open();
      e.stopPropagation();
    };

    const { t } = useTranslation();

    const PaperComponent = (props: any) => {
      const { children, ...other } = props;
      return (
        <Paper {...other}>
          {children}
          <List>
            <ListItem onClick={(e) => e.preventDefault()}>
              <ListItemButton onMouseDown={handleClick}>
                <ListItemIcon>
                  <PlusIcon sx={{ width: 16, height: 16 }} />
                </ListItemIcon>
                <ListItemText>{t('createNewContact')}</ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Paper>
      );
    };

    return (
      <>
        <Autocomplete
          ref={ref}
          sx={{
            ...sx,
          }}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          disableCloseOnSelect
          loading={isFetching}
          autoComplete
          autoHighlight
          blurOnSelect
          clearOnEscape
          clearOnBlur
          popupIcon={<SearchIcon />}
          PaperComponent={PaperComponent}
          PopperComponent={ContactsPopper}
          componentsProps={{
            popper: {
              sx: {
                width: 'auto',
              },
            },
          }}
          noOptionsText="No contact found"
          options={data}
          value={value}
          filterOptions={(x) => x}
          renderInput={(params) => (
            <TextField {...params} display={display} label={label} />
          )}
          getOptionLabel={(option) => option.email}
          isOptionEqualToValue={(option, inputValue) =>
            option.uuid === inputValue.uuid
          }
          onChange={(
            event: any,
            newValue: ContactType | null,
            reason: string
          ) => {
            onChange(newValue);
          }}
          onInputChange={(event, newValue: string, reason: string) => {
            if (reason !== 'reset') {
              setSearchValue(newValue);
            }
          }}
          renderOption={(props, contact: ContactType) => {
            const { className, ...rest } = props;

            let listItemProps = {};

            if (props['aria-selected']) {
              listItemProps = {
                secondaryAction: <CheckIcon sx={{ width: 16, height: 16 }} />,
              };
            }

            return (
              <ListItem {...rest} {...listItemProps}>
                <ListItemText>
                  {contact.displayName} — {contact.email}
                </ListItemText>
              </ListItem>
            );
          }}
        />

        <CreateContactDialog
          popupState={popupState}
          carrierUUID={carrierUUID}
          customerUUID={customerUUID}
          setContact={onChange}
        />
      </>
    );
  }
);

ContactsAutoCompleteTextField.displayName = 'ContactsAutoCompleteTextField';
