import React from 'react';
import { usePopupState } from 'material-ui-popup-state/hooks';

import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Divider,
  Box,
  Button,
  Skeleton,
  IconButton,
  Alert,
} from '@dizzbo/ui';
import { PlusIcon, LockedIcon } from '@dizzbo/ui';

import { useTourDetail } from '../../../hooks';
import { useGetCarrierContacts, useGetTourContacts } from '../../../queries';

import { AddContactsDialog } from './AddContactsDialog';
import { ContactListItem } from './ContactListItem';

type Props = {};

export const Contacts: React.FC<Props> = () => {
  const { tourData } = useTourDetail();

  const { data: carrierContactsData, isPending: isLoadingCarrierContacts } =
    useGetCarrierContacts(tourData.carrier?.uuid, !!tourData.carrier);

  const { data: contactsData, isPending: isLoadingContacts } =
    useGetTourContacts(tourData.uuid, !!tourData.carrier);

  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'customerContatsAddDialog',
  });

  const handleClick = (e) => {
    popupState.open();
    e.stopPropagation();
  };

  return tourData.carrier ? (
    <Card
      variant="filled-secondary"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
        minHeight: 374,
        opacity: tourData.carrier ? 1 : 0.5,
      }}
    >
      <CardHeader title="Contacts" variant="small" />
      <CardContent>
        <Stack
          spacing={6}
          divider={<Divider orientation="horizontal" flexItem />}
        >
          <Stack spacing={6}>
            {!isLoadingContacts && !isLoadingCarrierContacts ? (
              <>
                {carrierContactsData.map((contact) => (
                  <ContactListItem key={contact.uuid} contactData={contact} />
                ))}
                {contactsData.length > 0 && carrierContactsData.length > 0 && (
                  <Divider orientation="horizontal" flexItem />
                )}
                {contactsData.map((contact) => (
                  <ContactListItem
                    key={contact.uuid}
                    contactData={contact}
                    tourUUID={tourData.uuid}
                  />
                ))}
              </>
            ) : (
              <>
                <Skeleton width="100%" height={40} />
                <Skeleton width="100%" height={40} />
                <Skeleton width="100%" height={40} />
              </>
            )}
            <Box>
              <Button
                variant="tertiary"
                size="medium"
                startIcon={<PlusIcon />}
                onClick={handleClick}
              >
                Add Contacts
              </Button>
            </Box>
          </Stack>
        </Stack>
      </CardContent>
      <AddContactsDialog
        tourUUID={tourData.uuid}
        carrierUUID={tourData?.carrier.uuid}
        popupState={popupState}
      />
    </Card>
  ) : (
    <Card
      variant="filled-disabled"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <CardHeader
        title="Contacts"
        variant="small"
        action={
          <IconButton aria-label="settings" variant="tertiary">
            <LockedIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Alert title="Locked until a carrier is assigned" severity="info" />
      </CardContent>
    </Card>
  );
};
