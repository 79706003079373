import React from 'react';

import { Box, List, LoadingScreen } from '@dizzbo/ui';

import { useOrderDetail } from '../../hooks';
import { useGetEvents } from './hooks/useGetEvents';
import { EventListItem } from './EventListItem';

type Props = {};

export const EventList: React.FC<Props> = () => {
  const { orderData } = useOrderDetail();
  const { uuid } = orderData;
  const { data, isPending } = useGetEvents(uuid);

  return (
    <Box sx={{ minHeight: 300, position: 'relative' }}>
      {isPending ? (
        <LoadingScreen
          sx={{
            position: 'absolute',
            background: 'rgba(255,255,255, 0.3)',
          }}
        />
      ) : (
        <List>
          {data &&
            data.map((event, index) => (
              <EventListItem key={event.uuid} event={event} />
            ))}
        </List>
      )}
    </Box>
  );
};
