import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Divider,
} from '@dizzbo/ui';
import { WarningIcon } from '@dizzbo/ui';

import { CustomerSlot } from './CustomerSlot';
import { ReferenceNumbersForm } from './ReferenceNumbersForm';
import { useOrderDetail } from '../../../hooks';

type Props = {
  orientation?: 'horizontal' | 'vertical';
};

export const CustomerAssignment: React.FC<Props> = ({
  orientation = 'vertical',
}) => {
  const { orderData } = useOrderDetail();
  const { uuid, loader, invoiceReference, invoiceNotes } = orderData;
  const { t } = useTranslation();

  return (
    <Card
      variant="filled-secondary"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
        minHeight: orientation === 'horizontal' ? 0 : 374,
      }}
    >
      <CardHeader title={t('customerAssignment')} variant="small" />
      <CardContent>
        <Stack spacing={6}>
          {!loader && (
            <Alert
              title={t('noCustomerAssigned')}
              variant="standard"
              severity="warning"
              icon={<WarningIcon />}
            />
          )}
          <CustomerSlot orderUUID={uuid} customer={loader} />
          <Divider />
          <ReferenceNumbersForm
            orderUUID={uuid}
            invoiceReference={invoiceReference}
            invoiceNotes={invoiceNotes}
            hasCustomer={!!loader}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};
