import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TrailerIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 3.5H23.5V19L20.9646 19C20.7219 20.6961 19.2632 22 17.5 22C15.7368 22 14.2781 20.6961 14.0354 19L11.9646 19C11.7219 20.6961 10.2632 22 8.5 22C6.73677 22 5.27807 20.6962 5.03545 19L1 19L1.01014 17L2 17L2 3.5ZM4 14V17L5.33681 17C5.89854 15.8175 7.1038 15 8.5 15C9.8962 15 11.1015 15.8175 11.6632 17L14.3368 17C14.8985 15.8175 16.1038 15 17.5 15C18.8962 15 20.1014 15.8175 20.6632 17L21.5 17V14H4ZM4 13L21.5 13V5.5L4 5.5L4 13ZM17.5 17C16.6716 17 16 17.6716 16 18.5C16 19.3284 16.6716 20 17.5 20C18.3284 20 19 19.3284 19 18.5C19 17.6716 18.3284 17 17.5 17ZM10 18.5C10 19.3284 9.32843 20 8.5 20C7.67157 20 7 19.3284 7 18.5C7 17.6716 7.67157 17 8.5 17C9.32843 17 10 17.6716 10 18.5Z"
        />
      </SvgIcon>
    );
  }
);

TrailerIcon.displayName = 'TrailerIcon';

export default TrailerIcon;
