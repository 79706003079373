const SvgIcon = (theme) => {
  return {
    MuiSvgIcon: {
      defaultProps: {
        viewBox: '0 0 24 24',
        sx: {
          height: 24,
          width: 24,
        },
      },
    },
  };
};

export default SvgIcon;
