import { DndContext, closestCenter } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React, { useEffect, useState } from 'react';
import { usePrevious } from 'react-use';

import { Box, Typography } from '@dizzbo/ui';

import { StopType, UUIDType } from '@types';
import { useTranslation } from 'react-i18next';
import { SortableItem } from './SortableItem';

type Props = {
  tourStops: Array<StopType>;
  orderUUID: string;
};

export const StopList: React.FC<Props> = ({
  tourStops,
  orderUUID,
}: {
  tourStops: StopType[];
  orderUUID: UUIDType;
}) => {
  const previousStops = usePrevious(tourStops);
  const [localStopsData, setLocalStopsData] = useState(tourStops);

  useEffect(() => {
    if (previousStops != tourStops) {
      setLocalStopsData(tourStops);
    }
  }, [tourStops]);

  const handleDragEnd = (event) => {};

  const { t } = useTranslation();

  if (tourStops.length === 0) {
    return (
      <Box p={6}>
        <Typography variant="bodyRegular">
          {t('noLoadingPointsAvailable')}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext
          items={localStopsData.map((s) => s.uuid)}
          strategy={verticalListSortingStrategy}
        >
          {localStopsData.map((stop, index) => (
            <SortableItem
              key={stop.uuid}
              stop={stop}
              orderUUID={orderUUID}
              index={index}
              lastItem={tourStops.length === index + 1}
            />
          ))}
        </SortableContext>
      </DndContext>
    </>
  );
};

export default StopList;
