import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const FuelCardIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M11 19C12.1046 19 13 18.1046 13 17C13 15.8954 11 13 11 13C11 13 9 15.8954 9 17C9 18.1046 9.89543 19 11 19Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.79142 1.39272C6.19102 0.963891 4.54601 1.91364 4.11719 3.51404L2.56427 9.30959C2.46491 9.6804 2.43956 10.0536 2.47916 10.4135C1.59396 10.9351 1 11.8982 1 13V19C1 20.6569 2.34315 22 4 22H18C19.6569 22 21 20.6569 21 19V15.9326C21.4136 15.5584 21.7277 15.0647 21.8828 14.486L23.4357 8.69042C23.8645 7.09002 22.9148 5.44501 21.3144 5.01618L7.79142 1.39272ZM18 10H4.46559C4.47061 9.94259 4.48069 9.88482 4.49612 9.82723L5.5314 5.96352L20.9862 10.1046L20.608 11.5162C20.0916 10.6106 19.1171 10 18 10ZM21.5038 8.17278L6.04904 4.03167C6.19198 3.49821 6.74031 3.18163 7.27378 3.32457L20.7967 6.94803C21.3302 7.09098 21.6468 7.63931 21.5038 8.17278ZM3 13C3 12.4477 3.44772 12 4 12H18C18.5523 12 19 12.4477 19 13V19C19 19.5523 18.5523 20 18 20H4C3.44772 20 3 19.5523 3 19V13Z"
        />
      </SvgIcon>
    );
  }
);

FuelCardIcon.displayName = 'FuelCardIcon';

export default FuelCardIcon;
