import React from 'react';
import { useTheme, styled } from '@mui/material/styles';
import MuiPagination from '@mui/material/Pagination';
import MuiPaginationItem from '@mui/material/PaginationItem';
import { TablePaginationProps as MuiTablePaginationProps } from '@mui/material/TablePagination';

import {
  Box,
  ChevronLeftIcon,
  ChevronRightIcon,
  SkipRightIcon,
  SkipLeftIcon,
  Typography,
  IconButton,
} from '@dizzbo/ui';

interface PaginationProps {
  rowCount: number;
  pageIndex: number;
  pageCount: number;
  pageSize: number;
  setPagination?: any;
  className?: MuiTablePaginationProps['className'];
}

const PaginationWithPageNumber = ({
  className,
  rowCount,
  pageIndex,
  pageCount,
  pageSize,
  setPagination,
}: PaginationProps) => {
  const theme = useTheme();

  const labelDisplayedRows = ({
    from,
    to,
    rowCount,
  }: {
    from: number;
    to: number;
    rowCount: number;
  }) => {
    return `${from} – ${to} // ${
      rowCount !== -1 ? `${rowCount} total` : `more than ${to}`
    }`;
  };

  const getLabelDisplayedRowsTo = () => {
    if (rowCount === -1) {
      return (pageIndex + 1) * pageSize;
    }
    return pageSize === -1
      ? rowCount
      : Math.min(rowCount, (pageIndex + 1) * pageSize);
  };

  const handlePageChange = (event, newPage: any) => {
    setPagination({ pageIndex: newPage - 1, pageSize });
  };

  return (
    <Box display='flex' alignItems='center' justifyContent='center'>
      <MuiPagination
        color='primary'
        className={className}
        count={pageCount}
        page={pageIndex + 1}
        onChange={handlePageChange}
        showFirstButton
        renderItem={(item) =>
          item.type === 'previous' || item.type === 'first' ? (
            <MuiPaginationItem
              {...item}
              sx={{
                ':hover': {
                  backgroundColor: 'transparent',
                  border: `2px solid ${theme.styles.tableCell.default.borderColor}`,
                },
                '.MuiSvgIcon-root': { height: 16, width: 16 },
              }}
              slots={{
                first: SkipLeftIcon,
                previous: ChevronLeftIcon,
                next: ChevronRightIcon,
                last: SkipRightIcon,
              }}
            />
          ) : null
        }
      />
      <Typography variant='bodyBold' color='primary.main' mx={2}>
        {labelDisplayedRows({
          from: rowCount === 0 ? 0 : pageIndex * pageSize + 1,
          to: getLabelDisplayedRowsTo(),
          rowCount: rowCount === -1 ? -1 : rowCount,
        })}
      </Typography>
      <MuiPagination
        color='primary'
        className={className}
        count={pageCount}
        page={pageIndex + 1}
        onChange={handlePageChange}
        showLastButton
        renderItem={(item) =>
          item.type === 'next' || item.type === 'last' ? (
            <MuiPaginationItem
              {...item}
              sx={{
                ':hover': {
                  backgroundColor: 'transparent',
                  border: `2px solid ${theme.styles.tableCell.default.borderColor}`,
                },
                '.MuiSvgIcon-root': { height: 16, width: 16 },
              }}
              slots={{
                next: ChevronRightIcon,
                last: SkipRightIcon,
              }}
            />
          ) : null
        }
      />
    </Box>
  );
};

const Pagination = styled(PaginationWithPageNumber)({
  '.MuiPaginationItem-page': {
    display: 'none',
  },
  '.MuiPaginationItem-ellipsis': {
    display: 'none',
  },
});

export default Pagination;
