import { styled, useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import React from 'react';

import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);

import { Avatar, Box, Typography } from '@dizzbo/ui';

import { useAuth } from '../../hooks/useAuth';

import { ISODateTimeType, UUIDType } from 'packages/core/types';
import { DateDivider } from './DateDivider';

const ContainerStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isPlainMessage',
})<{ isPlainMessage?: boolean }>(({ isPlainMessage }) => ({
  display: 'flex',
  paddingTop: isPlainMessage ? 6 : 24,
  paddingRight: 24,
  paddingBottom: 12,
  paddingLeft: 24,
  width: '100%',
}));

const LeftContentStyles = styled(Box)(() => ({
  display: 'flex',
  flexShrink: 0,
  marginRight: 8,
  width: 32,
}));

const RightContentStyles = styled(Box)(() => ({
  display: 'block',
}));

const HeaderContainerStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isPlainMessage',
})<{ isPlainMessage?: boolean }>(() => ({
  marginBottom: 12,
}));

const ContentContainerStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isPlainMessage',
})<{ isPlainMessage?: boolean }>(({ theme, isPlainMessage }) => ({
  backgroundColor: isPlainMessage
    ? theme.palette.turquoise[100]
    : theme.palette.green[40],
  paddingTop: 10,
  paddingRight: 12,
  paddingBottom: 10,
  paddingLeft: 12,
  borderRadius: 8,
  ...theme.typography.bodyRegularSmall,
}));

type Props = {
  username: string;
  userUUID: UUIDType;
  previousUserUUID?: UUIDType;
  text: string;
  date: ISODateTimeType;
  previousDate?: ISODateTimeType;
};

export const CommentItem: React.FC<Props> = ({
  username,
  userUUID,
  previousUserUUID,
  text,
  date,
  previousDate,
}) => {
  const theme = useTheme();
  const { user } = useAuth();

  const dayjsDate = dayjs(date);
  const currentUserIsAuthor = userUUID === user.uuid;
  const previousUserIsAuthor = userUUID === previousUserUUID;

  let dayChanged = false;
  if (previousDate) {
    if (dayjs(previousDate).get('date') !== dayjsDate.get('date')) {
      dayChanged = true;
    }
  }
  return (
    <>
      {dayChanged && <DateDivider date={date} />}
      <ContainerStyles isPlainMessage={previousUserIsAuthor && !dayChanged}>
        <LeftContentStyles>
          {(!previousUserIsAuthor || dayChanged) && (
            <Avatar
              username={username}
              sx={{
                width: 32,
                height: 32,
                ...theme.typography.bodyBoldSmall,
                backgroundColor: currentUserIsAuthor
                  ? theme.palette.turquoise[100]
                  : theme.palette.green[100],
              }}
            />
          )}
        </LeftContentStyles>
        <RightContentStyles>
          {(!previousUserIsAuthor || dayChanged) && (
            <HeaderContainerStyles>
              <Typography variant="bodyBoldSmall">{username}</Typography>{' '}
              <Typography variant="labelSmall" color="primary.light">
                {dayjs(dayjsDate).format('LT')}
              </Typography>
              <br />
            </HeaderContainerStyles>
          )}
          <ContentContainerStyles isPlainMessage={currentUserIsAuthor}>
            {text}
          </ContentContainerStyles>
        </RightContentStyles>
      </ContainerStyles>
    </>
  );
};
