import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const UnlockedIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M11 13H13V17H11V13Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 4.5C24 2.01472 21.9853 0 19.5 0H18.5C16.0147 0 14 2.01472 14 4.5V8H7C4.79086 8 3 9.79086 3 12V18C3 20.2091 4.79086 22 7 22H17C19.2091 22 21 20.2091 21 18V12C21 9.79086 19.2091 8 17 8H16V4.5C16 3.11929 17.1193 2 18.5 2H19.5C20.8807 2 22 3.11929 22 4.5V6H24V4.5ZM5 12C5 10.8954 5.89543 10 7 10H17C18.1046 10 19 10.8954 19 12V18C19 19.1046 18.1046 20 17 20H7C5.89543 20 5 19.1046 5 18V12Z"
        />
      </SvgIcon>
    );
  }
);

UnlockedIcon.displayName = 'UnlockedIcon';

export default UnlockedIcon;
