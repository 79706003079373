import React from 'react';

import { ListIcon } from '@dizzbo/ui';

import { UUIDType } from 'packages/core/types';
import { useWorkspaces } from './hooks/useWorkspaces';
import { TabContainerStyles } from './Tab';

type Props = {
  uuid: UUIDType;
};

export const DefaultTab: React.FC<Props> = ({ uuid }: Props) => {
  const { setActiveWorkspaceUUID, activeWorkspaceUUID } = useWorkspaces();

  return (
    <TabContainerStyles
      sx={{ width: 56, minWidth: 56 }}
      active={uuid === activeWorkspaceUUID}
      onClick={() => setActiveWorkspaceUUID(uuid)}
    >
      <ListIcon />
    </TabContainerStyles>
  );
};
