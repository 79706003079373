import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import React from 'react';

import {
  Box,
  Card,
  Chip,
  Divider,
  LicensePlate,
  Stack,
  TrailerIcon,
  TruckIcon,
} from '@dizzbo/ui';
import { TourType } from '@types';

const ContainerStyles = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingRight: 24,
  paddingLeft: 24,
  marginBottom: 12,
}));

const CardContentStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: 24,
}));

const InfoStyles = styled(Box)(({ theme }) => ({
  width: '100%',
}));

const StopsContainerStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

const StopStyles = styled(Box)(({ theme }) => ({
  textAlign: 'left',
  '&:last-child': {
    textAlign: 'right',
  },
}));

const AddressStyles = styled(Box)(({ theme }) => ({
  // whiteSpace: 'nowrap',
  // overflow: 'hidden',
  // textOverflow: 'ellipsis',
  ...theme.typography.bodyBold,
  marginBottom: 2,
}));

const DateStyles = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ...theme.typography.bodyRegularSmall,
}));

const VehicleStyles = styled(Stack)(({ theme }) => ({
  alignItems: 'center ',
}));
const CarrierStyles = styled(Stack)(({ theme }) => ({
  ...theme.typography.bodyRegularSmall,
  lineHeight: 1,
  marginTop: 6,
}));

type Props = {
  tour: TourType;
};

export const DetailSummary: React.FC<Props> = ({ tour }) => {
  return (
    <ContainerStyles>
      <Card
        variant="outlined"
        elevation={0}
        sx={{
          width: '100%',
        }}
      >
        <CardContentStyles>
          <InfoStyles>
            <StopsContainerStyles>
              <StopStyles>
                <AddressStyles>
                  {tour.origin
                    ? `${tour.origin?.loadingPoint.address.country}, ${tour.origin?.loadingPoint.name}`
                    : '-'}
                </AddressStyles>
                <DateStyles>
                  {tour.origin?.requestedDateEnd
                    ? dayjs(tour?.origin?.requestedDateEnd).format(
                        'MMM DD, hh:mm'
                      )
                    : '-'}
                </DateStyles>
              </StopStyles>
              <StopStyles>
                <AddressStyles>
                  {tour.destination
                    ? `${tour.destination?.loadingPoint.address.country}, ${tour.destination?.loadingPoint.name}`
                    : '-'}
                </AddressStyles>
                <DateStyles>
                  {tour.destination?.requestedDateEnd
                    ? dayjs(tour?.destination?.requestedDateEnd).format(
                        'MMM DD, hh:mm'
                      )
                    : '-'}
                </DateStyles>
              </StopStyles>
            </StopsContainerStyles>
            <Divider sx={{ marginY: 3 }} />
            <VehicleStyles spacing={2} direction="row">
              <Chip label="on time" size="small" variant="forest" />{' '}
              <Stack direction="row">
                <TruckIcon sx={{ width: 16, height: 16 }} />
                <TrailerIcon sx={{ width: 16, height: 16 }} />
              </Stack>
              <LicensePlate number={tour.vehicle?.licensePlate} size="medium" />
            </VehicleStyles>
            <CarrierStyles>{tour.carrier && tour.carrier.name}</CarrierStyles>
          </InfoStyles>
        </CardContentStyles>
      </Card>
    </ContainerStyles>
  );
};
