import { SxProps } from '@mui/material';
import Popper from '@mui/material/Popper';
import { useQuery } from '@tanstack/react-query';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React, { useState } from 'react';

import { getVehiclesAutocomplete } from '@core/api/';
import { QueryKeys } from '@core/config';
import {
  Autocomplete,
  CheckIcon,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  PlusIcon,
  TextField,
  TruckIcon,
  Typography,
} from '@dizzbo/ui';

import { CarrierType, VehicleType } from '@types';
import { useTranslation } from 'react-i18next';
import { CreateVehicleDialog } from './CreateVehicleDialog';

const styles = {
  popper: {
    width: 'fit-content',
  },
};

type Props = {
  label: string;
  value?: VehicleType;
  onChange?: (vehicle: VehicleType) => void;
  sx?: SxProps;
  carrier?: CarrierType;
};

const VehiclePopper = function (props) {
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};

export const VehicleAutoCompleteTextField = React.forwardRef<
  HTMLElement,
  Props
>(({ label, value = null, onChange, sx, carrier }, ref) => {
  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'vehicletCreateDialog',
  });

  const [searchValue, setSearchValue] = useState('');
  const [open, setOpen] = React.useState(false);

  async function findVehicle() {
    const resData = await getVehiclesAutocomplete({
      q: searchValue,
      carrier: carrier?.uuid,
    });
    return resData;
  }

  const { isFetching, data = [] } = useQuery({
    queryKey: [QueryKeys.VEHICLES, searchValue],
    queryFn: findVehicle,
    enabled: open && !!searchValue,
  });

  const formatVehicleName = (vehicleData: VehicleType) => {
    return `${vehicleData.licensePlate}`;
  };

  const handleClick = (e) => {
    popupState.open();
    e.stopPropagation();
  };

  const { t } = useTranslation();

  const PaperComponent = (props: any) => {
    const { children, ...other } = props;
    return (
      <Paper {...other}>
        {children}
        {carrier && (
          <List>
            <ListItem onClick={(e) => e.preventDefault()}>
              <ListItemButton onMouseDown={handleClick}>
                <ListItemIcon>
                  <PlusIcon sx={{ width: 16, height: 16 }} />
                </ListItemIcon>
                <ListItemText>{t('createNewVehicle')}</ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        )}
      </Paper>
    );
  };

  return (
    <>
      <Autocomplete
        ref={ref}
        sx={{
          ...sx,
        }}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        disableCloseOnSelect
        loading={isFetching}
        autoComplete
        autoHighlight
        blurOnSelect
        clearOnEscape
        clearOnBlur
        popupIcon={<TruckIcon />}
        PaperComponent={PaperComponent}
        PopperComponent={VehiclePopper}
        componentsProps={{
          popper: {
            sx: {
              width: 'auto',
            },
          },
        }}
        noOptionsText={t('noMatchingVehicleFound')}
        options={data}
        value={value}
        filterOptions={(x) => x}
        renderInput={(params) => <TextField {...params} label={label} />}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : formatVehicleName(option)
        }
        isOptionEqualToValue={(option, inputValue) =>
          option.uuid === inputValue.uuid
        }
        onChange={(
          event: any,
          inputValue: VehicleType | null,
          reason: string
        ) => {
          onChange(inputValue);
        }}
        onInputChange={(event, inputValue: string, reason: string) => {
          if (reason !== 'reset') {
            setSearchValue(inputValue);
          }
        }}
        renderOption={(props, vehicleData: VehicleType, { inputValue }) => {
          const { className, ...rest } = props;

          let listItemProps = {};

          const text = (
            <Typography variant="bodyRegular">
              {formatVehicleName(vehicleData)}
            </Typography>
          );

          if (props['aria-selected']) {
            listItemProps = {
              secondaryAction: <CheckIcon sx={{ width: 16, height: 16 }} />,
            };
          }

          return (
            <ListItem {...rest} {...listItemProps}>
              <ListItemText>{text}</ListItemText>
            </ListItem>
          );
        }}
      />
      {carrier && (
        <CreateVehicleDialog
          popupState={popupState}
          carrier={carrier}
          setVehicle={onChange}
        />
      )}
    </>
  );
});

VehicleAutoCompleteTextField.displayName = 'VehicleAutoCompleteTextField';
