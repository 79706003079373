import React, { ReactNode } from 'react';

import { Currency } from '@dizzbo/core/i18n';
import { Row, TableInstance } from '@dizzbo/ui';

import { OrderType } from '@types';
import { TextCellContent } from '.';

type Props = {
  row: Row<OrderType>;
  renderedCellValue: ReactNode;
  table: TableInstance<OrderType>;
};

export const CurrencyCell: React.FC<Props> = ({
  renderedCellValue,
  row: { original },
  table,
}) => (
  <TextCellContent highlighted={original.todos.length > 0} table={table}>
    {renderedCellValue !== '' ? <Currency value={renderedCellValue} /> : '-'}
  </TextCellContent>
);
