import { Autocomplete as MuiAutocomplete } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiAutocompleteProps = ComponentProps<typeof MuiAutocomplete>;

export const Autocomplete = forwardRef<HTMLElement, MuiAutocompleteProps>(
  (props, ref) => <MuiAutocomplete {...props} ref={ref} />
);

Autocomplete.displayName = 'Autocomplete';
