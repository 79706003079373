import Popover from '@mui/material/Popover';
import { useTheme } from '@mui/material/styles';
import { DateRange } from '@mui/x-date-pickers-pro';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import dayjs, { Dayjs } from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  BasicSelect,
  Box,
  Button,
  DateIcon,
  Divider,
  MenuItem,
  Stack,
  SwitchIcon,
} from '@dizzbo/ui';

import { useWorkspaces } from '@dizzbo/core/hooks';

const filterSettings = {
  plannedLoadingDate: 'Planned loading date',
  plannedUnloadingDate: 'Planned unloading date',
  loadedAt: 'Loaded date',
  createdAt: 'Created date',
};

function validateAndParseDates(data) {
  const defaultData = ['plannedUnloadingDate', [null, null]];

  // Check if data is an object and has exactly 2 keys
  if (
    typeof data !== 'object' ||
    isEmpty(data) ||
    Object.keys(data).length !== 2
  ) {
    return defaultData;
  }

  // Valid prefixes
  const prefixes = [
    'plannedUnloadingDate',
    'plannedLoadingDate',
    'loadedAt',
    'createdAt',
  ];

  // Check if both keys have valid prefixes
  const validKeys = Object.keys(data).every((key) =>
    prefixes.some((prefix) => key.startsWith(prefix))
  );

  if (!validKeys) {
    return defaultData;
  }

  // Check if both keys have the same prefix
  const prefixSet = new Set(
    Object.keys(data).map((key) => key.match(/^[a-zA-Z]+(?=After|Before)/)[0])
  );

  if (prefixSet.size !== 1) {
    return defaultData;
  }

  // Check if one key ends with "After" and the other ends with "Before"
  const keyEndings = Object.keys(data).map((key) =>
    key.endsWith('After') ? 'After' : 'Before'
  );

  if (keyEndings[0] === keyEndings[1]) {
    return defaultData;
  }

  // Check if values are valid dates using dayjs
  const dates = Object.values(data).map((value) => dayjs(value, 'YYYY-MM-DD'));
  if (dates.some((date) => !date.isValid())) {
    return defaultData;
  }

  // Return valid dates
  return [
    prefixSet.values().next().value, // Get the prefix
    [dates[keyEndings.indexOf('After')], dates[keyEndings.indexOf('Before')]], // Arrange dates
  ];
}

export const DateRangeFilter: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'orderListDateRangeFilter',
  });

  const {
    activeWorkspaceUUID,
    setWorkspaceSettings,
    removeWorkspaceSettings,
    activeWorkspace,
  } = useWorkspaces();

  const dateFilterRangeType =
    activeWorkspace?.settings?.filters?.dateFilterRangeType || 'default';

  const [prefix, dates] = validateAndParseDates(
    activeWorkspace?.settings?.filters?.dateRange
  );

  const dateType = prefix || 'plannedLoadingDate';
  let dateRange = dates;

  switch (dateFilterRangeType) {
    case 'today':
      dateRange = [dayjs(), dayjs()];
      break;
    case 'tomorrow':
      dateRange = [dayjs().add(1, 'day'), dayjs().add(1, 'day')];
      break;
    case 'thisWeek':
      dateRange = [dayjs().startOf('week'), dayjs().endOf('week')];
      break;
    case 'thisMonth':
      dateRange = [dayjs().startOf('month'), dayjs().endOf('month')];
      break;
    case 'thisYear':
      dateRange = [dayjs().startOf('year'), dayjs().endOf('year')];
      break;
  }

  const setDateRangeValue = (
    dateRangeValue,
    filterType = null,
    rangeType = 'default'
  ) => {
    if (dateRangeValue) {
      const dateRange = {
        [`${filterType}After`]: dateRangeValue[0]?.format('YYYY-MM-DD'),
        [`${filterType}Before`]: dateRangeValue[1]?.format('YYYY-MM-DD'),
      };

      setWorkspaceSettings(activeWorkspaceUUID, 'filters', {
        dateRange: dateRange,
        dateFilterRangeType: rangeType,
      });
    }
  };

  const handleChangeDate = (calendarDateRange?: DateRange<Dayjs> | null) => {
    if (!calendarDateRange[0] && !calendarDateRange[1]) {
      setDateRangeValue(null);
    }
    if (calendarDateRange[0] && !calendarDateRange[1]) {
      setDateRangeValue([calendarDateRange[0], calendarDateRange[0]], dateType);
    }
    if (calendarDateRange[0] && calendarDateRange[1]) {
      setDateRangeValue(calendarDateRange, dateType);
    }
  };

  const handleChangeDateFilterType = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDateRangeValue(dateRange, e.target.value as any);
  };

  const renderLabel = () => {
    switch (dateFilterRangeType) {
      case 'today':
        return t('today');
      case 'tomorrow':
        return t('tomorrow');
      case 'thisWeek':
        return t('thisWeek');
      case 'thisMonth':
        return t('thisMonth');
      case 'thisYear':
        return t('thisYear');
      default:
        return (
          <>
            {dateRange && dateRange[0]?.format('MMM DD')}
            {dateRange && dateRange[0] && dateRange[1]
              ? ' - '
              : t('selectADate')}
            {dateRange && dateRange[1]?.format('MMM DD')}
          </>
        );
    }
  };

  return (
    <>
      <Button
        variant="secondary"
        size="medium"
        startIcon={
          <>
            <DateIcon />
          </>
        }
        endIcon={
          <>
            <SwitchIcon />
          </>
        }
        {...bindTrigger(popupState)}
      >
        {renderLabel()}
      </Button>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box>
          <Stack
            direction="row"
            sx={{
              px: 6,
              py: 6,
              borderBottom: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
            }}
          >
            <BasicSelect
              id="selectDateFilterType"
              value={dateType}
              onChange={handleChangeDateFilterType}
            >
              {Object.entries(filterSettings).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </BasicSelect>
          </Stack>
          <Stack
            spacing={0}
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
          >
            <Stack spacing={3} alignItems="flex-start" sx={{ px: 6, py: 6 }}>
              <Button
                variant="tertiary"
                size="medium"
                onClick={() => {
                  removeWorkspaceSettings(
                    activeWorkspaceUUID,
                    'filters',
                    'dateRange'
                  );
                  removeWorkspaceSettings(
                    activeWorkspaceUUID,
                    'filters',
                    'dateFilterRangeType'
                  );
                }}
              >
                {t('reset')}
              </Button>
              <Button
                variant="tertiary"
                size="medium"
                onClick={() => {
                  setDateRangeValue([dayjs(), dayjs()], dateType, 'today');
                }}
              >
                {t('today')}
              </Button>
              <Button
                variant="tertiary"
                size="medium"
                onClick={() => {
                  setDateRangeValue(
                    [dayjs().add(1, 'day'), dayjs().add(1, 'day')],
                    dateType,
                    'tomorrow'
                  );
                }}
              >
                {t('tomorrow')}
              </Button>
              <Button
                variant="tertiary"
                size="medium"
                onClick={() => {
                  setDateRangeValue(
                    [dayjs().startOf('week'), dayjs().endOf('week')],
                    dateType,
                    'thisWeek'
                  );
                }}
              >
                {t('thisWeek')}
              </Button>
              <Button
                variant="tertiary"
                size="medium"
                onClick={() => {
                  setDateRangeValue(
                    [dayjs().startOf('month'), dayjs().endOf('month')],
                    dateType,
                    'thisMonth'
                  );
                }}
              >
                {t('thisMonth')}
              </Button>
              <Button
                variant="tertiary"
                size="medium"
                onClick={() => {
                  setDateRangeValue(
                    [dayjs().startOf('year'), dayjs().endOf('year')],
                    dateType,
                    'thisYear'
                  );
                }}
              >
                {t('thisYear')}
              </Button>
            </Stack>
            <DateRangeCalendar
              calendars={1}
              onChange={handleChangeDate}
              value={dateRange}
            />
          </Stack>
        </Box>
      </Popover>
    </>
  );
};
