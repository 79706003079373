import { jwtDecode } from 'jwt-decode';

const isValidToken = (accessToken: any) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<JwtPayload>(accessToken);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp) => {
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    // eslint-disable-next-line no-alert
    console.log('Token expired');

    localStorage.removeItem('accessToken');

    // window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

export { isValidToken };
