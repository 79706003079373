import { FormLabel as MuiFormLabel } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiFormLabelProps = ComponentProps<typeof MuiFormLabel>;

export const FormLabel = forwardRef<HTMLLabelElement, MuiFormLabelProps>(
  (props, ref) => <MuiFormLabel ref={ref} {...props} />
);

FormLabel.displayName = 'FormLabel';
