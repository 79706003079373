import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from '@core/config';
import { axiosAuthenticatedRequest } from '@dizzbo/core/api';
import { OrderType, UUIDType } from '@types';

export const useGetOrder = (orderUUID: UUIDType) => {
  const getOrder = async (orderUUID: UUIDType): Promise<OrderType> => {
    const res = await axiosAuthenticatedRequest.get(`/v2/orders/${orderUUID}/`);
    return res.data;
  };

  return useQuery({
    queryKey: [QueryKeys.ORDERS, orderUUID],
    queryFn: () => getOrder(orderUUID),
  });
};
