import React from 'react';

import { Chip } from '@dizzbo/ui';

type Props = {
  label: string;
  value: boolean;
};

export const RequirementsDisplay: React.FC<Props> = ({ label, value }) => {
  const valueLabel = value ? 'Yes' : 'No';

  return (
    <Chip label={`${label} ${valueLabel}`} size="large" variant="outlined" />
  );
};
