import { ArrowRightIcon, Box, Button, ChevronLeftIcon } from '@dizzbo/ui';
import { useTheme } from '@mui/material/styles';
import { UUIDType } from '@types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

type Props = {
  tourUUID: UUIDType;
};

export const DetailHeader: React.FC<Props> = ({ tourUUID }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        zIndex: theme.zIndex.appBar,
        paddingTop: 2,
        paddingRight: 6,
        paddingBottom: 2,
        paddingLeft: 6,
        backgroundColor: theme.styles.common.pageBackground,
        '&::after': {
          pointerEvents: 'none',
          content: "' '",
          display: 'block',
          position: 'absolute',
          zIndex: 1,
          height: '48px',
          width: '100%',
          bottom: -48,
          left: 0,
          backgroundRepeat: 'repeat-x',
          backgroundImage: `url(${theme.styles.images.shadowHorizontal})`,
        },
      }}
    >
      <Button
        variant="tertiary"
        size="medium"
        startIcon={<ChevronLeftIcon />}
        component={RouterLink}
        to="/realtime/"
      >
        Realtime overview
      </Button>
      <Button
        variant="secondary"
        size="medium"
        startIcon={<ArrowRightIcon />}
        component={RouterLink}
        to="/tours/"
        sx={{ marginLeft: 'auto' }}
      >
        Tour Detail
      </Button>
    </Box>
  );
};
