import React from 'react';

import { Box, Button, LocationIcon, LocationMarker } from '@dizzbo/ui';
import { useTranslation } from 'react-i18next';

const API_KEY = import.meta.env.VITE_HERE_MAP_API_KEY as string;

type Props = {
  setShowLoadingPointLocationDetail: (show: boolean) => void;
  location?: { lat: number; lng: number };
};

export const MapPreview: React.FC<Props> = ({
  location,
  setShowLoadingPointLocationDetail,
}) => {
  const mapImage = `https://image.maps.hereapi.com/mia/v3/base/mc/center:${location.lat},${location.lng};zoom=14/752x300/png?apiKey=${API_KEY}&ppi=400&features=vehicle_restrictions:active_and_inactive&copyright=false&style=logistics.day`;

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        height: 300,
        width: '100%',
        backgroundColor: '#333333',
        position: 'relative',
        backgroundImage: `url(${mapImage})`,
        backgroundSize: 'auto 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        justifyContent: 'center',
      }}
    >
      <LocationMarker
        sx={{
          position: 'absolute',
          top: 120,
          left: '50%',
          marginLeft: -3,
          pointerEvents: 'none',
          zIndex: 99999,
        }}
      />
      <Button
        sx={{ position: 'absolute', bottom: 24, boxShadow: 1 }}
        startIcon={<LocationIcon />}
        size="medium"
        variant="primary"
        onClick={() => setShowLoadingPointLocationDetail(true)}
      >
        {t('changeMarkerPosition')}
      </Button>
    </Box>
  );
};
