import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CheckListIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M4.50006 8.56066L8.03039 5.03033L6.96973 3.96967L4.50006 6.43934L3.03039 4.96967L1.96973 6.03033L4.50006 8.56066Z" />
        <path d="M22 5H9V7H22V5Z" />
        <path d="M9 11H22V13H9V11Z" />
        <path d="M22 17H9V19H22V17Z" />
        <path d="M8.03039 10.5303L4.50006 14.0607L1.96973 11.5303L3.03039 10.4697L4.50006 11.9393L6.96973 9.46967L8.03039 10.5303Z" />
        <path d="M4.50006 20.0607L8.03039 16.5303L6.96973 15.4697L4.50006 17.9393L3.03039 16.4697L1.96973 17.5303L4.50006 20.0607Z" />
      </SvgIcon>
    );
  }
);

CheckListIcon.displayName = 'CheckListIcon';

export default CheckListIcon;
