import { memo, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { useWatch } from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';

export const AutoSave = memo(
  ({
    defaultValues,
    onSubmit,
    element = null,
    handleSubmit,
    isValid,
    control,
  }: Props) => {
    const debouncedSave = useCallback(
      debounce(() => {
        console.log('Saving');
        handleSubmit(onSubmit)();
      }, 1000),
      []
    );

    const watchedData = useWatch({
      control: control,
      defaultValue: defaultValues,
    });

    useDeepCompareEffect(() => {
      console.log('Triggered');
      if (isValid) {
        debouncedSave();
      }
    }, [watchedData]);

    return element;
  }
);

AutoSave.displayName = 'AutoSave';

type Props = {
  defaultValues?: any;
  onSubmit: any;
  element?: any;
  handleSubmit?: any;
  isValid?: boolean;
  control?: any;
};
