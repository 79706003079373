import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import React, { PropsWithChildren } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';

import componentsOverride from './overrides';
import palette from './palette';
import { darkShadows, shadows } from './shadows';
import typography from './typography';

import themeDark from './themeDark';
import themeLight from './themeLight';

const themeLightStyles = themeLight(palette);
const themeDarkStyles = themeDark(palette);

const baseTheme = {
  palette: {
    ...palette,
    text: { ...themeLightStyles.common.textColors },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  spacing: 4,
  typography: typography,
  shadows: shadows,
  styles: themeLightStyles,
  mode: 'light',
};

type CustomTheme = {
  [Key in keyof typeof baseTheme]: (typeof baseTheme)[Key];
};

declare module '@mui/material/styles/createTheme' {
  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}

const darkBaseTheme = cloneDeep(baseTheme);
darkBaseTheme.palette.text = themeDarkStyles.common.textColors;

const darkTheme = merge(
  darkBaseTheme,
  { shadows: darkShadows },
  { mode: 'dark' },
  { styles: { ...themeDarkStyles } }
);

const lightBaseTheme = cloneDeep(baseTheme);
const lightTheme = merge(lightBaseTheme, { styles: { ...themeLightStyles } });

type Props = PropsWithChildren<{
  theme?: {
    themeMode?: 'light' | 'dark';
  };
}>;

//   const { theme: contextTheme } = useSettings();

const ThemeProvider: React.FC<Props> = ({ children, theme: contextTheme }) => {
  const themeMode = contextTheme?.themeMode || 'light';

  const isDark = themeMode === 'dark';

  let myTheme = lightTheme;
  if (isDark) {
    myTheme = darkTheme;
  }

  const theme = createTheme(myTheme);
  theme.components = componentsOverride(theme);

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
};

export default ThemeProvider;
