import isEqual from 'lodash/isEqual';
import { MutableRefObject, useEffect } from 'react';
import { usePrevious } from 'react-use';

import { StopType } from '@types';
import { getStopMarker } from '../utils';

export const useRenderStops = (
  hereMapRef: MutableRefObject<H.Map> | undefined,
  stops: StopType[]
) => {
  const previousStops = usePrevious(stops);

  useEffect(() => {
    if (hereMapRef.current) {
      const map = hereMapRef.current as H.map;
      const routeGroup = new H.map.Group();

      const stopMarkers = stops.map((stop, index) => {
        const position = {
          lat: stop.lat,
          lng: stop.lng,
        };
        const stopMarker = getStopMarker(index, position, { data: stop });
        return stopMarker;
      });

      routeGroup.addObjects(stopMarkers);

      map.addObject(routeGroup);

      // recenter map to fit all route sections
      if (stopMarkers.length === 1) {
        if (!isEqual(previousStops, stops)) {
          map.setCenter(routeGroup.getBoundingBox().getCenter());
          map.setZoom(12);
        }
      }

      if (stopMarkers.length > 1) {
        if (!isEqual(previousStops, stops)) {
          map
            .getViewModel()
            .setLookAtData({ bounds: routeGroup.getBoundingBox() });
        }
      }

      return () => {
        if (!map) return;

        if (stopMarkers.length && map.getObjects().includes(routeGroup)) {
          map.removeObject(routeGroup);
        }
      };
    }
  }, [hereMapRef?.current, stops]);
};
