import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import Grid from '@mui/material/Unstable_Grid2';

import { LoadingScreen } from '@dizzbo/ui';
import { QueryKeys } from '@core/config';
import { getTour } from '@core/api';

import { useOrderDetail } from '../../hooks';

import { TourSummary } from './TourSummary';
import { CarrierPrice } from './CarrierPrice';
import { VehicleAsssignment } from './VehicleAsssignment';
import { TourPlan } from './TourPlan';
import { Contacts } from './Contacts';
import { TransportContract, TransportContractList } from './TransportContract';

interface Props {}

export const TourDetailForm: React.FC<Props> = () => {
  const theme = useTheme();
  const { orderData } = useOrderDetail();
  const {
    uuid: orderUUID,
    tour: { uuid: tourUUID },
  } = orderData;

  const { data: tourData, isPending } = useQuery({
    queryKey: [QueryKeys.TOURS, tourUUID],
    queryFn: () => getTour(tourUUID),
  });

  if (isPending) {
    return <LoadingScreen sx={{ position: 'absolute' }} />;
  }

  return (
    <Grid
      container
      spacing={6}
      rowSpacing={12}
      sx={{
        margin: 0,
        marginTop: theme.spacing(6),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      }}
    >
      <Grid xs={12}>
        <TourSummary reference={tourData.reference} tourUUID={tourUUID} />
      </Grid>
      <Grid xs={12} lg={6}>
        <VehicleAsssignment tourData={tourData} orderUUID={orderUUID} />
      </Grid>

      <Grid xs={6} lg={6}>
        <CarrierPrice
          price={tourData.price}
          tourUUID={tourUUID}
          orderUUID={orderUUID}
        />
      </Grid>
      <Grid xs={12}>
        <TourPlan tourStops={tourData?.stops} />
      </Grid>
      <Grid xs={6} lg={6}>
        <Contacts tour={tourData} />
      </Grid>
      <Grid xs={6} lg={6}>
        <TransportContract
          tourUUID={tourUUID}
          tourReference={tourData.reference}
          transportContractUUID={tourData.transportContract}
        />
      </Grid>
      <Grid xs={12}>
        <TransportContractList tourUUID={tourUUID} />
      </Grid>
    </Grid>
  );
};
