import { styled } from '@mui/material/styles';
import React, { FC, ReactNode } from 'react';

import { Box } from '../Box';

const PageHeaderWrapperStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'shadow',
})<any>(({ theme, shadow }) => {
  return {
    display: 'flex',
    width: '100%',
    height: 64,
    borderBottom: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
    backgroundColor: theme.styles.common.pageBackground,
    alignItems: 'center',
    paddingLeft: 24,
    paddingRight: 24,
    color: theme.styles.common.textColors.main,
    ...theme.typography.h2,
    boxShadow: shadow ? theme.shadows[2] : 'none',
    position: 'relative',
    zIndex: theme.zIndex.appBar,
  };
});

const IconStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginRight: theme.spacing(4),
  justifyContent: 'center',
  alignItems: 'center',
}));

const ChildrenStyles = styled(Box)(() => ({
  display: 'flex',
  marginLeft: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
}));

interface PageHeaderProps {
  title: string;
  icon?: ReactNode;
  children?: ReactNode;
  shadow?: boolean;
}

export const PageHeader: FC<PageHeaderProps> = ({
  title,
  icon,
  children,
  shadow = false,
}) => (
  <PageHeaderWrapperStyles shadow={shadow}>
    {icon && <IconStyles>{icon}</IconStyles>}
    {title}
    {children && <ChildrenStyles>{children}</ChildrenStyles>}
  </PageHeaderWrapperStyles>
);
