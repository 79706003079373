import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { updateTour } from '@core/api';
import { QueryKeys } from '@core/config';
import { AssignIcon, Box, LoadingButton, Stack } from '@dizzbo/ui';

import { OrderType, TourType, UUIDType } from '@types';
import { useTranslation } from 'react-i18next';
import { CarrierAutoCompleteTextField } from './CarrierAutoCompleteTextField';

const FormStyles = styled('form')(({ theme }) => ({
  width: '100%',
}));

const schema = yup.object().shape({
  carrier: yup.object().required('Carrier is required'),
});

type Props = {
  tourUUID: UUIDType;
  orderUUID: UUIDType;
};

export const CarrierForm: React.FC<Props> = ({ tourUUID, orderUUID }) => {
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const { mutate: mutateTour, isPending } = useMutation<
    OrderType,
    unknown,
    Partial<TourType>
  >({
    mutationKey: [QueryKeys.ORDERS, orderUUID],
    mutationFn: (values) =>
      updateTour({ tourUUID: tourUUID, tourData: values }),
  });

  const { t } = useTranslation();

  const onSubmit = (formData: any) => {
    const id = toast.loading('Assigning Carrier...');
    mutateTour(
      { carrier: formData?.carrier?.uuid },
      {
        onSuccess: (data, values) => {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.TOURS, tourUUID],
          });
          toast.update(id, {
            autoClose: 6000,
            render: t('carrierSuccessfullyAssigned'),
            type: 'success',
            isLoading: false,
          });
        },
        onError: (error, variables, context) => {
          toast.update(id, {
            autoClose: 6000,
            render: t('carrierCouldNotBeAssigned'),
            type: 'error',
            isLoading: false,
          });
        },
      }
    );
  };

  return (
    <FormStyles onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" spacing={6}>
        <Controller
          name="carrier"
          control={control}
          render={({ field }) => (
            <CarrierAutoCompleteTextField
              label="Carrier"
              {...field}
              sx={{ width: '100%' }}
            />
          )}
        />
        <Box>
          <LoadingButton
            size="large"
            type="submit"
            variant="primary"
            loading={isPending}
            startIcon={<AssignIcon />}
            disabled={!isValid}
          >
            {t('assign')}
          </LoadingButton>
        </Box>
      </Stack>
    </FormStyles>
  );
};
