import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const InProgressIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M17.5556 19C16.7578 19 16.1111 19.6716 16.1111 20.5C16.1111 21.3284 16.7578 22 17.5556 22C18.3533 22 19 21.3284 19 20.5C19 19.6716 18.3533 19 17.5556 19Z" />
        <path d="M11.5 19C10.7023 19 10.0556 19.6716 10.0556 20.5C10.0556 21.3284 10.7023 22 11.5 22C12.2977 22 12.9444 21.3284 12.9444 20.5C12.9444 19.6716 12.2977 19 11.5 19Z" />
        <path d="M4 20.5C4 19.6716 4.6467 19 5.44444 19C6.24219 19 6.88889 19.6716 6.88889 20.5C6.88889 21.3284 6.24219 22 5.44444 22C4.6467 22 4 21.3284 4 20.5Z" />
      </SvgIcon>
    );
  }
);

InProgressIcon.displayName = 'InProgressIcon';

export default InProgressIcon;
