import { useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { DebouncedFunc } from 'lodash';

export function useDebouncedState<T = any>(
  initialState?: T,
  debounceTime = 500
): [
  T | undefined,
  DebouncedFunc<React.Dispatch<React.SetStateAction<T | undefined>>>
] {
  const [state, setState] = useState<T | undefined>(initialState);

  const debouncedSetState = useRef(debounce(setState, debounceTime)).current;

  return [state, debouncedSetState];
}
