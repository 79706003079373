import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SortDescIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M20 5H4V7H20V5Z" />
        <path d="M4 11H14V13H4V11Z" />
        <path d="M4 17H8V19H4V17Z" />
      </SvgIcon>
    );
  }
);

SortDescIcon.displayName = 'SortDescIcon';

export default SortDescIcon;
