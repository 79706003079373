import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import React from 'react';
dayjs.extend(duration);

import { Currency, Distance } from '@dizzbo/core/i18n';
import { Skeleton, Stack, Typography } from '@dizzbo/ui';

import { useGetRouteSection } from '@core/hooks';
import { UUIDType } from '@types';
import { useTranslation } from 'react-i18next';
import { useOrderDetail } from '../hooks';

type Props = {
  sectionUUID: UUIDType;
};

export const SectionSummary: React.FC<Props> = ({ sectionUUID }: Props) => {
  const { orderData } = useOrderDetail();
  const { route: routeUUID } = orderData;

  const { data, isPending } = useGetRouteSection(routeUUID, sectionUUID);
  const formatDuration = (duration: number) => {
    return dayjs.duration(duration, 'seconds').format('D [d] H [h] m [min]');
  };

  const { t } = useTranslation();

  return (
    <>
      {isPending ? (
        <Skeleton variant="rounded" width={210} height={20} />
      ) : (
        <Stack spacing={2} direction="row">
          <Typography variant="footnote" color="primary">
            <Distance value={data?.distance / 1000} />
          </Typography>
          <Typography variant="footnote" color="primary">
            {formatDuration(data?.duration)}
          </Typography>
          <Typography variant="footnote" color="primary">
            {t('toll')} <Currency value={data?.toll} />
          </Typography>
        </Stack>
      )}
    </>
  );
};
