import { Skeleton as MuiSkeleton } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiSkeletonProps = ComponentProps<typeof MuiSkeleton>;

export const Skeleton = forwardRef<HTMLDivElement, MuiSkeletonProps>(
  (props, ref) => <MuiSkeleton ref={ref} {...props} />
);

Skeleton.displayName = 'Skeleton';
