import { alpha } from '@mui/material/styles';

import GreenSlantedPattern from '../../assets/images/patterns/pattern_green.png';

const iconBullet = (palette) => {
  return {
    iconBullet: {
      primary: {
        default: {
          color: palette.oxford[40],
          backgroundColor: palette.turquoise[100],
        },
      },

      secondary: {
        default: {
          color: palette.oxford[40],
          backgroundColor: palette.turquoise[60],
        },
      },
      tertiary: {
        default: {
          color: palette.turquoise[70],
          backgroundColor: palette.oxford[40],
        },
      },
      tertiaryDark: {
        default: {
          color: palette.oxford[20],
          backgroundColor: palette.oxford[60],
        },
      },
      bright: {
        default: {
          color: palette.oxford[40],
          backgroundColor: palette.green[100],
        },
      },
      brightDark: {
        default: {
          color: palette.green[100],
          backgroundColor: palette.oxford[40],
        },
      },
      inactive: {
        default: {
          color: palette.turquoise[20],
          backgroundColor: palette.oxford[20],
        },
      },
      disabled: {
        default: {
          color: palette.oxford[25],
          backgroundColor: alpha(palette.oxford[20], 0.25),
        },
      },
      info: {
        default: {
          color: palette.grey[70],
          backgroundColor: palette.blue[100],
        },
      },
      success: {
        default: {
          color: palette.turquoise[80],
          backgroundColor: palette.forest[100],
        },
      },
      mixed: {
        default: {
          color: palette.oxford[40],
          backgroundColor: palette.green[100],
          backgroundImage: GreenSlantedPattern,
          borderColor: palette.oxford[40],
        },
      },
    },
  };
};

export default iconBullet;
