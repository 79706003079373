import { useQuery } from '@tanstack/react-query';

import { getRouteSection } from '@core/api';
import { QueryKeys } from '@core/config';
import { UUIDType } from '@types';

export const useGetRouteSection = (
  routeUUID: UUIDType,
  sectionUUID: UUIDType
) => {
  return useQuery({
    queryKey: [QueryKeys.ROUTES, routeUUID, QueryKeys.SECTIONS, sectionUUID],
    queryFn: () => getRouteSection(routeUUID, sectionUUID),
    enabled: !!routeUUID,
  });
};
