import { Card as MuiCard } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiCardProps = ComponentProps<typeof MuiCard>;

export const Card = forwardRef<HTMLDivElement, MuiCardProps>((props, ref) => (
  <MuiCard ref={ref} {...props} />
));

Card.displayName = 'Card';
