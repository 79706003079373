import { usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';

import { createTourComment, getTourComments } from '@core/api';
import { QueryKeys } from '@core/config';
import { ConversationDialog } from '@dizzbo/core';
import {
  Button,
  Card,
  CardHeader,
  Chip,
  CommunicationIcon,
  TruckLTLIcon,
} from '@dizzbo/ui';
import { UUIDType } from '@types';
import { useTranslation } from 'react-i18next';

type Props = {
  tourUUID: UUIDType;
  reference: string;
};

export const TourSummary: React.FC<Props> = ({ tourUUID, reference }) => {
  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'tourConversationDialog',
  });

  const fetchComments = (params) => {
    return getTourComments(tourUUID, params);
  };

  const createComment = (data) => {
    return createTourComment(tourUUID, data);
  };

  const { t } = useTranslation();

  return (
    <>
      <Card
        variant="filled-secondary"
        elevation={1}
        sx={{
          height: '100%',
          width: '100%',
        }}
      >
        <CardHeader
          avatar={
            <Chip
              label="FTL"
              size="medium"
              variant="oxford-40"
              icon={<TruckLTLIcon />}
            />
          }
          title={`Dizzbo Tour ${reference}`}
          action={
            <Button
              aria-label="Communication"
              size="medium"
              variant="primary"
              startIcon={<CommunicationIcon />}
              onClick={() => popupState.open()}
            >
              {t('comments')}
            </Button>
          }
          variant="small"
          sx={{ borderBottom: 0 }}
        />
        {/* <CardContent>
        <Stack
          spacing={6}
          divider={<Divider orientation="horizontal" flexItem />}
        ></Stack>
      </CardContent> */}
      </Card>
      <ConversationDialog
        popupState={popupState}
        query={{
          queryFn: fetchComments,
          queryKey: [QueryKeys.TOURS, tourUUID, 'comments'],
        }}
        mutation={{
          mutationFn: createComment,
          mutationKey: [QueryKeys.TOURS, tourUUID, 'comments'],
        }}
      />
    </>
  );
};
