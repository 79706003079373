import { styled } from '@mui/material/styles';
import { useIsMutating, useQuery } from '@tanstack/react-query';
import intersection from 'lodash/intersection';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { getOrderTodos } from '@core/api';
import { QueryKeys } from '@core/config';
import { Box } from '@dizzbo/ui';

import { OrderTodoType, OrderType, TourType } from '@types';
import {
  LoadingTimeDialog,
  OrderConfirmationDialog,
  OrderReviewDialog,
  PoDReviewDialog,
  PoDUploadDialog,
  TransportContractDialog,
  UnloadingTimeDialog,
} from './Dialogs';
import { PhaseItem } from './PhaseItem';

const ContainerStyles = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingTop: 24,
  paddingRight: 24,
  paddingLeft: 24,
  paddingBottom: 24,
}));

type Props = {
  orderData: OrderType;
  tourData: TourType;
};

export const TodosSection: React.FC<Props> = ({ orderData, tourData }) => {
  const { t } = useTranslation();
  const settings = {
    order_confirmation_todo: {
      label: t('confirmOrder'),
      buttonLabel: t('sendOrderConfirmation'),
      disableAction: true,
      dialogComponent: OrderConfirmationDialog,
    },
    transport_contract_todo: {
      label: t('confirmTransportContract'),
      buttonLabel: t('sendTransportContract'),
      disableAction: true,
      dialogComponent: TransportContractDialog,
    },
    loading_time_todo: {
      label: t('loadingTime'),
      buttonLabel: t('setLoadingTime'),
      disableAction: false,
      dialogComponent: LoadingTimeDialog,
    },
    unloading_time_todo: {
      label: t('unloadingTime'),
      buttonLabel: t('setUnloadingTime'),
      disableAction: false,
      dialogComponent: UnloadingTimeDialog,
    },
    pod_upload_todo: {
      label: t('podUpload'),
      buttonLabel: t('uploadPoD'),
      disableAction: false,
      dialogComponent: PoDUploadDialog,
    },
    pod_review_todo: {
      label: t('podReview'),
      buttonLabel: t('reviewPoD'),
      disableAction: false,
      dialogComponent: PoDReviewDialog,
    },
    order_review_todo: {
      label: t('orderReview'),
      buttonLabel: t('reviewOrder'),
      disableAction: false,
      dialogComponent: OrderReviewDialog,
    },
  };

  const { uuid: orderUUID } = orderData;
  const prevMutationCountRef = useRef<number>(0);

  const { refetch, data: todosData } = useQuery({
    queryKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.TODOS],
    queryFn: () => getOrderTodos(orderUUID),
  });

  const isMutatingOrderCount = useIsMutating({
    mutationKey: [QueryKeys.ORDERS, orderUUID],
  });

  useEffect(() => {
    refetch();

    prevMutationCountRef.current = isMutatingOrderCount;
  }, [isMutatingOrderCount]);

  const planningTodos: OrderTodoType[] = [
    'order_confirmation_todo',
    'transport_contract_todo',
  ];
  const activePlanningTodos = intersection(planningTodos, todosData);

  const executionTodos: OrderTodoType[] = [
    'loading_time_todo',
    'unloading_time_todo',
    'pod_upload_todo',
    'pod_review_todo',
    'order_review_todo',
  ];

  const activeExecutionTodos: OrderTodoType[] = intersection(
    executionTodos,
    todosData
  );

  return activePlanningTodos.length > 0 || activeExecutionTodos.length > 0 ? (
    <ContainerStyles>
      {activePlanningTodos.length > 0 && (
        <PhaseItem
          label={t('orderPlanningTodos')}
          todos={activePlanningTodos}
          settings={settings}
          orderData={orderData}
          tourData={tourData}
        />
      )}
      {activeExecutionTodos.length > 0 && (
        <PhaseItem
          label={t('orderExecutionTodos')}
          todos={activeExecutionTodos}
          settings={settings}
          orderData={orderData}
          tourData={tourData}
        />
      )}
    </ContainerStyles>
  ) : null;
};
