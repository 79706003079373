const typography = {
  fontSize: 12,
  fontFamily:
    '"EuclidCircularARegular", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
  h1: {
    fontWeight: 600,
    fontSize: 40,
    lineHeight: '48px',
  },
  h2: {
    fontWeight: 600,
    fontSize: 28,
    lineHeight: '36px',
  },
  h3: {
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '32px',
  },
  h4: {
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '28px',
  },
  h5: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '24px',
  },
  h6: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
  },
  leadTextRegular: {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '24px',
  },
  leadTextBold: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '24px',
  },
  bodyRegular: {
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '20px',
  },
  bodyBold: {
    fontWeight: 600,
    fontSize: 15,
    lineHeight: '20px',
  },
  bodyRegularSmall: {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '18px',
  },
  bodyBoldSmall: {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '18px',
  },
  footnote: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '16px',
  },
  tiny: {
    fontWeight: 400,
    fontSize: 10,
    lineHeight: '14px',
  },
  label: {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '18px',
  },
  labelSmall: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '14px',
  },
  message: {
    fontWeight: 600,
    fontSize: 15,
    lineHeight: '20px',
  },
  buttonRegular: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '20px',
  },
  buttonRegularSmall: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
  },
  textLinkRegular: {
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '20px',
  },
  textLinkRegularSmall: {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '18px',
  },
  tabsRegular: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '24px',
  },
  tabsRegularSmall: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
  },
};

declare module '@mui/material/styles' {
  interface TypographyVariants {
    leadTextRegular: React.CSSProperties;
    leadTextBold: React.CSSProperties;
    bodyRegular: React.CSSProperties;
    bodyBold: React.CSSProperties;
    bodyRegularSmall: React.CSSProperties;
    bodyBoldSmall: React.CSSProperties;
    footnote: React.CSSProperties;
    tiny: React.CSSProperties;
    label: React.CSSProperties;
    labelSmall: React.CSSProperties;
    message: React.CSSProperties;
    buttonRegular: React.CSSProperties;
    buttonRegularSmall: React.CSSProperties;
    textLinkRegular: React.CSSProperties;
    textLinkRegularSmall: React.CSSProperties;
    tabsRegular: React.CSSProperties;
    tabsRegularSmall: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    leadTextRegular?: React.CSSProperties;
    leadTextBold?: React.CSSProperties;
    bodyRegular?: React.CSSProperties;
    bodyBold?: React.CSSProperties;
    bodyRegularSmall?: React.CSSProperties;
    bodyBoldSmall?: React.CSSProperties;
    footnote?: React.CSSProperties;
    tiny?: React.CSSProperties;
    label?: React.CSSProperties;
    labelSmall?: React.CSSProperties;
    message?: React.CSSProperties;
    buttonRegular?: React.CSSProperties;
    buttonRegularSmall?: React.CSSProperties;
    textLinkRegular?: React.CSSProperties;
    textLinkRegularSmall?: React.CSSProperties;
    tabsRegular: React.CSSProperties;
    tabsRegularSmall: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    leadTextRegular: true;
    leadTextBold: true;
    bodyRegular: true;
    bodyBold: true;
    bodyRegularSmall: true;
    bodyBoldSmall: true;
    footnote: true;
    tiny: true;
    label: true;
    labelSmall: true;
    message: true;
    buttonRegular: true;
    buttonRegularSmall: true;
    textLinkRegular: true;
    textLinkRegularSmall: true;
    tabsRegular: true;
    tabsRegularSmall: true;
  }
}
export default typography;
