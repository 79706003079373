import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography, Stack, TextField } from '@dizzbo/ui';
import { AccountIcon } from '@dizzbo/ui';
import { useAuth } from '../../hooks';

type Props = {};

export const Account: React.FC<Props> = () => {
  const { t, i18n } = useTranslation();
  const { user } = useAuth();

  return (
    <Box>
      <Stack spacing={6}>
        <Stack direction='row' spacing={2} alignItems='center'>
          <AccountIcon sx={{ width: 30, height: 30 }} />
          <Typography variant='h3'>{t('account')}</Typography>
        </Stack>
        <Stack direction='row' spacing={2}>
          <TextField
            label={t('firstName')}
            fullWidth
            value={user?.firstName}
            display
          />
          <TextField
            label={t('lastName')}
            fullWidth
            value={user?.lastName}
            display
          />
          <TextField label='Email' fullWidth value={user?.email} display />
        </Stack>
        <Stack direction='row' spacing={2} justifyContent='start'>
          <TextField
            label={t('phoneNumber')}
            fullWidth
            value={user?.phone}
            display
          />
          <TextField
            label={t('cellPhoneNumber')}
            fullWidth
            value={user?.mobilePhone}
            display
          />
        </Stack>
      </Stack>
    </Box>
  );
};
