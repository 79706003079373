import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';

import { Box } from '@dizzbo/ui';

import { View } from '../views';
import { LoginForm } from '../components/LoginForm';

type Props = {
  bgImage?: string;
};

export const LoginView: React.FC<Props> = ({ bgImage }) => {
  return (
    <View title='Login'>
      <Box
        sx={{
          height: '100vh',
          backgroundImage: `url(${bgImage})`,
          backgroundColor: '#17253C',
          backgroundSize: { xs: 'cover', xl: 'contain' },
          backgroundPosition: 'top right',
          backgroundRepeat: 'no-repeat',
          flexGrow: 1,
        }}
      >
        <Grid container spacing={0} sx={{ height: '100%' }}>
          <Grid
            xs={12}
            md={5}
            sx={{
              backgroundColor: 'rgba(8,19,36,0.6)',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <LoginForm />
          </Grid>
          <Grid xs={0} md={7}></Grid>
        </Grid>
      </Box>
    </View>
  );
};
