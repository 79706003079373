import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TableExpandIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M2 2H22V4H2V2Z" />
        <path d="M12 5L8 9L16 9L12 5Z" />
        <path d="M2 6.5H6V8.5H2V6.5Z" />
        <path d="M2 15.5H6V17.5H2V15.5Z" />
        <path d="M22 6.5H18V8.5H22V6.5Z" />
        <path d="M18 15.5H22V17.5H18V15.5Z" />
        <path d="M22 11H2V13H22V11Z" />
        <path d="M16 15L12 19L8 15L16 15Z" />
        <path d="M22 22V20H2V22H22Z" />
      </SvgIcon>
    );
  }
);

TableExpandIcon.displayName = 'TableExpandIcon';

export default TableExpandIcon;
