import { alpha } from '@mui/material/styles';

const tab = (palette) => {
  return {
    tab: {
      text: {
        default: {
          color: palette.turquoise[150],
        },
        active: {
          color: palette.oxford[40],
        },
      },
    },
  };
};

export default tab;
