import { alpha } from '@mui/material/styles';

// Button Styles are used for Button and IconButton

const switchInput = (palette) => {
  return {
    switch: {
      default: {
        backgroundColor: palette.common.white,
      },
      active: {
        color: palette.oxford[150],
      },
    },
  };
};

export default switchInput;
