import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import React, { forwardRef } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { updateOrderStop } from '@core/api';
import { AutoSave } from '@core/components';
import { QueryKeys } from '@core/config';
import { useFieldErrors } from '@dizzbo/core/hooks';
import {
  Box,
  Card,
  CardContent,
  DatePicker,
  DateTimePicker,
  Divider,
  SingleInputTimeRangeField,
  Stack,
  Typography,
} from '@dizzbo/ui';

import { isTimeSlotInputValid } from '@dizzbo/core';
import { DateRange } from '@mui/x-date-pickers-pro';
import { OrderDetailStopDateFormData, StopType, UUIDType } from '@types';
import {
  parseOrderDetailStopDateFormData,
  parseTimeSlotDateRange,
} from '@utils';
import { useTranslation } from 'react-i18next';
import { useOrderDetail } from '../../hooks';
import { DeliveryLoadingPointAutocomplete } from './DeliveryLoadingPointAutocomplete';
import { LoadingPointBullet } from './LoadingPointBullet';

const StopContainerStyles = styled(Stack)(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.styles.paper.filledSecondary.default.backgroundColor,
  '&:hover': {
    backgroundColor: theme.styles.planner.stopListItem.hover.backgroundColor,
  },
}));

const BulletContainerStyles = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'lastItem',
})<any>(({ lastItem }) => {
  if (lastItem) {
    return {
      height: '100%',
      position: 'relative',
    };
  }
  return {
    height: '100%',
    position: 'relative',
    '&::after': {
      content: "' '",
      position: 'absolute',
      top: 52,
      bottom: 0,
      left: '50%',
      border: '1px dashed #404E64',
    },
  };
});
const NotesStyles = styled(Typography)(() => ({}));

type Props = {
  stop: StopType;
  deliveryUUID: UUIDType;
  index: number;
  action: string;
  style?: object;
  lastItem?: boolean;
};

export const DeliveryLoadingPointItem = forwardRef<HTMLDivElement, Props>(
  (
    { stop, deliveryUUID, index, action = 'loading', style, lastItem = false },
    ref
  ) => {
    const queryClient = useQueryClient();
    const { orderUUID } = useOrderDetail();
    const { t } = useTranslation();

    const methods = useForm({
      mode: 'all',
      values: {
        requestedDate: stop?.requestedDate ? dayjs(stop?.requestedDate) : null,
        scheduledAt: stop?.scheduledAt ? dayjs(stop?.scheduledAt) : null,
        arrivedAt: stop?.arrivedAt ? dayjs(stop?.arrivedAt) : null,
        timeSlot:
          stop?.timeslotStartsAt && stop?.timeslotEndsAt
            ? [
                stop?.timeslotStartsAt ? dayjs(stop?.timeslotStartsAt) : null,
                stop?.timeslotEndsAt ? dayjs(stop?.timeslotEndsAt) : null,
              ]
            : null,
      },

      // resolver: yupResolver(schema),
    });

    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
    } = methods;

    const mutation = useMutation({
      mutationKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.DELIVERIES],
      mutationFn: (values: Partial<StopType>) =>
        updateOrderStop(orderUUID, stop?.uuid, values),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.STOPS, stop?.uuid],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.DELIVERIES],
        });
      },
      onError: (error) => {
        console.log(
          '%c <<<Error in useMutationHook in DeliveryLoadingPointItem.tsx>>> Error: ',
          'background: #222; color: #bada55',
          error
        );
      },
    });

    const { hasFieldError, fieldError } = useFieldErrors(
      mutation.isError,
      errors,
      mutation.error
    );

    const onSubmit = (formData: OrderDetailStopDateFormData) => {
      const parsedData: Partial<StopType> =
        parseOrderDetailStopDateFormData(formData);

      mutation.mutate(parsedData);
    };

    return (
      <FormProvider {...methods}>
        <Box sx={{ backgroundColor: '#FFFFFF', ...style }} ref={ref}>
          <StopContainerStyles direction="row" py={3} px={6}>
            <BulletContainerStyles lastItem={lastItem}>
              <LoadingPointBullet index={index} />
            </BulletContainerStyles>
            <Stack
              spacing={3}
              direction="column"
              ml={6}
              sx={{
                display: 'inline',
                width: '100%',
              }}
            >
              <DeliveryLoadingPointAutocomplete
                label={t('stop')}
                deliveryUUID={deliveryUUID}
                value={stop}
                disableClearable={false}
                action={action}
              />

              <Stack spacing={3} direction="row">
                <Controller
                  name="requestedDate"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      sx={{ width: '100%', minWidth: 160, maxWidth: 160 }}
                      label={t('customerPlan')}
                      disabled={!stop}
                      slotProps={{
                        textField: {
                          error: hasFieldError(field),
                          helperText: fieldError(field),
                        },
                      }}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="timeSlot"
                  control={control}
                  render={({ field }) => {
                    const { value: timeSlotDateRange, ...restFieldProps } =
                      field;
                    const parsedTimeSlotDateRange: DateRange<Dayjs | null> =
                      parseTimeSlotDateRange(timeSlotDateRange);

                    return (
                      <SingleInputTimeRangeField
                        sx={{
                          width: '100%',
                          minWidth: 120,
                          maxWidth: 120,
                        }}
                        label={t('timeslot')}
                        disabled={!stop}
                        format="HH:mm"
                        slotProps={{
                          textField: {
                            error:
                              hasFieldError(field) ||
                              !isTimeSlotInputValid(parsedTimeSlotDateRange),
                            helperText: fieldError(field),
                          },
                        }}
                        {...restFieldProps}
                        value={parsedTimeSlotDateRange}
                      />
                    );
                  }}
                />
                <Controller
                  name="scheduledAt"
                  control={control}
                  render={({ field }) => (
                    <DateTimePicker
                      sx={{ width: '100%', minWidth: 200, maxWidth: 200 }}
                      label={t('dispoPlan')}
                      disabled={!stop}
                      slotProps={{
                        textField: {
                          error: hasFieldError(field),
                          helperText: fieldError(field),
                        },
                      }}
                      {...field}
                    />
                  )}
                />
                <Divider orientation="vertical" flexItem />
                <Controller
                  name="arrivedAt"
                  control={control}
                  render={({ field }) => (
                    <DateTimePicker
                      sx={{ width: '100%', minWidth: 200, maxWidth: 200 }}
                      label={t('actualTime')}
                      disabled={!stop}
                      {...field}
                      slotProps={{
                        textField: {
                          error: hasFieldError(field),
                          helperText: fieldError(field),
                        },
                      }}
                    />
                  )}
                />
              </Stack>
              <AutoSave
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                isValid={isValid}
                control={control}
              />

              {stop?.loadingPoint?.openingHours && (
                <Card
                  variant="outlined"
                  sx={{ backgroundColor: 'transparent' }}
                >
                  <CardContent>
                    <Stack>
                      <Typography
                        variant="labelSmall"
                        color="primary-light"
                        mb={1}
                      >
                        {t('loadingPointNotes')}
                      </Typography>
                      <NotesStyles
                        variant="bodyRegular"
                        sx={{ whiteSpace: 'pre-wrap' }}
                        dangerouslySetInnerHTML={{
                          __html: stop?.loadingPoint?.openingHours,
                        }}
                      />
                    </Stack>
                  </CardContent>
                </Card>
              )}
            </Stack>
          </StopContainerStyles>
        </Box>
      </FormProvider>
    );
  }
);

DeliveryLoadingPointItem.displayName = 'DeliveryLoadingPointItem';
