import Grid from '@mui/material/Unstable_Grid2';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '@dizzbo/core/hooks';
import { View } from '@dizzbo/core/views';
import { Box, PageHeader } from '@dizzbo/ui';

import { BarChartCard, StatsCard } from '../components';
import { OrderTodos } from '../components/OrderTodos';

export const DashboardView: FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  return (
    <View title="Dashboard">
      <PageHeader
        title={t('welcomeToDizzboTMS', { username: user.firstName })}
        // title={`${t('hello')} ${user.firstName}, ${t('welcomeToDizzboTMS')}`}
      />
      <Box sx={{ overflowY: 'auto', height: 'calc(-64px + 100vh)' }}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            paddingTop: 12,
            paddingX: 2,
          }}
        >
          <Grid
            container
            spacing={6}
            sx={{
              marginLeft: 'auto',
              marginRight: 'auto',
              paddingRight: 0,
              paddingBottom: 0,
            }}
          >
            <Grid xs={4}>
              <StatsCard label="avg. turnover / day" value="64.698 €" />
            </Grid>
            <Grid xs={4}>
              <StatsCard label="avg. turnover / week" value="164.698 €" />
            </Grid>
            <Grid xs={4}>
              <StatsCard label="profit this week" value="64.698 €" />
            </Grid>
            <Grid xs={4}>
              <BarChartCard title="avg. turnover / day" />
            </Grid>
            <Grid xs={4}>
              <BarChartCard title="avg. turnover / week" />
            </Grid>
            <Grid xs={4}>
              <BarChartCard title="profit this week" />
            </Grid>

            <Grid xs={6} mt={12} mb={12}>
              <OrderTodos />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </View>
  );
};
