const Divider = (theme) => {
  return {
    MuiDivider: {
      defaultProps: {},
      styleOverrides: {
        root: {
          borderColor: theme.styles.divider.primary.default.borderColor,
        },
        light: {
          borderColor: theme.styles.divider.primaryContrast.default.borderColor,
        },
      },
    },
  };
};

export default Divider;
