import { styled } from '@mui/material/styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Currency } from '@dizzbo/core/i18n';
import {
  Alert,
  ArrowRightIcon,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  Skeleton,
  SquareIcon,
  Stack,
  Typography,
  WarningIcon,
} from '@dizzbo/ui';

import { confirmOrder, getOrderTodoTasks } from '@core/api';
import { QueryKeys } from '@core/config';
import { OrderTodoType, OrderType } from '@types';

const SubtitleStyles = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
  padding: 24,
}));

const ContentCardStyles = styled(Card)(({ theme }) => ({
  width: '100%',
  height: '100%',
}));

type Props = {
  orderData: OrderType;
  popupState: PopupState;
  todo: OrderTodoType;
};

export const OrderConfirmationDialog: React.FC<Props> = ({
  orderData,
  popupState,
  todo,
}: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const {
    uuid: orderUUID,
    reference,
    status,
    loader,
    price,
    stops,
  } = orderData;

  const { data: orderTodosData, isPending: isOrderTodosPending } = useQuery({
    queryKey: [QueryKeys.ORDERS, orderUUID, todo],
    queryFn: () => getOrderTodoTasks(orderUUID, todo),
    enabled: popupState.isOpen,
  });

  const { mutate: mutateConfirmOrder, isPending } = useMutation({
    mutationKey: [QueryKeys.ORDERS, orderUUID],
    mutationFn: (values) => confirmOrder(orderUUID),
    onSuccess() {
      toast.success(t('orderConfirmed'));
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ORDERS],
      });
      popupState.close();
    },
    onError() {
      toast.error(t('orderCouldNotBeConfirmed'));
      popupState.close();
    },
  });

  const handleClick = () => {
    mutateConfirmOrder();
    popupState.close();
  };

  return (
    <Dialog
      PaperProps={{
        elevation: 6,
        variant: 'filled-primary',
        sx: {
          minWidth: 600,
        },
      }}
      scroll="paper"
      {...bindDialog(popupState)}
    >
      <DialogTitle onClose={popupState.close}>
        <Typography variant="h3" color="primary">
          {reference}
        </Typography>
        <Typography
          variant="h3"
          color="warning.main"
          sx={{ textTransform: 'capitalize', marginLeft: 1 }}
        >
          – {status}
        </Typography>
      </DialogTitle>

      <DialogContent dividers sx={{ padding: 0 }}>
        <SubtitleStyles>
          <Typography variant="h5">{t('confirmOrder')}</Typography>
        </SubtitleStyles>
        {loader && (
          <ContentCardStyles variant="filled-green" elevation={0} square>
            <CardContent>
              <>
                <Typography variant="buttonRegularSmall">
                  {`${t('orderConfirmationDialog_customerNumber')}: ${loader?.customerNumber}`}
                </Typography>
                <br />
                <Typography variant="labelSmall">
                  {`${t('customer')}: ${loader?.name} ${loader?.secondName}`}
                </Typography>
                <br />
                <Typography variant="labelSmall">
                  {t('price')}: <Currency value={price} />
                </Typography>
                <Typography variant="labelSmall">{t('start')}:</Typography>
                <Typography variant="buttonRegularSmall">
                  {stops[0]?.scheduledAt &&
                    dayjs(stops[0]?.scheduledAt).format('DD.MM.YYYY')}
                </Typography>
              </>
            </CardContent>
          </ContentCardStyles>
        )}
        {orderTodosData?.length > 0 && (
          <ContentCardStyles variant="filled-disabled" elevation={0} square>
            <CardContent>
              {isOrderTodosPending ? (
                <Skeleton width="100%" height={56} />
              ) : (
                <Alert
                  title={t('orderCannotBeConfirmed')}
                  message={orderTodosData.map((subTodo) => (
                    <Box key={subTodo.uuid + subTodo.field}>
                      <Typography component={'span'}>
                        {subTodo.fieldLabel && `${subTodo.fieldLabel}:`}{' '}
                        {subTodo.message}
                      </Typography>
                      <br />
                    </Box>
                  ))}
                  variant="standard"
                  severity="warning"
                  icon={<WarningIcon />}
                />
              )}
            </CardContent>
          </ContentCardStyles>
        )}
      </DialogContent>
      <DialogActions>
        <Stack spacing={3} direction="row">
          <Button variant="tertiary" onClick={() => popupState.close()}>
            {t('cancel')}
          </Button>
          {orderTodosData?.length > 0 ? (
            <Button
              startIcon={<ArrowRightIcon />}
              size="medium"
              component={RouterLink}
              to={`/orders/${orderUUID}`}
            >
              {t('goToOrderDetails')}
            </Button>
          ) : (
            <LoadingButton
              type="submit"
              variant="primary"
              onClick={handleClick}
              loading={isPending}
              startIcon={<SquareIcon />}
            >
              {t('confirmOrder')}
            </LoadingButton>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
