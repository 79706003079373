import { styled } from '@mui/material/styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  bindDialog,
  PopupState,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { base64toBlob } from '@dizzbo/core/utils';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  Stack,
  Typography,
  ViewIcon,
  WarningIcon,
} from '@dizzbo/ui';

import {
  createTransportContract,
  getTransportContractPreview,
} from '@core/api';
import { QueryKeys } from '@core/config';

import { TransportContractPreviewDialog } from '@order-detail/components/TourDetailForm/TransportContract/TransportContractPreviewDialog';
import { OrderType, TourType } from '@types';
import { useTranslation } from 'react-i18next';
import TransportContractBackgroundImage from '../../../../../assets/images/transport-contract-half-thumbnail.png';

const SubtitleStyles = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
  padding: 24,
}));

const PreviewCardStyles = styled(Card)(() => ({
  width: '40%',
  height: 200,
  backgroundImage: `url(${TransportContractBackgroundImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'center',
  backgroundPositionY: 'bottom',
}));

const PreviewCardContentStyles = styled(CardContent)(() => ({
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'flex-end',
}));

const ContentCardStyles = styled(Card)(() => ({
  width: '60%',
  height: 200,
}));

const ContactCardStyles = styled(Card)(() => ({
  width: '100%',
  height: 80,
}));

type Props = {
  orderData: OrderType;
  tourData: TourType;
  popupState: PopupState;
};

export const TransportContractDialog: React.FC<Props> = ({
  orderData,
  tourData,
  popupState,
}: Props) => {
  const queryClient = useQueryClient();

  const podPreviewDialogPopupState = usePopupState({
    variant: 'dialog',
    popupId: 'podReviewDialog',
  });
  const { uuid: orderUUID, reference, status } = orderData;
  const { uuid: tourUUID, reference: tourReference } = tourData;

  const { control } = useForm({
    mode: 'all',
    defaultValues: {
      sendEmail: true,
    },
  });

  const { data: transportContractData, isPending } = useQuery({
    queryKey: [QueryKeys.TOURS, tourUUID, QueryKeys.TRANSPORT_CONTRACTS],
    queryFn: () => getTransportContractPreview(tourUUID),
    refetchOnWindowFocus: false,
    enabled: !!tourUUID && podPreviewDialogPopupState.isOpen,
  });

  let url = undefined;

  if (transportContractData && transportContractData.file) {
    const blob = base64toBlob(transportContractData.file, 'application/pdf');
    url = URL.createObjectURL(blob);
  }

  const { t } = useTranslation();

  const { mutate: mutateCreateTransportContract }: any = useMutation({
    mutationKey: [QueryKeys.ORDERS, orderUUID],
    mutationFn: () => createTransportContract(tourUUID),
    onSuccess() {
      toast.success(t('transportContractCreated'));
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ORDERS, orderUUID],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TOURS, tourUUID],
      });
      popupState.close();
    },
    onError() {
      toast.error(t('transportContractCouldNotBeCreated'));
      popupState.close();
    },
  });

  const handleClick = () => {
    mutateCreateTransportContract();
    popupState.close();
  };

  return (
    <Dialog
      PaperProps={{
        elevation: 6,
        variant: 'filled-primary',
        sx: {
          minWidth: 600,
        },
      }}
      scroll="paper"
      {...bindDialog(popupState)}
    >
      <DialogTitle onClose={popupState.close}>
        <Typography variant="h3" color="primary">
          {reference}
        </Typography>
        <Typography
          variant="h3"
          color="warning.main"
          sx={{ textTransform: 'capitalize', marginLeft: 1 }}
        >
          – {status}
        </Typography>
      </DialogTitle>
      <DialogContent dividers sx={{ padding: 0 }}>
        <SubtitleStyles>
          <Typography variant="h5">{t('sendTransportContract')}</Typography>
        </SubtitleStyles>
        {(!orderData.carrier || !orderData.price) && (
          <Box p={6}>
            <Alert
              title="No Carrier assigend"
              variant="standard"
              severity="warning"
              icon={<WarningIcon />}
            />
          </Box>
        )}
        <Stack direction="row">
          <PreviewCardStyles variant="filled-secondary" elevation={0} square>
            <PreviewCardContentStyles>
              <>
                <LoadingButton
                  loading={isPending && podPreviewDialogPopupState.isOpen}
                  startIcon={<ViewIcon />}
                  size="medium"
                  variant="primary"
                  onClick={() => podPreviewDialogPopupState.open()}
                >
                  {t('view')}
                </LoadingButton>
                {url && (
                  <TransportContractPreviewDialog
                    tourReference={tourReference}
                    popupState={podPreviewDialogPopupState}
                    file={url}
                  />
                )}
              </>
            </PreviewCardContentStyles>
          </PreviewCardStyles>
          <ContentCardStyles variant="filled-turquoise" elevation={0} square>
            <CardContent>
              <Typography variant="buttonRegularSmall">
                {t('carrierNumber')}: {orderData.carrier?.customerNumber}
              </Typography>
              <br />
              <Typography variant="labelSmall">
                {t('carrier')}: {orderData.carrier?.name}{' '}
                {orderData.carrier?.secondName}
              </Typography>
            </CardContent>
          </ContentCardStyles>
        </Stack>
        {/* <ContactCardStyles variant="filled-secondary-light">
          <CardContent>
            <Controller
              control={control}
              name="sendEmail"
              render={({ field }) => (
                <FormControlLabel
                  control={<Switch {...field} />}
                  label="Send contract confirmation via email"
                />
              )}
            />
          </CardContent>
        </ContactCardStyles> */}
      </DialogContent>
      <DialogActions>
        <Stack spacing={3} direction="row">
          <Button variant="tertiary" onClick={() => popupState.close()}>
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            form="form"
            onClick={handleClick}
            disabled={!orderData.carrier}
          >
            Ok
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
