import React from 'react';
import { styled } from '@mui/material/styles';

import { Box, Tooltip } from '@dizzbo/ui';

import { AppBarNavItemUser } from './AppBarNavItemUser';
import { AppBarNavItem } from './AppBarNavItem';
import { AppBarNavItemToggle } from './AppBarNavItemToggle';
import { NetworkActivityIndicator } from './NetworkActivityIndicator';

const AppBarStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
  zIndex: theme.zIndex.appBar,
  userSelect: 'none',

  minMeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

interface AppBarProps {
  handleToggleAppBar: () => void;
  expanded: boolean;
  navItems: any;
}

export const AppBar = ({
  handleToggleAppBar,
  expanded,
  navItems,
}: AppBarProps) => {
  return (
    <>
      <AppBarStyles>
        <Box>
          <AppBarNavItemToggle handleClick={() => handleToggleAppBar()} />
          {navItems.map((item) => {
            return (
              <Tooltip
                key={item.route}
                title={expanded ? '' : item.label}
                placement='right'
              >
                <Box>
                  <AppBarNavItem
                    label={item.label}
                    route={item.route}
                    Icon={item.icon}
                    key={item.route}
                    expanded={expanded}
                    count={item?.badgeContent}
                  />
                </Box>
              </Tooltip>
            );
          })}
        </Box>
        <Box>
          <NetworkActivityIndicator />
          <AppBarNavItemUser expanded={expanded} />
        </Box>
      </AppBarStyles>
    </>
  );
};
