import { alpha } from '@mui/material/styles';

const paper = (palette) => {
  return {
    paper: {
      outlined: {
        default: {
          borderColor: alpha(palette.oxford[120], 0.8),
        },
      },
      filled: {
        default: {
          backgroundColor: palette.oxford[80],
        },
      },
      filledPrimary: {
        default: {
          backgroundColor: palette.oxford[80],
        },
      },
      filledSecondary: {
        default: {
          backgroundColor: alpha(palette.oxford[30], 0.2),
        },
      },
      filledSecondaryLight: {
        default: {
          backgroundColor: alpha(palette.oxford[30], 0.08),
        },
      },
      filledDisabled: {
        default: {
          backgroundColor: palette.oxford[90],
        },
      },
    },
  };
};

export default paper;
