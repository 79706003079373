import React from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useVirtualizer } from '@tanstack/react-virtual';
import { SxProps, styled } from '@mui/material/styles';

import { QueryKeys } from '@core/config';
import { Box } from '@dizzbo/ui';
import { getTours } from '@core/api';

import { OrderListItem } from '../OrderListItem';

const PAGE_SIZE = 20;

const ContainerStyles = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  overflow: 'auto',
  padding: 24,
}));

type Props = {
  sx?: SxProps;
};

export const OrderList: React.FC<Props> = () => {
  const { data, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: [QueryKeys.TOURS],
      queryFn: ({ pageParam }) =>
        getTours({
          limit: PAGE_SIZE,
          offset: pageParam,
          hasRoute: true,
          hasVehicle: false,
        }),
      initialPageParam: 0,
      getNextPageParam: (_lastGroup, groups) =>
        _lastGroup.next ? groups.length * PAGE_SIZE : null,
    });

  const allTours = data ? data.pages.flatMap((d) => d.results) : [];

  const parentRef = React.useRef();

  const virtualizer = useVirtualizer({
    count: data?.pages[0]?.count ?? 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 86,
    overscan: 5,
  });

  React.useEffect(() => {
    const [lastItem] = [...virtualizer.getVirtualItems()].reverse();

    if (!lastItem) {
      return;
    }

    if (
      lastItem.index >= allTours.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  }, [
    hasNextPage,
    fetchNextPage,
    allTours.length,
    isFetchingNextPage,
    virtualizer.getVirtualItems(),
  ]);

  return (
    <ContainerStyles ref={parentRef}>
      <div
        style={{
          height: `${virtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {virtualizer.getVirtualItems().map((virtualRow) => {
          const tour = allTours[virtualRow.index];

          return (
            <div
              key={virtualRow.index}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '88px',
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              {tour && <OrderListItem data={tour} />}
            </div>
          );
        })}
      </div>
    </ContainerStyles>
  );
};
