import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CheckBoxCheckedIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props} ref={ref}>
        <rect width="16" height="16" rx="4" fill="#3B6E71" />
        <path
          d="M6.15792 11.9838L6.14134 11.9999L3 8.95902L4.3485 7.64322L6.15798 9.38457L11.6515 4L13 5.3158L6.17456 12L6.15792 11.9838Z"
          fill="white"
        />
      </SvgIcon>
    );
  }
);

CheckBoxCheckedIcon.displayName = 'CheckBoxCheckedIcon';

export default CheckBoxCheckedIcon;
