import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AttachmentIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5 3.5C9.39543 3.5 8.5 4.39543 8.5 5.5V17C8.5 18.933 10.067 20.5 12 20.5C13.933 20.5 15.5 18.933 15.5 17V7H17.5V17C17.5 20.0376 15.0376 22.5 12 22.5C8.96243 22.5 6.5 20.0376 6.5 17V5.5C6.5 3.29086 8.29086 1.5 10.5 1.5C12.7091 1.5 14.5 3.29086 14.5 5.5V16.5C14.5 17.8807 13.3807 19 12 19C10.6193 19 9.5 17.8807 9.5 16.5V7H11.5V16.5C11.5 16.7761 11.7239 17 12 17C12.2761 17 12.5 16.7761 12.5 16.5V5.5C12.5 4.39543 11.6046 3.5 10.5 3.5Z"
        />
      </SvgIcon>
    );
  }
);

AttachmentIcon.displayName = 'AttachmentIcon';

export default AttachmentIcon;
