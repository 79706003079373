import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PrintIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 4H19V8H22H23V9V18V19H22H2H1V18V9V8H2H5V4ZM19 13V10H21V17H3V10H5V13H19ZM7 6V11H17V6H7ZM5 22H19V20H5V22Z"
        />
      </SvgIcon>
    );
  }
);

PrintIcon.displayName = 'PrintIcon';

export default PrintIcon;
