import React, {
  useEffect,
  useMemo,
  createContext,
  PropsWithChildren,
} from 'react';
import isEqual from 'lodash/isEqual';
import { useLocalStorage, usePrevious } from 'react-use';
import useMediaQuery from '@mui/material/useMediaQuery';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { useAuth } from '@dizzbo/core/hooks';
import { useUpdateUserSettings } from '@dizzbo/core/queries';
import { setlanguageHeader } from '@dizzbo/core/api/axios';

interface ProviderProps extends PropsWithChildren {}

// ----------------------------------------------------------------------

const initialState = {
  theme: {
    themeMode: 'light',
  },
};

const SettingsContext = createContext(initialState);

SettingsContext.displayName = 'SettingsContext';
// ----------------------------------------------------------------------

function SettingsProvider({ children }: ProviderProps) {
  const { i18n } = useTranslation();
  const { user } = useAuth();
  const { mutateUserSettings } = useUpdateUserSettings();
  const previousUser = usePrevious(user);

  // get setting from localStorage
  const [value, setValue] = useLocalStorage('settings', {
    theme: initialState.theme,
  });

  useEffect(() => {
    if (
      user &&
      user.frontendSettings &&
      !isEmpty(user.frontendSettings) &&
      !isEqual(value, user.frontendSettings) &&
      !isEqual(previousUser, user)
    ) {
      setValue(user.frontendSettings);
      if (user.frontendSettings.language) {
        i18n.changeLanguage(user.frontendSettings.language);
        setlanguageHeader(user.frontendSettings.language);
      } else {
        setlanguageHeader(i18n.language);
        mutateUserSettings({ language: i18n.language });
      }
    }
  }, [user]);

  // check if the browser color scheme changes
  const isDarkModeEnabled = useMediaQuery('(prefers-color-scheme: dark)');

  useEffect(() => {
    // setSetting('theme', { themeMode: isDarkModeEnabled ? 'dark' : 'light' });
  }, [isDarkModeEnabled]);

  useEffect(() => {
    // update the users language settings via the API and change the dayjs locale
    if (i18n.language && value?.language !== i18n.language) {
      dayjs.locale(i18n.language);
      setlanguageHeader(i18n.language);
      if (user) {
        mutateUserSettings({ language: i18n.language });
      }
    }
  }, [i18n.language]);

  const localStorage = useMemo(
    () => ({
      ...value,
    }),
    [value]
  );

  return (
    <SettingsContext.Provider value={localStorage}>
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsProvider, SettingsContext };
