import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import H from '@here/maps-api-for-javascript/bin/mapsjs.bundle.harp.js';
import { keyframes } from '@mui/system';

import { Box } from '@dizzbo/ui';
import { TruckIcon } from '@dizzbo/ui';

export const getVehicleMarker = (
  position: H.geo.IPoint,
  licensePlate: string
): H.map.DomMarker => {
  const vehicleMarkerPulseAnimation = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(137, 215, 210, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(137, 215, 210, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(137, 215, 210, 0);
  }
`;

  const html = renderToStaticMarkup(
    <div>
      <Box
        sx={{
          background: '#4A73C7',
          padding: 4,
          borderRadius: 4,
          display: 'flex',
          flexDirection: 'row',
          color: '#C2E6E3',
        }}
      >
        <TruckIcon
          sx={{
            width: 16,
            height: 16,
            color: '#C2E6E3',
            fill: '#C2E6E3',
            marginRight: 4,
          }}
        />
        <Box
          sx={{
            fontFamily:
              '"EuclidCircularARegular","Helvetica Neue","Helvetica","Arial",sans-serif',
            width: '100%',
            height: 16,
            backgroundColor: '#4A73C7',
            color: '#E5E9F0',
            borderRadius: 2,
            border: '1px solid #C2E6E3',
            paddingRight: 4,
            paddingLeft: 4,
            fontWeight: 400,
            fontSize: 10,
            lineHeight: 14,
          }}
        >
          {licensePlate}
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          left: '50%',
          top: 28,
          marginLeft: -12,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            borderRadius: '50%',
            left: 2,
            height: 20,
            width: 20,
            boxShadow: '0 0 0 0 rgba(137, 215, 210, 1)',
            animation: `${vehicleMarkerPulseAnimation} 2s infinite`,
          }}
        />
        <svg
          style={{ position: 'absolute', top: 0 }}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_2853_3742)">
            <circle cx="12" cy="8" r="8" fill="#4A73C7" />
            <circle cx="12" cy="8" r="7" stroke="white" strokeWidth="2" />
          </g>
          <defs>
            <filter
              id="filter0_d_2853_3742"
              x="0"
              y="0"
              width="24"
              height="24"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_2853_3742"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_2853_3742"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </Box>
      <svg
        style={{ position: 'absolute', left: '50%', top: 24, marginLeft: -8 }}
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16 0L8 12L0 0H16Z" fill="#4A73C7" />
      </svg>
    </div>
  );

  const marker = new H.map.DomMarker(
    { lat: position.lat, lng: position.lng },
    {
      icon: new H.map.DomIcon(html),
      volatility: true,
    }
  );

  return marker;
};
