const IconButton = (theme) => {
  return {
    MuiIconButton: {
      defaultProps: {
        size: 'large',
      },
      styleOverrides: {
        root: {
          marginRight: 'initial',
        },
        sizeLarge: {
          padding: 10,
          svg: {
            height: 20,
            width: 20,
          },
        },
        sizeMedium: {
          svg: {
            height: 16,
            width: 16,
          },
        },
      },
    },
  };
};

export default IconButton;

declare module '@mui/material/IconButton' {
  interface IconButtonPropsSizeOverrides {
    tiny: true;
    small: true;
    medium: true;
    large: true;
  }
}
