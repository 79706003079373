import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PinDownIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M18.5 19.5L0.499999 19.5L0.499999 17.5L18.5 17.5L18.5 19.5Z" />
        <path d="M9.5001 16.5L4.50021 11.7419L6.00239 10.3123L8.5 12.6892L8.5 0.5L10.5 0.5L10.5 12.6894L12.9978 10.3123L14.5 11.7419L9.5001 16.5Z" />
      </SvgIcon>
    );
  }
);

PinDownIcon.displayName = 'PinDownIcon';

export default PinDownIcon;
