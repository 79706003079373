/* eslint-disable camelcase */
import React, { useMemo } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import type { MRT_Column } from 'material-react-table';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';

import { Box, Tooltip, Button, Divider, Switch } from '@dizzbo/ui';
import { SettingsIcon, ResetIcon, ChevronDownIcon } from '@dizzbo/ui';

// TODO FIX TYPES
export const TableShowHideColumnsButton: React.FC<any> = ({ table }) => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' });

  const {
    getAllColumns,
    getAllLeafColumns,
    getCenterLeafColumns,
    getIsAllColumnsVisible,
    getIsSomeColumnsPinned,
    getIsSomeColumnsVisible,
    getLeftLeafColumns,
    getRightLeafColumns,
    getState,
    toggleAllColumnsVisible,
    options: { localization, enablePinning, enableColumnOrdering },
  } = table;

  const { columnOrder, columnPinning } = getState();

  const allColumns = useMemo(() => {
    const columns = getAllColumns();
    if (
      columnOrder.length > 0 &&
      !columns.some(
        (col: { columnDef: { columnDefType: string } }) =>
          col.columnDef.columnDefType === 'group'
      )
    ) {
      return [
        ...getLeftLeafColumns(),
        ...Array.from(new Set(columnOrder)).map((colId) =>
          getCenterLeafColumns().find((col) => col?.id === colId)
        ),
        ...getRightLeafColumns(),
      ].filter(Boolean);
    }
    return columns;
  }, [
    columnOrder,
    columnPinning,
    getAllColumns(),
    getCenterLeafColumns(),
    getLeftLeafColumns(),
    getRightLeafColumns(),
  ]) as MRT_Column<any>[];

  const visibleColumns = [];
  const hiddenColumns = [];

  for (let index = 0; index < allColumns.length; index++) {
    const column = allColumns[index];

    if (column.id !== 'statusIcon') {
      if (column.getIsVisible()) {
        visibleColumns.push(column);
      } else {
        hiddenColumns.push(column);
      }
    }
  }

  const renderMenuItem = (column) => {
    const handleToggleColumnHidden = (column: MRT_Column<any>) => {
      if (columnDefType === 'group') {
        column?.columns?.forEach?.((childColumn: MRT_Column<any>) => {
          childColumn.toggleVisibility(!switchChecked);
        });
      } else {
        column.toggleVisibility();
      }
    };
    const { columnDef } = column;
    const { columnDefType } = columnDef;
    const switchChecked =
      (columnDefType !== 'group' && column.getIsVisible()) ||
      (columnDefType === 'group' &&
        column.getLeafColumns().some((col) => col.getIsVisible()));

    return (
      <MenuItem key={column.id} sx={{ minWidth: 290 }}>
        <FormControlLabel
          componentsProps={
            {
              // typography: {
              //   sx: {
              //     mb: 0,
              //     opacity: columnDefType !== 'display' ? 1 : 0.5,
              //   },
              // },
            }
          }
          checked={switchChecked}
          control={
            <Tooltip
              arrow
              enterDelay={1000}
              enterNextDelay={1000}
              title={localization.toggleVisibility}
            >
              <Switch />
            </Tooltip>
          }
          // disabled={
          //   (isSubMenu && switchChecked) ||
          //   !column.getCanHide() ||
          //   column.getIsGrouped()
          // }
          label={columnDef.header}
          onChange={() => handleToggleColumnHidden(column)}
        />
      </MenuItem>
    );
  };

  return (
    <>
      <Button
        variant="tertiary"
        startIcon={<SettingsIcon />}
        endIcon={<ChevronDownIcon />}
        {...bindTrigger(popupState)}
      ></Button>

      <Menu {...bindMenu(popupState)}>
        {visibleColumns.map((column, index) => renderMenuItem(column))}
        <Divider />
        {hiddenColumns.map((column, index) => renderMenuItem(column))}
        {hiddenColumns.length > 0 && <Divider />}
        <Box px={1}>
          <Button
            variant="tertiary"
            startIcon={<ResetIcon />}
            disabled={getIsAllColumnsVisible()}
            onClick={() => toggleAllColumnsVisible(true)}
          >
            Reset
          </Button>
        </Box>
      </Menu>
    </>
  );
};
