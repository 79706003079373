import { useQuery, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
} from 'react';
import { useLocalStorage } from 'react-use';

import {
  ACCESS_TOKEN,
  axiosAuthenticatedRequest,
  getAuthUser,
  REFRESH_TOKEN,
  refreshToken as refreshAccessToken,
} from '../api';
import { QueryKeys } from '../queries';
import { UserType, UUIDType } from '../types';
import { isValidToken } from '../utils';

type AuthContextType = {
  isLoading: boolean;
  user: UserType | null;
  loginUser: (data: any) => void;
  logoutUser: () => void;
  switchUserDepartment: (departmentUUID: UUIDType) => void;
};

const AuthContext = createContext<AuthContextType>({
  isLoading: true,
  user: null,
  loginUser: (data) => {},
  logoutUser: () => {},
  switchUserDepartment: (departmentUUID: UUIDType) => {},
});

AuthContext.displayName = 'AuthContext';

function AuthProvider({ children }: PropsWithChildren) {
  const queryClient = useQueryClient();
  const [accessToken, setAccessToken, removeAccessToken] =
    useLocalStorage<string>(ACCESS_TOKEN, null);
  const [refreshToken, setRefreshToken, removeRefreshToken] =
    useLocalStorage<string>(REFRESH_TOKEN, null);

  const isValidAccessToken = !!accessToken && isValidToken(accessToken);
  const isValidRefreshToken = !!refreshToken && isValidToken(refreshToken);

  // check if we have already a valid JWT token. If so fetch the user data
  useEffect(() => {
    async function initAccessToken() {
      const newAccessToken = await refreshAccessToken();
      setAccessToken(newAccessToken);
    }
    if (!isValidAccessToken && isValidRefreshToken) initAccessToken();
  }, [isValidAccessToken, isValidRefreshToken]);

  // query for handling fetching the user data
  // if it fails logout the user (delete the token etc)
  // else on success set the user data in our state
  //
  // gets enabled initially if there is a valid accessToken
  // or via refetch on login

  const { data: user, isPending: isLoading } = useQuery({
    queryKey: [QueryKeys.AUTH, accessToken],
    queryFn: () => getAuthUser(),
    retry: 3,
    refetchOnWindowFocus: false,
  });

  const loginUser = (data: any) => {
    setAccessToken(data.access);
    setRefreshToken(data.refresh);
  };

  const switchUserDepartment = async (departmentUUID: UUIDType) => {
    try {
      const response = await axiosAuthenticatedRequest.post(
        `/auth/token/refresh/`,
        {
          department: departmentUUID,
          refresh: refreshToken,
        }
      );
      const headerAuthorization = `Bearer ${response.data.access}`;
      axiosAuthenticatedRequest.defaults.headers['Authorization'] =
        headerAuthorization;
      queryClient.invalidateQueries();
      setAccessToken(response.data.access);
      setRefreshToken(response.data.refresh);
      return true;
    } catch (error) {
      logoutUser();
      return false;
    }
  };

  const logoutUser = () => {
    axiosAuthenticatedRequest.defaults.headers['Authorization'] = '';
    removeAccessToken();
    removeRefreshToken();
  };

  const value = useMemo<AuthContextType>(
    () => ({
      isLoading,
      user,
      loginUser,
      logoutUser,
      switchUserDepartment,
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export { AuthContext, AuthProvider };
