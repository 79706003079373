import { styled } from '@mui/material/styles';
import React, { forwardRef, useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';

import { Box } from '../Box';
import { Button } from '../Button';
import { Divider } from '../Divider';
import { IconBullet } from '../IconBullet';
import { IconButton } from '../IconButton';
import { LinearProgress } from '../LinearProgress';
import { Stack } from '../Stack';
import { Typography } from '../Typography';

import {
  DocumentIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
  RemoveIcon,
} from '../icons';

const ContainerStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'orientation',
})<any>(({ theme, orientation }) => {
  return {
    border: `1px dashed ${theme.styles.documentUploadField.default.borderColor}`,
    borderRadius: 4,
    padding: orientation === 'vertical' ? theme.spacing(6) : theme.spacing(3),
  };
});

type Props = {
  orientation?: string;
  onChange: (file: any) => void;
  progress?: number;
  fileTypes?: string[]; // ['JPG', 'PNG', 'GIF', 'PDF'];
  title?: string;
};

export const DocumentUploadField = forwardRef<HTMLDivElement, Props>(
  (
    {
      orientation = 'vertical',
      onChange,
      progress,
      fileTypes,
      title = 'Select file',
    },
    ref
  ) => {
    const [file, setFile] = useState(null);

    useEffect(() => {
      if (file) {
        reader.readAsDataURL(file);
      }
    }, [file]);

    const reader = new FileReader();

    reader.onload = (e) => {
      onChange({ file: e.target.result, name: file.name });
    };

    const handleChange = (file) => {
      setFile(file);
    };

    // https://gist.github.com/virolea/e1af9359fe071f24de3da3500ff0f429
    // file upload progress

    return (
      <FileUploader
        handleChange={handleChange}
        name="file"
        types={fileTypes}
        fileOrFiles={file}
        disabled={file}
      >
        <ContainerStyles orientation={orientation} ref={ref}>
          {file && (
            <Stack spacing={3} direction="row" alignItems="center">
              <IconBullet size="xxlarge" variant="secondary">
                <DocumentIcon />
              </IconBullet>
              <Stack spacing={0} sx={{ width: '100%' }}>
                <Typography variant="buttonRegularSmall" color="primary">
                  {file.name}
                </Typography>
                {progress && (
                  <LinearProgress variant="determinate" value={progress} />
                )}
                <Typography variant="labelSmall" color="primary.light">
                  {file.size / 100}kb
                </Typography>
              </Stack>
              <IconButton
                color="warning"
                size="medium"
                variant="tertiary"
                disabled={!!progress}
                sx={{ marginLeft: 'auto' }}
                onClickCapture={(e) => {
                  e.stopPropagation();
                  setFile(null);
                }}
              >
                <RemoveIcon />
              </IconButton>
            </Stack>
          )}
          {!file && (
            <Stack
              direction={orientation === 'horizontal' ? 'row' : 'column'}
              spacing={6}
              divider={
                <Divider
                  orientation={
                    orientation === 'horizontal' ? 'vertical' : 'horizontal'
                  }
                  flexItem
                />
              }
              alignItems="center"
              justifyContent="space-evenly"
            >
              <Button size="medium" variant="primary">
                {title}
              </Button>
              <Stack spacing={6} direction="row">
                <DoubleArrowRightIcon sx={{ color: 'primary.light' }} />
                <Typography variant="bodyRegular" color="primary.light">
                  Or drag & drop
                </Typography>
                <DoubleArrowLeftIcon sx={{ color: 'primary.light' }} />
              </Stack>
            </Stack>
          )}
        </ContainerStyles>
      </FileUploader>
    );
  }
);

DocumentUploadField.displayName = 'DocumentUploadField';
