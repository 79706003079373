import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const WarningIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          d="M11.3869 2.35518C11.6646 1.89233 12.3354 1.89233 12.6131 2.35517L23.3503 20.2505C23.6362 20.727 23.2929 21.3333 22.7372 21.3333H1.26283C0.707061 21.3333 0.36378 20.727 0.649719 20.2505L11.3869 2.35518Z"
          fill="#E69101"
        />
        <path
          d="M11.3546 14.1516L11.0961 10.8676L11.0962 7.5H12.0464H12.9967L12.9966 10.8676L12.7381 14.1516H11.3546Z"
          fill="white"
        />
        <path
          d="M12.0464 18C12.3781 18 12.6564 17.8839 12.8811 17.6516C13.1165 17.4193 13.2342 17.1395 13.2342 16.8122C13.2342 16.4955 13.1165 16.221 12.8811 15.9887C12.6457 15.7459 12.3674 15.6244 12.0464 15.6244C11.7254 15.6244 11.4472 15.7459 11.2118 15.9887C10.9763 16.221 10.8586 16.4955 10.8586 16.8122C10.8586 17.1395 10.971 17.4193 11.1957 17.6516C11.4311 17.8839 11.7147 18 12.0464 18Z"
          fill="white"
        />
      </SvgIcon>
    );
  }
);

WarningIcon.displayName = 'WarningIcon';

export default WarningIcon;
