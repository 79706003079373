import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const FullscreenIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M6 18.4853H11.6569L9.53553 16.364L12.4853 13.4142L11.0711 12L8.12132 14.9497L6 12.8284L6 18.4853Z" />
        <path d="M12.8284 5.99999L18.4852 5.99999V11.6568L16.3639 9.53553L13.4142 12.4853L11.9999 11.0711L14.9497 8.12131L12.8284 5.99999Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 2H22V22H2V2ZM4 4V20H20V4H4Z"
        />
      </SvgIcon>
    );
  }
);

FullscreenIcon.displayName = 'FullscreenIcon';

export default FullscreenIcon;
