import { useQuery } from '@tanstack/react-query';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';

import { getOrderDocumentList } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Skeleton,
  Stack,
  UploadIcon,
} from '@dizzbo/ui';

import { useTranslation } from 'react-i18next';
import { useOrderDetail } from '../../../hooks';
import { DocumentItem } from './DocumentItem';
import { DocumentUploadDialog } from './DocumentUploadDialog';

type Props = {};

export const Documents: React.FC<Props> = () => {
  const { orderUUID } = useOrderDetail();

  const { isPending, data: orderDocumentsData } = useQuery({
    queryKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.ORDER_DOCUMENTS],
    queryFn: () => getOrderDocumentList(orderUUID),
  });

  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'documentUploadDialog',
  });

  const { t } = useTranslation();

  return (
    <Card
      variant="filled-secondary"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <CardHeader
        title="Documents"
        variant="small"
        // action={
        //   <Stack direction="row">
        //     <Box>
        //       <BasicSelect
        //         id="sort-by"
        //         label="Sort by:"
        //         value={1}
        //         onChange={() => {}}
        //       >
        //         <MenuItem key={1} value={1}>
        //           asdfa
        //         </MenuItem>
        //       </BasicSelect>
        //     </Box>
        //     <Box sx={{ marginLeft: 6 }}>
        //       <BasicSelect
        //         id="show"
        //         label="Show:"
        //         value={1}
        //         onChange={() => {}}
        //       >
        //         <MenuItem key={1} value={1}>
        //           all
        //         </MenuItem>
        //       </BasicSelect>
        //     </Box>
        //   </Stack>
        // }
      />
      <CardContent>
        <Stack spacing={3} divider={<Divider flexItem />}>
          {!isPending ? (
            orderDocumentsData.length > 0 ? (
              orderDocumentsData.map((document) => (
                <DocumentItem
                  documentData={document}
                  key={document.uuid}
                  orderUUID={orderUUID}
                />
              ))
            ) : (
              '-'
            )
          ) : (
            <>
              <Skeleton width="100%" height={56} />
              <Skeleton width="100%" height={56} />
              <Skeleton width="100%" height={56} />
            </>
          )}
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          startIcon={<UploadIcon />}
          size="medium"
          variant="tertiary"
          onClick={() => popupState.open()}
        >
          {t('uploadDocument')}
        </Button>
      </CardActions>
      <DocumentUploadDialog orderUUID={orderUUID} popupState={popupState} />
    </Card>
  );
};
