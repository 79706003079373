import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { motion, AnimatePresence } from 'framer-motion';
import { useDebounce } from 'react-use';
import { useIsMutating } from '@tanstack/react-query';

import { Box } from '@dizzbo/ui';

const NavItemWrapperStyles = styled(Box)(({ theme }) => ({
  paddingTop: 12,
  paddingBottom: 12,
  height: 64,
}));

const NavItemStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: 40,
  minHeight: 40,
  padding: 0,
  textAlign: 'left',
  justifyContent: 'start',
  paddingRight: 20,
  overflowX: 'hidden',
  ...theme.typography.buttonRegular,
  color: theme.palette.turquoise[100],
  textDecoration: 'none',
  backgroundColor: 'transparent',
  transition: theme.transitions.create('background'),

  '&.active': {
    color: theme.palette.green[100],
    backgroundColor: theme.palette.turquoise[200],
    borderRight: `3px solid ${theme.palette.green[100]}`,
  },
}));

const IconWrapperStyles = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginLeft: 20,
  marginRight: 20,
  height: 24,
  width: 24,
}));

type CloudIndicatorProps = {
  active: boolean;
};

const ContainerVariants = {
  hidden: {
    transition: {
      staggerChildren: 0.2,
    },
  },
  show: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const listItem = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
};

const dots = [1, 2, 3];

const CloudIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <motion.svg
      className='MuiSvgIcon-root'
      variants={ContainerVariants}
      initial='hidden'
      animate='show'
      width={24}
      height={24}
      fill='currentColor'
      viewBox='0 0 24 24'
      style={{ opacity: 0.5 }}
    >
      {dots.map((dot) => {
        let path =
          'M10 13C10 13.5523 9.55228 14 9 14C8.44772 14 8 13.5523 8 13C8 12.4477 8.44772 12 9 12C9.55228 12 10 12.4477 10 13Z';

        if (dot === 2) {
          path =
            'M12 14C12.5523 14 13 13.5523 13 13C13 12.4477 12.5523 12 12 12C11.4477 12 11 12.4477 11 13C11 13.5523 11.4477 14 12 14Z';
        }
        if (dot === 3) {
          path =
            'M15 14C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12C14.4477 12 14 12.4477 14 13C14 13.5523 14.4477 14 15 14Z';
        }

        return (
          <motion.path
            key={dot}
            variants={listItem}
            transition={{
              duration: 2,
              ease: 'easeInOut',
              repeat: Infinity,
              repeatDelay: 0.2,
            }}
            d={path}
          />
        );
      })}
      <motion.path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M13 2C10.2033 2 7.79174 3.63961 6.67031 6.00763C2.95739 6.17978 0 9.24452 0 13C0 16.866 3.13401 20 7 20H17C20.866 20 24 16.866 24 13C24 10.0246 22.1442 7.48487 19.5289 6.47107C18.5151 3.85575 15.9754 2 13 2ZM8.28425 7.33325C8.97112 5.38991 10.8245 4 13 4C15.2548 4 17.1634 5.49329 17.7859 7.54713L17.941 8.05895L18.4529 8.21408C20.5067 8.83659 22 10.7452 22 13C22 15.7614 19.7614 18 17 18H7C4.23858 18 2 15.7614 2 13C2 10.2386 4.23858 8 7 8H8.04859L8.28425 7.33325Z'
      />
    </motion.svg>
  );
};

const CloudIndicator: React.FC<CloudIndicatorProps> = ({ active }) => {
  return (
    <AnimatePresence>
      {active && (
        <motion.div
          key='cloud-active'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          style={{ width: 24, height: 24 }}
        >
          <motion.div
            // animate={{
            //   scale: [1, 1.2, 1],
            // }}
            transition={{
              duration: 3,
              ease: 'easeInOut',
              // times: [0, 0.2, 0.5, 0.8, 1],
              // repeat: Infinity,
              // repeatDelay: 0.2,
            }}
          >
            <CloudIcon />
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

type NetworkActivityIndicatorProps = {};

export const NetworkActivityIndicator = ({}: NetworkActivityIndicatorProps) => {
  const isMutating = useIsMutating();
  const [showIndicator, setShowIndicator] = React.useState(false);
  const [, cancel] = useDebounce(
    () => {
      console.log('isMutating', isMutating);
      setShowIndicator(false);
    },
    3000,
    [isMutating]
  );

  useEffect(() => {
    if (!!isMutating) {
      setShowIndicator(true);
    }
  }, [isMutating]);

  return (
    <NavItemWrapperStyles>
      <NavItemStyles>
        <IconWrapperStyles>
          <CloudIndicator active={Boolean(showIndicator)} />
        </IconWrapperStyles>
      </NavItemStyles>
    </NavItemWrapperStyles>
  );
};
