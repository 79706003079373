import { alpha } from '@mui/material/styles';

const table = (palette) => {
  return {
    table: {
      default: {
        backgroundColor: palette.oxford[100],
      },
    },
    tableRow: {
      default: {
        backgroundColor: palette.oxford[100],
      },
      hover: {
        backgroundColor: palette.oxford[80],
      },
    },
    tableCell: {
      default: {
        borderColor: alpha(palette.oxford[120], 0.8),
      },
    },
    tableHead: {
      default: {
        color: palette.oxford[20],
        backgroundColor: alpha(palette.oxford[90], 0.8),
      },
    },
    tablePagination: {
      default: {
        backgroundColor: palette.oxford[100],
      },
    },
    tableFilterBar: {
      default: {
        backgroundColor: palette.oxford[90],
        borderColor: alpha(palette.oxford[120], 0.8),
      },
    },
  };
};

export default table;
