import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SortAscIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M20 19H4V17H20V19Z" />
        <path d="M4 13H14V11H4V13Z" />
        <path d="M4 7H8V5H4V7Z" />
      </SvgIcon>
    );
  }
);

SortAscIcon.displayName = 'SortAscIcon';

export default SortAscIcon;
