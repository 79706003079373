import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, MultiLineTextfield } from '@dizzbo/ui';

const ContainerStyles = styled(Box)(({ theme }) => ({
  display: 'block',
  width: '100%',
}));

type Props = {
  mutation: {
    mutationFn: (values) => void;
    mutationKey: string[];
  };
};

export const MessageInput: React.FC<Props> = ({ mutation }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutationFn, mutationKey } = mutation;
  const [message, setMessage] = useState('');

  const { mutate, isError, error }: any = useMutation({
    mutationKey: mutationKey,
    mutationFn: (values) => mutationFn(values),
    onSuccess: () => {
      setMessage('');
      queryClient.invalidateQueries({
        queryKey: mutationKey,
      });
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('e', e.target.value);
    setMessage(e.target.value);
  };

  const handleClick = () => {
    mutate({ text: message });
  };

  return (
    <ContainerStyles>
      <MultiLineTextfield
        label=""
        name="message"
        value={message}
        characterLimit={400}
        onChange={handleChange}
      />

      <Button type="submit" onClick={handleClick}>
        {t('send')}
      </Button>
    </ContainerStyles>
  );
};
