import { useQuery } from '@tanstack/react-query';

import { getOrder } from '@core/api';
import { QueryKeys } from '@core/config';
import { UUIDType } from '@types';

export const useGetOrder = (orderUUID: UUIDType, options: object = {}) => {
  return useQuery({
    queryKey: [QueryKeys.ORDERS, orderUUID],
    queryFn: () => getOrder(orderUUID),
    enabled: !!orderUUID,
    ...options,
  });
};
