import { useQuery } from '@tanstack/react-query';

import { getOrderStops } from '@core/api';
import { QueryKeys } from '@core/config';
import { UUIDType } from '@types';

export const useGetOrderStops = (orderUUID: UUIDType) => {
  return useQuery({
    queryKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.STOPS],
    queryFn: () => getOrderStops(orderUUID),
  });
};
