import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SquareCheckedIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M6 6H16.8433L18.8487 4H4V20H20V11.0189L18 13.0135V18H6V6Z" />
        <path d="M22.9142 5.41421L12.2071 16.1213L8 11.9142L9.41421 10.5L12.2071 13.2929L21.5 4L22.9142 5.41421Z" />
      </SvgIcon>
    );
  }
);

SquareCheckedIcon.displayName = 'SquareCheckedIcon';

export default SquareCheckedIcon;
