import { usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  AssignIcon,
  Box,
  Button,
  Cell,
  CheckIcon,
  ChevronRightIcon,
  DateIcon,
  DocumentIcon,
  IconBullet,
  IconButton,
  LoadingIcon,
  SquareIcon,
  Stack,
  TableInstance,
  Tooltip,
  TruckIcon,
  UnloadingIcon,
  UploadIcon,
  ViewIcon,
} from '@dizzbo/ui';
import { OrderType } from '@types';
import {
  LoadingTimeDialog,
  OrderConfirmationDialog,
  OrderReviewDialog,
  PoDReviewDialog,
  PoDUploadDialog,
  TransportContractDialog,
  UnloadingTimeDialog,
} from '../../Todos';
import { OrderActionsButton } from './OrderActionsButton';

type Props = {
  cell: Cell<OrderType>;
  table: TableInstance<OrderType>;
};

export const TodosCell: React.FC<Props> = ({
  cell: {
    row: { original: orderData },
  },
}: Props) => {
  const { uuid: orderUUID, todos = [], tour, status } = orderData;
  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'todoDialog',
  });

  // Dirty Fix if we have more than one todo. For now, we only have one or two todos at a time
  const secondPopupState = usePopupState({
    variant: 'dialog',
    popupId: 'secondTodoDialog',
  });

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ width: '100%' }}
      >
        {todos.map((todo: string) => {
          if (todo === 'order_confirmation_todo')
            return (
              <React.Fragment key={todo}>
                <Tooltip title="Confirm Order" key={todo}>
                  <Button
                    variant="primary"
                    size="medium"
                    startIcon={<SquareIcon />}
                    endIcon={<DocumentIcon />}
                    onClick={() => popupState.open()}
                  />
                </Tooltip>
                <OrderConfirmationDialog
                  orderData={orderData}
                  popupState={popupState}
                  todo={todo}
                />
              </React.Fragment>
            );
          if (todo === 'transport_contract_todo')
            return (
              <React.Fragment key={todo}>
                <Tooltip title="Send Transport Contract" key={todo}>
                  <Button
                    variant="primary"
                    size="medium"
                    startIcon={<AssignIcon />}
                    endIcon={<TruckIcon />}
                    onClick={() => popupState.open()}
                  />
                </Tooltip>
                <TransportContractDialog
                  orderData={orderData}
                  tourUUID={tour?.uuid}
                  popupState={popupState}
                />
              </React.Fragment>
            );
          if (todo === 'loading_time_todo')
            return (
              <React.Fragment key={todo}>
                <Tooltip title="Set loading time" key={todo}>
                  <Button
                    variant="primary"
                    size="medium"
                    startIcon={<DateIcon />}
                    endIcon={<LoadingIcon />}
                    onClick={() => popupState.open()}
                  />
                </Tooltip>
                <LoadingTimeDialog
                  orderData={orderData}
                  popupState={popupState}
                />
              </React.Fragment>
            );
          if (todo === 'unloading_time_todo')
            return (
              <React.Fragment key={todo}>
                <Tooltip title="Set unloading time" key={todo}>
                  <Button
                    variant="primary"
                    size="medium"
                    startIcon={<DateIcon />}
                    endIcon={<UnloadingIcon />}
                    onClick={() => secondPopupState.open()}
                  />
                </Tooltip>
                <UnloadingTimeDialog
                  orderData={orderData}
                  popupState={secondPopupState}
                />
              </React.Fragment>
            );
          if (todo === 'pod_upload_todo')
            return (
              <React.Fragment key={todo}>
                <Tooltip title="Upload POD" key={todo}>
                  <Button
                    variant="primary"
                    size="medium"
                    startIcon={<UploadIcon />}
                    onClick={() => popupState.open()}
                  >
                    POD
                  </Button>
                </Tooltip>
                <PoDUploadDialog
                  orderData={orderData}
                  popupState={popupState}
                />
              </React.Fragment>
            );
          if (todo === 'pod_review_todo')
            return (
              <React.Fragment key={todo}>
                <Tooltip title="Review POD" key={todo}>
                  <Button
                    variant="primary"
                    size="medium"
                    startIcon={<ViewIcon />}
                    endIcon={<DocumentIcon />}
                    onClick={() => popupState.open()}
                  >
                    POD
                  </Button>
                </Tooltip>

                <PoDReviewDialog
                  orderData={orderData}
                  popupState={popupState}
                  todo={todo}
                />
              </React.Fragment>
            );
          if (todo === 'order_review_todo')
            return (
              <React.Fragment key={todo}>
                <Tooltip title="Review Order" key={todo}>
                  <Button
                    variant="primary"
                    size="medium"
                    startIcon={<ViewIcon />}
                    endIcon={<DocumentIcon />}
                    onClick={() => popupState.open()}
                  />
                </Tooltip>
                <OrderReviewDialog
                  orderData={orderData}
                  popupState={popupState}
                />
              </React.Fragment>
            );
        })}
        {status === 'REVIEWED' && (
          <Tooltip title="Order Reviewed">
            <Box>
              <IconBullet variant="success" size="medium">
                <CheckIcon />
              </IconBullet>
            </Box>
          </Tooltip>
        )}
      </Stack>
      <OrderActionsButton orderUUID={orderUUID} />
      <IconButton
        variant="secondary"
        size="medium"
        component={RouterLink}
        to={`/orders/${orderUUID}`}
      >
        <ChevronRightIcon />
      </IconButton>
    </Stack>
  );
};
