import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CustomerIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4244 12.3739C15.04 12.5695 15.6232 12.8375 16.1642 13.1679L13.8915 14.3042C13.2969 14.1069 12.6609 14 12 14C8.68629 14 6 16.6863 6 20V21H4V20C4 16.4269 6.34252 13.4009 9.5756 12.3739C8.03951 11.5207 7 9.88174 7 8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8C17 9.88174 15.9605 11.5207 14.4244 12.3739ZM9 8C9 6.34315 10.3431 5 12 5C13.6569 5 15 6.34315 15 8C15 9.65685 13.6569 11 12 11C10.3431 11 9 9.65685 9 8Z"
        />
        <path d="M12 21.8528V16.9747L17.1562 19.122V24L12 21.8528Z" />
        <path d="M14.6199 17.3514L12.4002 16.4271L17.5 14L19.5219 14.9623L14.5963 17.3065L14.6199 17.3514Z" />
        <path d="M15.3768 17.6667L20.2906 15.3281L22.5998 16.4271L17.5 18.5508L15.3768 17.6667Z" />
        <path d="M17.8438 19.122L23 16.9747V21.8528L17.8438 24V19.122Z" />
      </SvgIcon>
    );
  }
);

CustomerIcon.displayName = 'CustomerIcon';

export default CustomerIcon;
