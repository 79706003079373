import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { updateOrder } from '@core/api';
import { AutoSave } from '@core/components';
import { QueryKeys } from '@core/config';
import { useFieldErrors } from '@dizzbo/core/hooks';
import { MultiLineTextfield, Stack, TextField } from '@dizzbo/ui';
import { OrderType, UUIDType } from '@types';

type Props = {
  orderUUID: UUIDType;
  invoiceReference?: string;
  invoiceNotes?: string;
  hasCustomer: boolean;
};

const schema = yup.object().shape({
  invoiceReference: yup.string().max(20),
  invoiceNotes: yup.string(),
});

export const ReferenceNumbersForm: React.FC<Props> = ({
  orderUUID,
  invoiceReference,
  invoiceNotes,
  hasCustomer,
}: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    mutate: mutateOrder,
    isError,
    error,
  } = useMutation<OrderType, unknown, Partial<OrderType>>({
    mutationKey: [QueryKeys.ORDERS, orderUUID],
    mutationFn: (values) =>
      updateOrder({ orderUUID: orderUUID, orderData: values }),
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    values: {
      invoiceReference: invoiceReference,
      invoiceNotes: invoiceNotes,
    },
    resolver: yupResolver(schema),
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
  });

  const { hasFieldError, fieldError } = useFieldErrors(isError, errors, error);

  const onSubmit = (formData: any) => {
    mutateOrder(formData, {
      onSuccess: (data, values) => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ORDERS, orderUUID],
        });
      },
    });
  };

  return (
    <>
      <Stack spacing={6}>
        <Controller
          name="invoiceReference"
          control={control}
          defaultValue=""
          rules={{ required: false }}
          render={({ field, fieldState }) => (
            <TextField
              label={t('invoiceReferenceNumber')}
              error={hasFieldError(field)}
              helperText={fieldError(field)}
              {...field}
              disabled={!hasCustomer}
            />
          )}
        />
        <Controller
          name="invoiceNotes"
          control={control}
          defaultValue=""
          rules={{ required: false }}
          render={({ field }) => (
            <MultiLineTextfield
              label={t('additionalInvoiceInformation')}
              characterLimit={1000}
              {...field}
              disabled={!hasCustomer}
            />
          )}
        />
      </Stack>
      <AutoSave
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        isValid={isValid}
        control={control}
      />
    </>
  );
};
