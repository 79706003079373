import { DialogActions as MuiDialogActions } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiDialogActionsProps = ComponentProps<typeof MuiDialogActions>;

export const DialogActions = forwardRef<HTMLDivElement, MuiDialogActionsProps>(
  (props, ref) => <MuiDialogActions ref={ref} {...props} />
);

DialogActions.displayName = 'DialogActions';
