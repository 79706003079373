import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ErrorIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <ellipse cx="12" cy="12" rx="10.5" ry="10.5" fill="#CF5759" />
        <path
          d="M7.99993 17L7 16L11 12L7 8L8 7L12 11L16 7L17 8L13 12L17 16L16.0001 17L12 13L7.99993 17Z"
          fill="white"
        />
      </SvgIcon>
    );
  }
);

ErrorIcon.displayName = 'ErrorIcon';

export default ErrorIcon;
