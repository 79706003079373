import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PersonsIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1C9.79086 1 8 2.79086 8 5C8 6.4336 8.75418 7.69106 9.88743 8.39726C9.47651 8.55623 9.0708 8.76658 8.68471 9.03477C7.97491 8.39173 7.0332 8 6 8C3.79086 8 2 9.79086 2 12C2 13.0733 2.4227 14.0478 3.11069 14.7662C2.72465 14.9864 2.35207 15.2588 2.00595 15.5891C0.793038 16.7469 0 18.5261 0 21H2C2 18.9739 2.63553 17.7531 3.3869 17.0359C4.15672 16.301 5.14692 16 6 16C7.58843 16 10 17.4251 10 21H12H14C14 18.9739 14.6355 17.7531 15.3869 17.0359C16.1567 16.301 17.1469 16 18 16C19.5884 16 22 17.4251 22 21H24C24 17.9298 22.5757 15.8732 20.8338 14.823C21.5545 14.0996 22 13.1018 22 12C22 9.79086 20.2091 8 18 8C16.934 8 15.9653 8.41702 15.2483 9.09683C14.8684 8.82326 14.4677 8.601 14.0593 8.42989C15.2221 7.73023 16 6.45596 16 5C16 2.79086 14.2091 1 12 1ZM10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5C14 6.10457 13.1046 7 12 7C10.8954 7 10 6.10457 10 5ZM14.1839 10.7974C14.0644 11.177 14 11.581 14 12C14 13.0733 14.4227 14.0478 15.1107 14.7662C14.7247 14.9864 14.3521 15.2588 14.006 15.5891C12.793 16.7469 12 18.5261 12 21C12 17.9298 10.5757 15.8732 8.83384 14.823C9.55452 14.0996 10 13.1018 10 12C10 11.5471 9.92473 11.1118 9.78602 10.7059C10.4812 10.2095 11.2876 10 12 10C12.6655 10 13.4705 10.2487 14.1839 10.7974ZM4 12C4 10.8954 4.89543 10 6 10C7.10457 10 8 10.8954 8 12C8 13.1046 7.10457 14 6 14C4.89543 14 4 13.1046 4 12ZM20 12C20 13.1046 19.1046 14 18 14C16.8954 14 16 13.1046 16 12C16 10.8954 16.8954 10 18 10C19.1046 10 20 10.8954 20 12Z"
        />
      </SvgIcon>
    );
  }
);

PersonsIcon.displayName = 'PersonsIcon';

export default PersonsIcon;
