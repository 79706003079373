import { shadows } from '../shadows';

const appBar = (palette) => {
  return {
    appBarWrapper: {
      boxShadow: shadows[4],
    },
    appBar: {
      backgroundColor: palette.oxford[80],
    },
  };
};

export default appBar;
