import { axiosAuthenticatedRequest } from '@dizzbo/core/api';
import {
  CarrierContactType,
  ISODateType,
  OrderType,
  PaginatedListType,
  ParamsWithPagination,
  TourComment,
  TourType,
  TransportContractType,
  UUIDType,
} from '@types';

export const getTours = async (
  params: ParamsWithPagination<{
    hasRoute?: boolean;
    hasVehicle?: boolean;
    scheduledAfter?: ISODateType;
    scheduledBefore?: ISODateType;
    search?: string;
  }>
) => {
  const res = await axiosAuthenticatedRequest.get<PaginatedListType<TourType>>(
    '/v2/tours/',
    {
      params: params,
    }
  );
  return res.data;
};

export const getTour = async (tourUUID: string): Promise<TourType> => {
  const res = await axiosAuthenticatedRequest.get(`/v2/tours/${tourUUID}/`);
  return res.data;
};

export const updateTour = async (data: any): Promise<any> => {
  const { tourUUID, tourData } = data;
  const res = await axiosAuthenticatedRequest.patch(
    `/v2/tours/${tourUUID}/`,
    tourData
  );
  return res.data;
};

export const createTransportContract = async (tourUUID: UUIDType) => {
  const res = await axiosAuthenticatedRequest.post<OrderType>(
    `/v2/tours/${tourUUID}/contracts/`
  );
  return res.data;
};

export const getTransportContract = async (
  tourUUID: UUIDType,
  transportContractUUID: UUIDType
): Promise<TransportContractType> => {
  const res = await axiosAuthenticatedRequest.get(
    `/v2/tours/${tourUUID}/contracts/${transportContractUUID}/`
  );
  return res.data;
};

export const getTransportContractPreview = async (tourUUID: UUIDType) => {
  const res = await axiosAuthenticatedRequest.get(
    `/v2/tours/${tourUUID}/contracts/pdf-preview/`
  );
  return res.data;
};

export const getTransportContracts = async (
  tourUUID: UUIDType
): Promise<TransportContractType[]> => {
  const res = await axiosAuthenticatedRequest.get(
    `/v2/tours/${tourUUID}/contracts/`
  );
  return res.data;
};

export const sendTransportContract = async (
  tourUUID: UUIDType,
  transportContractUUID: UUIDType
) => {
  const res = await axiosAuthenticatedRequest.post(
    `/v2/tours/${tourUUID}/contracts/${transportContractUUID}/send/`
  );
  return res.data;
};

export const recreateTransportContract = async (
  tourUUID: UUIDType,
  transportContractUUID: UUIDType
) => {
  const res = await axiosAuthenticatedRequest.post(
    `/v2/tours/${tourUUID}/contracts/${transportContractUUID}/recreate/`
  );
  return res.data;
};

/* Tour Contacts -----------------------------------------------------------*/

export const getTourContacts = async (
  tourUUID: UUIDType
): Promise<CarrierContactType[]> => {
  const res = await axiosAuthenticatedRequest.get(
    `/v2/tours/${tourUUID}/contacts/`
  );
  return res.data;
};

export const createTourContact = async (tourUUID: UUIDType, data) => {
  const res = await axiosAuthenticatedRequest.post(
    `/v2/tours/${tourUUID}/contacts/`,
    data
  );
  return res.data;
};

export const deleteTourContact = async (
  tourUUID: UUIDType,
  contactUUID: UUIDType
) => {
  const res = await axiosAuthenticatedRequest.delete<void>(
    `/v2/tours/${tourUUID}/contacts/${contactUUID}`
  );
  return res.data;
};

/* Tour Comments --------------------------------------------------------*/

export const getTourComments = async (tourUUID: UUIDType, params?: object) => {
  const res = await axiosAuthenticatedRequest.get<
    PaginatedListType<TourComment>
  >(`/v2/tours/${tourUUID}/comments/`, {
    params: params,
    paramsSerializer: {
      indexes: null, // by default: false
    },
  });
  return res.data;
};

export const createTourComment = async (tourUUID: UUIDType, data) => {
  const res = await axiosAuthenticatedRequest.post(
    `/v2/tours/${tourUUID}/comments/`,
    data
  );
  return res.data;
};
