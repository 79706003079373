import { Tabs as MuiTabs } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiTabsProps = ComponentProps<typeof MuiTabs>;

export const Tabs = forwardRef<HTMLDivElement, MuiTabsProps>(
  ({ children, ...rest }, ref) => (
    <MuiTabs ref={ref} {...rest}>
      {children}
    </MuiTabs>
  )
);

Tabs.displayName = 'Tabs';
