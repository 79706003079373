import { styled } from '@mui/material/styles';
import { useIsMutating } from '@tanstack/react-query';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React, { ReactElement, useState } from 'react';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingScreen,
  Paper,
} from '@dizzbo/ui';

import { RouteSettings, RoutingMap } from '@core/components/RoutingMap';
import { QueryKeys } from '@core/config';
import { useGetRoute } from '@core/hooks';

import { PricingBar } from '@planner/components/PricingBar';
import { useTranslation } from 'react-i18next';
import { useOrderDetail } from '../../hooks';

const DialogContentStyles = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: 0,
}));

const SideBarStyles = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  zIndex: 2,
  minWidth: 300,
  height: '60vh',
  overflowY: 'auto',
  backgroundColor:
    theme.styles.paper.filledTurquoiseLight.default.backgroundColor,
}));

const DialogActionsStyles = styled(DialogActions)(({ theme }) => ({
  padding: 0,
}));

const MapWrapperStyles = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'block',
  height: '60vh',
}));

interface RouteSettingsDialogProps {
  plannerData?: any;
  popupState: PopupState;
}

export const MapDialog: React.FC<RouteSettingsDialogProps> = ({
  popupState,
}): ReactElement => {
  const [totalPrice, setTotalPrice] = useState(0);
  const { routeUUID, orderData } = useOrderDetail();
  const { data } = useGetRoute(routeUUID);

  const isMutatingRoute = useIsMutating({
    mutationKey: [QueryKeys.ROUTES, routeUUID],
  });

  const { t } = useTranslation();

  return (
    <Dialog
      PaperProps={{
        elevation: 6,
        variant: 'filled-primary',
      }}
      {...bindDialog(popupState)}
      fullWidth={true}
      sx={{
        '.MuiDialog-paper': {
          minWidth: '90%',
        },
      }}
    >
      {!!isMutatingRoute && (
        <LoadingScreen
          sx={{
            position: 'absolute',
            background: 'rgba(255,255,255, 0.3)',
          }}
        />
      )}
      <DialogTitle onClose={popupState.close}>{t('routeSettings')}</DialogTitle>
      <DialogContentStyles dividers>
        <SideBarStyles square elevation={3}>
          <RouteSettings route={data} />
        </SideBarStyles>
        <MapWrapperStyles>
          <RoutingMap route={data} />
        </MapWrapperStyles>
      </DialogContentStyles>
      <DialogActionsStyles>
        <PricingBar
          setTotalPrice={setTotalPrice}
          totalPrice={totalPrice}
          orderData={orderData}
        />
      </DialogActionsStyles>
    </Dialog>
  );
};
