import * as React from 'react';
import { createRoot } from 'react-dom/client';
import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
} from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';
import 'dayjs/locale/de';
import * as Sentry from '@sentry/react';
import { I18nextProvider } from 'react-i18next';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

import { ThemeProvider } from '@dizzbo/ui';
import { AuthProvider } from '@dizzbo/core/contexts';
import { SettingsProvider } from '@dizzbo/core/contexts';
import i18n from '@dizzbo/core/i18n/i18n';
import { LoadingOverlay } from '@dizzbo/core/components';

import { SENTRY_DSN } from '@core/config';

import App from './app';

import '../../assets/scss/global.scss';

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/dizzbo\.com\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const container = document.getElementById('app');
const root = createRoot(container!);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      // 🎉 only show error toasts if we already have data in the cache
      // which indicates a failed background update
      if (query.state.data !== undefined) {
      }
    },
  }),
});

root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    {/* <LoadingOverlay /> */}
    <I18nextProvider i18n={i18n} defaultNS={'translation'}>
      <AuthProvider>
        <SettingsProvider>
          <ThemeProvider>
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </ThemeProvider>
        </SettingsProvider>
      </AuthProvider>
    </I18nextProvider>
  </QueryClientProvider>
  // </React.StrictMode>
);
