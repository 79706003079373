import React, { useRef } from 'react';
import { SxProps, styled } from '@mui/material/styles';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useVirtualizer } from '@tanstack/react-virtual';

import { Box } from '@dizzbo/ui';
import { getTours } from '@core/api/tours';
import { QueryKeys } from '@core/config';

import { ListItem } from './ListItem';

const PAGE_SIZE = 20;

const ListWrapperStyles = styled(Box)(({ theme }) => ({
  overflow: 'auto',
  height: '100%',
}));

const NoResultsWrapper = styled(Box)(() => ({
  margin: '0 1.5rem',
}));

type Props = {
  searchValue?: string;
  sx?: SxProps;
};

export const List: React.FC<Props> = ({ searchValue }) => {
  const parentRef = useRef();
  const { data, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: [QueryKeys.TOURS, searchValue],
      queryFn: ({ pageParam, queryKey }) =>
        getTours({ limit: PAGE_SIZE, offset: pageParam, search: queryKey[1] }),
      initialPageParam: 0,
      getNextPageParam: (_lastGroup, groups) =>
        _lastGroup.next ? groups.length * PAGE_SIZE : null,
    });

  const allTours = data ? data.pages.flatMap((d) => d.results) : [];
  console.log('allTours', allTours);
  console.log('hasNextPage', hasNextPage);
  console.log('isFetchingNextPage', isFetchingNextPage);

  console.log('parentRef', parentRef.current);

  const virtualizer = useVirtualizer({
    count: hasNextPage ? allTours.length + 1 : allTours.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 127,
    overscan: 5,
  });

  console.log('virtualizer', virtualizer.getVirtualItems());
  React.useEffect(() => {
    const [lastItem] = [...virtualizer.getVirtualItems()].reverse();

    if (!lastItem) {
      return;
    }

    if (
      lastItem.index >= allTours.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  }, [
    hasNextPage,
    fetchNextPage,
    allTours.length,
    isFetchingNextPage,
    virtualizer.getVirtualItems(),
  ]);

  return (
    <ListWrapperStyles ref={parentRef}>
      {virtualizer.getVirtualItems().length === 0 && !isFetchingNextPage ? (
        <NoResultsWrapper>No Results</NoResultsWrapper>
      ) : (
        <div
          style={{
            height: virtualizer.getTotalSize(),
            width: '100%',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              transform: `translateY(${
                virtualizer.getVirtualItems()[0]?.start ?? 0
              }px)`,
            }}
          >
            {virtualizer.getVirtualItems().map((virtualRow) => {
              const tour = allTours[virtualRow.index];

              return (
                <div
                  key={virtualRow.key}
                  data-index={virtualRow.index}
                  ref={virtualizer.measureElement}
                >
                  {tour && <ListItem tour={tour} />}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </ListWrapperStyles>
  );
};
