import { getOrder } from '@core/api';
import { QueryKeys } from '@core/config';
import { useQueries } from '@tanstack/react-query';
import { TourOrderType } from '@types';

export const useGetTourOrders = (orders: TourOrderType[]) => {
  orders = orders || [];
  return useQueries({
    queries: orders.map((order) => {
      return {
        queryKey: [QueryKeys.ORDERS, order.uuid],
        queryFn: () => getOrder(order.uuid),
      };
    }),
  });
};
