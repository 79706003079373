import { alpha } from '@mui/material/styles';

const chip = (palette) => {
  return {
    chip: {
      'turquoise-60': {
        default: {
          color: palette.oxford[100],
          backgroundColor: palette.turquoise[60],
        },
      },
      'turquoise-175': {
        default: {
          color: palette.turquoise[100],
          backgroundColor: palette.turquoise[175],
        },
      },
      'outline-oxford-40': {
        default: {
          color: palette.oxford[40],
          borderColor: palette.oxford[40],
          backgroundColor: 'transparent',
        },
      },
      'outline-red': {
        default: {
          color: palette.red[100],
          borderColor: palette.red[100],
          backgroundColor: 'transparent',
        },
      },
      'oxford-40': {
        default: {
          color: palette.green[80],
          backgroundColor: palette.oxford[40],
        },
      },
      'green-100': {
        default: {
          color: palette.oxford[40],
          backgroundColor: palette.green[100],
        },
      },
      'green-80': {
        default: {
          color: palette.oxford[40],
          backgroundColor: palette.green[80],
        },
      },
      'turquoise-100': {
        default: {
          color: palette.oxford[40],
          backgroundColor: palette.turquoise[100],
        },
      },
      forest: {
        default: {
          color: palette.common.white,
          backgroundColor: palette.forest[100],
        },
      },
      blue: {
        default: {
          color: palette.common.white,
          backgroundColor: palette.blue[100],
        },
      },
      orange: {
        default: {
          color: palette.common.white,
          backgroundColor: palette.orange[100],
        },
      },
      red: {
        default: {
          color: palette.common.white,
          backgroundColor: palette.red[100],
        },
      },
      'oxford-25': {
        default: {
          color: palette.common.white,
          backgroundColor: palette.oxford[25],
        },
      },
      disabled: {
        default: {
          color: palette.oxford[25],
          borderColor: alpha(palette.oxford[25], 0.25),
          backgroundColor: 'transparent',
        },
      },
      'turquoise-150': {
        default: {
          color: palette.common.white,
          borderColor: palette.turquoise[150],
          backgroundColor: palette.turquoise[150],
        },
      },
    },
  };
};

export default chip;
