const DataGrid = (theme) => {
  return {
    MuiDataGrid: {
      defaultProps: {
        sx: {},
      },
      styleOverrides: {
        root: {
          '--unstable_DataGrid-radius': 0,
          '& .MuiDataGrid-withBorderColor': {
            borderColor: `${theme.styles.tableCell.default.borderColor}`,
          },
          color: theme.styles.tableCell.default.color,
        },
        columnHeaders: {
          fontSize: 15,
          color: theme.styles.tableHead.default.color,
          paddingBottom: 27,
          paddingTop: 26,
          backgroundColor: theme.styles.tableHead.default.backgroundColor,
          // backgroundColor: alpha(theme.palette.turquoise[10], 0.8),
          backdropFilter: 'blur(10px)',
        },
        cell: {
          color: theme.styles.tableCell.default.color,
        },
      },
    },
  };
};

export default DataGrid;
