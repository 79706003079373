import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CO2Icon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M12.2527 13.94C12.2527 12.7 13.2227 11.67 14.4627 11.67C15.7027 11.67 16.6727 12.7 16.6727 13.94C16.6727 15.18 15.7027 16.21 14.4627 16.21C13.2227 16.21 12.2527 15.18 12.2527 13.94Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.11899 7.25562C5.69956 4.26104 8.33574 2 11.5 2C13.8284 2 15.8709 3.22429 17.0188 5.06426C20.956 5.56469 24 8.92686 24 13C24 17.4183 20.4183 21 16 21H11.2422C10.8662 21.5617 10.3671 22.0341 9.78343 22.3785C9.91901 22.5491 10 22.7651 10 23C10 23.5523 9.55229 24 9 24C8.44771 24 8 23.5523 8 23C8 22.9908 8.00012 22.9816 8.00037 22.9725C7.83608 22.9907 7.66913 23 7.5 23C5.50069 23 3.80591 21.6962 3.21956 19.8924C1.28288 18.6473 0 16.4735 0 14C0 10.7856 2.16654 8.07731 5.11899 7.25562ZM20.1389 12.43H21.7489V13.39H18.7489V13.07C18.7489 12.1392 19.3854 11.7123 20.0017 11.2988C20.0409 11.2726 20.0801 11.2463 20.1189 11.22C20.4389 11.01 20.7489 10.71 20.7489 10.4C20.7489 10.16 20.5889 9.96 20.2889 9.96C19.9489 9.96 19.7389 10.21 19.7489 10.46H18.7689C18.7689 9.61 19.4389 9 20.3189 9C21.0489 9 21.7289 9.46 21.7289 10.36C21.7289 11.16 21.3389 11.57 20.8589 11.89C20.829 11.9094 20.791 11.9328 20.748 11.9592C20.5471 12.083 20.2378 12.2735 20.1389 12.43ZM3 13.94C3 16 4.57 17.61 6.72 17.61C8.33 17.61 9.77 16.58 10.16 15.07H8.59C8.29 15.78 7.52 16.21 6.72 16.21C5.36 16.21 4.46 15.21 4.46 13.94C4.46 12.67 5.36 11.67 6.72 11.67C7.52 11.67 8.29 12.1 8.59 12.81H10.16C9.77 11.3 8.33 10.27 6.72 10.27C4.57 10.27 3 11.88 3 13.94ZM18.1327 13.94C18.1327 11.91 16.4927 10.27 14.4627 10.27C12.4327 10.27 10.7927 11.91 10.7927 13.94C10.7927 15.97 12.4327 17.61 14.4627 17.61C16.4927 17.61 18.1327 15.97 18.1327 13.94Z"
        />
      </SvgIcon>
    );
  }
);

CO2Icon.displayName = 'CO2Icon';

export default CO2Icon;
