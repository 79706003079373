import { darken, lighten } from '@mui/material/styles';

const Button = (theme) => {
  return {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {},
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        size: 'large',
        variant: 'primary',
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          return {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textTransform: 'none',
            color: theme.styles.button.primary.default.color,
            fontWeight: 500,
            borderRadius: 30,
            '&.MuiButton-sizeMedium': {
              paddingTop: 4,
              paddingBottom: 4,
            },
            ...(!ownerState.children && {
              '&.MuiButton-sizeMedium': {
                paddingTop: 8,
                paddingBottom: 8,
                '.MuiButton-startIcon': {
                  marginRight: 0,
                },
              },
              '&.MuiButton-sizeLarge': {
                paddingTop: 8,
                paddingBottom: 8,
                '.MuiButton-startIcon': {
                  marginRight: 0,
                },
              },
            }),
          };
        },
        sizeLarge: {
          fontSize: 16,
          paddingTop: 6,
          paddingRight: 16,
          paddingBottom: 6,
          paddingLeft: 16,
          '.MuiButton-endIcon, .MuiButton-startIcon': {
            svg: {
              height: 20,
              width: 20,
            },
          },
          '.MuiButton-startIcon': {
            marginLeft: 0,
            'svg + svg': {
              marginLeft: 10,
            },
            'div + svg': {
              marginLeft: 10,
            },
          },
          '.MuiButton-endIcon': {
            'svg + svg': {
              marginLeft: 10,
            },
            'div + svg': {
              marginLeft: 10,
            },
          },
        },
        sizeMedium: {
          fontSize: 14,
          paddingRight: 12,
          paddingLeft: 12,
          '.MuiButton-endIcon, .MuiButton-startIcon': {
            svg: {
              height: 16,
              width: 16,
            },
          },
          '.MuiButton-startIcon': {
            marginLeft: 0,
            'svg + svg': {
              marginLeft: 10,
            },
            'div + svg': {
              marginLeft: 10,
            },
          },
          '.MuiButton-endIcon': {
            'svg + svg': {
              marginLeft: 10,
            },
            'div + svg': {
              marginLeft: 10,
            },
            // mui is for somereason overriding the font size here
            // need to hardcode the chip font size here
            '>*:nth-of-type(1)': {
              fontSize: 10,
            },
          },
        },
        sizeSmall: {
          ...theme.typography.bodyRegularSmall,
          paddingRight: 12,
          paddingLeft: 12,
          '.MuiButton-endIcon, .MuiButton-startIcon': {
            svg: {
              height: 16,
              width: 16,
            },
          },
          '.MuiButton-startIcon': {
            marginLeft: 0,
            'svg + svg': {
              marginLeft: 6,
            },
            'div + svg': {
              marginLeft: 6,
            },
          },
          '.MuiButton-endIcon': {
            'svg + svg': {
              marginLeft: 6,
            },
            'div + svg': {
              marginLeft: 6,
            },
            // mui is for somereason overriding the font size here
            // need to hardcode the chip font size here
            '>*:nth-of-type(1)': {
              fontSize: 6,
            },
          },
        },
      },
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            backgroundColor:
              theme.styles.button.primary.default.backgroundColor,
            '&:hover': {
              backgroundColor:
                theme.styles.button.primary.hover.backgroundColor,
            },
            '&:active': {
              backgroundColor:
                theme.styles.button.primary.active.backgroundColor,
              color: theme.styles.button.primary.active.color,
            },
            '&:focus-visible': {
              boxShadow: `inset 0px 0px 0px 3px ${theme.styles.button.primary.focus.boxShadowColor};`,
            },
            '&.Mui-disabled': {
              cursor: 'not-allowed',
              color: theme.styles.button.primary.disabled.color,
              backgroundColor:
                theme.styles.button.primary.disabled.backgroundColor,
            },
            '&.MuiButton-primaryWarning': {
              backgroundColor:
                theme.styles.button.primaryDestructive.default.backgroundColor,
              color: theme.styles.button.primaryDestructive.default.color,
              '&:hover': {
                color: theme.styles.button.primaryDestructive.hover.color,
                backgroundColor:
                  theme.styles.button.primaryDestructive.hover.backgroundColor,
              },
              '&:active': {
                color: theme.styles.button.primaryDestructive.active.color,
                backgroundColor:
                  theme.styles.button.primaryDestructive.active.backgroundColor,
              },
              '&:focus-visible': {
                color: theme.styles.button.primaryDestructive.focus.color,
                backgroundColor:
                  theme.styles.button.primaryDestructive.focus.backgroundColor,
                boxShadow: `inset 0px 0px 0px 3px ${theme.styles.button.primaryDestructive.focus.boxShadowColor};`,
              },
              '&.Mui-disabled': {
                cursor: 'not-allowed',
                color: theme.styles.button.primaryDestructive.disabled.color,
                backgroundColor:
                  theme.styles.button.primaryDestructive.disabled
                    .backgroundColor,
              },
            },
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            color: theme.styles.button.secondary.default.color,
            backgroundColor:
              theme.styles.button.secondary.default.backgroundColor,
            '&:hover': {
              backgroundColor:
                theme.styles.button.secondary.hover.backgroundColor,
            },
            '&:active': {
              color: theme.styles.button.secondary.active.color,
              backgroundColor:
                theme.styles.button.secondary.active.backgroundColor,
            },
            '&:focus-visible': {
              boxShadow: `inset 0px 0px 0px 3px ${theme.styles.button.secondary.focus.boxShadowColor};`,
            },
            '&.Mui-disabled': {
              cursor: 'not-allowed',
              color: theme.styles.button.secondary.disabled.color,
              backgroundColor:
                theme.styles.button.secondary.disabled.backgroundColor,
            },
          },
        },
        {
          props: { variant: 'secondary-contrast' },
          style: {
            color: theme.styles.button.secondaryContrast.default.color,
            backgroundColor:
              theme.styles.button.secondaryContrast.default.backgroundColor,
            '&:hover': {
              color: theme.styles.button.secondaryContrast.hover.color,
              backgroundColor:
                theme.styles.button.secondaryContrast.hover.backgroundColor,
            },
            '&:active': {
              color: theme.styles.button.secondaryContrast.active.color,
              backgroundColor:
                theme.styles.button.secondaryContrast.active.backgroundColor,
            },
            '&:focus-visible': {
              color: theme.styles.button.secondaryContrast.focus.color,
              backgroundColor:
                theme.styles.button.secondaryContrast.focus.backgroundColor,
              boxShadow: `inset 0px 0px 0px 3px ${theme.styles.button.secondaryContrast.focus.boxShadowColor};`,
            },
            '&.Mui-disabled': {
              cursor: 'not-allowed',
              color: theme.styles.button.secondaryContrast.disabled.color,
              backgroundColor:
                theme.styles.button.secondaryContrast.disabled.backgroundColor,
            },
          },
        },
        {
          props: { variant: 'tertiary' },
          style: {
            color: theme.styles.button.tertiary.default.color,
            '&:hover': {
              backgroundColor: 'transparent',
              boxShadow: `inset 0px 0px 0px 2px ${theme.styles.button.tertiary.hover.boxShadowColor};`,
            },
            '&:active': {
              backgroundColor: 'transparent',
              color: theme.styles.button.tertiary.active.color,
              boxShadow: `inset 0px 0px 0px 2px ${theme.styles.button.tertiary.active.boxShadowColor};`,
            },
            '&:focus-visible': {
              backgroundColor: 'transparent',
              boxShadow: `inset 0px 0px 0px 3px ${theme.styles.button.tertiary.focus.boxShadowColor};`,
            },
            '&.Mui-disabled': {
              cursor: 'not-allowed',
              backgroundColor: 'transparent',
              color: theme.styles.button.tertiary.disabled.color,
            },
            '&.MuiButton-tertiaryWarning': {
              color: theme.styles.button.tertiaryDestructive.default.color,
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
                boxShadow: `inset 0px 0px 0px 2px ${theme.styles.button.tertiaryDestructive.hover.boxShadowColor};`,
              },
              '&:active': {
                color: theme.styles.button.tertiaryDestructive.active.color,
                backgroundColor: 'transparent',
                '&:hover': {
                  boxShadow: `inset 0px 0px 0px 2px ${theme.styles.button.tertiaryDestructive.active.boxShadowColor};`,
                },
              },
              '&:focus-visible': {
                backgroundColor: 'transparent',
                boxShadow: `inset 0px 0px 0px 3px ${theme.styles.button.tertiaryDestructive.focus.boxShadowColor};`,
              },
              '&.Mui-disabled': {
                cursor: 'not-allowed',
                color: theme.styles.button.tertiaryDestructive.disabled.color,
                backgroundColor: 'transparent',
              },
            },
          },
        },
        {
          props: { variant: 'tertiary-contrast' },
          style: {
            color: theme.styles.button.tertiaryContrast.default.color,
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent',
              boxShadow: `inset 0px 0px 0px 2px ${theme.styles.button.tertiaryContrast.hover.boxShadowColor};`,
            },
            '&:active': {
              color: theme.styles.button.tertiaryContrast.active.color,
              backgroundColor: 'transparent',
              boxShadow: `inset 0px 0px 0px 2px ${theme.styles.button.tertiaryContrast.active.boxShadowColor};`,
            },
            '&:focus-visible': {
              backgroundColor: 'transparent',
              boxShadow: `inset 0px 0px 0px 3px ${theme.styles.button.tertiaryContrast.focus.boxShadowColor};`,
            },
            '&.Mui-disabled': {
              cursor: 'not-allowed',
              backgroundColor: 'transparent',
              color: theme.styles.button.tertiaryContrast.disabled.color,
            },
            '&.MuiButton-tertiary-contrastWarning': {
              color:
                theme.styles.button.tertiaryDestructiveContrast.default.color,
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
                boxShadow: `inset 0px 0px 0px 2px ${theme.styles.button.tertiaryDestructiveContrast.hover.boxShadowColor};`,
              },
              '&:active': {
                color:
                  theme.styles.button.tertiaryDestructiveContrast.active.color,
                backgroundColor: 'transparent',
                boxShadow: `inset 0px 0px 0px 2px ${theme.styles.button.tertiaryDestructiveContrast.active.boxShadowColor};`,
              },
              '&:focus-visible': {
                backgroundColor: 'transparent',
                boxShadow: `inset 0px 0px 0px 3px ${theme.styles.button.tertiaryDestructiveContrast.focus.boxShadowColor};`,
              },
              '&.Mui-disabled': {
                cursor: 'not-allowed',
                color:
                  theme.styles.button.tertiaryDestructiveContrast.disabled
                    .color,
                backgroundColor: 'transparent',
              },
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            color: 'rgba(59, 110, 113, 1)',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent',
              boxShadow: 'none',
              textDecoration: 'underline',
            },
            '&:active': {
              color: theme.styles.button.tertiaryContrast.active.color,
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
            '&:focus-visible': {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
            '&.Mui-disabled': {
              cursor: 'not-allowed',
              backgroundColor: 'transparent',
              color: theme.styles.button.tertiaryContrast.disabled.color,
            },
          },
        },
      ],
    },
  };
};

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    'secondary-contrast': true;
    tertiary: true;
    'tertiary-contrast': true;
  }
}

export default Button;
