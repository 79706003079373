import { DateIcon } from '../../components/icons';

const DateTimePicker = (theme) => {
  return {
    MuiDateTimePicker: {
      defaultProps: {
        ampm: false,
        slotProps: {
          textField: {
            variant: 'filled',
          },
          desktopPaper: {
            elevation: 4,
          },
        },
        slots: {
          openPickerIcon: DateIcon,
        },
      },
      styleOverrides: {
        root: {},
      },
    },
    MuiMultiSectionDigitalClock: {
      styleOverrides: {
        root: {
          maxHeight: 400,
          '& ul': {
            width: 'auto',
            '&::after': {
              display: 'none',
            },
          },
          '& ul:first-of-type li': {
            borderRadius: '28px 0 0 28px',
          },
          '& ul:nth-of-type(2) li': {
            borderRadius: '0 28px 28px 0',
          },
          '& li': {
            color: `${theme.styles.datePicker.digitalClock.default.color}`,
            height: 40,
            width: 40,
            '&.Mui-selected': {
              backgroundColor: `${theme.styles.datePicker.digitalClock.selected.backgroundColor}`,
              color: `${theme.styles.datePicker.digitalClock.selected.color}`,
              '&:hover': {
                backgroundColor: `${theme.styles.datePicker.digitalClock.selected.backgroundColor}`,
              },
            },
          },
        },
      },
    },
  };
};

export default DateTimePicker;
