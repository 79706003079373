import { MenuItem as MuiMenuItem } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiMenuItemProps = ComponentProps<typeof MuiMenuItem>;

export const MenuItem = forwardRef<HTMLLIElement, MuiMenuItemProps>(
  (props, ref) => <MuiMenuItem ref={ref} {...props} />
);

MenuItem.displayName = 'MenuItem';
