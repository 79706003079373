import axios from 'axios';

// https://insights.transporeon.com/road/help/api/rate-on-demand

export const transporeonInsightsAuthenticatedRequest = axios.create({
  baseURL: 'https://insights.transporeon.com',
  timeout: 5000,
  headers: {
    Authorization: `bearer c9b993210529dd245441e09f3de1d440`,
    'Content-type': 'application/json',
  },
});

export const getRateOnDemand = async (data): Promise<any> => {
  const res = await transporeonInsightsAuthenticatedRequest.post(
    '/v1/rate-on-demand',
    data
  );
  return res.data;
};

export const getMetrics = async (): Promise<any> => {
  const res = await transporeonInsightsAuthenticatedRequest.get('/v1/metrics');
  return res.data;
};
