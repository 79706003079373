import { styled } from '@mui/material/styles';
import { SvgIconProps } from '@mui/material/SvgIcon';
import React, { FC } from 'react';

import { Card } from '../Card';
import { Chip } from '../Chip';
import { MinimalIconButton } from '../MinimalIconButton';
import { Stack } from '../Stack';
import { Typography } from '../Typography';

import { ButtonProps } from '@mui/material';
import { ChevronRightIcon, ViewIcon } from '../icons';

const BannerButtonWrapperStyles = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'status',
})<any>(() => {
  return {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: 56,
    paddingTop: 12,
    paddingRight: 24,
    paddingBottom: 12,
    paddingLeft: 24,
    alignItems: 'center',
  };
});

const TitleStyles = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'status',
})<any>(() => {
  return {
    marginLeft: 8,
  };
});

const ActionsStyles = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'status',
})<any>(() => {
  return { marginLeft: 'auto' };
});

interface BannerButtonProps extends ButtonProps {
  label: string;
  count?: number;
  icon?: SvgIconProps;
  onClick?: () => void;
}

export const BannerButton: FC<BannerButtonProps> = ({
  label,
  count = 0,
  icon = <ViewIcon sx={{ width: 32, height: 32 }} />,
  onClick,
}) => (
  <BannerButtonWrapperStyles variant="filled-secondary" elevation={1}>
    {icon}
    <TitleStyles variant="bodyBold" color="primary">
      {label}
    </TitleStyles>
    <ActionsStyles direction="row" spacing={2} justifyContent="space-between">
      <Chip label={count} size="large" variant="oxford-40" />
      <MinimalIconButton onClick={onClick}>
        <ChevronRightIcon />
      </MinimalIconButton>
    </ActionsStyles>
  </BannerButtonWrapperStyles>
);
