import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const EditIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.617 4.82843L19.3744 0.585785L7.81264 12.1475L7.28231 16.9205L12.0553 16.3902L23.617 4.82843ZM9.72293 13.0657L19.3744 3.41421L20.7886 4.82843L11.1371 14.4799L9.54616 14.6567L9.72293 13.0657ZM10.5 5H2V22H19V13.5H17V20H4V7H10.5V5Z"
        />
      </SvgIcon>
    );
  }
);

EditIcon.displayName = 'EditIcon';

export default EditIcon;
