import { ListItemIcon as MuiListItemIcon } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiListItemIconProps = ComponentProps<typeof MuiListItemIcon>;

export const ListItemIcon = forwardRef<HTMLDivElement, MuiListItemIconProps>(
  (props, ref) => {
    return <MuiListItemIcon {...props} ref={ref} />;
  }
);

ListItemIcon.displayName = 'ListItemIcon';
