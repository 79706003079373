import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  ArrowUpperRightIcon,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
} from '@dizzbo/ui';

import { TourType, UUIDType } from '@types';
import { useTranslation } from 'react-i18next';
import { CarrierSlot } from './CarrierSlot';
import { StatusMessage } from './StatusMessage';
import { VehicleSlot } from './VehicleSlot';

type Props = {
  tourData?: TourType;
  orderUUID?: UUIDType;
};

export const VehicleAsssignment: React.FC<Props> = ({
  tourData,
  orderUUID,
}) => {
  const { t } = useTranslation();

  return (
    <Card
      variant="filled-secondary"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
        minHeight: 374,
      }}
    >
      <CardHeader
        title="Vehicle Assignment"
        variant="small"
        action={
          !tourData.carrier && (
            <Button
              fullWidth
              variant="primary"
              size="medium"
              component={RouterLink}
              to={`/scheduler`}
              endIcon={<ArrowUpperRightIcon />}
            >
              {t('assignInScheduler')}
            </Button>
          )
        }
      />
      <CardContent>
        <Stack spacing={6}>
          <StatusMessage hasVehicle={!!tourData?.vehicle} />
          <CarrierSlot
            tourUUID={tourData.uuid}
            orderUUID={orderUUID}
            carrier={tourData.carrier}
          />
          <VehicleSlot
            tourUUID={tourData.uuid}
            orderUUID={orderUUID}
            carrier={tourData.carrier}
            vehicle={tourData.vehicle}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};
