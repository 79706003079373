import { styled, useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getRateOnDemand } from '@core/api';
import { Currency, Distance } from '@dizzbo/core/i18n';
import {
  Box,
  IconButton,
  InfoOutlineIcon,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Tooltip,
} from '@dizzbo/ui';

import { useGetRoute, useGetRouteSections } from '@core/hooks';
import { OrderType } from '@types';

const BarStyles = styled(Paper)(({ theme }) => ({
  width: '100%',
  height: theme.spacing(16),
  backgroundColor: theme.palette.turquoise[10],
}));

const LabelStyles = styled('label')(({ theme }) => ({
  ...theme.typography.labelSmall,
  color: theme.styles.common.textColors.light,
}));

const ValuelStyles = styled(Box)(({ theme }) => ({
  ...theme.typography.bodyRegular,
  color: theme.styles.common.textColors.dark,
}));

const SectionStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  minWidth: 130,
  paddingRight: theme.spacing(6),
  paddingLeft: theme.spacing(6),
  borderRight: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
  alignItems: 'center',
}));

const PriceSectionStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  paddingRight: theme.spacing(6),
  paddingLeft: theme.spacing(6),
}));

interface Props {
  setTotalPrice(price: number): void;
  totalPrice: number | string;
  orderData: OrderType;
}

export const DEFUALT_PRICE_PER_KM = 1.47;

export const PricingBar: FC<Props> = ({
  setTotalPrice,
  totalPrice,
  orderData,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { route: routeUUID } = orderData;
  const { data: routeData } = useGetRoute(routeUUID);
  const sections = routeData.sections || [];

  const { data: sectionsData, isFetching } = useGetRouteSections(
    routeUUID,
    sections
  );

  const tollsByCountry = routeData.tollsByCountry || {};

  const [pricePerKM, setPricePerKM] = useState<number>(DEFUALT_PRICE_PER_KM);
  const [totalDistance, setTotalDistance] = useState<number>(0);
  const [totalToll, setTotalToll] = useState<number>(1);
  const [totalRateOnDemand, setTotalRateOnDemand] = useState<number>(0);
  const [netPrice, setNetPrice] = useState<number>(0);

  const handlePriceChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const totalPrice: number = +event.target.value;
    setTotalPrice(totalPrice);
    const pricePerKm: number = +(totalPrice / (totalDistance / 1000)).toFixed(
      2
    );
    setPricePerKM(pricePerKm);
    const netPrice: number = +(totalPrice - totalToll).toFixed(2);
    setNetPrice(netPrice);
  };

  const handlePricePerKmChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const pricePerKm: number = +event.target.value;
    setPricePerKM(pricePerKm);
    const netPrice: number = (totalDistance / 1000) * pricePerKm;
    setNetPrice(netPrice);
    const totalPrice: number = +(netPrice + totalToll).toFixed(2);
    setTotalPrice(totalPrice);
  };

  const calcRateOnDemad = async (): Promise<void> => {
    let rateOnDemand = 0;

    for (let index = 0; index < sections.length; index++) {
      const originLoadingPointAddress =
        sections[index]?.origin?.loadingPoint?.address;
      const destinationLoadingPointAddress =
        sections[index]?.destination?.loadingPoint?.address;

      if (originLoadingPointAddress && destinationLoadingPointAddress) {
        try {
          const data = await getRateOnDemand({
            from_country: originLoadingPointAddress.country,
            to_country: destinationLoadingPointAddress.country,
            from_zip: originLoadingPointAddress.postalCode,
            to_zip: destinationLoadingPointAddress.postalCode,
            loading_start: dayjs().add(1, 'day'),
            unloading_end: dayjs().add(2, 'day'),
            weight: 27000,
          });

          rateOnDemand += data.predicted_spot_price;
          setTotalRateOnDemand(rateOnDemand);
        } catch {
          setTotalRateOnDemand(0);
        }
      }
    }
  };

  useEffect(() => {
    if (!isFetching && sectionsData.length > 0) {
      let distance = 0;
      let toll = 0;

      for (let index = 0; index < sectionsData.length; index++) {
        distance += sectionsData[index].distance;
        toll += sectionsData[index].toll;
      }

      calcRateOnDemad();
      setTotalToll(toll);
      setTotalDistance(distance);
      setTotalPrice(+((distance / 1000) * pricePerKM + toll).toFixed(2));
      setNetPrice((distance / 1000) * pricePerKM);
    } else {
      setTotalToll(0);
      setTotalDistance(0);
      setTotalPrice(0);
      setNetPrice(0);
    }
  }, [isFetching]);

  return (
    <BarStyles square elevation={3}>
      <Stack direction="row" spacing={0} width="100%" height="100%">
        <SectionStyles>
          <Stack>
            <LabelStyles>{t('distance')}</LabelStyles>
            <ValuelStyles>
              <Distance value={totalDistance / 1000} />
            </ValuelStyles>
          </Stack>
        </SectionStyles>
        <SectionStyles>
          <TextField
            value={pricePerKM}
            label={t('pricePerKilometer')}
            onChange={handlePricePerKmChange}
            sx={{ maxWidth: 160 }}
            disabled={sections.length === 0}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
        </SectionStyles>
        <SectionStyles>
          <Stack mr={3}>
            <LabelStyles>{t('netPrice')}</LabelStyles>
            <ValuelStyles>
              {netPrice ? <Currency value={netPrice} /> : '--.-- €'}
            </ValuelStyles>
          </Stack>
        </SectionStyles>
        <SectionStyles>
          <Stack mr={3}>
            <LabelStyles>{t('toll')}</LabelStyles>
            <ValuelStyles>
              {totalToll ? <Currency value={totalToll} /> : '--.-- €'}
            </ValuelStyles>
          </Stack>
          <Tooltip
            title={
              <Stack alignItems="flex-start">
                {Object.keys(tollsByCountry).length > 0
                  ? Object.keys(tollsByCountry).map((key) => (
                      <Box key={`country_key_${key}`}>
                        {key.toUpperCase()}: {tollsByCountry[key].toFixed(2)} €
                      </Box>
                    ))
                  : t('noTollByCountryAvailable')}
              </Stack>
            }
            placement="top"
          >
            <IconButton variant="tertiary" size="medium">
              <InfoOutlineIcon />
            </IconButton>
          </Tooltip>
        </SectionStyles>
        <PriceSectionStyles>
          <Stack
            direction="row"
            alignItems="center"
            sx={{ height: '100%' }}
            mr={3}
          >
            <Box sx={{ ...theme.typography.h5, marginRight: 4 }}>=</Box>
            <TextField
              label={t('totalPrice')}
              type="number"
              value={totalPrice}
              disabled={sections.length === 0}
              onChange={handlePriceChange}
              sx={{ maxWidth: 150 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
          </Stack>
          {totalRateOnDemand > 0 && (
            <Tooltip
              title={`${t('transporeonInsightsSpotRateOnDemand')}: ${totalRateOnDemand} €`}
              placement="top"
            >
              <IconButton variant="tertiary" size="medium">
                <InfoOutlineIcon />
              </IconButton>
            </Tooltip>
          )}
        </PriceSectionStyles>
      </Stack>
    </BarStyles>
  );
};
