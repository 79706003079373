import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@dizzbo/ui';

import { PaginatedListType } from 'packages/core/types';
import { ConversationList } from './ConversationList';
import { MessageInput } from './MessageInput';

type Props = {
  popupState: PopupState;
  query: {
    queryFn: ({
      limit,
      offset,
      search,
    }: {
      limit: number;
      offset: number;
      search?: string;
    }) => PaginatedListType<any>;
    queryKey: string[];
  };
  mutation: {
    mutationFn: (data) => void;
    mutationKey: string[];
  };
};

// function PaperComponent(props: PaperProps) {
//   return (
//     <Draggable
//       handle="#draggable-dialog-title"
//       cancel={'[class*="MuiDialogContent-root"]'}
//     >
//       <Paper {...props} />
//     </Draggable>
//   );
// }

export const ConversationDialog: React.FC<Props> = ({
  popupState,
  query,
  mutation,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          position: 'relative',
        },
      }}
      PaperProps={{
        elevation: 6,
        variant: 'filled-primary',
        sx: {
          minWidth: 600,
          height: '100%',
          position: 'absolute',
          right: 0,
        },
      }}
      // PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      scroll="paper"
      {...bindDialog(popupState)}
    >
      <DialogTitle onClose={popupState.close} id="draggable-dialog-title">
        <Typography variant="h3" color="primary">
          {t('conversation')}
        </Typography>
      </DialogTitle>

      <DialogContent dividers sx={{ padding: 0 }}>
        <ConversationList query={query} />
      </DialogContent>
      <DialogActions>
        <MessageInput mutation={mutation} />
      </DialogActions>
    </Dialog>
  );
};
