import React, { PropsWithChildren } from 'react';
import Fade from '@mui/material/Fade';

type Props = PropsWithChildren<{
  index: number;
  value: number;
}>;

export const LoadingPlanTabPanel: React.FC<Props> = ({
  children,
  value,
  index,
  ...other
}) => (
  <Fade in={value === index}>
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  </Fade>
);
