import React, { MutableRefObject } from 'react';
import { styled, Theme } from '@mui/material/styles';

import { Box } from '@dizzbo/ui';

const MapWrapperBox = styled(Box)(({ theme }: { theme: Theme }) => ({
  height: '100%',
  width: '100%',
  '& .vehicleMarkerWrapper': {},
  '& .H_imprint': {
    display: 'none',
  },
})) as typeof Box;

type Props = {};

type Ref = MutableRefObject<T | null>;

export const MapWrapper = React.forwardRef<Props, Ref>((props, ref) => (
  <MapWrapperBox ref={ref} />
));

MapWrapper.displayName = 'MapWrapper';
