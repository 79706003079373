import React from 'react';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import { SxProps } from '@mui/system';

import { Box, MinimalIconButton } from '@dizzbo/ui';
import { InfoOutlineIcon, WarningIcon } from '@dizzbo/ui';

const ContainerStyles = styled(Box)(({ theme }) => ({
  height: 64,
  // border: '2px solid transparent',
  width: '100%',
  minWidth: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  borderRadius: 8,
  overflow: 'hidden',
  // '&:hover': {
  //   border: `2px solid ${theme.palette.turquoise[100]}`,
  // },
}));

const FlagContainerStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'countryCode' && prop !== 'position',
})<any>(({ theme, countryCode, position }) => {
  let cc = 'de';
  if (countryCode) {
    cc = countryCode.toLowerCase();
  }
  const flagImgUrl = new URL(
    `../../../../assets/images/flags/vertical-flags/flag-${cc}.svg`,
    import.meta.url
  );
  return {
    display: 'block',
    position: 'absolute',
    height: 64,
    width: 8,
    left: position === 'right' ? 'auto' : 0,
    right: position === 'right' ? 0 : 'auto',
    backgroundImage: `url("${flagImgUrl}")`,
    borderTopLeftRadius: position === 'right' ? 0 : 8,
    borderBottomLeftRadius: position === 'right' ? 0 : 8,
    borderTopRightRadius: position === 'right' ? 8 : 0,
    borderBottomRightRadius: position === 'right' ? 8 : 0,
  };
});

const ContentStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'warning',
})<any>(({ theme, warning }) => {
  return {
    position: 'absolute',
    left: 8,
    right: 8,
    display: 'flex',
    flexDirection: 'column',
    height: 64,
    backgroundColor: warning
      ? theme.palette.warning.main
      : theme.palette.oxford[30],
    paddingTop: 4,
    paddingRight: 8,
    paddingBottom: 4,
    paddingLeft: 8,
  };
});

const HeaderStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  minWidth: '100%',
  alignItems: 'center',
  marginBottom: 1,
}));

const TitleStyles = styled(Box)(({ theme }) => ({
  marginRight: 4,
  // need to override fontweight important because the scheduler css is doing weird wildcard styling on all children
  fontWeight: `${theme.typography.bodyBold.fontWeight} !important`,
  fontSize: `${theme.typography.bodyBold.fontSize} !important`,
  lineHeight: `${theme.typography.bodyBold.lineHeight} !important`,
}));

const SubtitleStyles = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ...theme.typography.tiny,
  // need to override fontweight important because the scheduler css is doing weird wildcard styling on all children
  fontWeight: `${theme.typography.tiny.fontWeight} !important`,
  flexShrink: 1,
}));

const InfoStyles = styled(Box)(({ theme }) => ({
  marginLeft: 'auto',
  display: 'flex',
  width: 16,
  height: 16,
}));

const StopsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

const StopStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'last',
})<any>(({ theme, last }) => {
  return {
    // maxWidth: 150,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    textAlign: last ? 'right' : 'left',
  };
});

const StopCountstyles = styled(Box)(({ theme }) => ({
  ...theme.typography.tiny,
  // need to override fontweight important because the scheduler css is doing weird wildcard styling on all children
  fontWeight: `${theme.typography.tiny.fontWeight} !important`,
  marginBottom: 2,
}));

const AddressStyles = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ...theme.typography.tiny,
  // need to override fontweight important because the scheduler css is doing weird wildcard styling on all children
  fontWeight: `${theme.typography.tiny.fontWeight} !important`,
  marginBottom: 1,
}));

const StopsProgressWrapperStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  borderBottom: `1px solid rgba(255, 255, 255, 0.32)`,
  width: '100%',
  height: 2,
  marginBottom: 3,
}));

const StopsProgressSectionStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'first' && prop !== 'last',
})<any>(({ theme, first, last }) => {
  return {
    flex: 1,
    height: 2,
    position: 'relative',

    '&:before': {
      position: 'absolute',
      content: '""',
      top: 0,
      borderRadius: 10,
      height: 4,
      width: 4,
      backgroundColor: theme.palette.oxford[10],
      left: first ? 0 : 'auto',
      right: last ? 0 : 'auto',
    },
  };
});

const DateStyles = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ...theme.typography.tiny,
  // need to override fontweight important because the scheduler css is doing weird wildcard styling on all children
  fontWeight: `${theme.typography.tiny.fontWeight} !important`,
}));

const WarningWrapperStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  ...theme.typography.bodyBoldSmall,
  // need to override fontweight important because the scheduler css is doing weird wildcard styling on all children
  fontWeight: `${theme.typography.bodyBoldSmall.fontWeight} !important`,
  svg: {
    width: 14,
    marginRight: 10,
    path: {
      fill: theme.palette.warning.main,
      '&:first-of-type': {
        fill: '#ffffff',
      },
    },
  },
}));

export type Props = {
  sx?: SxProps;
  eventRecord?: any;
  resourceRecord?: any;
  renderData?: any;
};

export const TourListItem = ({
  eventRecord,
  resourceRecord,
  renderData,
}: Props) => {
  const { data } = eventRecord;
  const warning = data?.scheduler?.errors;

  const start = {
    countryCode: data?.origin?.loadingPoint?.address.country,
    city: data?.origin?.loadingPoint?.address.city,
    date: dayjs(data?.scheduler.startsAt).format('hh:mm'),
  };

  const destination = {
    countryCode: data?.destination?.loadingPoint?.address.country,
    city: data?.destination?.loadingPoint?.address.city,
    date: dayjs(data?.scheduler.arrivesAt).format('hh:mm'),
  };

  return (
    <ContainerStyles>
      <FlagContainerStyles countryCode={start.countryCode} />
      <ContentStyles warning={warning}>
        <HeaderStyles>
          <TitleStyles>{data.reference}</TitleStyles>
          <SubtitleStyles>{data.orders[0].loaderName}</SubtitleStyles>
          <InfoStyles>
            <MinimalIconButton size="tiny" variant="primary-contrast">
              <InfoOutlineIcon />
            </MinimalIconButton>
          </InfoStyles>
        </HeaderStyles>
        <StopsContainer>
          <StopStyles>
            <AddressStyles>
              {start.countryCode}, {start.city}
            </AddressStyles>
          </StopStyles>

          <StopStyles last>
            <AddressStyles>
              {destination.city}, {destination.countryCode}
            </AddressStyles>
          </StopStyles>
        </StopsContainer>
        {!warning ? (
          <>
            <StopsProgressWrapperStyles>
              <StopsProgressSectionStyles first></StopsProgressSectionStyles>
              <StopsProgressSectionStyles last></StopsProgressSectionStyles>
            </StopsProgressWrapperStyles>

            <StopsContainer>
              <StopStyles>
                <DateStyles>{start.date}</DateStyles>
              </StopStyles>
              <StopCountstyles>{data.stops.length} stops</StopCountstyles>
              <StopStyles last>
                <DateStyles>{destination.date}</DateStyles>
              </StopStyles>
            </StopsContainer>
          </>
        ) : (
          <WarningWrapperStyles>
            <WarningIcon /> Please reschedule, dates are not valid
          </WarningWrapperStyles>
        )}
      </ContentStyles>
      <FlagContainerStyles
        position="right"
        countryCode={destination.countryCode}
      />
    </ContainerStyles>
  );
};
