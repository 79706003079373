import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SortDescIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M13 16.1892L15.4976 13.8123L16.9998 15.2419L11.9999 20L7 15.2419L8.50218 13.8123L11 16.1894V4H13V16.1892Z" />
      </SvgIcon>
    );
  }
);

SortDescIcon.displayName = 'SortDescIcon';

export default SortDescIcon;
