import { Theme } from '@mui/material';

import Alert from './Alert';
import Autocomplete from './Autocomplete';
import Avatar from './Avatar';
import Button from './Button';
import Card from './Card';
import Checkbox from './Checkbox';
import Chip from './Chip';
import CssBaseline from './CssBaseline';
import DataGrid from './DataGrid';
import DatePicker from './DatePicker';
import DateTimePicker from './DateTimePicker';
import DateRangeCalendar from './DateRangeCalendar';
import Dialog from './Dialog';
import Divider from './Divider';
import FormControl from './FormControl';
import IconButton from './IconButton';
import Input from './Input';
import InputAdornment from './InputAdornment';
import LinearProgress from './LinearProgress';
import Link from './Link';
import List from './List';
import Menu from './Menu';
import Pagination from './Pagination';
import LoadingButton from './LoadingButton';
import Paper from './Paper';
import Popover from './Popover';
import Radio from './Radio';
import Select from './Select';
import Slider from './Slider';
import SvgIcon from './SvgIcon';
import Switch from './Switch';
import Table from './Table';
import Tabs from './Tabs';
import TextField from './TextField';
import ToggleButton from './ToggleButton';
import Toolbar from './Toolbar';
import Tooltip from './Tooltip';

const ComponentsOverrides = (theme: Theme) => {
  return Object.assign(
    Alert(theme),
    Autocomplete(theme),
    Avatar(theme),
    Button(theme),
    Card(theme),
    Checkbox(theme),
    Chip(theme),
    CssBaseline(theme),
    DataGrid(theme),
    DatePicker(theme),
    DateTimePicker(theme),
    DateRangeCalendar(theme),
    Dialog(theme),
    Divider(theme),
    FormControl(theme),
    IconButton(theme),
    Input(theme),
    InputAdornment(theme),
    LinearProgress(theme),
    Link(theme),
    List(theme),
    Menu(theme),
    Pagination(theme),
    LoadingButton(theme),
    Paper(theme),
    Popover(theme),
    Radio(theme),
    Select(theme),
    Slider(theme),
    SvgIcon(theme),
    Switch(theme),
    Table(theme),
    Tabs(theme),
    TextField(theme),
    ToggleButton(theme),
    Toolbar(theme),
    Tooltip(theme)
  );
};

export default ComponentsOverrides;
