import { styled } from '@mui/material/styles';
import React, { Children, forwardRef, ReactNode } from 'react';

import { Box } from '../Box';

export type VariantTypes =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'tertiaryDark'
  | 'bright'
  | 'brightDark'
  | 'mixed'
  | 'disabled'
  | 'inactive'
  | 'success'
  | 'info';

export type IconBulletProps = {
  children?: ReactNode;
  size?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
  variant?: VariantTypes;
};

const WrapperStyle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'variant',
})<any>(({ theme, size, variant }) => {
  let sizeStyles;

  if (size === 'medium') {
    sizeStyles = { minWidth: 32, minHeight: 32 };
  }

  if (size === 'large') {
    sizeStyles = { minWidth: 40, minHeight: 40 };
  }

  if (size === 'xlarge') {
    sizeStyles = { minWidth: 48, minHeight: 48 };
  }

  if (size === 'xxlarge') {
    sizeStyles = { minWidth: 56, minHeight: 56 };
  }

  let variantStyles;

  if (variant === 'primary') {
    variantStyles = {
      color: theme.styles.iconBullet.primary.default.color,
      backgroundColor: theme.styles.iconBullet.primary.default.backgroundColor,
    };
  }

  if (variant === 'secondary') {
    variantStyles = {
      color: theme.styles.iconBullet.secondary.default.color,
      backgroundColor:
        theme.styles.iconBullet.secondary.default.backgroundColor,
    };
  }

  if (variant === 'tertiary') {
    variantStyles = {
      color: theme.styles.iconBullet.tertiary.default.color,
      backgroundColor: theme.styles.iconBullet.tertiary.default.backgroundColor,
    };
  }

  if (variant === 'tertiaryDark') {
    variantStyles = {
      color: theme.styles.iconBullet.tertiaryDark.default.color,
      backgroundColor:
        theme.styles.iconBullet.tertiaryDark.default.backgroundColor,
    };
  }

  if (variant === 'bright') {
    variantStyles = {
      color: theme.styles.iconBullet.bright.default.color,
      backgroundColor: theme.styles.iconBullet.bright.default.backgroundColor,
    };
  }

  if (variant === 'brightDark') {
    variantStyles = {
      color: theme.styles.iconBullet.brightDark.default.color,
      backgroundColor:
        theme.styles.iconBullet.brightDark.default.backgroundColor,
    };
  }

  if (variant === 'inactive') {
    variantStyles = {
      color: theme.styles.iconBullet.inactive.default.color,
      backgroundColor: theme.styles.iconBullet.inactive.default.backgroundColor,
    };
  }

  if (variant === 'disabled') {
    variantStyles = {
      color: theme.styles.iconBullet.disabled.default.color,
      backgroundColor: theme.styles.iconBullet.disabled.default.backgroundColor,
    };
  }

  if (variant === 'info') {
    variantStyles = {
      color: theme.styles.iconBullet.info.default.color,
      backgroundColor: theme.styles.iconBullet.info.default.backgroundColor,
    };
  }

  if (variant === 'success') {
    variantStyles = {
      color: theme.styles.iconBullet.success.default.color,
      backgroundColor: theme.styles.iconBullet.success.default.backgroundColor,
    };
  }

  if (variant === 'mixed') {
    variantStyles = {
      color: theme.styles.iconBullet.mixed.default.color,
      backgroundColor: theme.styles.iconBullet.mixed.default.backgroundColor,
      backgroundImage: `url(${theme.styles.iconBullet.mixed.default.backgroundImage})`,
      backgroundSize: '22px 19px',
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: theme.styles.iconBullet.mixed.default.borderColor,
    };
  }

  return {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    outline: 0,
    border: 0,
    margin: 0,
    userSelect: 'none',
    verticalAlign: 'middle',
    textDecoration: 'none',
    textAlign: 'center',
    flex: '0 0 auto',
    borderRadius: 50,
    overflow: 'visible',
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    minWidth: 24,
    minHeight: 24,
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.light,
    ...sizeStyles,
    ...variantStyles,
  };
});

const ChildWrapperStyle = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'size' && prop !== 'first' && prop !== 'last',
})<any>(({ size, first, last }) => {
  let sizeStyles;

  if (size === 'medium') {
    sizeStyles = {
      fontSize: 13,
      svg: {
        width: 16,
        height: 16,
      },
      marginLeft: last ? 8 : 0 || first ? 8 : 0,
      marginRight: last ? 8 : 0,
    };
  }

  if (size === 'large') {
    sizeStyles = {
      fontSize: 15,
      svg: {
        width: 20,
        height: 20,
      },
    };
  }

  if (size === 'xlarge') {
    sizeStyles = {
      fontSize: 18,
      svg: {
        width: 24,
        height: 24,
      },
    };
  }

  if (size === 'xxlarge') {
    sizeStyles = {
      fontSize: 22,
      svg: {
        width: 28,
        height: 28,
      },
    };
  }

  return {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'inherit',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '6px',
    marginLeft: last ? 5 : 0 || first ? 5 : 0,
    marginRight: last ? 5 : 0,
    svg: {
      width: 14,
      height: 14,
    },
    ...sizeStyles,
  };
});

export const IconBullet = forwardRef<HTMLDivElement, IconBulletProps>(
  ({ children, size, variant }, ref) => {
    const childrenCount = Children.count(children);

    return (
      <WrapperStyle ref={ref} size={size} variant={variant}>
        {Children.map(children, (child, i) => {
          return (
            <ChildWrapperStyle
              size={size}
              first={childrenCount > 1 && i < 1}
              last={childrenCount > 1 && i + 1 === childrenCount}
            >
              {child}
            </ChildWrapperStyle>
          );
        })}
      </WrapperStyle>
    );
  }
);

IconBullet.displayName = 'IconBullet';
