import { WaypointWithSectionType } from '@types';
import { MutableRefObject, useEffect, useRef } from 'react';
import uuid4 from 'uuid4';
import { getWaypointMarker } from '../utils';

export const useWaypointListeners = (
  hereMapRef: MutableRefObject<H.Map> | undefined,
  mapBehaviourRef: MutableRefObject<H.mapevents.Behavior> | undefined,
  onDragMove: (waypoint: WaypointWithSectionType) => void,
  onDragEnd: (waypoint: WaypointWithSectionType) => void,
  onPointerUp: (waypoint: WaypointWithSectionType) => void,
  onRemoveWaypoint: (waypoint: WaypointWithSectionType) => void
) => {
  const dragMarkerRef = useRef<H.map.DomMarker>(null);

  useEffect(() => {
    if (!hereMapRef.current) return;
    const map = hereMapRef.current as H.Map;
    const mapBehaviour = mapBehaviourRef.current as H.mapevents.Behavior;

    /** ------------------------------------------------------------------------
     * TAP- remove waypoint when clicking on a waypoint marker
     * @param {H.mapevents.Event} event
     */

    const handleTap = (event: H.mapevents.Event) => {
      const target = event.target;

      if (target instanceof H.map.DomMarker) {
        if (map.getObjects().includes(target)) {
          map.removeObject(target);
        }
        onRemoveWaypoint(target.getData());
      }
    };
    map.addEventListener('tap', handleTap);

    /** ------------------------------------------------------------------------
     * POINTER DOWN - create waypoint from route section
     * @param {H.mapevents.Event} event
     */

    function handlePointerDown(event: H.mapevents.Event) {
      const target = event.target;
      const pointer = event.currentPointer;

      if (target instanceof H.map.Polyline) {
        const coord = map.screenToGeo(
          pointer.viewportX - 8,
          pointer.viewportY - 8
        );
        const { sectionUUID } = target.getData();

        mapBehaviour.disable();
        map.getViewPort().element.style.cursor = 'grabbing';

        const waypointUUID = uuid4();

        const existingWaypoint = map
          .getObjects()
          .find(
            (object) =>
              object instanceof H.map.DomMarker &&
              object.getData().uuid === waypointUUID
          );

        // create a new waypoint marker and add it to the map
        const dragMarker =
          existingWaypoint ||
          getWaypointMarker({
            ...coord,
            sectionUUID,
            uuid: waypointUUID, // uuid4(),
          });

        map.addObject(dragMarker);
        dragMarkerRef.current = dragMarker as H.map.DomMarker;
      }
    }
    map.addEventListener('pointerdown', handlePointerDown);

    /** ------------------------------------------------------------------------
     * POINTER MOVE - move waypoint created from route section
     * @param {H.mapevents.Event } event
     */

    function handlePointerMove(event: H.mapevents.Event) {
      if (!dragMarkerRef.current) return;

      const pointer = event.currentPointer;

      const coord = map.screenToGeo(
        pointer.viewportX - 8,
        pointer.viewportY - 8
      );

      // const { uuid, sectionUUID } = dragMarkerRef.current.getData();

      dragMarkerRef.current.setGeometry(coord);

      // const waypoint: WaypointWithSectionType = {
      //   lat: coord.lat,
      //   lng: coord.lng,
      //   uuid,
      //   sectionUUID,
      // };
      // onDragMove(waypoint);
    }
    map.addEventListener('pointermove', handlePointerMove);

    /** ------------------------------------------------------------------------
     * POINTER UP - store waypoint created from route section
     * @param {H.mapevents.Event} event
     */
    function handlePointerUp(event: H.mapevents.Event) {
      const pointer = event.currentPointer;

      if (!dragMarkerRef.current) return;
      // remove temp dragMarker
      const coord = map.screenToGeo(
        pointer.viewportX - 8,
        pointer.viewportY - 8
      );

      mapBehaviour.enable();
      map.getViewPort().element.style.cursor = 'auto';

      const { uuid, sectionUUID } = dragMarkerRef.current.getData();
      const waypoint: WaypointWithSectionType = {
        lat: coord.lat,
        lng: coord.lng,
        uuid,
        sectionUUID,
      };

      if (dragMarkerRef && map.getObjects().includes(dragMarkerRef.current)) {
        map.removeObject(dragMarkerRef.current);
      }
      dragMarkerRef.current = null;
      onPointerUp(waypoint);
    }
    map.addEventListener('pointerup', handlePointerUp);

    /** ------------------------------------------------------------------------
     * DRAG START - start dragging an existing waypoint
     * @param {H.mapevents.Event} event
     */
    function handleDragStart(event: H.mapevents.Event) {
      const target = event.target;

      if (target instanceof H.map.DomMarker) {
        mapBehaviour.disable(H.mapevents.Behavior.Feature.PANNING);
        map.getViewPort().element.style.cursor = 'grabbing';
      }
    }
    map.addEventListener('dragstart', handleDragStart, true);

    /** ------------------------------------------------------------------------
     * DRAG MOVE - move an existing waypoint
     * @param {H.mapevents.Event} event
     */
    function handleDragMove(event: H.mapevents.Event) {
      const target = event.target;
      if (!(target instanceof H.map.DomMarker)) return;

      const coord = map.screenToGeo(
        event.currentPointer.viewportX - 8,
        event.currentPointer.viewportY - 8
      );

      // if position has not changed, do nothing
      if (target.getGeometry().equals(coord)) return;

      const { uuid, sectionUUID } = target.getData();

      target.setGeometry(coord);

      const waypoint: WaypointWithSectionType = {
        lat: coord.lat,
        lng: coord.lng,
        uuid,
        sectionUUID,
      };

      onDragMove(waypoint);
    }
    map.addEventListener('drag', handleDragMove, true);

    /** ------------------------------------------------------------------------
     * DRAG END - store an existing moved waypoint
     * @param {H.mapevents.Event} event
     */
    function handleDragEnd(event: H.mapevents.Event) {
      const target = event.target;
      if (!(target instanceof H.map.DomMarker)) return;

      const coord = map.screenToGeo(
        event.currentPointer.viewportX - 8,
        event.currentPointer.viewportY - 8
      );

      mapBehaviour.enable(H.mapevents.Behavior.Feature.PANNING);
      map.getViewPort().element.style.cursor = 'auto';

      const { uuid, sectionUUID } = target.getData();
      const waypoint: WaypointWithSectionType = {
        lat: coord.lat,
        lng: coord.lng,
        uuid,
        sectionUUID,
      };

      if (map.getObjects().includes(target)) {
        map.removeObject(target);
      }

      onDragEnd(waypoint);
    }
    map.addEventListener('dragend', handleDragEnd, true);

    return () => {
      if (!hereMapRef?.current) return;

      map.removeEventListener('tap', handleTap);
      map.removeEventListener('pointerdown', handlePointerDown);
      map.removeEventListener('pointermove', handlePointerMove);
      map.removeEventListener('pointerup', handlePointerUp);
      map.removeEventListener('dragstart', handleDragStart);
      map.removeEventListener('drag', handleDragMove);
      map.removeEventListener('dragend', handleDragEnd);
    };
  }, [hereMapRef.current, onDragMove, onDragEnd, onRemoveWaypoint]);
};
