import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DoubleArrowRightIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M6.42954 17.4999L11.1877 12.5L6.42954 7.50011L5 9.00229L8.32859 12.5L5 15.9977L6.42954 17.4999Z" />
        <path d="M15.2419 17.4999L20 12.5L15.2419 7.50011L13.8123 9.00229L17.1409 12.5L13.8123 15.9977L15.2419 17.4999Z" />
      </SvgIcon>
    );
  }
);

DoubleArrowRightIcon.displayName = 'DoubleArrowRightIcon';

export default DoubleArrowRightIcon;
