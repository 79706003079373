import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TourIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 2C2.34526 2 1 3.34506 1 5.009C1 6.48 1.9074 8.37428 3.05399 10.098C3.36701 10.5686 3.68103 11.002 3.96974 11.3806C4.27263 10.9696 4.60493 10.4976 4.93611 9.98782C5.48864 9.13738 6.01895 8.21123 6.40649 7.30956C6.80256 6.38805 7 5.60474 7 5.009C7 3.34506 5.65474 2 4 2ZM4 6C4.55229 6 5 5.55228 5 5C5 4.44772 4.55229 4 4 4C3.44771 4 3 4.44772 3 5C3 5.55228 3.44771 6 4 6Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 13C2.34526 13 1 14.3451 1 16.009C1 17.48 1.9074 19.3743 3.05399 21.098C3.36701 21.5686 3.68103 22.002 3.96974 22.3806C4.27263 21.9696 4.60493 21.4976 4.93611 20.9878C5.48864 20.1374 6.01895 19.2112 6.40649 18.3096C6.80256 17.3881 7 16.6047 7 16.009C7 14.3451 5.65474 13 4 13ZM4 17C4.55229 17 5 16.5523 5 16C5 15.4477 4.55229 15 4 15C3.44771 15 3 15.4477 3 16C3 16.5523 3.44771 17 4 17Z"
        />
        <path d="M22 6H9V4H22V6Z" />
        <path d="M9 17H22V15H9V17Z" />
        <path d="M19 9H9V8H19V9Z" />
        <path d="M9 20H19V19H9V20Z" />
      </SvgIcon>
    );
  }
);

TourIcon.displayName = 'TourIcon';

export default TourIcon;
