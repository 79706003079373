import { QueryKeys } from '@core/config';
import { useQueryClient } from '@tanstack/react-query';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React from 'react';

import {
  ErrorIcon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MoreVerticalIcon,
} from '@dizzbo/ui';

import { useDeleteOrderStop } from '@core/hooks';

import { UUIDType } from '@types';
import { useTranslation } from 'react-i18next';
import { useOrderDetail } from '../../hooks';

type Props = {
  stopUUID: UUIDType;
};

export const StopActions: React.FC<Props> = ({ stopUUID }) => {
  const { orderUUID, routeUUID } = useOrderDetail();
  const { mutateAsync } = useDeleteOrderStop(orderUUID, stopUUID);
  const queryClient = useQueryClient();

  const handleDelete = async (popupState) => {
    try {
      await mutateAsync();
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ROUTES, routeUUID],
      });
    } catch (error) {}
    popupState.close();
  };

  const { t } = useTranslation();

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <>
          <IconButton
            size="medium"
            variant="tertiary"
            {...bindTrigger(popupState)}
          >
            <MoreVerticalIcon />
          </IconButton>
          <Menu {...bindMenu(popupState)}>
            <MenuItem onClick={() => handleDelete(popupState)}>
              <ListItemIcon>
                <ErrorIcon sx={{ width: 16, height: 16 }} />
              </ListItemIcon>
              <ListItemText>{t('deleteStop')}</ListItemText>
            </MenuItem>
          </Menu>
        </>
      )}
    </PopupState>
  );
};
