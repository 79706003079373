const Link = (theme) => {
  return {
    MuiLink: {
      defaultProps: {},
      styleOverrides: {
        root: {
          color: theme.styles.link.default.default.color,
          textDecoration: 'none',
          '&:hover': {
            color: theme.styles.link.default.hover.color,
            textDecoration: 'underline',
          },
          '&:active': {
            color: theme.styles.link.default.active.color,
            textDecoration: 'none',
          },
          '&:focus': {
            color: theme.styles.link.default.focus.color,
            textDecoration: 'none',
          },
        },
      },
    },
  };
};

export default Link;
