import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import React from 'react';

import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);

import { Box, Chip } from '@dizzbo/ui';
import { ISODateTimeType } from 'packages/core/types';

const ContainerStyles = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  '&::after': {
    content: "' '",
    position: 'absolute',
    width: '100%',
    height: 1,
    backgroundColor: theme.styles.divider.primary.default.borderColor,
    top: 8,
    zIndex: -1,
  },
}));

type Props = {
  date: ISODateTimeType;
};

export const DateDivider: React.FC<Props> = ({ date }) => {
  const dayjsDate = dayjs(date);

  let dateDisplay = dayjs(dayjsDate).format('l');

  if (dayjs().get('date') === dayjsDate.get('date')) {
    dateDisplay = 'Today';
  }

  if (dayjs().get('date') === dayjsDate.subtract(1, 'day').get('date')) {
    dateDisplay = 'Today';
  }

  return (
    <ContainerStyles>
      <Chip
        label={dateDisplay}
        size="small"
        variant="outlined"
        sx={{ backgroundColor: '#FFFFFF' }}
      />
    </ContainerStyles>
  );
};
