import React from 'react';
import { useTheme } from '@mui/material/styles';

import { Box, Tabs, Tab, Chip } from '@dizzbo/ui';
import { DocumentIcon, MapIcon } from '@dizzbo/ui';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

type Props = {
  activeTab: number;
  setActiveTab?: (activeTab: number) => void;
};

export const StatusFilters: React.FC<Props> = ({ activeTab, setActiveTab }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.styles.common.pageBackground,
        paddingLeft: 3,
        position: 'relative',
        zIndex: theme.zIndex.appBar,
        '&::after': {
          pointerEvents: 'none',
          content: "' '",
          display: 'block',
          position: 'absolute',
          zIndex: 1,
          height: '48px',
          width: '100%',
          bottom: -48,
          left: 0,
          backgroundRepeat: 'repeat-x',
          backgroundImage: `url(${theme.styles.images.shadowHorizontal})`,
        },
      }}
    >
      <Tabs
        value={activeTab}
        onChange={(event: React.SyntheticEvent, newValue: number) =>
          setActiveTab(newValue)
        }
        aria-label="Order detail tabs"
      >
        <Tab label="All" {...a11yProps(0)} />
        <Tab
          icon={<Chip label="23" variant="forest" size="small" />}
          iconPosition="end"
          label="On time"
          {...a11yProps(1)}
        />
      </Tabs>
    </Box>
  );
};
