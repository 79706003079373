import React from 'react';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';

import { TourSummary } from '@order-detail/components/TourDetailForm/TourSummary';
import { VehicleAsssignment } from '@order-detail/components/TourDetailForm//VehicleAsssignment';
import { TransportContract } from '@order-detail/components/TourDetailForm/TransportContract';

import { Contacts } from './Contacts';
import { CarrierPrice } from './CarrierPrice';
import { TourPlan } from './TourPlan';
import { useTourDetail } from '../../hooks';

interface Props {}

export const TourSection: React.FC<Props> = ({}: Props) => {
  const theme = useTheme();
  const { tourData } = useTourDetail();
  const orderUUID = tourData.orders[0].uuid;

  return (
    <Grid
      container
      spacing={6}
      rowSpacing={12}
      sx={{
        margin: 0,
        marginTop: theme.spacing(6),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      }}
    >
      <Grid xs={12}>
        <TourSummary reference={tourData.reference} />
      </Grid>
      <Grid xs={12} lg={6}>
        <VehicleAsssignment tourData={tourData} orderUUID={orderUUID} />
      </Grid>
      <Grid xs={6} lg={6}>
        <CarrierPrice />
      </Grid>
      <Grid xs={12}>
        <TourPlan />
      </Grid>
      <Grid xs={6} lg={6}>
        <Contacts />
      </Grid>
      <Grid xs={6} lg={6}>
        <TransportContract
          tourUUID={tourData.uuid}
          tourReference={tourData.reference}
          transportContractUUID={tourData.transportContract}
        />
      </Grid>
    </Grid>
  );
};
