const datePicker = (palette) => {
  return {
    datePicker: {
      default: {
        backgroundColor: palette.common.white,
      },
      pickerModeSelect: {
        default: {
          backgroundColor: palette.turquoise[60],
          color: palette.oxford[100],
        },
      },
      arrowSwitcher: {
        default: {
          color: palette.oxford[100],
        },
        button: {
          default: {
            color: palette.turquoise[100],
          },
          disabled: {
            color: palette.oxford[25],
          },
        },
      },
      calendarHeader: {
        default: {
          color: palette.turquoise[150],
        },
      },
      dayPicker: {
        default: {
          color: palette.oxford[25],
        },
      },
      pickersDay: {
        default: {
          color: palette.oxford[25],
        },
        selected: {
          backgroundColor: palette.turquoise[100],
          color: palette.oxford[100],
        },
        today: {
          color: palette.turquoise[125],
        },
      },
      pickerDay: {
        default: {
          backgroundColor: palette.turquoise[80],
          color: palette.oxford[100],
        },
        selected: {
          color: palette.oxford[100],
        },
      },
      digitalClock: {
        default: {
          color: palette.oxford[100],
        },
        selected: {
          backgroundColor: palette.turquoise[100],
          color: palette.oxford[100],
        },
      },
    },
  };
};

export default datePicker;
