import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DocumentsIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M17.3837 0H7V2H16.6163L21 5.94536V18H19V20H23V5.05464L17.3837 0Z" />
        <path d="M6 18H11V16H6V18Z" />
        <path d="M11 14H6V12H11V14Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.3837 3H2V23H18V8.05464L12.3837 3ZM4 21V5H11.6163L16 8.94536V21H4Z"
        />
      </SvgIcon>
    );
  }
);

DocumentsIcon.displayName = 'DocumentsIcon';

export default DocumentsIcon;
