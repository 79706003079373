import { shadows } from '../shadows';

const tooltip = (palette) => {
  return {
    tooltip: {
      default: {
        color: palette.turquoise[60],
        backgroundColor: palette.oxford[80],
        boxShadow: shadows[4],
      },
    },
  };
};

export default tooltip;
