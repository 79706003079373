import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PlusIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M20 13H13V20H11L11 13H4V11L11 11L11 4H13V11L20 11V13Z" />
      </SvgIcon>
    );
  }
);

PlusIcon.displayName = 'PlusIcon';

export default PlusIcon;
