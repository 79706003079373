import EuclidCircularALight from "../../assets/fonts/EuclidCircularA-Light.woff2";
import EuclidCircularARegular from "../../assets/fonts/EuclidCircularA-Regular.woff2";
import EuclidCircularAMedium from "../../assets/fonts/EuclidCircularA-Medium.woff2";
import EuclidCircularASemibold from "../../assets/fonts/EuclidCircularA-Semibold.woff2";
import EuclidCircularABold from "../../assets/fonts/EuclidCircularA-Bold.woff2";

const CssBaseline = (theme) => {
  return {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'EuclidCircularARegular';
        font-style: normal;
        font-display: swap;
        font-weight: 300;
        src: local('EuclidCircularARegular'), url(${EuclidCircularALight}) format('woff2');
      }
      @font-face {
        font-family: 'EuclidCircularARegular';
        font-style: normal;
        font-display: swap;
        font-weight: normal;
        src: local('EuclidCircularARegular'), url(${EuclidCircularARegular}) format('woff2');
      }
      @font-face {
        font-family: 'EuclidCircularARegular';
        font-style: normal;
        font-display: swap;
        font-weight: 500;
        src: local('EuclidCircularARegular'), url(${EuclidCircularAMedium}) format('woff2');
      }

      @font-face {
        font-family: 'EuclidCircularARegular';
        font-style: normal;
        font-display: swap;
        font-weight: 600;
        src: local('EuclidCircularASemibold'), url(${EuclidCircularASemibold}) format('woff2');
      }
      @font-face {
        font-family: 'EuclidCircularARegular';
        font-style: normal;
        font-display: swap;
        font-weight: 700;
        src: local('EuclidCircularASemibold'), url(${EuclidCircularABold}) format('woff2');
      }
    `,
    },
  };
};

export default CssBaseline;
