import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const NewWindowIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 2H22V22H2V2ZM4 8V20H20V8H19L18 5H14L13 8H4ZM6 4H4V6H6V4ZM7 4H9V6H7V4ZM12 4H10V6H12V4Z"
        />
      </SvgIcon>
    );
  }
);

NewWindowIcon.displayName = 'NewWindowIcon';

export default NewWindowIcon;
