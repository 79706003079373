import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ArrivalIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M6.50842 9.37522C7.54759 6.80802 10.0638 5 13 5C16.866 5 20 8.13401 20 12C20 15.866 16.866 19 13 19C10.0638 19 7.54759 17.192 6.50842 14.6248L4.65454 15.3752C5.98892 18.6717 9.22134 21 13 21C17.9705 21 22 16.9706 22 12C22 7.02944 17.9705 3 13 3C9.22134 3 5.98892 5.32832 4.65454 8.62478L6.50842 9.37522Z" />
        <path d="M17 12L12.2419 16.9999L10.8123 15.4977L13.1892 13.0001H1V11.0001H13.1894L10.8123 8.50227L12.2419 7.00009L17 12Z" />
      </SvgIcon>
    );
  }
);

ArrivalIcon.displayName = 'ArrivalIcon';

export default ArrivalIcon;
