import { FormControlLabel } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useLocalTranslation } from '@dizzbo/core/i18n/hooks/useLocalTranslation';
import {
  Box,
  Divider,
  FormControl,
  LanguageIcon,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@dizzbo/ui';

import {
  axiosAuthenticatedRequest,
  axiosRequest,
} from '@dizzbo/core/api/axios';

type Props = {};

export const Language: React.FC<Props> = () => {
  const { t, i18n } = useTranslation();
  const { lt } = useLocalTranslation();

  const availableLanguages = Object.keys(i18n.services.resourceStore.data);

  const currentLanguage = i18n.language;
  const index = availableLanguages.indexOf(currentLanguage);
  if (index > -1) {
    availableLanguages.splice(index, 1);
  }

  const handleChangeLanguage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const language = event.target.value;
    i18n.changeLanguage(language);
    axiosRequest.defaults.headers['Accept-Language'] = language;
    axiosAuthenticatedRequest.defaults.headers['Accept-Language'] = language;
  };

  return (
    <Box>
      <Stack spacing={6}>
        <Stack direction="row" spacing={2} alignItems="center">
          <LanguageIcon sx={{ width: 30, height: 30 }} />
          <Typography variant="h3">{t('language')}</Typography>
        </Stack>

        <Typography variant="bodyRegular">
          {t('yourCurrentlySelectedLanguage')}.
        </Typography>

        <FormControlLabel
          checked={true}
          value={currentLanguage}
          label={lt(`language${currentLanguage.toUpperCase()}`)}
          control={<Radio />}
        />
        <Divider />
        <Typography variant="bodyRegular">
          {t('otherAvailableLanguages')}.
        </Typography>

        <FormControl sx={{ marginLeft: -2 }}>
          <RadioGroup
            aria-labelledby="route-mode-radio-buttons-group"
            name="route-mode"
            // value={routeSettings.routeMode}
            onChange={handleChangeLanguage}
          >
            {availableLanguages.map((language) => (
              <FormControlLabel
                key={language}
                value={language}
                label={lt(`language${language.toUpperCase()}`)}
                control={<Radio />}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Stack>
    </Box>
  );
};
