import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const LanguageIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M19.6549 6.46549H17.7929V6H16.862V6.46549H15V7.39647H18.0926C17.9068 7.89396 17.5974 8.53817 17.1415 9.20167C16.7175 8.60859 16.4728 8.05936 16.3781 7.73241L15.4839 7.99152C15.6172 8.45168 15.957 9.19777 16.5504 9.96693C16.1697 10.406 15.7202 10.8319 15.1949 11.2071L15.736 11.9647C16.2862 11.5717 16.7617 11.1302 17.1692 10.6733C17.6557 11.1631 18.254 11.6289 18.9768 12L19.402 11.1718C18.7364 10.8301 18.1924 10.3949 17.7575 9.94303C18.4498 8.99137 18.8707 8.04769 19.0771 7.39647H19.6549V6.46549Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.25 3.25H22.75V14.75H20.25V17.2924L15.8008 14.75H11.25V3.25ZM21.25 13.25V4.75H12.75V13.25H16.1992L18.75 14.7076V13.25H21.25Z"
        />
        <path d="M10 7.25H2.25V18.75H4.75V21.2924L9.19917 18.75H13.75V16H12.25V17.25H8.80083L6.25 18.7076V17.25H3.75V8.75H10V7.25Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 16H6.32447L6.82713 14.7324H9.17287L9.67553 16H11L8.57447 10H7.42553L5 16ZM7.28989 13.5662L8 11.7577L8.71011 13.5662H7.28989Z"
        />
      </SvgIcon>
    );
  }
);

LanguageIcon.displayName = 'LanguageIcon';

export default LanguageIcon;
