import { DateRangeCalendar as MuiDateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import React, { ComponentProps, forwardRef } from 'react';

type MuiDateRangeCalendarProps = ComponentProps<typeof MuiDateRangeCalendar>;

export const DateRangeCalendar = forwardRef<
  HTMLDivElement,
  MuiDateRangeCalendarProps
>((props, ref) => <MuiDateRangeCalendar ref={ref} {...props} />);

DateRangeCalendar.displayName = 'DateRangeCalendar';
