import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CheckBoxIndeterminateIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props} ref={ref}>
        <g clip-path="url(#clip0_2811_3640)">
          <rect width="16" height="16" rx="4" fill="#3B6E71" />
          <rect x="4" y="7" width="8" height="2" fill="white" />
        </g>
        <defs>
          <clipPath id="clip0_2811_3640">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </SvgIcon>
    );
  }
);

CheckBoxIndeterminateIcon.displayName = 'CheckBoxIndeterminateIcon';

export default CheckBoxIndeterminateIcon;
