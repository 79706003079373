import { useQuery } from '@tanstack/react-query';

import { getOrderEvents } from '@core/api';
import { QueryKeys } from '@core/config';
import { UUIDType } from '@types';

export const useGetEvents = (orderUUID: UUIDType, options: object = {}) => {
  return useQuery({
    queryKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.ORDER_EVENTS],
    queryFn: () => getOrderEvents(orderUUID),
    ...options,
  });
};
