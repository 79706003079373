const toggleButton = (palette) => {
  return {
    toggleButton: {
      standard: {
        default: {
          borderColor: palette.oxford[20],
          color: palette.turquoise[150],
          backgroundColor: 'transparent',
        },
        hover: {
          borderColor: palette.oxford[20],
          color: palette.turquoise[150],
          backgroundColor: palette.turquoise[40],
        },
        active: {
          borderColor: palette.oxford[20],
          color: palette.oxford[100],
          backgroundColor: palette.turquoise[60],
        },
        disabled: {
          borderColor: palette.oxford[20],
          color: palette.oxford[25],
          backgroundColor: 'transparent',
        },
      },
    },
  };
};

export default toggleButton;
