import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { ComponentProps, forwardRef } from 'react';

type MuiDatePickerProps = ComponentProps<typeof MuiDatePicker>;

export const DatePicker = forwardRef<HTMLDivElement, MuiDatePickerProps>(
  (props, ref) => <MuiDatePicker ref={ref} {...props} />
);

DatePicker.displayName = 'DatePicker';
