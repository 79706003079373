import { useMutation } from '@tanstack/react-query';

import { deleteOrder } from '@core/api';
import { UUIDType } from '@types';

type Payload = UUIDType;

export const useDeleteOrder = () => {
  return useMutation({
    mutationFn: (values?: Payload) => deleteOrder(values),
    onSuccess: (data) => {},
  });
};
