import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ViewIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.8011 11.4014L23.2478 12L22.8015 12.598L22 12C22.8015 12.598 22.8006 12.5992 22.8006 12.5992L22.7993 12.601L22.7954 12.6061L22.7828 12.6228C22.7722 12.6367 22.7573 12.6562 22.7381 12.6809C22.6998 12.7302 22.6446 12.8003 22.5732 12.8881C22.4306 13.0638 22.2231 13.3112 21.9576 13.6063C21.4278 14.1953 20.6605 14.9815 19.7093 15.7699C17.8352 17.3233 15.1096 19 12 19C8.89048 19 6.16484 17.3233 4.29081 15.7699C3.3396 14.9815 2.57232 14.1953 2.04245 13.6063C1.77693 13.3112 1.56951 13.0638 1.42688 12.8881C1.35552 12.8003 1.30027 12.7302 1.26197 12.6809C1.24281 12.6562 1.22788 12.6367 1.21729 12.6228L1.20465 12.6061L1.20079 12.601L1.19858 12.598L2.00004 12C1.19858 11.402 1.19947 11.4008 1.19947 11.4008L1.20079 11.399L1.20465 11.3939L1.21729 11.3772C1.22788 11.3633 1.24281 11.3438 1.26197 11.3191C1.30027 11.2698 1.35552 11.1997 1.42688 11.1119C1.56951 10.9362 1.77693 10.6888 2.04245 10.3937C2.57232 9.8047 3.3396 9.01854 4.29081 8.2301C6.16484 6.67673 8.89048 5 12 5C15.1096 5 17.8352 6.67673 19.7093 8.2301C20.6605 9.01854 21.4278 9.8047 21.9576 10.3937C22.2231 10.6888 22.4306 10.9362 22.5732 11.1119C22.6446 11.1997 22.6998 11.2698 22.7381 11.3191C22.7573 11.3438 22.7722 11.3633 22.7828 11.3772L22.7954 11.3939L22.7993 11.399L22.8011 11.4014ZM3.52933 12.2687C3.44313 12.1729 3.36399 12.083 3.29223 12C3.36399 11.917 3.44313 11.8271 3.52933 11.7313C4.01152 11.1953 4.70869 10.4815 5.56713 9.7699C7.31239 8.32327 9.58675 7 12 7C14.4133 7 16.6877 8.32327 18.4329 9.7699C19.2914 10.4815 19.9886 11.1953 20.4707 11.7313C20.5569 11.8271 20.6361 11.917 20.7078 12C20.6361 12.083 20.5569 12.1729 20.4707 12.2687C19.9886 12.8047 19.2914 13.5185 18.4329 14.2301C16.6877 15.6767 14.4133 17 12 17C9.58675 17 7.31239 15.6767 5.56713 14.2301C4.70869 13.5185 4.01152 12.8047 3.52933 12.2687ZM22 12C22.8015 11.402 22.8011 11.4014 22.8011 11.4014L22 12Z"
        />
        <path d="M1.19858 11.402L2.00004 12C1.2035 12.5944 1.19858 12.598 1.19858 12.598L0.752319 12L1.19858 11.402Z" />
      </SvgIcon>
    );
  }
);

ViewIcon.displayName = 'ViewIcon';

export default ViewIcon;
