import { alpha } from '@mui/material/styles';

const alert = (palette) => {
  return {
    alert: {
      default: {
        color: palette.oxford[40],
      },
      error: {
        color: palette.red[100],
        borderColor: palette.red[100],
      },
      warning: {
        color: palette.orange[100],
        borderColor: palette.orange[100],
      },
      success: {
        color: palette.forest[100],
        borderColor: palette.forest[100],
      },
      info: {
        color: palette.blue[100],
        borderColor: palette.blue[100],
      },
      neutral: {
        color: palette.oxford[25],
        borderColor: alpha(palette.oxford[20], 0.25),
      },
    },
  };
};

export default alert;
