import { Paper as MuiPaper } from '@mui/material';
import { PaperPropsVariantOverrides } from '@mui/material/Paper';
import { OverridableStringUnion } from '@mui/types';
import React, { ComponentProps, FC } from 'react';

interface PaperPropsOverrides {
  /**
   * The variant to use.
   * @default 'elevation'
   */
  variant?: OverridableStringUnion<
    | 'elevation'
    | 'outlined'
    | 'filled'
    | 'filled-primary'
    | 'filled-secondary'
    | 'filled-secondary-light'
    | 'filled-disabled'
    | 'filled-turquoise'
    | 'filled-green',
    PaperPropsVariantOverrides
  >;
}

type MuiPaperProps = ComponentProps<typeof MuiPaper>;

type PaperProps = Omit<MuiPaperProps, 'variant'> & PaperPropsOverrides;

export const Paper: FC<PaperProps> = (props) => <MuiPaper {...props} />;
