import { alpha } from '@mui/material/styles';

const link = (palette) => {
  return {
    link: {
      textLinkRegular: {
        default: {
          color: palette.turquoise[150],
        },
        hover: {
          color: palette.turquoise[150],
        },
        active: {
          color: alpha(palette.turquoise[150], 0.5),
        },
        focus: {
          color: palette.turquoise[150],
        },
      },
      textLinkRegularContrast: {
        default: {
          color: palette.turquoise[100],
        },
        hover: {
          color: palette.turquoise[100],
        },
        active: {
          color: alpha(palette.turquoise[100], 0.5),
        },
        focus: {
          color: palette.turquoise[100],
        },
      },
      textLinkRegularDesctructive: {
        default: {
          color: palette.red[100],
        },
        hover: {
          color: palette.red[100],
        },
        active: {
          color: alpha(palette.red[100], 0.5),
        },
        focus: {
          color: palette.red[100],
        },
      },
      textLinkRegularContrastDesctructive: {
        default: {
          color: palette.red[80],
        },
        hover: {
          color: palette.red[80],
        },
        active: {
          color: alpha(palette.red[80], 0.5),
        },
        focus: {
          color: palette.turquoise[80],
        },
      },
    },
  };
};

export default link;
