import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@dizzbo/ui';
import { DizzboLogoIcon } from '@dizzbo/ui';

const NavItemWrapperStyles = styled(Box)(({ theme }) => ({
  paddingTop: 12,
  paddingBottom: 12,
  borderBottom: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
  height: 64,
}));

const NavItemStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: 40,
  minHeight: 40,
  padding: 0,
  textAlign: 'left',
  justifyContent: 'start',
  cursor: 'pointer',
  overflowX: 'hidden',
  ...theme.typography.buttonRegular,
  color: theme.palette.turquoise[100],
  textDecoration: 'none',
  backgroundColor: 'transparent',
  transition: theme.transitions.create('background'),
}));

const IconWrapperStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})<any>(({ theme, expanded }) => {
  return {
    marginLeft: 20,
    marginRight: 20,
    height: 24,
    width: 24,
  };
});

interface AppBarNavItemToggleProps {
  handleClick?: () => void;
}

export const AppBarNavItemToggle = ({
  handleClick,
}: AppBarNavItemToggleProps) => {
  return (
    <NavItemWrapperStyles>
      <NavItemStyles onClick={() => handleClick()}>
        <IconWrapperStyles>
          <DizzboLogoIcon sx={{ width: 24, height: 24 }} />
        </IconWrapperStyles>
      </NavItemStyles>
    </NavItemWrapperStyles>
  );
};
