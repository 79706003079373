import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createOrderStop } from '@core/api';
import { QueryKeys } from '@core/config';
import { UUIDType } from '@types';

type Payload = {
  loadingPoint: UUIDType;
};

export const useCreateOrderStop = (orderUUID: UUIDType) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values: Payload) => createOrderStop(orderUUID, values),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.STOPS],
      });
    },
  });
};
