import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TurnoverIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M18.3805 6.88401L16.8856 8.21276C16.374 7.6297 15.7583 7.13861 15.0641 6.76752C13.7378 6.05863 12.2068 5.83153 10.7319 6.12491C9.257 6.41829 7.92944 7.21399 6.97543 8.37645C6.34403 9.14582 5.90211 10.0443 5.67544 11H7.5L4.5 15L1.5 11H3.63338C3.88824 9.57856 4.50278 8.23676 5.42941 7.10766C6.67696 5.58753 8.413 4.54699 10.3417 4.16334C12.2705 3.77969 14.2726 4.07667 16.0069 5.00368C16.9109 5.48691 17.7131 6.12575 18.3805 6.88401Z" />
        <path d="M5.50001 17.116L6.99485 15.7872C7.50651 16.3703 8.12216 16.8614 8.81642 17.2325C10.1427 17.9414 11.6737 18.1685 13.1486 17.8751C14.6235 17.5817 15.9511 16.786 16.9051 15.6235C17.5365 14.8542 17.9784 13.9557 18.2051 13H16.3805L19.3805 9L22.3805 13H20.2471C19.9923 14.4214 19.3777 15.7632 18.4511 16.8923C17.2035 18.4125 15.4675 19.453 13.5388 19.8367C11.61 20.2203 9.60794 19.9233 7.87362 18.9963C6.96956 18.5131 6.16742 17.8742 5.50001 17.116Z" />
        <path d="M8.8293 12.752H8L8.18726 12.2943L8.74904 12.2943C8.74013 12.1962 8.73121 12.0981 8.73121 12C8.73121 11.8937 8.74013 11.7956 8.74904 11.6975H8L8.18726 11.2316H8.8293C9.1949 9.91553 10.4611 9 12.0217 9C13.3771 9 14.5006 9.66213 15 10.8147H13.6C13.2166 10.3161 12.6904 10.1035 12.0306 10.1035C11.1121 10.1035 10.4166 10.5613 10.1223 11.2316H13.2968L13.1185 11.6975H9.98853C9.9707 11.7956 9.96178 11.8937 9.96178 12C9.96178 12.0981 9.9707 12.1962 9.97962 12.2943H12.8866L12.7172 12.752H10.1134C10.4076 13.4305 11.1032 13.8965 12.0306 13.8965C12.6904 13.8965 13.2166 13.6839 13.6 13.1853H15C14.5006 14.3379 13.3771 15 12.0217 15C10.4611 15 9.17707 14.0763 8.8293 12.752Z" />
      </SvgIcon>
    );
  }
);

TurnoverIcon.displayName = 'TurnoverIcon';

export default TurnoverIcon;
