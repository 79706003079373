import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SecurityIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M7 16V14H9V16H7Z" />
        <path d="M13 14H11V16H13V14Z" />
        <path d="M17 16V14H15V16H17Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5 0C9.01472 0 7 2.01472 7 4.5V8C4.79086 8 3 9.79086 3 12V18C3 20.2091 4.79086 22 7 22H17C19.2091 22 21 20.2091 21 18V12C21 9.79086 19.2091 8 17 8V4.5C17 2.01472 14.9853 0 12.5 0H11.5ZM15 8V4.5C15 3.11929 13.8807 2 12.5 2H11.5C10.1193 2 9 3.11929 9 4.5V8H15ZM7 10C5.89543 10 5 10.8954 5 12V18C5 19.1046 5.89543 20 7 20H17C18.1046 20 19 19.1046 19 18V12C19 10.8954 18.1046 10 17 10H7Z"
        />
      </SvgIcon>
    );
  }
);

SecurityIcon.displayName = 'SecurityIcon';

export default SecurityIcon;
