import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const LoadingAndUnloadingIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 7.00001L8 3.00001H10V13.5H4V12.5L8 12.5V9.00001H4.58032L3 11.7656L3 16.5L5.33681 16.5C5.89854 15.3175 7.1038 14.5 8.5 14.5C9.8962 14.5 11.1015 15.3175 11.6632 16.5L14.3368 16.5C14.8985 15.3175 16.1038 14.5 17.5 14.5C18.8962 14.5 20.1014 15.3175 20.6632 16.5L21.5 16.5V14.5H23.5V18.5L20.9646 18.5C20.7219 20.1962 19.2632 21.5 17.5 21.5C15.7368 21.5 14.2781 20.1962 14.0354 18.5L11.9646 18.5C11.7219 20.1962 10.2632 21.5 8.5 21.5C6.73677 21.5 5.27807 20.1962 5.03545 18.5L1 18.5V11.2344L2.84825 8.00001H2L2 7.00001L8 7.00001ZM17.5 16.5C16.6716 16.5 16 17.1716 16 18C16 18.8284 16.6716 19.5 17.5 19.5C18.3284 19.5 19 18.8284 19 18C19 17.1716 18.3284 16.5 17.5 16.5ZM10 18C10 18.8284 9.32843 19.5 8.5 19.5C7.67157 19.5 7 18.8284 7 18C7 17.1716 7.67157 16.5 8.5 16.5C9.32843 16.5 10 17.1716 10 18Z"
        />
        <path d="M23.4853 7.00001H17.8284L19.9497 9.12133L17 12.0711L18.4142 13.4853L21.364 10.5355L23.4853 12.6569V7.00001Z" />
        <path d="M17.6568 8.48528L11.9999 8.48528L11.9999 2.82843L14.1213 4.94975L17.071 2L18.4852 3.41421L15.5355 6.36396L17.6568 8.48528Z" />
      </SvgIcon>
    );
  }
);

LoadingAndUnloadingIcon.displayName = 'LoadingAndUnloadingIcon';

export default LoadingAndUnloadingIcon;
