import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DoubleArrowDownIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M7 6.42954L11.9999 11.1877L16.9998 6.42954L15.4976 5L11.9999 8.32859L8.50218 5L7 6.42954Z" />
        <path d="M7 15.2419L11.9999 20L16.9998 15.2419L15.4976 13.8123L11.9999 17.1409L8.50218 13.8123L7 15.2419Z" />
      </SvgIcon>
    );
  }
);

DoubleArrowDownIcon.displayName = 'DoubleArrowDownIcon';

export default DoubleArrowDownIcon;
