import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, DialogContent, DialogTitle, Typography } from '@dizzbo/ui';

import { EventList } from './EventList';

type Props = {
  popupState: PopupState;
};

export const HistoryDialog: React.FC<Props> = ({ popupState }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      aria-labelledby="draggable-dialog-title"
      scroll="paper"
      fullWidth
      maxWidth="md"
      {...bindDialog(popupState)}
    >
      <DialogTitle onClose={popupState.close} id="draggable-dialog-title">
        <Typography variant="h3" color="primary">
          {t('history')}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        {popupState.isOpen && <EventList />}
      </DialogContent>
    </Dialog>
  );
};
