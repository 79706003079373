import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TourPlannerIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M13.0346 11.486L12.1162 12.2732L11.4654 11.5139L12.3838 10.7267L13.0346 11.486Z" />
        <path d="M11.2683 13L11.2846 12.986L10.6338 12.2267L9.73172 13L10.6338 13.7732L11.2846 13.0139L11.2683 13Z" />
        <path d="M12.1162 13.7267L13.0346 14.5139L12.3838 15.2732L11.4654 14.486L12.1162 13.7267Z" />
        <path d="M13.8662 15.2268L14.8129 16.0382L13.8026 16.7513L13.3343 16.0879L13.2154 15.986L13.2412 15.956L13.2259 15.9344L13.3115 15.874L13.8662 15.2268Z" />
        <path d="M13.0741 17.2656L12.1026 17.9513L11.5259 17.1344L12.4974 16.4486L13.0741 17.2656Z" />
        <path d="M11.3741 18.4656L10.4026 19.1513L9.82591 18.3344L10.7974 17.6486L11.3741 18.4656Z" />
        <path d="M9.67411 19.6656L8.7026 20.3513L8.12591 19.5344L9.09743 18.8486L9.67411 19.6656Z" />
        <path d="M7.97411 20.8656L7.0026 21.5513L6.42591 20.7344L7.39743 20.0486L7.97411 20.8656Z" />
        <path d="M6.27411 22.0656L5.78835 22.4084L5.21167 21.5915L5.69743 21.2486L6.27411 22.0656Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 8.87307C2.79369 8.87307 1 10.6665 1 12.8851C1 14.8464 2.20986 17.3721 3.73866 19.6705C4.15601 20.2979 4.5747 20.8757 4.95965 21.3806C5.36351 20.8326 5.80657 20.2032 6.24815 19.5235C6.98485 18.3896 7.69194 17.1547 8.20865 15.9525C8.73674 14.7238 9 13.6794 9 12.8851C9 10.6665 7.20631 8.87307 5 8.87307ZM5 14.2064C5.73638 14.2064 6.33333 13.6095 6.33333 12.8731C6.33333 12.1367 5.73638 11.5397 5 11.5397C4.26362 11.5397 3.66667 12.1367 3.66667 12.8731C3.66667 13.6095 4.26362 14.2064 5 14.2064Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.7662 0.679245L23.0088 4.92189L17.4471 10.4836L12.6741 11.014L13.2044 6.24099L18.7662 0.679245ZM18.7662 3.50767L15.1147 7.15913L14.938 8.75012L16.5289 8.57334L20.1804 4.92189L18.7662 3.50767Z"
        />
      </SvgIcon>
    );
  }
);

TourPlannerIcon.displayName = 'TourPlannerIcon';

export default TourPlannerIcon;
