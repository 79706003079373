import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import React from 'react';
import { useLinkClickHandler } from 'react-router-dom';

import {
  Box,
  Card,
  ChevronRightIcon,
  Chip,
  Divider,
  LicensePlate,
  Stack,
  TrailerIcon,
  TruckIcon,
  Typography,
} from '@dizzbo/ui';
import { TourType } from '@types';

const ContainerStyles = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingLeft: 24,
  paddingRight: 24,
  marginBottom: 12,
  '&:first-of-type': {
    marginTop: 12,
  },
  '&:hover': {
    cursor: 'pointer',
  },
}));

const CardContentStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: 8,
  paddingRight: 12,
  marginBottom: 8,
  paddingLeft: 12,
}));

const InfoStyles = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingRight: 12,
}));

const StopsContainerStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

const StopStyles = styled(Box)(({ theme }) => ({
  textAlign: 'left',
  '&:last-child': {
    textAlign: 'right',
  },
}));

const AddressStyles = styled(Box)(({ theme }) => ({
  // whiteSpace: 'nowrap',
  // overflow: 'hidden',
  // textOverflow: 'ellipsis',
  ...theme.typography.tiny,
  marginBottom: 2,
}));

const DateStyles = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ...theme.typography.tiny,
}));

const ActionInfoStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderLeft: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
  justifyContent: 'center',
  alignItems: 'center',
  paddingLeft: 12,
}));

const VehicleStyles = styled(Stack)(({ theme }) => ({
  alignItems: 'center ',
}));
const CarrierStyles = styled(Stack)(({ theme }) => ({
  ...theme.typography.tiny,
  lineHeight: 1,
  marginTop: 4,
}));

type Props = {
  tour: TourType;
};

export const ListItem: React.FC<Props> = ({ tour }) => {
  const handleClickCard = useLinkClickHandler(`/realtime/${tour.uuid}`);

  return (
    <ContainerStyles onClick={handleClickCard}>
      <Card
        variant="filled-secondary"
        elevation={1}
        sx={{
          width: '100%',
        }}
      >
        <CardContentStyles>
          <InfoStyles>
            <Typography variant="bodyBold">{tour.reference}</Typography>
            <StopsContainerStyles>
              <StopStyles>
                <AddressStyles>
                  {tour.origin
                    ? `${tour.origin?.loadingPoint.address.country}, ${tour.origin?.loadingPoint.name}`
                    : '-'}
                </AddressStyles>
                <DateStyles>
                  {tour.origin?.requestedDate
                    ? dayjs(tour.origin?.requestedDate).format('MMM DD, hh:mm')
                    : '-'}
                </DateStyles>
              </StopStyles>
              <StopStyles>
                <AddressStyles>
                  {tour.destination
                    ? `${tour.destination?.loadingPoint.address.country}, ${tour.destination?.loadingPoint.name}`
                    : '-'}
                </AddressStyles>
                <DateStyles>
                  {tour.destination?.requestedDate
                    ? dayjs(tour.destination?.requestedDate).format(
                        'MMM DD, hh:mm'
                      )
                    : '-'}
                </DateStyles>
              </StopStyles>
            </StopsContainerStyles>
            <Divider sx={{ marginY: 2 }} />
            <VehicleStyles spacing={2} direction="row">
              <Chip label="on time" size="small" variant="forest" />{' '}
              <Stack direction="row">
                <TruckIcon sx={{ width: 16, height: 16 }} />
                <TrailerIcon sx={{ width: 16, height: 16 }} />
              </Stack>
              <LicensePlate number={tour?.vehicle?.licensePlate} size="small" />
            </VehicleStyles>
            <CarrierStyles>{tour.carrier && tour.carrier.name}</CarrierStyles>
          </InfoStyles>
          <ActionInfoStyles>
            <ChevronRightIcon sx={{ width: 16, height: 16 }} />
          </ActionInfoStyles>
        </CardContentStyles>
      </Card>
    </ContainerStyles>
  );
};
