import { SxProps } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';

import { getCountries } from '@core/api/countries';

import { QueryKeys } from '@core/config';
import { Autocomplete } from '@dizzbo/ui/components/Autocomplete';
import { Box } from '@dizzbo/ui/components/Box';
import { TextField } from '@dizzbo/ui/components/TextField';
import { CountryType } from '@types';

/**
 * Get the flag character from the country code
 * @param {string} countryCode - The country code
 * @return {string}
 */
const getFlagCharacter = (countryCode: string) => {
  if (!countryCode) {
    return '';
  }
  // Source: https://github.com/thekelvinliu/country-code-emoji/blob/master/src/index.js
  const offset = 127397;
  if (typeof countryCode !== 'string')
    throw new TypeError('argument must be a string');
  const cc = countryCode.toUpperCase();
  return /^[A-Z]{2}$/.test(cc)
    ? String.fromCodePoint(...[...cc].map((c) => c.charCodeAt(0) + offset))
    : null;
};

type Props = {
  label: string;
  id?: string;
  value?: any;
  onChange?: (country: CountryType) => void;
  required?: boolean;
  error?: any;
  helperText?: string;
  sx?: SxProps;
};

export const CountryAutocomplete = React.forwardRef<HTMLElement, Props>(
  ({ id, value, onChange }, ref) => {
    const [open, setOpen] = React.useState(false);

    const { isFetching, data } = useQuery({
      queryKey: [QueryKeys.LOADING_POINTS],
      queryFn: () => getCountries(),
      enabled: open,
    });

    return (
      <Autocomplete
        ref={ref}
        id={id}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        value={value}
        options={data || []}
        loading={isFetching}
        autoHighlight
        blurOnSelect={true}
        getOptionLabel={(option) =>
          `${getFlagCharacter(option.code)} ${option.name}`
        }
        onChange={(
          event: any,
          newValue: CountryType | null,
          reason: string
        ) => {
          onChange(newValue);
        }}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <Box sx={{ marginRight: 2 }}>{getFlagCharacter(option.code)}</Box>{' '}
            {option.name} {option.code}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Choose a country"
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
      />
    );
  }
);

CountryAutocomplete.displayName = 'CountryAutocomplete';
