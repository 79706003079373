import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ResetIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M10.8417 3.16334C12.7705 2.77969 14.7726 3.07667 16.5069 4.00368C18.2412 4.93069 19.6004 6.43037 20.353 8.2472C21.1055 10.064 21.2048 12.0856 20.634 13.9674C20.0631 15.8493 18.8574 17.4749 17.2223 18.5675C15.5872 19.66 13.6239 20.1518 11.6669 19.9591C9.7098 19.7663 7.88013 18.9009 6.48959 17.5104L7.9038 16.0962C8.96716 17.1595 10.3663 17.8213 11.8629 17.9687C13.3595 18.1161 14.8608 17.74 16.1112 16.9045C17.3616 16.0691 18.2836 14.8259 18.7201 13.3868C19.1566 11.9478 19.0807 10.4019 18.5052 9.01256C17.9297 7.62323 16.8903 6.47641 15.5641 5.76752C14.2378 5.05863 12.7068 4.83153 11.2319 5.12491C9.757 5.41829 8.42944 6.21399 7.47543 7.37645C6.84403 8.14582 6.40211 9.04433 6.17544 10H9L5 14L1 10H4.13338C4.38824 8.57856 5.00278 7.23676 5.92941 6.10766C7.17696 4.58753 8.913 3.54699 10.8417 3.16334Z" />
      </SvgIcon>
    );
  }
);

ResetIcon.displayName = 'ResetIcon';

export default ResetIcon;
