import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box } from '@dizzbo/ui';

export const View = forwardRef(
  ({ children, title = '', ...other }: any, ref): any => {
    return (
      <Box ref={ref} {...other}>
        <Helmet>
          <title>Dizzbo TMS | {title}</title>
        </Helmet>
        {children}
      </Box>
    );
  }
);

View.displayName = 'View';
