import { CircularProgress as MuiCircularProgress } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiCircularProgressProps = ComponentProps<typeof MuiCircularProgress>;

export const CircularProgress = forwardRef<
  HTMLDivElement,
  MuiCircularProgressProps
>((props, ref) => <MuiCircularProgress ref={ref} {...props} />);

CircularProgress.displayName = 'CircularProgress';
