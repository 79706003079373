import { styled, useTheme } from '@mui/material/styles';
import PopupState, { bindTrigger } from 'material-ui-popup-state';
import React from 'react';

import { AvatarListItem, Card, EditIcon, IconButton, Stack } from '@dizzbo/ui';

import { UserType, UUIDType } from '@types';
import { EditAssigneesDialog } from './EditAssigneesDialog';
const AssigneeListItemWrapperStyles = styled(Card)(({ theme }) => ({
  display: 'flex',
  backgroundColor: 'transparent',
  position: 'relative',
  width: '100%',
  height: 56,
  paddingTop: 12,
  paddingBottom: 12,
  alignItems: 'center',
}));

type Props = {
  orderUUID: UUIDType;
  assignee: UserType;
};

export const AssigneeListItem: React.FC<Props> = ({
  orderUUID,
  assignee,
}: Props) => {
  const theme = useTheme();

  return (
    <AssigneeListItemWrapperStyles>
      <AvatarListItem
        username={assignee?.displayName}
        avatarSx={{
          width: 32,
          height: 32,
          ...theme.typography.bodyBoldSmall,
        }}
      />
      <Stack sx={{ marginLeft: 'auto' }}>
        <PopupState variant="popover" popupId="participant-menu">
          {(popupState) => (
            <React.Fragment>
              <IconButton
                size="medium"
                variant="tertiary"
                {...bindTrigger(popupState)}
              >
                <EditIcon />
              </IconButton>
              <EditAssigneesDialog
                key={assignee.uuid}
                orderUUID={orderUUID}
                popupState={popupState}
                assignees={[assignee]}
              />
            </React.Fragment>
          )}
        </PopupState>
      </Stack>
    </AssigneeListItemWrapperStyles>
  );
};
