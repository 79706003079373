import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const MailIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23 4H1V20H23V4ZM21 15.7143V7.24539L16.8544 10.9764L21 15.7143ZM15.3677 12.3145L12 15.3454L8.68775 12.3643L3.85717 18H20.3425L15.3677 12.3145ZM3 7.24535V15.9268L7.20072 11.026L3 7.24535ZM4.60597 6L12 12.6546L19.3941 6H4.60597Z"
        />
      </SvgIcon>
    );
  }
);

MailIcon.displayName = 'MailIcon';

export default MailIcon;
