import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CommunicationIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 3H2V14.5H4.5V17.118L9.73607 14.5H18V3ZM9.26393 12.5L6.5 13.882V12.5H4V5H16V12.5H9.26393Z"
        />
        <path d="M19 9H20.5V17H18.5V18.382L15.7361 17H13V15.5H11V19H15.2639L20.5 21.618V19H22.5V7H19V9Z" />
      </SvgIcon>
    );
  }
);

CommunicationIcon.displayName = 'CommunicationIcon';

export default CommunicationIcon;
