import React, { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '@dizzbo/core/hooks';
import { LoadingScreen } from '@dizzbo/ui';

export const GuestGuard: React.FC<PropsWithChildren> = ({ children }) => {
  const { isLoading, user } = useAuth();
  const { state } = useLocation();

  if (user) {
    return <Navigate to={state?.previousPath ?? '/'} />;
  }
  if (isLoading) {
    return <LoadingScreen />;
  }
  return children;
};
