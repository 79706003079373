import React from 'react';
import { useTheme } from '@mui/material/styles';

import { Box, Stack } from '@dizzbo/ui';
import Pagination from './Pagination';
import RowNumberSelector from './RowNumberSelector';
import LinearProgressBar from './LinearProgressBar';

interface TablePaginationBarProps {
  rowCount: number;
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  setPagination?: any;
  isLoading?: boolean;
  labelDisplayedRows?: any;
  labelRowsPerPage?: string;
  rowsPerPageOptions?: number[];
}

export const TablePaginationBar = ({
  rowCount,
  pagination,
  isLoading = true,
  labelDisplayedRows,
  labelRowsPerPage,
  rowsPerPageOptions = [5, 10, 15, 20, 25, 30, 50, 100],
  setPagination,
}: TablePaginationBarProps) => {
  const theme = useTheme();
  const pageCount = Math.ceil(rowCount / pagination.pageSize);

  return (
    <Box
      sx={{
        position: 'relative',
        height: 56,
        background: theme.styles.tablePagination.default.backgroundColor,
        boxShadow: theme.shadows[3],
        zIndex: theme.zIndex.appBar,
      }}
    >
      <LinearProgressBar isLoading={isLoading} />
      <Stack direction={'row'} alignItems='center' height='100%' paddingX={6}>
        <RowNumberSelector
          labelRowsPerPage={labelRowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          setPagination={setPagination}
          pageSize={pagination.pageSize}
        />
        <Stack
          direction={'row'}
          spacing={2}
          justifyContent='center'
          flexGrow={1}
          sx={{ marginRight: 58 }}
        >
          <Pagination
            rowCount={rowCount}
            pageIndex={pagination.pageIndex}
            pageCount={pageCount}
            pageSize={pagination.pageSize}
            setPagination={setPagination}
          />
        </Stack>
      </Stack>
    </Box>
  );
};
