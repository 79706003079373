/* eslint-disable camelcase */

import React from 'react';
import { useTheme } from '@mui/material/styles';
import { TableInstance } from '@dizzbo/ui/components/Table/types';

import { Box, Stack, Button, IconButton, Chip, Divider } from '@dizzbo/ui';
import {
  FullscreenIcon,
  FullscreenExitIcon,
  ChevronDownIcon,
  DateIcon,
} from '@dizzbo/ui';
import { TourListSearch } from '../TourListSearch';

import { TableShowHideColumnsButton } from './TableShowHideColumnsButton';

type TableFilterAndSearchBarProps = {
  table: TableInstance;
  setSearchValue: (value: string) => void;
};

export const TableFilterAndSearchBar: React.FC<any> = ({
  table,
  setSearchValue,
}: TableFilterAndSearchBarProps) => {
  const theme = useTheme();
  const { getState } = table;
  const { isFullScreen } = getState();

  return (
    <>
      <TourListSearch setSearchValue={setSearchValue} />
      <Box
        sx={{
          flexGrow: 1,
          height: 56,
          background: theme.styles.tableSearchBar.default.backgroundColor,
          borderBottom: `1px solid ${theme.styles.tableSearchBar.default.borderColor}`,
          zIndex: theme.zIndex.appBar + 2,
        }}
      >
        <Stack
          direction={'row'}
          alignItems="center"
          // justifyContent="center"
          height="100%"
          paddingX={6}
        >
          <Box sx={{ width: '100%' }}>
            <Stack direction={'row'} alignItems="center" spacing={2}>
              <Button
                variant="secondary"
                size="medium"
                startIcon={
                  <>
                    <DateIcon />
                  </>
                }
                endIcon={
                  <>
                    <ChevronDownIcon />
                  </>
                }
              >
                Aug 19 - Aug 30
              </Button>
              <Button
                variant="secondary"
                size="medium"
                startIcon={
                  <>
                    <DateIcon />
                  </>
                }
                endIcon={
                  <>
                    <Chip label="15" size="small" variant="turquoise-175" />
                    <ChevronDownIcon />
                  </>
                }
              >
                Order Planning
              </Button>
              <Button
                variant="secondary"
                size="medium"
                startIcon={
                  <>
                    <DateIcon />
                  </>
                }
                endIcon={
                  <>
                    <Chip label="15" size="small" variant="turquoise-175" />
                    <ChevronDownIcon />
                  </>
                }
              >
                Order Execution
              </Button>
            </Stack>
          </Box>
          <Stack
            direction={'row'}
            alignItems="center"
            spacing={2}
            divider={
              <Divider
                sx={{ marginTop: 10, marginBottom: 10 }}
                orientation="vertical"
                flexItem
              />
            }
          >
            <TableShowHideColumnsButton table={table} />

            <IconButton
              variant="tertiary"
              onClick={() => table.setIsFullScreen(!isFullScreen)}
            >
              {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default TableFilterAndSearchBar;
