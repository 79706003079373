import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, IconBullet } from '@dizzbo/ui';

interface CreateOrderFormSectionProps {
  sectionNumber?: number;
  lastSection?: boolean;
  children?: React.ReactNode;
}

export const CreateOrderFormSection: React.FC<CreateOrderFormSectionProps> = ({
  sectionNumber,
  lastSection,
  children,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        marginBottom: 6,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          alignItems: 'stretch',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            paddingTop: '11px',
            paddingRight: 6,
            paddingBottom: '11px',
            paddingLeft: 0,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconBullet size="medium">{sectionNumber}</IconBullet>
        </Box>
        {lastSection ? null : (
          <Box
            sx={{
              height: '100%',
              backgroundRepeat: 'repeat-y',
              backgroundPosition: '16px top',
              backgroundSize: '2px 9px',
              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="9" width="2" viewBox="0 0 2 9"><path fill="${encodeURIComponent(
                theme.palette.primary.main
              )}" d="M1,8.5L1,8.5c-0.55,0-1-0.45-1-1l0-1c0-0.55,0.45-1,1-1h0c0.55,0,1,0.45,1,1v1C2,8.05,1.55,8.5,1,8.5z"/></svg>')`,
            }}
          ></Box>
        )}
      </Box>

      {children}
    </Box>
  );
};
