import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

import {
  Box,
  TextField,
  IconButton,
  MinimalIconButton,
  InputAdornment,
  RemoveIcon,
  SearchIcon,
} from '@dizzbo/ui';
import { useWorkspaces } from '@dizzbo/core/hooks';

export const Search: React.FC = () => {
  const { t } = useTranslation();

  const { activeWorkspaceUUID, setWorkspaceSettings, activeWorkspace } =
    useWorkspaces();

  const [searchValue, setSearchValue] = useState(
    activeWorkspace?.settings?.filters?.search || ''
  );

  const debouncedSetWorkspaceSettings = useCallback(
    debounce((searchString) => {
      setWorkspaceSettings(activeWorkspaceUUID, 'filters', {
        search: searchString,
      });
    }, 1000),
    []
  );

  const handleChange = (event) => {
    debouncedSetWorkspaceSettings(event.currentTarget.value);
    setSearchValue(event.currentTarget.value);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: '100%',
        display: 'flex',
      }}
    >
      <TextField
        label={t('search')}
        value={searchValue}
        onChange={handleChange}
        fullWidth
        InputProps={{
          endAdornment: (
            <>
              {searchValue && (
                <InputAdornment position="end">
                  <MinimalIconButton
                    sx={{ opacity: 0.5 }}
                    onClick={() => {
                      setSearchValue('');
                      setWorkspaceSettings(activeWorkspaceUUID, 'filters', {
                        search: '',
                      });
                    }}
                  >
                    <RemoveIcon />
                  </MinimalIconButton>
                </InputAdornment>
              )}
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            </>
          ),
        }}
      />
    </Box>
  );
};
