import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TodoIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 2H3V10H11V2ZM6 8.56066L10.0303 4.53033L8.96967 3.46967L6 6.43934L5.03033 5.46967L3.96967 6.53033L6 8.56066Z"
        />
        <path d="M22 5H13V7H22V5Z" />
        <path d="M22 16H13V18H22V16Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 13H3V21H11V13ZM5 19V15H9V19H5Z"
        />
      </SvgIcon>
    );
  }
);

TodoIcon.displayName = 'TodoIcon';

export default TodoIcon;
