import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SwitchIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9999 4L16.9998 8.75813L15.4976 10.1877L11.9999 6.85909L8.50218 10.1877L7 8.75813L11.9999 4Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 20L7.00009 15.2419L8.50227 13.8123L12 17.1409L15.4977 13.8123L16.9999 15.2419L12 20Z"
        />
      </SvgIcon>
    );
  }
);

SwitchIcon.displayName = 'SwitchIcon';

export default SwitchIcon;
