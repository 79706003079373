import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ReplyIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 2.44237V9H13C14.7004 9 16.8026 9.56149 18.5622 10.9691C20.3481 12.3979 21.7246 14.6547 21.9965 17.917L22.304 21.6067L20.1807 18.5735L20.1761 18.567L20.1519 18.5335C20.1292 18.5024 20.0938 18.4546 20.0463 18.3924C19.9511 18.2678 19.8076 18.0861 19.6208 17.8664C19.2461 17.4255 18.7031 16.8388 18.0313 16.2546C16.6596 15.0618 14.8899 14 13 14H10V20.5576L1.63904 11.5L10 2.44237ZM18.9074 14.3799C18.4577 13.6128 17.9064 13.0058 17.3128 12.5309C15.9474 11.4385 14.2996 11 13 11H8.00001V7.55763L4.36087 11.5L8.00001 15.4424V12H13C15.3682 12 17.4238 13.184 18.9074 14.3799Z"
        />
      </SvgIcon>
    );
  }
);

ReplyIcon.displayName = 'ReplyIcon';

export default ReplyIcon;
