import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CancelIcon from '@mui/icons-material/Cancel';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import DensityLargeIcon from '@mui/icons-material/DensityLarge';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import DensitySmallIcon from '@mui/icons-material/DensitySmall';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PushPinIcon from '@mui/icons-material/PushPin';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import SortIcon from '@mui/icons-material/Sort';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Icons } from './types';

export const defaultIcons: Icons = {
  ArrowDownwardIcon,
  ArrowRightIcon,
  CancelIcon,
  ClearAllIcon,
  CloseIcon,
  DensityLargeIcon,
  DensityMediumIcon,
  DensitySmallIcon,
  DragHandleIcon,
  DynamicFeedIcon,
  EditIcon,
  ExpandMoreIcon,
  FilterAltIcon,
  FilterListIcon,
  FilterListOffIcon,
  FullscreenExitIcon,
  FullscreenIcon,
  KeyboardDoubleArrowDownIcon,
  MoreHorizIcon,
  MoreVertIcon,
  PushPinIcon,
  RestartAltIcon,
  SaveIcon,
  SearchIcon,
  SearchOffIcon,
  SortIcon,
  ViewColumnIcon,
  VisibilityOffIcon,
};
