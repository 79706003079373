import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PinRightClearIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M22 3V21H20V3H22Z" />
        <path d="M10.9999 13.4142L13.9497 16.364L15.3639 14.9497L12.4142 12L15.3639 9.05025L13.9497 7.63604L11 10.5858L8.0502 7.63604L6.63599 9.05025L9.58574 12L6.63599 14.9497L8.0502 16.364L10.9999 13.4142Z" />
      </SvgIcon>
    );
  }
);

PinRightClearIcon.displayName = 'PinRightClearIcon';

export default PinRightClearIcon;
