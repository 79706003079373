import { getTourContacts } from '@core/api';
import { QueryKeys } from '@core/config';
import { useQuery } from '@tanstack/react-query';
import { UUIDType } from '@types';

export const useGetTourContacts = (uuid: UUIDType, enabled: boolean) => {
  return useQuery({
    queryKey: [QueryKeys.CONTACTS, uuid],
    queryFn: () => getTourContacts(uuid),
    enabled: enabled,
  });
};
