import { Checkbox as MuiCheckbox } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiCheckboxProps = ComponentProps<typeof MuiCheckbox>;

type CheckboxProps = Omit<
  MuiCheckboxProps,
  | 'disableTouchRipple'
  | 'focusRipple'
  | 'centerRipple'
  | 'TouchRippleProps'
  | 'touchRippleRef'
>;

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  (props, ref) => <MuiCheckbox ref={ref} {...props} />
);

Checkbox.displayName = 'Checkbox';
