import React from 'react';
import { useNumberFormatter } from '@dizzbo/core/i18n';

type Props = {
  value: number | string;
};

export const Currency: React.FC<Props> = ({ value }) => {
  const formatter = useNumberFormatter({
    style: 'currency',
    minimumFractionDigits: 0,
    currencyDisplay: 'symbol',
    currency: 'EUR',
  });

  if (value === undefined) {
    return '';
  }

  if (Number.isNaN(value)) {
    try {
      const currencyValue = parseFloat(value as string);
      return <>{formatter.format(currencyValue as number)}</>;
    } catch (error) {
      return '';
    }
  } else {
    return <>{formatter.format(value as number)}</>;
  }
};
