import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const LocationIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8C18 9.18116 17.6192 10.5685 16.9878 12.0341C16.3616 13.4878 15.5185 14.9515 14.662 16.2668C13.8073 17.5795 12.9507 18.7268 12.3072 19.5466C12.1902 19.6956 12.0804 19.8336 11.9791 19.9596C11.8855 19.8487 11.7848 19.7282 11.6782 19.5987C11.0372 18.8203 10.1837 17.7228 9.33205 16.4453C7.5942 13.8385 6 10.6799 6 8ZM11.2686 22.1819C11.2688 22.1821 11.2689 22.1823 12 21.5L12.7526 22.1585L12.0239 22.9912L11.2686 22.1819ZM11.2686 22.1819L12 21.5C12.7526 22.1585 12.7532 22.1578 12.7533 22.1576L12.7547 22.156L12.7594 22.1507L12.7762 22.1313C12.7907 22.1146 12.8116 22.0903 12.8385 22.0588C12.8923 21.9959 12.9702 21.9041 13.0687 21.786C13.2657 21.5499 13.5454 21.2082 13.8803 20.7816C14.5493 19.9294 15.4427 18.733 16.338 17.3582C17.2315 15.986 18.1384 14.4185 18.8247 12.8253C19.5058 11.244 20 9.56884 20 8C20 3.58172 16.4183 0 12 0C7.58172 0 4 3.58172 4 8C4 11.3201 5.9058 14.9115 7.66795 17.5547C8.56628 18.9022 9.4628 20.0547 10.1343 20.8701C10.4705 21.2783 10.7515 21.6035 10.9496 21.8279C11.0487 21.9401 11.1271 22.0272 11.1814 22.087C11.2086 22.1169 11.2298 22.14 11.2445 22.1559L11.2617 22.1745L11.2666 22.1798L11.2686 22.1819ZM11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8ZM12 5C10.3431 5 9 6.34315 9 8C9 9.65685 10.3431 11 12 11C13.6569 11 15 9.65685 15 8C15 6.34315 13.6569 5 12 5Z"
        />
      </SvgIcon>
    );
  }
);

LocationIcon.displayName = 'LocationIcon';

export default LocationIcon;
