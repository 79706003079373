import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteWaypoint } from '@core/api';
import { QueryKeys } from '@core/config';
import { UUIDType } from '@types';

type Payload = {
  uuid: UUIDType;
  sectionUUID: UUIDType;
};

export const useDeleteSectionWaypoint = (routeUUID: UUIDType) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QueryKeys.ROUTES, routeUUID],
    mutationFn: (values: Payload) => deleteWaypoint(values),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          QueryKeys.ROUTES,
          routeUUID,
          QueryKeys.SECTIONS,
          variables.sectionUUID,
        ],
      });
    },
  });
};
