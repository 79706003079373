import { BryntumSchedulerPro } from '@bryntum/schedulerpro-react';
import React, { useRef } from 'react';

import { SchedulerStylesWrapper } from '@scheduler/components/SchedulerStylesWrapper';

import { TourType } from '@types';
import { schedulerConfig } from './config';

type Props = {
  toursData: TourType[];
};

export const TourDetailCalendar: React.FC<Props> = ({ toursData }) => {
  const schedulerProRef = useRef<BryntumSchedulerPro>(null);

  const syncData = async (event) => {};

  const handleDeleteEvent = () => {};

  const handleSelectionChange = (event) => {};

  const resources = [{ id: 1, name: '', image: false }];
  const events = [];

  if (toursData) {
    toursData.map((order, index) => {
      events.push({
        ...order,
        resourceId: 1,
      });
    });
  }

  return (
    <SchedulerStylesWrapper sx={{ minHeight: 210 }}>
      <BryntumSchedulerPro
        ref={schedulerProRef}
        resources={resources}
        events={events}
        onEventSelectionChange={handleSelectionChange}
        onDataChange={syncData}
        // onBeforeEventDelete={handleDeleteEvent}
        {...schedulerConfig}
        eventDragCreateFeature={{ disabled: true }}
      />
      /
    </SchedulerStylesWrapper>
  );
};
