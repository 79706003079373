import { FilledInput } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { ComponentProps, forwardRef } from 'react';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  Stack,
  Typography,
} from '../';

interface FilledInputPropsOverrides {
  display?: boolean;
}

type MuiFilledInputProps = ComponentProps<typeof FilledInput>;

export type FilledInputProps = MuiFilledInputProps & FilledInputPropsOverrides;

const MuiFilledInputStyles = styled(FilledInput, {
  shouldForwardProp: (prop) => prop !== 'display',
})<FilledInputProps>(({ theme, display }) => {
  let style;

  if (display) {
    style = {
      '& .MuiInputLabel-root.MuiInputLabel-sizeSmall.MuiFormLabel-filled': {
        transform: 'translate(0px, 0px) scale(0.75)',
      },
      '&.MuiInputBase-root': {
        '&.MuiFilledInput-root': {
          paddingRight: 0,
        },
        '&.MuiFilledInput-root.Mui-disabled': {
          backgroundColor: 'transparent',
          border: '1px solid transparent',
          textFillColor: 'unset',
          paddingLeft: 0,
          input: {
            color: theme.styles.input.default.color,
            backgroundColor: 'transparent',
            textFillColor: theme.styles.input.default.color,
            paddingLeft: 0,
          },
        },
      },
      '& .Mui-disabled.MuiFilledInput-root': {
        paddingLeft: 0,
      },
    };
  }

  return {
    ...style,
  };
});

const InputLabelStyles = styled(InputLabel, {
  shouldForwardProp: (prop) => prop !== 'display',
})<FilledInputProps>(({ display }) => {
  let style;

  if (display) {
    style = {
      '&.MuiInputLabel-root.MuiInputLabel-sizeSmall': {
        transform: 'translate(0px, 0px) scale(0.75)',
      },
    };
  }

  return {
    ...style,
  };
});

interface MultiLineTextfieldProps {
  characterLimit?: number;
  rows?: number;
  helperText?: string;
  error?: boolean;
  label: string;
  defaultValue?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  name: string;
  disabled?: boolean;
  display?: boolean;
  placeholder?: string;
}

export const MultiLineTextfield = forwardRef(
  (
    {
      characterLimit = 200,
      rows = 4,
      helperText,
      error,
      label,
      defaultValue,
      onChange,
      value,
      name,
      disabled,
      display = false,
      placeholder,
    }: MultiLineTextfieldProps,
    ref
  ) => {
    const count = value?.length;
    return (
      <FormControl variant="filled" size="small" error={error} fullWidth>
        <InputLabelStyles htmlFor="name-input" display={display}>
          {label}
        </InputLabelStyles>
        <MuiFilledInputStyles
          ref={ref}
          name={name}
          multiline
          value={value}
          rows={rows}
          defaultValue={defaultValue}
          componentsProps={{
            input: {
              maxLength: characterLimit,
            },
          }}
          onChange={onChange}
          display={display}
          disabled={disabled || display}
          placeholder={placeholder}
        />
        {!display && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
            <Typography
              color="primary.light"
              variant="bodyRegularSmall"
              sx={{ marginLeft: 'auto', marginTop: 1 }}
            >
              {count} / {characterLimit}
            </Typography>
          </Stack>
        )}
      </FormControl>
    );
  }
);

MultiLineTextfield.displayName = 'MultiLineTextfield';
