import React, { useEffect, useRef, useState } from 'react';

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  LocationMarker,
  Stack,
} from '@dizzbo/ui';

import { getHereMapsDefautlLayers } from '@core/components/RoutingMap/utils/hereMapsServices';
import { useTranslation } from 'react-i18next';

type Props = {
  location: { lat: number; lng: number };
  setLocation: (location: { lat: number; lng: number }) => void;
  closeLocationDetailDialog: () => void;
};

export const LoadingPointLocationDetail: React.FC<Props> = ({
  location,
  setLocation,
  closeLocationDetailDialog,
}: Props) => {
  const mapElementRef = useRef<HTMLDivElement>(null);
  const hereMapRef = useRef<H.Map>(null);

  const [mapView, setMapView] = useState({
    zoom: 18,
    lat: location.lat,
    lng: location.lng,
  });

  const resetLocation = () => {
    hereMapRef.current?.setCenter({ lat: location.lat, lng: location.lng });
    hereMapRef.current?.setZoom(18);
  };

  const handleMapViewChange = (ev) => {
    const lookAt = ev?.newValue?.lookAt;

    if (lookAt) {
      setMapView({
        zoom: lookAt.zoom,
        lat: lookAt.position.lat,
        lng: lookAt.position.lng,
      });
    }
  };

  useEffect(() => {
    const engineType = H.Map.EngineType['HARP'];
    const maptypes = getHereMapsDefautlLayers();
    const hereMap = new H.Map(
      mapElementRef.current,
      // @ts-ignore
      maptypes.vector.normal.logistics,
      {
        center: { lat: mapView.lat, lng: mapView.lng },
        zoom: mapView.zoom,
        pixelRatio:
          window.devicePixelRatio && window.devicePixelRatio > 1 ? 2 : 1,
        padding: { top: 50, left: 50, bottom: 50, right: 50 },
        engineType,
      }
    );

    const behavior = new H.mapevents.Behavior(
      new H.mapevents.MapEvents(hereMap)
    );
    hereMap.addEventListener('mapviewchange', handleMapViewChange);

    const ui = H.ui.UI.createDefault(hereMap, maptypes, 'de-DE');

    hereMapRef.current = hereMap;

    const miniMapLayers = getHereMapsDefautlLayers();
    // @ts-ignore
    const overviewMap = new H.ui.Overview(miniMapLayers.vector.normal.truck, {
      // @ts-ignore
      alignment: H.ui.LayoutAlignment.LEFT_BOTTOM,
      zoomDelta: 6,
      scaleX: 5,
      scaleY: 6,
    });
    // Add the control to the map
    ui.addControl('overview', overviewMap);

    return () => {
      if (hereMapRef.current) {
        hereMapRef.current.removeEventListener(
          'mapviewchange',
          handleMapViewChange
        );
        hereMapRef.current = null;
      }
    };
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <DialogTitle onClose={closeLocationDetailDialog}>Location</DialogTitle>
      <DialogContent dividers sx={{ padding: 0 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: 500,
            position: 'relative',
          }}
        >
          <LocationMarker
            sx={{
              position: 'absolute',
              top: 220,
              left: '50%',
              marginLeft: -3,
              pointerEvents: 'none',
              zIndex: 99999,
            }}
          />
          <Box ref={mapElementRef} sx={{ height: '100%', width: '100%' }} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="tertiary"
          onClick={resetLocation}
          sx={{ marginRight: 'auto' }}
        >
          {t('resetLocation')}
        </Button>
        <Stack spacing={3} direction="row">
          <Button variant="tertiary" onClick={closeLocationDetailDialog}>
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            form="form"
            onClick={() => {
              setLocation({ lat: mapView.lat, lng: mapView.lng });
              closeLocationDetailDialog();
            }}
          >
            {t('save')}
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
};
