import { alpha } from '@mui/material/styles';

const table = (palette) => {
  return {
    table: {
      default: {
        backgroundColor: palette.common.white,
      },
    },
    tableRow: {
      default: {
        backgroundColor: palette.common.white,
      },
      hover: {
        backgroundColor: palette.turquoise[20],
      },
    },
    tableCell: {
      default: {
        color: palette.oxford[100],
        borderColor: alpha(palette.oxford[20], 0.25),
      },
    },
    tableHead: {
      default: {
        color: palette.oxford[40],
        backgroundColor: alpha(palette.turquoise[10], 0.8),
      },
    },
    tablePagination: {
      default: {
        backgroundColor: palette.common.white,
      },
    },
    tableTabsBar: {
      default: {
        backgroundColor: palette.common.white,
        borderColor: alpha(palette.oxford[20], 0.25),
      },
    },
    tableSearchBar: {
      default: {
        backgroundColor: palette.white,
        borderColor: alpha(palette.oxford[20], 0.25),
      },
    },
    tableFilterBar: {
      default: {
        backgroundColor: palette.turquoise[10],
        borderColor: alpha(palette.oxford[20], 0.25),
      },
    },
  };
};

export default table;
