import { Avatar as MuiAvatar } from '@mui/material';
import { SxProps } from '@mui/system';
import * as React from 'react';

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  let letters;
  try {
    letters = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;
  } catch (error) {
    letters = name.charAt(0);
  }
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: letters,
  };
}

export const Avatar = ({
  username = '',
  sx = {},
  src = '',
  variant = 'circular',
  ...rest
}: {
  username?: string;
  sx?: SxProps;
  src?: string;
  variant?: 'circular' | 'circular' | 'square';
}) => {
  return (
    <MuiAvatar
      {...stringAvatar(username)}
      sx={{ textTransform: 'uppercase', fontWeight: 600, ...sx }}
      src={src}
      variant={variant}
      {...rest}
    />
  );
};
