import React, { useRef, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useClickAway } from 'react-use';

import { Box, MinimalIconButton, TextField, Tooltip } from '@dizzbo/ui';
import { CloseIcon, CheckIcon } from '@dizzbo/ui';

import { useWorkspaces } from './hooks/useWorkspaces';
import { useDoubleClick } from '../../hooks';
import { DefaultTab } from './DefaultTab';

export const TabContainerStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})<any>(({ theme, active }) => {
  return {
    display: 'flex',
    position: 'relative',
    maxHeight: 48,
    paddingTop: 14,
    paddingRight: 16,
    paddingBottom: 14,
    paddingLeft: 16,
    borderTopLeftRadius: theme.spacing(2),
    borderTopRightRadius: theme.spacing(2),
    backgroundColor: active
      ? theme.styles.listTab.active.backgroundColor
      : theme.styles.listTab.default.backgroundColor,
    color: active
      ? theme.styles.listTab.active.color
      : theme.styles.listTab.default.color,

    cursor: active ? 'text' : 'pointer',
    boxShadow: active ? '0 4px 14px 3px rgba(0, 0, 0, 0.08)' : 'none',

    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: -8,
      left: -8,
      right: -8,
      height: 8,
      backgroundColor: '#ffffff',
    },
  };
});

export const TitleStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})<any>(({ theme, active }) => {
  return {
    userSelect: 'none',
    cursor: active ? 'text' : 'pointer',
    ...theme.typography.tabsRegularSmall,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };
});

const TextFieldStyles = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    '&.MuiFilledInput-root': {
      paddingRight: 0,
      border: 0,
      backgroundColor: 'transparent',
      input: {
        ...theme.typography.tabsRegularSmall,
        color: theme.styles.input.default.color,
        backgroundColor: 'transparent',
        padding: 0,
        height: 20,
      },
    },
  },
}));

type Props = {
  uuid: UUIDType;
  title: string;
};

export const Tab: React.FC<Props> = ({ uuid, title }: Props) => {
  if (uuid === 'default-workspace') return <DefaultTab uuid={uuid} />;

  const {
    setActiveWorkspaceUUID,
    activeWorkspaceUUID,
    deleteWorkspace,
    setWorkspaceTitle,
  } = useWorkspaces();

  const titleRef = useRef();
  const tabRef = useRef();
  const [titleText, setTitleText] = useState<string>(title);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useDoubleClick({
    onDoubleClick: (event) => {
      event.stopPropagation();
      setIsEditing(true);
    },
    ref: titleRef,
    latency: 250,
  });

  useClickAway(tabRef, () => {
    setIsEditing(false);
  });

  const handleConfirmTitle = () => {
    if (titleText) {
      setWorkspaceTitle(uuid, titleText);
    }
    setIsEditing(false);
  };

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    deleteWorkspace(uuid);
  };

  return (
    <Tooltip arrow enterDelay={1000} enterNextDelay={1000} title={titleText}>
      <TabContainerStyles
        active={uuid === activeWorkspaceUUID}
        onClick={() => setActiveWorkspaceUUID(uuid)}
        ref={tabRef}
      >
        <TitleStyles ref={titleRef} active={uuid === activeWorkspaceUUID}>
          {isEditing ? (
            <TextFieldStyles
              autoFocus
              value={titleText}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setTitleText(event.target.value)
              }
            />
          ) : (
            title
          )}
        </TitleStyles>
        {isEditing ? (
          <MinimalIconButton
            size='medium'
            sx={{ marginLeft: 1 }}
            onClick={() => handleConfirmTitle()}
          >
            <CheckIcon />
          </MinimalIconButton>
        ) : (
          <MinimalIconButton
            size='medium'
            sx={{ marginLeft: 1 }}
            onClick={handleDelete}
          >
            <CloseIcon />
          </MinimalIconButton>
        )}
      </TabContainerStyles>
    </Tooltip>
  );
};
