import * as React from 'react';
import SvgIcon, {
  SvgIconProps as MuiSvgIconProps,
} from '@mui/material/SvgIcon';

interface SvgIconPropsOverrides {
  /**
   * If `true`, uses the dark version of the dizzbo logo.
   * @default false
   */
  dark?: boolean;
}

type SvgIconProps = MuiSvgIconProps & SvgIconPropsOverrides;

const DizzboLogoIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    const { dark, ...rest } = props;

    if (dark) {
      return (
        <SvgIcon {...rest} ref={ref}>
          <path
            d="M13.53 19.41H20.7855V16.094H15.5178L13.53 19.41Z"
            fill="#172234"
          />
          <path
            d="M19.8906 6.04812L21.1826 4H17.207L15.2192 7.316L7.86432 19.4097H11.8399L19.8906 6.04812Z"
            fill="#172234"
          />
          <path
            d="M11.3427 4L9.65306 6.82836L2 19.4097H6.07501L7.96343 16.0937L15.3183 4C15.3183 4 11.3427 4 11.3427 4Z"
            fill="#3B6E71"
          />
          <path
            d="M8.46057 6.04812L9.65326 4H2.89471V7.316H7.66545L8.46057 6.04812Z"
            fill="#3B6E71"
          />
        </SvgIcon>
      );
    }
    return (
      <SvgIcon {...rest} ref={ref}>
        <path
          d="M13.53 19.41H20.7855V16.094H15.5178L13.53 19.41Z"
          fill="#89D7D2"
        />
        <path
          d="M19.8906 6.04812L21.1826 4H17.207L15.2192 7.316L7.86432 19.4097H11.8399L19.8906 6.04812Z"
          fill="#89D7D2"
        />
        <path
          d="M11.3427 4L9.65306 6.82836L2 19.4097H6.07501L7.96343 16.0937L15.3183 4C15.3183 4 11.3427 4 11.3427 4Z"
          fill="#BAF0AA"
        />
        <path
          d="M8.46057 6.04812L9.65326 4H2.89471V7.316H7.66545L8.46057 6.04812Z"
          fill="#BAF0AA"
        />
      </SvgIcon>
    );
  }
);

DizzboLogoIcon.displayName = 'DizzboLogoIcon';

export default DizzboLogoIcon;
