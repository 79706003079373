import React from 'react';
import Collapse from '@mui/material/Collapse';
import { FilterRangeFields } from '../inputs/FilterRangeFields';
import { FilterTextField } from '../inputs/FilterTextField';
import { FilterCheckbox } from '../inputs/FilterCheckbox';
import { FilterRangeSlider } from '../inputs/FilterRangeSlider';
import { type Header, type TableInstance } from '../types';

interface Props {
  header: Header;
  table: TableInstance;
}

export const TableHeadCellFilterContainer = ({ header, table }: Props) => {
  const { getState } = table;
  const { showColumnFilters } = getState();
  const { column } = header;
  const { columnDef } = column;

  return (
    <Collapse in={showColumnFilters} mountOnEnter unmountOnExit>
      {columnDef.filterVariant === 'checkbox' ? (
        <FilterCheckbox column={column} table={table} />
      ) : columnDef.filterVariant === 'range-slider' ? (
        <FilterRangeSlider header={header} table={table} />
      ) : columnDef.filterVariant === 'range' ||
        ['between', 'betweenInclusive', 'inNumberRange'].includes(
          columnDef._filterFn
        ) ? (
        <FilterRangeFields header={header} table={table} />
      ) : (
        <FilterTextField header={header} table={table} />
      )}
    </Collapse>
  );
};
