import type { BoxProps as MuiBoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import React, { FC } from 'react';

import { Box } from '../Box';
import { DizzboLogoIcon } from '../icons';

export type LicensePlateProps = {
  size?: 'small' | 'medium' | 'large';
  countryCode?: string;
  number: string;
  sx?: SxProps;
};

interface CountryCodeProps {
  size?: 'small' | 'medium' | 'large';
}

export type CountryCodeBoxProps = CountryCodeProps & MuiBoxProps;

const LicensePlateStyle = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

const DizzboLogoIconStyles = styled(DizzboLogoIcon, {
  shouldForwardProp: (prop) => prop !== 'size',
})<any>(({ size }) => {
  let styles = {
    width: 8,
  };

  if (size === 'medium') {
    styles = {
      width: 10,
    };
  }

  if (size === 'large') {
    styles = {
      width: 14,
    };
  }

  return {
    ...styles,
  };
});

const CountryCodeStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'size',
})<any>(({ theme, size }) => {
  let styles = {
    ...theme.typography.tiny,
    paddingRight: 2,
    paddingLeft: 2,
    height: 14,
  };

  if (size === 'medium') {
    styles = {
      ...theme.typography.tiny,
      paddingRight: 2,
      paddingLeft: 2,
      height: 18,
    };
  }

  if (size === 'large') {
    styles = {
      ...theme.typography.bodyBoldSmall,
      paddingRight: 8,
      paddingLeft: 8,
      height: 32,
    };
  }

  return {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.light,
    borderRight: 0,
    borderTopLeftRadius: 2,
    borderBottomLeftRadius: 2,
    lineHeight: 1,
    color: theme.palette.primary.main,
    ...styles,
  };
});

const NumberStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'countryCode',
})<any>(({ theme, size, countryCode }) => {
  let styles = {
    ...theme.typography.tiny,
    paddingRight: 4,
    paddingLeft: 4,
    height: 14,
  };

  if (size === 'medium') {
    styles = {
      ...theme.typography.bodyBoldSmall,
      paddingRight: 4,
      paddingLeft: 4,
      height: 18,
    };
  }

  if (size === 'large') {
    styles = {
      ...theme.typography.leadTextBold,
      paddingRight: 8,
      paddingLeft: 8,
      height: 32,
    };
  }

  return {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.light,
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
    borderTopLeftRadius: countryCode ? 0 : 2,
    borderBottomLeftRadius: countryCode ? 0 : 2,
    textTransform: 'uppercase',
    lineHeight: 1,
    ...styles,
    color: theme.palette.primary.main,
  };
});

export const LicensePlate: FC<LicensePlateProps> = ({
  size,
  countryCode,
  number,
  sx,
}) => (
  <LicensePlateStyle sx={sx}>
    {countryCode && (
      <CountryCodeStyles size={size}>
        {countryCode === 'Dizzbo' ? (
          <DizzboLogoIconStyles dark size={size} />
        ) : (
          countryCode
        )}
      </CountryCodeStyles>
    )}
    <NumberStyles size={size} countryCode={countryCode}>
      <span>{number}</span>
    </NumberStyles>
  </LicensePlateStyle>
);
