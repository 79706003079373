import { Radio as MuiRadio } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import React, { ComponentProps, forwardRef } from 'react';

import { Box } from '../Box';

type MuiRadioProps = ComponentProps<typeof MuiRadio>;

type RadioProps = Omit<
  MuiRadioProps,
  | 'centerRipple'
  | 'disableRipple'
  | 'disableTouchRipple'
  | 'focusRipple'
  | 'focusVisibleClassName'
  | 'TouchRippleProps'
  | 'touchRippleRef'
  | 'disableFocusRipple'
>;

const Icon = () => {
  const theme = useTheme();

  return (
    <Box>
      <SvgIcon width="16" height="16" viewBox="0 0 16 16">
        <rect
          x="0.5"
          y="0.5"
          width="15"
          height="15"
          rx="7.5"
          fill="white"
          stroke={theme.styles.input.default.borderColor}
        />
        <rect
          opacity="0.1"
          width="16"
          height="16"
          rx="8"
          fill={theme.styles.input.default.borderColor}
        />
      </SvgIcon>
    </Box>
  );
};

const CheckedIcon = () => {
  const theme = useTheme();
  return (
    <Box>
      <SvgIcon width="16" height="16" viewBox="0 0 16 16">
        <rect
          width="16"
          height="16"
          rx="8"
          fill={theme.styles.input.hover.borderColor}
        />
        <rect x="5" y="5" width="6" height="6" rx="3" fill="white" />
      </SvgIcon>
    </Box>
  );
};

export const Radio = forwardRef<HTMLButtonElement, RadioProps>((props, ref) => (
  <MuiRadio
    {...props}
    checkedIcon={<CheckedIcon />}
    icon={<Icon />}
    ref={ref}
  />
));

Radio.displayName = 'Radio';
