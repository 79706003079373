const menu = (palette) => {
  return {
    menuItem: {
      default: {
        color: palette.turquoise[150],
      },
    },
  };
};

export default menu;
