import { ListItem as MuiListItem } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiListItemProps = ComponentProps<typeof MuiListItem>;

export const ListItem = forwardRef<HTMLLIElement, MuiListItemProps>(
  (props, ref) => <MuiListItem {...props} ref={ref} />
);

ListItem.displayName = 'ListItem';
