import { Alert as MuiAlert } from '@mui/material';
import { AlertPropsVariantOverrides } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { styled } from '@mui/material/styles';
import { OverridableStringUnion } from '@mui/types';
import React, { ComponentProps, FC, ReactNode } from 'react';

import { Box } from '../Box';

type MuiAlertProps = ComponentProps<typeof MuiAlert>;

interface AlertPropsOverrides {
  title?: ReactNode;
  message?: ReactNode;
  /**
   * The variant to use.
   * @default 'primary'
   */
  variant?: OverridableStringUnion<
    'standard' | 'standard-small',
    AlertPropsVariantOverrides
  >;
}

type AlertProps = Omit<MuiAlertProps, 'variant' | 'elevation' | 'square'> &
  AlertPropsOverrides;

const MessageStyles = styled(Box)(() => ({
  marginTop: 1,
}));

const ActionStyles = styled(Box)(() => ({
  marginLeft: 'auto',
}));

export const Alert: FC<AlertProps> = ({
  title,
  message,
  children,
  ...rest
}) => (
  <MuiAlert {...rest}>
    <Box>
      <AlertTitle>{title}</AlertTitle>
      {message && <MessageStyles>{message}</MessageStyles>}
    </Box>
    <ActionStyles>{children}</ActionStyles>
  </MuiAlert>
);
