import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const FilterIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M11.8293 7C11.4175 8.16519 10.3062 9 9 9C7.69378 9 6.58254 8.16519 6.17071 7H2V5H6.17071C6.58254 3.83481 7.69378 3 9 3C10.3062 3 11.4175 3.83481 11.8293 5H22V7H11.8293Z" />
        <path d="M17.8293 19C17.4175 20.1652 16.3062 21 15 21C13.6938 21 12.5825 20.1652 12.1707 19H2V17H12.1707C12.5825 15.8348 13.6938 15 15 15C16.3062 15 17.4175 15.8348 17.8293 17H22V19H17.8293Z" />
        <path d="M22 13H2V11H22V13Z" />
      </SvgIcon>
    );
  }
);

FilterIcon.displayName = 'FilterIcon';

export default FilterIcon;
