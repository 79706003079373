export const oxford = {
  120: '#04080E',
  100: '#081324',
  90: '#121C2D',
  80: '#172234',
  60: '#233043',
  50: '#334154',
  40: '#404E64',
  30: '#525E73',
  25: '#737E90',
  20: '#99A3B2',
  15: '#CDD3DF',
  12: '#DCE1E9',
  10: '#E5E9F0',
  5: '#F1F3F7',
  2: '#F8F9FB',
};

export const turquoise = {
  200: '#203840',
  175: '#30555D',
  150: '#3B6E71',
  125: '#5A9A9A',
  110: '#72B8B5',
  100: '#89D7D2',
  80: '#C2E6E3',
  60: '#D7EFEC',
  40: '#E7F3F3',
  20: '#F5FAFA',
  10: '#FAFDFD',
};

export const green = {
  200: '#5B7657',
  175: '#789B70',
  150: '#95C088',
  125: '#A3D099',
  100: '#B1E1A5',
  80: '#BAF0AA',
  70: '#CFF5C4',
  60: '#DDF7D5',
  40: '#EAFBE5',
};

export const red = {
  200: '#5F2B2D',
  175: '#7D3638',
  150: '#9B4242',
  125: '#AF4A4B',
  100: '#C25253',
  80: '#CF5759',
  70: '#DB8183',
  60: '#E7ABAC',
  40: '#EDCBCB',
};

export const forest = {
  200: '#1B4237',
  175: '#225544',
  150: '#2A6852',
  125: '#2F755D',
  100: '#348267',
  80: '#3F9E7D',
  70: '#6FB79E',
  60: '#9FCFBE',
  40: '#C5E2D8',
};

export const blue = {
  200: '#253A65',
  175: '#304B82',
  150: '#3B5C9F',
  125: '#4368B3',
  100: '#4A73C7',
  80: '#5B8FF5',
  70: '#84ABF8',
  60: '#ADC7FA',
  40: '#CEDDFC',
};

export const orange = {
  200: '#6C4606',
  175: '#8E5A03',
  150: '#B16F00',
  125: '#C77D00',
  100: '#DD8B00',
  80: '#E69101',
  70: '#ECAD41',
  60: '#F3C880',
  40: '#F7DEB3',
};
