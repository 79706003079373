import { alpha } from '@mui/material/styles';

const datePicker = (palette) => {
  return {
    datePicker: {
      default: {
        backgroundColor: palette.oxford[80],
      },
      pickerModeSelect: {
        default: {
          backgroundColor: palette.turquoise[175],
          color: palette.turquoise[100],
        },
      },
      arrowSwitcher: {
        default: {
          color: palette.oxford[10],
        },
        button: {
          default: {
            color: palette.turquoise[150],
          },
          disabled: {
            color: palette.oxford[25],
          },
        },
      },
      dayPicker: {
        default: {
          color: palette.oxford[25],
        },
      },
      pickersDay: {
        default: {
          color: palette.oxford[10],
        },
      },
      pickerDay: {
        default: {
          backgroundColor: palette.oxford[40],
          color: palette.oxford[10],
        },
        selected: {
          color: palette.oxford[100],
        },
      },
      digitalClock: {
        default: {
          color: palette.oxford[10],
        },
      },
    },
  };
};

export default datePicker;
