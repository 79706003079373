import React, { useState } from 'react';
import { usePopupState, PopupState } from 'material-ui-popup-state/hooks';
import { bindMenu } from 'material-ui-popup-state';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { useAuth } from '@dizzbo/core/hooks/useAuth';
import { Box, Divider, Typography, MenuItem, Menu } from '@dizzbo/ui';
import { PersonIcon, LanguageIcon } from '@dizzbo/ui';
import { SettingsDialog } from '../SettingsDialog';

import { AccountList } from './AccountList';

type Props = {
  popupState: PopupState;
};

export const AppBarUserMenu: React.FC<Props> = ({ popupState }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { user, logoutUser } = useAuth();
  const settingsPopupState = usePopupState({
    variant: 'popover',
    popupId: 'settingsModal',
  });
  const [settingsActiveTab, setSettingsActiveTab] = useState(0);

  const handleOpenSettings = (tab: number) => {
    setSettingsActiveTab(tab);
    settingsPopupState.open();
    popupState.close();
  };
  return (
    <>
      <Menu
        sx={{ mt: '40px' }}
        id='menu-appbar'
        keepMounted
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        {...bindMenu(popupState)}
      >
        <Box
          sx={{
            paddingTop: 2,
            paddingRight: 6,
            paddingBottom: 3,
            paddingLeft: 6,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant='bodyRegular'>{user.displayName}</Typography>
          <Typography variant='bodyRegularSmall'>{user.email}</Typography>
        </Box>
        <Divider />
        <AccountList />
        <Divider />
        <MenuItem
          key='profile'
          onClick={() => handleOpenSettings(0)}
          sx={{ minWidth: 374 }}
        >
          <PersonIcon sx={{ width: 16, height: 16, marginRight: 2 }} />
          <Typography variant='buttonRegularSmall'>{t('settings')}</Typography>
        </MenuItem>
        <MenuItem
          key='account'
          onClick={() => handleOpenSettings(0)}
          sx={{ minWidth: 374 }}
        >
          <PersonIcon sx={{ width: 16, height: 16, marginRight: 2 }} />
          <Typography variant='buttonRegularSmall'>
            {t('accountInformation')}
          </Typography>
        </MenuItem>
        <MenuItem
          key='language'
          onClick={() => handleOpenSettings(1)}
          sx={{ minWidth: 374 }}
        >
          <LanguageIcon sx={{ width: 16, height: 16, marginRight: 2 }} />
          <Typography variant='buttonRegularSmall'>
            {t('languageSettings')}
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          key='logout'
          onClick={() => {
            logoutUser();
            queryClient.removeQueries();
            navigate('/login');
          }}
          sx={{ minWidth: 374 }}
        >
          <Typography variant='buttonRegularSmall' color='error'>
            {t('logout')}
          </Typography>
        </MenuItem>
      </Menu>
      <SettingsDialog
        popupState={settingsPopupState}
        activeTab={settingsActiveTab}
        setActiveTab={setSettingsActiveTab}
      />
    </>
  );
};
