import { ToggleButtonGroup as MuiToggleButtonGroup } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiToggleButtonGroupProps = ComponentProps<typeof MuiToggleButtonGroup>;

export const ToggleButtonGroup = forwardRef<
  HTMLDivElement,
  MuiToggleButtonGroupProps
>((props, ref) => <MuiToggleButtonGroup {...props} ref={ref} />);

ToggleButtonGroup.displayName = 'ToggleButtonGroup';
