import { useTheme } from '@mui/material/styles';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import React from 'react';

import { useWorkspaces } from '@dizzbo/core/hooks';
import { Button, ChevronDownIcon, Chip, Menu } from '@dizzbo/ui';

import { useTodoCount } from '@orders/hooks/useTodoCount';

import { useTranslation } from 'react-i18next';
import { FilterMenuList } from '../FilterMenuList';
import { filterConfig } from './filterConfig';

export const TodosFilter: React.FC = () => {
  const theme = useTheme();

  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'orderListTodoFilter',
  });

  const { activeWorkspace } = useWorkspaces();

  const todosFilterValues = activeWorkspace?.settings?.filters?.todos || [];

  const hasActiveFilters = todosFilterValues.length > 0;

  const { todosCount } = useTodoCount();

  const { t } = useTranslation();

  return (
    <>
      <Button
        variant="secondary"
        size="medium"
        endIcon={
          <>
            <Chip label={todosCount} size="small" variant="oxford-40" />
            <ChevronDownIcon />
          </>
        }
        {...bindTrigger(popupState)}
        sx={{
          boxShadow: hasActiveFilters
            ? `inset 0px 0px 0px 2px ${theme.styles.button.primary.focus.boxShadowColor};`
            : 'none',
        }}
      >
        {t('todos')}
      </Button>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{
          paper: { sx: { minWidth: 264 } },
        }}
      >
        <FilterMenuList
          filterType="todos"
          filterConfig={filterConfig}
          initialFilterValues={todosFilterValues}
        />
      </Menu>
    </>
  );
};
