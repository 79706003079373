import { styled } from '@mui/material/styles';
import React, { useRef } from 'react';
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';

import {
  Box,
  Card,
  IconButton,
  PinRightIcon,
  Stack,
  Typography,
} from '@dizzbo/ui';

import { useDoubleClick } from '@dizzbo/core/hooks';
import { TourType } from '@types';
import { TourDetail } from './TourDetail';
const DialogTitleStyles = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingTop: 16,
  paddingRight: 24,
  paddingBottom: 16,
  paddingLeft: 24,
}));

type CustomResizeHandleProps = {
  handleAxis: string;
};

export const CustomResizeHandle: React.FC<CustomResizeHandleProps> =
  React.forwardRef(({ handleAxis, ...rest }: CustomResizeHandleProps, ref) => {
    return (
      <Box
        ref={ref}
        className={`handle-${handleAxis}`}
        {...rest}
        sx={{
          backgroundColor: '#333333',
          width: 20,
          height: 20,
          position: 'absolute',
          bottom: 0,
          right: 0,
        }}
      />
    );
  });

CustomResizeHandle.displayName = 'CustomResizeHandle';

type WindowProps = {
  id: any;
  title?: string;
  position: { left: number; top: number };
  size: { width: number; height: number };
  pinned?: {
    isPinned: boolean;
    position: { left: number; top: number };
    size: { width: number; height: number };
  };
  resizeWindow?: (id: any, width: number, height: number) => void;
  moveWindow?: (id: any, left: number, top: number) => void;
  pinWindow?: (id: any, isPinned: boolean, left?: number, top?: number) => void;
  expandWindow?: (id: any) => void;
  selectedTour?: TourType;
};

export const Window: React.FC<WindowProps> = ({
  id,
  title,
  position,
  size,
  resizeWindow,
  pinWindow,
  moveWindow,
  pinned,
  selectedTour,
  expandWindow,
}) => {
  const headerRef = useRef();
  const onResize = (event, { node, size, handle }) => {
    resizeWindow(id, size.width, size.height);
  };

  const handlePinWindow = () => {
    pinWindow(id, true, pinned.position.left, pinned.position.top);
  };

  const handleDrag = (e: MouseEvent, data: Object) => {
    moveWindow(id, data.x, data.y);
  };

  const handleDragStart = (e: MouseEvent, data: Object) => {
    if (pinned.isPinned) {
      pinWindow(id, false, position.left, position.top);
    }
  };

  useDoubleClick({
    onDoubleClick: (e) => {
      expandWindow(id);
    },
    ref: headerRef,
    latency: 250,
  });

  return (
    <Draggable
      handle=".handle"
      onDrag={handleDrag}
      onStart={handleDragStart}
      position={{ x: position.left, y: position.top }}
      bounds="parent"
      // defaultPosition={{ x: 0, y: 0 }}
    >
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
        }}
      >
        <Resizable
          height={size.height}
          width={size.width}
          onResize={onResize}
          minConstraints={[200, 200]}
          handle={<CustomResizeHandle />}
        >
          <Box
            sx={{
              width: pinned.isPinned ? pinned.size.width : size.width,
              height: pinned.isPinned ? pinned.size.height : size.height,
            }}
          >
            <Card
              className="box"
              square={pinned.isPinned}
              elevation={3}
              sx={{
                height: '100%',
                width: '100%',
              }}
              variant="filled-primary"
              data-testid="box"
            >
              <Box
                className="handle"
                sx={{
                  width: '100%',
                  backgroundColor: '#ffffff',
                  cursor: 'move',
                }}
              >
                <DialogTitleStyles ref={headerRef}>
                  <Stack spacing={1}>
                    <Typography variant="h5" color="primary">
                      {title}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={4}
                    sx={{ marginLeft: 'auto' }}
                  >
                    {!pinned.isPinned && (
                      <IconButton
                        size="medium"
                        variant="tertiary"
                        onClick={handlePinWindow}
                      >
                        <PinRightIcon />
                      </IconButton>
                    )}
                  </Stack>
                </DialogTitleStyles>
              </Box>

              <TourDetail uuid={selectedTour.uuid} />
            </Card>
          </Box>
        </Resizable>
      </Box>
    </Draggable>
  );
};
