import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { AppBarWrapper } from '@dizzbo/core/components';
import { Box } from '@dizzbo/ui';

import { DashboardIcon, DocumentsIcon, TourPlannerIcon } from '@dizzbo/ui';

const ContainerStyles = styled(Box)(({}) => ({
  height: '100%',
  position: 'relative',
  overflowX: 'hidden',
  overflowY: 'hidden',
  display: 'flex',
}));

export const orderTodoFilters = [
  { orderConfirmationTodo: true },
  { loadingTimeTodo: true },
  { podReviewTodo: true },
  { unloadingTimeTodo: true },
  { transportContractTodo: true },
  { podUploadTodo: true },
];

export const DashboardLayout = () => {
  const { t } = useTranslation();
  // const { todosCount } = useTodoCount();

  const navItems = [
    {
      label: t('dashboard'),
      route: '/',
      icon: DashboardIcon,
    },
    {
      type: 'orders',
      label: t('myOrders'),
      route: '/orders',
      icon: DocumentsIcon,
      // badgeContent: todosCount,
    },
    // {
    //   type: 'tours',
    //   label: t('tours'),
    //   route: '/tours',
    //   icon: TourIcon,
    // },
    {
      type: 'planner',
      label: t('planner'),
      route: '/planner',
      icon: TourPlannerIcon,
    },
    // {
    //   type: 'realtime',
    //   label: t('realtime'),
    //   route: '/realtime',
    //   icon: MapIcon,
    // },
    // {
    //   type: 'scheduler',
    //   label: t('scheduler'),
    //   route: '/scheduler',
    //   icon: SchedulerIcon,
    // },
  ];
  return (
    <ContainerStyles>
      <AppBarWrapper navItems={navItems}>
        <Outlet />
      </AppBarWrapper>
    </ContainerStyles>
  );
};
