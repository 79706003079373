import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AllIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M6 7H18V10H6V7Z" />
        <path d="M6 11H15V14H6V11Z" />
        <path d="M18 15H6V18H18V15Z" />
      </SvgIcon>
    );
  }
);

AllIcon.displayName = 'AllIcon';

export default AllIcon;
