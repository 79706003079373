import React, { useState, useEffect } from 'react';
import { usePrevious } from 'react-use';
import { useTranslation } from 'react-i18next';
import { DndContext, closestCenter } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { Stack, Button } from '@dizzbo/ui';
import { PlusIcon } from '@dizzbo/ui';

import { QueryKeys } from '@core/config';
import { useGetOrderStops, useMoveRouteStop } from '@core/hooks';

import { SortableItem } from './SortableItem';
import AddStopItem from './AddStopItem';

import { useOrderDetail } from '../hooks';

type Props = {};

export const StopList: React.FC<Props> = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [displayAddStopItem, setDisplayAddStopItem] = useState(false);
  const { orderData } = useOrderDetail();
  const { uuid: orderUUID, route: routeUUID } = orderData;
  const { data: stopsData } = useGetOrderStops(orderUUID);
  const { mutateAsync } = useMoveRouteStop(routeUUID, orderUUID);

  /*
   * these local stops are used to display the stops in the UI
   * so that changes via drag and drop are reflected immediately
   */
  const [localStops, setLocalStops] = useState(stopsData || []);

  useEffect(() => {
    if (stopsData) {
      setLocalStops(stopsData);
    }
  }, [stopsData]);

  const previousStops = usePrevious(stopsData);

  useEffect(() => {
    if (stopsData) {
      const newStopAdded = previousStops?.length < stopsData.length;
      if (newStopAdded && stopsData.length > 1) {
        setDisplayAddStopItem(false);
        return;
      }
      // insufficent stops
      if (stopsData.length < 2) {
        setDisplayAddStopItem(true);
      }
    }
  }, [previousStops, stopsData]);

  const handleDragEnd = async (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldStopIndex = localStops
        .map(function (e) {
          return e.uuid;
        })
        .indexOf(active.id);

      const newStopIndex = localStops
        .map(function (e) {
          return e.uuid;
        })
        .indexOf(over.id);

      const orderedStops = arrayMove(localStops, oldStopIndex, newStopIndex);

      setLocalStops(orderedStops);
      try {
        await mutateAsync({ stop: active.id, position: newStopIndex });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ROUTES, routeUUID],
        });
      } catch {
        toast.error(`An error occurred.`);
      }
    }
  };

  return (
    <>
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext
          items={localStops.map((s) => s.uuid)}
          strategy={verticalListSortingStrategy}
        >
          {localStops.map((stop, index) => (
            <SortableItem
              key={stop.uuid}
              stopUUID={stop.uuid}
              index={index}
              lastItem={
                localStops.length === index + 1 &&
                !displayAddStopItem &&
                localStops.length !== 1
              }
            />
          ))}
        </SortableContext>
        {localStops.length === 0 && <AddStopItem index={0} lastItem={false} />}
        {displayAddStopItem && (
          <AddStopItem index={localStops.length || 1} lastItem={true} />
        )}
        <Stack direction="row" spacing={3} py={3} px={6}>
          <Button
            size="medium"
            variant="tertiary"
            startIcon={<PlusIcon />}
            onClick={() => setDisplayAddStopItem(true)}
            disabled={displayAddStopItem}
          >
            {t('addStop')}
          </Button>
          <Button size="medium" variant="tertiary" disabled>
            {t('optimizeRoute')}
          </Button>
          {/* <Button
            size="medium"
            variant="tertiary"
            startIcon={<ResetIcon />}
            onClick={resetState}
            disabled={stops.length === 0}
          >
            {t('reset')}
          </Button> */}
        </Stack>
      </DndContext>
    </>
  );
};
