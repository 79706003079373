import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ArrowUpDown: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M6.99989 4L11.9998 8.75813L10.4976 10.1877L8 7.81083V20H6V7.81063L3.50218 10.1877L2 8.75813L6.99989 4Z" />
        <path d="M20.4976 13.8123L18 16.1892V4H16V16.1894L13.5022 13.8123L12 15.2419L16.9999 20L21.9998 15.2419L20.4976 13.8123Z" />
      </SvgIcon>
    );
  }
);

ArrowUpDown.displayName = 'ArrowUpDown';

export default ArrowUpDown;
