const minimalIconButton = (palette) => {
  return {
    minimalIconButton: {
      primary: {
        default: {
          color: palette.oxford[100],
        },
        hover: {
          color: palette.oxford[25],
        },
        active: {
          color: palette.oxford[100],
        },
        focus: {
          color: palette.oxford[150],
        },
        disabled: {
          color: palette.oxford[25],
        },
      },
      primaryContrast: {
        default: {
          color: palette.oxford[10],
        },
        hover: {
          color: palette.oxford[25],
        },
        active: {
          color: palette.oxford[10],
        },
        focus: {
          color: palette.turquoise[100],
        },
        disabled: {
          color: palette.oxford[25],
        },
      },
    },
  };
};

export default minimalIconButton;
