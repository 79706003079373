import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateRoute } from '@core/api/routes';
import { QueryKeys } from '@core/config';
import { RouteType, UUIDType } from '@types';

export const useUpdateRouteSettings = (routeUUID: UUIDType) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QueryKeys.ROUTES, routeUUID],
    mutationFn: (values: RouteType) => updateRoute(routeUUID, values),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ROUTES, routeUUID],
      });
    },
  });
};
