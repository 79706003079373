const Paper = (theme) => {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          color: theme.styles.paper.color.default,
          backgroundImage: 'none',
          backgroundColor: theme.styles.paper.filled.default.backgroundColor,
        },
        rounded: {
          borderRadius: theme.shape.borderRadius * 2,
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: ({ ownerState, theme }) => ({
            borderColor: theme.styles.paper.outlined.default.borderColor,
            boxShadow: theme.shadows[ownerState.elevation],
          }),
        },
        {
          props: { variant: 'filled' },
          style: ({ ownerState, theme }) => ({
            backgroundColor: theme.styles.paper.filled.default.backgroundColor,
            boxShadow: theme.shadows[ownerState.elevation],
          }),
        },
        {
          props: { variant: 'filled-primary' },
          style: ({ ownerState, theme }) => ({
            backgroundColor:
              theme.styles.paper.filledPrimary.default.backgroundColor,
            boxShadow: theme.shadows[ownerState.elevation],
          }),
        },
        {
          props: { variant: 'filled-secondary' },
          style: ({ ownerState, theme }) => ({
            backgroundColor:
              theme.styles.paper.filledSecondary.default.backgroundColor,
            boxShadow: theme.shadows[ownerState.elevation],
          }),
        },
        {
          props: { variant: 'filled-secondary-light' },
          style: ({ ownerState, theme }) => ({
            backgroundColor:
              theme.styles.paper.filledSecondaryLight.default.backgroundColor,
            boxShadow: theme.shadows[ownerState.elevation],
          }),
        },
        {
          props: { variant: 'filled-disabled' },
          style: ({ ownerState, theme }) => ({
            backgroundColor:
              theme.styles.paper.filledDisabled.default.backgroundColor,
            boxShadow: theme.shadows[ownerState.elevation],
          }),
        },
        {
          props: { variant: 'filled-turquoise' },
          style: ({ ownerState, theme }) => ({
            backgroundColor:
              theme.styles.paper.filledTurquoise.default.backgroundColor,
            boxShadow: theme.shadows[ownerState.elevation],
          }),
        },
        {
          props: { variant: 'filled-green' },
          style: ({ ownerState, theme }) => ({
            backgroundColor:
              theme.styles.paper.filledGreen.default.backgroundColor,
            boxShadow: theme.shadows[ownerState.elevation],
          }),
        },
      ],
    },
  };
};

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    elevation: true;
    outlined: true;
    filled: true;
    'filled-primary': true;
    'filled-secondary': true;
    'filled-secondary-light': true;
    'filled-disabled': true;
    'filled-turquoise': true;
    'filled-green': true;
  }
}
export default Paper;
