import { InputAdornment as MuiInputAdornment } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiInputAdornmentProps = ComponentProps<typeof MuiInputAdornment>;

export const InputAdornment = forwardRef<
  HTMLDivElement,
  MuiInputAdornmentProps
>((props, ref) => <MuiInputAdornment ref={ref} {...props} />);

InputAdornment.displayName = 'InputAdornment';
