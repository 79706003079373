import { FieldErrors } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

export const useFieldErrors = (
  isError: boolean,
  errors: FieldErrors,
  error: any
) => {
  return {
    hasFieldError: (field: any) => {
      if (isError) {
        return Boolean(error?.response?.data[field.name]);
      }
      if (!isEmpty(errors) && errors[field.name]) {
        return Boolean(errors[field.name]);
      }
      return false;
    },
    fieldError: (field: any) => {
      if (isError) {
        return error?.response?.data[field.name];
      }
      if (!isEmpty(errors) && errors[field.name]) {
        return errors[field.name].message;
      }
      return '';
    },
  };
};
