import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { getDepartments, getTeams, updateOrder } from '@core/api';
import { AutoSave } from '@core/components';
import { QueryKeys } from '@core/config';
import { useAuth } from '@dizzbo/core/hooks/useAuth';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  PlusIcon,
  Stack,
  Typography,
  WarningIcon,
} from '@dizzbo/ui';

import { OrderType } from '@types';
import { useTranslation } from 'react-i18next';
import { useOrderDetail } from '../../../hooks';
import { AddParticipantsDialog } from './AddParticipantsDialog';
import { AssigneeListItem } from './AssigneeListItem';
import { DepartmentsAutoCompleteTextField } from './DepartmentsAutoCompleteTextField';
import { ParticipantsListItem } from './ParticipantsListItem';

type Props = {};

export const Contributors: React.FC<Props> = () => {
  const { orderData } = useOrderDetail();

  const {
    uuid: orderUUID,
    assignee,
    participants = [],
    team,
    executingDepartment,
    owningDepartment,
  } = orderData;
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'participantsAddDialog',
  });
  const { mutate: mutateOrder }: any = useMutation<
    OrderType,
    unknown,
    Partial<OrderType>
  >({
    mutationKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.DEPARTMENTS],
    mutationFn: (values) =>
      updateOrder({ orderUUID: orderUUID, orderData: values }),
  });

  const { data: departmentsData } = useQuery({
    queryKey: [QueryKeys.DEPARTMENTS],
    queryFn: () => getDepartments(),
  });

  const { data: teamsData } = useQuery({
    queryKey: [QueryKeys.TEAMS],
    queryFn: () => getTeams(),
  });

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    mode: 'all',
    values: {
      executingDepartment: executingDepartment,
      owningDepartment: owningDepartment || user?.department.uuid,
      team: team,
    },
  });

  const onSubmit = (formData: any) => {
    const data = {
      executingDepartment: formData.executingDepartment.uuid,
      owningDepartment: formData.owningDepartment.uuid,
      team: formData?.team?.uuid,
    };
    mutateOrder(data, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ORDERS, orderUUID],
        });
      },
      onError: () => {},
    });
  };

  const handleClick = (e) => {
    popupState.open();
    e.stopPropagation();
  };

  const { t } = useTranslation();

  return (
    <Card
      variant="filled-secondary"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
        minHeight: 374,
      }}
    >
      <CardHeader title="Contributors" variant="small" />
      <CardContent>
        {!executingDepartment && (
          <Alert
            title="Executive Department missing"
            variant="standard"
            severity="warning"
            icon={<WarningIcon />}
            sx={{ marginBottom: 6 }}
          />
        )}
        {!executingDepartment && (
          <Alert
            title="Executive Department missing"
            variant="standard"
            severity="warning"
            icon={<WarningIcon />}
            sx={{ marginBottom: 6 }}
          />
        )}

        <Stack
          spacing={6}
          divider={<Divider orientation="horizontal" flexItem />}
        >
          <Stack spacing={6}>
            <Typography variant="h6">{t('departments')}</Typography>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={6}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Controller
                name="owningDepartment"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <DepartmentsAutoCompleteTextField
                    sx={{ width: '100%' }}
                    label="Owner Department"
                    data={departmentsData?.results ?? []}
                    {...field}
                  />
                )}
              />
              <Controller
                name="executingDepartment"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <DepartmentsAutoCompleteTextField
                    sx={{ width: '100%' }}
                    label="Executive Department"
                    data={departmentsData?.results ?? []}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Stack>
          <Stack spacing={6}>
            <Typography variant="h6">{t('teams')}</Typography>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={6}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Controller
                name="team"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <DepartmentsAutoCompleteTextField
                    sx={{ width: '100%' }}
                    label="Team"
                    data={teamsData?.results ?? []}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Stack>
          <Stack spacing={6}>
            <Typography variant="h6">{t('assignee')}</Typography>
            <AssigneeListItem assignee={assignee} orderUUID={orderUUID} />
          </Stack>
          <Stack spacing={6}>
            <Typography variant="h6">{t('participants')}</Typography>
            {participants.map((user) => (
              <ParticipantsListItem
                key={user.uuid}
                username={user.displayName}
                userUUID={user.uuid}
                orderUUID={orderUUID}
                participants={participants}
              />
            ))}
            <Box>
              <Button
                variant="tertiary"
                size="medium"
                startIcon={<PlusIcon />}
                onClick={handleClick}
              >
                {t('addParticipants')}
              </Button>
            </Box>
          </Stack>
        </Stack>
        <AutoSave
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          isValid={isValid}
          control={control}
        />
      </CardContent>
      <AddParticipantsDialog
        orderUUID={orderUUID}
        popupState={popupState}
        participants={participants}
      />
    </Card>
  );
};
