import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import React, { FC } from 'react';

import { Box, DocumentIcon } from '@dizzbo/ui';

function getExtension(filename) {
  return filename.split('.').pop().toLowerCase();
}

type Props = {
  sx?: SxProps;
  file?: string;
};

const ContainerStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isRounded',
})<any>(({ theme, isRounded }) => {
  return {
    position: 'relative',
    width: 56,
    height: 56,
    borderRadius: isRounded ? 100 : 0,
    boxShadow: !isRounded ? theme.shadows[2] : 'none',
    backgroundColor: theme.palette.turquoise[60],
  };
});

const IconWrapperStyles = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ImageWrapperStyles = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
}));

const ImageStyles = styled('img')(() => ({
  width: '100%',
  height: '100%',
  textAlign: 'center',
  objectFit: 'cover',
  color: 'transparent',
  textIndent: '10000px',
}));

export const DocumentAvatar: FC<Props> = ({ file }) => {
  let isRounded = true;

  if (file && getExtension(file) !== 'pdf') {
    isRounded = false;
  }

  return (
    <ContainerStyles isRounded={isRounded}>
      <IconWrapperStyles>
        <DocumentIcon sx={{ width: 28, height: 28 }} />
      </IconWrapperStyles>
      <ImageWrapperStyles>
        <ImageStyles src={file} />
      </ImageWrapperStyles>
    </ContainerStyles>
  );
};
