import React from 'react';

import { Card, CardContent } from '@dizzbo/ui';

import { CarrierType, UUIDType } from '@types';
import { CarrierDisplay } from './CarrierDisplay';
import { CarrierForm } from './CarrierForm';

type Props = {
  tourUUID: UUIDType;
  orderUUID: UUIDType;
  carrier?: CarrierType;
};

export const CarrierSlot: React.FC<Props> = ({
  tourUUID,
  orderUUID,
  carrier,
}) => {
  return (
    <Card variant="filled-secondary" elevation={0}>
      <CardContent>
        {carrier ? (
          <CarrierDisplay tourUUID={tourUUID} carrier={carrier} />
        ) : (
          <CarrierForm tourUUID={tourUUID} orderUUID={orderUUID} />
        )}
      </CardContent>
    </Card>
  );
};
