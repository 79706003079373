const Dialog = (theme) => {
  return {
    MuiDialog: {
      defaultProps: {
        scroll: 'body',
      },
      styleOverrides: {
        root: {},
      },
    },
    MuiDialogTitle: {
      defaultProps: {},
      styleOverrides: {
        root: {
          ...theme.typography.h3,
          color: theme.styles.common.textColors.main,
          svg: {
            marginRight: 6,
          },
        },
      },
    },
    MuiDialogContent: {
      defaultProps: {},
      styleOverrides: {
        root: {
          color: theme.styles.common.textColors.main,
          padding: theme.spacing(6),
          '&.MuiDialogContent-dividers': {
            borderTop: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
            borderBottom: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
          },
        },
      },
    },
    MuiDialogActions: {
      defaultProps: {},
      styleOverrides: {
        root: {
          paddingTop: theme.spacing(4),
          paddingRight: theme.spacing(6),
          paddingBottom: theme.spacing(4),
          paddingLeft: theme.spacing(6),
        },
      },
    },
  };
};

export default Dialog;
