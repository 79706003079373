import { styled } from '@mui/material/styles';
import React from 'react';

import { Box, Typography } from '@dizzbo/ui';
import { OrderType } from '@types';

const ContainerStyles = styled(Box)(({ theme }) => ({
  height: '100%',
  padding: 12,
  minWidth: '100%',
  display: 'flex',
  backgroundColor: theme.palette.turquoise[20],
}));

const ContentStyles = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
}));

export type Props = {
  data: OrderType;
};

export const OrderItem = ({ data }: Props) => {
  return (
    <ContainerStyles>
      <ContentStyles>
        <Typography variant="bodyBoldSmall" color="primary">
          {data.reference}
        </Typography>
      </ContentStyles>
    </ContainerStyles>
  );
};
