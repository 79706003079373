import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const GridIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M5 1H1V5H5V1Z" />
        <path d="M1 10H5V14H1V10Z" />
        <path d="M1 19H5V23H1V19Z" />
        <path d="M14 1H10V5H14V1Z" />
        <path d="M10 10H14V14H10V10Z" />
        <path d="M10 19H14V23H10V19Z" />
        <path d="M23 1H19V5H23V1Z" />
        <path d="M19 10H23V14H19V10Z" />
        <path d="M19 19H23V23H19V19Z" />
      </SvgIcon>
    );
  }
);

GridIcon.displayName = 'GridIcon';

export default GridIcon;
