import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { deDE, enUS, esES, itIT } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/en';
import 'dayjs/locale/de';
import 'dayjs/locale/es';
import 'dayjs/locale/it';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { useTranslation } from 'react-i18next';

import { ToastContainer } from '@dizzbo/ui';

import { Router } from '@core/routes';

const App = () => {
  const { i18n } = useTranslation();

  let localeText =
    enUS.components.MuiLocalizationProvider.defaultProps.localeText;

  if (i18n.language === 'de') {
    localeText =
      deDE.components.MuiLocalizationProvider.defaultProps.localeText;
  }

  if (i18n.language === 'es') {
    localeText =
      esES.components.MuiLocalizationProvider.defaultProps.localeText;
  }

  if (i18n.language === 'it') {
    localeText =
      itIT.components.MuiLocalizationProvider.defaultProps.localeText;
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={i18n.language}
      localeText={localeText}
    >
      <BrowserRouter>
        <Router />
        <ReactQueryDevtools initialIsOpen={false} />
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
        />
      </BrowserRouter>
    </LocalizationProvider>
  );
};

export default App;
