import { Tab as MuiTab } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiTabProps = ComponentProps<typeof MuiTab>;

export const Tab = forwardRef<HTMLDivElement, MuiTabProps>((props, ref) => (
  <MuiTab ref={ref} {...props} />
));

Tab.displayName = 'Tab';
