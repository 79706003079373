import { FormControl as MuiFormControl } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiFormControlProps = ComponentProps<typeof MuiFormControl>;

export const FormControl = forwardRef<HTMLDivElement, MuiFormControlProps>(
  (props, ref) => <MuiFormControl ref={ref} {...props} />
);

FormControl.displayName = 'FormControl';
