import { oxford, turquoise, green, red, forest, blue, orange } from './colors';

const palette = {
  mode: 'light',
  common: {
    white: '#ffffff',
    black: '#000000',
  },
  primary: {
    main: oxford[40],
    dark: oxford[100],
    light: oxford[25],
  },
  secondary: {
    main: turquoise[100], // Oxford Blue/60
    light: turquoise[60], // Oxford Blue/40
    dark: turquoise[175], // Oxford Blue/100
  },
  error: {
    main: red[100],
    light: red[40],
    dark: red[200],
  },
  warning: {
    main: orange[100],
    light: orange[40],
    dark: orange[200],
  },
  info: {
    main: blue[100],
    light: blue[40],
    dark: blue[200],
  },
  success: {
    main: forest[100],
    light: forest[40],
    dark: forest[200],
  },
  grey: {
    100: '#535C68',
    70: '#717B89',
    40: '#A5ABB4',
    10: '#E5E9F0',
    5: '##F1F3F7',
  },
  oxford: {
    ...oxford,
  },
  turquoise: {
    ...turquoise,
  },
  green: {
    ...green,
  },
  red: {
    ...red,
  },
  forest: {
    ...forest,
  },
  blue: {
    ...blue,
  },
  orange: {
    ...orange,
  },
} as const;

declare module '@mui/material/styles/createPalette' {
  interface PaletteOptions {
    oxford?: PaletteColorOptions;
    turquoise?: PaletteColorOptions;
    green?: PaletteColorOptions;
    red?: PaletteColorOptions;
    forest?: PaletteColorOptions;
    blue?: PaletteColorOptions;
    orange?: PaletteColorOptions;
  }
  interface Palette {
    oxford?: PaletteColor;
    turquoise: PaletteColor;
    green: PaletteColor;
    red: PaletteColor;
    forest: PaletteColor;
    blue: PaletteColor;
    orange: PaletteColor;
  }
}

export default palette;
