import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import Fade from '@mui/material/Fade';

import { View } from '@dizzbo/core/views';
import { LoadingScreen, StickyBox, Stack } from '@dizzbo/ui';

import { OrderDetailHeader } from '../components/OrderDetailHeader';
import { TodosSection } from '../components/Todos';
import { OrderDetailNavigation } from '../components/OrderDetailNavigation';
import { OrderDetailForm } from '../components/OrderDetailForm';
import { TourDetailForm } from '../components/TourDetailForm';
import { Settings } from '../components/Settings';
import { OrderDetailProvider } from '../contexts';

import { useGetOrder, useGetTour } from '../queries';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Fade in={value === index}>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    </Fade>
  );
};

export const OrderDetailView: FC = () => {
  const containerRef = React.useRef();
  const theme = useTheme();
  const { uuid } = useParams();

  const { data: orderData } = useGetOrder(uuid);

  const { data: tourData, isPending } = useGetTour(
    orderData?.tour?.uuid,
    !!orderData?.tour
  );

  const [activeTab, setActiveTab] = React.useState(0);

  if (isPending) {
    return <LoadingScreen sx={{ position: 'absolute' }} />;
  }

  return (
    <View
      title={`Order Detail - ${orderData.reference}`}
      sx={{ height: '100%', overflowY: 'auto', paddingBottom: 6 }}
      ref={containerRef}
    >
      <OrderDetailProvider orderData={orderData}>
        <StickyBox
          container={containerRef}
          zIndex={theme.zIndex.appBar + 3}
          stickTo="top"
          offset={0}
          stuckStyles={{}}
          unstuckStyles={{}}
        >
          <OrderDetailHeader />
        </StickyBox>
        <TodosSection orderData={orderData} tourData={tourData} />
        <StickyBox
          container={containerRef}
          zIndex={theme.zIndex.appBar + 4}
          stickTo="top"
          offset={80}
          stuckStyles={{}}
          unstuckStyles={{}}
        >
          <OrderDetailNavigation
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </StickyBox>
        <TabPanel value={activeTab} index={0}>
          <OrderDetailForm />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <TourDetailForm />
        </TabPanel>
        <Stack
          direction="row"
          spacing={6}
          justifyContent="space-between"
          sx={{
            marginTop: theme.spacing(6),
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6),
          }}
        >
          <Settings settingsData={orderData.settings} />
        </Stack>
      </OrderDetailProvider>
    </View>
  );
};
