import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PinLeftClearIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M2 21L2 3L4 3L4 21H2Z" />
        <path d="M13.0001 10.5858L10.0503 7.63604L8.63609 9.05025L11.5858 12L8.63609 14.9497L10.0503 16.364L13.0001 13.4142L15.9498 16.364L17.364 14.9497L14.4143 12L17.364 9.05025L15.9498 7.63604L13.0001 10.5858Z" />
      </SvgIcon>
    );
  }
);

PinLeftClearIcon.displayName = 'PinLeftClearIcon';

export default PinLeftClearIcon;
