import { Shadows } from '@mui/material';

export const shadows = [
  'none',
  '0px 1px 0px rgba(153, 163, 178, 0.25)',
  '0px 4px 24px rgba(0, 0, 0, 0.08)',
  '0px 8px 32px rgba(0, 0, 0, 0.15)',
  '0px 0px 32px 4px rgba(0, 0, 0, 0.2)',
  '0px 8px 48px rgba(0, 0, 0, 0.35)',
  '0px 4px 32px rgba(0, 0, 0, 0.5)',
  '0px 4px 32px rgba(0, 0, 0, 0.8)',
  '0px 4px 48px #000000',
  '0px 4px 48px 10px #000000',
  // dirty fix as MUIs Shadows type is expecting an arroy of 25 items
  ...Array(15).fill('none'),
] as Shadows;

export const darkShadows = [
  'none',
  '0px 1px 0px rgba(4, 8, 14, 0.8)',
  '0px 4px 24px rgba(0, 0, 0, 0.08)',
  '0px 8px 32px rgba(0, 0, 0, 0.15)',
  '0px 0px 32px 4px rgba(0, 0, 0, 0.2)',
  '0px 8px 48px rgba(0, 0, 0, 0.35)',
  '0px 4px 32px rgba(0, 0, 0, 0.5)',
  '0px 4px 32px rgba(0, 0, 0, 0.8)',
  '0px 4px 48px #000000',
  '0px 4px 48px 10px #000000',
  // dirty fix as MUIs Shadows type is expecting an arroy of 25 items
  ...Array(15).fill('none'),
] as Shadows;
