import { TextField as MuiTextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { ComponentProps, forwardRef } from 'react';

interface TextFieldPropsOverrides {
  display?: boolean;
}

type MuiTextFieldProps = ComponentProps<typeof MuiTextField>;

export type TextFieldProps = MuiTextFieldProps & TextFieldPropsOverrides;

const MuiTextFieldStyles = styled(MuiTextField, {
  shouldForwardProp: (prop) => prop !== 'display',
})<TextFieldProps>(({ theme, display }) => {
  let style;

  if (display) {
    style = {
      '&.MuiTextField-root': {
        '.MuiInputAdornment-root': {
          display: 'none',
        },
        '.MuiAutocomplete-endAdornment': {
          display: 'none',
        },
      },
      '& .MuiInputLabel-root.MuiInputLabel-sizeSmall.MuiFormLabel-filled': {
        transform: 'translate(0px, 0px) scale(0.75)',
      },
      '& .MuiInputBase-root': {
        '&.MuiFilledInput-root': {
          paddingRight: 0,
        },
        '&.MuiFilledInput-root.Mui-disabled': {
          backgroundColor: 'transparent',
          border: '1px solid transparent',
          textFillColor: 'unset',
          paddingLeft: 0,
          input: {
            color: theme.styles.input.default.color,
            backgroundColor: 'transparent',
            textFillColor: theme.styles.input.default.color,
            paddingLeft: 0,
          },
        },
      },
      '& .Mui-disabled.MuiFilledInput-root': {
        paddingLeft: 0,
      },
    };
  }

  return {
    ...style,
  };
});

export const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  ({ display = false, disabled, ...rest }: TextFieldProps, ref) => (
    <MuiTextFieldStyles
      display={display}
      disabled={disabled || display}
      ref={ref}
      {...rest}
    />
  )
);

TextField.displayName = 'TextField';
