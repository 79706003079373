const Toolbar = (theme) => {
  return {
    MuiToolbar: {
      defaultProps: {},
      styleOverrides: {
        root: {},
        regular: {
          height: 64,
        },
      },
    },
  };
};

export default Toolbar;
