import { axiosAuthenticatedRequest } from '@dizzbo/core/api';
import { ContactType } from '@types';

export const getContactsAutocomplete = async (
  params
): Promise<ContactType[]> => {
  const res = await axiosAuthenticatedRequest.get(
    '/v2/contacts/autocomplete/',
    { params: params }
  );
  return res.data;
};

export const createContact = async (data) => {
  const res = await axiosAuthenticatedRequest.post(`/v2/contacts/`, data);
  return res.data;
};
