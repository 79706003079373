import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SettingsIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M8 4C6.89543 4 6 4.89543 6 6C6 7.10457 6.89543 8 8 8H10C11.1046 8 12 7.10457 12 6C12 4.89543 11.1046 4 10 4H8Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1C5.23858 1 3 3.23858 3 6C3 8.76142 5.23858 11 8 11H16C18.7614 11 21 8.76142 21 6C21 3.23858 18.7614 1 16 1H8ZM5 6C5 4.34315 6.34315 3 8 3H16C17.6569 3 19 4.34315 19 6C19 7.65685 17.6569 9 16 9H8C6.34315 9 5 7.65685 5 6Z"
        />
        <path d="M14 15C12.8954 15 12 15.8954 12 17C12 18.1046 12.8954 19 14 19H16C17.1046 19 18 18.1046 18 17C18 15.8954 17.1046 15 16 15H14Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 17C3 14.2386 5.23858 12 8 12H16C18.7614 12 21 14.2386 21 17C21 19.7614 18.7614 22 16 22H8C5.23858 22 3 19.7614 3 17ZM8 14C6.34315 14 5 15.3431 5 17C5 18.6569 6.34315 20 8 20H16C17.6569 20 19 18.6569 19 17C19 15.3431 17.6569 14 16 14H8Z"
        />
      </SvgIcon>
    );
  }
);

SettingsIcon.displayName = 'SettingsIcon';

export default SettingsIcon;
