import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CurrencyIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M4 10.752H4.8293C5.17707 12.0763 6.46115 13 8.02166 13C9.37707 13 10.5006 12.3379 11 11.1853H9.6C9.21656 11.6839 8.69045 11.8965 8.03057 11.8965C7.10319 11.8965 6.40764 11.4305 6.11338 10.752H8.7172L8.88662 10.2943H5.97962C5.9707 10.1962 5.96178 10.0981 5.96178 10C5.96178 9.89373 5.9707 9.79564 5.98853 9.69755H9.11847L9.29682 9.23161H6.12229C6.41656 8.56131 7.1121 8.10354 8.03057 8.10354C8.69045 8.10354 9.21656 8.31608 9.6 8.81471H11C10.5006 7.66213 9.37707 7 8.02166 7C6.46115 7 5.1949 7.91553 4.8293 9.23161H4.18726L4 9.69755H4.74904C4.74013 9.79564 4.73121 9.89374 4.73121 10C4.73121 10.0981 4.74013 10.1962 4.74904 10.2943L4.18726 10.2943L4 10.752Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 3.25C4.27208 3.25 1.25 6.27208 1.25 10C1.25 13.7279 4.27208 16.75 8 16.75C11.7279 16.75 14.75 13.7279 14.75 10C14.75 6.27208 11.7279 3.25 8 3.25ZM2.75 10C2.75 7.10051 5.10051 4.75 8 4.75C10.8995 4.75 13.25 7.10051 13.25 10C13.25 12.8995 10.8995 15.25 8 15.25C5.10051 15.25 2.75 12.8995 2.75 10Z"
        />
        <path d="M16.222 9.75C18.9725 9.75 21.25 12.0736 21.25 15C21.25 17.9264 18.9725 20.25 16.222 20.25C14.2331 20.25 12.497 19.0408 11.682 17.2594L10.318 17.8834C11.3577 20.1563 13.6011 21.75 16.222 21.75C19.8537 21.75 22.75 18.701 22.75 15C22.75 11.299 19.8537 8.25 16.222 8.25V9.75Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.4159 18.25H16.0645V17.715C15.3162 17.627 14.75 17.0988 14.75 16.2455H15.6415C15.6611 16.5638 15.8303 16.7398 16.0645 16.8076V15.6428L15.8368 15.5547C15.212 15.3109 14.75 14.864 14.75 14.1394C14.75 13.3403 15.2576 12.8188 16.0645 12.7714V12.25H16.4159V12.7782C17.1318 12.8798 17.6264 13.4486 17.6264 14.1258H16.7348C16.6958 13.9159 16.5916 13.7669 16.4159 13.6992V14.7692L16.6437 14.8572C17.3335 15.1281 17.75 15.5615 17.75 16.3538C17.75 17.1733 17.2424 17.6744 16.4159 17.7218V18.25ZM16.0645 13.6653C15.8107 13.7128 15.6415 13.8821 15.6415 14.1258C15.6415 14.3629 15.7977 14.5051 16.0645 14.627V13.6653ZM16.4159 16.8279C16.6632 16.7872 16.8585 16.6247 16.8585 16.3606C16.8585 16.1033 16.7023 15.934 16.4159 15.7918V16.8279Z"
        />
      </SvgIcon>
    );
  }
);

CurrencyIcon.displayName = 'CurrencyIcon';

export default CurrencyIcon;
