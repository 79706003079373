import Popper from '@mui/material/Popper';
import { SxProps } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';

import { getUsersAutocomplete } from '@dizzbo/core/api';
import {
  Autocomplete,
  CheckIcon,
  ListItem,
  ListItemText,
  TextField,
} from '@dizzbo/ui';

import { QueryKeys } from '@core/config';
import { UserType } from '@types';

const styles = {
  popper: {
    width: 'fit-content',
  },
};

type Props = {
  label: string;
  value?: any;
  onChange?: (user: UserType) => void;
  sx?: SxProps;
};

const ParticipantsPopper = function (props) {
  // need to override style here because else "fit-content" would not be
  // recognized by MUI and how it calculates the width of the popper
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};

export const ParticipantsAutoCompleteTextField = React.forwardRef(
  ({ label, value, onChange, sx }: Props, ref) => {
    const [searchValue, setSearchValue] = useState(value?.name);
    const [open, setOpen] = React.useState(false);

    async function findUser(query) {
      const term = query.queryKey[1];
      const resData = await getUsersAutocomplete({ q: term });
      return resData;
    }

    const { isFetching, data = [] } = useQuery({
      queryKey: [QueryKeys.USERS, searchValue],
      queryFn: findUser,
      enabled: open && !!searchValue,
    });

    return (
      <Autocomplete
        ref={ref}
        sx={{
          ...sx,
        }}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        multiple
        disableCloseOnSelect
        loading={isFetching}
        autoComplete // seems not to work
        autoHighlight
        blurOnSelect // input should be blurred when an option is selected
        clearOnEscape // clear input via pressing Esc
        PopperComponent={ParticipantsPopper}
        componentsProps={{
          popper: {
            sx: {
              width: 'auto',
            },
          },
        }}
        noOptionsText="No user found"
        options={data}
        value={value}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: <></>,
            }}
          />
        )}
        getOptionLabel={(option) => option?.displayName}
        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
        onChange={(event: any, newValue: UserType | null, reason: string) => {
          onChange(newValue);
        }}
        onInputChange={(event, newValue: string, reason: string) => {
          // dont set a new search value when the suggestion list closes/an item get selected
          // TODO this seems not tow work reliable. As soon as you selected an item
          // and reopen the search the full string gets set and also is highlighted via autosuggest-highlight
          if (reason !== 'reset') {
            setSearchValue(newValue);
          }
        }}
        renderOption={(props, user: UserType) => {
          // removing className property as we dont need the special styling applied via autocomplete
          const { className, ...rest } = props;

          let listItemProps = {};

          if (props['aria-selected']) {
            listItemProps = {
              secondaryAction: <CheckIcon sx={{ width: 16, height: 16 }} />,
            };
          }

          return (
            <ListItem key={user.uuid} {...rest} {...listItemProps}>
              <ListItemText>{user.displayName}</ListItemText>
            </ListItem>
          );
        }}
      />
    );
  }
);

ParticipantsAutoCompleteTextField.displayName =
  'ParticipantsAutoCompleteTextField';
