import { axiosAuthenticatedRequest } from '@dizzbo/core/api';
import { AddLoadingPointFormParsedData, StopType } from '@types';

export const getLoadingPoints = async (params) => {
  const res = await axiosAuthenticatedRequest.get<StopType[]>(
    '/v2/loadingpoints/autocomplete/',
    { params: params }
  );
  return res.data;
};

export const createLoadingPoint = async (
  formData: AddLoadingPointFormParsedData
) => {
  const res = await axiosAuthenticatedRequest.post(
    '/v2/loadingpoints/',
    formData
  );
  return res.data;
};

// TODO: this function is not used anywhere
export const getLoadingPoint = async (loadingPointUUID: string) => {
  const res = await axiosAuthenticatedRequest.get(
    `/v2/loadingpoints/${loadingPointUUID}/`
  );
  return res.data;
};
