import { SxProps } from '@mui/material';
import Popper from '@mui/material/Popper';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';

import { QueryKeys } from '@core/config';
import {
  Autocomplete,
  CarrierIcon,
  CheckIcon,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@dizzbo/ui';

import { getCarriersAutocomplete } from '@core/api/';
import { CarrierType } from '@types';

const styles = {
  popper: {
    width: 'fit-content',
  },
};

type Props = {
  label: string;
  value?: CarrierType;
  onChange?: (carrier: CarrierType) => void;
  sx?: SxProps;
};

const PopperMy = function (props) {
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};

export const CarrierAutoCompleteTextField = React.forwardRef<
  HTMLElement,
  Props
>(({ label, value, onChange, sx }, ref) => {
  const [searchValue, setSearchValue] = useState('');
  const [open, setOpen] = React.useState(false);

  async function findCarrier(query) {
    const resData = await getCarriersAutocomplete({ q: searchValue });
    return resData;
  }

  const { isFetching, data = [] } = useQuery({
    queryKey: [QueryKeys.CARRIERS, searchValue],
    queryFn: findCarrier,
    enabled: open && !!searchValue,
  });

  const formatCustomerName = (carrierData: CarrierType) => {
    return `${carrierData.customerNumber} - ${carrierData.name} - ${carrierData?.address?.country} - ${carrierData?.address?.city}`;
  };

  return (
    <Autocomplete
      ref={ref}
      sx={{
        ...sx,
      }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      disableCloseOnSelect={true}
      loading={isFetching}
      autoComplete={true} // seems not to work
      autoHighlight
      blurOnSelect={true} // input should be blurred when an option is selected
      clearOnEscape={true} // clear input via pressing Esc
      popupIcon={<CarrierIcon />}
      PopperComponent={PopperMy}
      componentsProps={{
        popper: {
          sx: {
            width: 'auto',
          },
        },
      }}
      noOptionsText="No matching carrier found"
      options={data}
      value={value}
      filterOptions={(x) => x}
      renderInput={(params) => <TextField {...params} label={label} />}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : formatCustomerName(option)
      }
      isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
      onChange={(event: any, newValue: CarrierType | null, reason: string) => {
        onChange(newValue);
      }}
      onInputChange={(event, newValue: string, reason: string) => {
        if (reason !== 'reset') {
          setSearchValue(newValue);
        }
      }}
      renderOption={(props, carrierData: CarrierType) => {
        // removing className property as we dont need the special styling applied via autocomplete
        const { className, ...rest } = props;

        let listItemProps = {};

        const text = (
          <Typography variant="bodyRegular">
            {formatCustomerName(carrierData)}
          </Typography>
        );

        if (props['aria-selected']) {
          listItemProps = {
            secondaryAction: <CheckIcon sx={{ width: 16, height: 16 }} />,
          };
        }

        return (
          <ListItem {...rest} {...listItemProps}>
            <ListItemText>{text}</ListItemText>
          </ListItem>
        );
      }}
    />
  );
});

CarrierAutoCompleteTextField.displayName = 'CarrierAutoCompleteTextField';
