import { alpha } from '@mui/material/styles';

const scheduler = (palette) => {
  return {
    scheduler: {
      currentTimeIndicator: {
        borderColor: palette.green[100],
        backgroundColor: palette.green[100],
        color: palette.oxford[40],
      },
      gridPanelBody: {},
      gridHeader: {
        backgroundColor: palette.turquoise[40],
        borderColor: palette.oxford[12],
        color: palette.oxford[40],
        weekend: {
          backgroundColor: alpha(palette.oxford[20], 0.25),
        },
      },
      timeline: {
        backgroundColor: palette.turquoise[20],
        weekend: {
          backgroundColor: alpha(palette.oxford[20], 0.25),
        },
      },
      eventResizeHandle: {
        color: palette.turquoise[100],
      },
    },
  };
};

export default scheduler;
