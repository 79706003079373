const Tooltip = (theme) => {
  return {
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        tooltip: {
          color: theme.styles.tooltip.default.color,
          backgroundColor: theme.styles.tooltip.default.backgroundColor,
          textAlign: 'center',
          ...theme.typography.bodyRegularSmall,
          paddingTop: 12,
          paddingRight: 24,
          paddingBottom: 12,
          paddingLeft: 24,
          boxShadow: theme.styles.tooltip.default.boxShadow, //'0px 4px 32px rgba(0, 0, 0, 0.5)',
        },
        arrow: {
          color: theme.styles.tooltip.default.backgroundColor,
        },
      },
    },
  };
};

export default Tooltip;
