import React from 'react';
import { styled } from '@mui/material/styles';

import { Box } from '@dizzbo/ui';
import { PlusIcon } from '@dizzbo/ui';

import { useWorkspaces } from './hooks/useWorkspaces';

const TabContainerStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})<any>(({ theme, active }) => {
  return {
    display: 'flex',
    overflow: 'hidden',
    maxHeight: 48,
    width: 52,
    minWidth: 52,
    paddingTop: 14,
    paddingRight: 16,
    paddingBottom: 14,
    paddingLeft: 16,
    borderTopLeftRadius: theme.spacing(2),
    borderTopRightRadius: theme.spacing(2),
    backgroundColor: active
      ? theme.styles.listTab.active.backgroundColor
      : theme.styles.listTab.default.backgroundColor,
    color: active
      ? theme.styles.listTab.active.color
      : theme.styles.listTab.default.color,

    cursor: 'pointer',
    boxShadow: active ? theme.shadows[2] : 'none',
  };
});

const IconStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& svg': {
    width: 20,
    height: 20,
  },
}));

export const CreateTab: React.FC = () => {
  const { addWorkspace } = useWorkspaces();

  return (
    <TabContainerStyles onClick={() => addWorkspace()}>
      <IconStyles>
        <PlusIcon />
      </IconStyles>
    </TabContainerStyles>
  );
};
