import React from 'react';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { motion, AnimatePresence } from 'framer-motion';

import { Box, Chip } from '@dizzbo/ui';

const NavItemWrapperStyles = styled(Box)(({ theme }) => ({
  paddingTop: 12,
  paddingBottom: 12,
  height: 64,
}));

const NavItemStyles = styled(NavLink)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: 40,
  minHeight: 40,
  padding: 0,
  textAlign: 'left',
  justifyContent: 'start',
  paddingRight: 20,
  overflowX: 'hidden',
  ...theme.typography.buttonRegular,
  color: theme.palette.turquoise[100],
  textDecoration: 'none',
  backgroundColor: 'transparent',
  transition: theme.transitions.create('background'),

  '&.active': {
    color: theme.palette.green[100],
    backgroundColor: theme.palette.turquoise[200],
    borderRight: `3px solid ${theme.palette.green[100]}`,
  },
}));

const IconWrapperStyles = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginLeft: 20,
  marginRight: 20,
  height: 24,
  width: 24,
}));

const ContentWrapperStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  whiteSpace: 'nowrap',
  overflowX: 'hidden',
  width: '100%',
  alignItems: 'center',
}));

const NewItemsIndicatorStyles = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: -7,
  right: -7,
  width: 13,
  height: 13,
  borderRadius: 12,
  backgroundColor: '#ffffff',
  border: `3px solid ${theme.styles.appBar.backgroundColor}`,
}));

interface AppBarNavItemProps {
  Icon: React.FC<SvgIconProps>;
  label: string;
  route?: string;
  expanded: boolean;
  count: number;
}

export const AppBarNavItem = ({
  Icon,
  label,
  route,
  expanded,
  count,
}: AppBarNavItemProps) => {
  return (
    <NavItemWrapperStyles>
      <NavItemStyles to={route}>
        <IconWrapperStyles>
          <Icon sx={{ width: 24, height: 24 }} />
          {count > 0 && <NewItemsIndicatorStyles />}
        </IconWrapperStyles>
        <AnimatePresence>
          {expanded && (
            <motion.div
              key="AppBarNavItemContent"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              style={{ width: '100%' }}
            >
              <ContentWrapperStyles
                sx={{
                  whiteSpace: 'nowrap',
                  overflowX: 'hidden',
                  width: '100%',
                }}
              >
                {label}
                {count > 0 && (
                  <Chip
                    label={count}
                    size="small"
                    variant="turquoise-100"
                    sx={{ marginLeft: 'auto' }}
                  />
                )}
              </ContentWrapperStyles>
            </motion.div>
          )}
        </AnimatePresence>
      </NavItemStyles>
    </NavItemWrapperStyles>
  );
};
