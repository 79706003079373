export const enum QueryKeys {
  CARRIERS = 'carriers',
  CONTACTS = 'contacts',
  CUSTOMERS = 'customers',
  DELIVERIES = 'deliveries',
  DELIVERY_GOODS = 'delivery-goods',
  DEPARTMENTS = 'departments',
  FRONTEND_SETTINGS = 'frontend-settings',
  LOADING_POINTS = 'loading-points',
  ORDERS = 'orders',
  ORDER_DOCUMENTS = 'order-documents',
  ORDER_PODS = 'order-pods',
  ROUTES = 'routes',
  SECTIONS = 'sections',
  STOPS = 'stops',
  TOURS = 'tours',
  TRANSPOREON_INSIGHTS_RATE_ON_DEMAND = 'transporeon-insights-rate-on-demand',
  TYPE_OF_GOODS = 'type-of-goods',
  UNITS_OF_MEASUREMENT = 'units-of-measurement',
  USERS = 'users',
  VEHICLES = 'vehicles',
  TRANSPORT_CONTRACTS = 'transport-contracts',
  PARTICIPANTS = 'participants',
  ASSIGNEES = 'assignees',
  CUSTOMER_CONTACTS = 'customer-contacts',
  CARRIER_CONTACTS = 'carrier-contacts',
  TODOS = 'todos',
  TEAMS = 'teams',
  ORDER_EVENTS = 'order-events',
}

export const enum WorkspaceKeys {
  ORDER_LIST = 'orderListWorkspaces',
  TOUR_LIST = 'toursListWorkspaces',
}
