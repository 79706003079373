import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, IconBullet } from '@dizzbo/ui';
import { IconBulletProps } from '@dizzbo/ui/IconBullet/IconBullet.types';
import {
  LoadingIcon,
  UnloadingIcon,
  LoadingAndUnloadingIcon,
  DragBarsIcon,
  LocationIcon,
} from '@dizzbo/ui';

const IndexIndicatorStyle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isAddStopItem',
})<any>(({ theme, isAddStopItem }) => {
  return {
    cursor: isAddStopItem ? 'auto' : 'grab',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alingItems: 'center',
  };
});

export const LoadingPointBullet = ({
  index,
  actions = [],
  dragHandleAttributes,
  dragHandleListeners,
  isAddStopItem = false,
}: {
  index: number;
  actions?: string[];
  dragHandleAttributes?: DraggableAttributes;
  dragHandleListeners?: SyntheticListenerMap | undefined;
  isAddStopItem?: boolean;
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const renderIndexContent = () => {
    if (isHovering && !isAddStopItem) {
      return <DragBarsIcon />;
    } else {
      return (
        <Box
          sx={{
            width: 20,
            height: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {index + 1}
        </Box>
      );
    }
  };
  let icon = <LocationIcon />;

  const iconBulletProps: IconBulletProps = {
    variant: 'secondary',
  };

  if (actions.includes('unloading')) {
    iconBulletProps.variant = 'bright';
    icon = <UnloadingIcon />;
  }

  if (actions.includes('loading')) {
    iconBulletProps.variant = 'brightDark';
    icon = <LoadingIcon />;
  }

  if (['unloading', 'loading'].every((i) => actions.includes(i))) {
    iconBulletProps.variant = 'mixed';
    icon = <LoadingAndUnloadingIcon />;
  }

  return (
    <IndexIndicatorStyle
      {...dragHandleAttributes}
      {...dragHandleListeners}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      isAddStopItem={isAddStopItem}
    >
      <IconBullet size="large" {...iconBulletProps}>
        {renderIndexContent()}
        {icon}
      </IconBullet>
    </IndexIndicatorStyle>
  );
};

export default LoadingPointBullet;
