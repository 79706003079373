import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DashboardIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 11V2H11V11H2ZM4 4H9V9H4V4Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 22V13H11V22H2ZM4 15H9V20H4V15Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 2V11H22V2H13ZM20 4H15V9H20V4Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 22V13H22V22H13ZM15 15H20V20H15V15Z"
        />
      </SvgIcon>
    );
  }
);

DashboardIcon.displayName = 'DashboardIcon';

export default DashboardIcon;
