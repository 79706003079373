import React, { useRef } from 'react';
import { BryntumSchedulerPro } from '@bryntum/schedulerpro-react';
import { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro';

import { SchedulerStylesWrapper } from '@scheduler/components/SchedulerStylesWrapper';

import { schedulerConfig } from './config';
import { useTourDetail } from '../../../hooks';

type Props = {
  onChange: (dateRange: DateRange<Dayjs> | null) => void;
};

export const TourScheduler: React.FC<Props> = ({ onChange }) => {
  const { deliveriesData, ordersData } = useTourDetail();

  const syncData = async (event) => {};

  const schedulerProRef = useRef<BryntumSchedulerPro>(null);

  // set visible date range on scheduler
  // useEffect(() => {
  //   if (!schedulerProRef.current?.instance) return;

  //   if (!visibleDate) return;

  //   schedulerProRef.current.instance.startDate = visibleDate
  //     .startOf('day')
  //     .toDate();
  //   schedulerProRef.current.instance.endDate = visibleDate
  //     .endOf('day')
  //     .toDate();
  // }, [schedulerProRef.current?.instance, visibleDate]);

  const handleDeleteEvent = () => {
    onChange(null);
  };

  const assignments = [
    {
      id: 1,
      event: 'bb9fffbd-c556-4d45-8a5d-1374786a6a3b',
      resource: '5e409e4a-0a41-4cf8-b8f2-3908ad25e353',
    },
    {
      id: 2,
      event: 'af972f7c-31e8-4c17-b58b-3b4fcf4fed1d',
      resource: '5e409e4a-0a41-4cf8-b8f2-3908ad25e353',
    },
  ];

  const resources = ordersData ?? [];
  const events = deliveriesData ?? [];
  console.log('resources', resources);
  console.log('events', events);
  return (
    <SchedulerStylesWrapper sx={{ minHeight: 210 }}>
      <BryntumSchedulerPro
        ref={schedulerProRef}
        resources={resources}
        events={events}
        // assignments={assignments}
        onDataChange={syncData}
        // onBeforeEventDelete={handleDeleteEvent}
        {...schedulerConfig}
        eventDragCreateFeature={{ disabled: true }}
      />
    </SchedulerStylesWrapper>
  );
};
