import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { StopListItem } from './StopListItem';
import { useTheme } from '@mui/system';

type Props = {
  index: number;
  stopUUID: UUIDType;
  lastItem: boolean;
};

export const SortableItem: React.FC<Props> = ({
  index,
  stopUUID,
  lastItem,
}: Props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: stopUUID });
  const theme = useTheme();

  const style = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : 'translate3d(0px, 0px, 0)',
    // transform: CSS.Transform.toString(transform),
    zIndex: isDragging ? 99999999 : 1,
    transition,
    width: '100%',
    boxShadow: isDragging ? theme.shadows[3] : 'none',
  };

  return (
    <StopListItem
      index={index}
      key={index}
      stopUUID={stopUUID}
      isDragging={isDragging}
      dragHandleAttributes={attributes}
      dragHandleListeners={listeners}
      ref={setNodeRef}
      style={style}
      lastItem={lastItem}
    />
  );
};
