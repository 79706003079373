import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ChildOfIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M13 2H11V14.5H19.5857L18.2928 15.7929L19.7071 17.2071L23.4142 13.5L19.7071 9.79289L18.2928 11.2071L19.5857 12.5H13V2Z" />
      </SvgIcon>
    );
  }
);

ChildOfIcon.displayName = 'ChildOfIcon';

export default ChildOfIcon;
