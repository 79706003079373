import React from 'react';
import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';

import { Box, LicensePlate, MinimalIconButton } from '@dizzbo/ui';
import { TruckIcon, InfoOutlineIcon } from '@dizzbo/ui';

import { TruckListItemProgressIndicator } from './TruckListItemProgressIndicator';

const ContainerStyles = styled(Box)(({ theme }) => ({
  height: '100%',
  padding: 12,
  minWidth: '100%',
  display: 'flex',
  backgroundColor: theme.palette.turquoise[20],
}));

const IndicatorStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
}));

const ContentStyles = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
}));

const LicensePlateStyles = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  alignItems: 'center',
  marginBottom: 8,
}));

const FooterStyles = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const CarrierNameStyles = styled(Box)(({ theme }) => ({
  maxWidth: 180,
  ...theme.typography.tiny,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export type Props = {
  data: any;
  sx?: SxProps;
};

export const TruckListItem = ({ data }: Props) => {
  const workload = Math.floor(Math.random() * 100) + 1;
  let color = 'secondary';
  if (workload <= 50) {
    color = 'warning';
  }
  if (workload <= 20) {
    color = 'error';
  }

  return (
    <ContainerStyles>
      <IndicatorStyles>
        <Box sx={{ width: 24, height: 24 }}>
          <TruckListItemProgressIndicator
            value={workload}
            size={24}
            thickness={6}
            color={color}
          />
        </Box>
      </IndicatorStyles>
      <ContentStyles>
        <LicensePlateStyles>
          <TruckIcon sx={{ width: 16 }} />
          <LicensePlate
            sx={{ marginLeft: 2 }}
            size="medium"
            countryCode="DE"
            number={data.originalData.licensePlate}
          />
        </LicensePlateStyles>
        <FooterStyles>
          <CarrierNameStyles>{data.originalData.carrierName}</CarrierNameStyles>
          <MinimalIconButton size="tiny" sx={{ marginLeft: 2 }}>
            <InfoOutlineIcon />
          </MinimalIconButton>
        </FooterStyles>
      </ContentStyles>
    </ContainerStyles>
  );
};
