const Radio = (theme) => {
  return {
    MuiRadio: {
      defaultProps: {
        sx: {},
      },
      styleOverrides: {
        root: {
          '.MuiSvgIcon-root': {
            width: 16,
            height: 16,
          },
          '.MuiBox-root': {
            width: 16,
            height: 16,
            borderRadius: 16,
          },
          '&:hover': {
            '.MuiBox-root': {
              boxShadow: `0px 0px 0px 2px ${theme.styles.input.hover.borderColor}`,
            },
          },
          '&:focus': {
            '.MuiBox-root': {
              boxShadow: `0px 0px 0px 3px ${theme.styles.input.hover.borderColor}`,
            },
          },
          '.MuiFormControlLabel-label': {
            ...theme.typography.label,
          },
        },
      },
    },
  };
};

export default Radio;
