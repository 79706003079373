import { useQueries, UseQueryResult } from '@tanstack/react-query';

import { getRouteSection } from '@core/api';

import { QueryKeys } from '@core/config';
import { RouteSectionType, UUIDType } from '@types';

type GetRouteSectionsData = {
  data: RouteSectionType[];
  isFetching: boolean;
  isLoading: boolean;
};

export const useGetRouteSections = (
  routeUUID: UUIDType,
  sections: Array<RouteSectionType>
): GetRouteSectionsData => {
  const routeSections: Array<RouteSectionType> = sections || [];

  return useQueries({
    queries: routeSections.map((section) => {
      return {
        queryKey: [
          QueryKeys.ROUTES,
          routeUUID,
          QueryKeys.SECTIONS,
          section.uuid,
        ],
        queryFn: () => getRouteSection(routeUUID, section.uuid),
      };
    }),
    combine: (results: Array<UseQueryResult<RouteSectionType, Error>>) => {
      return {
        data: results.map((result) => result.data),
        isFetching: results.some((result) => result.isFetching),
        isLoading: results.some((result) => result.isLoading),
      };
    },
  });
};
