import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { createOrderDocument } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DocumentUploadField,
  Stack,
  UploadIcon,
} from '@dizzbo/ui';
import { UUIDType } from '@types';
import { useTranslation } from 'react-i18next';

type Props = {
  popupState: PopupState;
  orderUUID: UUIDType;
};

export const DocumentUploadDialog: React.FC<Props> = ({
  popupState,
  orderUUID,
}) => {
  const queryClient = useQueryClient();
  const [progress, setProgress] = useState(null);

  const {
    mutate: mutateOrderDocument,
    isError,
    error,
  }: any = useMutation({
    mutationKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.ORDER_DOCUMENTS],
    mutationFn: (values) =>
      createOrderDocument(orderUUID, values, (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
      }),
  });

  const { control, watch } = useForm({
    mode: 'all',
    defaultValues: {
      title: '',
      file: null,
    },
  });

  const watchFileChange = watch('file');

  useEffect(() => {
    if (watchFileChange) {
      mutateOrderDocument(
        {
          order: orderUUID,
          file: watchFileChange.file,
          title: watchFileChange.name,
        },
        {
          onSuccess: (data, values) => {
            queryClient.invalidateQueries({
              queryKey: [
                QueryKeys.ORDERS,
                orderUUID,
                QueryKeys.ORDER_DOCUMENTS,
              ],
            });
            popupState.close();
            toast.success(`Document ${watchFileChange.name} uploaded.`);
          },
          onError: (error, variables, context) => {
            popupState.close();
            toast.error(`Document could not be uploaded.`);
          },
        }
      );
    }
  }, [watchFileChange]);

  const { t } = useTranslation();

  return (
    <>
      <Dialog
        PaperProps={{
          elevation: 6,
          variant: 'filled-primary',
          sx: {
            minWidth: 600,
          },
        }}
        scroll="paper"
        {...bindDialog(popupState)}
      >
        <DialogTitle onClose={popupState.close}>
          <UploadIcon />
          {t('uploadDocument')}
        </DialogTitle>
        <DialogContent dividers>
          <Stack spacing={6}>
            <Controller
              name="file"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <DocumentUploadField progress={progress} {...field} />
              )}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};
