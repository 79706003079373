import ShadowLeftImage from "../../assets/images/shadows/shadow-vertical-left-dark.png";
import ShadowRightImage from "../../assets/images/shadows/shadow-vertical-right-dark.png";
import ShadowHorizontalImage from "../../assets/images/shadows/shadow-horizontal-dark.png";

const images = (palette) => {
  return {
    images: {
      shadowHorizontal: ShadowHorizontalImage,
      shadowVerticalLeft: ShadowLeftImage,
      shadowVerticalRight: ShadowRightImage,
    },
  };
};

export default images;
