import { Cell, TableInstance } from '@dizzbo/ui';
import { OrderType } from '@types';
import dayjs from 'dayjs';
import React, { ReactNode } from 'react';
import { TextCellContent } from '.';

type Props = {
  cell: Cell<OrderType>;
  renderedCellValue: ReactNode;
  table: TableInstance<OrderType>;
};

export const DateCell: React.FC<Props> = ({
  cell: {
    row: { original },
  },
  renderedCellValue,
  table,
}) => (
  <TextCellContent highlighted={original.todos.length > 0} table={table}>
    {typeof renderedCellValue === 'string'
      ? dayjs(renderedCellValue).format('DD.MM.YY h:mm')
      : null}
  </TextCellContent>
);
