import React from 'react';
import { MenuItem } from '@mui/material';
import { BasicSelect } from '@dizzbo/ui';

interface RowNumberSelectorProps {
  labelRowsPerPage: string;
  rowsPerPageOptions: number[];
  setPagination?: any;
  pageSize: number;
}

const RowNumberSelector = ({
  labelRowsPerPage,
  rowsPerPageOptions,
  setPagination,
  pageSize,
}: RowNumberSelectorProps) => {
  const handleRowsPerPageChange = (event: any) => {
    const pageSize = parseInt(event.target.value, 10);
    setPagination({ pageIndex: 0, pageSize: pageSize });
  };

  return (
    <div style={{ width: 'auto' }}>
      {rowsPerPageOptions.length > 1 && (
        <BasicSelect
          id='test'
          label={labelRowsPerPage}
          value={pageSize}
          onChange={handleRowsPerPageChange}
        >
          {rowsPerPageOptions.map((rowsPerPageOption) => (
            <MenuItem key={rowsPerPageOption} value={rowsPerPageOption}>
              {rowsPerPageOption}
            </MenuItem>
          ))}
        </BasicSelect>
      )}
    </div>
  );
};

export default RowNumberSelector;
