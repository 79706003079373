import { LinearProgress as MuiLinearProgress } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiLinearProgressProps = ComponentProps<typeof MuiLinearProgress>;

export const LinearProgress = forwardRef<
  HTMLDivElement,
  MuiLinearProgressProps
>((props, ref) => <MuiLinearProgress ref={ref} {...props} />);

LinearProgress.displayName = 'LinearProgress';
