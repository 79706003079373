import React, { ReactNode } from 'react';

import { getStatusColor, getStatusLabel } from '@dizzbo/core/utils/orderStatus';
import { TableInstance, Typography } from '@dizzbo/ui';
import { OrderType } from '@types';

type Props = {
  renderedCellValue: ReactNode;
  table: TableInstance<OrderType>;
};

export const StatusCell: React.FC<Props> = ({
  renderedCellValue: status,
  table,
}: Props) => {
  const { getState } = table;
  const { density } = getState();

  const color = getStatusColor(status as string);
  const statusText = getStatusLabel(status as string);
  const textVariant = density === 'compact' ? 'bodyBoldSmall' : 'bodyBold';

  return (
    <Typography
      variant={textVariant}
      sx={{ textTransform: 'capitalize' }}
      color={color}
    >
      {statusText}
    </Typography>
  );
};
