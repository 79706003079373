import { yupResolver } from '@hookform/resolvers/yup';
import { keyframes, styled } from '@mui/material/styles';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  DizzboLogo,
  Link,
  LoadingButton,
  Stack,
  TextField,
  Typography,
} from '@dizzbo/ui';

import { postUserCredentials } from '@dizzbo/core/api';
import { useAuth, useFieldErrors } from '@dizzbo/core/hooks';

import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

const shake = keyframes`
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
`;

type AnimatedCardProps = {
  isError?: boolean;
};

const AnimatedCardStyles = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isError',
})<AnimatedCardProps>(({ theme, isError }) => {
  if (isError) {
    return {
      animation: `${shake} 0.82s cubic-bezier(.36,.07,.19,.97) both`,
    };
  }
  return {};
});

const schema = yup.object().shape({
  email: yup
    .string()
    .email(i18n.t('pleaseEnterAValidEmailAddress'))
    .required(i18n.t('pleaseEnterAnEmailAddress')),
});

export const OTPLoginForm = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'onBlur',
    delayError: 300,
    resolver: yupResolver(schema),
  });
  const { loginUser } = useAuth();

  const authMutation = useMutation({
    mutationFn: (values) => {
      return postUserCredentials(values);
    },
    onSuccess: (data: any, values: any) => {
      loginUser(data);
    },
    onError: (error: any, variables: any, context: any) => {
      console.log(error.response, variables, context);
    },
  });

  const { hasFieldError, fieldError } = useFieldErrors(
    authMutation.isError,
    errors,
    authMutation.error
  );

  const onSubmit = (formData: any) => {
    authMutation.mutate(formData);
  };

  const { t } = useTranslation();

  return (
    <AnimatedCardStyles isError={authMutation.isError}>
      <Card
        elevation={6}
        variant="filled-primary"
        sx={{
          minWidth: 400,
        }}
      >
        <CardHeader
          avatar={<DizzboLogo sx={{ width: 74, height: 16 }} dark />}
        />
        <CardHeader title="TMS Login" variant="small" />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={6}>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <TextField
                    type="email"
                    label="Email address"
                    error={hasFieldError(field, fieldState)}
                    helperText={fieldError(field, fieldState)}
                    {...field}
                  />
                )}
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="primary"
                loading={authMutation.isPending}
                disabled={!isDirty || !isValid}
              >
                {t('login')}
              </LoadingButton>
              <Divider />
              <Typography variant="bodyRegularSmall">
                {t('ifYouNeedHelpWithYourLogInContactUs')}: <br />
                <Link variant="textLinkRegularSmall" href="#">
                  support@dizzbo.com
                </Link>
              </Typography>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </AnimatedCardStyles>
  );
};
