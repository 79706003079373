import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PlayIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path fillRule="evenodd" clipRule="evenodd" d="M5 22L22 12L5 2L5 22Z" />
      </SvgIcon>
    );
  }
);

PlayIcon.displayName = 'PlayIcon';

export default PlayIcon;
