import { FormControlLabel } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SettingsGearIcon,
  Stack,
  Switch,
  Typography,
} from '@dizzbo/ui';

import {
  EmissionTypeType,
  RouteModeType,
  RouteSettingsType,
  RouteType,
} from '@types';
import { useUpdateRouteSettings } from './hooks';

type Props = {
  route: RouteType;
};

const emissiontTypes = [
  { value: 'euro1', label: 'Euro 1' },
  { value: 'euro2', label: 'Euro 2' },
  { value: 'euro3', label: 'Euro 3' },
  { value: 'euro4', label: 'Euro 4' },
  { value: 'euro5', label: 'Euro 5' },
  { value: 'euro6', label: 'Euro 6' },
  { value: 'euroEev', label: 'Euro EEV' },
];

const axleCountChoices = [
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
];

const grossWeightChoices = [
  { value: 3500, label: '≤ 3,5t' },
  { value: 7490, label: '≤ 7,49t' },
  { value: 7500, label: '≤ 7,5t' },
  { value: 11999, label: '≤ 11,99t' },
  { value: 12000, label: '≤ 12t' },
  { value: 18000, label: '≤ 18t' },
  { value: 32000, label: '≤ 32t' },
  { value: 40000, label: '≤ 40t' },
];

export const RouteSettings: React.FC<Props> = ({ route }) => {
  const { t } = useTranslation();
  const { settings } = route;
  const { mutateAsync } = useUpdateRouteSettings(route.uuid);

  const setRouteSettings = async (data: RouteSettingsType) => {
    try {
      await mutateAsync({ settings: { ...settings, ...data } });
    } catch (error) {
      console.error(error);
    }
    await mutateAsync(data);
  };

  return (
    <Stack p={6} spacing={6}>
      <Stack alignItems="center" direction="row">
        <SettingsGearIcon sx={{ width: 20, height: 20, marginRight: 3 }} />
        <Typography variant="h6">{t('settings')}</Typography>
      </Stack>
      <Box>
        <FormControlLabel
          sx={{ marginLeft: -2 }}
          control={
            <Switch
              name="avoid-tolls"
              checked={settings?.avoidToll}
              onChange={() =>
                setRouteSettings({ avoidToll: !settings?.avoidToll })
              }
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={t('avoidTolls')}
        />
      </Box>
      <Box>
        <FormControlLabel
          sx={{ marginLeft: -2 }}
          control={
            <Switch
              name="avoid-ferry"
              checked={settings?.avoidFerry}
              onChange={() =>
                setRouteSettings({ avoidFerry: !settings?.avoidFerry })
              }
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={t('avoidFerries')}
        />
      </Box>
      <Box>
        <FormControlLabel
          sx={{ marginLeft: -2 }}
          control={
            <Switch
              name="avoid-switzerland"
              checked={settings?.avoidSwitzerland}
              onChange={() =>
                setRouteSettings({
                  avoidSwitzerland: !settings?.avoidSwitzerland,
                })
              }
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={t('avoidSwitzerland')}
        />
      </Box>

      <Divider />
      <Box>
        <FormControl sx={{ marginLeft: -2 }}>
          <RadioGroup
            aria-labelledby="route-mode-radio-buttons-group"
            name="route-mode"
            value={settings.routingMode}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setRouteSettings({
                routingMode: event.currentTarget.value as RouteModeType,
              })
            }
          >
            <FormControlLabel
              value="short"
              label={t('shortestRoute')}
              control={<Radio />}
              sx={{ marginBottom: 2 }}
            />
            <FormControlLabel
              value="fast"
              label={t('fastestRoute')}
              control={<Radio />}
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Divider />

      <FormControl fullWidth>
        <InputLabel id="vehicle-type-label">{t('emissionType')}</InputLabel>
        <Select
          labelId="emission-type-label"
          id="emission-type"
          label="emission-type"
          value={settings.emissionType}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
            setRouteSettings({
              emissionType: event.target.value as EmissionTypeType,
            })
          }
        >
          {emissiontTypes.map((emissiontType) => (
            <MenuItem key={emissiontType.value} value={emissiontType.value}>
              {emissiontType.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="vehicle-type-label">{t('axleCount')}</InputLabel>
        <Select
          labelId="axle-count-label"
          id="axle-count"
          label="axle-count"
          value={settings.vehicleAxles}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
            setRouteSettings({
              vehicleAxles: event.target.value as number,
            })
          }
        >
          {axleCountChoices.map((axleCountChoice) => (
            <MenuItem key={axleCountChoice.value} value={axleCountChoice.value}>
              {axleCountChoice.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel id="gross-weight-label">{t('grossWeight')}</InputLabel>
        <Select
          labelId="gross-weight-label"
          id="gross-weight"
          label="gross-weight"
          value={settings.vehicleWeight}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
            setRouteSettings({
              vehicleWeight: event.target.value as number,
            })
          }
        >
          {grossWeightChoices.map((grossWeightChoice) => (
            <MenuItem
              key={grossWeightChoice.value}
              value={grossWeightChoice.value}
            >
              {grossWeightChoice.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};
