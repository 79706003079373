import React from 'react';

import { Card, CardContent } from '@dizzbo/ui';

import { CarrierType, UUIDType, VehicleType } from '@types';
import { VehicleDisplay } from './VehicleDisplay';
import { VehicleForm } from './VehicleForm';

type Props = {
  tourUUID: UUIDType;
  orderUUID: UUIDType;
  carrier?: CarrierType;
  vehicle?: VehicleType;
};

export const VehicleSlot: React.FC<Props> = ({
  tourUUID,
  orderUUID,
  carrier,
  vehicle,
}) => {
  return (
    <Card variant="filled-secondary" elevation={0}>
      <CardContent>
        {vehicle ? (
          <VehicleDisplay tourUUID={tourUUID} vehicle={vehicle} />
        ) : (
          <VehicleForm
            tourUUID={tourUUID}
            orderUUID={orderUUID}
            carrier={carrier}
          />
        )}
      </CardContent>
    </Card>
  );
};
