import { getTour } from '@core/api';
import { QueryKeys } from '@core/config';
import { useQuery } from '@tanstack/react-query';
import { UUIDType } from '@types';

export const useGetTour = (uuid: UUIDType, staleTime: number = 0) => {
  return useQuery({
    queryKey: [QueryKeys.TOURS, uuid],
    queryFn: () => getTour(uuid),
    staleTime: staleTime,
  });
};
