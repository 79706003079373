import { FormHelperText } from '../../components/FormHelperText';

const Input = (theme) => {
  return {
    MuiInputBase: {
      defaultProps: {
        sx: {},
      },
      styleOverrides: {
        root: {
          boxShadow: '0px 0px 0px 0px transparent',
          borderRadius: 4,
          border: `1px solid ${theme.styles.input.default.borderColor}`,
          color: theme.styles.input.default.color,
        },
        input: {
          borderRadius: 4,
          backgroundColor: theme.styles.input.default.backgroundColor,
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {},
      styleOverrides: {
        root: {
          '&.MuiInputLabel-sizeSmall': {
            fontWeight: 400,
            fontSize: 15,
            color: theme.styles.inputLabel.default.color,
            transition:
              'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
            transform: 'translate(12px, 10px) scale(1)',
            '&.Mui-focused, &.MuiFormLabel-filled ': {
              fontSize: 16,
              fontWeight: 500,
              transform: 'translate(12px, 1px) scale(0.75)',
            },
            '&.Mui-disabled': {
              color: theme.styles.inputLabel.disabled.color,
            },
          },
        },
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: theme.styles.input.default.backgroundColor,
          transition: theme.transitions.create([
            'border-color',
            'border-size',
            // 'background-color',
            'box-shadow',
          ]),
          '& .MuiInputBase-inputMultiline': {
            backgroundColor: 'transparent',
          },
          '&:hover': {
            backgroundColor: theme.styles.input.default.backgroundColor,
            border: `1px solid ${theme.styles.input.hover.borderColor}`,
          },
          '&.Mui-focused': {
            backgroundColor: theme.styles.input.default.backgroundColor,
            border: `1px solid ${theme.styles.input.hover.borderColor}`,
            boxShadow: `0px 0px 0px 1px ${theme.styles.input.hover.borderColor}`,
            // Styling the EndAdornment Typography if the input is focused
            // Styles if the input has content are also applied via TextField Theme Overrides
            '.MuiInputAdornment-root': {
              '.MuiTypography-root': {
                ...theme.typography.bodyRegular,
                color: theme.palette.primary.dark,
                transform: 'translate(0px, 7px) scale(1)',
              },
            },
          },
          '&.Mui-error': {
            border: '1px solid #C25253',
            boxShadow: 'none',
          },
          '&.MuiInputBase-colorWarning': {
            backgroundColor: theme.styles.input.warning.backgroundColor,
            input: {
              backgroundColor: theme.styles.input.warning.backgroundColor,
            },
          },
          '&.Mui-disabled': {
            backgroundColor: theme.styles.input.disabled.backgroundColor,
            border: `1px solid ${theme.styles.input.disabled.borderColor}`,
            '&:hover': {
              border: `1px solid ${theme.styles.input.disabled.borderColor}`,
            },
            input: {
              backgroundColor: 'transparent',
            },
            svg: {
              color: theme.styles.input.disabled.iconColor,
            },
            '.MuiTypography-root': {
              color: `${theme.styles.input.disabled.iconColor} !important`,
            },
          },
          '.MuiInputAdornment-root': {
            color: theme.styles.input.default.color,
            position: 'relative',
            '.MuiTypography-root': {
              transition:
                'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
              ...theme.typography.bodyRegular,
              color: theme.styles.inputLabel.default.color,
            },
            '.MuiIconButton-root': {
              padding: 5,
              background: 'transparent',
              '&:not(.MuiMinimalIconButton-root)': {
                color: theme.styles.input.default.color,
              },
              svg: {
                height: 20,
                width: 20,
              },
            },
          },
        },
        sizeSmall: {
          paddingRight: 0,
          input: {
            height: 24,
            paddingTop: 14,
            paddingBottom: 0,
            fontSize: 15,
          },
        },
        disabled: {},
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        component: FormHelperText,
      },
      styleOverrides: {
        root: {},
      },
    },
  };
};

export default Input;
