import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const GoodIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3.88196L21 8.38196V18.154L12 22.0915L3 18.154V8.38196L12 3.88196ZM5 16.846V10.529L7.86907 11.7843L7.92581 11.9048L8.03125 11.8552L10.9999 13.154V19.471L5 16.846ZM10.4352 10.7239L11.9999 11.4085L17.6431 8.93958L15.9868 8.11142L10.4352 10.7239ZM13.7094 6.97274L12 6.11803L6.35683 8.93961L8.00113 9.65899L13.7094 6.97274ZM19 16.846L12.9999 19.471V13.154L19 10.529V16.846Z"
        />
      </SvgIcon>
    );
  }
);

GoodIcon.displayName = 'GoodIcon';

export default GoodIcon;
