import { Stack, Typography } from '@dizzbo/ui';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'; // import plugin
import React from 'react';
import { useTranslation } from 'react-i18next';
dayjs.extend(duration);

export const StopSectionSummary: React.FC<any> = ({
  length,
  duration,
  totalToll,
}: {
  duration: number;
  length: number;
  totalToll: number;
}) => {
  const formatDuration = () => {
    return dayjs.duration(duration, 'seconds').format('D [d] H [h] m [min]');
  };

  const { t } = useTranslation();

  return (
    <Stack spacing={2} direction="row">
      <Typography variant="footnote" color="primary">
        {length / 1000} km
      </Typography>
      <Typography variant="footnote" color="primary">
        {formatDuration()}
      </Typography>
      <Typography variant="footnote" color="primary">
        {t('toll')} {totalToll} €
      </Typography>
    </Stack>
  );
};

StopSectionSummary.displayName = 'StopSectionSummary';

export default StopSectionSummary;
