import dayjs from 'dayjs';
import React, { ReactNode } from 'react';

import { Chip, Stack, TableInstance, Tooltip } from '@dizzbo/ui';
import { OrderType, StopType } from '@types';

type Props = {
  renderedCellValue: ReactNode;
  table: TableInstance<OrderType>;
};

export const StopListCell: React.FC<Props> = ({
  renderedCellValue: stops,
}: Props) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    spacing={3}
    sx={{
      position: 'relative',
      zIndex: 0,
      '&::after': {
        content: "' '",
        position: 'absolute',
        width: '100%',
        height: 2,
        backgroundColor: 'rgba(153, 163, 178, 0.50)',
        opacity: 0.2,
        top: 7,
        zIndex: -1,
        // bottom: 12,
        // right: -12,
      },
    }}
  >
    {(stops as StopType[]).map((stop, index) => (
      <Tooltip
        key={stop.uuid}
        title={`${stop.loadingPoint.name} ${
          stop.loadingPoint.address.formatted
        }  ${
          stop.scheduledAt
            ? dayjs(stop.scheduledAt).format('DD.MM.YYYY hh:mm')
            : ''
        }`}
        placement="top"
      >
        <Chip
          label={stop?.loadingPoint.address.country}
          size="small"
          variant={
            index === 0 || index === (stops as StopType[]).length - 1
              ? 'outline-bold'
              : 'outlined'
          }
          sx={{
            backgroundColor: '#FFFFFF !important',
          }}
        />
      </Tooltip>
    ))}
  </Stack>
);
