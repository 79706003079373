import H from '@here/maps-api-for-javascript/bin/mapsjs.bundle.harp.js';

export const getStopMarker = (
  sectionIndex: number,
  position: H.geo.IPoint | H.geo.MultiPoint,
  options?: H.map.Marker.Options
): H.map.Marker => {
  const iconText = String(sectionIndex + 1);
  const textColor = '#C2E6E3';
  const fillColor = '#4A73C7';

  const svgMarkup = `<svg width="24" height="41" style="margin-bottom: -20px" viewBox="0 0 24 41" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="33" r="8" fill="url(#paint0_radial_4508_12148)"/><circle cx="12" cy="29" r="4" fill="white"/><path d="M15.7266 23.4101C20.5296 21.8424 24 17.3265 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.3265 3.47037 21.8424 8.2734 23.4101L10.8905 27.3357C10.3536 27.6944 10 28.3059 10 29C10 30.1046 10.8954 31 12 31C13.1046 31 14 30.1046 14 29C14 28.3059 13.6464 27.6944 13.1095 27.3357L15.7266 23.4101Z" fill="#4A73C7"/><text x="12" y="16" font-size="10px" style="font-family:EuclidCircularARegular, Helvetica Neue, Helvetica, Arial, sans-serif;" font-weight="600" text-anchor="middle" fill="${textColor}" >${iconText}</text><defs><radialGradient id="paint0_radial_4508_12148" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12 33) rotate(90) scale(8)"><stop stop-color="#081324" stop-opacity="0.3"/><stop offset="1" stop-color="#081324" stop-opacity="0"/></radialGradient></defs></svg>`;
  const icon = new H.map.Icon(svgMarkup, { anchor: { x: 12, y: 20 } });

  return new H.map.Marker(position, options).setIcon(icon);
};
