import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PaymentLockedIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M1.25 7.75C1.25 4.02208 4.27208 1 8 1C10.0367 1 11.8627 1.90204 13.1003 3.32842C12.5656 3.4846 12.0702 3.69406 11.6241 3.95153C10.6818 3.05223 9.4054 2.5 8 2.5C5.10051 2.5 2.75 4.85051 2.75 7.75C2.75 9.95678 4.11156 11.8456 6.04043 12.6221C5.7185 13.0087 5.46014 13.45 5.2814 13.9302C2.90737 12.8844 1.25 10.5107 1.25 7.75Z" />
        <path d="M9.17393 10.574V9.33823C8.85462 9.55109 8.47261 9.64646 8.03057 9.64646C7.10319 9.64646 6.40764 9.18052 6.11338 8.50204H8.7172L8.88662 8.04428H5.97962C5.9707 7.94619 5.96178 7.84809 5.96178 7.75C5.96178 7.64373 5.9707 7.54564 5.98853 7.44755H9.11847L9.2698 7.05219C9.27472 7.02858 9.27981 7.00505 9.28509 6.98161H6.12229C6.41656 6.31131 7.1121 5.85354 8.03057 5.85354C8.61295 5.85354 9.09114 6.01909 9.45902 6.40075C9.60049 6.02584 9.79206 5.67602 10.028 5.35436C9.4807 4.9618 8.78826 4.75 8.02166 4.75C6.46115 4.75 5.1949 5.66553 4.8293 6.98161H4.18726L4 7.44755H4.74904C4.74013 7.54564 4.73121 7.64374 4.73121 7.75C4.73121 7.84809 4.74013 7.94618 4.74904 8.04427L4.18726 8.04428L4 8.50204H4.8293C5.17707 9.82629 6.46115 10.75 8.02166 10.75C8.43042 10.75 8.8181 10.6898 9.17393 10.574Z" />
        <path d="M13.5 15H11.5V17H13.5V15Z" />
        <path d="M17.5 17V15H15.5V17H17.5Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 8.5C10 6.01472 12.0147 4 14.5 4C16.9853 4 19 6.01472 19 8.5V11C21.2091 11 23 12.7909 23 15V17C23 19.2091 21.2091 21 19 21H10C7.79086 21 6 19.2091 6 17V15C6 12.7909 7.79086 11 10 11V8.5ZM17 8.5V11H12V8.5C12 7.11929 13.1193 6 14.5 6C15.8807 6 17 7.11929 17 8.5ZM8 15C8 13.8954 8.89543 13 10 13H19C20.1046 13 21 13.8954 21 15V17C21 18.1046 20.1046 19 19 19H10C8.89543 19 8 18.1046 8 17V15Z"
        />
      </SvgIcon>
    );
  }
);

PaymentLockedIcon.displayName = 'PaymentLockedIcon';

export default PaymentLockedIcon;
