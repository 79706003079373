import { VehiclePositionType } from '@types';
import { MutableRefObject, useEffect } from 'react';
import { getVehicleMarker } from '../utils';

export const useRenderVehicles = (
  hereMapRef: MutableRefObject<H.Map> | undefined,
  vehicles: VehiclePositionType[]
) => {
  useEffect(() => {
    if (!hereMapRef.current) return;
    const map = hereMapRef.current as H.map;

    const vehicleGroup = new H.map.Group();

    const vehicleMarkers = vehicles.map((vehicle, index) => {
      const position = {
        lat: vehicle.lat,
        lng: vehicle.lng,
      };
      const vehicleMarker = getVehicleMarker(position, vehicle.licensePlate);
      return vehicleMarker;
    });

    vehicleGroup.addObjects(vehicleMarkers);

    map.addObject(vehicleGroup);

    return () => {
      if (!map) return;

      if (vehicleMarkers.length && map.getObjects().includes(vehicleGroup)) {
        map.removeObject(vehicleGroup);
      }
    };
  }, [hereMapRef?.current, vehicles]);
};
