import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PhoneIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M10 4H14V3H10V4Z" />
        <path d="M10 11C10 11.5523 9.55228 12 9 12C8.44772 12 8 11.5523 8 11C8 10.4477 8.44772 10 9 10C9.55228 10 10 10.4477 10 11Z" />
        <path d="M12 12C12.5523 12 13 11.5523 13 11C13 10.4477 12.5523 10 12 10C11.4477 10 11 10.4477 11 11C11 11.5523 11.4477 12 12 12Z" />
        <path d="M16 11C16 11.5523 15.5523 12 15 12C14.4477 12 14 11.5523 14 11C14 10.4477 14.4477 10 15 10C15.5523 10 16 10.4477 16 11Z" />
        <path d="M9 15C9.55228 15 10 14.5523 10 14C10 13.4477 9.55228 13 9 13C8.44772 13 8 13.4477 8 14C8 14.5523 8.44772 15 9 15Z" />
        <path d="M10 17C10 17.5523 9.55228 18 9 18C8.44772 18 8 17.5523 8 17C8 16.4477 8.44772 16 9 16C9.55228 16 10 16.4477 10 17Z" />
        <path d="M12 15C12.5523 15 13 14.5523 13 14C13 13.4477 12.5523 13 12 13C11.4477 13 11 13.4477 11 14C11 14.5523 11.4477 15 12 15Z" />
        <path d="M13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z" />
        <path d="M12 21C12.5523 21 13 20.5523 13 20C13 19.4477 12.5523 19 12 19C11.4477 19 11 19.4477 11 20C11 20.5523 11.4477 21 12 21Z" />
        <path d="M16 14C16 14.5523 15.5523 15 15 15C14.4477 15 14 14.5523 14 14C14 13.4477 14.4477 13 15 13C15.5523 13 16 13.4477 16 14Z" />
        <path d="M15 18C15.5523 18 16 17.5523 16 17C16 16.4477 15.5523 16 15 16C14.4477 16 14 16.4477 14 17C14 17.5523 14.4477 18 15 18Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 0C6.34315 0 5 1.34314 5 3V21C5 22.6569 6.34315 24 8 24H16C17.6569 24 19 22.6569 19 21V3C19 1.34315 17.6569 0 16 0H8ZM7 3C7 2.44772 7.44771 2 8 2H16C16.5523 2 17 2.44772 17 3V21C17 21.5523 16.5523 22 16 22H8C7.44772 22 7 21.5523 7 21V3Z"
        />
      </SvgIcon>
    );
  }
);

PhoneIcon.displayName = 'PhoneIcon';

export default PhoneIcon;
