import { useTranslation } from 'react-i18next';

/**
 * Provides similar funcionality like useTranslation hook, but is ignored by string extractor script.
 * The string translations should be manually tracked, added and removed in untrackedTranslation.json files in locale.
 * The keys for both track and untracked strings should be unique and not overlap.
 * Example
 * const { lt } = useLocalTranslation();
 * lt('keyGoesHere');
 */
export const useLocalTranslation = () => {
  const { t } = useTranslation();

  const lt = (string: string) => t(string);

  return { lt };
};
