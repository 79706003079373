import React from 'react';

import { getStatusColor } from '@dizzbo/core/utils/orderStatus';
import { StatusIcon } from '@dizzbo/ui';

export const filterConfig: {
  groupName: string;
  key: string;
  filters: { name: string; value: string; icon?: React.ReactNode }[];
}[] = [
  {
    groupName: null,
    key: 'team',
    filters: [
      {
        name: 'filters_draft',
        value: 'DRAFT',
        icon: (
          <StatusIcon
            sx={{ width: 16, height: 16, color: getStatusColor('DRAFT') }}
          />
        ),
      },
      {
        name: 'filters_confirmed',
        value: 'CONFIRMED',
        icon: (
          <StatusIcon
            sx={{ width: 16, height: 16, color: getStatusColor('CONFIRMED') }}
          />
        ),
      },
      {
        name: 'filters_dispatched',
        value: 'DISPATCHED',
        icon: (
          <StatusIcon
            sx={{ width: 16, height: 16, color: getStatusColor('DISPATCHED') }}
          />
        ),
      },
      {
        name: 'filters_loaded',
        value: 'LOADED',
        icon: (
          <StatusIcon
            sx={{ width: 16, height: 16, color: getStatusColor('LOADED') }}
          />
        ),
      },
      {
        name: 'filters_unloaded',
        value: 'UNLOADED',
        icon: (
          <StatusIcon
            sx={{ width: 16, height: 16, color: getStatusColor('UNLOADED') }}
          />
        ),
      },
      {
        name: 'filters_reviewed',
        value: 'REVIEWED',
        icon: (
          <StatusIcon
            sx={{ width: 16, height: 16, color: getStatusColor('REVIEWED') }}
          />
        ),
      },
      {
        name: 'filters_invoiced',
        value: 'INVOICED',
        icon: (
          <StatusIcon
            sx={{ width: 16, height: 16, color: getStatusColor('INVOICED') }}
          />
        ),
      },
      {
        name: 'filters_canceled',
        value: 'CANCELED',
        icon: (
          <StatusIcon
            sx={{ width: 16, height: 16, color: getStatusColor('CANCELED') }}
          />
        ),
      },
    ],
  },
];
