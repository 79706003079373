import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const MapIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M12 8C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.00001 7.009C7.00001 4.24473 9.23647 2 12 2C14.7636 2 17 4.24473 17 7.009C17 7.99169 16.6897 9.06232 16.244 10.0993C15.7966 11.1403 15.2027 12.1702 14.6132 13.0774C14.0232 13.9856 13.4338 14.7767 12.992 15.3407C12.9116 15.4434 12.836 15.5387 12.7661 15.6257L12.0051 16.5745L11.2213 15.6443C11.1564 15.5672 11.0866 15.4835 11.0128 15.3937C10.5703 14.8551 9.97981 14.0943 9.38875 13.2057C8.21822 11.446 7.00001 9.11926 7.00001 7.009ZM12 4C10.3453 4 9.00001 5.34506 9.00001 7.009C9.00001 8.48 9.9074 10.3743 11.054 12.098C11.367 12.5686 11.681 13.002 11.9697 13.3806C12.2726 12.9696 12.6049 12.4976 12.9361 11.9878C13.4886 11.1374 14.019 10.2112 14.4065 9.30956C14.8026 8.38805 15 7.60474 15 7.009C15 5.34506 13.6547 4 12 4Z"
        />
        <path d="M4.57305 13H7.59464V15H6.02163L4.38071 20H19.5315L17.5624 15H15.946V13H18.9242L22.4686 22H1.61938L4.57305 13Z" />
      </SvgIcon>
    );
  }
);

MapIcon.displayName = 'MapIcon';

export default MapIcon;
