import React, { useState } from 'react';
import { styled } from '@mui/material/styles';

import { Card, CardContent, CardActions, Box, Paper } from '@dizzbo/ui';
import { RoutingMap } from '@core/components/RoutingMap';

import { TourScheduler } from '../TourScheduler';

const CardContentStyles = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
}));

const StopListWrapperStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: 400,
}));

const MapWrapperStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hasStops',
})<any>(({ theme, hasStops }) => ({
  height: hasStops ? 600 : 300,
  width: '100%',
}));

type Props = {};

export const TourPlan: React.FC<Props> = () => {
  const { tourData } = useTourDetail();
  const [totalPrice, setTotalPrice] = useState(0);

  return (
    <Card
      variant="filled-secondary"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <CardContentStyles>
        <MapWrapperStyles hasStops={tourData.stops.length > 0}>
          <RoutingMap />
        </MapWrapperStyles>
        <StopListWrapperStyles>
          <TourScheduler />
        </StopListWrapperStyles>
      </CardContentStyles>
      <Paper elevation={3}>
        <CardActions sx={{ padding: 0, overflow: 'hidden' }}></CardActions>
      </Paper>
    </Card>
  );
};
