import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SpeechBubbleIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 4H2V17.5H4.5V20.118L9.73607 17.5H22V4ZM9.26393 15.5L6.5 16.882V15.5H4V6H20V15.5H9.26393Z"
        />
      </SvgIcon>
    );
  }
);

SpeechBubbleIcon.displayName = 'SpeechBubbleIcon';

export default SpeechBubbleIcon;
