import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PoDIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M4 2H14.3837L19.1657 6.30376L17.7478 7.71834L13.6163 4H6V20H18V14.467L20 12.4717V22H4V2Z" />
        <path d="M12.2071 18.1213L22.9142 7.41421L21.5 6L12.2071 15.2929L9.41421 12.5L8 13.9142L12.2071 18.1213Z" />
      </SvgIcon>
    );
  }
);

PoDIcon.displayName = 'PoDIcon';

export default PoDIcon;
