import { Box as MuiBox } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import React, { ComponentProps, FC, useEffect, useState } from 'react';

interface MuiBoxPropsOverrides {
  container?: any;
  /**
   * The variant to use.
   * @default 'top'
   */
  stickTo?: 'top' | 'right' | 'bottom' | 'left';
  stuckStyles?: SxProps<Theme>;
  unstuckStyles?: SxProps<Theme>;
  offset?: number;
  zIndex?: number;
  children?: React.ReactElement;
}

type MuiBoxProps = ComponentProps<typeof MuiBox>;

type StickyBoxProps = MuiBoxProps & MuiBoxPropsOverrides;

export const StickyBox: FC<StickyBoxProps> = (props) => {
  const {
    stickTo,
    stuckStyles,
    unstuckStyles,
    offset,
    zIndex,
    container,
    children,
  } = props;

  const [stuck, setStuck] = useState(false);
  const ref = React.createRef();

  const styles = stuck ? stuckStyles : unstuckStyles;

  const baseStyles = {
    position: 'sticky',
    [stickTo]: offset,
    zIndex: zIndex,
    transition: 'all 0.3s ease-in',
    ...styles,
  };

  useEffect(() => {
    const cachedRef = ref.current;
    const rootMarginValue = (offset + 1) * -1;
    const observer = new IntersectionObserver(
      ([e]) => setStuck(e.intersectionRatio < 1),
      {
        root: container.current,
        threshold: [1],
        rootMargin: `${rootMarginValue}px 0px 110px 0px `,
      }
    );
    // @ts-ignore
    observer.observe(cachedRef);
    // @ts-ignore
    return () => observer.unobserve(cachedRef);
  }, [ref]);

  const mapped = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ...child.props,
        stuck: stuck,
      });
    }
    return null;
  });
  return (
    // @ts-ignore
    <MuiBox sx={baseStyles} ref={ref}>
      {mapped}
    </MuiBox>
  );
};

StickyBox.displayName = 'StickyBox';
