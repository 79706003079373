import React from 'react';
import { alpha } from '@mui/material/styles';

import { RemoveIcon, ChevronDownIcon } from '../../components/icons';

const Autocomplete = (theme) => {
  return {
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <ChevronDownIcon />,
        clearIcon: <RemoveIcon />,
        componentsProps: {
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 2],
                },
              },
            ],
          },
        },
        ChipProps: {
          variant: theme.mode === 'light' ? 'turquoise-60' : 'turquoise-175',
        },
        sx: {},
      },
      styleOverrides: {
        root: {
          '.MuiInputLabel-sizeSmall': {
            transform: 'translate(12px, 10px) scale(1)',
          },
          '.MuiFormLabel-root': {
            '&.MuiInputLabel-root': {
              '&.MuiInputLabel-sizeSmall': {
                '&.Mui-focused': {
                  transform: 'translate(12px, 1px) scale(0.75)',
                },
              },
            },
          },
          '&.MuiAutocomplete-hasClearIcon': {
            '.MuiInputLabel-sizeSmall': {
              fontSize: 16,
              fontWeight: 500,
              transform: 'translate(12px, 1px) scale(0.75) !important',
              '&.Mui-focused': {
                transform: 'translate(12px, 1px) scale(0.75)',
              },
            },
          },
          '&.Mui-focused': {
            '.MuiInputLabel-sizeSmall': {
              fontSize: 16,
              fontWeight: 500,
              transform: 'translate(12px, 1px) scale(0.75)',
            },
          },

          '.MuiAutocomplete-tag': {
            marginTop: 0,
            marginRight: 0,
            marginBottom: '2px',
            marginLeft: 0,
          },
          '.MuiFilledInput-root.MuiInputBase-sizeSmall': {
            paddingBottom: 0,
          },
          '.MuiFilledInput-root': {
            paddingBottom: 0,
            paddingTop: 16,
            '.MuiAutocomplete-endAdornment': {
              right: 0,
              // transform: 'none',
            },
            '&.MuiInputBase-sizeSmall': {
              '.MuiFilledInput-input': {
                height: 'auto',
                paddingBottom: 3,
                paddingTop: 0,
                fontSize: 15,
              },
            },
          },
          // sizeSmall: {
          //   paddingRight: 0,
          //   input: {
          //     height: 24,
          //     paddingTop: 14,
          //     paddingBottom: 0,
          //     fontSize: 15,
          //   },
          // },
        },
        inputRoot: {
          paddingTop: 0,
          paddingBottom: 0,
          // '.MuiFilledInput-input': {
          //   height: 24,
          //   paddingTop: 14,
          //   paddingBottom: 0,
          //   fontSize: 15,
          // },

          // '&.MuiFilledInput-input': {
          //   paddingRight: 0,
          //   '&.MuiInputBase-sizeSmall': {
          //     paddingRight: 0,
          //     input: {
          //       height: 24,
          //       paddingTop: 14,
          //       paddingBottom: 0,
          //       fontSize: 15,
          //     },
          //   },
          // },
        },
        endAdornment: {
          display: 'flex',
          // top: 0,
          right: 0,
          // width: '50px',
          height: '100%',
          maxHeight: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          button: {
            borderRadius: 0,
          },
        },
        clearIndicator: {
          visibility: 'visible',
          paddingTop: theme.spacing(2),
          paddingRight: theme.spacing(3),
          paddingBottom: theme.spacing(2),
          paddingLeft: theme.spacing(3),
          color: alpha(
            theme.styles.minimalIconButton.primary.default.color,
            0.5
          ),
          '&:hover': {
            color: theme.styles.minimalIconButton.primary.hover.color,
            backgroundColor: 'transparent',
          },
          '&:active': {
            color: alpha(
              theme.styles.minimalIconButton.primary.active.color,
              0.5
            ),
            backgroundColor: 'transparent',
          },
          '&:focus': {
            color: theme.styles.minimalIconButton.primary.focus.color,
            backgroundColor: 'transparent',
          },
        },
        popupIndicator: ({ ownerState, theme }) => {
          return {
            paddingTop: theme.spacing(2),
            paddingRight: theme.spacing(3),
            paddingBottom: theme.spacing(2),
            paddingLeft: theme.spacing(3),
            height: '100%',
            color: theme.styles.input.default.color,

            '&:hover': {
              backgroundColor: 'transparent',
            },
            '&:active': {
              backgroundColor: 'transparent',
            },
            '&:focus': {
              backgroundColor: 'transparent',
            },
            '&:last-child': {
              '&::before': {
                content: "' '",
                display: 'block',
                position: 'absolute',
                zIndex: 1,
                right: 42,
                height: '50%',
                // top: 0,
                border: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
                borderWidth: '0 0 0 1px',
              },
            },
            svg: {
              transition: theme.transitions.create(['transform']),
              transform: 'rotate(0deg)',
            },
          };
        },

        popupIndicatorOpen: ({ ownerState, theme }) => {
          return {
            transform: 'none',
            svg: {
              transition: theme.transitions.create(['transform']),
              transform:
                ownerState.popupIcon?.type?.displayName === 'ChevronDownIcon'
                  ? 'rotate(180deg)'
                  : 'rotate(0deg)',
            },
          };
        },
        popper: {
          borderRadius: theme.shape.borderRadius,
          boxShadow: '0px 0px 32px 4px rgba(0, 0, 0, 0.2)',
          '.MuiListItemIcon-root': {
            marginRight: 8,
          },
          '.MuiList-root': {
            padding: 0,
          },
        },
        listbox: {
          padding: 0,
          '.MuiAutocomplete-option': {
            ...theme.typography.bodyRegular,
            // paddingTop: 8,
            // paddingRight: 24,
            // paddingBottom: 8,
            // paddingLeft: 24,
            '&.Mui-focused': {
              // backgroundColor:
              //   theme.styles.minimalIconButton.primary.hover.color,
            },
            '&[aria-selected="true"]': {
              // '&:hover': {
              //   backgroundColor: 'red',
              // },
              // '&:Mui-focused': {
              //   backgroundColor: 'red',
              // },
              // backgroundColor: 'red',
              '&.Mui-focused': {
                // backgroundColor:
                //   theme.styles.minimalIconButton.primary.hover.color,
              },
            },
          },

          '.MuiListItem-root': {
            // height: 36,
          },
          '.MuiListItemIcon-root': {
            marginRight: 10,
          },
          '.MuiListItemText-root': {
            paddingRight: 24,
            color: '#000000',
          },
          '&[aria-selected="true"]': {
            backgroundColor: theme.styles.minimalIconButton.primary.hover.color,
          },
          // ':focus': {
          //   backgroundColor: 'red',
          // },
        },
      },
    },
  };
};

export default Autocomplete;
