import { SxProps, styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import React from 'react';
import { useDrag } from 'react-dnd';

import { Box, InfoOutlineIcon, MinimalIconButton } from '@dizzbo/ui';
import { TourType } from '@types';

const ContainerStyles = styled(Box)(({ theme }) => ({
  height: 64,
  width: '100%',
  minWidth: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: theme.palette.turquoise[20],
  border: `1px solid ${theme.palette.oxford[10]}`,
  borderRadius: 8,
}));

const FlagContainerStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'countryCode' && prop !== 'position',
})<any>(({ theme, countryCode, position }) => {
  let cc = 'dizzbo';
  if (countryCode) {
    cc = countryCode.toLowerCase();
  }
  const flagImgUrl = new URL(
    `../../../../assets/images/flags/vertical-flags/flag-${cc}.svg`,
    import.meta.url
  );
  return {
    display: 'block',
    position: 'absolute',
    height: 64,
    width: 8,
    left: position === 'right' ? 'auto' : 0,
    right: position === 'right' ? 0 : 'auto',
    backgroundImage: `url("${flagImgUrl}")`,
    borderTopLeftRadius: position === 'right' ? 0 : 8,
    borderBottomLeftRadius: position === 'right' ? 0 : 8,
    borderTopRightRadius: position === 'right' ? 8 : 0,
    borderBottomRightRadius: position === 'right' ? 8 : 0,
  };
});

const ContentStyles = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: 8,
  right: 8,
  display: 'flex',
  flexDirection: 'column',
  height: 64,
  maxWidth: 240,
  paddingTop: 4,
  paddingRight: 8,
  paddingBottom: 4,
  paddingLeft: 8,
}));

const HeaderStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  minWidth: '100%',
  alignItems: 'center',
  marginBottom: 2,
}));

const TitleStyles = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  marginRight: 4,
  ...theme.typography.bodyBold,
}));

const SubtitleStyles = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ...theme.typography.tiny,
  flexShrink: 1,
}));

const InfoStyles = styled(Box)(({ theme }) => ({
  marginLeft: 'auto',
  display: 'flex',
  width: 16,
  height: 16,
}));

const StopsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

const StopStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'last',
})<any>(({ theme, last }) => {
  return {
    maxWidth: 100,
    textAlign: last ? 'right' : 'left',
  };
});

const AddressStyles = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ...theme.typography.tiny,
  marginBottom: 2,
}));

const DateStyles = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ...theme.typography.tiny,
}));

type Props = {
  data: TourType;
  sx?: SxProps;
};

export const OrderListItem: React.FC<Props> = ({ data }) => {
  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    // "type" is required. It is used by the "accept" specification of drop targets.
    type: 'ORDER',
    item: data,
    // The collect function utilizes a "monitor" instance (see the Overview for what this is)
    // to pull important pieces of state from the DnD system.
    collect: (monitor) => ({
      item: monitor.getItem(),
      isDragging: monitor.isDragging(),
      end: monitor.getDropResult(),
    }),
    end: (item, monitor) => {},
  }));

  const style = {
    opacity: isDragging ? 0.4 : 1,
    paddingBottom: '24px',
  };

  const start = {
    countryCode: data?.origin?.loadingPoint.address.country,
    city: data?.origin?.loadingPoint.address.city,
    date: data?.origin?.requestedDate
      ? dayjs(data?.origin?.requestedDate).format('MMM DD, hh:mm')
      : '-',
  };

  const destination = {
    countryCode: data?.destination?.loadingPoint.address.country,
    city: data?.destination?.loadingPoint.address.city,
    date: data?.destination?.requestedDate
      ? dayjs(data?.destination?.requestedDate).format('MMM DD, hh:mm')
      : '-',
  };

  return (
    <Box ref={dragPreview} style={style}>
      <ContainerStyles role="Handle" ref={drag}>
        <FlagContainerStyles countryCode={start.countryCode} />
        <ContentStyles>
          <HeaderStyles>
            <TitleStyles>{data.orders[0]?.reference}</TitleStyles>
            <SubtitleStyles>{data.orders[0]?.loaderName}</SubtitleStyles>
            <InfoStyles>
              <MinimalIconButton size="tiny">
                <InfoOutlineIcon />
              </MinimalIconButton>
            </InfoStyles>
          </HeaderStyles>
          <StopsContainer>
            <StopStyles>
              <AddressStyles>
                {start.countryCode}, {start.city}
              </AddressStyles>
              <DateStyles>{start.date}</DateStyles>
            </StopStyles>
            <StopStyles last>
              <AddressStyles>
                {destination.city}, {destination.countryCode}
              </AddressStyles>
              <DateStyles>{destination.date}</DateStyles>
            </StopStyles>
          </StopsContainer>
        </ContentStyles>
        <FlagContainerStyles
          position="right"
          countryCode={destination.countryCode}
        />
      </ContainerStyles>
    </Box>
  );
};
