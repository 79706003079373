import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SchedulerIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3V1H10V3H4V8H10L10 15H4V20H10V22H12V20H17V15H12L12 8H20V3H12ZM10 5H6V6H10V5ZM12 6V5H18V6H12ZM10 17H6V18H10V17ZM12 18V17H15V18H12Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 9H9V14H1V9ZM3 11V12H7V11H3Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 9H23V14H14V9ZM16 11V12H21V11H16Z"
        />
      </SvgIcon>
    );
  }
);

SchedulerIcon.displayName = 'SchedulerIcon';

export default SchedulerIcon;
