import { Menu as MuiMenu } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiMenuProps = ComponentProps<typeof MuiMenu>;

export const Menu = forwardRef<HTMLDivElement, MuiMenuProps>((props, ref) => (
  <MuiMenu ref={ref} {...props} />
));

Menu.displayName = 'Menu';
