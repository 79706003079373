import { Switch as MuiSwitch } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiSwitchProps = ComponentProps<typeof MuiSwitch>;

type SwitchProps = Omit<
  MuiSwitchProps,
  | 'centerRipple'
  | 'disableTouchRipple'
  | 'focusRipple'
  | 'focusVisibleClassName'
  | 'TouchRippleProps'
  | 'touchRippleRef'
>;

export const Switch = forwardRef<HTMLButtonElement, SwitchProps>(
  (props, ref) => <MuiSwitch {...props} ref={ref} />
);

Switch.displayName = 'Switch';
