import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SuccessIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <ellipse cx="12" cy="12" rx="10.5" ry="10.5" fill="#3F9E7D" />
        <path
          d="M9.81818 15.1194L6.95455 12.2985L6 13.2388L9.81818 17L18 8.9403L17.0455 8L9.81818 15.1194Z"
          fill="white"
        />
      </SvgIcon>
    );
  }
);

SuccessIcon.displayName = 'SubcontractIcon';

export default SuccessIcon;
