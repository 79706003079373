import React from 'react';
import { Box } from '../../components/Box';

const Tabs = (theme) => {
  return {
    MuiTab: {
      defaultProps: {},
      styleOverrides: {
        root: {
          ...theme.typography.h5,
          textTransform: 'capitalize',
          minHeight: 56,
          color: theme.styles.tab.text.default.color,
          paddingRight: theme.spacing(3),
          paddingLeft: theme.spacing(3),
          '&.Mui-selected': {
            color: theme.styles.tab.text.active.color,
          },
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        TabIndicatorProps: {
          children: (
            <Box
              sx={{
                marginRight: 3,
                marginLeft: 3,
                backgroundColor: theme.styles.tab.text.active.color,
                height: 3,
                position: 'relative',
              }}
            />
          ),
        },
      },
      styleOverrides: {
        indicator: {
          backgroundColor: 'transparent',
          height: 3,
        },
        root: {
          '&.MuiTabs-vertical': {
            paddingRight: 40,
            '.MuiTabs-flexContainer': {
              alignItems: 'flex-start',
            },

            '.MuiTabs-indicator': {
              width: 3,
              right: 'auto',
              left: 0,
              '.MuiBox-root': {
                height: 20,
                margin: 0,
                marginTop: 18,
              },
            },
            '.MuiTab-iconWrapper': {
              marginRight: 6,
            },
          },
        },
      },
    },
  };
};

export default Tabs;
