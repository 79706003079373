const LinearProgress = (theme) => {
  return {
    MuiLinearProgress: {
      defaultProps: {},
      styleOverrides: {
        root: {
          height: 2,
          backgroundColor: theme.palette.oxford[5],
          borderRaidus: 4,
          '.MuiLinearProgress-bar': {
            backgroundColor: theme.palette.oxford[100],
            borderRaidus: 4,
          },
        },
      },
    },
  };
};

export default LinearProgress;
