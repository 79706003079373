import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { Box } from '@dizzbo/ui';

interface Props {
  isLoading: boolean;
  muiLinearProgressProps?: any;
}

const LinearProgressBar = ({ isLoading, muiLinearProgressProps }: Props) => {
  const linearProgressProps =
    muiLinearProgressProps instanceof Function
      ? muiLinearProgressProps()
      : muiLinearProgressProps;

  return (
    <>
      {isLoading ? (
        <LinearProgress
          aria-label="Loading"
          aria-busy="true"
          sx={{
            position: 'relative',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#5A9A9A',
            },
          }}
          {...linearProgressProps}
        />
      ) : (
        <Box sx={{ height: 2 }} />
      )}
    </>
  );
};

export default LinearProgressBar;
