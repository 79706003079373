import { WorkspaceType } from 'packages/core/types';
import { ComponentState } from 'react';

const handlers = {
  SET_WORKSPACES: (state: ComponentState, action: any) => {
    // used for setting the workspaces on initial load
    // from remote or from local storage
    const { workspaces } = action.payload;
    return {
      ...state,
      isLoadingWorkspaces: false,
      workspaces,
    };
  },
  SET_ACTIVE_WORKSPACE_UUID: (state: ComponentState, action: any) => {
    // set the current active workspace
    // used for eg the tab navigation
    const { uuid } = action.payload;
    let activeWorkspace = undefined;
    const result = state.workspaces.find(
      (workspace: WorkspaceType) => workspace.uuid === uuid
    );
    if (result) {
      activeWorkspace = result;

      return {
        ...state,
        activeWorkspaceUUID: uuid,
        activeWorkspace: activeWorkspace,
      };
    } else {
      return state;
    }
  },
  ADD_WORKSPACE: (state: ComponentState, action: any) => {
    // add a new workspace to the workspaces array
    // used on the tabs action to create a new tab
    const { workspace } = action.payload;
    const { workspaces } = state;
    return {
      ...state,
      activeWorkspaceUUID: workspace.uuid,
      activeWorkspace: workspace,
      workspaces: [...workspaces, workspace],
    };
  },
  DELETE_WORKSPACE: (state: ComponentState, action: any) => {
    // delete a workspace from the workspaces array
    // used on the tabs action to delete a tab
    const { uuid } = action.payload;
    const { workspaces } = state;
    let activeWorkspaceUUID = state.activeWorkspaceUUID;
    let activeWorkspace = state.activeWorkspace;

    const result = workspaces.find(
      (workspace: WorkspaceType) => workspace.uuid === uuid
    );

    if (result) {
      workspaces.splice(workspaces.indexOf(result), 1);
      activeWorkspace = { ...workspaces[workspaces.length - 1] };
      activeWorkspaceUUID = activeWorkspace.uuid;
    }

    return {
      ...state,
      activeWorkspaceUUID: activeWorkspaceUUID,
      activeWorkspace: activeWorkspace,
      workspaces: [...workspaces],
    };
  },
  SET_WORKSPACE_TITLE: (state: ComponentState, action: any) => {
    const { uuid, title } = action.payload;
    // find the workspace for the given uuid
    // and modify the title and set it in the workspaces array
    const modifiedWorkspaces = state.workspaces.map((workspace) =>
      workspace.uuid === uuid ? { ...workspace, title: title } : workspace
    );

    return {
      ...state,
      workspaces: [...modifiedWorkspaces],
    };
  },
  SET_WORKSPACE_SETTINGS: (state: ComponentState, action: any) => {
    const { uuid, settingType, settings } = action.payload;
    const { workspaces } = state;
    // find the workspace for the uuid
    const result = workspaces.find(
      (workspace: WorkspaceType) => workspace.uuid === uuid
    );
    // if ther is no workspace for that uuid, return the state
    if (!result) {
      return state;
    }
    // if we found the workspace, but it has no settings, create an empty object
    if (!result.settings) {
      result.settings = {};
    }

    // if we didnt receive any settings in the payload, delete the setting
    // this is basically a way to reset the setting for that settingType
    if (!settings) {
      delete result.settings[settingType];
    } else {
      // if we got settings set them for the settingType
      result.settings[settingType] = {
        ...result.settings[settingType],
        ...settings,
      };
    }
    // set the modfied workspace in the workspaces array
    const modifiedWorkspaces = workspaces.map((workspace) =>
      workspace.uuid === uuid ? result : workspace
    );

    return {
      ...state,
      // update the currently activate workspace with the modfiied one
      activeWorkspace: { ...result },
      workspaces: [...modifiedWorkspaces],
    };
  },
  REMOVE_WORKSPACE_SETTINGS: (state: ComponentState, action: any) => {
    // remove a setting from the workspace
    // used on date filters or filter chips to delete a filter
    // eg "removeWorkspaceSettings(activeWorkspaceUUID, 'filters', 'dateRange')"

    const { uuid, settingType, setting } = action.payload;
    const { workspaces } = state;

    const result = workspaces.find(
      (workspace: WorkspaceType) => workspace.uuid === uuid
    );
    if (!result) {
      return state;
    }

    try {
      delete result.settings[settingType][setting];
    } catch (e) {}

    const modifiedWorkspaces = workspaces.map((workspace) =>
      workspace.uuid === uuid ? result : workspace
    );

    return {
      ...state,
      activeWorkspace: { ...result },
      workspaces: [...modifiedWorkspaces],
    };
  },
};

export const reducer = (state: ComponentState, action: any) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;
