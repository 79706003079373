import React, {
  type DragEvent,
  type ReactNode,
  useMemo,
  useState,
} from 'react';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import { useTheme } from '@mui/material/styles';
import { TableHeadCellColumnActionsButton } from './TableHeadCellColumnActionsButton';
import { TableHeadCellFilterContainer } from './TableHeadCellFilterContainer';
import { TableHeadCellFilterLabel } from './TableHeadCellFilterLabel';
import { TableHeadCellGrabHandle } from './TableHeadCellGrabHandle';
import { TableHeadCellResizeHandle } from './TableHeadCellResizeHandle';
import { TableHeadCellSortLabel } from './TableHeadCellSortLabel';
import { getCommonCellStyles } from '../column.utils';
import { type Theme } from '@mui/material/styles';
import { type Header, type TableInstance } from '../types';

interface Props {
  header: Header;
  table: TableInstance;
}

export const TableHeadCell = ({ header, table }: Props) => {
  const theme = useTheme();
  const {
    getState,
    options: {
      enableColumnActions,
      enableColumnDragging,
      enableColumnOrdering,
      enableGrouping,
      enableMultiSort,
      layoutMode,
      muiTableHeadCellProps,
    },
    refs: { tableHeadCellRefs },
    setHoveredColumn,
  } = table;
  const {
    density,
    draggingColumn,
    grouping,
    hoveredColumn,
    showColumnFilters,
  } = getState();
  const { column } = header;
  const { columnDef } = column;
  const { columnDefType } = columnDef;

  const mTableHeadCellProps =
    muiTableHeadCellProps instanceof Function
      ? muiTableHeadCellProps({ column, table })
      : muiTableHeadCellProps;

  const mcTableHeadCellProps =
    columnDef.muiTableHeadCellProps instanceof Function
      ? columnDef.muiTableHeadCellProps({ column, table })
      : columnDef.muiTableHeadCellProps;

  const tableCellProps = {
    ...mTableHeadCellProps,
    ...mcTableHeadCellProps,
  };

  const showColumnActions =
    (enableColumnActions || columnDef.enableColumnActions) &&
    columnDef.enableColumnActions !== false;

  const showDragHandle =
    enableColumnDragging !== false &&
    columnDef.enableColumnDragging !== false &&
    (enableColumnDragging ||
      (enableColumnOrdering && columnDef.enableColumnOrdering !== false) ||
      (enableGrouping &&
        columnDef.enableGrouping !== false &&
        !grouping.includes(column.id)));

  const headerPL = useMemo(() => {
    let pl = 0;
    if (column.getCanSort()) pl++;
    if (showColumnActions) pl += 1.75;
    if (showDragHandle) pl += 1.25;
    return pl;
  }, [showColumnActions, showDragHandle]);

  const draggingBorder = useMemo(
    () =>
      draggingColumn?.id === column.id
        ? `${theme.shadows[2]}`
        : hoveredColumn?.id === column.id
        ? `${theme.shadows[3]}`
        : undefined,
    [draggingColumn, hoveredColumn]
  );

  const draggingBorders = draggingBorder
    ? {
        boxShadow: draggingBorder,
      }
    : undefined;

  const handleDragEnter = (_e: DragEvent) => {
    if (enableGrouping && hoveredColumn?.id === 'drop-zone') {
      setHoveredColumn(null);
    }
    if (enableColumnOrdering && draggingColumn && columnDefType !== 'group') {
      setHoveredColumn(
        columnDef.enableColumnOrdering !== false ? column : null
      );
    }
  };

  const headerElement =
    columnDef?.Header instanceof Function
      ? columnDef?.Header?.({
          column,
          header,
          table,
        })
      : columnDef?.Header ?? (columnDef.header as ReactNode);

  const [isHovered, setIsHovered] = useState(false);

  return (
    <TableCell
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      align={columnDefType === 'group' ? 'center' : 'left'}
      colSpan={header.colSpan}
      onDragEnter={handleDragEnter}
      ref={(node: HTMLTableCellElement) => {
        if (node) {
          tableHeadCellRefs.current[column.id] = node;
        }
      }}
      {...tableCellProps}
      sx={(theme: Theme) => ({
        flexDirection: layoutMode === 'grid' ? 'column' : undefined,
        fontWeight: 'bold',
        overflow: 'visible',
        p:
          density === 'compact'
            ? '0.5rem'
            : density === 'comfortable'
            ? columnDefType === 'display'
              ? '0.75rem'
              : '1rem'
            : columnDefType === 'display'
            ? '1rem 1.25rem'
            : '1.5rem',
        pb:
          columnDefType === 'display'
            ? 0
            : showColumnFilters || density === 'compact'
            ? '0.4rem'
            : '0.6rem',
        pt:
          columnDefType === 'group' || density === 'compact'
            ? '0.25rem'
            : density === 'comfortable'
            ? '.75rem'
            : '1.25rem',
        userSelect: enableMultiSort && column.getCanSort() ? 'none' : undefined,
        verticalAlign: 'top',
        zIndex:
          column.getIsResizing() || draggingColumn?.id === column.id
            ? 3
            : column.getIsPinned() && columnDefType !== 'group'
            ? 2
            : 1,
        ...getCommonCellStyles({
          column,
          header,
          table,
          tableCellProps,
          theme,
        }),
        ...draggingBorders,
      })}
    >
      {header.isPlaceholder ? null : (
        <Box
          className="Mui-TableHeadCell-Content"
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection:
              tableCellProps?.align === 'right' ? 'row-reverse' : 'row',
            justifyContent:
              columnDefType === 'group' || tableCellProps?.align === 'center'
                ? 'center'
                : column.getCanResize()
                ? 'space-between'
                : 'flex-start',
            position: 'relative',
            width: '100%',
            height: 32,
          }}
        >
          <Box
            className="Mui-TableHeadCell-Content-Labels"
            onClick={column.getToggleSortingHandler()}
            sx={{
              alignItems: 'center',
              cursor:
                column.getCanSort() && columnDefType !== 'group'
                  ? 'pointer'
                  : undefined,
              display: 'flex',
              flexDirection:
                tableCellProps?.align === 'right' ? 'row-reverse' : 'row',
              overflow: columnDefType === 'data' ? 'hidden' : undefined,
              pl:
                tableCellProps?.align === 'center'
                  ? `${headerPL}rem`
                  : undefined,
            }}
          >
            <Box
              className="Mui-TableHeadCell-Content-Wrapper"
              sx={{
                minWidth: `${Math.min(columnDef.header?.length ?? 0, 5)}ch`,
                overflow: columnDefType === 'data' ? 'hidden' : undefined,
                textOverflow: 'ellipsis',
                whiteSpace:
                  (columnDef.header?.length ?? 0) < 20 ? 'nowrap' : 'normal',
                '&:hover': {
                  textOverflow: 'clip',
                },
              }}
              title={columnDefType === 'data' ? columnDef.header : undefined}
            >
              {headerElement}
            </Box>
            {column.getCanSort() && (
              <TableHeadCellSortLabel
                header={header}
                table={table}
                tableCellProps={tableCellProps}
              />
            )}
            {column.getCanFilter() && (
              <TableHeadCellFilterLabel header={header} table={table} />
            )}
          </Box>
          {columnDefType !== 'group' && (
            <Box
              className="Mui-TableHeadCell-Content-Actions"
              sx={{ whiteSpace: 'nowrap' }}
            >
              {showDragHandle && (
                <Box
                  sx={{
                    display: 'inline',
                    opacity: isHovered ? 1 : 0,
                    height: '2rem',
                  }}
                >
                  <TableHeadCellGrabHandle
                    column={column}
                    table={table}
                    tableHeadCellRef={{
                      current: tableHeadCellRefs.current[column.id],
                    }}
                  />
                </Box>
              )}
              {showColumnActions && (
                <TableHeadCellColumnActionsButton
                  header={header}
                  table={table}
                />
              )}
            </Box>
          )}
          {column.getCanResize() && (
            <TableHeadCellResizeHandle header={header} table={table} />
          )}
        </Box>
      )}
      {column.getCanFilter() && (
        <TableHeadCellFilterContainer header={header} table={table} />
      )}
    </TableCell>
  );
};
