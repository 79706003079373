import { styled } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import React, { FC, useState } from 'react';

import { View } from '@dizzbo/core/views';
import { Box, PageHeader } from '@dizzbo/ui';

import { getVehicles } from '@core/api';
import { RoutingMap } from '@core/components/RoutingMap';
import { QueryKeys } from '@core/config';

import { VehiclePositionType, VehicleType } from '@types';
import { List } from '../components/List';
import { ListSearch } from '../components/ListSearch';
import { StatusFilters } from '../components/StatusFilters';

const ContainerStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(-64px + 100vh)',
  width: '100%',
  zIndex: theme.zIndex.appBar + 1,
}));

const ListAndMapWrapperStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: 'calc(-142px + 100vh)',
  width: '100%',
  paddingTop: 24,
  paddingRight: 24,
}));

const ListSearchWrapperStyles = styled(Box)(({ theme }) => ({
  width: '30%',
  height: '100%',
}));

const ListWrapperStyles = styled(Box)(({ theme }) => ({
  width: '100%%',
  height: 'calc(-53px + 100%)',
}));

const MapWrapperStyles = styled(Box)(({ theme }) => ({
  width: '70%',
  height: '100%',
  borderRadius: 8,
  overflow: 'hidden',
}));

export const RealtimeListView: FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const [activeTab, setActiveTab] = useState(0);

  const { data: vehiclesData } = useQuery({
    queryKey: [QueryKeys.VEHICLES],
    queryFn: () => getVehicles(),
  });

  const vehicles = vehiclesData?.results.reduce(
    (result: VehiclePositionType[], vehicle: VehicleType) => {
      if (vehicle.lat && vehicle.lng) {
        result.push({
          uuid: vehicle.uuid,
          licensePlate: vehicle.licensePlate,
          lat: vehicle.lat,
          lng: vehicle.lng,
        });
      }
      return result;
    },
    []
  );

  return (
    <View title="Realtime" sx={{ height: '100%' }}>
      <PageHeader title="Realtime" />
      <ContainerStyles>
        <StatusFilters activeTab={activeTab} setActiveTab={setActiveTab} />
        <ListAndMapWrapperStyles>
          <ListSearchWrapperStyles>
            <ListSearch
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
            <ListWrapperStyles>
              <List searchValue={searchValue} />
            </ListWrapperStyles>
          </ListSearchWrapperStyles>
          <MapWrapperStyles>
            <RoutingMap vehicles={vehicles} />
          </MapWrapperStyles>
        </ListAndMapWrapperStyles>
      </ContainerStyles>
    </View>
  );
};
