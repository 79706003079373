import React from 'react';

import { BryntumSchedulerProProps } from '@bryntum/schedulerpro-react';
import { OrderListItem } from './OrderListItem';

import {
  EventModel,
  ResourceModel,
  ViewPreset,
  SchedulerEventModel,
  SchedulerResourceModel,
  SchedulerAssignmentModel,
  EventRenderData,
} from '@bryntum/schedulerpro';

const eventRenderer = ({
  eventRecord,
  resourceRecord,
  assignmentRecord,
  renderData,
}: {
  eventRecord: SchedulerEventModel;
  resourceRecord: SchedulerResourceModel;
  assignmentRecord: SchedulerAssignmentModel;
  renderData: EventRenderData;
}) => {
  return (
    <OrderListItem eventRecord={eventRecord} resourceRecord={resourceRecord} />
  );
};

const customViewPreset = new ViewPreset({
  id: 'customPreset', // Unique id value provided to recognize your view preset. Not required, but having it you can simply set new view preset by id: scheduler.viewPreset = 'customPreset'
  name: 'My view preset', // A human-readable name provided to be used in GUI, e.i. preset picker, etc.
  base: 'dayAndWeek', // Extends 'hourAndDay' view preset provided by PresetManager. You can pick out any of PresetManager's view presets: PresetManager.records
  tickWidth: 100,
});

class OrderResourceModel extends ResourceModel {
  static get fields() {
    return [
      { name: 'id', dataSource: 'uuid' },
      { name: 'name', dataSource: 'uuid' },
    ];
  }
}

class TourEventModel extends EventModel {
  static get fields() {
    return [
      {
        name: 'startDate',
        dataSource: 'scheduler.startsAt',
      },
      {
        name: 'endDate',
        dataSource: 'scheduler.arrivesAt',
      },
      { name: 'id', dataSource: 'uuid' },
      { name: 'resourceId', dataSource: 'vehicle.uuid' },
      { name: 'name', dataSource: 'reference' },
      { name: 'durationUnit', defaultValue: 'second' },
    ];
  }
}

export const schedulerConfig: BryntumSchedulerProProps = {
  // https://bryntum.com/products/scheduler/docs/api/Scheduler/view/SchedulerBase
  // ############################################################################
  eventStore: {
    modelClass: TourEventModel,
  },
  // resourceStore: {
  //   modelClass: OrderResourceModel,
  // },
  timeZone: 'Europe/Berlin',

  barMargin: 2,
  rowHeight: 72,

  startDate: new Date(),
  weekStartDay: 1,

  viewPreset: 'customPreset',
  allowOverlap: true, // disable overlapping events
  infiniteScroll: true, // make the timeline infinite scrollable
  zoomOnTimeAxisDoubleClick: false,
  zoomOnMouseWheel: false,
  createEventOnDblClick: false,
  eventTooltipFeature: {
    disabled: true,
  },

  eventMenuFeature: {
    // disable the context menu on right click on the event itself
    disabled: true,
  },
  timeAxisHeaderMenuFeature: {
    // diable the context menu on right click in the top time axis
    disabled: true,
    items: {
      zoomLevel: false,
    },
  },
  scheduleMenuFeature: {
    // disable the right click menu in the timeline
    disabled: true,
  },

  timeRangesFeature: {
    disabled: false,
    showCurrentTimeLine: true, // how the vertical indicator for the current datetime
    showHeaderElements: true,
    enableResizing: true, // allow to resize time ranges in the timeline
  },
  columnLinesFeature: {
    disabled: false,
  },
  eventResizeFeature: {
    disabled: false,
  },
  eventDragCreateFeature: {
    disabled: false, // disable the event create via drag/click on the timeline
  },
  scheduleTooltipFeature: {
    // works -> disabling all the tooltips in the timeline
    disabled: true,
  },
  dependenciesFeature: {
    // disable the dependencies & interface
    disabled: true,
  },
  nonWorkingTimeFeature: {
    disabled: false,
  },
  headerZoomFeature: {
    // zoom via click an drag on the header timeline
    disabled: true,
  },
  eventRenderer,
  // eventTooltipFeature: {
  //   disabled: true,
  // },

  columns: [
    // {
    //   // disable the right click context menu
    //   enableCellContextMenu: false,
    //   enableHeaderContextMenu: false,
    //   text: 'Orders',
    //   field: 'name',
    //   width: 260,
    //   minWidth: 260,
    //   readOnly: true,
    //   // align: 'center',
    //   renderer: ({ record }) => <TourItem data={record} />,
    // },
  ],
  listeners: {
    beforeEventEdit() {
      // Show custom editor here
      // ...

      // Prevent built in editor
      return false;
    },
  },
};
