import { Dialog as MuiDialog } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiCardProps = ComponentProps<typeof MuiDialog>;

export const Dialog = forwardRef<HTMLDivElement, MuiCardProps>((props, ref) => (
  <MuiDialog ref={ref} {...props} />
));

Dialog.displayName = 'Dialog';
