import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const EmptyIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M13 13L20 13V11H13V13Z" />
        <path d="M11 11H4V13L11 13V11Z" />
      </SvgIcon>
    );
  }
);

EmptyIcon.displayName = 'EmptyIcon';

export default EmptyIcon;
