import { useTheme } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import { cancelOrder, copyOrder } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  Button,
  ErrorIcon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MoreVerticalIcon,
  PlusIcon,
} from '@dizzbo/ui';
import { UUIDType } from '@types';
import { useTranslation } from 'react-i18next';

type Props = {
  orderUUID: UUIDType;
};

export const OrderActionsButton: React.FC<Props> = ({ orderUUID }) => {
  const theme = useTheme();
  const queryClient = useQueryClient();

  const { mutateAsync: mutateCopyOrder } = useMutation({
    mutationFn: () => copyOrder(orderUUID),
  });

  const { mutateAsync: mutateCancelOrder }: any = useMutation({
    mutationFn: () => cancelOrder(orderUUID),
  });

  const { t } = useTranslation();

  const handleCopy = async (popupState) => {
    const id = toast.loading('Copying Order...');
    try {
      const data = await mutateCopyOrder();
      queryClient.invalidateQueries({ queryKey: [QueryKeys.ORDERS] });
      toast.update(id, {
        render: (
          <>
            {t('orderSuccessfullyCopied.')}
            <Button
              component={RouterLink}
              to={`/orders/${data.uuid}`}
              sx={{ marginLeft: 8 }}
              variant="secondary"
              size="medium"
            >
              {t('openCopy')}
            </Button>
          </>
        ),
        autoClose: 6000,
        type: 'success',
        isLoading: false,
      });
    } catch {
      toast.update(id, {
        render: t('theOrderCouldNotBeCopied'),
        autoClose: 6000,
        type: 'error',
        isLoading: false,
      });
    }

    popupState.close();
  };

  const handleCancel = async (popupState) => {
    const id = toast.loading('Canceling Order...');
    try {
      await mutateCancelOrder();
      queryClient.invalidateQueries({ queryKey: [QueryKeys.ORDERS] });
      toast.update(id, {
        render: t('orderSuccessfullyCanceled'),
        autoClose: 6000,
        type: 'success',
        isLoading: false,
      });
    } catch {
      toast.update(id, {
        render: t('theOrderCouldNotBeCanceled'),
        autoClose: 6000,
        type: 'error',
        isLoading: false,
      });
    }

    popupState.close();
  };

  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <IconButton
              size="medium"
              variant="tertiary"
              {...bindTrigger(popupState)}
            >
              <MoreVerticalIcon />
            </IconButton>
            <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={() => handleCopy(popupState)}>
                <ListItemIcon>
                  <PlusIcon sx={{ width: 16, height: 16 }} />
                </ListItemIcon>
                <ListItemText>{t('copyAndCreateNewOrder')}</ListItemText>
              </MenuItem>
              <MenuItem
                sx={{ color: theme.palette.error.main }}
                onClick={() => handleCancel(popupState)}
              >
                <ListItemIcon sx={{ color: theme.palette.error.main }}>
                  <ErrorIcon sx={{ width: 16, height: 16 }} />
                </ListItemIcon>
                <ListItemText>{t('cancelOrder')}</ListItemText>
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    </>
  );
};
