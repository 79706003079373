import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ArrowUpperRightIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M17.8277 13.2431L17.6567 6.34313L10.7568 6.17218L10.8081 8.24522L14.2552 8.33063L5.63599 16.9498L7.0502 18.364L15.6692 9.74498L15.7546 13.1917L17.8277 13.2431Z" />
      </SvgIcon>
    );
  }
);

ArrowUpperRightIcon.displayName = 'ArrowUpperRightIcon';

export default ArrowUpperRightIcon;
