import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const RemoveIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M11.9999 13.4142L14.9497 16.364L16.3639 14.9497L13.4142 12L16.3639 9.05025L14.9497 7.63604L12 10.5858L9.0502 7.63604L7.63599 9.05025L10.5857 12L7.63599 14.9497L9.0502 16.364L11.9999 13.4142Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
        />
      </SvgIcon>
    );
  }
);

RemoveIcon.displayName = 'RemoveIcon';

export default RemoveIcon;
