import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DocumentIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 2H14.3837L20 7.05464V22H4V2ZM6 4V20H18V7.94536L13.6163 4H6ZM13 17H8V15H13V17ZM8 13H13V11H8V13Z"
        />
      </SvgIcon>
    );
  }
);

DocumentIcon.displayName = 'DocumentIcon';

export default DocumentIcon;
