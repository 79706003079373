import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import React from 'react';
import { toast } from 'react-toastify';

import { updateOrder } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  CheckIcon,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  LoadingButton,
  Menu,
  MenuItem,
  SwitchIcon,
} from '@dizzbo/ui';
import { OrderType, UUIDType } from '@types';
import { useTranslation } from 'react-i18next';

type Props = {
  orderUUID: UUIDType;
  fieldName: string;
  value: string;
  presetValue: boolean | null;
  label: string;
};

export const RequirementSelect: React.FC<Props> = ({
  orderUUID,
  fieldName,
  value,
  presetValue,
  label,
}) => {
  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'poDRequiredDialog',
  });
  const queryClient = useQueryClient();

  const { mutate: mutateOrder, isPending }: any = useMutation<
    OrderType,
    unknown,
    Partial<OrderType>
  >({
    mutationKey: [QueryKeys.ORDERS, orderUUID],
    mutationFn: (values) =>
      updateOrder({ orderUUID: orderUUID, orderData: values }),
  });

  const { t } = useTranslation();

  const handleToggle = (value: string) => () => {
    const id = toast.loading('Updating Order...');

    mutateOrder(
      { [fieldName]: value },
      {
        onSuccess: (data, values) => {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.ORDERS, orderUUID],
          });
          toast.update(id, {
            autoClose: 6000,
            render: t('orderUpdated'),
            type: 'success',
            isLoading: false,
          });
        },
        onError: (error, variables, context) => {
          toast.update(id, {
            autoClose: 6000,
            render: t('orderCouldNotBeUpdated'),
            type: 'error',
            isLoading: false,
          });
        },
      }
    );
    popupState.close();
  };

  const labels = {
    YES: 'Yes',
    NO: 'No',
    DEFAULT: `${presetValue ? 'Yes' : 'No'} (Customer Preset)`,
  };

  return (
    <>
      <LoadingButton
        variant="secondary"
        size="medium"
        endIcon={<SwitchIcon />}
        loading={isPending}
        {...bindTrigger(popupState)}
        sx={{ fontSize: 13, fontWeight: 400 }}
      >
        {label} {labels[value]}
      </LoadingButton>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{
          paper: { sx: { minWidth: 264 } },
        }}
      >
        <MenuItem>
          <ListItemButton role="button" onClick={handleToggle('YES')} dense>
            <ListItemText>{t('yes')}</ListItemText>
            {value === 'YES' && (
              <ListItemIcon>
                <CheckIcon sx={{ width: 16, height: 16 }} />
              </ListItemIcon>
            )}
          </ListItemButton>
        </MenuItem>
        <MenuItem>
          <ListItemButton role="button" onClick={handleToggle('NO')} dense>
            <ListItemText>No</ListItemText>
            {value === 'NO' && (
              <ListItemIcon>
                <CheckIcon sx={{ width: 16, height: 16 }} />
              </ListItemIcon>
            )}
          </ListItemButton>
        </MenuItem>
        {presetValue !== null && (
          <MenuItem>
            <ListItemButton
              role="button"
              onClick={handleToggle('DEFAULT')}
              dense
            >
              <ListItemText>
                {presetValue ? 'Yes' : 'No'} (Customer Preset)
              </ListItemText>
              {value === 'DEFAULT' && (
                <ListItemIcon>
                  <CheckIcon sx={{ width: 16, height: 16 }} />
                </ListItemIcon>
              )}
            </ListItemButton>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
