const FormControl = (theme) => {
  return {
    MuiFormControl: {
      defaultProps: {
        size: 'small',
        variant: 'filled',
      },
      styleOverrides: {},
    },
    MuiFormControlLabel: {
      defaultProps: {},
      styleOverrides: {
        // resetting MarginLeft due to a issue with checkbox input.
        // not sure what the reason was to set -8 :-/
        root: { marginLeft: 0 },
        label: { ...theme.typography.bodyRegular },
      },
    },
  };
};

export default FormControl;
