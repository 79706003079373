import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SubcontractIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.3714 2.07152C17.133 1.97616 16.867 1.97616 16.6286 2.07152L11.6286 4.07152C11.249 4.22339 11 4.5911 11 5V11C11 11.4089 11.249 11.7766 11.6286 11.9285L16.6223 13.926C16.8865 14.0337 17.1873 14.0244 17.4472 13.8944L22.4472 11.3944C22.786 11.225 23 10.8788 23 10.5V5C23 4.79555 22.9378 4.60139 22.8281 4.43937C22.8003 4.39838 22.7695 4.35944 22.7359 4.32291C22.6534 4.23312 22.5539 4.15789 22.441 4.10248C22.4291 4.09666 22.4171 4.09105 22.405 4.08567C22.3924 4.0801 22.3797 4.07479 22.367 4.06975L17.3714 2.07152ZM13 10.323V6.47703L16 7.67703V11.523L13 10.323ZM19.3074 5L17 4.07703L14.6926 5L17 5.92297L19.3074 5ZM21 6.47704L18 7.67704V11.382L21 9.88197V6.47704Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.6 13.2997C5.74862 12.6611 3.5443 11.6618 0.835601 12.1133L0 12.2525V22.0997H4.7543L4.97895 21.3134L12.1568 23.706C13.0418 24.001 14.002 23.9774 14.8715 23.6393L22.8625 20.5317C23.1773 20.4092 23.4091 20.1361 23.4786 19.8055C23.5481 19.4748 23.446 19.1315 23.2071 18.8926L22.8612 18.5466C21.9018 17.5872 20.5214 17.1795 19.1946 17.4638L16.7514 17.9874C16.45 16.9143 15.5766 16.0939 14.4813 15.8633L6.98691 14.2855L7.1506 13.7126L6.6 13.2997ZM14.0692 17.8204C14.3925 17.8885 14.6578 18.1107 14.7841 18.409L14.0039 18.5761L9.71696 17.6235C9.17782 17.5037 8.64365 17.8436 8.52384 18.3827C8.40403 18.9219 8.74396 19.4561 9.2831 19.5759L13.9962 20.6232L19.6137 19.4195C19.8356 19.3719 20.0604 19.3631 20.2794 19.3903L14.1466 21.7753C13.7118 21.9444 13.2317 21.9562 12.7892 21.8087L5.52891 19.3885L6.43609 16.2134L14.0692 17.8204ZM2 20.0997V14.0034C3.19827 13.965 4.19748 14.2748 4.82574 14.5695L3.2457 20.0997H2Z"
        />
      </SvgIcon>
    );
  }
);

SubcontractIcon.displayName = 'SubcontractIcon';

export default SubcontractIcon;
