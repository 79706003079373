import { styled } from '@mui/system';
import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';

import { Box, IconButton, RemoveIcon, Skeleton, Stack } from '@dizzbo/ui';

import { LoadingPointAutocomplete } from '@core/components';
import { QueryKeys } from '@core/config';
import {
  useDeleteOrderStop,
  useGetOrderStop,
  useGetRoute,
  useUpdateOrderStop,
} from '@core/hooks';

import { LoadingPointBullet } from './LoadingPointBullet';
import { SectionSummary } from './SectionSummary';

import { LoadingPointType, UUIDType } from '@types';
import { useOrderDetail } from '../hooks';

const StopContainerStyles = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isHovering' && prop !== 'isDragging',
})<any>(({ theme, isHovering, isDragging }) => {
  return {
    paddingTop: 12,
    paddingBottom: 8,
    paddingRight: 24,
    paddingLeft: 24,
    height: '100%',
    backgroundColor: theme.styles.planner.stopListItem.default.backgroundColor,
    '&:hover': {
      backgroundColor: theme.styles.planner.stopListItem.hover.backgroundColor,
    },
  };
});

const BulletContainerStyles = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'lastItem',
})<any>(({ theme, lastItem }) => {
  if (lastItem) {
    return {
      height: '100%',
      position: 'relative',
    };
  }
  return {
    height: '100%',
    position: 'relative',
    minHeight: 75,
    '&::after': {
      content: "' '",
      position: 'absolute',
      top: 52,
      bottom: 0,
      left: '50%',
      border: '1px dashed #404E64',
    },
  };
});

type Props = {
  stopUUID: UUIDType;
  index: number;
  isDragging?: boolean;
  dragHandleAttributes?: any;
  dragHandleListeners?: any;
  style?: any;
  lastItem?: boolean;
};

export const StopListItem = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      stopUUID,
      index,
      isDragging,
      dragHandleAttributes,
      dragHandleListeners,
      style,
      lastItem,
    },
    ref
  ) => {
    const queryClient = useQueryClient();
    const [isHovering, setIsHovering] = useState(false);
    const { orderData } = useOrderDetail();
    const { uuid: orderUUID, route: routeUUID } = orderData;

    const { data } = useGetRoute(routeUUID);
    const { mutateAsync } = useUpdateOrderStop(orderUUID, stopUUID);
    const { data: stopData, isPending } = useGetOrderStop(orderUUID, stopUUID);
    const { mutateAsync: deleteStop } = useDeleteOrderStop(orderUUID, stopUUID);

    const displaySectionSummary = data?.sections.length >= index + 1;

    const handleDelete = async () => {
      try {
        await deleteStop();
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ROUTES, routeUUID],
        });
      } catch (error) {}
    };

    const handleAutocompleteOnChange = async (
      loadingPoint: LoadingPointType
    ) => {
      if (loadingPoint) {
        try {
          await mutateAsync({ loadingPoint: loadingPoint.uuid });
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.ROUTES, routeUUID],
          });
        } catch (error) {
          console.error('error', error);
        }
      }
    };

    return (
      <Box sx={{ backgroundColor: '#FFFFFF', ...style }} ref={ref}>
        <StopContainerStyles
          isHovering={isHovering}
          isDragging={isDragging}
          onMouseEnter={() => {
            setIsHovering(true);
          }}
          onMouseLeave={() => {
            setIsHovering(false);
          }}
          direction="row"
        >
          <BulletContainerStyles lastItem={lastItem}>
            <LoadingPointBullet
              index={index}
              dragHandleAttributes={dragHandleAttributes}
              dragHandleListeners={dragHandleListeners}
            />
          </BulletContainerStyles>
          <Stack
            spacing={4}
            direction="column"
            ml={3}
            sx={{
              display: 'inline',
              width: '100%',
            }}
          >
            <Stack direction="row" width="100%">
              {!isPending ? (
                <LoadingPointAutocomplete
                  label="Stop"
                  onChange={handleAutocompleteOnChange}
                  value={stopData.loadingPoint}
                  // disableClearable={false}
                  sx={{ width: '100%' }}
                  disableClearable={true}
                  disablePopupIcon={true}
                />
              ) : (
                <>
                  <Skeleton width="100%" height={40} />
                </>
              )}
              <IconButton
                size="large"
                variant="tertiary"
                color="success"
                sx={{ marginLeft: 2 }}
                onClick={handleDelete}
              >
                <RemoveIcon />
              </IconButton>
            </Stack>
            {displaySectionSummary && stopData?.originSection && (
              <SectionSummary sectionUUID={stopData.originSection} />
            )}
          </Stack>
        </StopContainerStyles>
      </Box>
    );
  }
);

StopListItem.displayName = 'StopListItem';

export default StopListItem;
