import { alpha } from '@mui/material/styles';

const input = (palette) => {
  return {
    input: {
      default: {
        color: palette.oxford[100],
        borderColor: palette.oxford[20],
        backgroundColor: palette.common.white,
      },
      hover: {
        borderColor: palette.turquoise[150],
      },
      disabled: {
        backgroundColor: alpha(palette.oxford[20], 0.1),
        borderColor: alpha(palette.turquoise[20], 0.1),
        iconColor: palette.oxford[25],
      },
      warning: {
        backgroundColor: palette.turquoise[40],
      },
    },
    inputLabel: {
      default: {
        color: palette.oxford[25],
      },
      disabled: {
        color: palette.oxford[25],
      },
    },
    inputAdornment: {
      default: {
        color: palette.oxford[100],
        borderColor: alpha(palette.turquoise[20], 0.25),
      },
    },
    slider: {
      default: {
        color: palette.oxford[100],
        track: { backgroundColor: palette.turquoise[150] },
        rail: { backgroundColor: alpha(palette.oxford[20], 0.25) },
        thumb: {
          backgroundColor: palette.turquoise[150],
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.5'%3E%3Cline x1='8.5' y1='4' x2='8.5' y2='16' stroke='white'/%3E%3Cline x1='11.5' y1='4' x2='11.5' y2='16' stroke='white'/%3E%3C/g%3E%3C/svg%3E%0A")`,
        },
      },
    },
  };
};

export default input;
