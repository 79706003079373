const Popover = (theme) => {
  return {
    MuiPopover: {
      defaultProps: {
        elevation: 4,
      },
      styleOverrides: {
        root: {},
        paper: {
          borderRadius: theme.shape.borderRadius,
          backgroundColor:
            theme.styles.paper.filledPrimary.default.backgroundColor,
        },
      },
    },
  };
};

export default Popover;
