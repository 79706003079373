import { alpha } from '@mui/material/styles';
import { motion } from 'framer-motion';
import React, { FC } from 'react';

export const LoadingIndicator: FC = () => {
  const container = {
    hidden: {},
    show: {
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
        staggerDirection: -1,
      },
    },
  };

  const strokeVariants = {
    hidden: {
      fill: 'rgba(114, 184, 181, 1)',
    },
    show: {
      fill: alpha('rgba(114, 184, 181, 1)', 0.3),
    },
  };

  const strokeTransition = {
    duration: 1,
    repeat: Infinity,
    ease: 'easeOut',
  };

  return (
    <motion.svg
      variants={container}
      initial="hidden"
      animate="show"
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        variants={strokeVariants}
        transition={strokeTransition}
        d="M18.2058 23.5609H29.0926V18.5853H21.1885L18.2058 23.5609Z"
      />
      <motion.path
        variants={strokeVariants}
        transition={strokeTransition}
        d="M27.7498 3.51165L29.6885 0.438477H23.7232L20.7405 5.41409L9.70459 23.5604H15.6699L27.7498 3.51165Z"
      />
      <motion.path
        variants={strokeVariants}
        transition={strokeTransition}
        d="M14.9239 0.438477L12.3886 4.68238L0.905273 23.5604H7.01976L9.8533 18.5848L20.8892 0.438477C20.8892 0.438477 14.9239 0.438477 14.9239 0.438477Z"
      />
      <motion.path
        variants={strokeVariants}
        transition={strokeTransition}
        d="M10.5993 3.51165L12.3889 0.438477H2.2478V5.41409H9.40622L10.5993 3.51165Z"
      />
    </motion.svg>
  );
};
