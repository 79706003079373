import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useDebounce } from 'react-use';
import { SxProps } from '@mui/system';

import {
  Box,
  TextField,
  IconButton,
  MinimalIconButton,
  InputAdornment,
} from '@dizzbo/ui';
import { SearchIcon, RemoveIcon } from '@dizzbo/ui';

type Props = {
  searchValue?: string;
  setSearchValue?: (searchValue: string) => void;
  sx?: SxProps;
};

export const ListSearch: React.FC<Props> = ({
  searchValue,
  setSearchValue,
}: Props) => {
  const theme = useTheme();

  const [isReady, cancel] = useDebounce(
    () => {
      setSearchValue(searchValue);
    },
    500,
    [searchValue]
  );

  const handleClearSearchString = () => {
    setSearchValue('');
  };

  return (
    <Box
      sx={{
        position: 'relative',
        flexGrow: 1,
        paddingRight: 6,
        paddingBottom: 3,
        paddingLeft: 6,
        // boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.08)',
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
        }}
      >
        <TextField
          label="Find tour"
          value={searchValue}
          onChange={({ currentTarget }) => {
            setSearchValue(currentTarget.value);
          }}
          fullWidth
          InputProps={{
            endAdornment: (
              <>
                {searchValue && (
                  <InputAdornment position="end">
                    <MinimalIconButton
                      sx={{ opacity: 0.5 }}
                      onClick={handleClearSearchString}
                    >
                      <RemoveIcon />
                    </MinimalIconButton>
                  </InputAdornment>
                )}
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              </>
            ),
          }}
        />
      </Box>
    </Box>
  );
};
