import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLinkClickHandler } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { BannerButton } from '@dizzbo/ui';
import { getOrders } from '@core/api/orders';
import { QueryKeys } from '@core/config';

export const ReviewUnloadingTimeTodo: React.FC = () => {
  const { t } = useTranslation();
  const filter = { unloadingTimeTodo: true };
  const { data: ordersData, isPending } = useQuery({
    queryKey: [QueryKeys.ORDERS, filter],
    queryFn: () => getOrders(filter),
  });

  // const openUnloadingTimeTodoOrders = useLinkClickHandler({
  //   pathname: '/orders',
  //   search: 'todo=unloading_time_todo',
  // });

  return (
    <BannerButton
      count={!isPending ? ordersData?.count : 0}
      label={t('reviewUnloadingTime')}
      // onClick={openUnloadingTimeTodoOrders}
    />
  );
};
