import React, { FC, useState } from 'react';
import {
  usePopupState,
  bindTrigger,
  bindPopover,
  bindMenu,
} from 'material-ui-popup-state/hooks';

import {
  Box,
  PageHeader,
  Button,
  Menu,
  MenuItem,
  ListItemButton,
  ListItemText,
} from '@dizzbo/ui';
import { DateIcon, SwitchIcon } from '@dizzbo/ui';
import { View } from '@dizzbo/core/views';

import { TourList } from '../components/TourList';

export const TourListView: FC = () => {
  const viewTypes = [
    { label: 'List', value: 'list' },
    { label: 'Calendar', value: 'calendar' },
  ];
  const [viewType, setViewType] = useState('list');
  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'tourListSwitch',
  });

  const handleViewTypeChange = (type: string) => {
    setViewType(type);
    popupState.close();
  };

  return (
    <View title="My Tours" sx={{ height: '100%' }}>
      <PageHeader title="My Tours">
        <Button
          variant="secondary"
          size="medium"
          startIcon={
            <>
              <DateIcon />
            </>
          }
          endIcon={
            <>
              <SwitchIcon />
            </>
          }
          {...bindTrigger(popupState)}
        >
          {viewType === 'list' ? 'List' : 'Calendar'}
        </Button>
        <Menu
          {...bindMenu(popupState)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          // slotProps={{
          //   paper: { sx: { minWidth: 264 } },
          // }}
        >
          {viewTypes.map((item) => (
            <MenuItem
              key={item.value}
              onClick={() => handleViewTypeChange(item.value)}
            >
              <ListItemButton role="button" dense>
                <ListItemText>{item.label}</ListItemText>
              </ListItemButton>
            </MenuItem>
          ))}
        </Menu>
      </PageHeader>
      <Box sx={{ height: 'calc(-64px + 100vh)' }}>
        <TourList viewType={viewType} />
      </Box>
    </View>
  );
};
