import React from 'react';
import Popper from '@mui/material/Popper';
import { SxProps } from '@mui/system';

import { Autocomplete, ListItem, ListItemText, TextField } from '@dizzbo/ui';
import { CheckIcon } from '@dizzbo/ui';

const styles = {
  popper: {
    width: 'fit-content',
  },
};

export type TypeOfGood = {
  uuid: string;
  title: string;
};

type DeliveryGoodTypeAutoCompleteTextFieldProps = {
  data: [];
  label: string;
  value?: object;
  onChange?: (department: TypeOfGood) => void;
  sx?: SxProps;
  disablePopupIcon?: boolean;
  disableClearable?: boolean;
};

const PopperMy = function (props) {
  // need to override style here because else "fit-content" would not be
  // recognized by MUI and how it calculates the width of the popper
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};

export const DeliveryGoodTypeAutoCompleteTextField = React.forwardRef(
  (
    {
      data,
      label,
      value,
      onChange,
      sx,
      disablePopupIcon,
      disableClearable = true,
    }: DeliveryGoodTypeAutoCompleteTextFieldProps,
    ref
  ) => {
    return (
      <>
        <Autocomplete
          ref={ref}
          sx={{
            ...sx,
            ...(disablePopupIcon && {
              '.MuiInputBase-root': {
                paddingRight: '10px !important',
              },
            }),
          }}
          disableClearable={disableClearable}
          disableCloseOnSelect
          autoComplete // seems not to work
          autoHighlight
          blurOnSelect // input should be blurred when an option is selected
          clearOnEscape // clear input via pressing Esc
          // popupIcon={<SearchIcon />}
          PopperComponent={PopperMy}
          componentsProps={{
            popper: {
              sx: {
                width: 'auto',
              },
            },
            popupIndicator: {
              sx: {
                ...(disablePopupIcon && {
                  display: 'none',
                }),
              },
            },
          }}
          noOptionsText="No type of good found"
          options={data || []}
          value={value}
          // filterOptions={(x) => x}
          renderInput={(params) => <TextField {...params} label={label} />}
          getOptionLabel={(option) => {
            return option.title;
          }}
          isOptionEqualToValue={(option, value) => option.uuid === value}
          onChange={(event: any, value: TypeOfGood | null, reason: string) => {
            if (reason === 'clear') {
              // TODO this is not correct, we need to remove the value here. So that we have ab empty field.
              // was before setInputValue(null);
              onChange(value);
              // setInputValue(null);
              // handle the event of clearing the input eg clean up the context
            } else {
              onChange(value);
            }
          }}
          renderOption={(props, typeOfGood: TypeOfGood, { inputValue }) => {
            // removing className property as we dont need the special styling applied via autocomplete
            const { className, ...rest } = props;

            let listItemProps = {};

            if (props['aria-selected']) {
              listItemProps = {
                secondaryAction: <CheckIcon sx={{ width: 16, height: 16 }} />,
              };
            }

            return (
              <ListItem {...rest} {...listItemProps}>
                <ListItemText>{typeOfGood.title}</ListItemText>
              </ListItem>
            );
          }}
        />
      </>
    );
  }
);

DeliveryGoodTypeAutoCompleteTextField.displayName =
  'DeliveryGoodTypeAutoCompleteTextField';
