import React from 'react';
import { useLocalStorage } from 'react-use';

import { View } from '@dizzbo/core/views';
import { Box } from '@dizzbo/ui';

import { RoutingMapStatic } from '@core/components/RoutingMap';
import { RouteType } from '@types';

type MapSettingsDataType = {
  settings?: { zoom: number; lat: number; lng: number };
  hideWaypoints?: boolean;
  language?: string;
  mapType?: string;
  mapOverlayType?: string;
};

type Props = {};

export const MapPreviewView: React.FC<Props> = () => {
  const [routeData, setRouteData] = useLocalStorage<RouteType>('route');
  const [mapSettingsData, setMapSettingsData] =
    useLocalStorage<MapSettingsDataType>('map_settings');

  console.log(routeData);

  return (
    <View title="MapPreview">
      <Box
        sx={{
          height: '100vh',
          backgroundColor: '#FFFFFF',
          width: '100%',
        }}
      >
        <RoutingMapStatic
          route={routeData}
          settings={mapSettingsData?.settings}
          hideWaypoints={mapSettingsData?.hideWaypoints}
          language={mapSettingsData?.language}
          mapType={mapSettingsData?.mapType}
          mapOverlayType={mapSettingsData?.mapOverlayType}
        />
      </Box>
    </View>
  );
};
