import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ArrowUpIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M16.9998 8.75813L11.9999 4L7 8.75813L8.50218 10.1877L11 7.81063V20H13V7.81083L15.4976 10.1877L16.9998 8.75813Z" />
      </SvgIcon>
    );
  }
);

ArrowUpIcon.displayName = 'ArrowUpIcon';

export default ArrowUpIcon;
