import React, { useEffect } from 'react';
import { useLocalStorage } from 'react-use';

import { LoadingButton } from '@dizzbo/ui';
import { getOAuthURL } from '@dizzbo/core/api';

type Props = {
  redirectURL: string;
  children: React.ReactNode;
};

export const OAuthButton: React.FC<Props> = ({ redirectURL, children }) => {
  const [value, setValue, remove] = useLocalStorage('flowId');

  useEffect(() => {
    remove();
  }, []);

  const handleAzureOAuth = async () => {
    const data = await getOAuthURL({
      redirectUrl: redirectURL,
    });
    if (data) {
      setValue(data.flowId);
      window.location.assign(data.requestUrl);
    }
  };

  return (
    <LoadingButton
      fullWidth
      size="large"
      variant="primary"
      loading={false}
      onClick={handleAzureOAuth}
    >
      {children}
    </LoadingButton>
  );
};
