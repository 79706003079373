import { ListItemText as MuiListItemText } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiListItemTextProps = ComponentProps<typeof MuiListItemText>;

export const ListItemText = forwardRef<HTMLDivElement, MuiListItemTextProps>(
  (props, ref) => <MuiListItemText {...props} ref={ref} />
);

ListItemText.displayName = 'ListItemText';
