import * as React from 'react';
import {
  BarPlot,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  BarElement,
  ResponsiveChartContainer,
  BarElementProps,
} from '@mui/x-charts';
import { useTheme } from '@mui/material/styles';

import { Card, CardHeader } from '@dizzbo/ui';

type SlotBarElementProps = {
  data: number[];
  threshold?: number;
  ownerState: {
    classes?: [];
    color: string;
    dataIndex: number;
    id: string;
    isFaded: boolean;
    isHighlighted: boolean;
  };
};

const SlotBarElement: React.FC<BarElementProps & SlotBarElementProps> = ({
  data,
  threshold,
  style,
  ownerState,
  ...other
}) => {
  const theme = useTheme();
  const isBelowBar = data[ownerState.dataIndex] < threshold;
  const color = isBelowBar ? theme.palette.red[60] : theme.palette.forest[60];

  // work around BarElement export issue
  return (
    <BarElement
      {...other}
      dataIndex={ownerState.dataIndex}
      id={ownerState.id}
      style={{
        ...style,
        fill: color,
      }}
    />
  );
};

type BarChartCardProps = {
  title: string;
  data: number[];
};

export const BarChartCard: React.FC<BarChartCardProps> = ({
  title,
  data = [1100, 700, 100, 900, 130, 440, 900],
}) => {
  const theme = useTheme();
  const threshold = 200;

  return (
    <Card variant="filled-secondary" elevation={1}>
      <CardHeader title={title} variant="small" />

      <ResponsiveChartContainer
        height={300}
        series={[
          {
            type: 'bar',
            data: data,
          },
        ]}
        xAxis={[
          {
            scaleType: 'band',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          },
        ]}
        sx={{
          '& .MuiChartsAxis-root': {
            '& .MuiChartsAxis-tickLabel': {
              fontSize: '10px !important',
              fill: theme.palette.oxford[25],
            },
            '& .MuiChartsAxis-line': {
              stroke: 'transparent',
            },
            '& .MuiChartsAxis-tick': {
              stroke: 'transparent',
            },
          },
        }}
      >
        <BarPlot
          // skipAnimation
          slots={{ bar: SlotBarElement }}
          slotProps={{
            bar: {
              data,
              threshold,
            },
          }}
        />
        <ChartsXAxis />
        <ChartsYAxis position="left" />
        <ChartsTooltip />
      </ResponsiveChartContainer>
    </Card>
  );
};
