import { Select as MuiSelect } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { ComponentProps, forwardRef } from 'react';

interface SelectPropsOverrides {
  /**
   * The variant to use.
   * @default 'filled'
   */
  type?: 'filled' | 'text';
}

type MuiSelectProps = ComponentProps<typeof MuiSelect>;

type SelectProps = MuiSelectProps & SelectPropsOverrides;

const MuiSelectStyle = styled(MuiSelect, {
  shouldForwardProp: (prop) => prop !== 'type',
})<SelectProps>(({ type }) => {
  let style;

  style = {};

  if (type === 'text') {
    style = {};
  }

  return {
    ...style,
  };
});

export const Select = forwardRef<HTMLButtonElement, SelectProps>(
  ({ type, ...rest }: SelectProps, ref) => (
    <MuiSelectStyle type={type} ref={ref} {...rest} />
  )
);

Select.displayName = 'Select';
