import { alpha } from '@mui/material/styles';

// Button Styles are used for Button and IconButton

const button = (palette) => {
  return {
    button: {
      primary: {},
      primaryDestructive: {
        default: {
          color: palette.common.white,
          backgroundColor: palette.red[100],
        },
        hover: {
          color: palette.common.white,
          backgroundColor: palette.red[150],
        },
        active: {
          color: alpha(palette.common.white, 0.5),
          backgroundColor: palette.red[150],
        },
        focus: {
          color: palette.common.white,
          backgroundColor: palette.red[80],
          boxShadowColor: palette.red[150],
        },
      },
      secondary: {
        default: {
          color: palette.turquoise[100],
          backgroundColor: palette.turquoise[175],
        },
        hover: {
          color: palette.oxford[100],
          backgroundColor: palette.turquoise[200],
        },
        active: {
          color: alpha(palette.oxford[100], 0.5),
          backgroundColor: palette.turquoise[200],
        },
        focus: {
          color: palette.oxford[100],
          backgroundColor: palette.turquoise[175],
          boxShadowColor: palette.turquoise[150],
        },
      },
      secondaryContrast: {
        default: {
          color: palette.oxford[100],
          backgroundColor: palette.turquoise[60],
        },
        hover: {
          color: palette.oxford[100],
          backgroundColor: palette.turquoise[80],
        },
        active: {
          color: alpha(palette.oxford[100], 0.5),
          backgroundColor: palette.turquoise[80],
        },
        focus: {
          color: palette.oxford[100],
          backgroundColor: palette.turquoise[60],
          boxShadowColor: palette.turquoise[150],
        },
      },
      tertiary: {
        default: {
          color: palette.turquoise[100],
        },
        hover: {
          color: palette.turquoise[100],
          boxShadowColor: palette.turquoise[100],
        },
        active: {
          color: alpha(palette.turquoise[100], 0.5),
          boxShadowColor: alpha(palette.oxford[20], 0.25),
        },
        focus: {
          color: palette.turquoise[100],
          boxShadowColor: palette.turquoise[100],
        },
      },
      tertiaryDestructive: {
        default: {
          color: palette.red[80],
        },
        hover: {
          color: palette.red[80],
          boxShadowColor: palette.red[80],
        },
        active: {
          color: alpha(palette.red[80], 0.5),
          boxShadowColor: alpha(palette.oxford[20], 0.25),
        },
        focus: {
          color: palette.red[80],
          boxShadowColor: palette.red[80],
        },
      },
      tertiaryContrast: {
        default: {
          color: palette.turquoise[150],
        },
        hover: {
          color: palette.turquoise[150],
          boxShadowColor: palette.turquoise[150],
        },
        active: {
          color: alpha(palette.turquoise[150], 0.5),
          borderColor: alpha(palette.oxford[20], 0.25),
        },
        focus: {
          color: palette.turquoise[150],
          borderColor: palette.turquoise[150],
        },
      },
      tertiaryDestructiveContrast: {
        default: {
          color: palette.red[100],
        },
        hover: {
          color: palette.red[100],
          boxShadowColor: palette.red[100],
        },
        active: {
          color: alpha(palette.red[100], 0.5),
          boxShadowColor: alpha(palette.oxford[20], 0.25),
        },
        focus: {
          color: palette.red[100],
          boxShadowColor: palette.red[100],
        },
      },
    },
  };
};

export default button;
