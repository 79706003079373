import React, { ReactNode } from 'react';

import { PointIcon, TableInstance } from '@dizzbo/ui';
import { OrderTodoType, OrderType } from '@types';

type Props = {
  renderedCellValue: ReactNode;
  table: TableInstance<OrderType>;
};

export const StatusIconCell: React.FC<Props> = ({
  renderedCellValue: todos,
}: Props) => (
  <>
    {(todos as OrderTodoType[]).length > 0 && (
      <PointIcon sx={{ width: 16, height: 16 }} color="info" />
    )}
  </>
);
