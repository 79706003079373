import React from 'react';
import { styled } from '@mui/material/styles';

import { Card, Typography } from '@dizzbo/ui';
import { TurnoverIcon } from '@dizzbo/ui';

const StatsCardWrapperStyles = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'status',
})<any>(({ theme, status }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: 24,
  };
});

type Props = {
  label: string;
  value?: string;
};

export const StatsCard: React.FC<Props> = ({ label, value, ...rest }) => {
  return (
    <StatsCardWrapperStyles variant="filled-secondary" elevation={1}>
      <TurnoverIcon sx={{ width: 32, height: 32, marginBottom: 3 }} />
      <Typography variant="h4" color="primary">
        {value}
      </Typography>
      <Typography variant="bodyRegularSmall" color="primary">
        {label}
      </Typography>
    </StatsCardWrapperStyles>
  );
};
