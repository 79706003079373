import { useQueries } from '@tanstack/react-query';

import { getOrders } from '@core/api/orders';
import { QueryKeys } from '@core/config';

const orderTodoFilters = [
  'order_confirmation_todo',
  'transport_contract_todo',
  'loading_time_todo',
  'unloading_time_todo',
  'pod_upload_todo',
  'pod_review_todo',
  'order_review_todo',
];

export const useTodoCount = () => {
  const ordersQueries = useQueries({
    queries: orderTodoFilters.map((todoName) => {
      const filter = { todos: todoName };
      return {
        queryKey: [QueryKeys.ORDERS, filter],
        queryFn: () => getOrders(filter),
        staleTime: 1000,
      };
    }),
  });
  const isLoading = ordersQueries.some((query) => query.isPending);

  let todosCount = 0;
  let todosCountGrouped = {};
  if (!isLoading) {
    ordersQueries?.map((query, index) => {
      todosCountGrouped = {
        ...todosCountGrouped,
        ...{ [orderTodoFilters[index]]: query?.data.count },
      };
      todosCount += query?.data.count;
    });
  }
  return { todosCount, todosCountGrouped };
};
