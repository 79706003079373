import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLinkClickHandler } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { BannerButton } from '@dizzbo/ui';
import { DocumentContractIcon } from '@dizzbo/ui';
import { getOrders } from '@core/api/orders';
import { QueryKeys } from '@core/config';

export const TransportContractTodo: React.FC = () => {
  const { t } = useTranslation();
  const filter = { transportContractTodo: true };
  const { data: ordersData, isPending } = useQuery({
    queryKey: [QueryKeys.ORDERS, filter],
    queryFn: () => getOrders(filter),
  });

  // const openTransportContractTodoOrders = useLinkClickHandler({
  //   pathname: '/orders',
  //   search: 'todo=transport_contract_todo',
  // });

  return (
    <BannerButton
      count={!isPending ? ordersData?.count : 0}
      label={t('confirmTransportContract')}
      icon={<DocumentContractIcon />}
      // onClick={openTransportContractTodoOrders}
    />
  );
};
