import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const InvoicingIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 14C21.3137 14 24 11.3137 24 8C24 4.68629 21.3137 2 18 2C14.6863 2 12 4.68629 12 8C12 11.3137 14.6863 14 18 14ZM14 8.75204H14.8293C15.1771 10.0763 16.4611 11 18.0217 11C19.3771 11 20.5006 10.3379 21 9.18529H19.6C19.2166 9.68392 18.6904 9.89646 18.0306 9.89646C17.1032 9.89646 16.4076 9.43052 16.1134 8.75204H18.7172L18.8866 8.29428L15.9796 8.29425C15.9707 8.19617 15.9618 8.09808 15.9618 8C15.9618 7.89373 15.9707 7.79564 15.9885 7.69755H19.1185L19.2968 7.23161H16.1223C16.4166 6.56131 17.1121 6.10354 18.0306 6.10354C18.6904 6.10354 19.2166 6.31608 19.6 6.81471H21C20.5006 5.66213 19.3771 5 18.0217 5C16.4611 5 15.1949 5.91553 14.8293 7.23161H14.1873L14 7.69755H14.749C14.7401 7.79564 14.7312 7.89374 14.7312 8C14.7312 8.09809 14.7401 8.19618 14.749 8.29427L14.1873 8.29428L14 8.75204Z"
        />
        <path d="M5 3H13.101C12.5151 3.57412 12.0297 4.2504 11.6736 5H5C4.44772 5 4 5.44772 4 6V10H11.2899C11.3926 10.3452 11.5213 10.6793 11.6736 11H4V19C4 19.5523 4.44772 20 5 20H16C16.5523 20 17 19.5523 17 19V14.9291C17.3266 14.9758 17.6605 15 18 15C18.3395 15 18.6734 14.9758 19 14.9291V19C19 20.6569 17.6569 22 16 22H5C3.34315 22 2 20.6569 2 19V6C2 4.34315 3.34315 3 5 3Z" />
        <path d="M5 8H10V7H5V8Z" />
        <path d="M8 13.5C8 14.3284 7.32843 15 6.5 15C5.67157 15 5 14.3284 5 13.5C5 12.6716 5.67157 12 6.5 12C7.32843 12 8 12.6716 8 13.5Z" />
        <path d="M10.5 15C11.3284 15 12 14.3284 12 13.5C12 12.6716 11.3284 12 10.5 12C9.67157 12 9 12.6716 9 13.5C9 14.3284 9.67157 15 10.5 15Z" />
        <path d="M8 17.5C8 18.3284 7.32843 19 6.5 19C5.67157 19 5 18.3284 5 17.5C5 16.6716 5.67157 16 6.5 16C7.32843 16 8 16.6716 8 17.5Z" />
        <path d="M10.5 19C11.3284 19 12 18.3284 12 17.5C12 16.6716 11.3284 16 10.5 16C9.67157 16 9 16.6716 9 17.5C9 18.3284 9.67157 19 10.5 19Z" />
      </SvgIcon>
    );
  }
);

InvoicingIcon.displayName = 'InvoicingIcon';

export default InvoicingIcon;
