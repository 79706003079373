import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CheckCircleIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
        />
        <path d="M10.5001 16.0607L17.0304 9.53033L15.9697 8.46967L10.5001 13.9393L8.03039 11.4697L6.96973 12.5303L10.5001 16.0607Z" />
      </SvgIcon>
    );
  }
);

CheckCircleIcon.displayName = 'CheckCircleIcon';

export default CheckCircleIcon;
