import Popper from '@mui/material/Popper';
import { SxProps } from '@mui/system';
import React from 'react';

import {
  Autocomplete,
  CheckIcon,
  ListItem,
  ListItemText,
  TextField,
} from '@dizzbo/ui';
import { DepartmentType } from '@types';

const styles = {
  popper: {
    width: 'fit-content',
  },
};

type DepartmentsAutoCompleteTextFieldProps = {
  data: DepartmentType[];
  label: string;
  value?: any;
  onChange?: (department: DepartmentType) => void;
  sx?: SxProps;
  disablePopupIcon?: boolean;
  disableClearable?: boolean;
  display?: boolean;
};

const PopperMy = function (props) {
  // need to override style here because else "fit-content" would not be
  // recognized by MUI and how it calculates the width of the popper
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};

export const DepartmentsAutoCompleteTextField = React.forwardRef(
  (
    {
      data,
      label,
      value,
      onChange,
      sx,
      disablePopupIcon,
      disableClearable = true,
      display,
    }: DepartmentsAutoCompleteTextFieldProps,
    ref
  ) => {
    return (
      <>
        <Autocomplete
          ref={ref}
          sx={{
            ...sx,
            ...(disablePopupIcon && {
              '.MuiInputBase-root': {
                paddingRight: '10px !important',
              },
            }),
          }}
          disableClearable={disableClearable}
          disableCloseOnSelect={true}
          autoComplete={true} // seems not to work
          autoHighlight
          blurOnSelect={true} // input should be blurred when an option is selected
          clearOnEscape={true} // clear input via pressing Esc
          // popupIcon={<SearchIcon />}
          PopperComponent={PopperMy}
          componentsProps={{
            popper: {
              sx: {
                width: 'auto',
              },
            },
            popupIndicator: {
              sx: {
                ...(disablePopupIcon && {
                  display: 'none',
                }),
              },
            },
          }}
          noOptionsText="No matching department found"
          options={data || []}
          value={value}
          disabled={display}
          // filterOptions={(x) => x}
          renderInput={(params) => (
            <TextField {...params} display={display} label={label} />
          )}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
          onChange={(
            event: any,
            value: DepartmentType | null,
            reason: string
          ) => {
            if (reason === 'clear') {
              // TODO this is not correct, we need to remove the value here. So that we have ab empty field.
              // was before setInputValue(null);
              onChange(value);
              // setInputValue(null);
              // handle the event of clearing the input eg clean up the context
            } else {
              onChange(value);
            }
          }}
          renderOption={(props, department: DepartmentType, { inputValue }) => {
            // removing className property as we dont need the special styling applied via autocomplete
            const { className, ...rest } = props;

            let listItemProps = {};

            if (props['aria-selected']) {
              listItemProps = {
                secondaryAction: <CheckIcon sx={{ width: 16, height: 16 }} />,
              };
            }

            return (
              <ListItem {...rest} {...listItemProps}>
                <ListItemText>{department.name}</ListItemText>
              </ListItem>
            );
          }}
        />
      </>
    );
  }
);

DepartmentsAutoCompleteTextField.displayName =
  'DepartmentsAutoCompleteTextField';
