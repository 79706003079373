import React, {
  ComponentState,
  useMemo,
  createContext,
  ReactNode,
  useReducer,
} from 'react';

interface ProviderProps {
  children: ReactNode;
}

// ----------------------------------------------------------------------

const initialState = {
  tickWidth: 100,
  visibleDate: {
    date: new Date(),
    block: 'center',
  },
};

const handlers = {
  SET_TICK_WIDTH: (state: ComponentState, action: any) => {
    const { tickWidth } = action.payload;
    return {
      ...state,
      tickWidth,
    };
  },
  SET_DATE: (state: ComponentState, action: any) => {
    const { visibleDate } = action.payload;
    return {
      ...state,
      visibleDate: visibleDate,
    };
  },
};

const reducer = (state: ComponentState, action: any) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const SchedulerSettingsContext = createContext({
  ...initialState,
  setTickWidth: (event: Event, newValue: number | number[]) =>
    Promise.resolve(),
  setDate: (event: Event, date: Date) => Promise.resolve(),
  setDateToday: () => Promise.resolve(),
});

SchedulerSettingsContext.displayName = 'SchedulerSettingsContext';
// ----------------------------------------------------------------------

function SchedulerSettingsProvider({ children }: ProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setTickWidth = (event: Event, tickWidth: number | number[]) => {
    dispatch({
      type: 'SET_TICK_WIDTH',
      payload: {
        tickWidth: tickWidth,
      },
    });
  };

  const setDateToday = () => {
    dispatch({
      type: 'SET_DATE',
      payload: {
        visibleDate: {
          date: new Date(),
          block: 'center',
        },
      },
    });
  };

  const value = useMemo(
    () => ({
      ...state,
      setTickWidth,
      setDateToday,
    }),
    [state]
  );

  return (
    <SchedulerSettingsContext.Provider value={value}>
      {children}
    </SchedulerSettingsContext.Provider>
  );
}

export { SchedulerSettingsProvider, SchedulerSettingsContext };
