import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { Stack, Box } from '@dizzbo/ui';
import { Currency } from '@dizzbo/core/i18n';

const ContainerStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
}));

const LeadingStyles = styled(Box)(({ theme }) => ({
  ...theme.typography.h5,
  marginRight: 4,
}));

const PriceContainerStyles = styled(Box)(({ theme }) => ({
  marginLeft: 12,
}));

const LabelStyles = styled(Box)(({ theme }) => ({
  ...theme.typography.labelSmall,
  color: theme.styles.common.textColors.light,
}));

const PriceStyles = styled(Box)(({ theme }) => ({
  ...theme.typography.h3,
}));

export const CustomerPriceTotal = ({ price }: { price?: number | string }) => {
  const { t } = useTranslation();
  return (
    <ContainerStyles>
      <Stack direction="row" alignItems="center" sx={{ height: '100%' }}>
        <LeadingStyles>=</LeadingStyles>
        <PriceContainerStyles>
          <LabelStyles>{t('customerPriceTotal')}</LabelStyles>
          <PriceStyles>
            {price ? <Currency value={price} /> : '--,-- €'}
          </PriceStyles>
        </PriceContainerStyles>
      </Stack>
    </ContainerStyles>
  );
};
