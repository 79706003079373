const common = (palette) => {
  return {
    common: {
      pageBackground: palette.common.white,
      modalBackground: palette.common.white,
      textColors: {
        main: palette.oxford[40],
        dark: palette.oxford[100],
        light: palette.oxford[25],
      },
      // colors: {
      //   primary: {
      //     dark: palette.turquoise[150], // Turquoise/150
      //     main: palette.turquoise[100], // Turquoise/100 ++
      //     light: '#C4EBE8', // Turquoise/50
      //   },
      //   secondary: {
      //     dark: '#081324', // Oxford Blue/100
      //     main: palette.oxford[100], // Oxford Blue/60
      //     light: palette.oxford[40], // Oxford Blue/40
      //   },
      // },
      error: {
        main: '#C25253',
        light: '#C25253',
        dark: '#CF5759',
      },
      warning: {
        main: '#DD8B00',
        light: '#DD8B00',
        dark: '#E69101',
      },
      info: {
        main: '#4A73C7',
        light: '#4A73C7',
        dark: '#5B8FF5',
      },
      success: {
        main: '#348267',
        light: '#348267',
        dark: '#3F9E7D',
      },
      orderStatus: {
        DRAFT: palette.orange[100],
        CONFIRMED: palette.oxford[20],
        DISPATCHED: palette.blue[100],
        UNLOADED: palette.blue[100],
        INVOICED: palette.forest[100],
      },
    },
  };
};

export default common;
