const List = (theme) => {
  return {
    MuiList: {
      defaultProps: {},
      styleOverrides: {
        root: {},
      },
    },

    MuiListSubheader: {
      defaultProps: {},
      styleOverrides: {
        root: {
          ...theme.typography.label,
          paddingTop: theme.spacing(2),
          paddingRight: theme.spacing(6),
          paddingBottom: theme.spacing(2),
          paddingLeft: theme.spacing(6),
          color: theme.styles.listSubheader.default.color,
        },
      },
    },
    MuiListItem: {
      defaultProps: {},
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          return {
            cursor: 'pointer',
            paddingTop: 0,
            paddingRight: theme.spacing(6),
            paddingBottom: 0,
            paddingLeft: theme.spacing(6),
            ...(ownerState.disableGutters && {
              paddingRight: 0,
              paddingLeft: 0,
            }),
            height: theme.spacing(10),
            '&.Mui-focused, &:hover': {
              backgroundColor:
                theme.styles.listItem.primary.hover.backgroundColor,
            },
            '&[aria-selected="true"]': {
              backgroundColor:
                theme.styles.listItem.primary.active.backgroundColor,
            },
            '.MuiButtonBase-root': {
              height: '100%',
            },
            '.MuiCheckbox-root ': {
              svg: {
                width: 20,
                height: 20,
                color: theme.styles.listItem.checkBoxIconColor,
              },
            },
          };
        },
      },
    },
    MuiListItemText: {
      defaultProps: {},
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    },
    MuiListItemIcon: {
      defaultProps: {},
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: theme.spacing(2),
        },
      },
    },
    MuiListItemButton: {
      defaultProps: {},
      styleOverrides: {
        root: {
          padding: 0,
          color: theme.styles.listItemButton.default.color,
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '.MuiListItemIcon-root': {
            color: theme.styles.listItemButton.default.color,
          },
        },
      },
    },
  };
};

export default List;
