import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { updateTour } from '@core/api';
import { AutoSave } from '@core/components';
import { QueryKeys } from '@core/config';
import { useFieldErrors } from '@dizzbo/core/hooks';
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  WarningIcon,
} from '@dizzbo/ui';

import { UUIDType } from '@types';
import { CarrierPriceTotal } from './CarrierPriceTotal';

type Props = {
  tourUUID: UUIDType;
  orderUUID: UUIDType;
  price?: number;
  orientation?: string;
};

export const CarrierPrice: React.FC<Props> = ({
  tourUUID,
  orderUUID,
  price,
  orientation = 'vertical',
}) => {
  const queryClient = useQueryClient();

  const {
    mutate: mutateOrder,
    isError,
    error,
  }: any = useMutation({
    // TODO: Currently we refetch the OrderTodos via the mutation key
    // this is in the LTL case a problem
    mutationKey: [QueryKeys.ORDERS, orderUUID],
    mutationFn: (values) =>
      updateTour({ tourUUID: tourUUID, tourData: values }),
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ORDERS, orderUUID],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TOURS, tourUUID],
      });
    },
  });

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    values: {
      price: price ? price.toFixed(2) : '',
    },
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
  });

  const { hasFieldError, fieldError } = useFieldErrors(isError, errors, error);

  const onSubmit = (formData: any) => {
    mutateOrder(formData, {
      onSuccess: (data: any, values: any) => {},
      onError: (error: any, variables: any, context: any) => {},
    });
  };

  return (
    <Card
      variant="filled-secondary"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
        minHeight: orientation === 'horizontal' ? 0 : 374,
      }}
    >
      <CardHeader title="Carrier Price" variant="small" />
      <CardContent>
        {!price && (
          <Alert
            title="Carrier price missing"
            variant="standard"
            severity="warning"
            icon={<WarningIcon />}
            sx={{ marginBottom: 6 }}
          />
        )}

        <Stack
          spacing={6}
          divider={<Divider orientation="horizontal" flexItem />}
        >
          <Controller
            name="price"
            control={control}
            defaultValue=""
            rules={{ required: false }}
            render={({ field, fieldState }) => (
              <TextField
                label="Carrier Price"
                required
                type="number"
                error={hasFieldError(field)}
                helperText={fieldError(field)}
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Stack>

        <AutoSave
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          isValid={isValid}
          control={control}
        />
      </CardContent>
      <CardContent>
        {/* TODO Price Positions */}
        {orientation === 'vertical' && <Box sx={{ height: 80 }}></Box>}
      </CardContent>
      <CardContent>
        <CarrierPriceTotal price={+getValues('price')} />
      </CardContent>
    </Card>
  );
};
