import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ContactIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3C9.23858 3 7 5.23858 7 8C7 9.88174 8.03951 11.5207 9.5756 12.3739C6.34252 13.4009 4 16.4269 4 20V21H6V20C6 16.6863 8.68629 14 12 14C15.3137 14 18 16.6863 18 20V21H20V20C20 16.4269 17.6575 13.4009 14.4244 12.3739C15.9605 11.5207 17 9.88174 17 8C17 5.23858 14.7614 3 12 3ZM9 8C9 6.34315 10.3431 5 12 5C13.6569 5 15 6.34315 15 8C15 9.65685 13.6569 11 12 11C10.3431 11 9 9.65685 9 8Z"
        />
      </SvgIcon>
    );
  }
);

ContactIcon.displayName = 'ContactIcon';

export default ContactIcon;
