import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { MenuItem } from '@mui/material';

import { Box, Stack, IconButton, BasicSelect, Typography } from '@dizzbo/ui';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  SkipRightIcon,
  SkipLeftIcon,
} from '@dizzbo/ui';

function defaultLabelDisplayedRows({ from, to, count }) {
  return `${from} – ${to} // ${
    count !== -1 ? `${count} total` : `more than ${to}`
  }`;
}

function defaultGetAriaLabel(type) {
  return `Go to ${type} page`;
}

export const TablePagination: React.FC<any> = React.forwardRef(
  function TablePagination(props: any, ref) {
    const theme = useTheme();
    const {
      colSpan: colSpanProp,
      count,
      getItemAriaLabel = defaultGetAriaLabel,
      labelDisplayedRows = defaultLabelDisplayedRows,
      labelRowsPerPage = 'Rows per page:',

      onPageChange,
      onRowsPerPageChange,
      page,
      rowsPerPage,
      rowsPerPageOptions = [10, 25, 50, 100],
      showFirstButton,
      showLastButton,
    } = props;

    const getLabelDisplayedRowsTo = () => {
      if (count === -1) {
        return (page + 1) * rowsPerPage;
      }
      return rowsPerPage === -1
        ? count
        : Math.min(count, (page + 1) * rowsPerPage);
    };

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box
        sx={{
          flexGrow: 1,
          height: 56,
          background: 'transparent',
        }}
      >
        <Stack
          direction={'row'}
          alignItems="center"
          justifyContent="space-between"
          height="100%"
          paddingX={6}
        >
          <div>
            {rowsPerPageOptions.length > 1 && (
              <BasicSelect
                id="test"
                label={labelRowsPerPage}
                value={rowsPerPage}
                onChange={onRowsPerPageChange}
              >
                {rowsPerPageOptions.map((rowsPerPageOption) => (
                  <MenuItem
                    key={
                      rowsPerPageOption.label
                        ? rowsPerPageOption.label
                        : rowsPerPageOption
                    }
                    value={
                      rowsPerPageOption.value
                        ? rowsPerPageOption.value
                        : rowsPerPageOption
                    }
                  >
                    {rowsPerPageOption.label
                      ? rowsPerPageOption.label
                      : rowsPerPageOption}
                  </MenuItem>
                ))}
              </BasicSelect>
            )}
          </div>
          <Typography variant="label" color="primary.main">
            {labelDisplayedRows({
              from: count === 0 ? 0 : page * rowsPerPage + 1,
              to: getLabelDisplayedRowsTo(),
              count: count === -1 ? -1 : count,
              page,
            })}
          </Typography>
          <Stack direction={'row'} spacing={2}>
            {showFirstButton && (
              <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label={getItemAriaLabel('first', page)}
                title={getItemAriaLabel('first', page)}
                size="medium"
                variant="tertiary"
              >
                {theme.direction === 'rtl' ? (
                  <SkipRightIcon />
                ) : (
                  <SkipLeftIcon />
                )}
              </IconButton>
            )}
            <IconButton
              onClick={handleBackButtonClick}
              disabled={page === 0}
              color="inherit"
              aria-label={getItemAriaLabel('previous', page)}
              title={getItemAriaLabel('previous', page)}
              size="medium"
              variant="tertiary"
            >
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
            <IconButton
              onClick={handleNextButtonClick}
              disabled={
                count !== -1
                  ? page >= Math.ceil(count / rowsPerPage) - 1
                  : false
              }
              color="inherit"
              aria-label={getItemAriaLabel('next', page)}
              title={getItemAriaLabel('next', page)}
              size="medium"
              variant="tertiary"
            >
              {theme.direction === 'rtl' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
            {showLastButton && (
              <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label={getItemAriaLabel('last', page)}
                title={getItemAriaLabel('last', page)}
                size="medium"
                variant="tertiary"
              >
                {theme.direction === 'rtl' ? (
                  <SkipLeftIcon />
                ) : (
                  <SkipRightIcon />
                )}
              </IconButton>
            )}
          </Stack>
        </Stack>
      </Box>
    );
  }
);
