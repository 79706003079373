import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SettingsGearIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.8951 2H9.10499L8.80922 4.66194C8.25044 4.90521 7.72513 5.21025 7.24188 5.56827L4.78725 4.49282L1.89221 9.50718L4.05074 11.0951C4.01722 11.3924 4.00004 11.6944 4.00004 12C4.00004 12.3057 4.01722 12.6076 4.05074 12.9049L1.89228 14.4928L4.78733 19.5072L7.24191 18.4317C7.72515 18.7898 8.25045 19.0948 8.80922 19.3381L9.10499 22H14.8951L15.1909 19.3381C15.7496 19.0948 16.2749 18.7898 16.7582 18.4318L19.2127 19.5072L22.1078 14.4928L19.9493 12.905C19.9828 12.6076 20 12.3057 20 12C20 11.6944 19.9829 11.3924 19.9493 11.0951L22.1078 9.50718L19.2128 4.49282L16.7582 5.56827C16.2749 5.21025 15.7496 4.90521 15.1909 4.66194L14.8951 2ZM10.66 6.11533L10.8951 4H13.105L13.34 6.11533L13.9406 6.32047C14.6853 6.57483 15.3649 6.97208 15.9464 7.48033L16.4244 7.89806L18.3758 7.04308L19.4807 8.95692L17.7665 10.218L17.8882 10.84C17.9615 11.2145 18 11.6023 18 12C18 12.3977 17.9615 12.7855 17.8882 13.16L17.7665 13.782L19.4807 15.0431L18.3757 16.9569L16.4243 16.102L15.9464 16.5197C15.3649 17.0279 14.6853 17.4252 13.9406 17.6795L13.34 17.8847L13.105 20H10.8951L10.66 17.8847L10.0595 17.6795C9.3148 17.4252 8.63521 17.0279 8.05369 16.5197L7.57573 16.102L5.62434 16.9569L4.51938 15.0431L6.23361 13.782L6.11189 13.16C6.0386 12.7855 6.00004 12.3977 6.00004 12C6.00004 11.6023 6.0386 11.2146 6.11188 10.84L6.2336 10.218L4.51931 8.95692L5.62426 7.04308L7.57571 7.89807L8.05367 7.48034C8.6352 6.97208 9.3148 6.57483 10.0595 6.32047L10.66 6.11533Z"
        />
      </SvgIcon>
    );
  }
);

SettingsGearIcon.displayName = 'SettingsGearIcon';

export default SettingsGearIcon;
