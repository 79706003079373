import { ListItemButton as MuiListItemButton } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiListItemButtonProps = ComponentProps<typeof MuiListItemButton>;

export const ListItemButton = forwardRef<
  HTMLDivElement,
  MuiListItemButtonProps
>((props, ref) => <MuiListItemButton {...props} ref={ref} />);

ListItemButton.displayName = 'ListItemButton';
