import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TruckFTLIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M10 5H13V8H10V5Z" />
        <path d="M13 9H10V12H13V9Z" />
        <path d="M17 9H14V12H17V9Z" />
        <path d="M18 9H21V12H18V9Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23 3H8L8 7.5L2 7.5L2 8.5H2.84825L1 11.7344V19L5.03545 19C5.27807 20.6962 6.73677 22 8.5 22C10.2632 22 11.7219 20.6961 11.9646 19L14.0354 19C14.2781 20.6961 15.7368 22 17.5 22C19.2632 22 20.7219 20.6961 20.9646 19L23 19V3ZM22 13H9L9 4L22 4V13ZM4 13V14L22 14V17L20.6632 17C20.1014 15.8175 18.8962 15 17.5 15C16.1038 15 14.8985 15.8175 14.3368 17L11.6632 17C11.1015 15.8175 9.8962 15 8.5 15C7.1038 15 5.89854 15.8175 5.33681 17L3 17L3 12.2656L4.58032 9.5H8V13L4 13ZM16 18.5C16 17.6716 16.6716 17 17.5 17C18.3284 17 19 17.6716 19 18.5C19 19.3284 18.3284 20 17.5 20C16.6716 20 16 19.3284 16 18.5ZM8.5 20C9.32843 20 10 19.3284 10 18.5C10 17.6716 9.32843 17 8.5 17C7.67157 17 7 17.6716 7 18.5C7 19.3284 7.67157 20 8.5 20Z"
        />
        <path d="M17 5H14V8H17V5Z" />
        <path d="M18 5H21V8H18V5Z" />
      </SvgIcon>
    );
  }
);

TruckFTLIcon.displayName = 'TruckFTLIcon';

export default TruckFTLIcon;
