import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { ISODateTimeType } from '@types';
import dayjs from 'dayjs';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@dizzbo/ui';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  popupState: PopupState;
  file: string;
  tourReference?: string;
  createdAt?: ISODateTimeType;
  emailSentAt?: ISODateTimeType;
};

export const TransportContractPreviewDialog: React.FC<Props> = ({
  popupState,
  file,
  tourReference,
  createdAt,
  emailSentAt,
}: Props) => {
  const { t } = useTranslation();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <Dialog
        fullScreen
        PaperProps={{
          elevation: 6,
          variant: 'filled-primary',
        }}
        scroll="paper"
        {...bindDialog(popupState)}
        TransitionComponent={Transition}
      >
        <DialogTitle onClose={popupState.close}>
          <Stack>
            {tourReference}
            <Stack direction="row" spacing={4}>
              <Typography variant="bodyRegularSmall" color="primary">
                {t('createdAt')} {dayjs(createdAt).format('DD.MM.YYYY')}
              </Typography>
              {emailSentAt && (
                <Typography variant="bodyRegularSmall" color="primary">
                  {t('emailSentAt')} {dayjs(emailSentAt).format('DD.MM.YYYY')}
                </Typography>
              )}
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <Viewer
            fileUrl={file}
            defaultScale={1}
            plugins={[defaultLayoutPluginInstance]}
          />
        </DialogContent>
      </Dialog>
    </Worker>
  );
};
