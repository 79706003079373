import { useQueryClient } from '@tanstack/react-query';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React from 'react';

import {
  ErrorIcon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MoreVerticalIcon,
} from '@dizzbo/ui';
import { useTranslation } from 'react-i18next';

export const StopActions: React.FC<any> = ({
  stopUUID,
  orderUUID,
}: {
  stopUUID: string;
  orderUUID: string;
}) => {
  const queryClient = useQueryClient();

  // const { mutate: mutateDelivery }: any = useMutation(() =>
  //   deleteOrderStop(orderUUID, stopUUID)
  // );

  const handleDelete = (popupState) => {
    // mutateDelivery('', {
    //   onSuccess: () => {
    //     queryClient.invalidateQueries({
    //       queryKey: [QueryKeys.STOPS, orderUUID],
    //     });
    //   },
    // });
    // popupState.close();
  };

  const { t } = useTranslation();

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <IconButton
            size="medium"
            variant="tertiary"
            {...bindTrigger(popupState)}
          >
            <MoreVerticalIcon />
          </IconButton>
          <Menu {...bindMenu(popupState)}>
            <MenuItem onClick={() => handleDelete(popupState)}>
              <ListItemIcon>
                <ErrorIcon sx={{ width: 16, height: 16 }} />
              </ListItemIcon>
              <ListItemText>{t('deleteStop')}</ListItemText>
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
};
