import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TelematicsIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.2929 11.1924L23.6569 17.5564L19.4142 21.799L13.0503 15.435L14.4645 14.0208L13.7574 13.3137L13.0503 14.0208L10.2219 11.1924L10.929 10.4853L10.2219 9.77819L8.80765 11.1924L2.44369 4.82844L6.68633 0.585796L13.0503 6.94976L11.6361 8.36398L12.3432 9.07111L13.0503 8.36396L15.8788 11.1924L15.1716 11.8995L15.8787 12.6066L17.2929 11.1924ZM19.4142 18.9706L15.8787 15.435L15.8787 15.435L17.2929 14.0208L20.8284 17.5564L19.4142 18.9706ZM10.2219 6.94976L8.80765 8.36397L5.27212 4.82844L6.68633 3.41422L10.2219 6.94976L10.2219 6.94976ZM11.0001 17.5C8.51477 17.5 6.50005 15.4853 6.50005 13H5.50005C5.50005 16.0376 7.96249 18.5 11.0001 18.5V17.5ZM8.50005 13C8.50005 14.3807 9.61934 15.5 11.0001 15.5V16.5C9.06706 16.5 7.50005 14.933 7.50005 13H8.50005ZM11.0001 19.5C7.4102 19.5 4.50005 16.5898 4.50005 13H3.50005C3.50005 17.1421 6.85792 20.5 11.0001 20.5V19.5Z"
        />
      </SvgIcon>
    );
  }
);

TelematicsIcon.displayName = 'TelematicsIcon';

export default TelematicsIcon;
