import { alpha } from '@mui/material/styles';

const banner = (palette) => {
  return {
    bannerTodoItem: {
      default: {
        backgroundColor: palette.turquoise[80],
      },
      disabled: {
        backgroundColor: alpha(palette.oxford[15], 0.3),
      },
    },
  };
};

export default banner;
