import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DoubleArrowUpIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M16.9998 18.5705L11.9999 13.8123L7 18.5705L8.50218 20L11.9999 16.6714L15.4976 20L16.9998 18.5705Z" />
        <path d="M16.9998 9.75813L11.9999 5L7 9.75813L8.50218 11.1877L11.9999 7.85909L15.4976 11.1877L16.9998 9.75813Z" />
      </SvgIcon>
    );
  }
);

DoubleArrowUpIcon.displayName = 'DoubleArrowUpIcon';

export default DoubleArrowUpIcon;
