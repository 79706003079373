import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const UploadIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M11.9999 4L16.9998 8.75813L15.4976 10.1877L13 7.81083V18H11V7.81063L8.50218 10.1877L7 8.75813L11.9999 4Z" />
        <path d="M5 16H3V22H21V16H19V20H5V16Z" />
      </SvgIcon>
    );
  }
);

UploadIcon.displayName = 'UploadIcon';

export default UploadIcon;
