import React from 'react';
import { Tooltip, IconButton } from '@dizzbo/ui';
import { TableExpandIcon, TableCompressIcon } from '@dizzbo/ui';
import { useWorkspaces } from '@dizzbo/core/hooks';

type Props = {};

export const DensityButton: React.FC<Props> = ({}: Props) => {
  const { activeWorkspace, activeWorkspaceUUID, setWorkspaceSettings } =
    useWorkspaces();

  const density =
    activeWorkspace?.settings?.tableSettings?.density || 'comfortable';

  const handleToggleDensity = () => {
    const newDensity = density === 'comfortable' ? 'compact' : 'comfortable';
    setWorkspaceSettings(activeWorkspaceUUID, 'tableSettings', {
      density: newDensity,
    });
  };

  return (
    <Tooltip arrow title="Change Row Density">
      <IconButton variant="tertiary" onClick={handleToggleDensity}>
        {density === 'comfortable' ? (
          <TableCompressIcon />
        ) : (
          <TableExpandIcon />
        )}
      </IconButton>
    </Tooltip>
  );
};
