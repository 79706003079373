import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CalculatorIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M6 6H18V9H6V6Z" />
        <path d="M8 13V11H6V13H8Z" />
        <path d="M8 15V17H6V15H8Z" />
        <path d="M12 11H10V13H12V11Z" />
        <path d="M10 15H12V17H10V15Z" />
        <path d="M16 15C17.1046 15 18 14.1046 18 13C18 11.8954 17.1046 11 16 11C14.8954 11 14 11.8954 14 13C14 14.1046 14.8954 15 16 15Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 6C2 3.23858 4.23858 1 7 1H17C19.7614 1 22 3.23858 22 6V18C22 20.7614 19.7614 23 17 23H7C4.23858 23 2 20.7614 2 18V6ZM7 3C5.34315 3 4 4.34315 4 6V18C4 19.6569 5.34315 21 7 21H17C18.6569 21 20 19.6569 20 18V6C20 4.34315 18.6569 3 17 3H7Z"
        />
      </SvgIcon>
    );
  }
);

CalculatorIcon.displayName = 'CalculatorIcon';

export default CalculatorIcon;
