import React from 'react';

import { Alert, SuccessIcon, WarningIcon } from '@dizzbo/ui';
import { useTranslation } from 'react-i18next';

type Props = {
  hasVehicle?: boolean;
};

export const StatusMessage: React.FC<Props> = ({ hasVehicle }) => {
  const { t } = useTranslation();

  return hasVehicle ? (
    <Alert
      title={t('vehicleAssigned')}
      variant="standard"
      severity="success"
      icon={<SuccessIcon />}
    />
  ) : (
    <Alert
      title={t('noVehicleAssigned')}
      variant="standard"
      severity="warning"
      icon={<WarningIcon />}
    />
  );
};
