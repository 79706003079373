import { DateRange } from '@mui/x-date-pickers-pro';
import {
  ISODateTimeType,
  ISODateType,
  OrderDetailStopDateFormData,
  StopType,
} from '@types';
import { Dayjs } from 'dayjs';

export function parseOrderDetailStopDateFormData(
  formData: OrderDetailStopDateFormData
): Partial<StopType> {
  const { requestedDate, scheduledAt, arrivedAt, timeSlot } = formData;

  const requestedDateParsed: ISODateType | null =
    requestedDate && requestedDate.isValid()
      ? (requestedDate.format('YYYY-MM-DD') as ISODateType)
      : null;
  const scheduledAtParsed: ISODateTimeType | null =
    scheduledAt && scheduledAt.isValid()
      ? (scheduledAt.toISOString() as ISODateTimeType)
      : null;
  const arrivedAtParsed: ISODateTimeType | null =
    arrivedAt && arrivedAt.isValid()
      ? (arrivedAt.toISOString() as ISODateTimeType)
      : null;

  let timeSlotStartsAtDateAdjusted: Dayjs | null = null;
  let timeSlotEndsAtDateAdjusted: Dayjs | null = null;

  if (requestedDate && timeSlot) {
    timeSlotStartsAtDateAdjusted =
      timeSlot[0] && timeSlot[0].isValid()
        ? requestedDate
            .hour(timeSlot[0].hour())
            .minute(timeSlot[0].minute())
            .second(timeSlot[0].second())
        : null;

    timeSlotEndsAtDateAdjusted =
      timeSlot[1] &&
      timeSlot[1].isValid() &&
      (timeSlot[1].isSame(timeSlot[0]) || timeSlot[1].isAfter(timeSlot[0]))
        ? requestedDate
            .hour(timeSlot[1].hour())
            .minute(timeSlot[1].minute())
            .second(timeSlot[1].second())
        : timeSlotStartsAtDateAdjusted;
  }

  const timeSlotStartsAtParsed: ISODateTimeType | null =
    timeSlotStartsAtDateAdjusted
      ? (timeSlotStartsAtDateAdjusted.toISOString() as ISODateTimeType)
      : null;

  const timeSlotEndsAtParsed: ISODateTimeType | null =
    timeSlotEndsAtDateAdjusted
      ? (timeSlotEndsAtDateAdjusted.toISOString() as ISODateTimeType)
      : null;

  const parsedData: Partial<StopType> = {
    requestedDate: requestedDateParsed,
    scheduledAt: scheduledAtParsed,
    timeslotStartsAt: timeSlotStartsAtParsed,
    timeslotEndsAt: timeSlotEndsAtParsed,
    arrivedAt: arrivedAtParsed,
  };

  return parsedData;
}

export function parseTimeSlotDateRange(
  timeSlotDateRange: Array<Dayjs> | undefined
): DateRange<Dayjs | null> {
  const parsedTimeSlotDateRange: DateRange<Dayjs | null> = timeSlotDateRange
    ? [timeSlotDateRange[0] || null, timeSlotDateRange[1] || null]
    : [null, null];

  return parsedTimeSlotDateRange;
}
