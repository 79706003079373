import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { createOrderProofOfDelivery } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DocumentUploadField,
  Stack,
  UploadIcon,
} from '@dizzbo/ui';
import { OrderType } from '@types';
import { useTranslation } from 'react-i18next';

type Props = {
  orderData: OrderType;
  popupState: PopupState;
};

export const PoDUploadDialog: React.FC<Props> = ({
  orderData,
  popupState,
}: Props) => {
  const queryClient = useQueryClient();
  const { uuid: orderUUID } = orderData;

  const [progress, setProgress] = useState(null);

  const { mutate: mutateOrderDocument }: any = useMutation({
    mutationKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.ORDER_PODS],
    mutationFn: (values) =>
      createOrderProofOfDelivery(orderUUID, values, (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
      }),
  });

  const { control, watch } = useForm({
    mode: 'all',
    defaultValues: {
      file: null,
    },
  });

  const watchFileChange = watch('file');

  const { t } = useTranslation();

  useEffect(() => {
    if (watchFileChange) {
      mutateOrderDocument(
        {
          order: orderUUID,
          file: watchFileChange.file,
          title: watchFileChange.name,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: [QueryKeys.ORDERS],
            });
            popupState.close();
            toast.success(t('podUploaded', { fileName: watchFileChange.name }));
          },
          onError: () => {
            popupState.close();
            toast.error(t('podCouldNotBeUploaded'));
          },
        }
      );
    }
  }, [watchFileChange]);

  return (
    <Dialog
      PaperProps={{
        elevation: 6,
        variant: 'filled-primary',
        sx: {
          minWidth: 600,
        },
      }}
      scroll="paper"
      {...bindDialog(popupState)}
    >
      <DialogTitle onClose={popupState.close}>
        <UploadIcon />
        Upload Proof of Delivery
      </DialogTitle>
      <DialogContent dividers>
        <Stack spacing={6}>
          <Controller
            name="file"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <DocumentUploadField
                progress={progress}
                fileTypes={['PDF']}
                {...field}
              />
            )}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
