import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, Stack } from '@dizzbo/ui';

import { ConfirmOrderTodo } from './ConfirmOrderTodo';
import { ReviewLoadingTimeTodo } from './ReviewLoadingTimeTodo';
import { ReviewPoDTodo } from './ReviewPoDTodo';
import { UploadPoDTodo } from './UploadPoDTodo';
import { TransportContractTodo } from './TransportContractTodo';
import { ReviewUnloadingTimeTodo } from './ReviewUnloadingTimeTodo';

export const OrderTodos: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={6}>
      <Typography variant="h3">{t('myOrders')}</Typography>
      <ConfirmOrderTodo />
      <TransportContractTodo />
      <ReviewLoadingTimeTodo />
      <ReviewUnloadingTimeTodo />
      <ReviewPoDTodo />
      <UploadPoDTodo />
    </Stack>
  );
};
