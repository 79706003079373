import React from 'react';
import type { PaginationState, SortingState } from 'react-table';

import { useWorkspaces } from '@dizzbo/core/hooks';

import { OrderType } from '@types';
import { useGetOrders } from '../../queries/useGetOrders';
import { Table } from './Table';
import { defaultColumnOrder, defaultColumnVisibility } from './columns';

type Props = {};

export const OrderList: React.FC<Props> = () => {
  const { activeWorkspaceUUID, setWorkspaceSettings, activeWorkspace } =
    useWorkspaces();

  const { data, isPending, isStale, isFetching, isError } = useGetOrders();

  const handleChangePinning = (updatedColumnPinning: {
    left: (keyof OrderType)[];
    right: (keyof OrderType)[];
  }) => {
    setWorkspaceSettings(activeWorkspaceUUID, 'tableSettings', {
      columnPinning: updatedColumnPinning,
    });
  };

  const handleChangeColumnVisibility = (
    updatedVisibility: Record<string, boolean>
  ) => {
    setWorkspaceSettings(activeWorkspaceUUID, 'tableSettings', {
      columnVisibility: updatedVisibility,
    });
  };

  const handleChangeColumnOrder = (updatedColumnOrder: string[]) => {
    setWorkspaceSettings(activeWorkspaceUUID, 'tableSettings', {
      columnOrder: updatedColumnOrder,
    });
  };

  const handleChangePagination = (updatedPagination: PaginationState) => {
    setWorkspaceSettings(activeWorkspaceUUID, 'tableSettings', {
      pagination: updatedPagination,
    });
  };

  const handleChangeSorting = (updatedSorting: SortingState) => {
    const sorting = updatedSorting();
    if (sorting.length === 0) {
      setWorkspaceSettings(activeWorkspaceUUID, 'tableSettings', {
        sorting: [{ id: 'has_todos', desc: false }],
      });
    }
    setWorkspaceSettings(activeWorkspaceUUID, 'tableSettings', {
      sorting: sorting,
    });
  };

  return (
    <Table
      columnOrder={
        activeWorkspace?.settings?.tableSettings?.columnOrder ||
        defaultColumnOrder
      }
      setColumnOrder={handleChangeColumnOrder}
      columnVisibility={
        activeWorkspace?.settings?.tableSettings?.columnVisibility ||
        defaultColumnVisibility
      }
      setColumnVisibility={handleChangeColumnVisibility}
      data={data}
      density={
        activeWorkspace?.settings?.tableSettings?.density || 'comfortable'
      }
      isLoading={isPending}
      isStale={isStale}
      isFetching={isFetching}
      isError={isError}
      sorting={
        activeWorkspace?.settings?.tableSettings?.sorting || [
          {
            id: 'has_todos',
            desc: false,
          },
        ]
      }
      setSorting={handleChangeSorting}
      pagination={
        activeWorkspace?.settings?.tableSettings?.pagination || {
          pageSize: 20,
          pageIndex: 0,
        }
      }
      setPagination={handleChangePagination}
      columnPinning={activeWorkspace?.settings?.tableSettings?.columnPinning}
      setColumnPinning={handleChangePinning}
    />
  );
};
