import { useQuery, keepPreviousData } from '@tanstack/react-query';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import dayjs from 'dayjs';

import { getOrders } from '@core/api/orders';
import { QueryKeys } from '@core/config';
import { useWorkspaces } from '@dizzbo/core/hooks';

type Sorting = {
  id: string;
  desc: boolean;
};

const formatSortingString = (sorting: Sorting[]) => {
  for (let index = 0; index < sorting.length; index++) {
    let sortingPrefix = '';
    if (!sorting[index].desc) {
      sortingPrefix = '-';
    }
    return sortingPrefix + sorting[index].id;
  }
};

export const useGetOrders = () => {
  const { activeWorkspace } = useWorkspaces();
  const filterSettings = activeWorkspace?.settings?.filters;
  const pagination = activeWorkspace?.settings?.tableSettings?.pagination;

  const activeSorting = activeWorkspace?.settings?.tableSettings?.sorting;
  let sorting = [{ id: 'has_todos', desc: false }];
  if (activeSorting && activeSorting?.length > 0) {
    sorting = activeSorting;
  }

  const pageIndex = pagination?.pageIndex || 0;
  const pageSize = pagination?.pageSize || 20;

  // const pagination = useMemo(
  //   () => ({ pageIndex, pageSize }),
  //   [pageIndex, pageSize]
  // );

  const getOrdersWithParams = async () => {
    const params = {};
    params['offset'] = pageIndex * pageSize;
    params['limit'] = pageSize;

    // Sorting
    // ----------------------------------------------------------------------
    const sortingParams = formatSortingString(sorting);

    if (sortingParams) {
      params['ordering'] = sortingParams;
    }

    // Filters
    // ----------------------------------------------------------------------

    const filterSettingsCopy = { ...filterSettings };
    // Modify dateRange Filter if there is a dateFilterRangeType like "today",
    // "thisWeek", "thisMonth", "thisYear". Because we need to use the actual dates for it

    if (
      filterSettingsCopy['dateFilterRangeType'] &&
      filterSettingsCopy['dateRange']
    ) {
      const dateFilterRangeType = filterSettingsCopy['dateFilterRangeType'];
      const dateRangekeys = Object.keys(filterSettingsCopy['dateRange']);
      if (dateRangekeys && dateRangekeys.length === 2) {
        switch (dateFilterRangeType) {
          case 'today':
            filterSettingsCopy['dateRange'] = {
              [dateRangekeys[0]]: dayjs().format('YYYY-MM-DD'),
              [dateRangekeys[1]]: dayjs().format('YYYY-MM-DD'),
            };
            break;
          case 'tomorrow':
            filterSettingsCopy['dateRange'] = {
              [dateRangekeys[0]]: dayjs().add(1, 'day').format('YYYY-MM-DD'),
              [dateRangekeys[1]]: dayjs().add(1, 'day').format('YYYY-MM-DD'),
            };
            break;
          case 'thisWeek':
            filterSettingsCopy['dateRange'] = {
              [dateRangekeys[0]]: dayjs().startOf('week').format('YYYY-MM-DD'),
              [dateRangekeys[1]]: dayjs().endOf('week').format('YYYY-MM-DD'),
            };
            break;
          case 'thisMonth':
            filterSettingsCopy['dateRange'] = {
              [dateRangekeys[0]]: dayjs().startOf('month').format('YYYY-MM-DD'),
              [dateRangekeys[1]]: dayjs().endOf('month').format('YYYY-MM-DD'),
            };
            break;
          case 'thisYear':
            filterSettingsCopy['dateRange'] = {
              [dateRangekeys[0]]: dayjs().startOf('year').format('YYYY-MM-DD'),
              [dateRangekeys[1]]: dayjs().endOf('year').format('YYYY-MM-DD'),
            };
            break;
        }
      }
    }
    delete filterSettingsCopy['dateFilterRangeType'];

    const filterParams =
      filterSettingsCopy &&
      Object.entries(filterSettingsCopy).reduce<Record<string, string>>(
        (result, [filterKey, filterValue]) => {
          let newFilter = {};
          if (Array.isArray(filterValue)) {
            newFilter = {
              [filterKey]: filterValue.map((filter) => filter.value),
            };
          }
          if (isString(filterValue)) {
            newFilter = { [filterKey]: filterValue };
          }
          if (isPlainObject(filterValue)) {
            newFilter = filterValue;
          }

          return {
            ...result,
            ...newFilter,
          };
        },
        {}
      );

    const resData = await getOrders({
      ...params,
      ...filterParams,
    });
    return resData;
  };

  const sortingParams = formatSortingString(sorting);

  return useQuery({
    queryKey: [
      QueryKeys.ORDERS,
      filterSettings,
      pageIndex,
      pageSize,
      sortingParams,
    ],
    queryFn: getOrdersWithParams,
    placeholderData: keepPreviousData,
  });
};
