import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from '@core/config';
import { axiosAuthenticatedRequest } from '@dizzbo/core/api';
import { TourType, UUIDType } from '@types';

export const useGetTour = (tourUUID: UUIDType, enabled: boolean = true) => {
  const getTour = async (tourUUID: UUIDType): Promise<TourType> => {
    const res = await axiosAuthenticatedRequest.get(`/v2/tours/${tourUUID}/`);
    return res.data;
  };

  return useQuery({
    queryKey: [QueryKeys.TOURS, tourUUID],
    queryFn: () => getTour(tourUUID),
    enabled: enabled,
  });
};
