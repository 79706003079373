const ToggleButton = (theme) => {
  return {
    MuiToggleButton: {
      defaultProps: {},
      styleOverrides: {
        root: {
          ...theme.typography.buttonRegularSmall,
          paddingTop: 6,
          paddingRight: 12,
          paddingBottom: 6,
          paddingLeft: 6,
          margin: 0,
          borderRadius: 30,
          borderColor: theme.styles.toggleButton.standard.default.borderColor,
          color: theme.styles.toggleButton.standard.default.color,
          backgroundColor:
            theme.styles.toggleButton.standard.default.backgroundColor,
          textTransform: 'capitalize',
          transition: theme.transitions.create(['background-color', 'color']),
          '&.MuiToggleButtonGroup-grouped:first-of-type': {
            paddingLeft: 16,
          },
          '&.MuiToggleButtonGroup-grouped:last-of-type': {
            paddingRight: 16,
          },
          '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': { margin: 0 },
          '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': { margin: 0 },

          '&:hover': {
            borderColor: theme.styles.toggleButton.standard.hover.borderColor,
            color: theme.styles.toggleButton.standard.hover.color,
            backgroundColor:
              theme.styles.toggleButton.standard.hover.backgroundColor,
          },
          '&:active': {
            borderColor: theme.styles.toggleButton.standard.active.borderColor,
            color: theme.styles.toggleButton.standard.active.color,
            backgroundColor:
              theme.styles.toggleButton.standard.active.backgroundColor,
          },
          '&.Mui-selected': {
            borderColor: theme.styles.toggleButton.standard.active.borderColor,
            color: theme.styles.toggleButton.standard.active.color,
            backgroundColor:
              theme.styles.toggleButton.standard.active.backgroundColor,
            '&:hover': {
              backgroundColor:
                theme.styles.toggleButton.standard.hover.backgroundColor,
            },
          },
        },
      },
    },
  };
};

export default ToggleButton;
