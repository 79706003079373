import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const InfoIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <ellipse cx="12" cy="12" rx="10.5" ry="10.5" fill="#5B8FF5" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 9.5C12.6904 9.5 13.25 8.94036 13.25 8.25C13.25 7.55964 12.6904 7 12 7C11.3096 7 10.75 7.55964 10.75 8.25C10.75 8.94036 11.3096 9.5 12 9.5ZM13 17V11H11V17H13Z"
          fill="white"
        />
      </SvgIcon>
    );
  }
);

InfoIcon.displayName = 'InfoIcon';

export default InfoIcon;
