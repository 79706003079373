import { usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';

import { ConversationDialog } from '@dizzbo/core';
import {
  Button,
  Card,
  CardHeader,
  Chip,
  CommunicationIcon,
  HistoryIcon,
  TruckLTLIcon,
} from '@dizzbo/ui';

import { createOrderComment, getOrderComments } from '@core/api';
import { QueryKeys } from '@core/config';

import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useOrderDetail } from '../../../hooks';
import { HistoryDialog } from '../../History';

type Props = {};

export const OrderSummary: React.FC<Props> = () => {
  const { orderData } = useOrderDetail();
  const { uuid, reference } = orderData;
  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'orderConversationDialog',
  });

  const historyDialogPopupState = usePopupState({
    variant: 'dialog',
    popupId: 'orderHistoryDialog',
  });

  const fetchComments = (params) => {
    return getOrderComments(uuid, params);
  };

  const createComment = (data) => {
    return createOrderComment(uuid, data);
  };

  const { t } = useTranslation();

  return (
    <>
      <Card
        variant="filled-secondary"
        elevation={1}
        sx={{
          height: '100%',
          width: '100%',
        }}
      >
        <CardHeader
          avatar={
            <Chip
              label="FTL"
              size="medium"
              variant="oxford-40"
              icon={<TruckLTLIcon />}
            />
          }
          title={`Dizzbo Order ${reference}`}
          action={
            <Stack direction="row" spacing={2}>
              <Button
                aria-label="Communication"
                size="medium"
                variant="primary"
                startIcon={<CommunicationIcon />}
                onClick={() => popupState.open()}
              >
                {t('comments')}
              </Button>
              <Button
                aria-label="History"
                size="medium"
                variant="primary"
                startIcon={<HistoryIcon />}
                onClick={() => historyDialogPopupState.open()}
              >
                {t('history')}
              </Button>
            </Stack>
          }
          variant="small"
          sx={{ borderBottom: 0 }}
        />

        {/* <CardContent>
        <Stack
          spacing={6}
          divider={<Divider orientation="horizontal" flexItem />}
        ></Stack>
      </CardContent> */}
      </Card>
      <ConversationDialog
        popupState={popupState}
        query={{
          queryFn: fetchComments,
          queryKey: [QueryKeys.ORDERS, uuid, 'comments'],
        }}
        mutation={{
          mutationFn: createComment,
          mutationKey: [QueryKeys.ORDERS, uuid, 'comments'],
        }}
      />
      <HistoryDialog popupState={historyDialogPopupState} />
    </>
  );
};
