import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SearchEmptyIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M13.9497 15.364L10.9999 12.4142L8.0502 15.364L6.63599 13.9497L9.58574 11L6.63599 8.05024L8.0502 6.63603L11 9.58578L13.9497 6.63603L15.3639 8.05025L12.4142 11L15.3639 13.9497L13.9497 15.364Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 20C13.125 20 15.0781 19.2635 16.6177 18.0319L21.543 22.9571L22.9572 21.5429L18.0319 16.6176C19.2635 15.078 20 13.125 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20ZM11 18C14.866 18 18 14.866 18 11C18 7.13401 14.866 4 11 4C7.13401 4 4 7.13401 4 11C4 14.866 7.13401 18 11 18Z"
        />
      </SvgIcon>
    );
  }
);

SearchEmptyIcon.displayName = 'SearchEmptyIcon';

export default SearchEmptyIcon;
