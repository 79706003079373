import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ContactAddIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M17.661 9V6.33901L15 6.33901V4.66099L17.661 4.66099V2H19.339V4.66099H22V6.33901L19.339 6.33901V9H17.661Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.8125 8.8125C4.8125 6.22367 6.91117 4.125 9.5 4.125C12.0888 4.125 14.1875 6.22367 14.1875 8.8125C14.1875 10.5766 13.213 12.1132 11.7729 12.9131C14.8039 13.8758 17 16.7127 17 20.0625V21H15.125V20.0625C15.125 16.9559 12.6066 14.4375 9.5 14.4375C6.3934 14.4375 3.875 16.9559 3.875 20.0625V21H2V20.0625C2 16.7127 4.19611 13.8758 7.22713 12.9131C5.78704 12.1132 4.8125 10.5766 4.8125 8.8125ZM9.5 6C7.9467 6 6.6875 7.2592 6.6875 8.8125C6.6875 10.3658 7.9467 11.625 9.5 11.625C11.0533 11.625 12.3125 10.3658 12.3125 8.8125C12.3125 7.2592 11.0533 6 9.5 6Z"
        />
      </SvgIcon>
    );
  }
);

ContactAddIcon.displayName = 'ContactAddIcon';

export default ContactAddIcon;
