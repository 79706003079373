import PropTypes from 'prop-types';
import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { OrderType, TourType } from '@types';
import { useGetOrderDeliveries } from '../queries';
const TourDetailContext = createContext(null);

TourDetailContext.displayName = 'TourDetailContext';

type ProviderProps = PropsWithChildren<{
  tourData: TourType;
  ordersData: OrderType[];
}>;

const lockedFieldsByState = {
  DRAFT: {
    price: true,
  },
};

function TourDetailProvider({ children, tourData, ordersData }: ProviderProps) {
  const isFieldLocked = (fieldName: string): boolean => {
    return lockedFieldsByState[tourData.status]?.[fieldName];
  };
  const [deliveriesData, setDeliveryData] = useState([]);
  const deliveriesQueries = useGetOrderDeliveries(ordersData);
  const isFetching = deliveriesQueries.some((query) => query.isFetching);

  useEffect(() => {
    if (!isFetching) {
      const data = [];
      deliveriesQueries.map((query) => {
        data.push(...query.data);
      });
      setDeliveryData(data);
    }
  }, [isFetching]);

  const value = useMemo(
    () => ({
      tourData,
      ordersData,
      deliveriesData,
      isFieldLocked,
    }),
    [tourData, ordersData, deliveriesData]
  );

  return (
    <TourDetailContext.Provider value={value}>
      {children}
    </TourDetailContext.Provider>
  );
}

TourDetailProvider.propTypes = {
  children: PropTypes.node,
};

export { TourDetailContext, TourDetailProvider };
