import React from 'react';
import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';

import { Box, Stack, Skeleton } from '@dizzbo/ui';

import { Tab } from './Tab';
import { CreateTab } from './CreateTab';

import { useWorkspaces } from './hooks/useWorkspaces';
import { WorkspacesProvider } from './contexts';

type Props = {
  sx?: SxProps;
};

const BarStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  height: 64,
  flexGrow: 1,
  paddingRight: 24,
  paddingLeft: 24,
  paddingBottom: 0,
  paddingTop: 0,
  backgroundColor: '#ffffff',
  zIndex: theme.zIndex.appBar,
  boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.08)',
}));

const TabsContainerStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  marginBottom: 8,
  overflow: 'hidden',
}));

const TabNavigationWrapper: React.FC<Props> = ({ sx }) => {
  const { workspaces, isLoadingWorkspaces } = useWorkspaces();

  return (
    <BarStyles sx={sx}>
      <TabsContainerStyles>
        <Stack
          direction={'row'}
          alignItems='end'
          height='100%'
          width='100%'
          spacing={2}
        >
          {isLoadingWorkspaces ? (
            <>
              <Skeleton variant='rounded' sx={{ width: 200, height: 48 }} />
              <Skeleton variant='rounded' sx={{ width: 200, height: 48 }} />
              <Skeleton variant='rounded' sx={{ width: 200, height: 48 }} />
            </>
          ) : (
            workspaces.map((workspace, index) => (
              <Tab
                key={workspace.uuid}
                uuid={workspace.uuid}
                title={workspace.title}
              />
            ))
          )}
          <CreateTab />
        </Stack>
      </TabsContainerStyles>
    </BarStyles>
  );
};

type TabNavigationProps = {
  sx?: SxProps;
  workspaceNamespace: string;
  children: ReactNode;
};

export const TabNavigation: React.FC<TabNavigationProps> = ({
  sx,
  workspaceNamespace,
  children,
}) => {
  return (
    <WorkspacesProvider workspaceNamespace={workspaceNamespace}>
      <TabNavigationWrapper sx={sx} />
      {children}
    </WorkspacesProvider>
  );
};
