import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React from 'react';
import { toast } from 'react-toastify';

import { deleteOrderDocument } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  DocumentAvatar,
  ErrorIcon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MoreVerticalIcon,
  PinDownIcon,
  Stack,
  Typography,
} from '@dizzbo/ui';
import { DocumentsType, UUIDType } from '@types';
import { useTranslation } from 'react-i18next';

const ActionsStyles = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'status',
})<any>(({ theme, status }) => {
  return { marginLeft: 'auto !important' };
});

type Props = {
  orderUUID: UUIDType;
  documentData: DocumentsType;
};

export const DocumentItem: React.FC<Props> = ({ documentData, orderUUID }) => {
  const queryClient = useQueryClient();
  const {
    uuid: documentUUID,
    file,
    uploadedBy,
    createdAt,
    title,
  } = documentData;

  const { mutate: mutateOrderDocument }: any = useMutation({
    mutationKey: [
      QueryKeys.ORDERS,
      orderUUID,
      QueryKeys.ORDER_DOCUMENTS,
      documentUUID,
    ],
    mutationFn: () => deleteOrderDocument(orderUUID, documentUUID),
  });

  const handleDelete = (popupState) => {
    mutateOrderDocument('', {
      onSuccess: (documentData, values) => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.ORDER_DOCUMENTS],
        });
        toast.success(`Document deleted.`);
      },
      onError: (error, variables, context) => {
        toast.error(`Document could not be deleted.`);
      },
    });
    popupState.close();
  };

  const { t } = useTranslation();

  return (
    <Stack spacing={3} direction="row" alignItems="center">
      <Stack
        direction="row"
        spacing={3}
        alignItems="center"
        onClick={() => window.open(file, '_blank')}
        sx={{ cursor: 'pointer' }}
      >
        <DocumentAvatar file={file} />
        <Stack spacing={0}>
          <Typography variant="buttonRegularSmall" color="primary">
            {title}
          </Typography>
          <Typography variant="labelSmall" color="primary.light">
            {t('uploadedBy')} {uploadedBy.displayName},{' '}
            {dayjs(createdAt).format('DD.MM.YY h:mm')}
          </Typography>
        </Stack>
      </Stack>
      <ActionsStyles>
        <PopupState variant="popover" popupId="demo-popup-menu">
          {(popupState) => (
            <React.Fragment>
              <IconButton
                size="medium"
                variant="tertiary"
                {...bindTrigger(popupState)}
              >
                <MoreVerticalIcon />
              </IconButton>
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => handleDelete(popupState)}>
                  <ListItemIcon>
                    <ErrorIcon sx={{ width: 16, height: 16 }} />
                  </ListItemIcon>
                  <ListItemText>{t('deleteFile')} </ListItemText>
                </MenuItem>
                <MenuItem onClick={() => window.open(file, '_blank')}>
                  <ListItemIcon>
                    <PinDownIcon sx={{ width: 16, height: 16 }} />
                  </ListItemIcon>
                  <ListItemText>{t('downloadFile')}</ListItemText>
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      </ActionsStyles>
    </Stack>
  );
};
