import React from 'react';
import {
  WarningIcon,
  SuccessIcon,
  ErrorIcon,
  InfoIcon,
} from '../../components/icons';

const Alert = (theme) => {
  return {
    MuiAlert: {
      defaultProps: {
        variant: 'standard',
        iconMapping: {
          info: <InfoIcon />,
          success: <SuccessIcon />,
          warning: <WarningIcon />,
          error: <ErrorIcon />,
        },
      },
      styleOverrides: {
        root: {
          color: theme.styles.alert.default.color,
          paddingTop: theme.spacing(4),
          paddingRight: theme.spacing(6),
          paddingBottom: theme.spacing(4),
          paddingLeft: theme.spacing(6),
          background: 'transparent',
          borderWidth: '1px',
          borderStyle: 'solid',
          '.MuiAlertTitle-root': {
            ...theme.typography.message,
            margin: 0,
          },
          '&.MuiAlert-standard-smallError': {
            borderColor: theme.styles.alert.error.borderColor,
            '.MuiAlertTitle-root': {
              color: theme.styles.alert.error.color,
            },
          },
          '&.MuiAlert-standard-smallWarning': {
            borderColor: theme.styles.alert.warning.borderColor,
            '.MuiAlertTitle-root': {
              color: theme.styles.alert.warning.color,
            },
          },
          '&.MuiAlert-standard-smallInfo': {
            borderColor: theme.styles.alert.info.borderColor,
            '.MuiAlertTitle-root': {
              color: theme.styles.alert.info.color,
            },
          },
          '&.MuiAlert-standard-smallSuccess': {
            borderColor: theme.styles.alert.success.borderColor,
            '.MuiAlertTitle-root': {
              color: theme.styles.alert.success.color,
            },
          },
          '&.MuiAlert-standard-neutral': {
            borderColor: theme.styles.alert.neutral.borderColor,
            '.MuiAlertTitle-root': {
              color: theme.styles.alert.neutral.color,
            },
            svg: {
              fill: theme.styles.alert.neutral.color,
            },
          },
        },
        icon: {
          padding: 0,
          paddingLeft: 6,
          opacity: 1,
          '.MuiSvgIcon-root': {
            width: 20,
            height: 20,
          },
        },
        message: {
          paddingTop: 0,
          paddingBottom: 0,
          ...theme.typography.bodySmall,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
        },
        standardError: {
          borderColor: theme.styles.alert.error.borderColor,
          '.MuiAlertTitle-root': {
            color: theme.styles.alert.error.color,
          },
        },
        standardWarning: {
          borderColor: theme.styles.alert.warning.borderColor,
          '.MuiAlertTitle-root': {
            color: theme.styles.alert.warning.color,
          },
        },
        standardInfo: {
          borderColor: theme.styles.alert.info.borderColor,
          '.MuiAlertTitle-root': {
            color: theme.styles.alert.info.color,
          },
        },
        standardSuccess: {
          borderColor: theme.styles.alert.success.borderColor,
          '.MuiAlertTitle-root': {
            color: theme.styles.alert.success.color,
          },
        },
      },
    },
  };
};

declare module '@mui/material/Alert' {
  interface AlertPropsVariantOverrides {
    standard: true;
    'standard-small': true;
    'standard-neutral': true;
  }
  // interface AlertPropsColorOverrides {
  //   neutral: true;
  // }
}

export default Alert;
