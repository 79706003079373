const Switch = (theme) => {
  return {
    MuiSwitch: {
      defaultProps: {},
      styleOverrides: {
        root: {
          padding: 8,
          width: 46,
          height: 32,

          '& .MuiSwitch-track': {
            opacity: 1,
            borderWidth: 1,
            borderColor: theme.palette.grey[70],
            borderStyle: 'solid',
            borderRadius: 22 / 2,
            backgroundColor: theme.styles.switch.default.backgroundColor,
            transition: theme.transitions.create(['background-color'], {
              duration: 500,
            }),
            '&:before': {
              content: '""',
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              width: 16,
              height: 16,
              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="6" width="8" viewBox="0 0 8 6"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main)
              )}" d="M3.32634 5.98608L3.31307 5.99995L0 2.5363L1.0788 1.40847L3.32638 3.7582L6.9212 0L8 1.12783L3.33965 6L3.32634 5.98608Z"/></svg>')`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '1px center',
              left: 12,
            },
          },
          '& .MuiSwitch-thumb': {
            boxShadow: 'none',
            backgroundColor: theme.palette.grey[70],
            width: 12,
            height: 12,
            margin: 1,
          },
          '.MuiSwitch-switchBase': {
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(14px)',
              '.MuiSwitch-thumb': {
                backgroundColor: '#ffffff',
              },
            },
            '&.Mui-checked+.MuiSwitch-track': {
              '&:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="6" width="8" viewBox="0 0 8 6"><path fill="${encodeURIComponent(
                  'rgba(255,255,255,0.5'
                )}" d="M3.32634 5.98608L3.31307 5.99995L0 2.5363L1.0788 1.40847L3.32638 3.7582L6.9212 0L8 1.12783L3.33965 6L3.32634 5.98608Z"/></svg>')`,
              },
              backgroundColor: theme.styles.switch.active.backgroundColor,
              opacity: 1,
            },
          },
        },
      },
    },
  };
};

export default Switch;
