import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const OngoingIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M16.8856 7.21276L18.3805 5.88401C17.7131 5.12575 16.9109 4.48691 16.0069 4.00368C14.2726 3.07667 12.2705 2.77969 10.3417 3.16334C8.413 3.54699 6.67696 4.58753 5.42941 6.10766C4.50278 7.23676 3.88824 8.57856 3.63338 10H0.5L4.5 14L8.5 10H5.67544C5.90211 9.04433 6.34403 8.14582 6.97543 7.37645C7.92944 6.21399 9.257 5.41829 10.7319 5.12491C12.2068 4.83153 13.7378 5.05863 15.0641 5.76752C15.7583 6.13861 16.374 6.6297 16.8856 7.21276Z" />
        <path d="M6.99485 14.7872L5.50001 16.116C6.16742 16.8742 6.96956 17.5131 7.87362 17.9963C9.60794 18.9233 11.61 19.2203 13.5388 18.8367C15.4675 18.453 17.2035 17.4125 18.4511 15.8923C19.3777 14.7632 19.9923 13.4214 20.2471 12H23.3805L19.3805 8L15.3805 12H18.2051C17.9784 12.9557 17.5365 13.8542 16.9051 14.6235C15.9511 15.786 14.6235 16.5817 13.1486 16.8751C11.6737 17.1685 10.1427 16.9414 8.81642 16.2325C8.12216 15.8614 7.50651 15.3703 6.99485 14.7872Z" />
      </SvgIcon>
    );
  }
);

OngoingIcon.displayName = 'OngoingIcon';

export default OngoingIcon;
