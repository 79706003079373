import GlobalStyles from '@mui/material/GlobalStyles';
import React, { useEffect, useRef, useState } from 'react';

import { Box } from '@dizzbo/ui';

import { RouteType, VehiclePositionType } from '@types';
import {
  useRenderSections,
  useRenderStops,
  useRenderVehicles,
  useRenderWaypoints,
} from './hooks';
import { MapWrapper } from './MapWrapper';
import { getHereMapsPlatform } from './utils/hereMapsServices';

function validateMapType(s: string) {
  const pattern = /^[a-zA-Z]+\.[a-zA-Z]+\.[a-zA-Z]+$/;
  if (pattern.test(s)) {
    return s.split('.');
  } else {
    return false;
  }
}

const DEFAULT_MAP_TYPE = 'vector.normal.logistics';
const DEFAULT_MAP_OVERLAY_TYPE = 'vector.traffic.logistics';

type Props = {
  route?: RouteType;
  vehicles?: VehiclePositionType[];
  settings?: { zoom: number; lat: number; lng: number };
  hideWaypoints?: boolean;
  language?: string;
  mapType?: string;
  mapOverlayType?: string;
};

export const RoutingMapStatic: React.FC<Props> = ({
  route,
  vehicles = [],
  settings = { zoom: 10, lat: 51, lng: 10 },
  hideWaypoints = false,
  language = 'de',
  mapType = 'vector.normal.logistics',
  mapOverlayType = 'vector.traffic.logistics',
}: Props) => {
  const [mapLoaded, setMapLoaded] = useState<string | null>();
  const mapElementRef = useRef<HTMLElement | null>(null);
  const hereMapRef = useRef<H.Map>(null);

  const sections = route?.sections || [];
  const waypoints = [];
  const stops = sections.flatMap((section, index) => {
    waypoints.concat(section.waypoints);
    if (sections.length === index + 1) {
      return [section.origin, section.destination];
    }
    return section.origin;
  });

  useEffect(() => {
    const engineType = H.Map.EngineType['HARP'];
    const mapTypes = getHereMapsPlatform().createDefaultLayers({
      engineType: engineType,
      lg: language,
    });

    const mapTypeArray = validateMapType(mapType)
      ? validateMapType(mapType)
      : DEFAULT_MAP_TYPE.split('.');

    const hereMap = new H.Map(
      mapElementRef.current,
      // @ts-ignore
      mapTypes[mapTypeArray[0]][mapTypeArray[1]][mapTypeArray[2]],
      {
        center: { lat: settings.lat, lng: settings.lng },
        zoom: settings.zoom,
        pixelRatio:
          window.devicePixelRatio && window.devicePixelRatio > 1 ? 2 : 1,
        padding: { top: 50, left: 50, bottom: 50, right: 50 },
        engineType,
      }
    );

    if (mapOverlayType) {
      const mapOverlayTypeArray = validateMapType(mapOverlayType)
        ? validateMapType(mapType)
        : DEFAULT_MAP_OVERLAY_TYPE.split('.');

      hereMap.addLayer(
        mapTypes[mapOverlayTypeArray[0]][mapOverlayTypeArray[1]][
          mapOverlayTypeArray[2]
        ]
      );
    }

    hereMap.getEngine().addEventListener('render', (evt) => {
      if (hereMap.getEngine() === evt.target) {
        setMapLoaded('MapLoaded');
      }
    });

    hereMapRef.current = hereMap;

    return () => {
      if (hereMapRef.current) {
        hereMapRef.current = null;
      }
    };
  }, []);

  useRenderStops(hereMapRef, stops);
  useRenderSections(hereMapRef, sections);
  if (!hideWaypoints) {
    useRenderWaypoints(hereMapRef, waypoints);
  }
  useRenderVehicles(hereMapRef, vehicles);

  return (
    <Box sx={{ width: '100%', height: '100%' }} className={mapLoaded}>
      <GlobalStyles
        styles={(theme) => ({
          '.tsqd-parent-container': { display: 'none' },
        })}
      />
      <MapWrapper ref={mapElementRef} />
    </Box>
  );
};
