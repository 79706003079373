import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Chip, DocumentAvatar, ErrorIcon, Stack, Typography } from '@dizzbo/ui';

import { TransportContractType } from '@types';
import { TransportContractPreviewDialog } from './TransportContractPreviewDialog';

const ActionsStyles = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'status',
})<any>(({ theme, status }) => {
  return { marginLeft: 'auto !important' };
});

type Props = {
  transportContractData: TransportContractType;
};

export const TransportContractItem: React.FC<Props> = ({
  transportContractData,
}) => {
  const { t } = useTranslation();
  const { file, createdAt, emailSentAt, canceled } = transportContractData;

  const podPreviewDialogPopupState = usePopupState({
    variant: 'dialog',
    popupId: 'podReviewDialog',
  });

  return (
    <Stack spacing={3} direction="row" alignItems="center">
      <Stack
        direction="row"
        spacing={3}
        alignItems="center"
        onClick={() => podPreviewDialogPopupState.open()}
        sx={{ cursor: 'pointer' }}
      >
        <DocumentAvatar file={file} />
        <Stack spacing={0}>
          <Typography variant="buttonRegularSmall" color="primary">
            {transportContractData.createdAt}
          </Typography>

          <Stack direction="row" spacing={4}>
            <Typography variant="labelSmall" color="primary.light">
              {t('createdAt')} {dayjs(createdAt).format('DD.MM.YYYY hh:mm')}
            </Typography>
            {emailSentAt && (
              <Typography variant="labelSmall" color="primary.light">
                {t('emailSentAt')}{' '}
                {dayjs(emailSentAt).format('DD.MM.YYYY hh:mm')}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
      <ActionsStyles direction="row" spacing={4}>
        {canceled && (
          <Chip
            label="Canceled"
            size="large"
            variant="outlined"
            icon={<ErrorIcon />}
          />
        )}
      </ActionsStyles>
      {transportContractData && transportContractData.file && (
        <TransportContractPreviewDialog
          tourReference={transportContractData.createdAt}
          popupState={podPreviewDialogPopupState}
          file={transportContractData.file}
          createdAt={transportContractData.createdAt}
          emailSentAt={transportContractData.emailSentAt}
        />
      )}
    </Stack>
  );
};
