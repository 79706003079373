import { styled } from '@mui/material/styles';
import React from 'react';

import { ArrowRightIcon, Box, Stack, Typography } from '@dizzbo/ui';

import { getStatusColor } from '@dizzbo/core/utils/orderStatus';
import { OrderTodoType, OrderType, TourType } from '@types';
import { TodoItem } from './TodoItem';

const PhaseContainerStyles = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
});

const PhaseStatusContainerStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'statusColor',
})<{ statusColor: string }>(({ statusColor }) => ({
  position: 'relative',
  paddingLeft: 24,
  paddingTop: 28,
  width: '30%',
  '&::before': {
    content: "' '",
    display: 'block',
    position: 'absolute',

    height: '100%',
    width: 32,
    top: 0,
    left: 36,
    borderLeft: `4px solid ${statusColor}`,
  },
}));

const PhaseTitleContainerStyles = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const PhaseTitleIconStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'statusColor',
})<{ statusColor: string }>(({ statusColor }) => {
  return {
    display: 'flex',
    zIndex: 1,
    width: 28,
    height: 28,
    backgroundColor: statusColor,
    border: `2px solid #FFFFFF`,
    borderRadius: 32,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 8,
    color: '#ffffff',
  };
});

const PhaseTodosContainerStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'status',
})({
  width: '70%',
  paddingTop: 24,
  paddingRight: 24,
  paddingLeft: 24,
  paddingBottom: 24,
});

type Props = {
  label: string;
  todos?: OrderTodoType[];
  settings: object;
  orderData: OrderType;
  tourData: TourType;
};

export const PhaseItem: React.FC<Props> = ({
  label,
  todos,
  settings,
  orderData,
  tourData,
}) => {
  const { status } = orderData;
  const statusColor = getStatusColor(status);

  return (
    <PhaseContainerStyles>
      <PhaseStatusContainerStyles statusColor={statusColor}>
        <PhaseTitleContainerStyles>
          <PhaseTitleIconStyles statusColor={statusColor}>
            <ArrowRightIcon
              fontSize="small"
              sx={{ width: 16, height: 16 }}
              color="inherit"
            />
          </PhaseTitleIconStyles>
          <Typography
            variant="h4"
            color="primary"
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {label}
          </Typography>
        </PhaseTitleContainerStyles>
      </PhaseStatusContainerStyles>
      <PhaseTodosContainerStyles>
        <Stack spacing={6}>
          {todos.map((todo) => (
            <TodoItem
              key={todo}
              todoKey={todo}
              todo={settings[todo]}
              orderData={orderData}
              tourData={tourData}
            />
          ))}
        </Stack>
      </PhaseTodosContainerStyles>
    </PhaseContainerStyles>
  );
};
