import { List as MuiList } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiListProps = ComponentProps<typeof MuiList>;

export const List = forwardRef<HTMLUListElement, MuiListProps>((props, ref) => (
  <MuiList {...props} ref={ref} />
));

List.displayName = 'List';
