import { useQuery } from '@tanstack/react-query';

import { getOrderStop } from '@core/api';
import { QueryKeys } from '@core/config';
import { UUIDType } from '@types';

export const useGetOrderStop = (orderUUID: UUIDType, stopUUID: UUIDType) => {
  return useQuery({
    queryKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.STOPS, stopUUID],
    queryFn: () => getOrderStop(orderUUID, stopUUID),
  });
};
