import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { updateOrder } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  Stack,
} from '@dizzbo/ui';

import { OrderType, UserType, UUIDType } from '@types';
import { useTranslation } from 'react-i18next';
import { ParticipantsAutoCompleteTextField } from './ParticipantsAutoCompleteTextField';

type Props = {
  orderUUID: UUIDType;
  popupState: PopupState;
  assignees: UserType[];
};

export const EditAssigneesDialog: React.FC<Props> = ({
  orderUUID,
  popupState,
  assignees,
}: Props) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      // we are using a array of assignees because we expect this will change in future
      // to multiple assignees. Currently only one assignee is supported.
      // Thats why we are picking the first assignee on submit.
      assignees: assignees,
    },
  });
  const queryClient = useQueryClient();
  useEffect(() => {
    // reset the form if the dialog is not open.
    // Because this Dialog doesn't unmount.
    if (popupState.isClosed) {
      reset();
    }
  }, [popupState]);

  const { mutate: mutateOrder, isPending }: any = useMutation<
    OrderType,
    unknown,
    Partial<OrderType>
  >({
    mutationKey: [QueryKeys.ORDERS, orderUUID],
    mutationFn: (values) =>
      updateOrder({ orderUUID: orderUUID, orderData: values }),
  });

  const { t } = useTranslation();

  const onSubmit = (formData: any) => {
    const oldUserUUIDs = assignees.map((user?: UserType) => {
      return user?.uuid;
    });
    const newUserUUIDs = formData.assignees.map((user: UserType) => {
      return user.uuid;
    });
    const newAssignees = [...newUserUUIDs, ...oldUserUUIDs];

    mutateOrder(
      { assignee: newAssignees[0] },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.ORDERS, orderUUID],
          });
          toast.success(t('assigneeChanged'));
          popupState.close();
        },
        onError: () => {
          toast.error(t('couldNotChangeAssignee'));
          popupState.close();
        },
      }
    );
  };

  return (
    <>
      <Dialog
        PaperProps={{
          elevation: 6,
          variant: 'filled-primary',
          sx: {
            minWidth: 600,
          },
        }}
        scroll="paper"
        {...bindDialog(popupState)}
      >
        <DialogTitle onClose={popupState.close}>Change Assignee</DialogTitle>
        <DialogContent dividers>
          <form id="form" onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={6}>
              <Controller
                name="assignees"
                control={control}
                defaultValue={[]}
                rules={{ required: false }}
                render={({ field }) => (
                  <ParticipantsAutoCompleteTextField
                    label="Assignee"
                    {...field}
                  />
                )}
              />
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Stack spacing={3} direction="row">
            <Button variant="tertiary" onClick={() => popupState.close()}>
              {t('cancel')}
            </Button>
            <LoadingButton
              loading={isPending}
              type="submit"
              form="form"
              disabled={!isDirty || !isValid}
            >
              {t('apply')}
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};
