import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DocumentContractIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M9.5 11.5H7.14286L6 13.0556V17H7.26772C7.38903 16.1519 8.11838 15.5 9 15.5C9.88162 15.5 10.611 16.1519 10.7323 17H13.5177C13.639 16.1519 14.3684 15.5 15.25 15.5C16.1316 15.5 16.861 16.1519 16.9823 17H18V9.5H9.5V11.5Z" />
        <path d="M15.25 16C14.6453 16 14.1408 16.4294 14.025 17C14.0086 17.0808 14 17.1644 14 17.25C14 17.9404 14.5596 18.5 15.25 18.5C15.9404 18.5 16.5 17.9404 16.5 17.25C16.5 17.1644 16.4914 17.0808 16.475 17C16.3592 16.4294 15.8547 16 15.25 16Z" />
        <path d="M9 16C9.60474 16 10.1092 16.4294 10.225 17C10.2414 17.0808 10.25 17.1644 10.25 17.25C10.25 17.9404 9.69036 18.5 9 18.5C8.30964 18.5 7.75 17.9404 7.75 17.25C7.75 17.1644 7.75861 17.0808 7.775 17C7.89082 16.4294 8.39526 16 9 16Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.4142 0H2V24H22V6.58579L15.4142 0ZM4 22V2H14.5858L20 7.41421V22H4Z"
        />
      </SvgIcon>
    );
  }
);

DocumentContractIcon.displayName = 'DocumentContractIcon';

export default DocumentContractIcon;
