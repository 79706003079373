import { axiosRequest } from './axios';

type UserCredentialsType = {
  email: string;
  passwprd: string;
};

export const postUserCredentials = async (
  data: UserCredentialsType
): Promise<any> => {
  const res = await axiosRequest.post('/auth/token/', data);
  return res.data;
};

type OAuthInitType = {
  redirect_url: string;
};

export const getOAuthURL = async (params: OAuthInitType): Promise<any> => {
  const res = await axiosRequest.get('/auth/oauth/request/', {
    params,
  });
  return res.data;
};

type OAuthCredentialsType = {
  flowId: string;
  payload: { code: string; sessionState: string; state: string };
};

export const postOAuthCredentials = async (
  data: OAuthCredentialsType
): Promise<any> => {
  const res = await axiosRequest.post('/auth/oauth/authenticate/', data);
  return res.data;
};
