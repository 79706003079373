import { useMutation, useQueryClient } from '@tanstack/react-query';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React from 'react';
import { toast } from 'react-toastify';

import {
  ErrorIcon,
  IconButton,
  LicensePlate,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MoreVerticalIcon,
  Stack,
  TrailerIcon,
  TruckIcon,
} from '@dizzbo/ui';

import { updateTour } from '@core/api';
import { QueryKeys } from '@core/config';
import { TourType, UUIDType, VehicleType } from '@types';
import { useTranslation } from 'react-i18next';

type Props = {
  tourUUID: UUIDType;
  vehicle?: VehicleType;
};

export const VehicleDisplay: React.FC<Props> = ({ tourUUID, vehicle }) => {
  const queryClient = useQueryClient();

  const { mutate: mutateTour } = useMutation<
    TourType,
    unknown,
    Partial<TourType>
  >({
    mutationKey: [QueryKeys.TOURS, tourUUID],
    mutationFn: (values) =>
      updateTour({ tourUUID: tourUUID, tourData: values }),
  });

  const { t } = useTranslation();

  const handleDelete = (popupState) => {
    mutateTour(
      { vehicle: null },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.TOURS, tourUUID],
          });
          toast.success(t('vehicleRemoved'));
        },
        onError: () => {
          toast.error(t('vehicleCouldNotBeRemoved'));
        },
      }
    );
    popupState.close();
  };

  return (
    <Stack direction="row" spacing={6} alignItems="center">
      <Stack width="100%" direction="row" alignItems="center">
        <Stack direction="row" mr={2}>
          <TruckIcon sx={{ width: 24, height: 24 }} />
          <TrailerIcon sx={{ width: 24, height: 24 }} />
        </Stack>
        <LicensePlate number={vehicle.licensePlate} size="large" />
      </Stack>
      <PopupState variant="popover" popupId="carrier-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <IconButton
              size="medium"
              variant="tertiary"
              {...bindTrigger(popupState)}
            >
              <MoreVerticalIcon />
            </IconButton>
            <Menu {...bindMenu(popupState)}>
              <MenuItem onClick={() => handleDelete(popupState)}>
                <ListItemIcon>
                  <ErrorIcon sx={{ width: 16, height: 16 }} />
                </ListItemIcon>
                <ListItemText>{t('removeVehicle')}</ListItemText>
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    </Stack>
  );
};
