import { RouteSectionType } from '@types';
import { MutableRefObject, useEffect } from 'react';
import { getRouteSectionPolylines } from '../utils';

export const useRenderSections = (
  hereMapRef: MutableRefObject<H.Map> | undefined,
  sections: RouteSectionType[]
) => {
  // render routes

  useEffect(() => {
    if (hereMapRef.current) {
      const map = hereMapRef.current as H.map;

      const routeGroup = new H.map.Group();
      const routeSectionPolylines = sections.map((section) => {
        const polylines = section.legs.flatMap(({ polyline, mode }) => {
          if (!polyline) return [];
          const herePolyline = getRouteSectionPolylines(
            H.geo.LineString.fromFlexiblePolyline(polyline),
            section?.uuid,
            mode
          );
          return herePolyline;
        });

        return polylines;
      });

      routeGroup.addObjects(routeSectionPolylines.flat());

      function handleRouteSectionPointerEnter() {
        map.getViewPort().element.style.cursor = 'grab';
      }

      function handleRouteSectionPointerLeave() {
        map.getViewPort().element.style.cursor = 'auto';
      }

      routeGroup.addEventListener(
        'pointerenter',
        handleRouteSectionPointerEnter,
        true
      );

      routeGroup.addEventListener(
        'pointerleave',
        handleRouteSectionPointerLeave,
        true
      );

      map.addObject(routeGroup);

      return () => {
        if (!map) return;

        routeGroup.removeEventListener(
          'pointerenter',
          handleRouteSectionPointerEnter
        );

        routeGroup.removeEventListener(
          'pointerleave',
          handleRouteSectionPointerLeave
        );

        if (
          routeSectionPolylines.length &&
          map.getObjects().includes(routeGroup)
        ) {
          map.removeObject(routeGroup);
        }
      };
    }
  }, [hereMapRef.current, sections]);
};
