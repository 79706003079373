import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import onResize from 'simple-element-resize-detector';

import { useGetRouteSections } from '@core/hooks';

import {
  RouteType,
  VehiclePositionType,
  WaypointType,
  WaypointWithSectionType,
} from '@types';
import {
  useRenderSections,
  useRenderStops,
  useRenderVehicles,
  useRenderWaypoints,
  useWaypointListeners,
} from './hooks';
import { MapWrapper } from './MapWrapper';
import { getHereMapsPlatform } from './utils/hereMapsServices';

import {
  useCreateSectionWaypoint,
  useDeleteSectionWaypoint,
  useUpdateSectionWaypoint,
} from './hooks';

type Props = {
  route?: RouteType;
  vehicles?: VehiclePositionType[];
  settings?: { zoom: number; lat: number; lng: number };
  showOverview?: boolean;
  hideWaypoints?: boolean;
};

export const RoutingMap: React.FC<Props> = ({
  route,
  vehicles = [],
  settings = { zoom: 4, lat: 50, lng: 5 },
  showOverview = false,
  hideWaypoints = false,
}: Props) => {
  const { i18n } = useTranslation();

  const mapElementRef = useRef<HTMLElement | null>(null);
  const hereMapRef = useRef<H.Map>(null);
  const mapBehaviour = useRef<H.mapevents.Behavior>(null);

  const sections = route?.sections || [];

  const stops = sections.flatMap((section, index) => {
    if (sections.length === index + 1) {
      return [section.origin, section.destination];
    }
    return section.origin;
  });

  const [sectionsdata, setSectionsdata] = useState([]);
  const [waypoints, setWaypoint] = useState([]);

  const { data: sectionsData, isFetching } = useGetRouteSections(
    route?.uuid,
    sections
  );

  const engineType = H.Map.EngineType['HARP'];
  // https://www.here.com/docs/bundle/maps-api-for-javascript-api-reference/page/H.service.Platform.html#createDefaultLayers

  const maptypes = getHereMapsPlatform().createDefaultLayers({
    engineType: engineType,
    lg: i18n.language,
  });

  useEffect(() => {
    if (!isFetching) {
      const waypoints = [];
      const data = [];
      sectionsData.map((query) => {
        if (query?.waypoints) {
          for (const waypoint of query.waypoints) {
            waypoints.push({
              ...waypoint,
              sectionUUID: query.uuid,
            });
          }
        }
        data.push(query);
      });
      setWaypoint(waypoints);
      setSectionsdata(data);
    }
  }, [isFetching, sectionsData]);

  useEffect(() => {
    const hereMap = new H.Map(
      mapElementRef.current,
      // @ts-ignore
      maptypes.vector.normal.logistics,
      {
        center: { lat: settings.lat, lng: settings.lng },
        zoom: settings.zoom,
        pixelRatio:
          window.devicePixelRatio && window.devicePixelRatio > 1 ? 2 : 1,
        padding: { top: 50, left: 50, bottom: 50, right: 50 },
        engineType,
      }
    );

    hereMap.addLayer(maptypes.vector.traffic.logistics);
    // handle resize event
    onResize(mapElementRef.current, () => {
      hereMap.getViewPort().resize();
    });

    const behavior = new H.mapevents.Behavior(
      new H.mapevents.MapEvents(hereMap)
      // TODO: prevent the map from zooming while the user scroll on the page
      // a) this can be done by adding the following map event settings
      // but the maps itself is not scrollable anymore, which is a bug https://stackoverflow.com/questions/59954162/here-maps-for-javascript-disabling-wheel-zoom-doesnt-make-page-scrollable
      // b) currently we enable all mapevents again after a waypoint is set, we must ensure that only the given events are enabled again
      // {
      //   enabled:
      //     H.mapevents.Behavior.DRAGGING | H.mapevents.Behavior.DBLTAPZOOM,
      // }
    );

    const ui = H.ui.UI.createDefault(hereMap, maptypes, 'en-US');

    hereMapRef.current = hereMap;
    mapBehaviour.current = behavior;

    if (showOverview) {
      const overviewMap = new H.ui.Overview(maptypes.vector.normal.truck, {
        // @ts-ignore
        alignment: H.ui.LayoutAlignment.LEFT_BOTTOM,
        zoomDelta: 6,
        scaleX: 5,
        scaleY: 6,
      });
      // Add the control to the map
      ui.addControl('overview', overviewMap);
    }

    return () => {
      if (hereMapRef.current) {
        hereMapRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (!hereMapRef.current) return;

    hereMapRef.current.getViewPort().element.style.cursor = isFetching
      ? 'progress'
      : 'auto';
  }, [isFetching, hereMapRef.current]);

  /* Update  Waypoint --------------------------------------------------------*/

  const { mutateAsync: mutateUpdateAsync } = useUpdateSectionWaypoint(
    route?.uuid
  );

  const debouncedCalculateRoute = useRef(
    debounce((data) => mutateUpdateAsync(data), 500)
  ).current;

  const handleDragMove = useCallback(
    async (waypoint: WaypointType) => {
      debouncedCalculateRoute.cancel();
      await debouncedCalculateRoute(waypoint);
    },
    [debouncedCalculateRoute]
  );

  const handleDragEnd = useCallback(
    async (waypoint: WaypointWithSectionType) => {
      debouncedCalculateRoute.cancel();
      await debouncedCalculateRoute(waypoint);
      debouncedCalculateRoute.flush();
    },
    [debouncedCalculateRoute]
  );

  /* Delete  Waypoint --------------------------------------------------------*/

  const { mutateAsync: mutateDeleteAsync } = useDeleteSectionWaypoint(
    route?.uuid
  );

  const handleRemoveWaypoint = useCallback(
    async (waypoint: WaypointWithSectionType) => {
      debouncedCalculateRoute.cancel();
      mutateDeleteAsync({
        uuid: waypoint.uuid,
        sectionUUID: waypoint.sectionUUID,
      });
    },
    [debouncedCalculateRoute]
  );

  /* Create  Waypoint --------------------------------------------------------*/

  const { mutateAsync: mutateCreateAsync } = useCreateSectionWaypoint(
    route?.uuid
  );

  const handlePointerUp = useCallback(
    async (waypoint: WaypointWithSectionType) => {
      debouncedCalculateRoute.cancel();
      mutateCreateAsync(waypoint);
    },
    [debouncedCalculateRoute]
  );

  useWaypointListeners(
    hereMapRef,
    mapBehaviour,
    handleDragMove,
    handleDragEnd,
    handlePointerUp,
    handleRemoveWaypoint
  );

  useRenderStops(hereMapRef, stops);
  useRenderSections(hereMapRef, sectionsdata);
  if (!hideWaypoints) {
    useRenderWaypoints(hereMapRef, waypoints);
  }
  useRenderVehicles(hereMapRef, vehicles);

  return <MapWrapper ref={mapElementRef} />;
};
