import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { styled } from '@mui/material/styles';
import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';

import { Box, Skeleton, Stack } from '@dizzbo/ui';

import { LoadingPointAutocomplete } from '@core/components';
import { QueryKeys } from '@core/config';
import { useGetOrderStop, useGetRoute, useUpdateOrderStop } from '@core/hooks';

import { LoadingPointType, UUIDType } from '@types';
import { useOrderDetail } from '../../hooks';
import { LoadingPointBullet } from './LoadingPointBullet';
import { StopActions } from './StopActions';
import { StopContent } from './StopContent';
import { StopSectionSummary } from './StopSectionSummary';

const StopContainerStyles = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isHovering',
})<any>(({ theme, isHovering }) => {
  return {
    height: '100%',
    backgroundColor: theme.styles.paper.filledSecondary.default.backgroundColor,
    '&:hover': {
      backgroundColor: theme.styles.planner.stopListItem.hover.backgroundColor,
    },
  };
});

const BulletContainerStyles = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'lastItem',
})<any>(({ theme, lastItem }) => {
  if (lastItem) {
    return {
      height: '100%',
      position: 'relative',
    };
  }
  return {
    height: '100%',
    position: 'relative',
    '&::after': {
      content: "' '",
      position: 'absolute',
      top: 60,
      bottom: 0,
      left: '50%',
      border: '1px dashed #404E64',
    },
  };
});

type Props = {
  stopUUID: UUIDType;
  index: number;
  dragHandleAttributes: DraggableAttributes;
  dragHandleListeners: SyntheticListenerMap | undefined;
  style?: any;
  lastItem: boolean;
};

export const StopListItem = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      stopUUID,
      index,
      dragHandleAttributes,
      dragHandleListeners,
      style,
      lastItem = false,
    },
    ref
  ) => {
    const queryClient = useQueryClient();
    const { orderUUID, routeUUID } = useOrderDetail();
    const [isHovering, setIsHovering] = useState(false);

    const { data } = useGetRoute(routeUUID);
    const { mutateAsync } = useUpdateOrderStop(orderUUID, stopUUID);
    const { data: stopData, isPending } = useGetOrderStop(orderUUID, stopUUID);

    const displaySectionSummary = data?.sections.length >= index + 1;

    const handleAutocompleteOnChange = async (
      loadingPoint: LoadingPointType
    ) => {
      if (loadingPoint) {
        try {
          await mutateAsync({ loadingPoint: loadingPoint.uuid });
          queryClient.invalidateQueries({
            queryKey: [QueryKeys.ROUTES, routeUUID],
          });
        } catch (error) {
          console.error('error', error);
        }
      }
    };

    return (
      <Box sx={{ backgroundColor: '#FFFFFF', ...style }} ref={ref}>
        <StopContainerStyles
          isHovering={isHovering}
          onMouseEnter={() => {
            setIsHovering(true);
          }}
          onMouseLeave={() => {
            setIsHovering(false);
          }}
          direction="row"
          py={3}
          px={6}
        >
          <BulletContainerStyles lastItem={lastItem}>
            <LoadingPointBullet
              index={index}
              isAddStopItem={false}
              actions={stopData?.actions}
              dragHandleAttributes={dragHandleAttributes}
              dragHandleListeners={dragHandleListeners}
            />
          </BulletContainerStyles>
          <Stack
            spacing={3}
            direction="column"
            ml={6}
            sx={{
              display: 'inline',
              width: '100%',
            }}
          >
            <Stack
              spacing={0}
              direction="row"
              alignItems="center"
              sx={{
                width: '100%',
              }}
            >
              {!isPending ? (
                <LoadingPointAutocomplete
                  label="Stop"
                  onChange={handleAutocompleteOnChange}
                  value={stopData.loadingPoint}
                  disableClearable={false}
                  sx={{ width: '100%' }}
                />
              ) : (
                <>
                  <Skeleton width="100%" height={40} />
                </>
              )}
              <Box ml={2}>
                <StopActions stopUUID={stopUUID} />
              </Box>
            </Stack>

            <Box>
              <StopContent
                stopData={stopData}
                stopUUID={stopUUID}
                isLoading={isPending}
              />
            </Box>
            {displaySectionSummary && stopData?.originSection && (
              <StopSectionSummary sectionUUID={stopData.originSection} />
            )}
          </Stack>
        </StopContainerStyles>
      </Box>
    );
  }
);

StopListItem.displayName = 'StopListItem';

export default StopListItem;
