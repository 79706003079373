import { styled, useTheme } from '@mui/material/styles';
import React, { FC } from 'react';
import { ToastContainer as ToastifyToastContainer } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';

import { MinimalIconButton } from '../MinimalIconButton';
import { CloseIcon } from '../icons';

const StyledToastContainer = styled(ToastifyToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&&.Toastify__toast-container {
    width: 100%;
    max-width: 450px;
  }
  .Toastify__toast {
    border-radius: 8px;
    box-shadow: 0px 8px 48px rgba(0, 0, 0, 0.35);
    color: #ffffff;
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: ${(props) => props.theme.typography.message.fontSize}px;
    font-weight: ${(props) => props.theme.typography.message.fontWeight};
    padding: 10px 24px;
    // min-height: 32px;
  }
  .Toastify__toast-body {
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: ${(props) => props.theme.typography.message.fontSize}px;
    font-weight: ${(props) => props.theme.typography.message.fontWeight};
    padding: 0;
    // min-height: 32px;
  }
  .Toastify__toast--default {
    color: ${(props) => props.theme.palette.primary.main};
    background-color: ${(props) => props.theme.styles.common.white};
    svg {
      fill: ${(props) => props.theme.palette.primary.main};
    }
  }
  .Toastify__toast--success {
    background-color: ${(props) => props.theme.styles.common.success.main};
  }
  .Toastify__toast--info {
    background-color: ${(props) => props.theme.styles.common.info.main};
  }
  .Toastify__toast--warning {
    background-color: ${(props) => props.theme.styles.common.warning.main};
  }
  .Toastify__toast--error {
    background-color: ${(props) => props.theme.styles.common.error.main};
  }

  .Toastify__progress-bar {
    background: rgba(0, 0, 0, 0.35);
  }
  .Toastify__progress-bar--rtl {
  }
  .Toastify__progress-bar-theme--light {
  }
  .Toastify__progress-bar-theme--dark {
  }
  .Toastify__progress-bar--info {
  }
  .Toastify__progress-bar--success {
  }
  .Toastify__progress-bar--warning {
  }
  .Toastify__progress-bar--error {
  }
  .Toastify__close-button {
    align-self: center;
    color: #ffffff;
    opacity: 1;
  }
  .Toastify__toast-icon {
    width: 18px;
    height: 18px;
    color: #ffffff;
    svg {
      fill: #ffffff;
    }
  }
  .Toastify__spinner {
    width: 18px;
    height: 18px;
    border-right-color: ${(props) => props.theme.palette.primary.main};
  }
`;

const CloseButton: FC<any> = ({ closeToast }) => (
  <MinimalIconButton
    onClick={closeToast}
    variant="primary-contrast"
    aria-label="close"
  >
    <CloseIcon />
  </MinimalIconButton>
);

export const ToastContainer: FC<any> = (props: any) => {
  injectStyle();
  const theme = useTheme();
  return (
    <StyledToastContainer {...props} theme={theme} closeButton={CloseButton} />
  );
};
