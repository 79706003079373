import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SupportIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M6 11C6 7.60283 6.73433 5.95947 7.66896 5.11829C8.62096 4.2615 10.0302 4 12 4C13.9698 4 15.379 4.2615 16.331 5.11829C17.2657 5.95947 18 7.60283 18 11C18 13.0307 17.4033 15.0728 16.3397 16.5804C15.7221 17.4558 14.9603 18.1376 14.0682 18.5512C13.53 18.2097 12.8017 18 12 18C10.3431 18 9 18.8954 9 20C9 21.1046 10.3431 22 12 22C13.4805 22 14.7105 21.285 14.9556 20.3448C16.1785 19.7654 17.1924 18.8412 17.974 17.7333C18.7461 16.6389 19.3039 15.3528 19.6364 14H21C21.5523 14 22 13.5523 22 13V9C22 8.44772 21.5523 8 21 8H19.7783C19.4485 6.02726 18.7491 4.60384 17.669 3.63171C16.121 2.2385 14.0302 2 12 2C9.9698 2 7.87904 2.2385 6.33104 3.63171C5.25089 4.60384 4.55151 6.02726 4.22172 8H3C2.44772 8 2 8.44772 2 9V13C2 13.5523 2.44772 14 3 14H6V11Z" />
      </SvgIcon>
    );
  }
);

SupportIcon.displayName = 'SupportIcon';

export default SupportIcon;
