import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CheckIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.9142 5.20712L7.20711 19.9142L2 14.7071L3.41421 13.2929L7.20711 17.0858L20.5 3.79291L21.9142 5.20712Z"
        />
      </SvgIcon>
    );
  }
);

CheckIcon.displayName = 'CheckIcon';

export default CheckIcon;
