import { SingleInputTimeRangeField as MuiSingleInputTimeRangeField } from '@mui/x-date-pickers-pro';
import React, { ComponentProps, forwardRef } from 'react';

type SingleInputTimeRangeFieldProps = ComponentProps<
  typeof MuiSingleInputTimeRangeField
>;

export const SingleInputTimeRangeField = forwardRef<
  HTMLDivElement,
  SingleInputTimeRangeFieldProps
>((props, ref) => <MuiSingleInputTimeRangeField {...props} ref={ref} />);

SingleInputTimeRangeField.displayName = 'SingleInputTimeRangeField';
