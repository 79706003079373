import React, { useState } from 'react';
import { styled } from '@mui/material/styles';

import { Box, IconBullet } from '@dizzbo/ui';
import { DragBarsIcon, LocationIcon } from '@dizzbo/ui';

const IndexIndicatorStyle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<any>(({ theme, disabled }) => {
  return {
    cursor: disabled ? 'auto' : 'grab',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alingItems: 'center',
  };
});

type Props = {
  index: number;
  // TODO: can this be deleted?
  isDragging?: boolean;
  dragHandleAttributes;
  dragHandleListeners;
};

export const LoadingPointBullet: React.FC<Props> = ({
  index,
  isDragging,
  dragHandleAttributes,
  dragHandleListeners,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const renderIndexContent = () => {
    if (isHovering) {
      return <DragBarsIcon />;
    } else {
      return (
        <Box
          sx={{
            width: 20,
            height: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {index + 1}
        </Box>
      );
    }
  };

  return (
    <IndexIndicatorStyle
      {...dragHandleAttributes}
      {...dragHandleListeners}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <IconBullet size="large" variant="secondary">
        {renderIndexContent()}
        <LocationIcon />
      </IconBullet>
    </IndexIndicatorStyle>
  );
};

export default LoadingPointBullet;
