import { styled } from '@mui/material/styles';
import React, { FC } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { View } from '@dizzbo/core/views';
import {
  Box,
  Button,
  MinusIcon,
  PageHeader,
  PlusIcon,
  SchedulerIcon,
  Slider,
  Stack,
} from '@dizzbo/ui';

import { useTranslation } from 'react-i18next';
import { OrderList } from '../components/OrderList';
import { Scheduler } from '../components/Scheduler';
import { SchedulerSettingsProvider } from '../contexts';
import { useSchedulerSettings } from '../hooks/useSchedulerSettings';

const ContainerStyles = styled(Box)(() => ({
  height: '100%',
  position: 'relative',
  overflowX: 'hidden',
  overflowY: 'hidden',
  display: 'flex',
  flexDirection: 'row',
}));

const ContentWrapperStyles = styled(Box)(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'hidden',
  overflowY: 'hidden',
}));

const OrderWrapperStyles = styled(Box)(({ theme }) => ({
  width: 300,
  minWidth: 300,
  boxShadow: theme.shadows[2],
  zIndex: theme.zIndex.appBar,
}));

export const SchedulerView: FC = () => {
  const { tickWidth, setTickWidth, setDateToday } = useSchedulerSettings();
  const { t } = useTranslation();

  return (
    <SchedulerSettingsProvider>
      <View title="Scheduler" sx={{ height: '100%' }}>
        <PageHeader title="Scheduler" icon={<SchedulerIcon />}>
          <Stack spacing={4} direction="row" alignItems="center">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button variant="tertiary" size="medium" onClick={setDateToday}>
                {t('today')}
              </Button>
            </Box>

            <Stack
              spacing={4}
              direction="row"
              alignItems="center"
              sx={{ minWidth: 200 }}
            >
              <MinusIcon />
              <Slider
                defaultValue={tickWidth}
                value={tickWidth}
                min={50}
                max={400}
                step={10}
                onChange={setTickWidth}
              />
              <PlusIcon />
            </Stack>
          </Stack>
        </PageHeader>

        <ContainerStyles>
          <DndProvider backend={HTML5Backend}>
            <ContentWrapperStyles>
              <Scheduler />
            </ContentWrapperStyles>
            <OrderWrapperStyles>
              <OrderList />
            </OrderWrapperStyles>
          </DndProvider>
        </ContainerStyles>
      </View>
    </SchedulerSettingsProvider>
  );
};
