import { styled } from '@mui/material/styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { toast } from 'react-toastify';

import {
  getTransportContract,
  recreateTransportContract,
  sendTransportContract,
} from '@core/api';
import { QueryKeys } from '@core/config';
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  ListItemIcon,
  ListItemText,
  LoadingButton,
  LockedIcon,
  Menu,
  MenuItem,
  MoreVerticalIcon,
  Paper,
  SendIcon,
  Stack,
  ViewIcon,
} from '@dizzbo/ui';

import { TransportContractPreviewDialog } from './TransportContractPreviewDialog';

import { UUIDType } from '@types';
import { useTranslation } from 'react-i18next';
import HeroBackgroundImage from '../../../../../assets/images/transport-contract-thumbnail.png';

const PaperStyles = styled(Paper)(() => ({
  width: '100%',
  height: '100%',
  minHeight: 320,
  backgroundImage: `url(${HeroBackgroundImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'center',
  backgroundPositionY: 'center',
  justifyContent: 'center',
  alignItems: 'flex-end',
  display: 'flex',
  padding: 24,
}));

type Props = {
  tourUUID: UUIDType;
  tourReference: string;
  transportContractUUID?: UUIDType;
};

export const TransportContract: React.FC<Props> = ({
  tourUUID,
  tourReference,
  transportContractUUID,
}) => {
  const queryClient = useQueryClient();

  const podPreviewDialogPopupState = usePopupState({
    variant: 'dialog',
    popupId: 'podReviewDialog',
  });

  const menuPopupState = usePopupState({
    variant: 'popover',
    popupId: 'transportContractMenu',
  });

  const { data: transportContractData, isPending } = useQuery({
    queryKey: [
      QueryKeys.TOURS,
      tourUUID,
      QueryKeys.TRANSPORT_CONTRACTS,
      transportContractUUID,
    ],
    queryFn: () => getTransportContract(tourUUID, transportContractUUID),
    enabled: !!transportContractUUID,
  });

  const { mutateAsync: mutateSendTransportContract }: any = useMutation({
    mutationKey: [
      QueryKeys.TOURS,
      tourUUID,
      QueryKeys.TRANSPORT_CONTRACTS,
      transportContractUUID,
    ],
    mutationFn: (values) =>
      sendTransportContract(tourUUID, transportContractUUID),
  });

  const { mutateAsync: mutateRecreateTransportContract }: any = useMutation({
    mutationKey: [
      QueryKeys.TOURS,
      tourUUID,
      QueryKeys.TRANSPORT_CONTRACTS,
      transportContractUUID,
    ],
    mutationFn: (values) =>
      recreateTransportContract(tourUUID, transportContractUUID),
  });

  const { t } = useTranslation();

  const handleResendTransportContract = async () => {
    const id = toast.loading('Sending Transport Contract...');
    try {
      await mutateSendTransportContract();
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TOURS, tourUUID],
      });
      menuPopupState.close();
      toast.update(id, {
        autoClose: 6000,
        render: t('transportContractSent'),
        type: 'success',
        isLoading: false,
      });
    } catch {
      toast.update(id, {
        autoClose: 6000,
        render: t('transportContractCouldNotBeSent'),
        type: 'error',
        isLoading: false,
      });
    }
  };

  const handleRecreateTransportContract = async () => {
    const id = toast.loading('Recreating Transport Contract...');
    try {
      await mutateRecreateTransportContract();
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TOURS, tourUUID],
      });
      menuPopupState.close();
      toast.update(id, {
        autoClose: 6000,
        render: t('transportContractRecreated'),
        type: 'success',
        isLoading: false,
      });
    } catch {
      toast.update(id, {
        autoClose: 6000,
        render: t('transportContractCouldNotBeRecreated'),
        type: 'error',
        isLoading: false,
      });
    }
  };

  return transportContractData ? (
    <Card
      variant="filled-secondary"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <CardHeader title="Transport Contract" variant="small" />
      <CardContent sx={{ padding: '0!important' }}>
        <PaperStyles variant="filled-secondary" square>
          <Stack direction="row" spacing={3}>
            <LoadingButton
              loading={isPending && podPreviewDialogPopupState.isOpen}
              startIcon={<ViewIcon />}
              size="medium"
              variant="primary"
              onClick={() => podPreviewDialogPopupState.open()}
            >
              {t('view')}
            </LoadingButton>
            {transportContractData && transportContractData.file && (
              <TransportContractPreviewDialog
                tourReference={tourReference}
                popupState={podPreviewDialogPopupState}
                file={transportContractData.file}
                createdAt={transportContractData.createdAt}
                emailSentAt={transportContractData.emailSentAt}
              />
            )}
            {transportContractUUID && (
              <IconButton
                size="medium"
                variant="primary"
                {...bindTrigger(menuPopupState)}
              >
                <MoreVerticalIcon />
              </IconButton>
            )}
          </Stack>
          <Menu {...bindMenu(menuPopupState)}>
            <MenuItem onClick={() => handleResendTransportContract()}>
              <ListItemIcon>
                <SendIcon sx={{ width: 16, height: 16 }} />
              </ListItemIcon>
              <ListItemText>{t('resendTransportContract')}</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleRecreateTransportContract()}>
              <ListItemIcon>
                <SendIcon sx={{ width: 16, height: 16 }} />
              </ListItemIcon>
              <ListItemText>{t('recreateTransportContract')}</ListItemText>
            </MenuItem>
          </Menu>
        </PaperStyles>
      </CardContent>
    </Card>
  ) : (
    <Card
      variant="filled-disabled"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <CardHeader
        title="Transport Contract"
        variant="small"
        action={
          <IconButton aria-label="settings" variant="tertiary">
            <LockedIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Alert
          title={t('availableAsSoonAsTransportContractIsAvailable')}
          severity="info"
        />
      </CardContent>
    </Card>
  );
};
