import React, { PropsWithChildren } from 'react';

import { TableInstance, Typography } from '@dizzbo/ui';
import { OrderType } from '@types';

type Props = PropsWithChildren<{
  highlighted: boolean;
  table: TableInstance<OrderType>;
}>;

export const TextCellContent: React.FC<Props> = ({
  children,
  highlighted = false,
  table,
}: Props) => {
  const { getState } = table;
  const { density } = getState();

  let textVariant: any = 'bodyRegular';
  if (density === 'compact') {
    textVariant = 'bodyRegularSmall';
    if (highlighted) {
      textVariant = 'bodyBoldSmall';
    }
  } else {
    if (highlighted) {
      textVariant = 'bodyBold';
    }
  }

  return (
    <Typography variant={textVariant}>{children ? children : '-'}</Typography>
  );
};
