import { confirmOrder } from '@core/api';
import { QueryKeys } from '@core/config';
import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { OrderType } from '@types';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  SquareIcon,
  Stack,
  Typography,
} from '@dizzbo/ui';

const SubtitleStyles = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
  padding: 24,
}));

const ContentCardStyles = styled(Card)(({ theme }) => ({
  width: '10  0%',
  height: 200,
}));

type Props = {
  orderData: OrderType;
  popupState: PopupState;
};

export const OrderConfirmationDialog: React.FC<Props> = ({
  orderData,
  popupState,
}: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { uuid: orderUUID, reference, status } = orderData;

  const { mutate: mutateConfirmOrder, isPending } = useMutation({
    mutationKey: [QueryKeys.ORDERS, orderUUID],
    mutationFn: (values) => confirmOrder(orderUUID),
    onSuccess() {
      toast.success(t('orderConfirmed'));
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ORDERS, orderUUID],
      });
      popupState.close();
    },
    onError() {
      toast.error(t('orderCouldNotBeConfirmed'));
      popupState.close();
    },
  });

  const handleClick = () => {
    mutateConfirmOrder();
    popupState.close();
  };
  return (
    <Dialog
      PaperProps={{
        elevation: 6,
        variant: 'filled-primary',
        sx: {
          minWidth: 600,
        },
      }}
      scroll="paper"
      {...bindDialog(popupState)}
    >
      <DialogTitle onClose={popupState.close}>
        <Typography variant="h3" color="primary">
          {reference}
        </Typography>
        <Typography
          variant="h3"
          color="warning.main"
          sx={{ textTransform: 'capitalize', marginLeft: 1 }}
        >
          – {status}
        </Typography>
      </DialogTitle>

      <DialogContent dividers sx={{ padding: 0 }}>
        <SubtitleStyles>
          <Typography variant="h5">{t('confirmOrder')}</Typography>
        </SubtitleStyles>
        <ContentCardStyles variant="filled-green" elevation={0} square>
          <CardContent>
            <Typography variant="buttonRegularSmall">
              {t('orderConfirmationDialog_customerNumber')}{' '}
              {orderData.loader?.customerNumber}
            </Typography>
            <br />
            <Typography variant="labelSmall">
              {orderData.loader?.name} {orderData.loader?.secondName}{' '}
            </Typography>
          </CardContent>
        </ContentCardStyles>
      </DialogContent>
      <DialogActions>
        <Stack spacing={3} direction="row">
          <Button variant="tertiary" onClick={() => popupState.close()}>
            {t('cancel')}
          </Button>
          <LoadingButton
            type="submit"
            variant="primary"
            onClick={handleClick}
            loading={isPending}
            startIcon={<SquareIcon />}
          >
            {t('confirmOrder')}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
