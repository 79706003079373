import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';

import { updateOrderStop } from '@core/api';
import { LoadingPointAutocomplete } from '@core/components';
import { QueryKeys } from '@core/config';
import { Box, Skeleton, Stack } from '@dizzbo/ui';

import { StopType, UUIDType } from '@types';
import { LoadingPointBullet } from './LoadingPointBullet';
import { StopActions } from './StopActions';
import { StopSectionSummary } from './StopSectionSummary';

const StopContainerStyles = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isHovering',
})<{ isHovering: boolean }>(({ theme, isHovering }) => ({
  height: '100%',
  backgroundColor: theme.styles.paper.filledSecondary.default.backgroundColor,
  '&:hover': {
    backgroundColor: theme.styles.planner.stopListItem.hover.backgroundColor,
  },
}));

const BulletContainerStyles = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'lastItem',
})<{ lastItem: boolean }>(({ theme, lastItem }) => {
  if (lastItem) {
    return {
      height: '100%',
      position: 'relative',
    };
  }
  return {
    height: '100%',
    position: 'relative',
    '&::after': {
      content: "' '",
      position: 'absolute',
      top: 54,
      bottom: 0,
      left: '50%',
      border: '1px dashed #404E64',
    },
  };
});

type Props = {
  orderUUID: UUIDType;
  index: number;
  dragHandleAttributes: DraggableAttributes;
  dragHandleListeners: SyntheticListenerMap | undefined;
  style?: any;
  lastItem: boolean;
  stop: StopType;
};

export const StopListItem = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      orderUUID,
      index,
      dragHandleAttributes,
      dragHandleListeners,
      style,
      lastItem = false,
      stop,
    },
    ref
  ) => {
    const routeSections = [];
    const [isHovering, setIsHovering] = useState(false);
    const queryClient = useQueryClient();

    const mutation = useMutation({
      mutationFn: (values) => {
        return updateOrderStop(orderUUID, stop.uuid, values);
      },
      onSuccess: (data: any, values: any) => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.STOPS, orderUUID],
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.STOPS, stop.uuid],
        });
      },
    });

    const handleAutocompleteOnChange = (loadingPoint: any) => {
      if (loadingPoint) {
        mutation.mutate({ loadingPoint: loadingPoint.uuid, ...loadingPoint });
      }
    };

    const shouldRenderSummary =
      !lastItem && routeSections?.length > 0 && index < routeSections?.length;

    return (
      <Box sx={{ backgroundColor: '#FFFFFF', ...style }} ref={ref}>
        <StopContainerStyles
          isHovering={isHovering}
          onMouseEnter={() => {
            setIsHovering(true);
          }}
          onMouseLeave={() => {
            setIsHovering(false);
          }}
          direction="row"
          py={3}
          px={6}
        >
          <BulletContainerStyles lastItem={lastItem}>
            <LoadingPointBullet
              index={index}
              isAddStopItem={false}
              actions={stop.actions}
              dragHandleAttributes={dragHandleAttributes}
              dragHandleListeners={dragHandleListeners}
            />
          </BulletContainerStyles>
          <Stack
            spacing={3}
            direction="column"
            ml={3}
            sx={{
              display: 'inline',
              width: '100%',
            }}
          >
            <Stack
              spacing={0}
              direction="row"
              alignItems="center"
              sx={{
                width: '100%',
              }}
            >
              <LoadingPointAutocomplete
                label="Stop"
                display
                value={stop.loadingPoint}
                disableClearable={false}
                sx={{ width: '100%' }}
                onChange={handleAutocompleteOnChange}
              />

              <Box ml={2}>
                <StopActions stopUUID={stop.uuid} orderUUID={orderUUID} />
              </Box>
            </Stack>
            {shouldRenderSummary && (
              <>
                {!isCalculatingRouteData ? (
                  <StopSectionSummary
                    length={routeSections[index]?.summary.length}
                    duration={routeSections[index]?.summary.duration}
                    totalToll={routeSections[index]?.summary.totalToll}
                  />
                ) : (
                  <Skeleton width={210} height={40} />
                )}
              </>
            )}
          </Stack>
        </StopContainerStyles>
      </Box>
    );
  }
);

StopListItem.displayName = 'StopListItem';

export default StopListItem;
