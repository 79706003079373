import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Unstable_Grid2';
import { useMutation } from '@tanstack/react-query';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import { postcodeValidator } from 'postcode-validator';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { createLoadingPoint } from '@core/api';
import { useFieldErrors } from '@dizzbo/core/hooks';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LoadingButton,
  MultiLineTextfield,
  Stack,
  TextField,
  Typography,
} from '@dizzbo/ui';

import i18n from '@dizzbo/core/i18n/i18n';
import {
  AddLoadingPointFormData,
  AddLoadingPointFormParsedData,
  LoadingPointType,
} from '@types';
import { useTranslation } from 'react-i18next';
import { GooglePlacesAutocomplete } from '../GooglePlacesAutocomplete';
import { CountryAutocomplete } from './CountryAutocomplete';
import { LoadingPointLocationDetail } from './LoadingPointLocationDetail';
import { MapPreview } from './MapPreview';

const schema = yup.object().shape({
  name: yup.string().required(i18n.t('pleaseEnterANameForThisLoadingPoint')),
  streetAddress: yup.string(),
  city: yup.string().required(i18n.t('pleaseEnterACity')),
  postalCode: yup
    .string()
    .required(i18n.t('pleaseEnterAPostalCode'))
    .test({
      name: 'max',
      exclusive: false,
      params: {},
      message: i18n.t('notAValidPostalCodeForThisCountry'),
      test: (value, context) =>
        context.parent.country?.code
          ? postcodeValidator(value, context.parent.country?.code)
          : true,
    }),
  country: yup.object({}).required(i18n.t('pleaseEnterACountry')),
  openingHours: yup.string(),
  instructions: yup.string(),
});

type Props = {
  popupState: PopupState;
  setLoadingPoint: (waypoint: LoadingPointType) => void;
};

export const CreateLoadingPointDialog: React.FC<Props> = ({
  popupState,
  setLoadingPoint,
}: Props) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });
  const content = useRef(null);
  const [location, setLocation] = useState({ lat: 50, lng: 5 });
  const [locationChanged, setLocationChanged] = useState(false);
  const [userScrolledToBottom, setUserScrolledToBottom] = useState(false);
  const [showLoadingPointLocationDetail, setShowLoadingPointLocationDetail] =
    useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (!popupState.isOpen) {
      // reset the form if the dialog is not open.
      // Because this Dialog doesn't unmount.
      setLocation({ lat: 50, lng: 5 });
      setUserScrolledToBottom(false);
      setShowLoadingPointLocationDetail(false);
      reset();
    }
  }, [popupState]);

  useEffect(() => {
    if (location && location.lat !== 50 && location.lng !== 5) {
      setLocationChanged(true);
    }
  }, [location]);

  useEffect(() => {
    if (content.current) {
      // check if our content has an overflow/needs to be scrolled.
      // If not set userScrolledToBottom to false
      if (!(content.current?.scrollHeight > content.current?.clientHeight)) {
        if (locationChanged) {
          setUserScrolledToBottom(true);
        }
      }
    }
  }, [popupState, locationChanged]);

  const {
    mutateAsync: mutateCreateLoadingPoint,
    error,
    isError,
    isPending,
  } = useMutation({
    mutationFn: (values: AddLoadingPointFormParsedData) => {
      return createLoadingPoint(values);
    },
    onSuccess: (data: LoadingPointType) => {
      setLoadingPoint(data);
      popupState.close();
      toast.success(t('loadingPointCreated', { loadingPointName: data.name }));
    },
    onError: (error: any) => {
      toast.error(`${error.response.data.address.non_field_errors[0]}`);
    },
  });

  const { hasFieldError, fieldError } = useFieldErrors(isError, errors, error);

  const onSubmit = (formData: AddLoadingPointFormData) => {
    const {
      city,
      country,
      instructions,
      name,
      openingHours,
      postalCode,
      streetAddress,
    } = formData;
    const { code: countryCode } = country;
    const { lat, lng } = location;

    const parsedData: AddLoadingPointFormParsedData = {
      name,
      address: {
        streetAddress,
        city,
        postalCode,
        country: countryCode,
        lat,
        lng,
      },
      openingHours,
      instructions,
    };

    mutateCreateLoadingPoint(parsedData);
  };

  const handleNoneFieldErrors = () => {
    if (error && error.response.data.address.non_field_errors) {
      return (
        <Alert
          severity="error"
          title={error.response.data.address.non_field_errors[0]}
          message={error.response.data.address.non_field_errors[0]}
        />
      );
    }
  };

  const handleScroll = (event: SyntheticEvent) => {
    const target = event.target as HTMLDivElement;
    const { scrollHeight, scrollTop, clientHeight } = target;
    if (Math.abs(scrollHeight - (scrollTop + clientHeight)) <= 4) {
      setUserScrolledToBottom(true);
    }
  };

  return (
    <Dialog
      PaperProps={{
        elevation: 6,
        variant: 'filled-primary',
        sx: {
          width: '100%',
          maxWidth: 800,
        },
      }}
      scroll="paper"
      // open={true}
      {...bindDialog(popupState)}
    >
      {showLoadingPointLocationDetail ? (
        <LoadingPointLocationDetail
          location={location}
          setLocation={setLocation}
          closeLocationDetailDialog={() =>
            setShowLoadingPointLocationDetail(false)
          }
        />
      ) : (
        <>
          <DialogTitle onClose={popupState.close}>
            {t('createNewLoadingPoint')}
          </DialogTitle>
          <DialogContent dividers onScroll={handleScroll} ref={content}>
            <form id="form" onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={6}>
                <GooglePlacesAutocomplete
                  label={t('searchForLocation')}
                  setFieldValue={setValue}
                  resetForm={reset}
                  setLocation={setLocation}
                />
              </Stack>
              <Divider sx={{ marginTop: 6, marginBottom: 6 }} />

              <Grid container spacing={6} rowSpacing={6}>
                <Grid xs={12}>
                  <Typography variant="h6">
                    {t('companyOrLoadingPointName')}
                  </Typography>
                  {handleNoneFieldErrors()}
                </Grid>
                <Grid xs={12}>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        label={t('loadingPointName')}
                        fullWidth
                        required
                        error={hasFieldError(field)}
                        helperText={fieldError(field)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={12}>
                  <Controller
                    name="streetAddress"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        label={t('streetOrLocation')}
                        fullWidth
                        error={hasFieldError(field)}
                        helperText={fieldError(field)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={12} md={4}>
                  <Controller
                    name="city"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        label={t('city')}
                        fullWidth
                        required
                        error={hasFieldError(field)}
                        helperText={fieldError(field)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={12} md={4}>
                  <Controller
                    name="postalCode"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        label={t('postalCode')}
                        fullWidth
                        required
                        error={hasFieldError(field)}
                        helperText={fieldError(field)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={12} md={4}>
                  <Controller
                    name="country"
                    defaultValue={{ code: 'DE', name: 'Deutschland' }}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CountryAutocomplete
                        label={t('country')}
                        required
                        error={hasFieldError(field)}
                        helperText={fieldError(field)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={12}>
                  <MapPreview
                    location={location}
                    setShowLoadingPointLocationDetail={
                      setShowLoadingPointLocationDetail
                    }
                  />
                  {!locationChanged && (
                    <Alert
                      sx={{ marginTop: 6 }}
                      severity="warning"
                      title={t('pleaseCheckLocation')}
                      message={t('ensureThatTheLocationOnTheMapIsCorrect')}
                    />
                  )}
                </Grid>
              </Grid>

              <Divider sx={{ marginTop: 6, marginBottom: 6 }} />

              <Grid xs={12}></Grid>

              <Stack spacing={6}>
                <Typography variant="h6">
                  {t('additionalInformation')}
                </Typography>
                <Controller
                  name="openingHours"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <MultiLineTextfield
                      label={t('openingHours')}
                      error={hasFieldError(field)}
                      helperText={fieldError(field)}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="instructions"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <MultiLineTextfield
                      label={t('instructions')}
                      error={hasFieldError(field)}
                      helperText={fieldError(field)}
                      {...field}
                    />
                  )}
                />
              </Stack>
            </form>
          </DialogContent>
          <DialogActions>
            <Stack spacing={3} direction="row">
              <Button variant="tertiary" onClick={() => popupState.close()}>
                {t('cancel')}
              </Button>
              <LoadingButton
                type="submit"
                form="form"
                size="large"
                variant="primary"
                disabled={!isValid || !userScrolledToBottom}
                loading={isPending}
              >
                {t('save')}
              </LoadingButton>
            </Stack>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
