import React from 'react';
import { useQuery } from '@tanstack/react-query';

import { Card } from '@dizzbo/ui';
import { QueryKeys } from '@core/config';
import { getOrderDeliveryList } from '@core/api';

import { Stops } from './Stops';
import { LoadingPlanTabs } from './LoadingPlanTabs';
import { LoadingPlanTabPanel } from './LoadingPlanTabPanel';
import { DeliveryDetail } from './DeliveryDetail';
import { useOrderDetail } from '../../hooks/useOrderDetail';

type Props = {};

export const LoadingPlan: React.FC<Props> = () => {
  const { orderData } = useOrderDetail();
  const { uuid: orderUUID } = orderData;
  const [activeTab, setActiveTab] = React.useState(0);

  const { data: deliveriesData, isPending } = useQuery({
    queryKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.DELIVERIES],
    queryFn: () => getOrderDeliveryList(orderUUID),
    enabled: !!orderData,
  });

  if (isPending) {
    return null;
  }

  return (
    <Card
      variant="filled-secondary"
      elevation={1}
      sx={{
        height: '100%',
        width: '100%',
        overflow: 'visible',
      }}
    >
      <LoadingPlanTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        deliveriesData={deliveriesData}
      />
      <LoadingPlanTabPanel value={activeTab} index={0}>
        <Stops />
      </LoadingPlanTabPanel>
      {deliveriesData?.map((delivery, index) => (
        <LoadingPlanTabPanel
          key={delivery.uuid}
          value={activeTab}
          index={index + 1}
        >
          <DeliveryDetail setActiveTab={setActiveTab} deliveryData={delivery} />
        </LoadingPlanTabPanel>
      ))}
    </Card>
  );
};
