import { BryntumSchedulerPro } from '@bryntum/schedulerpro-react';
import React, { useRef, useState } from 'react';

import { SchedulerStylesWrapper } from '@scheduler/components/SchedulerStylesWrapper';

import { TourType } from '@types';
import { WindowWrapper } from './WindowWrapper';
import { schedulerConfig } from './config';

type Props = {
  toursData: TourType[];
};

export const TourCalendar: React.FC<Props> = ({ toursData }) => {
  const [selectedTour, setSelectedTour] = useState(undefined);
  const schedulerProRef = useRef<BryntumSchedulerPro>(null);

  const syncData = async (event) => {};

  const handleDeleteEvent = () => {};

  const handleSelectionChange = (event) => {
    if (event.action === 'select' || event.action === 'update') {
      setSelectedTour(event.selection[0].originalData);
    }
    if (event.action === 'clear') {
      setSelectedTour(undefined);
    }
  };

  const resources = [{ id: 1, name: '', image: false }];
  const events = [];

  if (toursData) {
    toursData.map((order, index) => {
      events.push({
        ...order,
        resourceId: 1,
      });
    });
  }

  return (
    <WindowWrapper selectedTour={selectedTour}>
      <SchedulerStylesWrapper sx={{ minHeight: 210, display: 'block' }}>
        <BryntumSchedulerPro
          ref={schedulerProRef}
          resources={resources}
          events={events}
          onEventSelectionChange={handleSelectionChange}
          onDataChange={syncData}
          // onBeforeEventDelete={handleDeleteEvent}
          {...schedulerConfig}
          eventDragCreateFeature={{ disabled: true }}
        />
        /
      </SchedulerStylesWrapper>
    </WindowWrapper>
  );
};
