import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const HideIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0497 17.5954L18.9952 19.9942L20.5485 18.7344L7.27662 2.37009L5.72327 3.6299L7.67471 6.03601C6.3734 6.64894 5.22605 7.45488 4.29081 8.2301C3.3396 9.01854 2.57232 9.80469 2.04245 10.3937C1.77693 10.6888 1.56951 10.9362 1.42688 11.1119C1.35552 11.1997 1.30027 11.2698 1.26197 11.3191C1.24281 11.3438 1.22788 11.3633 1.21729 11.3772L1.20465 11.3939L1.20079 11.399L1.19947 11.4007C1.19947 11.4007 1.19858 11.402 2.00004 12L1.19858 11.402L0.752319 12L1.19858 12.598L1.20079 12.601L1.20465 12.6061L1.21729 12.6228C1.22788 12.6367 1.24281 12.6562 1.26197 12.6809C1.30027 12.7302 1.35552 12.8003 1.42688 12.8881C1.56951 13.0638 1.77693 13.3112 2.04245 13.6063C2.57232 14.1953 3.3396 14.9815 4.29081 15.7699C6.16484 17.3233 8.89048 19 12 19C13.8525 19 15.5687 18.4049 17.0497 17.5954ZM15.7654 16.0118L14.1817 14.0592C13.6348 14.6385 12.8596 15 12 15C10.3431 15 9 13.6569 9 12C9 10.9165 9.57441 9.96714 10.4353 9.43985L8.97961 7.64497C7.71919 8.16717 6.5579 8.94866 5.56713 9.7699C4.70869 10.4815 4.01152 11.1953 3.52933 11.7313C3.44313 11.8271 3.36399 11.917 3.29223 12C3.36399 12.083 3.44313 12.1729 3.52933 12.2687C4.01152 12.8047 4.70869 13.5185 5.56713 14.2301C7.31239 15.6767 9.58675 17 12 17C13.3177 17 14.594 16.6055 15.7654 16.0118Z"
        />
        <path d="M21.9576 13.6063C21.5119 14.1018 20.8981 14.7368 20.1482 15.3953L18.886 13.8418C19.5384 13.2642 20.0765 12.707 20.4707 12.2687C20.5569 12.1729 20.6361 12.083 20.7078 12C20.6361 11.917 20.5569 11.8271 20.4707 11.7313C19.9886 11.1953 19.2914 10.4815 18.4329 9.7699C17.0427 8.61754 15.3167 7.54346 13.4522 7.15416L11.706 5.00498C11.8036 5.00168 11.9016 5 12 5C15.1096 5 17.8352 6.67672 19.7093 8.2301C20.6605 9.01854 21.4278 9.80469 21.9576 10.3937C22.2231 10.6888 22.4306 10.9362 22.5732 11.1119C22.6446 11.1997 22.6998 11.2698 22.7381 11.3191C22.7573 11.3438 22.7722 11.3633 22.7828 11.3772L22.7954 11.3939L22.7993 11.399L22.8011 11.4014L22 12C22.8015 12.598 22.8006 12.5992 22.8006 12.5992L22.7993 12.601L22.7954 12.6061L22.7828 12.6228C22.7722 12.6367 22.7573 12.6562 22.7381 12.6809C22.6998 12.7302 22.6446 12.8003 22.5732 12.8881C22.4306 13.0638 22.2231 13.3112 21.9576 13.6063Z" />
        <path d="M22 12L22.8015 12.598L23.2478 12L22.8011 11.4014C22.8011 11.4014 22.8015 11.402 22 12Z" />
      </SvgIcon>
    );
  }
);

HideIcon.displayName = 'HideIcon';

export default HideIcon;
