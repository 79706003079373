import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TransportIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 11.5V17H3L3 3H21V17H19V11.5H12.5V17H11.5V11.5H5ZM5 5H11.5V10.5H5V5ZM12.5 10.5H19V5H12.5V10.5Z"
        />
        <path d="M22 22H19V20H14V22H10V20H5V22H2V18H22V22Z" />
      </SvgIcon>
    );
  }
);

TransportIcon.displayName = 'TransportIcon';

export default TransportIcon;
