import { alpha } from '@mui/material/styles';

const planner = (palette) => {
  return {
    planner: {
      stopListItem: {
        default: {
          backgroundColor: palette.common.white,
        },
        hover: {
          backgroundColor: alpha(palette.turquoise[80], 0.2),
        },
      },
    },
  };
};

export default planner;
