import { alpha } from '@mui/material/styles';

const list = (palette) => {
  return {
    listSubheader: {
      default: {
        color: palette.oxford[25],
      },
    },
    listItem: {
      primary: {
        default: {
          borderColor: alpha(palette.oxford[20], 0.25),
        },
        hover: {
          backgroundColor: palette.turquoise[40],
        },
        active: {
          backgroundColor: palette.turquoise[60],
        },
      },
      checkBoxIconColor: palette.oxford[100],
    },
    listItemButton: {
      default: {
        color: palette.turquoise[150],
      },
    },
  };
};

export default list;
