import alert from './alert';
import appBar from './appBar';
import banner from './banner';
import button from './button';
import chip from './chip';
import common from './common';
import datePicker from './datePicker';
import divider from './divider';
import documentUploadField from './documentUploadField';
import iconBullet from './iconBullet';
import images from './images';
import listTab from './listTab';
import input from './input';
import link from './link';
import list from './list';
import menu from './menu';
import minimalIconButton from './minimalIconButton';
import paper from './paper';
import planner from './planner';
import scheduler from './scheduler';
import switchInput from './switch';
import tab from './tab';
import table from './table';
import toggleButton from './toggleButton';
import tooltip from './tooltip';

const themeLight = (palette) => {
  return Object.assign(
    alert(palette),
    appBar(palette),
    banner(palette),
    button(palette),
    chip(palette),
    common(palette),
    datePicker(palette),
    divider(palette),
    documentUploadField(palette),
    iconBullet(palette),
    images(palette),
    listTab(palette),
    input(palette),
    link(palette),
    list(palette),
    menu(palette),
    minimalIconButton(palette),
    paper(palette),
    planner(palette),
    scheduler(palette),
    switchInput(palette),
    tab(palette),
    table(palette),
    toggleButton(palette),
    tooltip(palette)
  );
};

export default themeLight;
