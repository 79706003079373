import { Box, Paper, PaperPropsVariantOverrides } from '@mui/material';
import { styled } from '@mui/material/styles';
import { OverridableStringUnion } from '@mui/types';
import React, { FC, PropsWithChildren, ReactNode } from 'react';

type CardSubHeaderProps = PropsWithChildren<{
  title: string;
  icon?: ReactNode;
  variant?: OverridableStringUnion<
    'elevation' | 'outlined',
    PaperPropsVariantOverrides
  >;
}>;

const ContainerStyles = styled(Paper)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: 64,
  alignItems: 'center',
  paddingLeft: 24,
  paddingRight: 24,
  color: theme.styles.common.textColors.main,
  ...theme.typography.h5,
}));

const IconStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginRight: theme.spacing(4),
  justifyContent: 'center',
  alignItems: 'center',
}));

const ChildrenStyles = styled(Box)(() => ({
  display: 'flex',
  marginLeft: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: 200,
}));

export const CardSubHeader: FC<CardSubHeaderProps> = ({
  title,
  icon,
  children,
  variant = 'filled-secondary',
}) => (
  <ContainerStyles variant={variant} elevation={0}>
    {icon && <IconStyles>{icon}</IconStyles>}
    {title}
    {children && <ChildrenStyles>{children}</ChildrenStyles>}
  </ContainerStyles>
);

CardSubHeader.displayName = 'CardSubHeader';
