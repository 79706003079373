import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CloudActiveIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d='M10 13C10 13.5523 9.55228 14 9 14C8.44772 14 8 13.5523 8 13C8 12.4477 8.44772 12 9 12C9.55228 12 10 12.4477 10 13Z' />
        <path d='M12 14C12.5523 14 13 13.5523 13 13C13 12.4477 12.5523 12 12 12C11.4477 12 11 12.4477 11 13C11 13.5523 11.4477 14 12 14Z' />
        <path d='M15 14C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12C14.4477 12 14 12.4477 14 13C14 13.5523 14.4477 14 15 14Z' />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M13 2C10.2033 2 7.79174 3.63961 6.67031 6.00763C2.95739 6.17978 0 9.24452 0 13C0 16.866 3.13401 20 7 20H17C20.866 20 24 16.866 24 13C24 10.0246 22.1442 7.48487 19.5289 6.47107C18.5151 3.85575 15.9754 2 13 2ZM8.28425 7.33325C8.97112 5.38991 10.8245 4 13 4C15.2548 4 17.1634 5.49329 17.7859 7.54713L17.941 8.05895L18.4529 8.21408C20.5067 8.83659 22 10.7452 22 13C22 15.7614 19.7614 18 17 18H7C4.23858 18 2 15.7614 2 13C2 10.2386 4.23858 8 7 8H8.04859L8.28425 7.33325Z'
        />
      </SvgIcon>
    );
  }
);

CloudActiveIcon.displayName = 'CloudActiveIcon';

export default CloudActiveIcon;
