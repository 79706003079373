import React, { ChangeEvent } from 'react';
import { useTheme } from '@mui/material/styles';

import { Box } from '@dizzbo/ui';

import { TablePagination } from './TablePagination';

// TODO FIX TYPES
export const TablePaginationBar: React.FC<any> = ({ table }: any) => {
  const theme = useTheme();
  const {
    getPrePaginationRowModel,
    getState,
    setPageIndex,
    setPageSize,
    options: {
      muiTablePaginationProps,
      enableToolbarInternalActions,
      localization,
      rowCount,
    },
  } = table;

  const {
    pagination: { pageSize = 10, pageIndex = 0 },
    showGlobalFilter,
  } = getState();

  const totalRowCount = rowCount ?? getPrePaginationRowModel().rows.length;
  const showFirstLastPageButtons = totalRowCount / pageSize > 2;

  const tablePaginationProps =
    muiTablePaginationProps instanceof Function
      ? muiTablePaginationProps({ table })
      : muiTablePaginationProps;

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        position: 'relative',
        height: 56,
        zIndex: 2,
        background: theme.styles.tablePagination.default.backgroundColor,
        boxShadow: '0px 8px 48px rgba(0, 0, 0, 0.15)',
      }}
    >
      <TablePagination
        count={totalRowCount}
        onPageChange={(_: any, newPage: number) => setPageIndex(newPage)}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={pageIndex}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50, 100]}
        showFirstButton={showFirstLastPageButtons}
        showLastButton={showFirstLastPageButtons}
      />
    </Box>
  );
};
