import { useMutation } from '@tanstack/react-query';
import { bindDialog } from 'material-ui-popup-state/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SquareIcon,
  Stack,
} from '@dizzbo/ui';
import { PopupState } from 'material-ui-popup-state/hooks';

import { deleteOrder, updateOrder } from '@core/api';
import { CreateOrderForm } from '@order-detail/components/CreateOrderForm';
import { OrderType } from '@types';

interface CreateOrderDialogProps {
  orderData: OrderType;
  popupState: PopupState;
}

export const CreateOrderDialog: React.FC<CreateOrderDialogProps> = ({
  orderData,
  popupState,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate: mutateDeleteOrder }: any = useMutation({
    mutationFn: (values) => deleteOrder(values.orderUUID),
  });

  const { mutate: mutateUpdateOrder }: any = useMutation({
    mutationFn: (values) =>
      updateOrder({
        orderUUID: orderData?.uuid,
        orderData: {
          status: 'DRAFT',
        },
      }),
  });

  const handleConfirmOrder = () => {
    mutateUpdateOrder();
    navigate(`/orders/${orderData.uuid}`);
  };

  const handleCloseDialog = () => {
    mutateDeleteOrder({ orderUUID: orderData?.uuid });
    popupState.close();
  };

  if (!orderData) return null;

  return (
    <Dialog
      PaperProps={{
        elevation: 6,
        variant: 'filled-primary',
      }}
      {...bindDialog(popupState)}
      onClose={handleCloseDialog}
      fullWidth={true}
      sx={{ '.MuiDialog-paper': { maxWidth: 1280, minHeight: '90vh' } }}
      scroll="paper"
    >
      <DialogTitle onClose={handleCloseDialog}>
        {t('createNewOrder')}
      </DialogTitle>
      <DialogContent dividers>
        <CreateOrderForm orderData={orderData} />
      </DialogContent>
      <DialogActions>
        <Stack spacing={3} direction="row">
          <Button variant="tertiary" onClick={handleCloseDialog}>
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            form="form"
            disabled={false}
            onClick={handleConfirmOrder}
          >
            {t('saveAsDraft')}
          </Button>
          <Button
            type="submit"
            form="form"
            disabled={false}
            startIcon={<SquareIcon />}
            onClick={handleConfirmOrder}
          >
            {t('confirmOrder')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
