import React from 'react';
import { Chip } from '@dizzbo/ui';
import { useTranslation } from 'react-i18next';

import { LoadingIcon, UnloadingIcon, InfoOutlineIcon } from '@dizzbo/ui';

interface Props {
  index: number;
  action: string;
}

export const DeliveryListItem: React.FC<Props> = ({
  index,
  action = 'loading',
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Chip
        label={`${t('delivery')} ${index}`}
        size="large"
        variant={action === 'loading' ? 'oxford-40' : 'green-100'}
        onDelete={() => {}}
        deleteIcon={<InfoOutlineIcon />}
        icon={action === 'loading' ? <LoadingIcon /> : <UnloadingIcon />}
      />
    </>
  );
};
