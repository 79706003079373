import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CreateOrderIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M4 2H14.3837L20 7.05464V11H18V7.94536L13.6163 4H6V20H12V22H4V2Z" />
        <path d="M20 24H18V20H14V18H18V14H20V18H24V20H20V24Z" />
      </SvgIcon>
    );
  }
);

CreateOrderIcon.displayName = 'CreateOrderIcon';

export default CreateOrderIcon;
