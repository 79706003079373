import { InputLabel as MuiInputLabel } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiInputLabelProps = ComponentProps<typeof MuiInputLabel>;

export const InputLabel = forwardRef<HTMLLabelElement, MuiInputLabelProps>(
  (props, ref) => <MuiInputLabel ref={ref} {...props} />
);

InputLabel.displayName = 'InputLabel';
