import { axiosAuthenticatedRequest } from './axios';

export const getAuthUser = async (): Promise<UserType> => {
  try {
    const res = await axiosAuthenticatedRequest.get('/v2/users/me/');
    return res?.data || null;
  } catch {
    return null;
  }
};

export const updateAuthUser = async (formData: any): Promise<UserType> => {
  const res = await axiosAuthenticatedRequest.put('/v2/users/me/', formData);
  return res.data;
};

export const getUsersAutocomplete = async (params): Promise<UserType[]> => {
  const res = await axiosAuthenticatedRequest.get('/v2/users/autocomplete/', {
    params: params,
  });
  return res.data;
};

export const getFrontendSettings = async () => {
  const res = await axiosAuthenticatedRequest.get<FrontendSettingsType>(
    '/v2/users/me/frontend-settings/'
  );
  return res.data;
};

export const updateFrontendSettings = async (
  frontendSettings: Partial<FrontendSettingsType>
) => {
  const res = await axiosAuthenticatedRequest.patch<FrontendSettingsType>(
    '/v2/users/me/frontend-settings/',
    frontendSettings
  );
  return res.data;
};
