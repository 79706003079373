import React, { useState } from 'react';
import { styled } from '@mui/material/styles';

import { Box } from '@dizzbo/ui';
import { AppBar } from './AppBar';

const AppBarWrapperStyles = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})<any>(({ theme, expanded }) => {
  let transition = {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  };
  if (!expanded) {
    transition = {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    };
  }
  return {
    display: 'flex',
    zIndex: theme.zIndex.appBar + 5,
    width: expanded ? 306 : 64,
    left: 0,
    top: 0,
    bottom: 0,
    flexDirection: 'column',
    backgroundColor: theme.styles.appBar.backgroundColor,
    boxShadow: theme.styles.appBarWrapper.boxShadow,
    ...transition,
  };
});

const ContentWrapperStyles = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'hidden',
  overflowY: 'hidden',
  // position: 'absolute',
  left: 64,
  [theme.breakpoints.up('lg')]: {
    position: 'relative',
    left: 0,
  },
}));

export const AppBarWrapper = ({ navItems, children }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggleAppBar = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <AppBarWrapperStyles expanded={expanded}>
        <AppBar
          handleToggleAppBar={handleToggleAppBar}
          expanded={expanded}
          navItems={navItems}
        />
      </AppBarWrapperStyles>
      <ContentWrapperStyles>{children}</ContentWrapperStyles>
    </>
  );
};
