const common = (palette) => {
  return {
    common: {
      pageBackground: palette.oxford[100],
      modalBackground: palette.oxford[80],
      textColors: {
        primary: palette.oxford[10],
        secondary: palette.oxford[20],
        disabled: palette.oxford[25],
      },
      error: {
        main: '#C25253',
        light: '#C25253',
        dark: '#CF5759',
      },
      warning: {
        main: '#DD8B00',
        light: '#DD8B00',
        dark: '#E69101',
      },
      info: {
        main: '#4A73C7',
        light: '#4A73C7',
        dark: '#5B8FF5',
      },
      success: {
        main: '#348267',
        light: '#348267',
        dark: '#3F9E7D',
      },
    },
  };
};

export default common;
