import { useSortable } from '@dnd-kit/sortable';
import { useTheme } from '@mui/material/styles';
import { StopType, UUIDType } from '@types';
import React from 'react';
import { StopListItem } from './StopListItem';

type Props = {
  index: number;
  stop: StopType;
  orderUUID: UUIDType;
  lastItem: boolean;
};

export const SortableItem: React.FC<Props> = ({
  index,
  stop,
  orderUUID,
  lastItem,
}: Props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: stop.uuid });
  const theme = useTheme();

  const style = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : 'translate3d(0px, 0px, 0)',
    // transform: CSS.Transform.toString(transform),
    zIndex: isDragging ? 99999999 : 1,
    transition,
    width: '100%',
    boxShadow: isDragging ? theme.shadows[3] : 'none',
  };

  return (
    <StopListItem
      index={index}
      key={stop.uuid}
      stop={stop}
      orderUUID={orderUUID}
      dragHandleAttributes={attributes}
      dragHandleListeners={listeners}
      ref={setNodeRef}
      style={style}
      lastItem={lastItem}
    />
  );
};
