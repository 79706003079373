import { Box as MuiBox } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiBoxProps = ComponentProps<typeof MuiBox>;

export const Box = forwardRef<HTMLDivElement, MuiBoxProps>((props, ref) => (
  <MuiBox {...props} ref={ref} />
));

Box.displayName = 'Box';
