import { useMutation } from '@tanstack/react-query';

import { createOrder } from '@core/api';
import { OrderType } from '@types';

type Payload = OrderType | undefined;

export const useCreateOrder = () => {
  return useMutation({
    mutationFn: (values?: Payload) => createOrder(values),
    onSuccess: (data) => {},
  });
};
