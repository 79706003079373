import React, { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { styled } from '@mui/material/styles';

import { View } from '@dizzbo/core/views';
import { RoutingMap } from '@core/components/RoutingMap';

import { Box } from '@dizzbo/ui';
import { getTour, getVehicle, getRoute } from '@core/api';
import { QueryKeys } from '@core/config';
import { LoadingScreen, PageHeader } from '@dizzbo/ui';

import { DetailHeader } from '../components/DetailHeader';
import { Detail } from '../components/Detail';

const ContainerStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(-64px + 100vh)',
  width: '100%',
  zIndex: theme.zIndex.appBar + 1,
}));

const DetailAndMapWrapperStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: 'calc(-142px + 100vh)',
  width: '100%',
  paddingTop: 24,
  paddingRight: 24,
}));

const DetailWrapperStyles = styled(Box)(({ theme }) => ({
  width: '30%',
  height: '100%',
}));

const MapWrapperStyles = styled(Box)(({ theme }) => ({
  width: '70%',
  height: '100%',
  borderRadius: 8,
  overflow: 'hidden',
}));

export const RealtimeDetailView: FC = () => {
  const { id } = useParams();

  const { isPending, data: tourData } = useQuery({
    queryKey: [QueryKeys.TOURS, id],
    queryFn: () => getTour(id),
  });

  const { isPending: isLoadingVehicle, data: vehicleData } = useQuery({
    queryKey: [QueryKeys.VEHICLES, tourData?.vehicle?.uuid],
    queryFn: () => getVehicle(tourData?.vehicle?.uuid),
    enabled: !!tourData?.vehicle?.uuid,
  });

  const { data: routeData, isPending: isLoadingTourData } = useQuery({
    queryKey: [QueryKeys.ROUTES, tourData?.route],
    queryFn: () => getRoute(tourData.route),
    enabled: !!tourData?.route,
  });

  const vehicles = [];

  if (vehicleData) {
    vehicles.push({
      uuid: vehicleData?.uuid,
      licensePlate: vehicleData?.licensePlate,
      lat: vehicleData?.lat,
      lng: vehicleData?.lng,
    });
  }
  if (isPending) {
    return <LoadingScreen sx={{ position: 'absolute' }} />;
  }

  return (
    <View title={`Tour Detail - ${tourData.reference}`} sx={{ height: '100%' }}>
      <PageHeader title="Realtime" />
      <ContainerStyles>
        <DetailHeader tourUUID={tourData.uuid} />
        <DetailAndMapWrapperStyles>
          <DetailWrapperStyles>
            <Detail tour={tourData} />
          </DetailWrapperStyles>
          <MapWrapperStyles>
            <RoutingMap route={routeData} />
          </MapWrapperStyles>
        </DetailAndMapWrapperStyles>
      </ContainerStyles>
    </View>
  );
};
