import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SquareIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 4H20V20H4V4ZM6 6V18H18V6H6Z"
        />
      </SvgIcon>
    );
  }
);

SquareIcon.displayName = 'SquareIcon';

export default SquareIcon;
