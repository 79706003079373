import { Tooltip as MuiTooltip } from '@mui/material';
import React, { ComponentProps, FC } from 'react';

interface TooltipPropsOverrides {
  /**
   * If `true`, adds an arrow to the tooltip.
   * @default false
   */
  arrow?: boolean;
}

type MuiTooltipProps = ComponentProps<typeof MuiTooltip>;

type TooltipProps = Omit<MuiTooltipProps, 'arrow'> & TooltipPropsOverrides;

export const Tooltip: FC<TooltipProps> = (props) => (
  <MuiTooltip {...props} arrow={true} />
);
