import { styled } from '@mui/material/styles';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useVirtualizer } from '@tanstack/react-virtual';
import React, { useRef } from 'react';

import { Box } from '@dizzbo/ui';

import { PaginatedListType } from 'packages/core/types';
import { useTranslation } from 'react-i18next';
import { CommentItem } from './CommentItem';

const PAGE_SIZE = 20;

const ListWrapperStyles = styled(Box)(({}) => ({
  overflow: 'auto',
  height: '100%',
}));

const NoResultsWrapper = styled(Box)(() => ({
  margin: '0 1.5rem',
}));

type Props = {
  searchValue?: string;
  query: {
    queryFn: ({
      limit,
      offset,
      search,
    }: {
      limit: number;
      offset: number;
      search?: string;
    }) => PaginatedListType<any>;
    queryKey: string[];
  };
};

export const ConversationList: React.FC<Props> = ({ searchValue, query }) => {
  const parentRef = useRef();
  const { data, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: [...query.queryKey, searchValue],
      queryFn: ({ pageParam, queryKey }) =>
        query.queryFn({
          limit: PAGE_SIZE,
          offset: pageParam,
          search: queryKey[1],
        }),
      initialPageParam: 0,
      getNextPageParam: (_lastGroup, groups) =>
        _lastGroup.next ? groups.length * PAGE_SIZE : null,
    });

  const itemList = data ? data.pages.flatMap((d) => d.results) : [];

  const virtualizer = useVirtualizer({
    count: hasNextPage ? itemList.length + 1 : itemList.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 127,
    overscan: 5,
  });

  console.log('virtualizer', virtualizer.getVirtualItems());
  React.useEffect(() => {
    const [lastItem] = [...virtualizer.getVirtualItems()].reverse();

    if (!lastItem) {
      return;
    }

    if (
      lastItem.index >= itemList.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  }, [
    hasNextPage,
    fetchNextPage,
    itemList.length,
    isFetchingNextPage,
    virtualizer.getVirtualItems(),
  ]);

  const { t } = useTranslation();

  return (
    <ListWrapperStyles ref={parentRef}>
      {virtualizer.getVirtualItems().length === 0 && !isFetchingNextPage ? (
        <NoResultsWrapper>{t('noMessages')}</NoResultsWrapper>
      ) : (
        <div
          style={{
            height: virtualizer.getTotalSize(),
            width: '100%',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              transform: `translateY(${
                virtualizer.getVirtualItems()[0]?.start ?? 0
              }px)`,
            }}
          >
            {virtualizer.getVirtualItems().map((virtualRow) => {
              const item = itemList[virtualRow.index];
              const previousItem = itemList[virtualRow.index - 1];

              return (
                <div
                  key={virtualRow.key}
                  data-index={virtualRow.index}
                  ref={virtualizer.measureElement}
                >
                  {item && (
                    <CommentItem
                      text={item.text}
                      userUUID={item.author.uuid}
                      previousUserUUID={previousItem?.author?.uuid}
                      previousDate={previousItem?.createdAt}
                      username={item.author.displayName}
                      date={item.createdAt}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </ListWrapperStyles>
  );
};
