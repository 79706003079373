import { alpha } from '@mui/material/styles';

const LoadingButton = (theme) => {
  return {
    MuiLoadingButton: {
      defaultProps: {
        // loadingIndicator: <LoadingIndicator />,
      },
      styleOverrides: {
        root: {
          '&.MuiLoadingButton-loading': {
            backgroundColor: alpha(theme.palette.oxford[20], 0.1),
          },
        },
      },
    },
  };
};

export default LoadingButton;
