import { useMutation, useQueryClient } from '@tanstack/react-query';

import { moveRouteStop } from '@core/api';
import { QueryKeys } from '@core/config';
import { UUIDType } from '@types';

export const useMoveRouteStop = (routeUUID: UUIDType, orderUUID: UUIDType) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values: Payload) => moveRouteStop(routeUUID, values),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.STOPS],
      });
    },
  });
};
