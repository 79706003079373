import { CardContent as MuiCardContent } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiCardContentProps = ComponentProps<typeof MuiCardContent>;

export const CardContent = forwardRef<HTMLDivElement, MuiCardContentProps>(
  (props, ref) => <MuiCardContent ref={ref} {...props} />
);

CardContent.displayName = 'CardContent';
