import { ToggleButton as MuiToggleButton } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiToggleButtonProps = ComponentProps<typeof MuiToggleButton>;

type ToggleButtonProps = Omit<
  MuiToggleButtonProps,
  | 'centerRipple'
  | 'disableRipple'
  | 'disableTouchRipple'
  | 'focusRipple'
  | 'focusVisibleClassName'
  | 'TouchRippleProps'
  | 'touchRippleRef'
  | 'disableFocusRipple'
>;

export const ToggleButton = forwardRef<HTMLButtonElement, ToggleButtonProps>(
  (props: ToggleButtonProps, ref) => <MuiToggleButton {...props} ref={ref} />
);

ToggleButton.displayName = 'ToggleButton';
