import React from 'react';
import { alpha } from '@mui/material/styles';

import {
  DateIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  SwitchIcon,
} from '../../components/icons';

const DatePicker = (theme) => {
  return {
    MuiDatePicker: {
      defaultProps: {
        slotProps: {
          textField: {
            variant: 'filled',
          },
          desktopPaper: {
            elevation: 4,
          },
        },
        slots: {
          openPickerIcon: DateIcon,
          leftArrowIcon: ChevronLeftIcon,
          rightArrowIcon: ChevronRightIcon,
        },
      },
      styleOverrides: {
        root: {},
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          ...theme.typography.buttonRegularSmall,
          width: 40,
          height: 40,
          margin: 0,
          '&.Mui-selected': {
            backgroundColor:
              theme.styles.datePicker.pickersDay.selected.backgroundColor,
            color: theme.styles.datePicker.pickersDay.selected.color,
            '&:hover': {
              backgroundColor:
                theme.styles.datePicker.pickersDay.selected.backgroundColor,
              color: theme.styles.datePicker.pickersDay.selected.color,
            },
            '&:focus': {
              backgroundColor:
                theme.styles.datePicker.pickersDay.selected.backgroundColor,
              color: theme.styles.datePicker.pickersDay.selected.color,
            },
          },
          '&.MuiDateRangePickerDay-day': {
            transform: 'scale(1)',
          },
          '&.MuiDateRangePickerDay-dayOutsideRangeInterval': {
            opacity: 0.5,
          },
        },
        today: {
          '&:not(.Mui-selected)': {
            border: 0,
          },
          ':before': {
            content: '"• "',
            color: `${theme.styles.datePicker.pickersDay.today.color}`,
            position: 'absolute',
            bottom: 0,
            left: '50%',
            marginLeft: -3,
            marginBottom: -3,
            fontSize: 20,
          },
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          width: 328,
          maxHeight: 400,
          padding: 24,
          height: 'auto',
        },
      },
    },
    MuiPickersCalendarHeader: {
      defaultProps: {
        slots: {
          switchViewIcon: SwitchIcon,
        },
      },
      styleOverrides: {
        root: {
          margin: 0,
          paddingTop: 8,
          paddingRight: 8,
          paddingBottom: 16,
          paddingLeft: 20,
          color: `${theme.styles.datePicker.calendarHeader.default.color}`,
          '.MuiSvgIcon-root': {
            width: 16,
            height: 16,
          },
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        root: {},
        weekDayLabel: {
          width: 44,
          height: 44,
          margin: 0,
          marginRight: 0,
          ...theme.typography.footnote,
          '&:last-child': {
            marginRight: 0,
          },
        },
        monthContainer: {
          '&:first-of-type': {
            display: 'contents',
          },
          '&:nth-of-type(2)': {
            display: 'none',
          },
        },
        weekContainer: {
          marginTop: 8,
          marginRight: 0,
          marginBottom: 8,
          marginLeft: 0,
        },
        slideTransition: {
          overflow: 'hidden',
        },
      },
    },
  };
};

export default DatePicker;

// const DateRangePicker = (theme) => {
//   return {
//     MuiPickerStaticWrapper: {
//       styleOverrides: {
//         root: {
//           backgroundColor: 'transparent',
//         },
//         content: {
//           backgroundColor: theme.styles.datePicker.default.backgroundColor,
//           borderBottom: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
//         },
//       },
//     },
//     MuiStaticDatePicker: {
//       styleOverrides: {
//         root: {
//           backgroundColor: 'transparent',
//         },
//         content: {
//           backgroundColor: theme.styles.datePicker.default.backgroundColor,
//         },
//       },
//     },
//     MuiPickersArrowSwitcher: {
//       styleOverrides: {
//         root: {
//           backgroundColor: 'transparent',
//           color: theme.styles.datePicker.arrowSwitcher.default.color,
//           '.MuiTypography-root': {
//             ...theme.typography.label,
//           },
//         },
//         spacer: {},
//         button: {
//           backgroundColor: 'transparent',
//           color: theme.styles.datePicker.arrowSwitcher.button.default.color,
//           '&:hover': {
//             backgroundColor: 'transparent',
//           },
//           '&:active': {
//             backgroundColor: 'transparent',
//           },
//           '&.Mui-disabled': {
//             color: theme.styles.datePicker.arrowSwitcher.button.disabled.color,
//             backgroundColor: 'transparent',
//           },
//         },
//       },
//     },

//     MuiPickersCalendarHeader: {
//       styleOverrides: {
//         /** Styles applied to the root element. */
//         root: {},
//         /** Styles applied to the label container element. */
//         labelContainer: {},
//         /** Styles applied to the label element. */
//         label: {},
//         /** Styles applied to the switch view button element. */
//         switchViewButton: {},
//         /** Styles applied to the switch view icon element. */
//         switchViewIcon: {},
//       },
//     },

//     MuiDayPicker: {
//       styleOverrides: {
//         /** Styles applied to the header element. */
//         header: {
//           '.MuiTypography-root': {
//             color: theme.styles.datePicker.dayPicker.default.color,
//             ...theme.typography.footnote,
//           },
//         },
//         /** Styles applied to the week day label element. */
//         weekDayLabel: {},
//         /** Styles applied to the loading container element. */
//         loadingContainer: {},
//         /** Styles applied to the slide transition element. */
//         slideTransition: {},
//         /** Styles applied to the month container element. */
//         monthContainer: {},
//         /** Styles applied to the week container element. */
//         weekContainer: {
//           marginBottom: 8,
//           '.MuiDateRangePickerDay-root': {
//             '&.MuiDateRangePickerDay-rangeIntervalDayHighlight:first-of-type': {
//               borderTopLeftRadius: 0,
//               borderBottomLeftRadius: 0,
//               '&:has(.Mui-selected)': {
//                 backgroundColor:
//                   theme.styles.datePicker.default.backgroundColor,
//               },
//             },

//             '&.MuiDateRangePickerDay-rangeIntervalDayHighlight:last-of-type': {
//               borderTopRightRadius: 0,
//               borderBottomRightRadius: 0,
//               '&:has(.Mui-selected)': {
//                 backgroundColor:
//                   theme.styles.datePicker.default.backgroundColor,
//               },
//             },
//           },
//         },
//       },
//     },
//     PrivatePickersSlideTransition: {
//       styleOverrides: {
//         /** Styles applied to the root element. */
//         root: {
//           '&.MuiDayPicker-slideTransition': {
//             minHeight: 250,
//             minWidth: 330,
//           },
//         },
//         /** Styles applied to content element sliding in from left. */
//         'slideEnter-left': {},
//         /** Styles applied to content element sliding in from right. */
//         'slideEnter-right': {},
//         /** Styles applied to the element entering (transitioning into) the container. */
//         slideEnterActive: {},
//         /** Styles applied to the element leaving (transitioning out of) the container. */
//         slideExit: {},
//         /** Styles applied to the element on the left leaving (transitioning out of) the container. */
//         'slideExitActiveLeft-left': {},
//         /** Styles applied to the element on the right leaving (transitioning out of) the container. */
//         'slideExitActiveLeft-right': {},
//       },
//     },

//     MuiPickersDay: {
//       styleOverrides: {
//         root: {
//           width: 40,
//           height: 40,
//           ...theme.typography.buttonRegularSmall,
//           color: theme.styles.datePicker.pickersDay.default.color,
//           '&.Mui-disabled': {
//             color: alpha(theme.styles.datePicker.pickersDay.default.color, 0.5),
//           },
//           transform: 'scale(1) !important',
//           '&.MuiPickersDay-today': {
//             border: 0, //`1px solid ${theme.styles.datePicker.pickersDay.today.borderColor}`,
//             ':before': {
//               content: '"• "',
//               color: `${theme.styles.datePicker.pickersDay.today.color}`,
//               position: 'absolute',
//               bottom: 0,
//               left: '50%',
//               marginLeft: -3,
//               marginBottom: -3,
//               fontSize: 20,
//             },
//           },
//         },
//         /** Styles applied to the root element if `disableMargin=false`. */
//         dayWithMargin: {},
//         /** Styles applied to the root element if `outsideCurrentMonth=true` and `showDaysOutsideCurrentMonth=true`. */
//         dayOutsideMonth: {},
//         /** Styles applied to the root element if `outsideCurrentMonth=true` and `showDaysOutsideCurrentMonth=false`. */
//         hiddenDaySpacingFiller: {},
//         /** Styles applied to the root element if `disableHighlightToday=false` and `today=true`. */
//         today: {},
//         /** State class applied to the root element if `selected=true`. */
//         selected: {},
//         /** State class applied to the root element if `disabled=true`. */
//         disabled: {
//           color: 'red', //alpha(theme.styles.datePicker.pickersDay.default.color, 0.5),
//         },
//       },
//     },
//     MuiDateRangePickerDay: {
//       styleOverrides: {
//         root: {
//           '&.MuiDateRangePickerDay-rangeIntervalDayHighlightStart': {
//             '.MuiDateRangePickerDay-day': {
//               '&.Mui-selected': {
//                 backgroundColor:
//                   theme.styles.datePicker.pickerDay.selected.backgroundColor,
//                 borderTopRightRadius: 0,
//                 borderBottomRightRadius: 0,
//                 color: theme.styles.datePicker.pickerDay.selected.color,
//               },
//             },
//           },
//           '&.MuiDateRangePickerDay-rangeIntervalDayHighlightEnd': {
//             '.MuiDateRangePickerDay-day': {
//               '&.Mui-selected': {
//                 backgroundColor:
//                   theme.styles.datePicker.pickerDay.selected.backgroundColor,
//                 borderTopLeftRadius: 0,
//                 borderBottomLeftRadius: 0,
//                 color: theme.styles.datePicker.pickerDay.selected.color,
//               },
//             },
//           },
//         },
//         /** Styles applied to the root element if `isHighlighting=true` and `outsideCurrentMonth=false`. */
//         rangeIntervalDayHighlight: {
//           backgroundColor:
//             theme.styles.datePicker.pickerDay.default.backgroundColor,
//           '.MuiButtonBase-root': {
//             color: theme.styles.datePicker.pickerDay.default.color,
//           },
//         },
//         /** Styles applied to the root element if `isStartOfHighlighting=true` or `day` is the start of the month. */
//         rangeIntervalDayHighlightStart: {},
//         /** Styles applied to the root element if `isEndOfHighlighting=true` or `day` is the end of the month. */
//         rangeIntervalDayHighlightEnd: {},
//         /** Styles applied to the preview element. */
//         rangeIntervalPreview: {
//           border: 0,
//         },
//         /** Styles applied to the root element if `isPreviewing=true` and `outsideCurrentMonth=false`. */
//         rangeIntervalDayPreview: {},
//         /** Styles applied to the root element if `isStartOfPreviewing=true` or `day` is the start of the month. */
//         rangeIntervalDayPreviewStart: {},
//         /** Styles applied to the root element if `isEndOfPreviewing=true` or `day` is the end of the month. */
//         rangeIntervalDayPreviewEnd: {},
//         /** Styles applied to the day element. */
//         day: {},
//         /** Styles applied to the day element if `isHighlighting=false`. */
//         dayOutsideRangeInterval: {},
//         /** Styles applied to the day element if `selected=false` and `isHighlighting=true`. */
//         dayInsideRangeInterval: {},
//         /** Styles applied to the day element if `selected=false`. */
//         notSelectedDate: {},
//       },
//     },
//     MuiDateRangePickerViewDesktop: {
//       defaultProps: {
//         sx: {},
//       },
//       styleOverrides: {
//         root: {
//           ...theme.typography.buttonRegularSmall,
//         },
//       },
//     },
//   };
// };

// export default DateRangePicker;
