import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { toast } from 'react-toastify';

import { updateOrderProofOfDelivery } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  Box,
  Button,
  CloseIcon,
  Dialog,
  DialogContent,
  SquareIcon,
  Stack,
  Typography,
} from '@dizzbo/ui';
import { ISODateTimeType, UUIDType } from '@types';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitleStyles = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingTop: 16,
  paddingRight: 24,
  paddingBottom: 16,
  paddingLeft: 24,
}));

type Props = {
  popupState: PopupState;
  orderUUID: UUIDType;
  podUUID: UUIDType;
  file: string | Uint8Array;
  fileTitle: string;
  fileDate: ISODateTimeType;
  onSuccess?: () => void;
};

export const ProofOfDeliveryReviewDialog: React.FC<Props> = ({
  popupState,
  orderUUID,
  podUUID,
  file,
  fileTitle,
  fileDate,
  onSuccess = () => {},
}: Props) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const queryClient = useQueryClient();

  const { mutate: mutateProofOfDelivery }: any = useMutation({
    mutationKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.ORDER_PODS, podUUID],
    mutationFn: (values) =>
      updateOrderProofOfDelivery(orderUUID, podUUID, values),
    onSuccess(data, values) {
      toast.success(t('podSuccess'));
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ORDERS, orderUUID, QueryKeys.ORDER_PODS],
      });
      onSuccess();
      popupState.close();
    },
    onError(data, values) {
      toast.update(t('podFail'));
      popupState.close();
    },
  });

  const handleApprovePoD = () => {
    mutateProofOfDelivery({
      status: 'accepted',
    });
  };

  const handleRejectPoD = () => {
    mutateProofOfDelivery({
      status: 'rejected',
    });
  };

  const { t } = useTranslation();

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <Dialog
        fullScreen
        PaperProps={{
          elevation: 6,
          variant: 'filled-primary',
          sx: {
            minWidth: 600,
          },
        }}
        scroll="paper"
        {...bindDialog(popupState)}
        TransitionComponent={Transition}
      >
        <DialogTitleStyles>
          <Stack spacing={1}>
            <Typography variant="h5" color="primary">
              {fileTitle}
            </Typography>
            <Typography variant="bodyRegularSmall" color="primary">
              {dayjs(fileDate).format('DD.MM.YY h:mm')}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={4} sx={{ marginLeft: 'auto' }}>
            <Button size="medium" variant="tertiary" onClick={popupState.close}>
              {t('cancel')}
            </Button>
            <Button
              startIcon={<CloseIcon />}
              color="warning"
              size="medium"
              variant="primary"
              onClick={() => handleRejectPoD()}
            >
              {t('reject')}
            </Button>
            <Button
              startIcon={<SquareIcon />}
              size="medium"
              variant="primary"
              onClick={() => handleApprovePoD()}
            >
              {t('approve')}
            </Button>
          </Stack>
        </DialogTitleStyles>
        <DialogContent sx={{ padding: 0 }}>
          <Viewer
            fileUrl={file}
            defaultScale={1}
            plugins={[defaultLayoutPluginInstance]}
          />
        </DialogContent>
      </Dialog>
    </Worker>
  );
};
