import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ArrowRightIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M15.2417 16.9999L19.9999 12L15.2417 7.00009L13.8122 8.50227L16.1892 11.0001L3.99988 11.0001L3.99988 13.0001L16.189 13.0001L13.8122 15.4977L15.2417 16.9999Z" />
      </SvgIcon>
    );
  }
);

ArrowRightIcon.displayName = 'ArrowRightIcon';

export default ArrowRightIcon;
