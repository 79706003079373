import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const FreightExchangeIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M12.9999 23V21H10.9999V23H12.9999Z" />
        <path d="M12.9999 19V17H10.9999L10.9999 19H12.9999Z" />
        <path d="M12.9999 7V5H10.9999L10.9999 7H12.9999Z" />
        <path d="M12.9999 3L12.9999 1H10.9999V3H12.9999Z" />
        <path d="M7 14.2929L3 10.2929L7 6.29289V9.29289H15.1716L15.1716 11.2929H7L7 14.2929Z" />
        <path d="M17.4142 10.1214L21.4142 14.1214L17.4142 18.1214V15.1214L9.24267 15.1214L9.24267 13.1214L17.4142 13.1214V10.1214Z" />
      </SvgIcon>
    );
  }
);

FreightExchangeIcon.displayName = 'FreightExchangeIcon';

export default FreightExchangeIcon;
