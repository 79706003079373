import { Divider as MuiDivider } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiDividerProps = ComponentProps<typeof MuiDivider>;

export const Divider = forwardRef<HTMLHRElement, MuiDividerProps>(
  ({ variant, ...restProps }, ref) => (
    <MuiDivider
      ref={ref}
      {...restProps}
      variant={variant}
      sx={variant === 'middle' ? { marginTop: 2, marginBottom: 2 } : {}}
    />
  )
);

Divider.displayName = 'Divider';
