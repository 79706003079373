import H from '@here/maps-api-for-javascript/bin/mapsjs.bundle.harp.js';

const coreConfig = {
  apikey: import.meta.env.VITE_HERE_MAP_API_KEY as string,
};

export const getHereMapsPlatform = (
  options: Omit<H.service.Platform.Options, 'apikey'> = {}
) => {
  const coreMapSetting: H.service.Platform.Options = {
    ...coreConfig,
    ...options,
  };

  return new H.service.Platform(coreMapSetting);
};

export const getHereMapsRouterService = () => {
  const platform = getHereMapsPlatform();
  return platform.getRoutingService(null, 8);
};

export const getHereMapsDefautlLayers = (
  options: Omit<H.service.Platform.Options, 'apikey'> = {}
) => {
  const engineType = H.Map.EngineType['HARP'];
  return getHereMapsPlatform(options).createDefaultLayers({
    engineType: engineType,
    lg: 'de',
  });
};
