import * as React from 'react';
import { CircularProgressProps } from '@dizzbo/ui/CircularProgress/CircularProgress.types';

import { Box, Typography, CircularProgress } from '@dizzbo/ui';

export const TruckListItemProgressIndicator = (
  props: CircularProgressProps & { value: number }
) => {
  const { value, color, thickness, size } = props;
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        value={value}
        size={size}
        thickness={thickness}
        color={color}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="tiny" color={color}>{`${Math.round(
          props.value
        )}`}</Typography>
      </Box>
    </Box>
  );
};
