import { BryntumSchedulerPro } from '@bryntum/schedulerpro-react';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useEffect, useRef } from 'react';
import { useDrop } from 'react-dnd';

import { useDebouncedState } from '@dizzbo/core/hooks';

import { getTours, getVehicles, updateTour } from '@core/api';
import { QueryKeys } from '@core/config';

import { ISODateType, TourType } from '@types';
import { useSchedulerSettings } from '../../hooks/useSchedulerSettings';
import { SchedulerStylesWrapper } from '../SchedulerStylesWrapper';
import { schedulerProConfig } from './SchedulerConfig';

type Props = {};

export const Scheduler = ({}: Props) => {
  const { tickWidth, visibleDate } = useSchedulerSettings();
  const queryClient = useQueryClient();
  const [renderedDateRange, setRenderedDateRange] = useDebouncedState<
    [Date, Date]
  >(undefined, 100);

  const { data: vehiclesData, isPending: isLoadingVehicles } = useQuery({
    queryKey: [QueryKeys.VEHICLES],
    queryFn: () => getVehicles(),
  });

  const [rangeStart, rangeEnd] = renderedDateRange ?? [];
  const scheduledAfter = (rangeStart &&
    dayjs(rangeStart).format('YYYY-MM-DD')) as undefined | ISODateType;
  const scheduledBefore = (rangeEnd && dayjs(rangeEnd).format('YYYY-MM-DD')) as
    | undefined
    | ISODateType;

  const params = {
    hasRoute: true,
    hasVehicle: true,
    scheduledAfter,
    scheduledBefore,
  };

  const { data: toursData } = useQuery({
    queryKey: [QueryKeys.TOURS, params],
    queryFn: () => getTours(params),
    placeholderData: keepPreviousData,
  });

  const { mutate: mutateTour }: any = useMutation({
    mutationFn: (data) => updateTour(data),
  });

  const updateTourEvent = ({
    invalidateEvents = false,
    tourUUID = '',
    vehicleUUID = '',
    startDate = undefined,
    arrivalDate = undefined,
  }) => {
    mutateTour(
      {
        tourUUID: tourUUID,
        tourData: {
          startDate: startDate,
          arrivalDate: arrivalDate,
          vehicle: vehicleUUID,
        },
      },
      {
        onSuccess: (data, values) => {
          console.log('onSuccess data', data);
          if (invalidateEvents) {
            queryClient.invalidateQueries({ queryKey: [QueryKeys.TOURS] });
          }
        },
        onError: (error, variables, context) => {},
      }
    );
  };

  const syncData = async (event) => {
    if (event.action == 'update') {
      const { startDate, endDate, resourceId } = event.changes;

      const anyOldValueWasSet =
        (startDate && 'oldValue' in startDate) ||
        (endDate && 'oldValue' in endDate);

      if (!anyOldValueWasSet) return;

      const data = event.records[0].data;

      updateTourEvent({
        invalidateEvents: true,
        tourUUID: data.uuid,
        vehicleUUID: resourceId ? resourceId.value : data.vehicle.uuid,
        startDate: startDate ? startDate.value : data.startDate,
        arrivalDate: endDate ? endDate.value : data.endDate,
      });
    }
  };

  const schedulerProRef = useRef<BryntumSchedulerPro | null>(null);

  /*
   * Set the schedulerProRef and attach event listeners
   * @param ref BryntumSchedulerPro
   * @returns
   */
  function setSchedulerProRef(ref: BryntumSchedulerPro) {
    if (!ref) return;

    schedulerProRef.current = ref;

    if (!ref?.instance) return;

    ref.instance.eventStore.on({
      loadDateRange(e) {
        if (!e.changed) return;

        setRenderedDateRange.cancel();

        setRenderedDateRange([e.new.startDate, e.new.endDate]);
      },
    });
  }

  useEffect(() => {
    if (!schedulerProRef.current?.instance) return;

    schedulerProRef.current.instance.timeAxisViewModel.tickSize = tickWidth;
  }, [tickWidth]);

  useEffect(() => {
    if (!schedulerProRef.current?.instance || !visibleDate) return;

    schedulerProRef.current.instance.visibleDate = visibleDate;
  }, [visibleDate]);

  const findAncestor = (el, cls) => {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
  };

  const [collectedProps, drop] = useDrop<TourType>(() => ({
    accept: 'ORDER',
    drop: (item, monitor) => {
      const coords = monitor.getClientOffset();
      if (!coords || !schedulerProRef.current) return;

      const targetElement = document.elementFromPoint(coords.x, coords.y);
      if (!targetElement) return;

      const resourceRowElement = findAncestor(targetElement, 'b-grid-row');
      if (!resourceRowElement) return;

      const resource =
        schedulerProRef.current.instance.resolveResourceRecord(
          resourceRowElement
        );
      const startDate = schedulerProRef.current.instance.getDateFromXY(
        [coords.x, coords.y],
        'floor',
        false
      );

      const duration = item.duration > 0 ? item.duration : 86400;

      const arrivalDate = dayjs(startDate).add(duration, 'seconds');

      console.log('drop item startDate', startDate);
      console.log('drop item arrivalDate', arrivalDate);
      console.log('drop item startDate', startDate.toISOString());
      console.log('drop item arrivalDate', arrivalDate.toISOString());

      updateTourEvent({
        invalidateEvents: true,
        tourUUID: item.uuid,
        vehicleUUID: resource.id as string,
        startDate: startDate,
        arrivalDate: arrivalDate.toISOString(),
      });
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
    hover: (item, monitor) => {
      const offset = monitor.getClientOffset();
    },
  }));

  if (isLoadingVehicles) {
    return null;
  }

  const handleDeleteTour = (event) => {
    const data = event.eventRecords[0].data;

    updateTourEvent({
      invalidateEvents: true,
      tourUUID: data.uuid,
    });
  };

  const handleBeforeEventDrag = ({ source: scheduler, eventRecord }) => {
    console.log('handleBeforeEventDrag data', eventRecord);
    // TODO Lock events with Status Dispatched
    // Events with type 'LockedY' must not change resource
    // // feature.constrainDragToResource = eventRecord.type === 'LockedY';

    // Events with type 'LockedX' must not change time slot.
    // // feature.constrainDragToTimeSlot = eventRecord.type === 'LockedX';
  };

  return (
    <SchedulerStylesWrapper ref={drop}>
      <BryntumSchedulerPro
        ref={setSchedulerProRef}
        events={toursData?.results ?? []}
        resources={vehiclesData?.results ?? []}
        onDataChange={syncData}
        onBeforeEventDelete={handleDeleteTour}
        onBeforeEventDrag={handleBeforeEventDrag}
        {...schedulerProConfig}
      />
    </SchedulerStylesWrapper>
  );
};
