import React from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  DocumentIcon,
  Stack,
  Typography,
} from '@dizzbo/ui';
import { SettingType } from '@types';
import { useTranslation } from 'react-i18next';

const renderSettingValue = (value: any) => {
  if (typeof value === 'boolean') {
    return value ? 'yes' : 'no';
  }
  return value;
};

type Props = {
  settingsData: SettingType[];
};

export const Settings: React.FC<Props> = ({ settingsData }) => {
  const { t } = useTranslation();

  return (
    <Card
      variant="outlined"
      elevation={0}
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <CardHeader title="Settings" variant="small" avatar={<DocumentIcon />} />
      <CardContent>
        <Stack spacing={6} direction="row">
          <Stack spacing={3} direction="column">
            <Typography variant="h6" color="primary.light">
              {t('customer')}
            </Typography>
            <Stack spacing={0} direction="column">
              {settingsData.map((setting, index) => (
                <Typography
                  key={index}
                  variant="bodyRegularSmall"
                  color="primary.light"
                  sx={{ textTransform: 'capitalize' }}
                >
                  {setting.field.replace(/_/g, ' ')}:{' '}
                  {renderSettingValue(setting.value)}
                </Typography>
              ))}
            </Stack>
          </Stack>
          <Stack spacing={3} direction="column">
            <Typography variant="h6" color="primary.light">
              {t('carrier')}
            </Typography>
            <Stack spacing={0} direction="column">
              <Typography variant="bodyRegularSmall" color="primary.light">
                {t('sendCreditNoteEmailYes')}
              </Typography>
              <Typography variant="bodyRegularSmall" color="primary.light">
                {t('requirePodYes')}
              </Typography>
              <Typography variant="bodyRegularSmall" color="primary.light">
                {t('sendPodEmailYes')}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
