import { UUIDType } from '@types';

export const getRouteSectionPolylines = (
  lineString: H.geo.LineString,
  sectionUUID: UUIDType,
  mode: 'ferry' | 'truck'
): H.map.Polyline[] => {
  return [
    // add routeOutline
    new H.map.Polyline(lineString, {
      style: {
        lineWidth: 6,
        strokeColor: mode === 'ferry' ? '#FFCD4E' : '#5B8FF5',
        lineTailCap: 'arrow-tail',
        lineHeadCap: 'arrow-head',
      },
      data: { sectionUUID },
    }),
    // add routeArrows
    new H.map.Polyline(lineString, {
      style: {
        lineWidth: 4,
        fillColor: mode === 'ferry' ? '#FFEBCA' : '#C2E6E3',
        strokeColor: mode === 'ferry' ? '#FFEBCA' : '#C2E6E3',
        lineDash: [0, 6],
        lineTailCap: 'arrow-tail',
        lineHeadCap: 'arrow-head',
      },
      data: { sectionUUID },
    }),
  ];
};
