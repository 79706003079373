import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DoubleArrowLeftIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M17.5705 7.50011L12.8123 12.5L17.5705 17.4999L19 15.9977L15.6714 12.5L19 9.00229L17.5705 7.50011Z" />
        <path d="M8.75813 7.50011L4 12.5L8.75813 17.4999L10.1877 15.9977L6.85909 12.5L10.1877 9.00229L8.75813 7.50011Z" />
      </SvgIcon>
    );
  }
);

DoubleArrowLeftIcon.displayName = 'DoubleArrowLeftIcon';

export default DoubleArrowLeftIcon;
