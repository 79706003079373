import { alpha } from '@mui/material/styles';

const Table = (theme) => {
  return {
    MuiTableRow: {
      defaultProps: {},
      styleOverrides: {
        root: {
          '&.MuiTableRow-root': {
            backgroundColor: theme.styles.tableRow.default.backgroundColor,
            td: {
              backgroundColor: theme.styles.tableRow.default.backgroundColor,
            },
            '&:hover': {
              td: {
                backgroundColor: `${theme.styles.tableRow.hover.backgroundColor} !important`,
              },
            },
          },
          // backgroundColor: theme.styles.tableRow.default.backgroundColor,
          // backgroundColor: 'red',

          // '&.MuiTableRow-hover:hover': {
          //   backgroundColor: theme.styles.tableRow.default.backgroundColor,
          // },
          // '&:hover': {
          //   td: {
          //     backgroundColor: `${theme.styles.tableRow.hover.backgroundColor} !important`,
          //   },
          // },
        },
      },
    },
    MuiTableCell: {
      defaultProps: {},
      styleOverrides: {
        root: {
          color: theme.styles.tableCell.default.color,
          borderBottom: `1px solid ${theme.styles.tableCell.default.borderColor}`,

          // '.MuiTableCell-body&:hover': {
          //   backgroundColor: theme.palette.turquoise[20],
          // },
        },
      },
    },
    MuiTableHead: {
      defaultProps: {},
      styleOverrides: {
        root: {
          opacity: '1 !important',
          '.Mui-TableHeadCell-Content-Labels': {
            width: '100%',
          },
          '.MuiTableCell-head': {
            fontSize: 15,
            color: theme.styles.tableHead.default.color,
            paddingBottom: 27,
            paddingTop: 26,
            backgroundColor: theme.styles.tableHead.default.backgroundColor,
            // backgroundColor: alpha(theme.palette.turquoise[10], 0.8),
            backdropFilter: 'blur(10px)',
          },
          '.Mui-TableHeadCell-Content': {
            alignItems: 'center',
          },
        },
      },
    },
  };
};

export default Table;
