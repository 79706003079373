import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PinRightIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M22 3V21H20V3H22Z" />
        <path d="M19 11.9999L14.2419 16.9998L12.8123 15.4976L15.1892 13H3V11L15.1894 11L12.8123 8.50218L14.2419 7L19 11.9999Z" />
      </SvgIcon>
    );
  }
);

PinRightIcon.displayName = 'PinRightIcon';

export default PinRightIcon;
