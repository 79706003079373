import { RadioGroup as MuiRadioGroup } from '@mui/material';
import React, { ComponentProps, forwardRef } from 'react';

type MuiRadioGroupProps = ComponentProps<typeof MuiRadioGroup>;

export const RadioGroup = forwardRef<HTMLDivElement, MuiRadioGroupProps>(
  (props, ref) => <MuiRadioGroup ref={ref} {...props} />
);

RadioGroup.displayName = 'RadioGroup';
