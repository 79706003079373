import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const LocationDetailIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M7.99999 8C7.99999 8.55228 7.55228 9 6.99999 9C6.44771 9 5.99999 8.55228 5.99999 8C5.99999 7.44772 6.44771 7 6.99999 7C7.55228 7 7.99999 7.44772 7.99999 8Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 3C4.23646 3 2 5.24473 2 8.009C2 10.1193 3.21821 12.446 4.38874 14.2057C4.9798 15.0943 5.57026 15.8551 6.01282 16.3937C6.08663 16.4835 6.15636 16.5672 6.22133 16.6443L7.00505 17.5745L7.76612 16.6257C7.83596 16.5387 7.91156 16.4434 7.99203 16.3407C8.43375 15.7767 9.02319 14.9856 9.61323 14.0774C10.2027 13.1702 10.7965 12.1403 11.244 11.0993C11.6897 10.0623 12 8.99169 12 8.009C12 5.24473 9.76354 3 7 3ZM4 8.009C4 6.34506 5.34526 5 7 5C8.65474 5 10 6.34506 10 8.009C10 8.60474 9.80256 9.38805 9.40649 10.3096C9.01895 11.2112 8.48864 12.1374 7.93611 12.9878C7.60493 13.4976 7.27263 13.9696 6.96974 14.3806C6.68103 14.002 6.36701 13.5686 6.05399 13.098C4.9074 11.3743 4 9.48 4 8.009Z"
        />
        <path d="M22 19H8.63191C9.06808 18.4444 9.58154 17.7636 10.1148 17H22V19Z" />
        <path d="M22 13H12.5272C12.6729 12.7063 12.8116 12.4107 12.9416 12.1143C13.1035 11.745 13.2527 11.3726 13.3848 11H22V13Z" />
        <path d="M22 7H13.944C13.8528 6.2945 13.6527 5.62222 13.3607 5H22V7Z" />
      </SvgIcon>
    );
  }
);

LocationDetailIcon.displayName = 'LocationDetailIcon';

export default LocationDetailIcon;
