import { colors } from '@dizzbo/ui';

const { blue, forest, orange, oxford, red } = colors;

export const statusLabels = {
  DRAFT: 'Draft',
  CONFIRMED: 'Confirmed',
  DISPATCHED: 'Dispatched',
  LOADED: 'Loaded',
  UNLOADED: 'Unloaded',
  REVIEWED: 'Reviewed',
  INVOICED: 'Invoiced',
  CANCELED: 'Canceled',
};

export type StatusKeyType = keyof typeof statusLabels;

export const aggregatedStatusLabels = {
  DRAFT: 'Draft',
  PENDING: 'Pending',
  IN_PROGRESS: 'In progress',
  REVIEWED: 'Reviewed',
  INVOICED: 'Invoiced',
  CANCELED: 'Canceled',
};

export type AggregatedStatusKeyType = keyof typeof aggregatedStatusLabels;

export const aggregatedStatusMapping: Record<
  StatusKeyType,
  AggregatedStatusKeyType
> = {
  DRAFT: 'DRAFT',
  CONFIRMED: 'PENDING',
  DISPATCHED: 'IN_PROGRESS',
  LOADED: 'IN_PROGRESS',
  UNLOADED: 'IN_PROGRESS',
  REVIEWED: 'REVIEWED',
  INVOICED: 'INVOICED',
  CANCELED: 'CANCELED',
};

export const aggregatedStatusColors: Record<AggregatedStatusKeyType, string> = {
  DRAFT: orange[100],
  PENDING: oxford[20],
  IN_PROGRESS: blue[100],
  REVIEWED: forest[100],
  INVOICED: forest[100],
  CANCELED: red[100],
};

export const statusOrdering: StatusKeyType[] = [
  'DRAFT',
  'CONFIRMED',
  'DISPATCHED',
  'LOADED',
  'UNLOADED',
  'REVIEWED',
  'INVOICED',
  'CANCELED',
];

export const statusColors: Record<StatusKeyType, string> = {
  DRAFT: aggregatedStatusColors.DRAFT,
  CONFIRMED: aggregatedStatusColors.PENDING,
  DISPATCHED: aggregatedStatusColors.IN_PROGRESS,
  LOADED: aggregatedStatusColors.IN_PROGRESS,
  UNLOADED: aggregatedStatusColors.IN_PROGRESS,
  REVIEWED: aggregatedStatusColors.REVIEWED,
  INVOICED: aggregatedStatusColors.INVOICED,
  CANCELED: aggregatedStatusColors.CANCELED,
};

/**
 *
 * @param status
 * @returns Color for status
 */
export function getStatusColor(status: string) {
  return statusColors[status] ?? statusColors.DRAFT;
}

/**
 *
 * @param status
 * @returns  Label for status
 */
export function getStatusLabel(status: string) {
  return statusLabels[status] ?? statusLabels.DRAFT;
}

/**
 *
 * @param status
 * @returns Label for aggregated status
 */
export function getAggregatetStatusLabel(status: string) {
  return (
    aggregatedStatusLabels[status] ??
    aggregatedStatusLabels[aggregatedStatusMapping[status]] ??
    aggregatedStatusLabels.DRAFT
  );
}
