import button from './button';
import common from './common';
import datePicker from './datePicker';
import input from './input';
import images from './images';
import link from './link';
import paper from './paper';
import tab from './tab';
import table from './table';
import tooltip from './tooltip';

const themeLight = (palette) => {
  return Object.assign(
    button(palette),
    common(palette),
    datePicker(palette),
    input(palette),
    images(palette),
    link(palette),
    paper(palette),
    tab(palette),
    table(palette),
    tooltip(palette)
  );
};

export default themeLight;
