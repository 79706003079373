import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CheckBoxDefaultIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props} ref={ref}>
        <rect
          x="0.5"
          y="0.5"
          width="15"
          height="15"
          rx="3.5"
          fill="white"
          stroke="#99A3B2"
        />
        <rect opacity="0.1" width="16" height="16" rx="4" fill="#99A3B2" />
      </SvgIcon>
    );
  }
);

CheckBoxDefaultIcon.displayName = 'CheckBoxDefaultIcon';

export default CheckBoxDefaultIcon;
