const Card = (theme) => {
  return {
    MuiCard: {
      defaultProps: {},
      styleOverrides: {
        root: {
          // borderRadius: theme.shape.borderRadius * 2,
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {},
      styleOverrides: {
        root: {
          color: theme.styles.common.textColors.main,
          padding: theme.spacing(7, 6),
          borderBottom: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
        },
        title: {
          ...theme.typography.h5,
        },
        action: {
          alignSelf: 'center',
          margin: 0,
        },
      },
      variants: [
        {
          props: { variant: 'small' },
          style: {
            padding: theme.spacing(0, 6),
            height: 54,
          },
        },
        {
          props: { variant: 'medium' },
          style: {
            padding: theme.spacing(0, 6),
            height: 72,
            '& .MuiCardHeader-title': {
              ...theme.typography.h3,
            },
          },
        },
      ],
    },
    MuiCardContent: {
      defaultProps: {},
      styleOverrides: {
        root: {
          padding: theme.spacing(6),
          '& + .MuiCardContent-root ': {
            borderTop: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
          },
        },
      },
    },
    MuiCardActions: {
      defaultProps: {},
      styleOverrides: {
        root: {
          paddingTop: theme.spacing(3),
          paddingRight: theme.spacing(6),
          paddingBottom: theme.spacing(3),
          paddingLeft: theme.spacing(6),
          borderTop: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
        },
      },
    },
  };
};

declare module '@mui/material/CardHeader' {
  interface CardHeaderPropsVariantOverrides {
    small: true;
  }
}

export default Card;
