import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AnnotationIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M20 12H18V8H14V6H18V2H20V6H24V8H20V12Z" />
        <path d="M12 8H3V6H12V8Z" />
        <path d="M3 12H16V10H3V12Z" />
        <path d="M20 16H3V14H20V16Z" />
        <path d="M3 20H20V18H3V20Z" />
      </SvgIcon>
    );
  }
);

AnnotationIcon.displayName = 'AnnotationIcon';

export default AnnotationIcon;
