import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLinkClickHandler } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { BannerButton } from '@dizzbo/ui';
import { DocumentOrderIcon } from '@dizzbo/ui';

import { getOrders } from '@core/api/orders';
import { QueryKeys } from '@core/config';

export const ReviewPoDTodo: React.FC = () => {
  const { t } = useTranslation();
  const filter = { podReviewTodo: true };
  const { data: ordersData, isPending } = useQuery({
    queryKey: [QueryKeys.ORDERS, filter],
    queryFn: () => getOrders(filter),
  });

  // const openPodReviewTodoOrders = useLinkClickHandler({
  //   pathname: '/orders',
  //   search: 'todo=pod_review_todo',
  // });

  return (
    <BannerButton
      count={!isPending ? ordersData?.count : 0}
      label={t('reviewPoD')}
      icon={<DocumentOrderIcon />}
      // onClick={openPodReviewTodoOrders}
    />
  );
};
