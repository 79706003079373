import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useDebounce } from 'react-use';

import {
  Box,
  Stack,
  TextField,
  IconButton,
  Divider,
  MinimalIconButton,
  InputAdornment,
} from '@dizzbo/ui';
import { SearchIcon, RemoveIcon } from '@dizzbo/ui';

import { TourListSearchProps } from './TourListSearch.types';

export const TourListSearch: React.FC<TourListSearchProps> = ({
  setSearchValue,
}: TourListSearchProps) => {
  const theme = useTheme();

  const [searchString, setSearchString] = useState('');
  const [isReady, cancel] = useDebounce(
    () => {
      setSearchValue(searchString);
    },
    500,
    [searchString]
  );

  const handleClearSearchString = () => {
    setSearchString('');
  };

  return (
    <Box
      sx={{
        position: 'relative',
        flexGrow: 1,
        height: 56,
        boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.08)',
        paddingTop: 2,
        paddingRight: 6,
        paddingBottom: 2,
        paddingLeft: 6,
        background: theme.styles.tableSearchBar.default.backgroundColor,
      }}
    >
      <Stack
        direction={'row'}
        alignItems="center"
        height="100%"
        spacing={4}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Box
          sx={{
            flexGrow: 1,
            // maxWidth: 400,
            display: 'flex',
          }}
        >
          <TextField
            label="Search"
            value={searchString}
            onChange={({ currentTarget }) => {
              setSearchString(currentTarget.value);
            }}
            fullWidth
            InputProps={{
              endAdornment: (
                <>
                  {searchString && (
                    <InputAdornment position="end">
                      <MinimalIconButton
                        sx={{ opacity: 0.5 }}
                        onClick={handleClearSearchString}
                      >
                        <RemoveIcon />
                      </MinimalIconButton>
                    </InputAdornment>
                  )}
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                </>
              ),
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
};
