/* eslint-disable camelcase */

import React, { ReactElement, useMemo } from 'react';
import { useTheme, alpha } from '@mui/material/styles';
import { MaterialReactTable } from '@dizzbo/ui';
import { ColumnDef } from '@dizzbo/ui/components/Table/types';
import dayjs from 'dayjs';
import { Link as RouterLink } from 'react-router-dom';

import {
  WarningIcon,
  LoadingIcon,
  ChevronRightIcon,
  MoreVerticalIcon,
} from '@dizzbo/ui';
import {
  Button,
  Stack,
  Tooltip,
  IconButton,
  Box,
  Typography,
} from '@dizzbo/ui';

import { TableFilterAndSearchBar } from './TableFilterAndSearchBar';
import { TablePaginationBar } from './TablePaginationBar';

export interface TableProps {
  data: any;
  isLoading: boolean;
  isStale: boolean;
  isFetching: boolean;
  isError: boolean;
  columnFilters: any;
  setColumnFilters: any;
  globalFilter: any;
  setGlobalFilter: any;
  sorting: any;
  setSorting: any;
  pagination: any;
  setPagination: any;
}

export const TourTable = ({
  data,
  isLoading,
  isStale,
  isFetching,
  isError,
  columnFilters,
  setColumnFilters,
  globalFilter,
  setGlobalFilter,
  sorting,
  setSorting,
  pagination,
  setPagination,
}: TableProps): ReactElement => {
  const theme = useTheme();

  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'reference',
        header: 'Tour ID',
        enableClickToCopy: true,
        enableSorting: false,

        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Box sx={{}}>
              <Typography variant="bodyBold">{original.reference}</Typography>
            </Box>
          );
        },
      },
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          let color: any = 'default';
          if (original.status === 'pending') {
            color = 'grey.40';
          }
          if (original.status === 'on road to load') {
            color = 'info.main';
          }
          if (original.status === 'fulfilled') {
            color = 'success';
          }
          if (original.status === 'invoiced') {
            color = 'info';
          }
          return (
            <Typography
              variant="bodyBold"
              color={color}
              sx={{ textTransform: 'capitalize' }}
            >
              {original.status}
            </Typography>
          );
        },
      },
      {
        accessorFn: (data) => {
          return `${
            data.stops.length > 0 ? data.stops[0].loadingPoint.shortName : '-'
          }`;
        },
        id: 'from',
        header: 'From',
        enableSorting: false,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <>
              <Typography sx={{ fontWeight: 600 }}>
                {original?.stops?.length > 0
                  ? original.stops[0].loadingPoint.shortName
                  : '-'}
              </Typography>
            </>
          );
        },
      },
      {
        accessorFn: (data) => {
          return `${
            data.stops.length > 0 ? data.stops[1].loadingPoint.shortName : '-'
          }`;
        },
        id: 'to',
        header: 'To',
        enableSorting: false,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <>
              <Typography sx={{ fontWeight: 600 }}>
                {original.stops?.length > 0
                  ? original.stops[1].loadingPoint.shortName
                  : '-'}
              </Typography>
            </>
          );
        },
      },
      {
        accessorKey: 'createdAt',
        header: 'Created',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return dayjs(original.createdAt).format('DD.MM.YY h:mm');
        },
      },
      {
        accessorKey: 'price',
        header: 'Price',
        // enableColumnFilter: true,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return <div>{original.price}</div>;
        },
      },
      {
        accessorKey: 'todos',
        enableColumnActions: false,
        enableResizing: false,
        size: 56,
        header: '',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Stack direction="row" spacing={2} alignItems="center">
            <IconButton
              variant="secondary"
              size="medium"
              // @ts-ignore
              component={RouterLink}
              to={`/tours/${original.uuid}`}
            >
              <ChevronRightIcon />
            </IconButton>
          </Stack>
        ),
      },
    ],
    []
  );

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <MaterialReactTable
        enableGlobalFilter={false}
        enableFilters={false}
        enableStickyHeader
        columns={columns}
        data={data?.results ?? []}
        initialState={{
          showColumnFilters: false,
          columnPinning: { right: ['todos'], left: ['statusIcon'] },
        }}
        manualFiltering
        manualPagination
        manualSorting
        enablePinning
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        rowCount={data?.count ?? 0}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isFetching && isStale,
          sorting,
        }}
        muiTableBodyCellProps={({ column }) => {
          const pinnedPosition = column.getIsPinned();

          if (pinnedPosition) {
            let pinnedShadowSX;

            pinnedShadowSX = {
              backgroundImage: `url(${theme.styles.images.shadowVerticalLeft})`,
              backgroundSize: 32,
              left: -32,
            };

            if (pinnedPosition === 'left') {
              pinnedShadowSX = {
                backgroundImage: `url(${theme.styles.images.shadowVerticalRight})`,
                backgroundSize: 32,
                right: -32,
              };
            }

            return {
              sx: {
                boxShadow: 'none',
                overflow: 'visible',
                '&::before': {
                  content: "' '",
                  display: 'block',
                  position: 'absolute',
                  zIndex: 1,
                  height: '100%',
                  width: 32,
                  top: 0,
                  backgroundRepeat: 'repeat-y',
                  ...pinnedShadowSX,
                },
              },
            };
          }
        }}
        muiTableHeadCellProps={({ column }) => {
          const pinnedPosition = column.getIsPinned();
          if (pinnedPosition) {
            let pinnedShadowSX;

            pinnedShadowSX = {
              backgroundImage: `url(${theme.styles.images.shadowVerticalLeft})`,
              backgroundSize: '32px 2px',
              left: -32,
            };

            if (pinnedPosition === 'left') {
              pinnedShadowSX = {
                backgroundImage: `url(${theme.styles.images.shadowVerticalRight})`,
                backgroundSize: '32px 2px ',
                right: -32,
              };
            }
            return {
              sx: {
                zIndex: 1,
                boxShadow: 'none',
                backgroundColor: alpha(theme.palette.turquoise[10], 0.8),
                backdropFilter: 'blur(10px)',
                '&::before': {
                  content: "' '",
                  display: 'block',
                  position: 'absolute',
                  zIndex: 1,
                  height: '100%',
                  width: 32,
                  top: 0,
                  backgroundRepeat: 'repeat-y',
                  ...pinnedShadowSX,
                },
                // boxShadow: column.getIsPinned()
                //   ? '0px 4px 24px rgba(0, 0, 0, 0.08)'
                //   : 'none',
              },
            };
          }
          return {
            sx: {
              backgroundColor: theme.palette.turquoise[10],
            },
          };
        }}
        muiTableContainerProps={({ table }) => {
          const { getState } = table;
          const { isFullScreen } = getState();
          if (isFullScreen) {
            return {
              sx: {
                maxHeight: 'calc(-168px + 100vh)',
                height: 'calc(-168px + 100vh)',
              },
            };
          }
          return {
            sx: {
              maxHeight: 'calc(-232px + 100vh)',
              height: 'calc(-232px + 100vh)',
            },
          };
        }}
        muiTablePaperProps={({ table }) => {
          return {
            sx: {
              width: '100%',
              height: '100%',
              boxShadow: 'none',
              backgroundColor: theme.styles.table.default.backgroundColor,
            },
          };
        }}
        renderTopToolbar={({ table }) => (
          <TableFilterAndSearchBar
            table={table}
            setSearchValue={setGlobalFilter}
          />
        )}
        renderBottomToolbar={({ table }) => (
          <TablePaginationBar table={table} />
        )}
        icons={{
          MoreVertIcon: (props) => <MoreVerticalIcon />,
          // PushPinIcon: (props) => <PinRightIcon />,
        }}
        // renderColumnActionsMenuItems={({ closeMenu, column, table }) => {
        //   return [<div>1</div>, <div>2</div>, <div>2</div>];
        // }}
        muiTableHeadCellColumnActionsButtonProps={({ table, column }) => ({
          sx: {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.primary.dark,
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: 'transparent',
            backgroundColor: 'transparent',
            padding: '10px',
            margin: 0,
            opacity: 1,
            transform: 'none',
            '&:hover': {
              backgroundColor: 'transparent',
              borderColor: theme.palette.turquoise[100],
            },
            svg: {
              width: 16,
              height: 16,
            },
          },
        })}
        // muiTableToolbarBottomProps={{ sx: { position: 'fixed', bottom: 0 } }}
      />
    </Box>
  );
};
