import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CloseIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M17.9497 19.364L11.9999 13.4142L6.0502 19.364L4.63599 17.9498L10.5857 12L4.63599 6.05026L6.0502 4.63605L12 10.5858L17.9497 4.63605L19.3639 6.05026L13.4142 12L19.3639 17.9498L17.9497 19.364Z" />
      </SvgIcon>
    );
  }
);

CloseIcon.displayName = 'CloseIcon';

export default CloseIcon;
