import React from 'react';

import { Skeleton } from '@dizzbo/ui';

import { StopType, UUIDType } from '@types';
import { DeliveryList } from './DeliveryList';
import { StopDates } from './StopDates';

type Props = {
  stopData: StopType;
  stopUUID: UUIDType;
  isLoading: boolean;
};

export const StopContent: React.FC<Props> = ({
  stopData,
  stopUUID,
  isLoading,
}: Props) =>
  !isLoading ? (
    <>
      <StopDates
        stopUUID={stopUUID}
        requestedDate={stopData?.requestedDate}
        scheduledAt={stopData?.scheduledAt}
        arrivedAt={stopData?.arrivedAt}
        loadingPoint={stopData?.loadingPoint}
        timeslotStartsAt={stopData?.timeslotStartsAt}
        timeslotEndsAt={stopData?.timeslotEndsAt}
      />
      <DeliveryList
        loadingDeliveries={stopData?.loadingDeliveries}
        unloadingDeliveries={stopData?.unloadingDeliveries}
      />
    </>
  ) : (
    <Skeleton width="100%" height={56} />
  );
