import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ProfitIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M16 6L13 2L10 6H12V11.101C12.5741 10.5151 13.2504 10.0297 14 9.67363V6H16Z" />
        <path d="M11 9V12.3924C10.3652 13.446 10 14.6803 10 16C10 16.695 10.1013 17.3663 10.2899 18H9V9H11Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23 16C23 19.3137 20.3137 22 17 22C13.6863 22 11 19.3137 11 16C11 12.6863 13.6863 10 17 10C20.3137 10 23 12.6863 23 16ZM13.8293 16.752H13L13.1873 16.2943L13.749 16.2943C13.7401 16.1962 13.7312 16.0981 13.7312 16C13.7312 15.8937 13.7401 15.7956 13.749 15.6975H13L13.1873 15.2316H13.8293C14.1949 13.9155 15.4611 13 17.0217 13C18.3771 13 19.5006 13.6621 20 14.8147H18.6C18.2166 14.3161 17.6904 14.1035 17.0306 14.1035C16.1121 14.1035 15.4166 14.5613 15.1223 15.2316H18.2968L18.1185 15.6975H14.9885C14.9707 15.7956 14.9618 15.8937 14.9618 16C14.9618 16.0981 14.9707 16.1962 14.9796 16.2943L17.8866 16.2943L17.7172 16.752H15.1134C15.4076 17.4305 16.1032 17.8965 17.0306 17.8965C17.6904 17.8965 18.2166 17.6839 18.6 17.1853H20C19.5006 18.3379 18.3771 19 17.0217 19C15.4611 19 14.1771 18.0763 13.8293 16.752Z"
        />
        <path d="M5 14H3V18H5V14Z" />
        <path d="M6 12H8V18H6V12Z" />
      </SvgIcon>
    );
  }
);

ProfitIcon.displayName = 'ProfitIcon';

export default ProfitIcon;
