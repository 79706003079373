import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ChevronRightIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.1213 11.7071L7.41423 21.4142L6.00002 20L14.2929 11.7071L6.00002 3.41421L7.41423 2L17.1213 11.7071Z"
        />
      </SvgIcon>
    );
  }
);

ChevronRightIcon.displayName = 'ChevronRightIcon';

export default ChevronRightIcon;
