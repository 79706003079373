import { styled, useTheme } from '@mui/material/styles';
import { useIsMutating } from '@tanstack/react-query';
import React, { ReactElement, useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';

import { QueryKeys } from '@core/config';
import { View } from '@dizzbo/core/views';
import { Box, LoadingScreen, PageHeader, Paper, TourIcon } from '@dizzbo/ui';

import { RouteSettings, RoutingMap } from '@core/components/RoutingMap';
import { useCreateOrder, useGetOrder, useGetRoute } from '@core/hooks';

import { PlannerProvider } from '../contexts';

import { UUIDType } from '@types';
import { PricingBar } from '../components/PricingBar';
import { StopList } from '../components/StopList';
import { ToolBar } from '../components/ToolBar';

const ContainerStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  zIndex: theme.zIndex.appBar + 1,
}));

const ContentWrapperStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: 'calc(-192px + 100vh)',
  width: '100%',
  zIndex: 2,
}));

const SidebarWrapperStyles = styled(Paper)(({ theme }) => ({
  width: '40%',
  [theme.breakpoints.up('lg')]: {
    width: '30%',
  },
  height: '100%',
  overflowY: 'auto',
  zIndex: 2,
}));

const MapWrapperStyles = styled(Box)(({ theme }) => ({
  width: '60%',
  [theme.breakpoints.up('lg')]: {
    width: '70%',
  },
  height: '100%',
  overflow: 'hidden',
  zIndex: 1,
}));

const BottomBarStyles = styled(Paper)(({ theme }) => ({
  width: '100%',
  height: 128,
  overflow: 'hidden',
  zIndex: 4,
}));

export const PlannerView = (): ReactElement => {
  const theme = useTheme();
  const [totalPrice, setTotalPrice] = useState(0);
  const [tempOrderUUID, setTempOrderUUID] = useLocalStorage<UUIDType | null>(
    'plannerTempOrderUUID'
  );

  const { mutateAsync: mutateAsyncCreateOrder } = useCreateOrder();
  const {
    data: orderData,
    isPending,
    isError,
    isSuccess,
  } = useGetOrder(tempOrderUUID, { retry: false });

  const handleCreateOrder = () => {
    mutateAsyncCreateOrder(
      {},
      {
        onSuccess: (orderData) => {
          setTempOrderUUID(orderData.uuid);
        },
      }
    );
  };

  useEffect(() => {
    if (!tempOrderUUID) {
      handleCreateOrder();
    }
    if (isSuccess) {
      if (orderData.status !== 'NEW') {
        handleCreateOrder();
      }
    }
    if (isError) {
      handleCreateOrder();
    }
  }, [isError, isSuccess, tempOrderUUID]);

  const { data: routeData } = useGetRoute(orderData?.route);

  const isMutatingRoute = useIsMutating({
    mutationKey: [QueryKeys.ROUTES],
  });

  if (isPending || isError) {
    return <LoadingScreen sx={{ position: 'absolute' }} />;
  }

  return (
    <View title="Planner" sx={{ height: '100%' }}>
      {!!isMutatingRoute && (
        <LoadingScreen
          sx={{
            position: 'absolute',
            background: 'rgba(255,255,255, 0.3)',
          }}
        />
      )}
      <PageHeader title="Tour Planner" icon={<TourIcon />} shadow />
      <PlannerProvider orderData={orderData}>
        <ContainerStyles>
          <ContentWrapperStyles>
            <SidebarWrapperStyles square elevation={3}>
              <Box sx={{ width: '100%', height: '100%' }} bgcolor="white">
                <Box
                  pt={3}
                  pb={3}
                  sx={{
                    borderBottom: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
                  }}
                >
                  <StopList />
                </Box>
                <Box
                  pt={3}
                  pb={3}
                  sx={{
                    borderBottom: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
                  }}
                >
                  {routeData && <RouteSettings route={routeData} />}
                </Box>
              </Box>
            </SidebarWrapperStyles>
            <MapWrapperStyles>
              <RoutingMap route={routeData} />
            </MapWrapperStyles>
          </ContentWrapperStyles>
          <BottomBarStyles square elevation={3}>
            {routeData && (
              <PricingBar
                setTotalPrice={setTotalPrice}
                totalPrice={totalPrice}
                orderData={orderData}
              />
            )}

            <ToolBar totalPrice={totalPrice} setTotalPrice={setTotalPrice} />
          </BottomBarStyles>
        </ContainerStyles>
      </PlannerProvider>
    </View>
  );
};
