import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SkipRightIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path d="M5.27185 4.18537L6.72825 2.81464L15.3733 12L6.72825 21.1854L5.27185 19.8146L12.6268 12L5.27185 4.18537Z" />
        <path d="M20 3V21H18L18 3L20 3Z" />
      </SvgIcon>
    );
  }
);

SkipRightIcon.displayName = 'SkipRightIcon';

export default SkipRightIcon;
