import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CarrierIcon: React.FC<SvgIconProps> = React.forwardRef(
  (props: SvgIconProps, ref) => {
    return (
      <SvgIcon {...props} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9 5C16.4367 2.71776 14.419 1 12 1C9.23858 1 7 3.23858 7 6V7H19.5V5H16.9ZM14.8293 5C14.4175 3.83481 13.3062 3 12 3C10.6938 3 9.58254 3.83481 9.17071 5H14.8293Z"
        />
        <path d="M12.1429 17H14.5V16H23V22.5H21.9823C21.861 21.6519 21.1316 21 20.25 21C19.3684 21 18.639 21.6519 18.5177 22.5H15.7323C15.611 21.6519 14.8816 21 14 21C13.1184 21 12.389 21.6519 12.2677 22.5H11V18.5556L12.1429 17Z" />
        <path d="M19.025 22.5C19.1408 21.9294 19.6453 21.5 20.25 21.5C20.8547 21.5 21.3592 21.9294 21.475 22.5C21.4914 22.5808 21.5 22.6644 21.5 22.75C21.5 23.4404 20.9404 24 20.25 24C19.5596 24 19 23.4404 19 22.75C19 22.6644 19.0086 22.5808 19.025 22.5Z" />
        <path d="M14 21.5C14.6047 21.5 15.1092 21.9294 15.225 22.5C15.2414 22.5808 15.25 22.6644 15.25 22.75C15.25 23.4404 14.6904 24 14 24C13.3096 24 12.75 23.4404 12.75 22.75C12.75 22.6644 12.7586 22.5808 12.775 22.5C12.8908 21.9294 13.3953 21.5 14 21.5Z" />
        <path d="M15 8H17C17 9.88174 15.9605 11.5207 14.4244 12.3739C15.7298 12.7886 16.89 13.5291 17.8095 14.5H14.4017C13.6663 14.1784 12.854 14 12 14C8.68629 14 6 16.6863 6 20V21H4V20C4 16.4269 6.34252 13.4009 9.5756 12.3739C8.03951 11.5207 7 9.88174 7 8H9C9 9.65685 10.3431 11 12 11C13.6569 11 15 9.65685 15 8Z" />
      </SvgIcon>
    );
  }
);

CarrierIcon.displayName = 'CarrierIcon';

export default CarrierIcon;
